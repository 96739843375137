import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import { CheckboxSc } from 'components/final-form/CheckBox';
import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import PatientInfoRenderComponent from 'components/final-form/PatientInfoRenderComponent';
import { generateRadioButtonOptions, PxRadioSc } from 'components/final-form/Radio';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import InnerFormTable, { InnerFormTableCellProps } from 'components/InnerFormTable';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Grid, RadioProps } from 'semantic-ui-react';
import { downloadPatientTestingExportFile, getExportTestingDto } from 'service/exportTestingService';
import { getPatientDetails } from 'service/patientService';
import { getPatientTesting } from 'service/patientTestingService';
import { ExportType, HideNumbers } from 'ts-types/api.enums';
import {
  ExportTestingDto,
  ExportTestingRequest,
  PatientDto,
  QuestionSetDto,
  TestPreview,
  UpsertPatientTestingDto,
} from 'ts-types/api.types';
import { noop } from 'util/functionUtils';
import { multiLanguageInnerTableCellRenderer } from 'util/tableUtils';

interface Props {
}

const initialExportValues: Partial<UpsertPatientTestingDto> = {
  hideNumbers: HideNumbers.DONT_HIDE
}

const cancelTokenSource = axios.CancelToken.source();

const ExportTestingView = (props: Props) => {

  const { state } = useLocation();
  const { language } = useAuthContext();

  const patientId: number | undefined = state?.patientId ? Number(state?.patientId) : undefined;
  const patientTestingId: number | undefined = state?.patientTestingId ? Number(state?.patientTestingId) : undefined;
  const comparePatientTestingId: number | undefined = state?.comparePatientTestingId ? Number(state?.comparePatientTestingId) : undefined;
  const prevPath: string | undefined = state?.prevPath ? state?.prevPath : undefined;
  const backPrevPath: string | undefined = state?.backPrevPath ? state?.backPrevPath : undefined;
  const patientTestingQuestionSetId: string | undefined = state?.patientTestingQuestionSetId ? state?.patientTestingQuestionSetId : undefined;
  const { t } = useTranslation('teresa');
  const history = useHistory();

  const initialExportRequest: Partial<ExportTestingRequest> = {
    testPreviews: [],
    questionSets: [],
  };

  const [exportPreview, setExportPreview] = useState<ExportTestingDto | undefined>(undefined);
  const [exportRequest, setExportRequest] = useState<Partial<ExportTestingRequest>>(initialExportRequest);
  const [exportType, setExportType] = React.useState<ExportType>(ExportType.HL7);
  const [hideNumbers, setHideNumbers] = React.useState<HideNumbers>(HideNumbers.DONT_HIDE);

  const [dataLoaded, setDataLoaded] = React.useState<boolean>(true);
  const [successMsg, setSuccessMsg] = React.useState<string | undefined>(undefined);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [patient, setPatient] = useState <PatientDto | undefined>();
  const [patientTesting, setPatientTesting] = useState <Partial<UpsertPatientTestingDto | undefined>>(initialExportValues);
  const [questionSetId, setQuestionSetId] = useState<number[]>();

  const exportLabels: Array<ExportType> = Object.values(ExportType);
  const exportRadioDefinitions = generateRadioButtonOptions(
    exportLabels, 'export', t, 'previewResult.exportType', exportLabels);
  const hideNumbersLabels: Array<HideNumbers> = Object.values(HideNumbers);
  const hideNumbersDefinitions  =  generateRadioButtonOptions( hideNumbersLabels,
    "hideNumber", t, 'previewResult.hideNumber', hideNumbersLabels)


  useEffect(() => {
    if (patientTestingId) {
      fetchData(patientTestingId);
    }
  }, []);

  const fetchData = async (id: number) => {

    setDataLoaded(false);

    if (patientId) {
      const patientData = await getPatientDetails(patientId, cancelTokenSource);
      setPatient(patientData);
    }

    if (patientTestingId) {
      const patientTesting = await getPatientTesting(patientTestingId, cancelTokenSource);
      setPatientTesting(patientTesting);
      setHideNumbers(patientTesting.hideNumbers as HideNumbers);
    }

    try {
      if (id) {
        const exportTestingDto = await getExportTestingDto(id, cancelTokenSource);
        setExportPreview(exportTestingDto);

        const anyCompletedTests = _.some(exportTestingDto.testPreviews,
          (testPreview) => {
          return testPreview.completed;
        });

        if (!anyCompletedTests) {
          setExportType(ExportType.EXCEL);
        }

        const request: Partial<ExportTestingRequest> = {
          testPreviews: exportTestingDto.testPreviews.filter(tp => tp.completed).map(tp => tp.patientTestId!),
          questionSets: exportTestingDto.questionSets.filter(qs => qs.completed).map(qs => qs.id),
        };

        setExportRequest(request);
        setQuestionSetId(exportTestingDto.questionSets.map(qs => qs.id))
      }
    } catch (e) {
      handleError(e.response.data);
    } finally {
      setDataLoaded(true);
    }
  };

  const handleError = useCallback((error: any) => {

    if (error) {
      let errMsgs = [];
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsgs.push(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          errMsgs.push(t(`error.${errorCode}`));
        } else {
          errMsgs.push(t('error.general'));
        }
      }

      setErrorMessage(errMsgs);
    }

  }, []);

  const setErrorMessage = (errMsgs?: string[]) => {

    if (errMsgs) {
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const downloadFile = async () => {
    if (patientTestingId) {
      try {
        const response = await downloadPatientTestingExportFile(
          patientTestingId,
          hideNumbers,
          exportType,
          exportRequest,
          cancelTokenSource,
          comparePatientTestingId
        );
        setSuccessMsg(t('exportTesting.addSuccess'));
        setTimeout(() => {
          setSuccessMsg(undefined);
        }, 1200);
      } catch (e) {
        handleError(e.response.data);
      }
    }
  };

  const goToPreviousPage = () => {
    if (prevPath === '/') {
      history.push(prevPath)
    } else if (prevPath === '/doctor/details') {
      history.push(prevPath!, {id: patient?.treaterId})
    } else {
      history.push(prevPath!, {
          id: patientId,
          patientTestingId: patientTestingId,
          patient: patient,
          questionSetId: questionSetId,
          prevPath: backPrevPath,
          patientTestingQuestionSetId: patientTestingQuestionSetId
      })
    }
  }

  const renderContentForm = (): JSX.Element => {

    return (
      <FinalForm
        onSubmit={noop}
        initialValues={patientTesting}
        subscription={{ pristine: true, submitting: true, values: true }}
        render={(formProps: FormRenderProps<Partial<UpsertPatientTestingDto>>) => renderContent(formProps)}
      />
    );
  };

  const renderContent = ({handleSubmit, values}: FormRenderProps<Partial<UpsertPatientTestingDto>>): JSX.Element => {
    const disableExport = (exportType === ExportType.HL7 && _.isEmpty(exportRequest.testPreviews)) ||
      (exportType === ExportType.EXCEL && _.isEmpty(exportRequest.testPreviews) && _.isEmpty(exportRequest.questionSets));

    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          <Grid.Row>
            <Grid.Column width={6}>
              <div className='title-h1'>{t('exportTesting.viewTitle')}</div>
            </Grid.Column>
            <Grid.Column width={10}>
              <PatientInfoRenderComponent patient={patient} patientTesting={patientTesting}/>
            </Grid.Column>
          </Grid.Row>

          {errorMessages.length > 0 &&
            <Grid.Row>
              <Grid.Column width={16}>
                <div className='error'>
                  <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                    {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                  </StyledErrorMessage>
                </div>
              </Grid.Column>
            </Grid.Row>
          }
          <Grid.Row style={{ minHeight: 'inherit' }}>
            <Grid.Column width={16}>
              <DataLabel style={{ marginTop: 0, marginBottom: 0 }}>
                {t('exportTesting.type')}
              </DataLabel>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16}>
              {exportRadioDefinitions.map(options => {
                const disabledHL7 = options.value === ExportType.HL7 && _.isEmpty(exportRequest.testPreviews);
                return (
                  <PxRadioSc
                    onClick={(param: React.FormEvent<HTMLInputElement>, data: RadioProps) =>
                      setExportType(data.value as ExportType)
                    }
                    checked={exportType === options.value}
                    {...options}
                    disabled={disabledHL7}
                  />
                );
              })
              }
            </Grid.Column>

          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16}>
              <Grid stackable doubling columns={2}>
                <Grid.Column width={8}>
                  {renderExportPreviewTable('testPreviews')}
                </Grid.Column>
                <Grid.Column width={8}>
                  {renderExportPreviewTable('questionSets')}
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16}>
              {hideNumbersDefinitions.map(options => {
                return (
                  <PxRadioSc
                    onClick={(param: React.FormEvent<HTMLInputElement>, data: RadioProps) =>
                      setHideNumbers(data.value as HideNumbers)
                    }
                    checked={hideNumbers === options.value}
                    {...options}
                  />
                );
              })
              }
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16}
                         style={{
                           borderTop: '1px solid var(--very-light-blue)',
                           marginTop: '2rem',
                         }}
            >
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16}>
              <Grid stackable doubling columns={2}>
                <Grid.Row textAlign='right'>
                  <Grid.Column width={16} style={{ paddingRight: '0' }}>
                    {
                      successMsg &&
                      <SaveAndUpdateConfirmationMessage>
                        {successMsg}
                      </SaveAndUpdateConfirmationMessage>
                    }
                    <CompositeButton
                      primary
                      type='button'
                      className='action-button'
                      onClick={() => downloadFile()}
                      disabled={disableExport}
                    >
                      {t('button.export')}
                    </CompositeButton>
                    <CompositeButton
                      type='button'
                      className='action-button'
                      secondary
                      onClick={goToPreviousPage}>
                      {t('action.back')}
                    </CompositeButton>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </TsaGrid>
      </form>
    );
  };

  const selectAllCheckBox = (key: string) => {

    if (exportPreview) {
      const count = _.filter(
        exportPreview[key as keyof ExportTestingDto],
        (t: TestPreview | QuestionSetDto) => t.completed
      ).length;

      const selected: number[] = exportRequest[key as keyof ExportTestingRequest] as number[];
      const countSelected = selected.length;
      const indeterminate = countSelected > 0
        && countSelected < count;

      const noSelected = countSelected === 0 && count > 0;
      const allSelected = count > 0
        && countSelected === count;

      return (
        <CheckboxSc
          checked={countSelected > 0}
          indeterminate={indeterminate}
          onChange={() => {
            let request = { ...exportRequest };
            if (noSelected || indeterminate) {
              request[key as keyof ExportTestingRequest] =
                (exportPreview![key as keyof ExportTestingDto] as TestPreview[])
                .filter((value: TestPreview | QuestionSetDto) => {
                  if ('completed' in value) {
                    return value.completed;
                  }
                  return true;
                })
                .map((value: TestPreview | QuestionSetDto) => {
                  if ('id' in value && value.id) {
                    return value.id;
                  } else if ('patientTestId' in value && value.patientTestId) {
                    return value.patientTestId;
                  }
                  return 0;
                });
            }

            if (allSelected) {
              request[key as keyof ExportTestingRequest] = [];
            }

            setExportRequest(request);
          }
          }
          disabled={count === 0}
        />
      );
    }
    return <></>;
  };

  const singleRecordCheckBox = (key: string) => (rowData: any) => {

    const checkedElement: number = 'id' in rowData && rowData.id
      ? rowData.id
      : rowData.patientTestId;

    const isDisabled = !rowData.completed;

    return (
      <>
        <div {...isDisabled ? {title: 'No results'} : {}}>
          <CheckboxSc
            disabled={isDisabled}
            checked={exportRequest[key as keyof ExportTestingRequest]!.includes(checkedElement) && !isDisabled}
            onChange={() => {
              let request = { ...exportRequest };
              if (exportRequest[key as keyof ExportTestingRequest]!.includes(checkedElement)) {
                request[key as keyof ExportTestingRequest] = request[key as keyof ExportTestingRequest]!
                .filter(id => id !== checkedElement);
              } else {
                request[key as keyof ExportTestingRequest] =
                  [...request[key as keyof ExportTestingRequest]!, checkedElement];
              }
              setExportRequest(request);
            }}
          />
        </div>
      </>
    );
  };

  const renderExportPreviewTable = (key: string) => {

    let nameDescriptionCol: InnerFormTableCellProps = {
      width: 8,
      label: t('testconf.description'),
      dataKey: 'description',
      oneLine: true,
      cellRenderer: multiLanguageInnerTableCellRenderer('description', language),
    };

    if (key === 'questionSets') {
      nameDescriptionCol.label = t('questionSet.name');
      nameDescriptionCol.dataKey = 'name';
      nameDescriptionCol.cellRenderer = multiLanguageInnerTableCellRenderer('name', language);
    }

    return (
      <>
        <Grid.Row>
          <Grid.Column width={15}>
            <DataLabel>
              {key === 'questionSets'
                ? t('questionSet.title')
                : t('exportTesting.tests')
              }
            </DataLabel>
            <InnerFormTable id='true-score'
                            numOfCells={2}
                            values={
                              exportPreview && exportPreview[key as keyof ExportTestingDto]
                                ? exportPreview[key as keyof ExportTestingDto]
                                : []
                            }
                            visibleRows={5}
                            columns={[
                              {
                                width: 1,
                                label: selectAllCheckBox(key),
                                dataKey: 'patientTestId',
                                cellRenderer: singleRecordCheckBox(key),
                              },
                              nameDescriptionCol,
                            ]}
            />
          </Grid.Column>
        </Grid.Row>
      </>
    );
  };

  return (
    <UpsertContentWrapperDiv>
      {dataLoaded
        ? <React.Fragment>
          {renderContentForm()}
        </React.Fragment>
        : <LoaderComponent message={t('general.loading')} />
      }
    </UpsertContentWrapperDiv>
  );
};

export default ExportTestingView;