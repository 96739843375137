import { CancelTokenSource } from 'axios';
import axios, { backendUrls } from 'service/http';
import {
  TestConfDto, TestConfExportDto,
  TestConfSearchRequest, TestResultExportDto,
  UpsertTestConfDto,
  UpsertTestConfImage,
} from 'ts-types/api.types';


const apiUri = `${backendUrls.apiRoot}/testconf`;

export const getTestConfs = (source: CancelTokenSource): Promise<Array<TestConfDto>> => {
  return axios
  .get(apiUri, { cancelToken: source.token })
  .then(response => response.data);
};

export const getTestConf = (testConfId: number, source: CancelTokenSource): Promise<UpsertTestConfDto> => {
  return axios
  .get(`${apiUri}/${testConfId}/details`, { cancelToken: source.token })
  .then(response => response.data);
};

export const addTestConf = (testConf: Partial<UpsertTestConfDto>, source: CancelTokenSource): Promise<TestConfDto> => {
  return axios
  .post(apiUri, testConf, { cancelToken: source.token })
  .then((response) => response.data);
};

export const editTestConf = (testConfId: number, testConf: Partial<UpsertTestConfDto>, source: CancelTokenSource): Promise<TestConfDto> => {
  return axios
  .put(`${apiUri}/${testConfId}`, testConf, { cancelToken: source.token })
  .then((response) => response.data);
};

export const searchTestConfs = (
  request: Partial<TestConfSearchRequest>,
  source: CancelTokenSource): Promise<TestConfDto[]> => {
  return axios
  .post(`${apiUri}/search`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const addTestConfImages = (
  testConfId: number,
  upsertTestConfImage: Partial<UpsertTestConfImage>,
  source: CancelTokenSource): Promise<TestConfDto> => {
  let bodyFormData = new FormData();
  Object.keys(upsertTestConfImage).forEach((key) => {
    let value = upsertTestConfImage[key as keyof UpsertTestConfImage];

    if (value) {
      //@ts-ignore
      bodyFormData.append(key, value);
    }
  });
  return axios
  .post(`${apiUri}/${testConfId}/test-images`, bodyFormData, { cancelToken: source.token })
  .then(response => response.data);
};

export const editTestConfImages = (
  testConfId: number,
  upsertTestConfImage: Partial<UpsertTestConfImage>,
  source: CancelTokenSource): Promise<TestConfDto> => {
  let bodyFormData = new FormData();
  Object.keys(upsertTestConfImage).forEach((key) => {
    let value = upsertTestConfImage[key as keyof UpsertTestConfImage];

    if (value) {
      //@ts-ignore
      bodyFormData.append(key, value);
    }
  });
  return axios
  .put(`${apiUri}/${testConfId}/test-image`, bodyFormData, { cancelToken: source.token })
  .then(response => response.data);
};

export const deleteTestConf = (testConfId: number, source: CancelTokenSource): Promise<TestConfDto> => {
  return axios
  .delete(`${apiUri}/${testConfId}`, { cancelToken: source.token })
  .then(response => response.data);
};