import { CancelTokenSource } from 'axios';
import axios, { backendUrls } from 'service/http';
import { ExercisingResultPreview, PdfResponse } from 'ts-types/api.types';

const baseUrl = process.env.REACT_APP_BACKEND_ROOT_URL;
const apiUri = `${backendUrls.apiRoot}/patient-exercising-result`;

export const previewExercisingResults = (patientExercisingId: number, source: CancelTokenSource):
  Promise<ExercisingResultPreview> => {
  return axios
    .get(`${apiUri}/${patientExercisingId}/preview-results`, {cancelToken: source.token})
    .then(response => response.data);
}

export const getPatientExercisingPreviewPdf = (
  patientExercising: number, source: CancelTokenSource)
  : Promise<PdfResponse> => {

  return axios
  .get(`${apiUri}/${patientExercising}/preview`, {cancelToken: source.token})
  .then((response) => response.data);
};

export const getPatientExercisingPreviewPdfUrl = (pdfResponse: PdfResponse) => {
  return `${baseUrl}/4b5c93a2d7f5416c95dd058113056127/${pdfResponse.pdfId}/${pdfResponse.pdfName}`;
};