import { CancelTokenSource } from 'axios';
import axios, { backendUrls } from 'service/http';
import { TestConfExportDto } from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/testconfexport`;

export const getTestConfsExport = (source: CancelTokenSource): Promise<{ [index: string]: TestConfExportDto }> => {
  return axios
  .get(`${apiUri}/export`, { cancelToken: source.token })
  .then(response => response.data);
};