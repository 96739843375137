import { useAuthContext } from 'auth/AuthContext';
import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import CompositeButton from 'components/final-form/CompositeButton';
import InnerTableActionButton from 'components/InnerTableActionButton';
import Input from 'components/final-form/Input';
import TabComponent from 'components/final-form/TabComponent';
import SearchDisplayContainer from 'components/SearchDisplayContainer';
import VirtualizedTable from 'components/VirtualizedTable';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Loader } from 'semantic-ui-react';
import { deleteDomainById, searchDomains } from 'service/domainService';
import axios from 'service/http';
import { debounce } from 'ts-debounce';
import { DomainDto, DomainSearchRequest } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { useAfterFirstRender } from 'util/functionUtils';
import { emptyTableCell, multiLanguageFieldCellRenderer } from 'util/tableUtils';

const cancelTokenSource = axios.CancelToken.source();

interface Props {
}

const DomainsView = (props: Props) => {

  const { t } = useTranslation('teresa');
  const history = useHistory();
  const { language } = useAuthContext();

  const [domainsLoaded, setDomainsLoaded] = useState<boolean>(true);
  const [foundDomains, setFoundDomains] = useState<DomainDto[]>([]);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [searchValues, setSearchValues] = useState<Partial<DomainSearchRequest>>({
    searchKey: '',
  });

  useEffect(() => {
    fetchDomains(searchValues);
  }, [])

  const handleError = (error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.userNotAdmin,
        errorUtils.domainNotFound
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      } else {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };

  const setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const fetchDomains = debounce((values: Partial<DomainSearchRequest>): void => {

    const onFinally = () => {
      setDomainsLoaded(true);
    };

    setDomainsLoaded(false);
    setFoundDomains([]);

    let newSearchValues = { ...values };
    if (_.isEmpty(newSearchValues)) {
      newSearchValues = {
        searchKey: '',
      };
    }

    searchDomains(newSearchValues, cancelTokenSource)
    .then(response => {
        setFoundDomains(response);
      },
    )
    .catch((e: any) => handleError(e.response.data))
    .finally(onFinally);

  }, 300);

  const handleChange = useAfterFirstRender(({ values }: any): void => {
    setSearchValues(values);
    fetchDomains(values);
  });


  const deleteDomain = (id: number, values: Partial<DomainSearchRequest>) => (): void => {
    deleteDomainById(id, cancelTokenSource)
    .then(() => {
      fetchDomains(values);
    });
  };

  const domainsRowGetter = ({ index }: any) => {

    Object.assign(foundDomains[index], { index: index + 1 });

    return foundDomains[index];
  };

  const domainsRowRenderer = ({ className, columns, index, key, style }: any) => {
    const a11yProps = { 'aria-rowindex': index + 1 };

    const domainId = foundDomains[index].id;

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role='row'
        style={style}
        onDoubleClick={() => openDomainDetails(domainId!)}
      >
        {columns}
      </div>
    );
  };

  const openDomainDetails = (id: number): void => {
    history.push('/domain', {
      id: id,
    });
  };

  const activeCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return <Icon name='check' size='small' />;
    }
    return emptyTableCell();
  };

  const translateDescription = (domainDto: DomainDto) => {
    const description = domainDto.description;

    const domainDescriptions: { [key: string]: string } = {
      'de': domainDto.description || description,
      'en': domainDto.descriptionEn || description,
      'fr': domainDto.descriptionFr || description,
      'it': domainDto.descriptionIt || description,
    };
    return domainDescriptions[language];
  }

  const domainActionCellRenderer = (values: Partial<DomainSearchRequest>) => ({ rowData }: any) => {
    return (
      <div className='row-actions'>
        <InnerTableActionButton
          message={t('button.edit')}
          onConfirm={() => openDomainDetails(rowData.id)}
          divider={true}
        />
        <DeleteRecordConfirmation triggerButtonText={t('button.delete')}
                                  confirmAction={deleteDomain(rowData.id, values)}
                                  deleteConfirmationText={t('domain.confirmDelete', { description: translateDescription(rowData) })}
                                  position={"top left"} />
      </div>
    );
  };

  const renderDomainsTable = (values: any): JSX.Element => {
    return (
      <VirtualizedTable
        rowCount={foundDomains.length}
        rowGetter={domainsRowGetter}
        rowRenderer={domainsRowRenderer}
        columns={[
          {
            width: 50,
            flexShrink: 0,
            label: t('domain.id'),
            dataKey: 'id',
          },
          {
            width: 250,
            flexGrow: 1,
            label: t('domain.description'),
            dataKey: 'description',
            cellRenderer: multiLanguageFieldCellRenderer('description', language)
          },
          {
            width: 100,
            label: t('domain.orderIndex'),
            dataKey: 'orderIndex',
          },
          {
            width: 100,
            label: t('domain.status'),
            dataKey: 'active',
            cellRenderer: activeCellRenderer,
          },
          {
            width: 200,
            flexShrink: 0,
            dataKey: 'id',
            label: t('domain.actions'),
            cellRenderer: domainActionCellRenderer(values),
          },
        ]}
      />
    );
  };

  const renderDomainForm = (): JSX.Element => {
    return (
      <FinalForm
        onSubmit={() => {
        }}
        initialValues={{
          active: undefined,
          searchKey: '',
        }}
        subscription={{ pristine: true, values: true }}
        render={renderSearchFormContent}
      />
    );
  };

  const renderSearchFormContent = ({ values }: FormRenderProps): React.ReactNode => {

    return (
      <>
        <div className='search-container'>
          <div className='title-h1'>{t('domain.viewTitle')}</div>

          <div className='search-form'>
            <Field
              id='searchKey'
              name='searchKey'
              component={Input}
              icon={'search'}
              iconPosition='left'
              placeholder={t('domain.placeholder')}
              autoFocus
              fluid
            />

            <CompositeButton
              type='button'
              className='action-button'
              onClick={() => history.push('/domain')}
              primary
              style={{ display: 'inline-block' }}
            >
              {t('button.add')}
            </CompositeButton>
          </div>
        </div>

        <Field
          id='active'
          name='active'
          component={TabComponent}
        />

        <div className='data-table'>
          {domainsLoaded && renderDomainsTable(values)}
          {!domainsLoaded &&
            <Loader className='table-loader' active inline content={t('domain.loading')} />}
        </div>

        <FormSpy subscription={{ values: true }} onChange={handleChange} />
      </>
    );
  };


  return <SearchDisplayContainer>
    {renderDomainForm()}
  </SearchDisplayContainer>;

};

export default DomainsView;