import PatientInfoRenderComponent from 'components/final-form/PatientInfoRenderComponent';
import React, { ReactNode } from 'react';
import { Grid, LoaderProps } from 'semantic-ui-react';
import { PatientDto, UpsertPatientExercisingDto, UpsertPatientTestingDto } from 'ts-types/api.types';

interface Props extends LoaderProps {
  titleElement: ReactNode,
  patient?: PatientDto,
  patientTesting?: Partial<UpsertPatientTestingDto>
  patientExercising?: UpsertPatientExercisingDto,
}

const PatientInfoHeaderComponent = (props: Props) => {

  const {
    patient,
    patientTesting,
    patientExercising,
    titleElement
  } = props;

  return (
    <Grid.Row>
      <Grid.Column>
        <Grid stackable doubling columns={2}>
          <Grid.Column width={10}>
            {titleElement}
          </Grid.Column>
          <Grid.Column width={6}>
            <PatientInfoRenderComponent
              patient={patient}
              patientTesting={patientTesting}
              patientExercising={patientExercising}
            />
          </Grid.Column>
        </Grid>
      </Grid.Column>
    </Grid.Row>
  );
}

export default PatientInfoHeaderComponent;