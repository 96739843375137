import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import TsaFormScrollableContainer from 'components/TsaFormScrollableContainer';
import TsaGrid from 'components/TsaGrid';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Icon, List, Loader, Modal, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import Input from 'components/final-form/Input';
import TextArea from 'components/final-form/TextArea';
import { composeValidators, multipleEmailsValidator, required } from 'util/validatorUtils';

const StyledModal = styled(Modal)`
  height: 90vh;
  width: 90vw !important;
`;

const StyledModalContent = styled(Modal.Content)`
  padding: 0 !important;
  height: 100% !important;

  .ui.inline.loader.active.send-email-in-progress {
    margin-top: -0.25rem;
    margin-left: 0.375rem;
  }
`;

const StyledSegmentGroup = styled(Segment.Group)`
  margin: 0 !important;
  height: 100% !important;
  border: none !important;
`;

const LeftSegment = styled(Segment)`
  width: 70% !important;
  padding: 0 !important;
`;

const RightSegment = styled(Segment.Group)`
  width: 30% !important;
  margin: 0 !important;
  border: none !important;
  background-color: var(--very-very-light-blue);
`;

const ListSegment = styled(Segment)`
  padding: 0 !important;
`;

const ButtonSegment = styled(Segment)`
  height: 8% !important;
  background-color: var(--very-very-light-blue) !important;
  min-height: 50px !important;
  padding-top: 7% !important;
  padding-bottom: 6% !important;
  align-items: center;

  & button {
    float: right;
  }
`;

const ListContent = styled.div`
  width: 90%;
  display: inline-block;
  align-items: center;
`;

const DownloadSection = styled.div`
  width: 10%;
  display: inline-block;
  align-items: center;
  vertical-align: top;
`;

const StyledListItem = styled(List.Item)`
  padding: 0.75rem !important;

  .file-name {
    word-break: break-all;
  }
`;

const StyledForm = styled.form`
  padding: 0 1rem;

  label {
    font-weight: bold;
  }
`;

const btnStyle = {
  background: 'transparent',
  cursor: 'pointer',
  padding: '5px 10px',
};

interface FormData {
  emailsTo: string,
  emailsCc: string,
  subject: string,
  messageText: string
}


interface Props {
  pdfResponses: Array<any>
  modalOpen: boolean,
  onCloseModal: () => void,
  getPdfUrl: (pdfResponse: any) => string,
  showSendEmail?: boolean,
  sendEmailInProgress?: boolean,
  sendEmailTextKey?: string,
  sendEmail?: (emailRequest: any) => string,
}

const ReportPdfModal = (props: Props) => {

  const { t } = useTranslation('teresa');

  const {
    pdfResponses,
    modalOpen,
    onCloseModal,
    getPdfUrl,
    showSendEmail = false,
    sendEmailInProgress = false,
    sendEmailTextKey = "",
    sendEmail,
  } = props;

  if (!pdfResponses || pdfResponses.length == 0) {
    onCloseModal();
  }

  const [selectedPdfResponse, setSelectedPdfResponse] = useState<any>(pdfResponses[0]);

  const pdfUrl: string = getPdfUrl(selectedPdfResponse);

  const mappedPdfResponses = pdfResponses.map((pdfResponse, index) => (
    <StyledListItem
      key={index}
      active={selectedPdfResponse.pdfId == pdfResponse.pdfId}
      onClick={() => setSelectedPdfResponse(pdfResponse)}
    >
      <List.Icon style={{ padding: '10px' }} name='file pdf outline' size='large' verticalAlign='middle' />
      <List.Content>
        <ListContent>
          <List.Header className='file-name' as='h4'>{pdfResponse.pdfName}</List.Header>
          <List.Description as='span'>{pdfResponse.pdfSize}</List.Description>
        </ListContent>
        <DownloadSection>
          <Button
            as='a'
            href={getPdfUrl(pdfResponse)}
            download={pdfResponse.pdfName}
            icon
            style={btnStyle}
          >
            <Icon name='download' size='large' />
          </Button>
        </DownloadSection>
      </List.Content>
    </StyledListItem>
  ));

  const initialPatientEmailFormValues: FormData = {
    emailsTo: pdfResponses[0] ? pdfResponses[0].emailsTo : '',
    emailsCc: pdfResponses[0] ? pdfResponses[0].emailsCc : '',
    subject: pdfResponses[0] ? pdfResponses[0].subject : '',
    messageText: pdfResponses[0] ? pdfResponses[0].messageText : '',
  };

  const handleSubmit = (values: any) => {
  };

  const renderFinalForm = () => {
    return (
      <UpsertContentWrapperDiv>
        <FinalForm
          onSubmit={(values) => handleSubmit(values)}
          initialValues={initialPatientEmailFormValues}
          subscription={{ pristine: true, submitting: true }}
          render={renderForm}
        />
      </UpsertContentWrapperDiv>
    );
  };

  const renderForm = ({ form, handleSubmit, submitting }: FormRenderProps): React.ReactNode => {

    const validators = composeValidators(required, multipleEmailsValidator);

    return (
      <StyledForm onSubmit={handleSubmit}>
        <TsaGrid>
          <Grid.Row>
            <Grid.Column>
              <DataLabel htmlFor='emailsTo'>{t('pdfModalEmail.to')}</DataLabel>
              <Field
                name='emailsTo'
                component={Input}
                validate={validators}
                fluid
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <DataLabel htmlFor='emailsCc'>{t('pdfModalEmail.cc')}</DataLabel>
              <Field
                name='emailsCc'
                component={Input}
                validate={validators}
                fluid
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <DataLabel htmlFor='subject'>{t('pdfModalEmail.subject')}</DataLabel>
              <Field
                name='subject'
                component={Input}
                fluid
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <DataLabel htmlFor='messageText'>{t('pdfModalEmail.message')}</DataLabel>
              <Field
                name='messageText'
                component={TextArea}
                placeholder={`${t('pdfModalEmail.messageText')}...`}
                style={{ minHeight: '20vh', maxHeight: '50vh' }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <CompositeButton
                primary
                type='submit'
                className='action-button'
              >
                {t(sendEmailTextKey)}
                {
                  sendEmailInProgress &&
                  <Loader className='send-email-in-progress' size='mini' active inline />
                }
              </CompositeButton>
            </Grid.Column>
          </Grid.Row>
        </TsaGrid>
      </StyledForm>
    );
  };

  return (
    <StyledModal
      open={modalOpen}
      onClose={onCloseModal}
    >
      <StyledModalContent>
        <StyledSegmentGroup horizontal>
          <LeftSegment>
            <embed
              src={pdfUrl}
              width='100%'
              height='100%'
              type='application/pdf'
            />
          </LeftSegment>
          <RightSegment>
            <TsaFormScrollableContainer height={'92%'}>
              <ListSegment>
                <List divided relaxed selection>
                  {mappedPdfResponses}
                </List>
              </ListSegment>
              {
                showSendEmail && renderFinalForm()
              }
            </TsaFormScrollableContainer>
            <ButtonSegment>
              <CompositeButton
                type='button'
                className='action-button'
                secondary
                onClick={onCloseModal}
              >
                {t('button.close')}
              </CompositeButton>
            </ButtonSegment>
          </RightSegment>
        </StyledSegmentGroup>
      </StyledModalContent>
    </StyledModal>
  );
};

export default ReportPdfModal;
