import axios, { CancelTokenSource } from 'axios';
import { backendUrls } from 'service/http';
import {
  AgeRangesDto,
  AgeRangesSearchRequest,
  UpsertAgeRangesDto,
} from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/age-ranges`;

export const getAgeRanges = (source: CancelTokenSource): Promise<Array<AgeRangesDto>> => {
  return axios
  .get(apiUri, { cancelToken: source.token })
  .then(response => response.data);
};

export const getAgeRangeById = (ageRangeId: number, source: CancelTokenSource): Promise<UpsertAgeRangesDto> => {
  return axios
  .get(`${apiUri}/${ageRangeId}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const searchAgeRange = (
  request: Partial<AgeRangesSearchRequest>,
  source: CancelTokenSource): Promise<AgeRangesDto[]> => {
  return axios
  .post(`${apiUri}/search`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const addAgeRange = (upsertAgeRangesDto: Partial<UpsertAgeRangesDto>, source: CancelTokenSource): Promise<AgeRangesDto> => {
  return axios
  .post(apiUri, upsertAgeRangesDto, { cancelToken: source.token })
  .then(response => response.data);
};

export const editAgeRange = (ageRangeId: number, upsertAgeRangesDto: Partial<UpsertAgeRangesDto>,
                                    source: CancelTokenSource): Promise<UpsertAgeRangesDto> => {
  return axios
  .put(`${apiUri}/${ageRangeId}`, upsertAgeRangesDto, { cancelToken: source.token })
  .then(response => response.data);
};

export const deleteAgeRangeById = (ageRangeId: number, source: CancelTokenSource): Promise<AgeRangesDto> => {
  return axios
  .delete(`${apiUri}/${ageRangeId}`, { cancelToken: source.token })
  .then(response => response.data);
};