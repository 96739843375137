import React, { useEffect, useState } from 'react';
import { Button, ButtonProps } from 'semantic-ui-react';
import { SemanticSIZES } from 'semantic-ui-react/dist/commonjs/generic';
import styled, { css } from 'styled-components';

interface Props extends ButtonProps {
  type?: string;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  primary?: boolean;
  secondary?: boolean;
  fluid?: boolean;
}

const getBorderColor = (props: Props) => {
  if (props.primary) return 'var(--primary-color)';
  if (props.secondary) return 'var(--secondary-color)';
  return 'transparent';
};

const StyledButton = styled(Button)<Props>`
  &&& {
    border: 1px solid transparent;

    &:hover {
      border-color: ${props => getBorderColor(props)};
      ${props => {
        const color = getBorderColor(props)
        return color != 'transparent' ? `color: ${color}` : '';
      }}
    }

    &:focus {
      border-color: ${props => getBorderColor(props)};
      ${props => {
        const color = getBorderColor(props)
        return color != 'transparent' ? `color: ${color}` : '';
      }}
    }
  }
`;

const CompositeButton = (props: Props) => {
  const { children, className, size = "large", ...rest } = props;
  const [modifiedSize, setModifiedSize] = useState<SemanticSIZES>(size);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1181) {
        if (size === "large") {
          setModifiedSize("small");
        } else if (size === "medium") {
          setModifiedSize("tiny");
        }
      } else {
        setModifiedSize(size);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [size]);

  return (
    <StyledButton
      {...rest}
      size={modifiedSize}
      className={className}
    >
      {children}
    </StyledButton>
  );
};

export default CompositeButton;
