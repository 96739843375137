import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import rgbHex from 'rgb-hex';
import { Button, Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

const ColorPickerContainerDiv = styled.div`

  .default-value-icon {
    width: 36px;
  }
  
  .choose-color-swatch {
    display: inline-block;
    padding: 5px;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    cursor: pointer;

    .choose-color-swatch-preview {
      width: 36px;
      height: 18px;
      border-radius: 2px;
    }
  }
`

const ColorPickerPopupContentDiv = styled.div`

  .actions-row {
    display: inline-flex;
    margin-top: 20px;

    button {
      margin-right: 0.75rem;
    }

    .ui.primary.button, .ui.primary.buttons .button {
      background-color: var(--primary-color);

      :active {
        background-color: var(--primary-color);
      }

      :focus {
        background-color: var(--primary-color);
      }

      :hover {
        background-color: var(--primary-color);
      }
    }

    .ui.secondary.button, .ui.secondary.buttons .button {
      background-color: var(--secondary-color);
    }
  }
`

interface Props {
  chosenColor?: string;
  onSaveColor: (color?: string) => void
}

const ChooseColorComponent = (props:Props) => {
  const {chosenColor, onSaveColor} = props;

  const { t } = useTranslation('teresa');
  const [color, setColor] = useState(chosenColor);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleChangeComplete = (color: any) => {
    setColor("#" + rgbHex(color.rgb.r, color.rgb.g, color.rgb.b));
  };

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    if (chosenColor === color) {
      setColor(color);
    } else {
      setColor(chosenColor);
    }
    setDisplayColorPicker(false);
  };

  const handleChooseColor = () => {
    onSaveColor(color);
    setDisplayColorPicker(false);
  }

  const handleColorClear = () => {
    onSaveColor(undefined);
    setDisplayColorPicker(false);
  }

  const trigger = (
    <div className="choose-color-swatch" onClick={handleClick}>
      { !chosenColor &&
        <div className='choose-color-swatch-preview' style={{ backgroundColor: `${chosenColor}` }} >
          <Icon name='close' size='small' className={'default-value-icon'} />
        </div>

      }
      { chosenColor &&
        <div className='choose-color-swatch-preview' style={{ backgroundColor: `${color}` }}>
          <Icon name='delete' size='small' className={'default-value-icon'} style={{color: `${color}`}}/>
        </div>

      }

    </div>
  );

  const content = (
    <ColorPickerPopupContentDiv>
      <SketchPicker
        styles={{
          default: {
            picker: {
              padding: "0",
              borderRadius: "0",
              boxShadow: "none"
            }
          }
        }}
        color={color}
        onChangeComplete={(color) => {
          handleChangeComplete(color)
        }}
      />
      <div className="actions-row">
        <Button
          compact
          type="Submit"
          primary
          onClick={handleChooseColor}>
          {t("button.yes")}
        </Button>

        <Button
          compact
          type="button"
          onClick={handleClose}
        >
          {t("button.cancel")}</Button>

        <Button
          compact
          type="Submit"
          secondary
          onClick={handleColorClear}>
          {t("button.clear")}
        </Button>
      </div>
    </ColorPickerPopupContentDiv>
  );

  return (
    <ColorPickerContainerDiv className="choose-color-box">
      <Popup
        trigger={trigger}
        open={displayColorPicker}
        popperDependencies={[displayColorPicker]}
        content={content}
        flowing
        on="click"
        size="large"
        onClose={handleClose}
      />
    </ColorPickerContainerDiv>
  );

}

export default ChooseColorComponent;