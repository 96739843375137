import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Form, Input } from 'semantic-ui-react';

interface ExtendedFieldRenderProps extends FieldRenderProps<any> {
  autoComplete?: 'on' | 'off' | 'nope',
  numberInput?: boolean
}

const FinalFormInput = (props: ExtendedFieldRenderProps) => {

  const {
    input,
    meta: { touched, error },
    autoComplete = 'nope',
    numberInput = false,
    ...restProps
  } = props;

  return (
    <Form.Field>
      <Input error={!!(touched && error)}
             {...input}
             {...restProps}
             autoComplete={autoComplete}
             className={numberInput ? 'text-align-right' : ''}
      />
    </Form.Field>
  );
};

export default FinalFormInput;
