import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import Input from 'components/final-form/Input';
import CompositeButton from 'components/final-form/CompositeButton';
import TabComponent from 'components/final-form/TabComponent';
import InnerTableActionButton from 'components/InnerTableActionButton';
import SearchDisplayContainer from 'components/SearchDisplayContainer';
import StyledErrorMessage from 'components/StyledErrorMessage';
import VirtualizedTable from 'components/VirtualizedTable';
import { FormState } from 'final-form';
import _ from 'lodash';
import React, { useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Loader } from 'semantic-ui-react';
import { deleteQuestionSet, searchQuestionSet } from 'service/questionSetsServices';
import { debounce } from 'ts-debounce';
import { ScopeType } from 'ts-types/api.enums';
import { QuestionSetDto, QuestionSetSearchRequest } from 'ts-types/api.types';
import { useAfterFirstRender } from 'util/functionUtils';
import { emptyTableCell, multiLanguageFieldCellRenderer, shortLabel } from 'util/tableUtils';

const cancelTokenSource = axios.CancelToken.source();

interface QuestionFieldPops extends FormState<any> {
}

interface Props {
  scopeType: ScopeType
}

const QuestionSetView = (props: Props) => {

  const { language } = useAuthContext();
  const history = useHistory();
  const { t } = useTranslation('teresa');
  const { pathname } = useLocation();

  const [questionsLoaded, setQuestionSetsLoaded] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [foundQuestionSets, setFoundQuestionSets] = useState<QuestionSetDto[]>([]);
  const [searchValues, setSearchValues] = useState<Partial<QuestionSetSearchRequest>>({
    searchKey: '',
  });

  React.useEffect(() => {
    fetchQuestionSets(searchValues);
  }, []);

  const setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const handleChange = useAfterFirstRender(({ values }: QuestionFieldPops): void => {
    setSearchValues(values);
    fetchQuestionSets(values);
  });

  const handleError = (error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };

  const fetchQuestionSets = debounce((values: Partial<QuestionSetSearchRequest>): void => {

    const onFinally = () => {
      setQuestionSetsLoaded(true);
    };

    setQuestionSetsLoaded(false);
    setFoundQuestionSets([]);

    let newSearchValues = { ...values };
    if (_.isEmpty(newSearchValues)) {
      newSearchValues = {
        searchKey: '',
      };
    }

    newSearchValues.scopeType = props.scopeType

    searchQuestionSet(newSearchValues, cancelTokenSource)
    .then(response => {
      setFoundQuestionSets(response);
    })
    .catch((e: any) => handleError(e.response.data))
    .finally(onFinally);

  }, 300);

  const openEditQuestionSets = (id: number): void => {
    history.push(props.scopeType === ScopeType.REHAB ? '/rehabilitation-question-set' : '/question-set', {
      id: id,
      scopeType: props.scopeType,
      prevPath: pathname
    });
  };

  const statusCellRenderer = ({ cellData }: any) => {

    if (cellData) {
      return <Icon name='check' size='small' />;
    }
    return emptyTableCell();
  };

  const numberOfQuestionsCellRenderer = ({ cellData }: any) => {
    if (!cellData || cellData === 0) {
      return emptyTableCell();
    }
    return cellData;
  };

  const translateName = (questionSetDto: QuestionSetDto) => {
    const name = questionSetDto.name;

    const questionSetNames: { [key: string]: string } = {
      'de': questionSetDto.name || name,
      'en': questionSetDto.nameEn || name,
      'fr': questionSetDto.nameFr || name,
      'it': questionSetDto.nameIt || name,
    };
    return questionSetNames[language];
  }

  const actionsCellRenderer = (values?: any) => ({ rowData }: any) => {

    return (
      <div className='row-actions'>
        <InnerTableActionButton
          message={t('button.edit')}
          onConfirm={() => openEditQuestionSets(rowData.id)}
          divider={true}
        />
        <DeleteRecordConfirmation triggerButtonText={t('button.delete')}
                                  confirmAction={deleteQuestionSets(rowData.id, values)}
                                  deleteConfirmationText={t('questionSet.confirmDelete', { name: translateName(rowData) })}
                                  position={"top left"} />
      </div>
    );
  };

  const foundQuestionSetRowRenderer = ({ className, columns, index, key, style }: any) => {
    const a11yProps = { 'aria-rowindex': index + 1 };
    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role='row'
        style={style}
        onDoubleClick={() => openEditQuestionSets(foundQuestionSets[index].id)}
      >
        {columns}
      </div>
    );
  };

  const colorDisplayRenderer = ({ rowData }: any) => {

    return rowData.colors.map((color: string) => {
      if (color !== 'empty') {
        return <span title={color}>
        <Icon name='square full' style={{ color: `${color}` }} />
      </span>;
      }
      return <span>
        <Icon name='close' size='small' className={'default-value-icon'} />
      </span>;
    });
  };

  const questionSetRowGetter = ({ index }: any) => {

    Object.assign(foundQuestionSets[index], { index: index + 1 });

    return foundQuestionSets[index];
  };

  const typeCellRenderer = ( {cellData}: any) => {
    if (cellData) {
      return t("questionSetType." + cellData);
    }
    return emptyTableCell();
  };

  const renderQuestionSets = (values: any): JSX.Element => {

    const showColor = props.scopeType == ScopeType.REHAB;

    let columns: any = [
      {
        width: 50,
        flexShrink: 0,
        label: '#',
        dataKey: 'index',
      },
      {
        width: 150,
        flexGrow: 1,
        label: (t('questionSet.name')),
        dataKey: 'name',
        cellRenderer: multiLanguageFieldCellRenderer('name', language),
      },
      {
        width: 100,
        label: t('questionSet.type'),
        dataKey: 'type',
        cellRenderer: typeCellRenderer
      },
      {
        width: 50,
        label: shortLabel(t("questionSet.numberOfQuestions"), t("questionSet.numberOfQuestionsShort")),
        dataKey: 'numOfQuestions',
        cellRenderer: (numberOfQuestionsCellRenderer),
      },
    ];

    if (showColor) {
      columns.push(
        {
          width: 250,
          label: t('questionSet.color'),
          dataKey: 'questions',
          cellRenderer: colorDisplayRenderer,
        },
      );
    }

    columns.push({
        width: 50,
        label: (t('questionSet.status')),
        dataKey: 'active',
        cellRenderer: statusCellRenderer,
      },
      {
        width: 200,
        flexShrink: 0,
        label: (t('actions')),
        dataKey: 'id',
        cellRenderer: (actionsCellRenderer(values)),
      });

    return (
      <VirtualizedTable
        rowCount={foundQuestionSets.length}
        rowGetter={questionSetRowGetter}
        rowRenderer={foundQuestionSetRowRenderer}
        columns={columns}
      />
    );
  };

  const renderSearchQuestionForm = (): JSX.Element => {
    return (
      <FinalForm
        onSubmit={() => {
        }}
        initialValues={{
          searchKey: '',
          active: undefined,
        }}
        subscription={{ pristine: true }}
        render={renderSearchFormContent}
      />
    );
  };

  const deleteQuestionSets = (id: number, values: any)=> (): void => {
    deleteQuestionSet(id, cancelTokenSource)
    .then(response => {
      const newQuestionSets = foundQuestionSets.filter((questionSet: QuestionSetDto) => questionSet.id != response.id);
      setFoundQuestionSets(newQuestionSets);
    })
    .catch((e: any) => handleError(e.response.data));
  };

  const renderSearchFormContent = ({ values }: FormRenderProps): React.ReactNode => {

    return (
      <>
        <div className='search-container'>
          <div className='title-h1'>{t('questionSet.title')}</div>

          <div className='search-form'>
            <Field
              id='searchKey'
              name='searchKey'
              component={Input}
              icon={'search'}
              iconPosition='left'
              placeholder={t('questionSet.placeholder')}
              autoFocus
              fluid
            />

            <CompositeButton
              type='button'
              className='action-button'
              onClick={() => history.push(props.scopeType === ScopeType.REHAB ? '/rehabilitation-question-set' : '/question-set', {prevPath: pathname, scopeType: props.scopeType})}
              primary
              style={{ display: 'inline-block' }}
            >
              {t('button.add')}
            </CompositeButton>
          </div>
        </div>

        {errorMessages.length > 0 &&
          <div className='error'>
            <StyledErrorMessage onDismiss={() => setErrorMessage()}>
              {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
            </StyledErrorMessage>
          </div>
        }

        <Field
          id='active'
          name='active'
          component={TabComponent}
        />

        <div className='data-table'>
          {questionsLoaded && renderQuestionSets(values)}
          {!questionsLoaded &&
            <Loader className='table-loader' active inline content={t('questionSet.loading')} />}
        </div>

        <FormSpy subscription={{ values: true }} onChange={handleChange} />
      </>
    );
  };

  return (
    <SearchDisplayContainer>
      {renderSearchQuestionForm()}
    </SearchDisplayContainer>
  );

};

export default QuestionSetView;