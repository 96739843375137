import axios from 'axios';
import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import DatePicker from 'components/final-form/DatePicker';
import Input from 'components/final-form/Input';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import Select, { stringArrayToDropdownOptionArray } from 'components/final-form/Select';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import { noop } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { addInvoiceConfig, editInvoiceConfig, getInvoiceConfigById } from 'service/invoiceConfigServices';
import { InvoiceConfigType } from 'ts-types/api.enums';
import { UpsertInvoiceConfigDto } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { composeValidators, mustBeAFullNumber, mustBePositiveNumber, required } from 'util/validatorUtils';

interface Props {

}

const UpsertInvoiceConfigForm = (props: Props) => {

  const cancelTokenSource = useMemo(axios.CancelToken.source, []);

  const { t } = useTranslation('teresa');
  const { push } = useHistory();
  const { state } = useLocation();
  const invoiceConfigId: number | undefined = state?.id ? Number(state.id) : undefined;
  const isIpad = useIsIpadWidthOrBelow();

  const [invoiceConfig, setInvoiceConfig] = React.useState<Partial<UpsertInvoiceConfigDto> | undefined>();
  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(true);
  const [successMsg, setSuccessMsg] = React.useState<string | undefined>(undefined);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const invoiceConfigLabels: Array<string> = Object.values(InvoiceConfigType);
  const invoiceConfigTypes = useMemo(() => stringArrayToDropdownOptionArray(invoiceConfigLabels, t, 'invoiceConfigType'), []);

  useEffect(() => {
    fetchFormData();
  }, []);

  const fetchFormData = async () => {

    const onFinally = () => setFormDataLoaded(true);

    setFormDataLoaded(false);

    let foundInvoiceConfig = { ...invoiceConfig };

    try {
      if (invoiceConfigId) {
        foundInvoiceConfig = await getInvoiceConfigById(invoiceConfigId, cancelTokenSource);
        setInvoiceConfig(foundInvoiceConfig);
      }
    } catch (e) {

    } finally {
      onFinally();
    }

  };

  const handleError = useCallback((error: any) => {
    if (error) {
      let errMsg = undefined;
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.invalidInput,
        errorUtils.invoiceConfigDate,
        errorUtils.invoiceConfigNotFound,
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsg = (t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errMsg) {
        if (knownErrors.includes(errorCode)) {
          errMsg = (t(`error.${errorCode}`));
        } else {
          errMsg = (t('error.general'));
        }
      }

      setErrorMessage(errMsg);
    }

  }, []);

  const setErrorMessage = (errorMessage?: string) => {
    if (errorMessage) {
      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const handleSubmit = useCallback(async (values: UpsertInvoiceConfigDto) => {

    let saveValues: UpsertInvoiceConfigDto = {
      ...values,
    };

    const onSave = () => {
      invoiceConfigId
        ? setSuccessMsg(t('invoiceConfig.onUpdate'))
        : setSuccessMsg(t('invoiceConfig.onAdd'));
      setTimeout(() => goToPreviousPage(), 1200);
    };

    if (invoiceConfigId) {
      await editInvoiceConfig(invoiceConfigId, saveValues, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e.response.data));
    } else {
      await addInvoiceConfig(saveValues, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e.response.data));
    }

  }, []);

  const goToPreviousPage = useCallback(() => {
    let invoicePage = '/invoice-config';

    push(invoicePage);

  }, []);

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={(values: UpsertInvoiceConfigDto) => handleSubmit(values)}
        initialValues={invoiceConfig}
        subscription={{ pristine: true, submitting: true, values: true }}
        render={(formProps: FormRenderProps<UpsertInvoiceConfigDto>) => renderFormContent(formProps)}
      />
    );
  };

  const renderFormContent = ({
                               handleSubmit,
                               submitting,
                               values,
                             }: FormRenderProps<UpsertInvoiceConfigDto>): React.ReactNode => {

    const disabledBefore = values.validFromDate && new Date(values.validFromDate);

    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          <Grid.Row>
            <Grid.Column width={16}>
              <div className='title-h1'>{t('invoiceConfig.title')}</div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16}>
              <Grid stackable doubling columns={2}>

                {
                  errorMessages.length > 0 &&
                  <Grid.Row>
                    <Grid.Column width={12}>
                      <div className='error'>
                        <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                          {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                        </StyledErrorMessage>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                }

                <Grid.Row>
                  <Grid.Column width={isIpad ? 12 : 6}>
                    <DataLabel>{t('invoiceConfig.type')}</DataLabel>
                    <Field
                      fluid
                      name='invoiceConfigType'
                      component={Select}
                      options={invoiceConfigTypes}
                      clearable
                      validate={required}
                      autoFocus
                    />
                  </Grid.Column>
                  <Grid.Column width={isIpad ? 12 : 6}>
                    <DataLabel>{t('invoiceConfig.numberOfIncludedSets')}</DataLabel>
                    <Field
                      fluid
                      name={'numberOfIncludedSets'}
                      component={Input}
                      validate={composeValidators(required, mustBePositiveNumber, mustBeAFullNumber)}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={isIpad ? 12 : 6}>
                    <DataLabel>{t('invoiceConfig.packagePrice')}</DataLabel>
                    <Field
                      fluid
                      name={'packagePrice'}
                      component={Input}
                      validate={composeValidators(required, mustBePositiveNumber)}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={isIpad ? 12 : 6}>
                    <DataLabel>{t('invoiceConfig.testSetPrice')}</DataLabel>
                    <Field
                      fluid
                      name={'testSetPrice'}
                      component={Input}
                      validate={composeValidators(required, mustBePositiveNumber)}
                    />
                  </Grid.Column>
                  <Grid.Column width={isIpad ? 12 : 6}>
                    <DataLabel>{t('invoiceConfig.exerciseSetPrice')}</DataLabel>
                    <Field
                      fluid
                      name={'exerciseSetPrice'}
                      component={Input}
                      validate={composeValidators(required, mustBePositiveNumber)}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={isIpad ? 12 : 6}>
                    <DataLabel>{t('invoiceConfig.validFromDate')}</DataLabel>
                    <Field
                      fluid
                      name={'validFromDate'}
                      component={DatePicker}
                    />
                  </Grid.Column>
                  <Grid.Column width={isIpad ? 12 : 6}>
                    <DataLabel>{t('invoiceConfig.validToDate')}</DataLabel>
                    <Field
                      fluid
                      name={'validToDate'}
                      component={DatePicker}
                      disabledBefore={disabledBefore}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={isIpad ? 16 : 12}
                               style={{ borderTop: '1px solid var(--very-light-blue)', marginTop: '2rem' }}
                  >
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row textAlign={'right'}>
            <Grid.Column width={isIpad ? 16 : 12} style={{ paddingRight: '0' }}>
              {
                successMsg &&
                <SaveAndUpdateConfirmationMessage>
                  {successMsg}
                </SaveAndUpdateConfirmationMessage>
              }
              <CompositeButton
                primary
                type='submit'
                className='action-button'
                disabled={submitting || !!successMsg}
                onClick={noop}
              >
                {t('button.save')}
              </CompositeButton>
              <CompositeButton
                type='button'
                className='action-button'
                secondary
                disabled={submitting || !!successMsg}
                onClick={goToPreviousPage}
              >
                {t('invoiceConfig.back')}
              </CompositeButton>
            </Grid.Column>
          </Grid.Row>
        </TsaGrid>
      </form>
    );
  };

  return (
    <UpsertContentWrapperDiv>
      {formDataLoaded
        ? <React.Fragment>
          {renderFinalForm()}
        </React.Fragment>
        : <LoaderComponent message={t('invoiceConfig.loading')} />
      }
    </UpsertContentWrapperDiv>
  );

};

export default UpsertInvoiceConfigForm;