import { CancelTokenSource } from 'axios';
import axios, { backendUrls } from 'service/http';
import { ClinicConfDto } from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/clinic-conf`;

export const getClinicConf = (clinicId: number, source: CancelTokenSource): Promise<ClinicConfDto> => {
  return axios
  .get(`${apiUri}/${clinicId}`, { cancelToken: source.token})
  .then(response => response.data);
}

export const saveClinicConf = (request: ClinicConfDto, source: CancelTokenSource): Promise<ClinicConfDto> => {

  let bodyFormData = new FormData();
  Object.keys(request).forEach((key) => {
    let value = request[key as keyof ClinicConfDto];

    if (value) {
      if (typeof value === "number") {
        //@ts-ignore
        value = value.toString();
      }
      //@ts-ignore
      bodyFormData.append(key, value);
    }

  });

  return axios
  .post(`${apiUri}`, bodyFormData, { cancelToken: source.token })
  .then(response => response.data);
};