import { CancelTokenSource } from 'axios';
import axios, { backendUrls } from 'service/http';
import {
  FormulaTestDto,
  ScoringTestDto,
  ScoringTestResponse,
  TrueScoreResultDto, TrueScoreResultSearchRequest,
  UpsertTrueScoreResultDto,
} from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/truescoreresult`;

export const getTrueScoreResults = (source: CancelTokenSource): Promise<Array<TrueScoreResultDto>> => {
  return axios
  .get(`${apiUri}/all`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getTrueScoreResult = (id: number, source: CancelTokenSource): Promise<UpsertTrueScoreResultDto> => {
  return axios
  .get(`${apiUri}/${id}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getTrueScoresForTestResult = (
  testResultId: number,
  source: CancelTokenSource): Promise<TrueScoreResultDto[]> => {

  return axios
  .get(`${apiUri}/${testResultId}/test-result`, { cancelToken: source.token })
  .then(response => response.data);
};

export const searchTrueScoreRules = (
  request: Partial<TrueScoreResultSearchRequest>,
  source: CancelTokenSource): Promise<TrueScoreResultDto[]> => {
  return axios
  .post(`${apiUri}/search`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const addTrueScoreResult = (trueScoreResult: Partial<UpsertTrueScoreResultDto>, source: CancelTokenSource):
  Promise<TrueScoreResultDto> => {
  return axios
  .post(apiUri, trueScoreResult, { cancelToken: source.token })
  .then(response => response.data);
};

export const editTrueScoreResult = (
  id: number,
  trueScoreResult: Partial<UpsertTrueScoreResultDto>,
  source: CancelTokenSource ): Promise<TrueScoreResultDto> => {
  return axios.put(`${apiUri}/${id}`, trueScoreResult, { cancelToken: source.token })
    .then(response => response.data)
}

export const calculateFormulaTest = ( formulaTest: Partial<FormulaTestDto> )
  : Promise<number> => {
  return axios.post(`${apiUri}/calculate/formula`, formulaTest)
  .then(response => response.data)
}

export const calculateScoringTest = ( scoringTestDto: Partial<ScoringTestDto>, source: CancelTokenSource )
  : Promise<ScoringTestResponse> => {
  return axios.post(`${apiUri}/calculate`, scoringTestDto , {cancelToken: source.token})
  .then(response => response.data)
}

export const deleteTrueScoreResult = (id: number, source: CancelTokenSource): Promise<string> => {
  return axios
    .delete(`${apiUri}/${id}`, { cancelToken: source.token })
    .then(response => response.data)
}
