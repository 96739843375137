import { CancelTokenSource } from 'axios';
import axios, { backendUrls } from 'service/http';
import { RawScoreResultDto, UpsertRawScoreResultDto, ValidateRawScoreFormulaDto } from 'ts-types/api.types';


const apiUri = `${backendUrls.apiRoot}/rawscoreresult`;

export const getRawScoreResults = (id: number, source: CancelTokenSource): Promise<Array<RawScoreResultDto>> => {
  return axios
  .get(`${apiUri}/${id}/all`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getRawScoreResult = (id: number, source: CancelTokenSource): Promise<UpsertRawScoreResultDto> => {
  return axios
  .get(`${apiUri}/${id}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const addRawScoreResult = (rawScoreResult: Partial<UpsertRawScoreResultDto>, source: CancelTokenSource):
  Promise<RawScoreResultDto> => {
  return axios.post(apiUri, rawScoreResult, { cancelToken: source.token })
  .then(response => response.data);
};

export const editRawScoreResult = (
  rawScoreResultId: any,
  rawScoreResult: Partial<UpsertRawScoreResultDto>,
  source: CancelTokenSource): Promise<RawScoreResultDto> => {
  return axios.put(`${apiUri}/${rawScoreResultId}`, rawScoreResult, { cancelToken: source.token })
  .then(response => response.data);
};

export const validateFormula = (
  validationDto: Partial<ValidateRawScoreFormulaDto>,
  source: CancelTokenSource): Promise<string> => {

  return axios.post(`${apiUri}/validate-formula`, validationDto, { cancelToken: source.token })
  .then(response => response.data);
};

export const deleteRawScoreResult = (id: number, source: CancelTokenSource): Promise<string> => {
  return axios
  .delete(`${apiUri}/${id}`, { cancelToken: source.token })
  .then(response => response.data);
};