import { CancelTokenSource } from 'axios';
import axios, { backendUrls } from 'service/http';
import { TestConfDto, TestTemplateDto, TestTemplateSearchRequest, UpsertTestTemplateDto } from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/test-template`;

export const getAllTestTemplates = (source: CancelTokenSource): Promise<TestTemplateDto[]> => {
  return axios
  .get(apiUri, { cancelToken: source.token })
  .then(response => response.data);
};

export const getTestTemplate = (testTemplateId: number, source: CancelTokenSource): Promise<UpsertTestTemplateDto> => {
  return axios
  .get(`${apiUri}/${testTemplateId}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const findAllTestsByTemplateId = (testTemplateId: number, source: CancelTokenSource): Promise<TestConfDto[]> => {
  return axios
  .get(`${apiUri}/tests/${testTemplateId}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getTestInTemplates = (testConfId: number, source: CancelTokenSource): Promise<TestTemplateDto[]> => {
  return axios
  .get(`${apiUri}/test-in-templates/${testConfId}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const searchTestTemplates = (
  request: Partial<TestTemplateSearchRequest>,
  source: CancelTokenSource): Promise<TestTemplateDto[]> => {
  return axios
  .post(`${apiUri}/search`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const addTestTemplate = (
  upsertTestTemplateDto: Partial<UpsertTestTemplateDto>,
  source: CancelTokenSource): Promise<TestTemplateDto> => {

  return axios
  .post(apiUri, upsertTestTemplateDto, { cancelToken: source.token })
  .then(response => response.data);
};

export const editTestTemplate = (
  testTemplateId: number,
  upsertTestTemplateDto: Partial<UpsertTestTemplateDto>,
  source: CancelTokenSource): Promise<TestTemplateDto> => {

  return axios
  .put(`${apiUri}/${testTemplateId}`, upsertTestTemplateDto, {cancelToken: source.token})
  .then(response => response.data);
};

export const deleteTestTemplateById = (id: number, source: CancelTokenSource): Promise<TestTemplateDto> => {
  return axios
  .delete(`${apiUri}/${id}`, { cancelToken: source.token })
  .then(response => response.data);
};