import TsaGrid from 'components/TsaGrid';
import React, { Component } from "react"
import { DividerProps } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledInnerTsaGrid = styled(TsaGrid)`
  &.ui.grid>.row>.column {
    padding-left: 0;
    padding-right: 0;
  }
`;

class InnerTsaGrid extends Component<DividerProps> {

  render(): React.ReactNode {
    const {children, ...rest} = this.props;
    return (
        <StyledInnerTsaGrid {...rest}>
          {children}
        </StyledInnerTsaGrid>
    )
  }
}

export default InnerTsaGrid;
