import React, { Component } from "react"
import { DividerProps } from 'semantic-ui-react';
import styled from 'styled-components';

const ContentWrapperDiv = styled.div`
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;

  .page-header {
    margin-bottom: 2rem;
  }

  .error {
    margin-bottom: 1rem;
  }

  .page-actions {
    display: inline-block;
    margin-top: 1rem;
    margin-bottom: 2rem;

    .ui.button + .ui.button {
      margin-left: 1rem;
    }
  }
`;

class ViewContentWrapperDiv extends Component<DividerProps> {

  render(): React.ReactNode {
    const {children, ...rest} = this.props;
    return (
        <ContentWrapperDiv {...rest}>
          {children}
        </ContentWrapperDiv>
    )
  }
}

export default ViewContentWrapperDiv;
