import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { ExtendedUpsertExerciseResultDto } from 'routes/exerciseresult/UpsertExerciseResultForm';
import {
  ExerciseResultData, ExerciseResultParamDto,
  UpsertExerciseConfDto,
  UpsertExerciseResultDto,
} from 'ts-types/api.types';

interface State {
  exerciseConfFormData: Partial<UpsertExerciseConfDto> | undefined;
  setExerciseConfFormData: (upsertExerciseConfDto: Partial<UpsertExerciseConfDto> | undefined) => void;
  setExerciseResult: (upsertExerciseResultDto: Partial<ExtendedUpsertExerciseResultDto>, exerciseResultIx?: number) => number;
  addOrEditExerciseResult: (exerciseResultIx: number, values: Partial<UpsertExerciseResultDto>, domainDesc: string, onSave: () => Promise<void>) => Promise<void>;
  deleteExerciseResult: (exerciseResultIx: number) => void;
  onAddExerciseResultExerciseResultParam: (exerciseResultIx: number, paramId: number) => void;
  deleteExerciseResultExerciseResultParam: (exerciseResultIx: number, paramId: number) => void;
  onAddExerciseResultParam: (exerciseResultParamId: number) => void;
  deleteExerciseResultParam: (exerciseResultParamId: number) => void;
  onDragAndDropExerciseResultParam: (exerciseResultIx: number) => (startIx: number, endIx: number) => void;
  onDragAndDropExerciseConfParams: (startIx: number, endIx: number) => void;
}


const ExerciseConfFormDataContext = React.createContext<State>({
  exerciseConfFormData: undefined,
  setExerciseConfFormData: (upsertExerciseConfDto: Partial<UpsertExerciseConfDto> | undefined) => {},
  setExerciseResult: (upsertExerciseResultDto: Partial<ExtendedUpsertExerciseResultDto>, exerciseResultIx?: number) => {return {} as number;},
  addOrEditExerciseResult: (exerciseResultIx: number, values: Partial<UpsertExerciseResultDto>, domainDesc: string, onSave: () => Promise<void>) => {return {} as Promise<void> },
  deleteExerciseResult: (exerciseResultIx: number) => {},
  onAddExerciseResultExerciseResultParam: (exerciseResultIx: number, paramId: number) => {},
  deleteExerciseResultExerciseResultParam: (exerciseResultIx: number, paramId: number) => {},
  onAddExerciseResultParam: (exerciseResultParamId: number) => {},
  deleteExerciseResultParam: (exerciseResultParamId: number) => {},
  onDragAndDropExerciseResultParam: (exerciseResultIx: number) => (startIx: number, endIx: number) => {},
  onDragAndDropExerciseConfParams: (startIx: number, endIx: number) => {},
});

interface Props extends JSX.ElementChildrenAttribute {}

const ExerciseConfFormDataProvider = (props: Props) => {

  const [exerciseConfFormData, setExerciseConfFormData] =
    useState<Partial<UpsertExerciseConfDto> | undefined>(undefined);

  const [exerciseResultFormData, setExerciseResultFormData] =
    useState<Partial<UpsertExerciseResultDto> | undefined>(undefined);

  const setExerciseResult = useCallback((values: Partial<ExtendedUpsertExerciseResultDto>, exerciseResultIx?: number): number => {

    let newFormData: Partial<UpsertExerciseConfDto> = { ...exerciseConfFormData! };

    if (exerciseResultIx !== undefined && exerciseResultIx >= 0) {
      // @ts-ignore
      newFormData.exerciseResults![exerciseResultIx] = values;
    } else {
      // @ts-ignore
      newFormData.exerciseResults = [...newFormData.exerciseResults, values];
      exerciseResultIx = newFormData.exerciseResults.length - 1;
    }

    setExerciseConfFormData(newFormData);

    return exerciseResultIx;
  }, [exerciseConfFormData]);

  const addOrEditExerciseResult = async (exerciseResultIx: number, values: Partial<UpsertExerciseResultDto>, domainDesc: string, onSave: () => Promise<void>) => {

    let newFormData: Partial<UpsertExerciseConfDto> = { ...exerciseConfFormData! };

    let data: Partial<ExerciseResultData> = {
      id: values.id,
      description: values.description,
      descriptionEn: values.descriptionEn,
      descriptionFr: values.descriptionFr,
      descriptionIt: values.descriptionIt,
      domainId: values.domainId,
      domainDescription: domainDesc,
      numOfExerciseResultParams: values.exerciseResultParams ? values.exerciseResultParams.length : 0,
      numOfExerciseUses: 0,
      orderIndex: values.orderIndex && !_.isNumber(values.orderIndex) ? parseInt(values.orderIndex) : values.orderIndex,
      active: values.active,
    };

    if (exerciseResultIx >= 0
      && exerciseConfFormData
      && exerciseConfFormData.exerciseResultDataList) {

      let exerciseResultDataList = [...exerciseConfFormData.exerciseResultDataList];

      exerciseResultDataList[exerciseResultIx] = data as ExerciseResultData;

      newFormData = {
        ...newFormData,
        exerciseResultDataList: [...exerciseResultDataList],
      };
    }

    setExerciseConfFormData(newFormData);

    try {
      await onSave();
    } catch (e) {}
  };

  const deleteExerciseResult = useCallback((exerciseResultIx: number) => {

    let newFormData = { ...exerciseConfFormData! };

    if (exerciseResultIx >= 0 && exerciseConfFormData && exerciseConfFormData.exerciseResults && exerciseConfFormData.exerciseResultDataList) {
      let exerciseResults = [...exerciseConfFormData.exerciseResults];
      let exerciseResultDataList = exerciseConfFormData.exerciseResultDataList;
      exerciseResults.splice(exerciseResultIx, 1);
      exerciseResultDataList.splice(exerciseResultIx, 1);
      newFormData.exerciseResults = exerciseResults;
      newFormData.exerciseResultDataList = exerciseResultDataList;
    }

    setExerciseConfFormData(newFormData);
  }, [exerciseConfFormData]);

  const onDragAndDropExerciseConfParams = (startIx: number, endIx: number) => {

    if (exerciseConfFormData
      && exerciseConfFormData.exerciseResultParams) {

      let newFormData = { ...exerciseConfFormData };

      let exerciseResultParams = newFormData.exerciseResultParams!;
      const [removed] = exerciseResultParams.splice(startIx, 1);
      exerciseResultParams.splice(endIx, 0, removed);

      // Update the orderIndex property for all elements
      exerciseResultParams.forEach((el, index) => {
        el.orderIndex = index + 1;
      });

      setExerciseConfFormData(newFormData);
    }
  };

  const onDragAndDropExerciseResultParam = (exerciseResultIx: number) => (startIx: number, endIx: number) => {

    if (exerciseConfFormData
      && exerciseConfFormData.exerciseResults
      && exerciseConfFormData.exerciseResults[exerciseResultIx]) {

      let exerciseResult: Partial<ExtendedUpsertExerciseResultDto> = {
        ...exerciseConfFormData.exerciseResults[exerciseResultIx],
      };

      let exerciseResultParams = exerciseResult.exerciseResultParams!;
      const [removed] = exerciseResultParams.splice(startIx, 1);
      exerciseResultParams.splice(endIx, 0, removed);

      // Update the orderIndex property for all elements
      exerciseResultParams.forEach((el, index) => {
        el.orderIndex = index + 1;
      });

      setExerciseResult(exerciseResult, exerciseResultIx);
    }
  };

  const onAddExerciseResultExerciseResultParam = useCallback((exerciseResultIx: number, paramId: number) => {

    let newFormData = { ...exerciseConfFormData! };

    const countParams = newFormData.exerciseResults![exerciseResultIx].exerciseResultParams!.length;

    const exerciseResultParam: ExerciseResultParamDto = {
      paramId: paramId,
      orderIndex: countParams + 1,
    };

    newFormData.exerciseResults![exerciseResultIx].exerciseResultParams =
      [...newFormData.exerciseResults![exerciseResultIx].exerciseResultParams!, exerciseResultParam];

    setExerciseConfFormData(newFormData);
  }, [exerciseConfFormData]);

  const deleteExerciseResultExerciseResultParam = useCallback((exerciseResultIx: number, paramId: number) => {

    if (exerciseConfFormData && exerciseConfFormData.exerciseResults && exerciseConfFormData.exerciseResults[exerciseResultIx]) {
      const param = exerciseConfFormData.exerciseResults[exerciseResultIx].exerciseResultParams
      .find(t => t.paramId === paramId);

      if (param) {
        let newFormData = { ...exerciseConfFormData! };
        newFormData.exerciseResults![exerciseResultIx].exerciseResultParams =
          exerciseConfFormData.exerciseResults[exerciseResultIx].exerciseResultParams
          .filter(er => er.paramId !== paramId)
          .map((er, ix) => ({
            ...er,
            orderIndex: ix + 1,
          }));

        setExerciseConfFormData(newFormData);
      }
    }
  }, [exerciseConfFormData]);

  const onAddExerciseResultParam = useCallback((exerciseResultParamId: number) => {

    let newFormData = { ...exerciseConfFormData! };

    const exerciseParam: ExerciseResultParamDto = {
      paramId: exerciseResultParamId,
      orderIndex: newFormData.exerciseResultParams!.length + 1,
    };

    newFormData.exerciseResultParams = [...newFormData.exerciseResultParams!, exerciseParam];

    setExerciseConfFormData(newFormData);
  }, [exerciseConfFormData]);

  const deleteExerciseResultParam = useCallback((exerciseResultParamId: number) => {

    if (exerciseConfFormData && exerciseConfFormData.exerciseResultParams) {

      let newFormData: Partial<UpsertExerciseConfDto> = { ...exerciseConfFormData! };

      newFormData.exerciseResultParams =
        exerciseConfFormData.exerciseResultParams
        .filter(ex => ex.paramId !== exerciseResultParamId)
        .map((ex, ix) => ({
          ...ex,
          orderIndex: ix + 1,
        }));

      setExerciseConfFormData(newFormData);
    }
  }, [exerciseConfFormData]);

  const state: State = React.useMemo(() => {
      return {
        exerciseConfFormData, setExerciseConfFormData,
        setExerciseResult, exerciseResultFormData,
        onAddExerciseResultExerciseResultParam, deleteExerciseResultExerciseResultParam,
        addOrEditExerciseResult, deleteExerciseResult,
        onAddExerciseResultParam, deleteExerciseResultParam,
        onDragAndDropExerciseResultParam, onDragAndDropExerciseConfParams
      };
    }, [
      exerciseConfFormData, setExerciseConfFormData,
      setExerciseResult, exerciseResultFormData,
      onAddExerciseResultExerciseResultParam, deleteExerciseResultExerciseResultParam,
      addOrEditExerciseResult, deleteExerciseResult,
      onAddExerciseResultParam, deleteExerciseResultParam,
    onDragAndDropExerciseResultParam, onDragAndDropExerciseConfParams
    ],
  );

  return (
    <ExerciseConfFormDataContext.Provider value={state}>
      {props.children}
    </ExerciseConfFormDataContext.Provider>

  );
};

export const useExerciseConfFormDataContext = () => React.useContext(ExerciseConfFormDataContext);

export default ExerciseConfFormDataProvider;