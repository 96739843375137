import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import CompositeButton from 'components/final-form/CompositeButton';
import FinalFormVerificationInput from 'components/final-form/VerificationInput';
import { FormApi } from 'final-form';
import React, { Fragment, useEffect, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';
import { enable2fa, get2faData } from 'service/userServices';
import styled from 'styled-components';
import { errorUtils } from 'util/errorUtils';

const TwoFactorAuthModalContainer = styled.div`
  padding: 1.75rem 2rem;
  background-color: var(--very-very-light-blue);
  font-family: 'Manrope', sans-serif;

  .form-grid {
    margin-bottom: 1.2rem;

    .label {
      display: inline-block;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .verification-input__character {
      text-align: center;
    }

    .verification-input__character:first-child {
      margin-left: 0;
    }

    .error {
      margin-top: 0.75rem;
      margin-left: 0.5rem;
      color: darkred;
    }
  }

  .actions-row {

    .success {
      color: darkgreen;
      display: inline-block;
      margin-left: 0.5rem;
    }

    .ui.button + .ui.button {
      margin-left: 1rem;
    }

    .ui.primary.button {
      background-color: var(--primary-color);

      :hover {
        background-color: transparent;
        border-color: var(--primary-color);
        color: var(--primary-color);
      }

      :focus {
        background-color: transparent;
        border-color: var(--primary-color);
        color: var(--primary-color);
      }
    }

    .ui.secondary.button {
      background-color: var(--dark-gray);

      :hover {
        background-color: transparent;
        border-color: var(--dark-gray);
        color: var(--dark-gray);
      }

      :focus {
        background-color: transparent;
        border-color: var(--dark-gray);
        color: var(--dark-gray);
      }
    }

    .ui.large.button {
      padding: 0.6rem 3rem 0.6rem;
      font-weight: 500;
      font-family: 'Manrope', sans-serif;
    }

  }
`;

interface Props {
  modalOpen: boolean;
  onCloseModal: () => void;
  onInit2faSuccess: () => void;
}

export interface TwoStepAuthValues {
  use2fa: boolean;
  qrcodeUrl: string;
  verificationCode: string;
}

const cancelTokenSource = axios.CancelToken.source();


const TwoStepAuthModal = (props: Props) => {

  const { t } = useTranslation('teresa');
  const { currentUser } = useAuthContext();

  const { modalOpen, onCloseModal, onInit2faSuccess } = props;

  const [initialized, setInitialized] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<Partial<TwoStepAuthValues>>({});
  const [verificationCodeKey, setVerificationCodeKey] = useState<number>(1);
  const [error, setError] = useState<string | undefined>(undefined);
  const [showUpdateSuccess, setShowUpdateSuccess] = useState<boolean>(false);

  const fetch2faData = async () => {
    if (currentUser) {
      try {
        const qrcodeImage = await get2faData(cancelTokenSource);

        const initValues = {
          use2fa: true,
          qrcodeUrl: qrcodeImage,
          verificationCode: '',
        };

        setInitialValues(initValues);
        setInitialized(true);

      } catch (e) {}
    }
  };

  useEffect(() => {
    fetch2faData();
  }, []);

  const handleSubmit = async (
    values: Partial<TwoStepAuthValues>,
    form: FormApi) => {

    try {
      await enable2fa(values.verificationCode!, cancelTokenSource);
    } catch (ex) {
      let error = 'error.general';
      const { response } = ex;
      if (!!response.data && !!response.data.error) {

        let errorCode = response.data.error;

        if ([errorUtils.invalidVerificationCode].includes(errorCode)) {
          error = `init2fa.modal.error.${errorCode}`;
        }
      }

      setVerificationCodeKey(verificationCodeKey + 1);
      setError(error);

      form.change('verificationCode', undefined);

      setTimeout(() => {
        (document.getElementsByClassName('verification-input')[0]! as any).focus();
      }, 50);

      return;
    }

    setShowUpdateSuccess(true);

    setTimeout(() => {
      onInit2faSuccess();
    }, 1000);

  };

  const renderForm = ({ form, submitting, handleSubmit }: FormRenderProps): React.ReactNode => {

    const values = form.getState().values;

    return (
      <form onSubmit={handleSubmit}>
        <div>
          <div className='form-grid'>
            {values.use2fa &&
              <Fragment>
                <div>
                  <img src={values.qrcodeUrl} alt='QR code' />
                </div>

                <div>
                  <label htmlFor='verificationCode' className='label'>
                    {t('init2fa.modal.verificationCode.label')}
                  </label>
                  <Field
                    key={verificationCodeKey}
                    name='verificationCode'
                    component={FinalFormVerificationInput}
                    autoFocus={true}
                  />
                </div>
                {
                  error &&
                  <div className='error'>
                    {t(error)}
                  </div>
                }
              </Fragment>
            }
          </div>

          <div className='actions-row'>
            <CompositeButton type='submit'
                             className='action-button'
                             primary
                             disabled={submitting}
            >
              {t('button.save')}
            </CompositeButton>
            <CompositeButton type='button'
                             className='action-button'
                             secondary
                             style={{ whiteSpace: 'nowrap' }}
                             onClick={onCloseModal}
                             disabled={submitting}
            >
              {t('action.cancel')}
            </CompositeButton>
            {
              showUpdateSuccess &&
              <div className='success'>{t('init2fa.modal.success')}</div>
            }
          </div>
        </div>
      </form>
    );
  };

  return (
    <Modal
      open={modalOpen}
      onClose={onCloseModal}
      style={{ width: '32rem', borderRadius: '5px', backgroundColor: 'var(--very-very-light-blue)', fontFamily: '\'Manrope\' sans-serif' }}
      closeOnDimmerClick={false}
    >
      <Modal.Header style={{ backgroundColor: 'var(--very-very-light-blue)', fontFamily: '\'Manrope\' sans-serif' }}>
        {t('init2fa.modal.title')}
      </Modal.Header>
      <TwoFactorAuthModalContainer>
        {
          initialized
            ?
            <FinalForm
              onSubmit={handleSubmit}
              subscription={{ pristine: true, submitting: true, values: true }}
              render={renderForm}
              initialValues={initialValues}
            />
            :
            <></>
        }
      </TwoFactorAuthModalContainer>
    </Modal>
  );
};

export default TwoStepAuthModal;
