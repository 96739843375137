export const minChars = (numOfChars: number) => (value: string) => {
  return value && value.length < numOfChars ? " validator.minChars " + numOfChars : undefined;
};

export const getMinMaxDescriptionTitle = (minValue: any, maxValue: any, t: Function) => {

  let minAndMaxValueDescription = undefined;

  if (minValue !== null && maxValue !== null) {
    minAndMaxValueDescription = t('valueRange.minMax', {min: minValue,max: maxValue });
  } else if (minValue === null && maxValue !== null) {
    minAndMaxValueDescription = t('valueRange.max', {max: maxValue });
  } else if (maxValue === null && minValue !== null) {
    minAndMaxValueDescription = t('valueRange.min', {min: minValue });
  }

  return minAndMaxValueDescription;

}