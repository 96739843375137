import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import AccordionWithArrow, { CustomContent, CustomTitle } from 'components/AccordionWithArrow';
import i18n from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItemProps, Icon, Input, Popup } from 'semantic-ui-react';
import { getExerciseConfsExport } from 'service/exerciseConfExportService';
import styled from 'styled-components';
import {
  DomainDto,
  ExerciseConfExportDto,
  ExerciseConfSearchRequest,
  ExerciseResultExportDto, TestConfExportDto,
  TestResultParamDto,
} from 'ts-types/api.types';
import { supportedLanguages } from 'util/localizationUtils';

const PopupTriggerContainer = styled.div`
  display: inline-block;
  position: relative;
  margin-inline: 5px;
`;

const StyledTextDisplayDiv = styled.div`
  display: inline-block;
  position: absolute;
  font-size: 17px;
  left: 40%;
  transform: translateX(-45%);
  top: 5%;
`;

const StyledPre = styled.div`
  white-space: pre-line;
  font: unset;
`;

const Label = styled.span`
  font-weight: bold;
  font-size: 15px;
`;

const FormBarLabel = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  margin-right: 5px;
`;

const FormBarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ExerciseExportView = () => {
  const [exerciseConfs, setExerciseConfs] = useState<{ [index: string]: ExerciseConfExportDto }>({});
  const [langOptions, setLangOptions] = useState<DropdownItemProps[]>([]);
  const [filteredExerciseConfs, setFilteredExerciseConfs] = useState<{ [index: string]: ExerciseConfExportDto }>({});
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
  const [searchValues, setSearchValues] = useState<Partial<ExerciseConfSearchRequest>>({
    exerciseConfSearchTerm: '',
  });

  const [searchByExerciseResultParams, setSearchByExerciseResultParams] = useState<boolean>(false);
  const [searchByExercises, setSearchByExercises] = useState<boolean>(false);
  const [searchByExerciseResults, setSearchByExerciseResults] = useState<boolean>(false);
  const [searchByAll, setSearchByAll] = useState<boolean>(true);

  const [expandAll, setExpandAll] = useState(false);
  const [expandExercises, setExpandExercises] = useState(false);
  const [expandExerciseResults, setExpandExerciseResults] = useState(false);
  const [expandExerciseResultParams, setExpandExerciseResultParams] = useState(false);

  const currentLanguage = i18n.language;
  const cancelTokenSource = axios.CancelToken.source();
  const { updateLanguage } = useAuthContext();
  const { language } = useAuthContext();
  const { t } = useTranslation('teresa');

  const handleExpandAll = () => {
    const newExpandAll = !expandAll;
    setExpandAll(newExpandAll);
    const updatedExerciseConfs: { [index: string]: ExerciseConfExportDto } = {};
    Object.keys(exerciseConfs).forEach(key => {
      const conf = exerciseConfs[key];
      updatedExerciseConfs[key] = {
        ...conf,
        expanded: newExpandAll,
        exerciseResultMapExpanded: newExpandAll,
        exerciseResultMap: Object.keys(conf.exerciseResultMap).reduce((resultMap, resultKey) => {
          const result = conf.exerciseResultMap[resultKey];
          resultMap[resultKey] = {
            ...result,
            expanded: newExpandAll,
            exerciseResultParamsExpanded: newExpandAll
          };
          return resultMap;
        }, {} as { [index: string]: ExerciseResultExportDto }),
      };
    });
    setExerciseConfs(updatedExerciseConfs);
  };
  const handleExpandExercises = () => {
    const newExpandExercises = !expandExercises;
    setExpandExercises(newExpandExercises);
    const updatedExerciseConfs = { ...exerciseConfs };
    Object.keys(updatedExerciseConfs).forEach(key => {
      updatedExerciseConfs[key] = {
        ...updatedExerciseConfs[key],
        expanded: newExpandExercises,
      };
    });
    setExerciseConfs(updatedExerciseConfs);
  };
  const handleExpandExerciseResults = () => {
    const newExpandExerciseResults = !expandExerciseResults;
    setExpandExerciseResults(newExpandExerciseResults);
    const updatedExerciseConfs = { ...exerciseConfs };
    Object.keys(updatedExerciseConfs).forEach(key => {
      const conf = updatedExerciseConfs[key];
      updatedExerciseConfs[key] = {
        ...conf,
        exerciseResultMapExpanded: newExpandExerciseResults,
        exerciseResultMap: Object.keys(conf.exerciseResultMap).reduce((resultMap, resultKey) => {
          const result = conf.exerciseResultMap[resultKey];
          resultMap[resultKey] = {
            ...result,
            expanded: newExpandExerciseResults,
          };
          return resultMap;
        }, {} as { [index: string]: ExerciseResultExportDto }),
      };
    });
    setExerciseConfs(updatedExerciseConfs);
  };

  const handleExpandExerciseResultParams = () => {
    const newExpandExerciseResultParams = !expandExerciseResultParams;
    setExpandExerciseResultParams(newExpandExerciseResultParams);
    const updatedExerciseConfs = { ...exerciseConfs };
    Object.keys(updatedExerciseConfs).forEach(key => {
      const conf = updatedExerciseConfs[key];
      if (conf.exerciseResultMapExpanded) {
        updatedExerciseConfs[key] = {
          ...conf,
          exerciseResultMap: Object.keys(conf.exerciseResultMap).reduce((resultMap, resultKey) => {
            const result = conf.exerciseResultMap[resultKey];
            if (result.expanded) {
              resultMap[resultKey] = {
                ...result,
                exerciseResultParamsExpanded: newExpandExerciseResultParams,
              };
            } else {
              resultMap[resultKey] = { ...result };
            }
            return resultMap;
          }, {} as { [index: string]: ExerciseResultExportDto }),
        };
      }
    });
    setExerciseConfs(updatedExerciseConfs);
  };

  const searchExerciseConfs = useCallback(() => {
    if (!searchValues.exerciseConfSearchTerm) {
      setFilteredExerciseConfs({ ...exerciseConfs });
      return;
    }

    const newExerciseConfs = { ...exerciseConfs };
    const filtered = Object.keys(newExerciseConfs).filter((key) => {
      const exerciseConf = newExerciseConfs[key];
      const { exerciseResultMap } = exerciseConf;
      let exerciseConfMatches = false;
      let resultMatches = false;
      let resultParamMatches = false;

      if (searchByAll || (searchByExercises && !searchByExerciseResults && !searchByExerciseResultParams)) {
        if (getExerciseConfDescription(exerciseConf).toLowerCase().includes(searchValues.exerciseConfSearchTerm!.toLowerCase())) {
          exerciseConfMatches = true;
        }
      }

      if (searchByAll || (searchByExerciseResults && !searchByExercises && !searchByExerciseResultParams)) {
        Object.keys(exerciseResultMap).forEach(resultKey => {
          const result = exerciseResultMap[resultKey];
          if (getExerciseResultDescription(result).toLowerCase().includes(searchValues.exerciseConfSearchTerm!.toLowerCase())) {
            resultMatches = true;
            exerciseResultMap[resultKey] = { ...result, expanded: true };
          }
        });
      }

      if (searchByAll || searchByExerciseResultParams) {
        Object.keys(exerciseResultMap).forEach(resultKey => {
          const result = exerciseResultMap[resultKey];
          if (result.exerciseResultParamMap && Object.values(result.exerciseResultParamMap).some(param => {
            return getExerciseResultParamDescription(param).toLowerCase().includes(searchValues.exerciseConfSearchTerm!.toLowerCase());
          })) {
            resultParamMatches = true;
            exerciseResultMap[resultKey] = { ...result, exerciseResultParamsExpanded: true, expanded: true };
          }
        });
      }

      if (resultMatches) {
        newExerciseConfs[key] = {
          ...exerciseConf,
          expanded: true,
          exerciseResultMapExpanded: true,
          exerciseResultMap: { ...exerciseResultMap },
        };
      } else if (resultParamMatches){
        newExerciseConfs[key] = {
          ...exerciseConf,
          expanded: true,
          exerciseResultMapExpanded: true,
          exerciseResultMap: { ...exerciseResultMap },
        };
      } else if (exerciseConfMatches) {
        newExerciseConfs[key] = { ...exerciseConf, expanded: true };
      }

      return exerciseConfMatches || resultMatches || resultParamMatches;
    }).reduce((acc, key) => {
      acc[key] = newExerciseConfs[key];
      return acc;
    }, {} as { [index: string]: ExerciseConfExportDto });

    setExerciseConfs(newExerciseConfs);
    setFilteredExerciseConfs(filtered);
  }, [searchValues, searchByAll, searchByExercises, searchByExerciseResults, searchByExerciseResultParams, language, exerciseConfs]);

  const highlightText = (fullText: string, searchTerm: string) => {
    if (!searchTerm) return fullText;
    const escapedSearchTerm = searchTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    const regex = new RegExp(`(${escapedSearchTerm})`, 'gi');
    const parts = fullText.split(regex);
    return parts.map((part, index) =>
      regex.test(part) ? <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span> : part,
    );
  };

  useEffect(() => {
    if (timerId) {
      clearTimeout(timerId);
    }
    const id = setTimeout(searchExerciseConfs, 1000);
    setTimerId(id);

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [searchValues, searchByAll, searchByExercises, searchByExerciseResults, searchExerciseConfs, searchByExerciseResultParams]);

  useEffect(() => {
    const updatedLangOptions = supportedLanguages.map((lang) => ({
      key: lang,
      value: lang,
      text: t(`language.${lang}`),
    }));
    setLangOptions(updatedLangOptions);

    getExerciseConfsExport(cancelTokenSource).then(r => setExerciseConfs(r));
  }, []);

  const instructionContent = (instruction: string) => {
    return (
      <StyledPre>
        {instruction}
      </StyledPre>
    );
  };

  const getExerciseResultParamDescription = (exerciseResultParam: TestResultParamDto) => {
    const texts: { [key: string]: string } = {
      'de': exerciseResultParam.description || '',
      'en': exerciseResultParam.descriptionEn || exerciseResultParam.description || '',
      'fr': exerciseResultParam.descriptionFr || exerciseResultParam.description || '',
      'it': exerciseResultParam.descriptionIt || exerciseResultParam.description || '',
    };
    return texts[language];
  };

  const getDomainDescription = (domain: DomainDto) => {
    const texts: { [key: string]: string } = {
      'de': domain.description || '',
      'en': domain.descriptionEn || domain.description || '',
      'fr': domain.descriptionFr || domain.description || '',
      'it': domain.descriptionIt || domain.description || '',
    };
    return texts[language];
  };

  const getExerciseResultDescription = (exerciseResult: ExerciseResultExportDto) => {
    const texts: { [key: string]: string } = {
      'de': exerciseResult.description || '',
      'en': exerciseResult.descriptionEn || exerciseResult.description || '',
      'fr': exerciseResult.descriptionFr || exerciseResult.description || '',
      'it': exerciseResult.descriptionIt || exerciseResult.description || '',
    };
    return texts[language];
  };

  const getExerciseConfDescription = (exerciseConf: ExerciseConfExportDto) => {
    const texts: { [key: string]: string } = {
      'de': exerciseConf.description || '',
      'en': exerciseConf.descriptionEn || exerciseConf.description || '',
      'fr': exerciseConf.descriptionFr || exerciseConf.description || '',
      'it': exerciseConf.descriptionIt || exerciseConf.description || '',
    };
    return texts[language];
  };

  const getInstructionTherapist = (exerciseConf: ExerciseConfExportDto) => {
    const texts: { [key: string]: string } = {
      'de': exerciseConf.instructionTherapist || '',
      'en': exerciseConf.instructionTherapistEn || exerciseConf.instructionTherapist || '',
      'fr': exerciseConf.instructionTherapistFr || exerciseConf.instructionTherapist || '',
      'it': exerciseConf.instructionTherapistIt || exerciseConf.instructionTherapist || '',
    };
    return texts[language];
  };

  const getInstructionPatient = (exerciseConf: ExerciseConfExportDto) => {
    const texts: { [key: string]: string } = {
      'de': exerciseConf.instructionPatient || '',
      'en': exerciseConf.instructionPatientEn || exerciseConf.instructionPatient || '',
      'fr': exerciseConf.instructionPatientFr || exerciseConf.instructionPatient || '',
      'it': exerciseConf.instructionPatientIt || exerciseConf.instructionPatient || '',
    };
    return texts[language];
  };

  const instructionPopupTrigger = (buttonText: string, instructionTrigger?: boolean) => {

    if (!instructionTrigger) {
      return false;
    }

    return (
      <PopupTriggerContainer>
        <Icon name='circle outline'
              style={{
                fontSize: '1.55rem',
              }}
        >
        </Icon>
        <StyledTextDisplayDiv>
          {buttonText}
        </StyledTextDisplayDiv>
      </PopupTriggerContainer>
    );
  };

  const instructions = (exerciseConf: ExerciseConfExportDto) => {
    return (
      <>
        {
          exerciseConf.instructionTherapist
            ? <Popup
              trigger={instructionPopupTrigger(t('testconf.therapistInstruction'), !!exerciseConf?.instructionTherapist)}
              content={instructionContent(getInstructionTherapist(exerciseConf))}
              on='hover'
              size='large'
              position='bottom right'
              wide='very'
            />
            : <> --- </>
        }
        <> /</>
        {
          exerciseConf.instructionPatient
            ? <Popup
              trigger={instructionPopupTrigger(t('testconf.patientInstruction'), !!exerciseConf?.instructionPatient)}
              content={instructionContent(getInstructionPatient(exerciseConf))}
              on='hover'
              size='large'
              position='bottom right'
              wide='very'
            />
            : <> --- </>
        }

      </>
    );
  };

  const handleExerciseConfOnExpand = (id: string) => {
    setExerciseConfs(prevExerciseConfs => {
      const updatedExerciseConfs = { ...prevExerciseConfs };
      updatedExerciseConfs[id] = {
        ...updatedExerciseConfs[id],
        expanded: !updatedExerciseConfs[id].expanded,
      };
      return updatedExerciseConfs;
    });
  };

  const handleExerciseConfExerciseResultsOnExpand = (id: string) => {
    setExerciseConfs(prevExerciseConfs => {
      const updatedExerciseConfs = { ...prevExerciseConfs };
      updatedExerciseConfs[id] = {
        ...updatedExerciseConfs[id],
        exerciseResultMapExpanded: !updatedExerciseConfs[id].exerciseResultMapExpanded,
      };
      return updatedExerciseConfs;
    });
  };

  const handleExerciseResultOnExpand = (exerciseResultid: string, exerciseConfId: string) => {
    setExerciseConfs(prevExerciseConfs => {
      const updatedExerciseConfs = { ...prevExerciseConfs };
      const updatedExerciseResults = { ...updatedExerciseConfs[exerciseConfId].exerciseResultMap };
      updatedExerciseResults[exerciseResultid] = {
        ...updatedExerciseResults[exerciseResultid],
        expanded: !updatedExerciseResults[exerciseResultid].expanded,
      };
      updatedExerciseConfs[exerciseConfId] = {
        ...updatedExerciseConfs[exerciseConfId],
        exerciseResultMap: { ...updatedExerciseResults },
      };
      return updatedExerciseConfs;
    });
  };

  const handleExerciseResultParamsOnExpand = (exerciseResultid: string, exerciseConfIndex: string) => {
    setExerciseConfs(prevExerciseConfs => {
      const updatedExerciseConfs = { ...prevExerciseConfs };
      const updatedExerciseResults = { ...updatedExerciseConfs[exerciseConfIndex].exerciseResultMap };
      updatedExerciseResults[exerciseResultid] = {
        ...updatedExerciseResults[exerciseResultid],
        exerciseResultParamsExpanded: !updatedExerciseResults[exerciseResultid].exerciseResultParamsExpanded,
      };
      updatedExerciseConfs[exerciseConfIndex] = {
        ...updatedExerciseConfs[exerciseConfIndex],
        exerciseResultMap: { ...updatedExerciseResults },
      };
      return updatedExerciseConfs;
    });
  };

  const renderExerciseResultParamContent = useCallback((exerciseResult: ExerciseResultExportDto) => {
    return Object.keys(exerciseResult.exerciseResultParamMap).map((exerciseResultParamId: string) => {
      const exerciseResultParam: TestResultParamDto = exerciseResult.exerciseResultParamMap[exerciseResultParamId];
      return <div style={{ marginTop: '5px' }}>
        {(searchByExerciseResultParams || searchByAll)
          ? highlightText(
            getExerciseResultParamDescription(exerciseResultParam),
            searchValues.exerciseConfSearchTerm ? searchValues.exerciseConfSearchTerm : '',
          )
          : getExerciseResultParamDescription(exerciseResultParam)} / {exerciseResultParam.unit} / {exerciseResultParam.code}
      </div>;
    });
  }, [exerciseConfs, searchValues.exerciseConfSearchTerm]);

  const renderExerciseResultContent = useCallback((exerciseConf: ExerciseConfExportDto, exerciseConfId: string) => {
    return Object.keys(exerciseConf.exerciseResultMap).map((exerciseResultId: string) => {
      const exerciseResult: ExerciseResultExportDto = exerciseConf.exerciseResultMap[exerciseResultId];
      const paramsMapLength = Object.keys(exerciseResult.exerciseResultParamMap).length;
      return <AccordionWithArrow expanded={exerciseResult.expanded}
                                 onExpand={() => handleExerciseResultOnExpand(exerciseResultId, exerciseConfId)}>
        <CustomTitle>
          {(searchByExerciseResults || searchByAll)
            ? highlightText(getExerciseResultDescription(exerciseResult), searchValues.exerciseConfSearchTerm
              ? searchValues.exerciseConfSearchTerm
              : '',
            )
            : getExerciseResultDescription(exerciseResult)}
          {exerciseResult.domain != null && <> / {getDomainDescription(exerciseResult.domain)}</>}
        </CustomTitle>
        <CustomContent>
          {
            paramsMapLength > 0 &&
            <AccordionWithArrow
              expanded={exerciseResult.exerciseResultParamsExpanded}
              onExpand={() => handleExerciseResultParamsOnExpand(exerciseResultId, exerciseConfId)}
            >
              <CustomTitle>
                <Label>{t('testresult.params')} ({paramsMapLength}) : </Label>
              </CustomTitle>
              <CustomContent>
                {renderExerciseResultParamContent(exerciseResult)}
              </CustomContent>
            </AccordionWithArrow>
          }
        </CustomContent>
      </AccordionWithArrow>;
    });
  }, [exerciseConfs, searchValues.exerciseConfSearchTerm]);

  const renderExerciseConfigRows = useCallback(() => {
    return <>
      {Object.keys(filteredExerciseConfs).map((exerciseConfId) => {
        const exerciseConf = exerciseConfs[exerciseConfId];
        const exerciseResultMapLength = Object.keys(exerciseConf.exerciseResultMap).length;
        return (
          <AccordionWithArrow expanded={exerciseConf.expanded} onExpand={() => handleExerciseConfOnExpand(exerciseConfId)}>
            <CustomTitle>
              {(searchByExercises || searchByAll)
                ? highlightText(getExerciseConfDescription(exerciseConf), searchValues.exerciseConfSearchTerm
                  ? searchValues.exerciseConfSearchTerm
                  : '',
                )
                : getExerciseConfDescription(exerciseConf)}
              <> /</>
              {instructions(exerciseConf)}
            </CustomTitle>
            <CustomContent>
              {exerciseResultMapLength > 0 &&
                <AccordionWithArrow expanded={exerciseConf.exerciseResultMapExpanded}
                                    onExpand={() => handleExerciseConfExerciseResultsOnExpand(exerciseConfId)}>
                  <CustomTitle>
                    <Label>{t('patientExercising.exerciseResultTitle')} ({exerciseResultMapLength}): </Label>
                  </CustomTitle>
                  <CustomContent>
                    {renderExerciseResultContent(exerciseConf, exerciseConfId)}
                  </CustomContent>
                </AccordionWithArrow>
              }
            </CustomContent>
          </AccordionWithArrow>
        );
      })}
    </>;
  }, [exerciseConfs, filteredExerciseConfs, searchValues.exerciseConfSearchTerm]);

  const renderFormBar = () => {
    return (
      <div className='search-form' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div className='title-h1' style={{ paddingLeft: '0.6rem' }}>{t('clinic.config.exerciseConf')}</div>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
          <FormBarLabel>
            <div>{t('testconf.expand')}:</div>
          </FormBarLabel>
          <FormBarContainer>
            <input
              type='checkbox'
              id='expandAll'
              checked={expandAll}
              onChange={handleExpandAll}
            />
            <label htmlFor='expandAll'>{t('sex.ALL')}</label>
          </FormBarContainer>
          <FormBarContainer>
            <input
              type='checkbox'
              id='expandExercises'
              checked={expandExercises}
              onChange={handleExpandExercises}
            />
            <label htmlFor='expandExercises'>{t('clinic.config.exerciseConf')}</label>
          </FormBarContainer>
          <FormBarContainer>
            <input
              type='checkbox'
              id='expandExerciseResults'
              checked={expandExerciseResults}
              onChange={handleExpandExerciseResults}
            />
            <label
              style={{ whiteSpace: 'nowrap' }}
              htmlFor='expandExerciseResults'
            >
              {t('patientExercising.exerciseResultTitle')}
            </label>
          </FormBarContainer>
          <FormBarContainer>
            <input
              type='checkbox'
              id='expandExerciseResultParams'
              checked={expandExerciseResultParams}
              onChange={handleExpandExerciseResultParams}
            />
            <label
              style={{ whiteSpace: 'nowrap' }}
              htmlFor='expandExerciseResultParams'
            >
              {t('patientTesting.params')}
            </label>
          </FormBarContainer>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
          <FormBarLabel>
            <div>{t('button.search')}:</div>
          </FormBarLabel>
          <FormBarContainer>
            <input
              type='checkbox'
              id='searchByAll'
              checked={searchByAll}
              onChange={() => {
                setSearchByExercises(false);
                setSearchByExerciseResults(false);
                setSearchByAll(!searchByAll);
              }}
            />
            <label htmlFor='searchByAll'>{t('sex.ALL')}</label>
          </FormBarContainer>
          <FormBarContainer>
            <input
              type='checkbox'
              id='searchByExercises'
              checked={searchByExercises}
              onChange={() => {
                if (!searchByExerciseResults && searchByExercises && !searchByExerciseResultParams){
                  setSearchByAll(true)
                } else setSearchByAll(false);
                setSearchByExercises(!searchByExercises);
              }}
            />
            <label htmlFor='searchByExercises'>{t('clinic.config.exerciseConf')}</label>
          </FormBarContainer>
          <FormBarContainer>
            <input
              type='checkbox'
              id='searchByExerciseResults'
              checked={searchByExerciseResults}
              onChange={() => {
                if (searchByExerciseResults && !searchByExercises && !searchByExerciseResultParams){
                  setSearchByAll(true)
                } else setSearchByAll(false);
                setSearchByExerciseResults(!searchByExerciseResults);
              }}
            />
            <label
              style={{ whiteSpace: 'nowrap' }}
              htmlFor='searchByExerciseResults'
            >
              {t('patientExercising.exerciseResultTitle')}
            </label>
          </FormBarContainer>
          <FormBarContainer>
            <input
              type='checkbox'
              id='searchByTestResultParams'
              checked={searchByExerciseResultParams}
              onChange={() => {
                if (!searchByExerciseResults && !searchByExercises && searchByExerciseResultParams) {
                  setSearchByAll(true);
                } else setSearchByAll(false);
                setSearchByExerciseResultParams(!searchByExerciseResultParams);
              }}
            />
            <label htmlFor='searchByTestResultParams'>{t('patientTesting.params')}</label>
          </FormBarContainer>
          <Input
            type='text'
            placeholder={t('button.search')}
            value={searchValues.exerciseConfSearchTerm}
            onChange={(e) => setSearchValues({ ...searchValues, exerciseConfSearchTerm: e.target.value })}
          />
          <Dropdown
            style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}
            className='language-menu-item'
            text={t(`language.${currentLanguage}`)}
            direction='left'
            icon='chevron up'
            value={currentLanguage}
            options={langOptions}
            onChange={(evt, data) => {
              const value = data.value as string;
              updateLanguage(value);
            }}
            selectOnBlur
            selectOnNavigation={false}
            fluid
          />
            </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderFormBar()}
      {renderExerciseConfigRows()}
    </div>
  );
};
export default ExerciseExportView;