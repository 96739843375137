import { CancelTokenSource } from 'axios';
import axios, { backendUrls } from './http';

export interface RequestResetPasswordDto {
  email: string
}

export interface SetPasswordDto {
  password: string;
}

const apiUri = `${backendUrls.apiRoot}/password-reset`;

export const validateToken = (token: string, source: CancelTokenSource): Promise<string> => {
  return axios
      .get(`${apiUri}`,{params:{ token: token}, cancelToken: source.token})
      .then(response => response.data);
};

export const requestPasswordReset = (requestResetPasswordDto: RequestResetPasswordDto,
                                     source: CancelTokenSource): Promise<string> => {
  return axios
      .post(apiUri, requestResetPasswordDto, {cancelToken: source.token})
      .then(response => response.data);
};

export const resetPassword = (token: string, setPasswordDto: SetPasswordDto,
                              source: CancelTokenSource): Promise<string> => {
  return axios
      .put(`${apiUri}`, setPasswordDto, {params:{token: token}, cancelToken: source.token})
      .then(response => response.data);
};
