import { useAuthContext } from 'auth/AuthContext';
import TextArea from 'components/final-form/TextArea';
import _ from 'lodash';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { Menu, Tab } from 'semantic-ui-react';
import styled from 'styled-components';
import { required } from 'util/validatorUtils';

const StyledTabMenu = styled(Tab)`
  & .ui.menu {
    margin-bottom: 0;
    min-height: 1.857143em;
  }

  & .ui.secondary.menu .item {
    border-radius: unset;
  }

  & .ui.secondary.menu .active.item {
    border: 1px solid var(--very-very-light-blue);
    border-bottom: unset;
    font-weight: 600;
    opacity: 1;
  }

  & .ui.attached.segment {
    margin: unset;
    width: unset;
    max-width: unset;
    box-shadow: none;
    border: none;
  }

  & .ui.segment {
    padding: 0;
    background: unset;
  }
`;

interface Props {
  fieldName: string;
  form: any;
  rows?: number;
  requiredFirst?: boolean;
}

const MultiLanguageTextArea = (props: Props) => {

  const { fieldName, form, rows = 2, requiredFirst = false } = props;
  const { language } = useAuthContext();

  const formState = form.getState();
  const formValues = formState.values;

  const languages = ['en', 'fr', 'it'];
  let fields = languages.map(l => {
    return { language: l, name: `${fieldName}${_.capitalize(l)}` };
  });

  fields.unshift({ language: 'de', name: `${fieldName}` });

  const [activeIndex, setActiveIndex] = useState<number>(languages.findIndex(l => l === language) + 1);
  const [panes, setPanes] = useState<any[]>([]);

  useLayoutEffect(() => {
    if (language !== 'de') {
      setActiveIndex(languages.findIndex(l => l === language) + 1);
    } else {
      setActiveIndex(0);
    }
  }, [language]);

  const focusInputOnClick = (elementId: string) => {
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        element.focus();
      }
    }, 1);
  };

  const handleTabChange = (e: any, data: any) => {
    setActiveIndex(data.activeIndex);
  };

  useEffect(() => {

    const renderPanes = fields.map((field, index) => {
      const inputValue = formValues[`${field.name}`];

      let style: any = {
        opacity: _.isEmpty(inputValue) && activeIndex !== index ? '.5' : 1,
        borderRadius: "5px 5px 0px 0px"
      };

      if (formState.errors[fieldName] && requiredFirst && index === 0) {
        style = {
          backgroundColor: 'var(--very-very-light-red)',
          borderColor: 'var(--dark-red)',
          color: 'var(--dark-red)',
          border: '1px solid var(--dark-red)',
          borderBottom: 'none',
          borderRadius: "5px 5px 0px 0px"
        };
      }

      const id = `textarea-${field.name}`;

      return (
        {
          menuItem: <Menu.Item
            key={id}
            style={style}
            active={activeIndex === index}
            onClick={(e) => focusInputOnClick(id)}
          >
            {field.language}
          </Menu.Item>,
          pane: <Tab.Pane key={`textarea-tab-${id}`}>
            <Field
              name={field.name}
              component={TextArea}
              className='multilang'
              id={id}
              rows={rows}
              validate={index === 0 && requiredFirst ? required : undefined}
              fluid
            />
          </Tab.Pane>,
        }
      );
    });

    setPanes(renderPanes);

  }, [language, activeIndex, form]);

  const renderContent = () => {
    return <StyledTabMenu
      menu={{ secondary: true }}
      panes={panes}
      renderActiveOnly={false}
      activeIndex={activeIndex}
      onTabChange={handleTabChange}
    />;
  };

  return renderContent();
};

export default MultiLanguageTextArea;
