import CheckBox from 'components/final-form/CheckBox';
import FinalFormColorPicker from 'components/final-form/ColorPicker';
import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import Input from 'components/final-form/Input';
import MultiLanguageInput from 'components/final-form/MultiLanguageInput';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import Select, { stringArrayToDropdownOptionArray } from 'components/final-form/Select';
import TopRowEntityColorPicker from 'components/final-form/TopRowEntityColorPicker';
import InnerTsaGrid from 'components/InnerTsaGrid';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TableContainer from 'components/TableContainerDiv';
import TsaGrid from 'components/TsaGrid';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import { FormApi } from 'final-form';
import arrayMutators from 'final-form-arrays';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Button, Container, Grid, Header, Icon, Table } from 'semantic-ui-react';
import axios from 'service/http';
import { addQuestionSet, editQuestionSet, findQuestionSetById } from 'service/questionSetsServices';
import styled from 'styled-components';
import { QuestionSetType, QuestionType, ScopeType, TrueResultEnum } from 'ts-types/api.enums';
import { QuestionDto, QuestionSetDto, UpsertTrueScoreResultDto } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { noop } from 'util/functionUtils';
import { composeValidators, mustBeNumber, required } from 'util/validatorUtils';

interface Props {
}

const StyledIcon = styled(Icon)`
  margin-right: 0 !important;
`;

const StyledTableHeader = styled(Grid.Column)`
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 11px;
  color: var(--light-blue);
  text-transform: uppercase;
`

const StyledField = styled(Field)`
height: 25px !important;
`

const StyledInputDiv = styled.div`
  input {
    text-align: right !important;
  }
`;

const StyledButton = styled(Button)<Props>`
  &&& {
    border: 1px solid transparent;

    &:hover {
      border-color: var(--secondary-color);
      color: var(--secondary-color);
    }

    &:focus {
      border-color: var(--secondary-color);
      color: var(--secondary-color);
    }
  }
`;

const UpsertQuestionSetForm = (props: Props) => {

  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);
  const { t } = useTranslation('teresa');
  const { state } = useLocation();
  const scopeType: ScopeType = state.scopeType ? state.scopeType : undefined;
  const prevPath: string = state?.prevPath ? state?.prevPath : '/';
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const questionSetId: number | undefined = state?.id ? Number(state.id) : undefined;
  const [successMsg, setSuccessMsg] = React.useState<string | undefined>(undefined);
  const { push } = useHistory();

  const questionSetTypeLabels: Array<string> = Object.values(QuestionSetType);

  const questionSetTypeOptions = useMemo(() =>
    stringArrayToDropdownOptionArray(questionSetTypeLabels, t, 'questionSetType'), []);

  const upsertTrueScoreResultDtos: UpsertTrueScoreResultDto[] = [
    {
      description: '',
      testResultId: undefined,
      questionSetId: questionSetId ? questionSetId : undefined,
      fromScoreRaw: undefined,
      toScoreRaw: undefined,
      trueResult: TrueResultEnum.VERY_MUCH_BELOW_STANDARD,
      trueScoreResultType: undefined,
      active: true,
    },
    {
      description: '',
      testResultId: undefined,
      questionSetId: questionSetId ? questionSetId : undefined,
      fromScoreRaw: undefined,
      toScoreRaw: undefined,
      trueResult: TrueResultEnum.MUCH_BELOW_STANDARD,
      trueScoreResultType: undefined,
      active: true,
    },
    {
      description: '',
      testResultId: undefined,
      questionSetId: questionSetId ? questionSetId : undefined,
      fromScoreRaw: undefined,
      toScoreRaw: undefined,
      trueResult: TrueResultEnum.BELOW_STANDARD,
      trueScoreResultType: undefined,
      active: true,
    },
    {
      description: '',
      testResultId: undefined,
      questionSetId: questionSetId ? questionSetId : undefined,
      fromScoreRaw: undefined,
      toScoreRaw: undefined,
      trueResult: TrueResultEnum.STANDARD,
      trueScoreResultType: undefined,
      active: true,
    },
    {
      description: '',
      testResultId: undefined,
      questionSetId: questionSetId ? questionSetId : undefined,
      fromScoreRaw: undefined,
      toScoreRaw: undefined,
      trueResult: TrueResultEnum.ABOVE_STANDARD,
      trueScoreResultType: undefined,
      active: true,
    },
  ];

  const initialQuestionSetValues: Partial<QuestionSetDto> = {
    type: QuestionSetType.NORMAL,
    active: true,
    upsertTrueScoreResultDto: upsertTrueScoreResultDtos
  };

  const [questionSet, setQuestion] = React.useState<Partial<QuestionSetDto> | undefined>(initialQuestionSetValues);
  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(true);
  const handleError = useCallback((error: any) => {

    if (error) {
      let errMsgs = [];
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.mandatoryFieldsNeedToBeFilledIn
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsgs.push(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          errMsgs.push(t(`error.${errorCode}`));
        } else {
          errMsgs.push(t('error.general'));
        }
      }
      setErrorMessage(errMsgs);
    }

  }, []);

  const handleSubmit = async (values: Partial<QuestionSetDto>) => {

    let saveValues: Partial<QuestionSetDto> = {
      ...values,
      scopeType: scopeType
    };

    if (values.type != QuestionSetType.SCORE) {
      saveValues.upsertTrueScoreResultDto = undefined;
    }

    let questions = saveValues.questions;

    if (questions && questions.length > 0) {
      questions.forEach((q, index) => {
        q.questionOrder = index + 1;
      });

      const onSave = () => {
        questionSetId
          ? setSuccessMsg(t('questionSet.onUpdate'))
          : setSuccessMsg(t('questionSet.onAdd'));
        setTimeout(() => {
          goToPreviousPage();
        }, 1200);
      };

      try {
        if (questionSetId) {
          await editQuestionSet(questionSetId, saveValues, cancelTokenSource);
        } else {
          await addQuestionSet(saveValues, cancelTokenSource);
        }
        onSave();
      } catch (e) {
        handleError(e.response.data);
      }

    } else {
      setErrorMessages([t('error.emptyQuestionSet')]);
    }

  };

  useEffect(() => {
    if (questionSetId) {
      setFormDataLoaded(false);
      findQuestionSetById(questionSetId, cancelTokenSource)
      .then(response => {
        const questionSetDto: Partial<QuestionSetDto> = {
          ...response,
        };
        setQuestion(questionSetDto);
      })
      .catch((e: any) => handleError(e.response.data))
      .finally(() => setFormDataLoaded(true));
    }
  }, []);

  const goToPreviousPage = useCallback(() => {
    push(prevPath);
  }, []);

  const setErrorMessage = (errMsgs?: string[]) => {

    if (errMsgs) {
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const onUpArrowClick = (form: FormApi, index: number) => {
    if (index === 0) {
      return;
    }

    const values = form.getState().values;
    let currentValues: Partial<QuestionSetDto> = { ...values };

    if (currentValues.questions) {
      const currentQuestion = { ...currentValues.questions[index] };
      const previousQuestion = { ...currentValues.questions[index - 1] };

      form.change(`questions[${index}]`, previousQuestion);
      form.change(`questions[${index - 1}]`, currentQuestion);
    }

  };

  const onDownArrowClick = (form: FormApi, index: number) => {
    const values = form.getState().values;
    let currentValues: Partial<QuestionSetDto> = { ...values };

    if (currentValues.questions) {
      if (currentValues.questions.length === index + 1) {
        return;
      }
      const currentQuestion = { ...currentValues.questions[index] };
      const nextQuestion = { ...currentValues.questions[index + 1] };

      form.change(`questions[${index}]`, nextQuestion);
      form.change(`questions[${index + 1}]`, currentQuestion);
    }

  };

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={(values: Partial<QuestionSetDto>) => handleSubmit(values)}
        initialValues={questionSet}
        mutators={{
          ...arrayMutators,
        }}
        subscription={{ pristine: true, submitting: true, submitFailed: true, hasValidationErrors: true, values: true }}
        render={renderFormContent}
      />
    );
  };

  const renderFormContent = ({ handleSubmit, submitting, form, submitFailed, hasValidationErrors, values }: FormRenderProps): React.ReactNode => {

    let trueScoreResults: UpsertTrueScoreResultDto[];

    if (questionSet && questionSet.upsertTrueScoreResultDto && questionSet.upsertTrueScoreResultDto.length > 0) {
      trueScoreResults = questionSet.upsertTrueScoreResultDto;
    } else {
      trueScoreResults = upsertTrueScoreResultDtos;
    }

    let type = false;
    if (questionSet && questionSet.type && values.type == QuestionSetType.SCORE) {
      type = true;
    }

    const questionTypes: Array<String> = ['SINGLE_ANSWER', 'MULTI_ANSWER', 'FREE_TEXT', 'YES_NO', 'NUMBER'];
    const questionTypeOptions = questionTypes.map(questionType => ({
      id: questionType,
      text: t(`questionSet.questions.questionType.${questionType}`),
      value: questionType,
    }));

    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          <Grid.Row>
            <Grid.Column width={16}>
              <div className='title-h1'>{t('questionSet.title')}</div>
            </Grid.Column>
          </Grid.Row>

          {(errorMessages.length > 0 || (submitFailed && hasValidationErrors)) &&
          <Grid.Row>
            <Grid.Column width={11}>
              <div className='error'>
                <StyledErrorMessage
                  onDismiss={submitFailed && hasValidationErrors ? undefined : () => setErrorMessage()}>
                  {errorMessages.length > 0
                    ? errorMessages.map((err: string) => <div key={err}>{err}</div>)
                    : t('error.MANDATORY_FIELDS_NEED_TO_BE_FILLED_IN')
                  }
                </StyledErrorMessage>
              </div>
            </Grid.Column>
          </Grid.Row>
          }

          <Grid.Row>
            <Grid.Column width={16}>
              <Grid.Row>
                <Grid.Column width={16}>


                  <Grid stackable doubling columns={2}>
                    <Grid.Column width={10} style={{ paddingRight: 10 }}>
                      <Grid.Row>
                        <Grid.Column width={7}>
                          <Header as='h4'>{t('questionSet.questions.header')}</Header>
                        </Grid.Column>
                      </Grid.Row>

                      <InnerTsaGrid>
                        <Grid.Row>
                          <Grid.Column width={7}>
                            <DataLabel>{t('questionSet.name')}</DataLabel>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row style={{ marginTop: '-2rem' }}>
                          <Grid.Column width={16}>
                            <MultiLanguageInput fieldName='name' form={form} requiredFirst={true} />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={7}>
                            <DataLabel>{t('questionSet.type')}</DataLabel>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row style={{ marginTop: '-2rem' }}>
                          <Grid.Column width={16}>
                            <Field
                              name='type'
                              component={Select}
                              options={questionSetTypeOptions}
                              onChange={(e: Event, data: any) => {
                                const { value } = data;
                                const newValues: Partial<QuestionSetDto> = { ...form.getState().values, type: value };
                                setFormDataLoaded(false);
                                setTimeout(() => {
                                  setQuestion(newValues);
                                  setFormDataLoaded(true);
                                }, 200);
                              }}
                              fluid
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={7}>
                            <DataLabel>{t('domain.status')}</DataLabel>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row style={{ marginTop: '-2rem' }}>
                          <Grid.Column width={16} textAlign='left'>
                            <Field
                              name='active'
                              component={CheckBox}
                              label={t('questionSet.active')}
                              toggle
                            />
                          </Grid.Column>
                        </Grid.Row>

                      </InnerTsaGrid>
                    </Grid.Column>
                    {type &&
                    <Grid.Column width={6} style={{ paddingRight: 0, height: '300px' }}>
                      <InnerTsaGrid>
                        <Header as='h4'>{t('questionSet.trueScoreRules')}</Header>
                        <Grid.Row>
                          <StyledTableHeader width={4} style={{ textAlign: 'center' }}>
                            {t('questionSet.from')}
                          </StyledTableHeader>
                          <StyledTableHeader width={4} style={{ textAlign: 'center' }}>
                            {t('questionSet.to')}
                          </StyledTableHeader>
                          <StyledTableHeader width={8} style={{ textAlign: 'center' }}>
                            {t('questionSet.trueResult')}
                          </StyledTableHeader>
                          <Grid.Column width={16} verticalAlign='top'>
                            <TableContainer className='scoring-test-container' height={260}>
                              <Table basic='very' striped size='small'>
                                <Table.Body className='table-body'>
                                  {!formDataLoaded
                                    ? <LoaderComponent message={t('testresult.testresultparams.loading')} />
                                    : trueScoreResults.map((trueScoreResult, index) => {

                                      return <Table.Row key={trueScoreResult.id}>
                                        <Table.Cell width={4}>
                                          <StyledInputDiv>
                                            <StyledInputDiv>
                                              <StyledField
                                                fluid
                                                name={`upsertTrueScoreResultDto[${index}].fromScoreRaw`}
                                                placeholder={t('truescoreresult.placeholder')}
                                                component={Input}
                                                validate={required}
                                              />
                                            </StyledInputDiv>
                                          </StyledInputDiv>
                                        </Table.Cell>
                                        <Table.Cell width={4}>
                                          <StyledInputDiv>
                                            <StyledField
                                              fluid
                                              name={`upsertTrueScoreResultDto[${index}].toScoreRaw`}
                                              placeholder={t('truescoreresult.placeholder')}
                                              component={Input}
                                              validate={required}
                                            />
                                          </StyledInputDiv>
                                        </Table.Cell>
                                        <Table.Cell width={8} style={{ textAlign: 'center' }}>
                                          <pre
                                            style={{ margin: 0 }}>&nbsp;{t(`truescoreresult.trueScore.${trueScoreResult.trueResult}`)}</pre>
                                        </Table.Cell>
                                      </Table.Row>;
                                    })}
                                </Table.Body>
                              </Table>
                            </TableContainer>
                          </Grid.Column>
                        </Grid.Row>
                      </InnerTsaGrid>
                    </Grid.Column>
                    }
                  </Grid>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <InnerTsaGrid>

                    <FieldArray name='questions'>
                      {
                        ({ fields }) => {
                          const questionsForm = fields.map((name, index) => {
                            const currentQuestion = fields.value[index];
                            const questionType = currentQuestion.questionType;
                            return (
                              <React.Fragment key={index}>
                                <Grid.Row>
                                  <Grid.Column width={5}>
                                    {index + 1}.
                                    <Icon name={'arrow alternate circle up outline'} size={'small'}
                                          style={{ marginRight: '0.1rem', marginLeft: '0.4rem', fontSize: 'x-large' }}
                                          disabled={index === 0}
                                          onClick={() => onUpArrowClick(form, index)}
                                    />
                                    &nbsp;
                                    <Icon name={'arrow alternate circle down outline'} size={'small'}
                                          disabled={index + 1 === fields.length}
                                          style={{ fontSize: 'x-large' }}
                                          onClick={() => onDownArrowClick(form, index)}
                                    />
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column width={7}>
                                    <DataLabel>{t('questionSet.questions.text')}</DataLabel>
                                  </Grid.Column>
                                </Grid.Row>

                                <Grid.Row style={{ marginTop: '-2rem' }}>
                                  <Grid.Column width={12}>
                                    <MultiLanguageInput fieldName={`${name}.text`} form={form} requiredFirst={true} />
                                  </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                  <Grid.Column width={4}>
                                    <DataLabel>{t('questionSet.questions.details')}</DataLabel>
                                  </Grid.Column>
                                </Grid.Row>

                                <Grid.Row style={{ marginTop: '-2rem' }}>
                                  <Grid.Column width={4}>
                                    <Field
                                      name={`${name}.questionType`}
                                      component={Select}
                                      placeholder={`${t('select')} ${t('question.type')}`}
                                      options={questionTypeOptions}
                                      validate={required}
                                      fluid
                                    />
                                  </Grid.Column>
                                  <Grid.Column width={3}>
                                    <Field
                                      name={`${name}.questionKey`}
                                      component={Input}
                                      placeholder={t('questionSet.questions.key.placeholder')}
                                      fluid
                                    />
                                  </Grid.Column>
                                  <Grid.Column width={4} verticalAlign='middle'>
                                    <TopRowEntityColorPicker>
                                      <label style={{ paddingLeft: '1rem' }}>
                                        {t('common.color')}:
                                      </label>
                                      <Field
                                        id='color'
                                        name={`${name}.color`}
                                        component={FinalFormColorPicker}
                                      />
                                    </TopRowEntityColorPicker>
                                  </Grid.Column>
                                  <Grid.Column width={4}>
                                    <StyledButton
                                      compact
                                      secondary
                                      type='button'
                                      onClick={(event: any) => {
                                        event.preventDefault();
                                        fields.remove(index);
                                      }}>
                                      <StyledIcon name='remove' />
                                      <span>{t('questionSet.questions.button.remove')}</span>
                                    </StyledButton>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Column width={5} />
                                <Grid.Row>
                                  <Grid.Column width={7}>
                                    <DataLabel>{questionType === QuestionType.NUMBER ?
                                      t('questionSet.minMaxValues') : t('questionSet.answers.possible')}
                                    </DataLabel>
                                  </Grid.Column>
                                </Grid.Row>

                                <Grid.Column width={14} style={{ marginTop: '-2rem', paddingLeft: '0' }}>
                                  {renderQuestionPossibleAnswers(name, currentQuestion, form)}
                                </Grid.Column>
                              </React.Fragment>
                            );
                          });
                          return (
                            <React.Fragment>
                              {questionsForm}
                              <Grid.Row>
                                <Grid.Column width={10}>
                                  <StyledButton
                                    compact
                                    secondary
                                    type='button'
                                    onClick={(event: any) => {
                                      event.preventDefault();
                                      if (scopeType === ScopeType.REHAB) {
                                        fields.push({
                                          questionType: 'NUMBER',
                                          questionOrder: fields ? fields.length! + 1 : 1,
                                        });
                                      } else {
                                        fields.push({
                                          questionType: 'YES_NO',
                                          questionOrder: fields ? fields.length! + 1 : 1,
                                        });
                                      }
                                    }}>
                                    <StyledIcon name='add' />
                                    <span>{t('questionSet.questions.button.add')}</span>
                                  </StyledButton>
                                </Grid.Column>
                              </Grid.Row>
                            </React.Fragment>
                          );
                        }
                      }
                    </FieldArray>
                  </InnerTsaGrid>
                </Grid.Column>
              </Grid.Row>


              <Grid.Row>
                <Grid.Column width={12}
                             style={{ borderTop: '1px solid var(--very-light-blue)', marginTop: '2rem', marginBottom: '2rem'  }}
                >
                </Grid.Column>
              </Grid.Row>

              <Grid.Row textAlign='right'>
                <Grid.Column width={16} style={{textAlign: 'right'}}>
                  {
                    successMsg &&
                    <SaveAndUpdateConfirmationMessage>
                      {successMsg}
                    </SaveAndUpdateConfirmationMessage>
                  }
                  <CompositeButton
                    primary
                    type='submit'
                    className='action-button'
                    disabled={submitting || !!successMsg}
                    onClick={noop}
                  >
                    {t('button.save')}
                  </CompositeButton>
                  <CompositeButton
                    type='button'
                    className='action-button'
                    secondary
                    disabled={submitting || !!successMsg}
                    onClick={goToPreviousPage}
                  >
                    {t('action.back')}
                  </CompositeButton>
                </Grid.Column>
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        </TsaGrid>

      </form>
    );
  };

  const renderQuestionPossibleAnswers = (questionPath: string, question: QuestionDto, form: FormApi): React.ReactNode => {

    const questionSetType = form.getState().values.type;

    const questionType = question.questionType;
    switch (questionType) {
      case 'YES_NO':
        return (
          <>
            {t('questionSet.answers.boolean.description')}
          </>
        );
      case 'SINGLE_ANSWER':
      case 'MULTI_ANSWER':
        return (
          <TsaGrid>
            <FieldArray name={`${questionPath}.possibleAnswers`}>
              {
                ({ fields }) => (
                  <>
                    {fields.map((possibleAnswer) => (
                      <Grid.Row key={possibleAnswer}>
                        <Grid.Column width={8}>
                          <MultiLanguageInput
                            fieldName={`${possibleAnswer}.description`}
                            form={form}
                            requiredFirst={true}
                          />
                        </Grid.Column>
                        <Grid.Column width={4} verticalAlign='bottom'>
                          <Field
                            name={`${possibleAnswer}.score`}
                            component={Input}
                            validate={
                              QuestionSetType.SCORE === questionSetType
                                ? composeValidators(required, mustBeNumber)
                                : mustBeNumber
                            }
                            placeholder={t('questionAnswer.score.placeholder')}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    ))
                    }
                    <Grid.Row>
                      <Grid.Column>
                        <StyledButton
                          compact
                          secondary
                          type='button'
                          onClick={(event: any) => {
                            event.preventDefault();
                            fields.push('');
                          }}>
                          <StyledIcon name='add' />
                        </StyledButton>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                )
              }
            </FieldArray>
          </TsaGrid>
        );
      case 'NUMBER':
        return (
          <TsaGrid>
            <Grid.Row style={{ display: 'flex', gap: '20px' }}>
              <Grid.Column width={4}>
                <Field
                  name={`${questionPath}.minimumValue`}
                  component={Input}
                  validate={composeValidators(mustBeNumber)}
                  placeholder={t('questionAnswer.numberMin.placeholder')}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <Field
                  name={`${questionPath}.maximumValue`}
                  component={Input}
                  validate={composeValidators(mustBeNumber)}
                  placeholder={t('questionAnswer.numberMax.placeholder')}
                />
              </Grid.Column>
            </Grid.Row>
          </TsaGrid>
        );
      default:
        return (
          <React.Fragment>
            {t('questionSet.answers.freetext.description')}
          </React.Fragment>
        );
    }
  };

  return (
    <Container fluid>
      <UpsertContentWrapperDiv>
        {formDataLoaded ? renderFinalForm() : <LoaderComponent message={t('questionSet.loading')} />}
      </UpsertContentWrapperDiv>
    </Container>
  );

};

export default UpsertQuestionSetForm;