import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import Input from 'components/final-form/Input';
import CompositeButton from 'components/final-form/CompositeButton';
import InnerTableActionButton from 'components/InnerTableActionButton';
import TabComponent from 'components/final-form/TabComponent';
import SearchDisplayContainer from 'components/SearchDisplayContainer';
import StyledErrorMessage from 'components/StyledErrorMessage';
import VirtualizedTable from 'components/VirtualizedTable';
import { FormState } from 'final-form';
import _ from 'lodash';
import React, { useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Loader } from 'semantic-ui-react';
import { deleteAgeRangeById, searchAgeRange } from 'service/ageRangesServices';
import axios from 'service/http';
import { debounce } from 'ts-debounce';
import {
  AgeRangesDto, AgeRangesSearchRequest,
} from 'ts-types/api.types';
import { useAfterFirstRender } from 'util/functionUtils';
import { emptyTableCell } from 'util/tableUtils';

const cancelTokenSource = axios.CancelToken.source();

interface AgeRangesFieldProps extends FormState<any> {
  values: Partial<AgeRangesSearchRequest>;
}

interface Props {
}

const AgeRangesView = (props: Props) => {

  const { t } = useTranslation('teresa');
  const history = useHistory();

  const [foundAgeRanges, setFoundAgeRanges] = useState<AgeRangesDto[]>([]);
  const [ageRangesLoaded, setAgeRangesLoaded] = useState<boolean>(true);
  const [searchValues, setSearchValues] = useState<Partial<AgeRangesSearchRequest>>({
    searchKey: ''
  });
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  React.useEffect(() => {
    fetchAgeRanges(searchValues);
  }, []);

  const handleError = (error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };

  const setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const handleChange = useAfterFirstRender(({ values }: AgeRangesFieldProps): void => {
    setSearchValues(values);
    fetchAgeRanges(values);
  });

  const fetchAgeRanges = debounce((values: Partial<AgeRangesSearchRequest>): void => {

    const onFinally = () => {
      setAgeRangesLoaded(true);
    };

    setAgeRangesLoaded(false);
    setFoundAgeRanges([]);

    let newSearchValues = { ...values };
    if (_.isEmpty(newSearchValues)) {
      newSearchValues = {
        searchKey: ''
      };
    }

    searchAgeRange(newSearchValues, cancelTokenSource)
    .then(response => {
        setFoundAgeRanges(response);
      },
    )
    .catch((e: any) => handleError(e.response.data))
    .finally(onFinally);

  }, 300);

  const openUpsertAgeRanges = (id?: number): void => {
    history.push("/age-ranges", {id: id})
  };

  const deleteAgeRange = (id: number, values: Partial<AgeRangesSearchRequest>) => (): void => {
    deleteAgeRangeById(id, cancelTokenSource)
    .then(() => {
      fetchAgeRanges(values);
    });
  };

  const renderSearchForm = (): JSX.Element => {
    return (
      <FinalForm
        onSubmit={() => {
        }}
        initialValues={{
          searchKey: ''
        }}
        subscription={{ pristine: true, values: true }}
        render={renderSearchFormContent}
      />
    );
  };

  const renderSearchFormContent = ({ values }: FormRenderProps): React.ReactNode => {

    return (
      <>
        <div className='search-container'>
          <div className='title-h1'>{t('ageRanges.viewTitle')}</div>
          <div className='search-form'>
            <Field
              id='searchKey'
              name='searchKey'
              component={Input}
              icon={'search'}
              iconPosition='left'
              placeholder={t('ageRanges.placeholder')}
              autoFocus
              fluid
            />

            <CompositeButton
              type='button'
              className='action-button'
              onClick={() => openUpsertAgeRanges()}
              primary
            >
              {t('button.add')}
            </CompositeButton>
          </div>
        </div>

        {errorMessages.length > 0 &&
          <div className='error'>
            <StyledErrorMessage onDismiss={() => setErrorMessage()}>
              {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
            </StyledErrorMessage>
          </div>
        }

        <Field
          id='active'
          name='active'
          component={TabComponent}
        />

        <div className='data-table'>
          {ageRangesLoaded && renderAgeRangesTable(values)}
          {!ageRangesLoaded &&
            <Loader className='table-loader' active inline content={t('ageRanges.loading')} />}
        </div>

        <FormSpy subscription={{ values: true }} onChange={handleChange} />
      </>
    );
  };

  const ageRangesRowGetter = ({ index }: any) => {

    Object.assign(foundAgeRanges[index], { index: index + 1 });

    return foundAgeRanges[index];
  };

  const descriptionCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return t(`${cellData}`);
    }
    return emptyTableCell();
  };

  const ageRangesRowRenderer = ({ className, columns, index, key, style }: any) => {
    const a11yProps = { 'aria-rowindex': index + 1 };

    const ageRangeId = foundAgeRanges[index].id;

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role='row'
        style={style}
        onDoubleClick={() => openUpsertAgeRanges(ageRangeId!)}
      >
        {columns}
      </div>
    );
  };

  const renderAgeRangesTable = (values: any): JSX.Element => {
    return (
      <VirtualizedTable
        rowCount={foundAgeRanges.length}
        rowGetter={ageRangesRowGetter}
        rowRenderer={ageRangesRowRenderer}
        columns={[
          {
            width: 50,
            flexShrink: 0,
            label: t('ageRanges.id'),
            dataKey: 'id',
          },
          {
            width: 250,
            flexGrow: 1,
            label: t('ageRanges.description'),
            dataKey: 'description',
            cellRenderer: descriptionCellRenderer
          },
          {
            width: 100,
            label: t('ageRanges.from'),
            dataKey: 'fromAgeRange',
          },
          {
            width: 100,
            label: t('ageRanges.to'),
            dataKey: 'toAgeRange',
          },
          {
            width: 100,
            label: t('ageRanges.status'),
            dataKey: 'active',
            cellRenderer: statusCellRenderer
          },
          {
            width: 200,
            flexShrink: 0,
            label: t('ageRanges.actions'),
            dataKey: 'id',
            cellRenderer: actionsCellRenderer(values),
          },
        ]}
      />
    );
  };

  const statusCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return <Icon name='check' size='small' />;
    }
    return emptyTableCell();
  };

  const actionsCellRenderer = (values: Partial<AgeRangesSearchRequest>) => ({ rowData }: any) => {
    return (
      <div className='row-actions'>
        <InnerTableActionButton
          message={t('button.edit')}
          onConfirm={() => openUpsertAgeRanges(rowData.id)}
          divider={true}
        />
        <DeleteRecordConfirmation triggerButtonText={t('button.delete')}
                                  confirmAction={deleteAgeRange(rowData.id, values)}
                                  deleteConfirmationText={t('ageRanges.confirmDelete', {id: rowData.id})}
                                  position={"top left"} />
      </div>
    );
  };

  return (
    <SearchDisplayContainer>
      {renderSearchForm()}
    </SearchDisplayContainer>
  );
};

export default AgeRangesView;