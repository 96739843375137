import { CancelTokenSource } from 'axios';
import axios, { backendUrls } from 'service/http';
import { ExportType, HideNumbers } from 'ts-types/api.enums';
import {
  ExportTestingDto, ExportTestingRequest,
  PatientTestDto,
  PatientTestingActivityDto,
  PatientTestingActivityResponse,
  PatientTestingDto,
  QuestionAnswerRequest,
  UpsertPatientTestingDto,
  UpsertPatientTestResultDto,
} from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/export-testing`;

export const getExportTestingDto = (patientTestingId: number, source: CancelTokenSource): Promise<ExportTestingDto> => {
  return axios
  .get(`${apiUri}/${patientTestingId}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const downloadPatientTestingExportFile = (
  patientTestingId: number,
  hideNumbers: HideNumbers,
  exportType: ExportType,
  exportRequest: Partial<ExportTestingRequest>,
  source: CancelTokenSource,
  comparePatientTestingId?: number): Promise<any> => {
  const params: any = {
    patientTestingId,
    hideNumbers,
    exportType,
  };

  if (comparePatientTestingId !== undefined) {
    params.comparePatientTestingId = comparePatientTestingId;
  }
  return axios
  .post(`${apiUri}/download`, exportRequest,
    {
      params: params,
      cancelToken: source.token,
      responseType: 'blob'
    })
  .then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download',
      response.headers['content-disposition'].split('filename=')[1]);
    document.body.appendChild(link);
    link.click();

    return true;
  });
};

