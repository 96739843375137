import { useAuthContext } from 'auth/AuthContext';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TwoStepAuthModal from 'routes/init-2fa/TwoStepAuthModal';
import CompositeButton from 'components/final-form/CompositeButton';
import styled from 'styled-components';
import { applyStyles } from 'util/localizationUtils';

const ContainerDiv = styled.div`

  .info-box {
    font-size: 1.1589rem;
    color: black;
    max-width: 40rem;
    margin-bottom: 1.5rem;
  }

  .actions-row {
    margin-top: 2rem;

    .ui.button + .ui.button {
      margin-left: 1rem;
    }
  }
`;

const Init2fa = () => {

  const { t } = useTranslation('teresa');
  const { setSkip2faInit } = useAuthContext();
  const { push } = useHistory();
  const { goBack } = useHistory();

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onCloseModal = () => {
    setModalOpen(false);
  };

  const onInit2faSuccess = () => {
    onCloseModal();
    goToPreviousPage();
  };

  const goToPreviousPage = useCallback(() => {
    goBack();
  }, [goBack]);

  return <ContainerDiv>
    <div className='title-h1'>{t('init2fa.title')}</div>
    <div key='info-box' className='info-box'>{applyStyles(t('init2fa.info'))}</div>

    <div key='actions-box' className='actions-row'>
      <CompositeButton
        type='button'
        className='action-button'
        size='large'
        primary
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {t('init2fa.action.setup2fa')}
      </CompositeButton>

      <CompositeButton
        type='button'
        className='action-button'
        size='large'
        style={{ whiteSpace: 'nowrap' }}
        secondary
        onClick={goToPreviousPage}
      >
        {t('init2fa.action.continueWout2fa')}
      </CompositeButton>
    </div>

    {modalOpen &&
      <TwoStepAuthModal
        key='init-2fa-modal'
        modalOpen={modalOpen}
        onCloseModal={onCloseModal}
        onInit2faSuccess={onInit2faSuccess}
      />
    }
  </ContainerDiv>;
};

export default Init2fa;
