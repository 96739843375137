import axios from 'axios';
import CheckBox from 'components/final-form/CheckBox';
import DataLabel from 'components/final-form/DataLabel';
import Input from 'components/final-form/Input';
import MultiLanguageInput from 'components/final-form/MultiLanguageInput';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import CompositeButton from 'components/final-form/CompositeButton';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import { noop } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Header } from 'semantic-ui-react';
import { addDomain, editDomain, getDomainById } from 'service/domainService';
import { UpsertDomainDto } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { required } from 'util/validatorUtils';

interface Props {

}

const UpsertDomainForm = (props: Props) => {

  const cancelTokenSource = useMemo(axios.CancelToken.source, []);

  const { t } = useTranslation('teresa');
  const { push } = useHistory();
  const { state } = useLocation();
  const domainId: number | undefined = state?.id ? Number(state.id) : undefined;
  const isIpad = useIsIpadWidthOrBelow();


  const initialDomainValues: Partial<UpsertDomainDto> = {
    active: true,
  };

  const [domain, setDomain] = React.useState<Partial<UpsertDomainDto> | undefined>(initialDomainValues);
  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(true);
  const [successMsg, setSuccessMsg] = React.useState<string | undefined>(undefined);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);


  useEffect(() => {
    if (domainId) {
      setFormDataLoaded(false);
      getDomainById(domainId, cancelTokenSource)
      .then(response => {
        const upsertDomainDto: Partial<UpsertDomainDto> = {
          ...response,
        };
        setDomain(upsertDomainDto);
      })
      .catch((e: any) => handleError(e.response.data))
      .finally(() => setFormDataLoaded(true));
    }
  }, []);

  const handleError = useCallback((error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.invalidInput,
        errorUtils.domainNotFound
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }

  }, []);


  const setErrorMessage = (errorMessage?: string) => {
    if (errorMessage) {
      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const handleSubmit = useCallback(async (values: Partial<UpsertDomainDto>) => {

    let saveValues: Partial<UpsertDomainDto> = {
      ...values,
    };

    const onSave = () => {
      domainId
        ? setSuccessMsg(t('domain.onUpdate'))
        : setSuccessMsg(t('domain.onAdd'));
      setTimeout(() => {
        goToPreviousPage();
      }, 1200);
    };

    if (domainId) {
      await editDomain(domainId, saveValues, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e.response.data));
    } else {
      await addDomain(saveValues, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e.response.data));
    }


  }, []);


  const goToPreviousPage = useCallback(() => {
    let domainSearch = '/domain/search';

    push(domainSearch);

  }, []);

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={(values: UpsertDomainDto) => handleSubmit(values)}
        initialValues={domain}
        subscription={{ pristine: true, submitting: true }}
        render={(formProps: FormRenderProps<UpsertDomainDto>) => renderFormContent(formProps)}
      />
    );
  };

  const renderFormContent = ({ handleSubmit, submitting, form }: FormRenderProps<UpsertDomainDto>): React.ReactNode => {
    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          <Grid.Row>
            <Grid.Column width={16}>
              <div className='title-h1'>{t('domain.viewTitle')}</div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16}>
              <Grid>

                {
                  errorMessages.length > 0 &&
                  <Grid.Row>
                    <Grid.Column width={12}>
                      <div className='error'>
                        <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                          {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                        </StyledErrorMessage>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                }

                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as='h4'>{t('domain.generalInfo')}</Header>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={isIpad ? 12 : 6}>
                    <DataLabel>{t('domain.description')}</DataLabel>
                    <MultiLanguageInput fieldName='description' form={form} requiredFirst={true} />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={isIpad ? 12 : 6}>
                    <DataLabel>{t('domain.orderIndex')}</DataLabel>
                    <Field
                      name={'orderIndex'}
                      component={Input}
                      validate={required}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={6} textAlign='left'>
                    <DataLabel>{t('domain.status')}</DataLabel>
                    <Field
                      name='active'
                      component={CheckBox}
                      label={t('domain.active')}
                      toggle
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={12}
                               style={{ borderTop: '1px solid #d4d9e0', marginTop: '2rem' }}
                  >
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row textAlign={'right'}>
            <Grid.Column width={12} style={{ paddingRight: '0' }}>
              {
                successMsg &&
                <SaveAndUpdateConfirmationMessage>
                  {successMsg}
                </SaveAndUpdateConfirmationMessage>
              }
              <CompositeButton
                primary
                type='submit'
                className='action-button'
                disabled={submitting || !!successMsg}
                onClick={noop}
              >
                {t('button.save')}
              </CompositeButton>
              <CompositeButton
                type='button'
                className='action-button'
                secondary
                disabled={submitting || !!successMsg}
                onClick={goToPreviousPage}
              >
                {t('action.back')}
              </CompositeButton>
            </Grid.Column>
          </Grid.Row>
        </TsaGrid>
      </form>
    );
  };


  return (
    <UpsertContentWrapperDiv>
      {formDataLoaded
        ? <React.Fragment>
          {renderFinalForm()}
        </React.Fragment>
        : <LoaderComponent message={t('domain.loading')} />
      }
    </UpsertContentWrapperDiv>
  );

};
export default UpsertDomainForm;