import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import DataLabel from 'components/final-form/DataLabel';
import { generateRadioButtonOptions } from 'components/final-form/Radio';
import { radioGroupInput } from 'components/final-form/RadioGroupInput';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import { DropdownOption, mapToDropdownOptionArrayWithTextGetter } from 'components/final-form/Select';
import { selectInput } from 'components/final-form/SelectComponent';
import CompositeButton from 'components/final-form/CompositeButton';
import { textAreaInput } from 'components/final-form/TextAreaInput';
import InnerFormTable from 'components/InnerFormTable';
import InnerTableActionButton from 'components/InnerTableActionButton';
import InnerTsaGrid from 'components/InnerTsaGrid';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import { useTestResultFormDataContext } from 'context/TestResultFormDataContext';
import { FormApi } from 'final-form';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Grid, Header, RadioProps } from 'semantic-ui-react';
import { getAgeRanges } from 'service/ageRangesServices';
import { getEducationalYears } from 'service/educaionalYearsServices';
import { editRawScoreResult, validateFormula } from 'service/rawScoreResultService';
import { findAllAvailableParameters } from 'service/testResultParamServices';
import { Sex } from 'ts-types/api.enums';
import {
  AgeRangesDto,
  AvailableParamsRequest,
  EducationalYearsDto,
  TestResultParamDto,
  UpsertRawScoreResultDto,
  ValidateRawScoreFormulaDto,
} from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { required } from 'util/validatorUtils';

interface Props {
}

const cancelTokenSource = axios.CancelToken.source();

const UpsertRawScoreResultForm = (props: Props) => {

  const sexLabels: Array<Sex> = Object.values(Sex);
  const { state } = useLocation();
  const { language } = useAuthContext();
  const testConfId: number | undefined = state?.testConfId ? Number(state?.testConfId) : undefined;
  const testResultId: number | undefined = state?.testResultId ? Number(state?.testResultId) : undefined;
  const scoreRulesType: string | undefined = state?.scoreRulesType ? String(state?.scoreRulesType) : undefined;
  const rawScoreResultIx: number | undefined = state.rawScoreResultIx >= 0 ? Number(state.rawScoreResultIx) : -1;

  const [ageRanges, setAgeRanges] = useState<AgeRangesDto[]>([]);
  const [ageRangesOptions, setAgeRangesOptions] = useState<Array<DropdownOption>>([]);
  const [educationalYears, setEducationalYears] = useState<EducationalYearsDto[]>([]);
  const [educationalYearsOptions, setEducationalYearsOptions] = useState<Array<DropdownOption>>([]);

  const { t } = useTranslation('teresa');
  const history = useHistory();
  const {
    testResultFormData,
    testConfDescriptions,
    addOrEditRawScoreResult,
  } = useTestResultFormDataContext();

  const [rawScoreResult, setRawScoreResult] = useState<Partial<UpsertRawScoreResultDto> | undefined>();
  const [testResultParams, setTestResultParams] = useState<TestResultParamDto[]>([]);

  const [genders, setGenders] = React.useState<RadioProps[]>(
    generateRadioButtonOptions(sexLabels, 'sex', t, 'sex', sexLabels));

  useEffect(() => {
    const ageRangeOptArray = mapToDropdownOptionArrayWithTextGetter(ageRanges, ageRange => `${ageRange.fromAgeRange} - ${ageRange.toAgeRange}`, 'id');
    setAgeRangesOptions(ageRangeOptArray);
  }, [ageRanges, language]);

  useEffect(() => {
    const educationalYearOptArray = mapToDropdownOptionArrayWithTextGetter(educationalYears, educationalYear => `${educationalYear.fromYear} - ${educationalYear.toYear}`, 'id');
    setEducationalYearsOptions(educationalYearOptArray);
  }, [educationalYears]);

  const [successMsg, setSuccessMsg] = React.useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {

    setFormDataLoaded(false);

    const ageRangesResponse = await getAgeRanges(cancelTokenSource);
    setAgeRanges(ageRangesResponse);

    const educationalYearsResponse = await getEducationalYears(cancelTokenSource);
    setEducationalYears(educationalYearsResponse);

    if (testResultFormData && testResultFormData.testResultParams && testConfId) {
      const testResultParamIdList: Array<number> = testResultFormData.testResultParams.map(p => p.testResultParamId);

      const request: AvailableParamsRequest = {
        testConfId: testConfId,
        testResultTestResultParamIds: testResultParamIdList,
        includeSystemParams: true,
      };
      const testResultParams = await findAllAvailableParameters(request, cancelTokenSource);
      setTestResultParams(testResultParams);
    }

    if (rawScoreResultIx >= 0 && testResultFormData) {
      const rawScores = testResultFormData.rawScores;
      if ((rawScoreResultIx >= 0) && rawScores) {
        setRawScoreResult(rawScores[rawScoreResultIx]);
      }
    }

    setFormDataLoaded(true);
  };

  const handleError = useCallback((error: any) => {
    if (error) {
      let errMsg = undefined;
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.invalidFormula,
        errorUtils.rawScoreResultNotFound,
        errorUtils.testResultNotFound,
        errorUtils.missingParameters,
      ];

      const violations: Array<any> = error.violations;
      let hasError = false;
      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode) && !hasError) {
            hasError = true;
            setErrorMessage(t(`error.${violation.errorCode}`, {errorDetail: violation.details}));
          }
        });
      }

      if (!hasError) {
        if (knownErrors.includes(errorCode)) {
          errMsg = (t(`error.${errorCode}`));
        } else {
          errMsg = (t('error.general'));
        }
        setErrorMessage(errMsg);
      }

    }

  }, []);

  const goToPreviousPage = () => {
    history.push('/testresult', { testResultId: testResultId, testConfId: testConfId, scoreRulesType: scoreRulesType });
  };

  const submitHandler = async (values: Partial<UpsertRawScoreResultDto>) => {

    let validationDto: ValidateRawScoreFormulaDto = {
      testConfId: testConfId!,
      formula: values.formula!,
      testResultParamIds: testResultFormData!.testResultParams!.map(trp => trp.testResultParamId),
    };

    try {
      await validateFormula(validationDto, cancelTokenSource);
    } catch (e) {
      handleError(e.response.data);
      return;
    }

    const ageRange = ageRanges.find(a => a.id === values.ageRangeId);
    const eduYears = educationalYears.find(e => e.id === values.educationalYearId);

    let saveValues: Partial<UpsertRawScoreResultDto> = {
      ...values,
      sex: values.sex,
      testResultId: testResultId,
      testConfId: testConfId,
      fromAge: ageRange ? ageRange.fromAgeRange : undefined,
      toAge: ageRange ? ageRange.toAgeRange : undefined,
      fromEducationalYear: eduYears ? eduYears.fromYear : undefined,
      toEducationalYear: eduYears ? eduYears.toYear : undefined,
    };

    const onSave = () => {
      rawScoreResultIx >= 0
        ? setSuccessMsg(t('testresult.onUpdate'))
        : setSuccessMsg(t('testresult.onAdd'));
      setTimeout(() => {
        if (testResultId) {
          history.push('/testresult', { testResultId: testResultId, testConfId: testConfId, scoreRulesType: scoreRulesType });
        } else {
          history.push('/testresult', { testResultId: testResultId, testConfId: testConfId, scoreRulesType: scoreRulesType });
        }
      }, 1200);
    };

    addOrEditRawScoreResult(rawScoreResultIx, saveValues, testResultParams, onSave);
  };

  const addParamToFormula = (code: string, form: FormApi<UpsertRawScoreResultDto>) => {

    const values = form.getState().values;
    const currentFormula = values.formula;

    if (currentFormula) {
      form.change('formula', `${currentFormula}` + '$' + `${code}`);
    } else {
      form.change('formula', '$' + `${code}`);
    }

    // @ts-ignore
    document.getElementById('formula-input').focus();
  };

  const testConfAndResultDescHeader = () => {
    let headerAddition = '';
    let testConfDesc = '';
    let testResultDesc = '';
    if (testConfDescriptions && testConfDescriptions.description) {
      const testConfDescMap: { [lang: string]: string } =
        {
          'de': testConfDescriptions.description,
          'en': testConfDescriptions.descriptionEn || testConfDescriptions.description,
          'fr': testConfDescriptions.descriptionFr || testConfDescriptions.description,
          'it': testConfDescriptions.descriptionIt || testConfDescriptions.description,
        };

      testConfDesc = testConfDescMap[language];
    }

    if (testResultFormData && testResultFormData.description) {
      const testResultDescMap: { [lang: string]: string } =
        {
          'de': testResultFormData.description,
          'en': testResultFormData.descriptionEn || testResultFormData.description,
          'fr': testResultFormData.descriptionFr || testResultFormData.description,
          'it': testResultFormData.descriptionIt || testResultFormData.description,
        };

      testResultDesc = testResultDescMap[language];
    }

    if (testConfDesc) {
      headerAddition = testConfDesc;
    }

    if (testResultDesc) {
      if (_.isEmpty(headerAddition)) {
        headerAddition = testResultDesc;
      } else {
        headerAddition = `${headerAddition} > ${testResultDesc}`
      }
    }

    if (!_.isEmpty(headerAddition)) {
      headerAddition = `(${headerAddition})`
    }

    return headerAddition;
  };

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={(values: Partial<UpsertRawScoreResultDto>) => submitHandler(values)}
        initialValues={rawScoreResult ? rawScoreResult : { sex: Sex.ALL }}
        subscription={{ pristine: true, submitting: true, values: true }}
        render={(formProps: FormRenderProps<UpsertRawScoreResultDto>) => renderFormContent(formProps)}
      />
    );
  };

  const renderInformationSection = (form: FormApi<UpsertRawScoreResultDto>) => {

    const leftInfoFields = [
      selectInput({
          name: 'ageRangeId',
          clearable: true,
        },
        ageRangesOptions,
      ),
      selectInput({
          name: 'educationalYearId',
          clearable: true,
        },
        educationalYearsOptions,
      ),
      radioGroupInput({ name: 'sex' }, genders, '25%'),
    ];

    const rightInfoFields = [
      textAreaInput({
        name: 'formula',
        validate: required,
        id: 'formula-input',
      }),
    ];

    const mappedLeftRow = leftInfoFields.map((field, index) => {
      return (
        <React.Fragment key={index}>
          <Grid.Row className='scoreLabel'>
            <Grid.Column width={16} verticalAlign='middle'>
              <DataLabel>{t(`rawscoreresult.${field.name}`)}</DataLabel>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16} verticalAlign='middle' className='rawScoreInput'>
              <Field
                fluid
                {...field}
              />
            </Grid.Column>
          </Grid.Row>
        </React.Fragment>
      );
    });

    const mappedRightRow = rightInfoFields.map((field, index) => {
      return (
        <React.Fragment key={index}>
          <Grid.Row className='scoreLabel'>
            <Grid.Column width={16} verticalAlign='middle'>
              <DataLabel>{t(`rawscoreresult.${field.name}`)}</DataLabel>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Field
                fluid
                {...field}
                style={{ minHeight: '130px' }}
              />
            </Grid.Column>
          </Grid.Row>
        </React.Fragment>
      );
    });

    return (
      <Grid.Row>
        <Grid.Column width={6}>
          <InnerTsaGrid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header as='h4'>{t(`rawscoreresult.conditionsTitle`)}</Header>
              </Grid.Column>
            </Grid.Row>
            {mappedLeftRow}
          </InnerTsaGrid>
        </Grid.Column>
        <Grid.Column width={10}>
          <InnerTsaGrid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header as='h4'>{t(`rawscoreresult.calculationTitle`)}</Header>
              </Grid.Column>
            </Grid.Row>
            {mappedRightRow}
            <Grid.Row>
              <Grid.Column width={16}>
                {renderTestResultParamsTable(form)}
              </Grid.Column>
            </Grid.Row>
          </InnerTsaGrid>
        </Grid.Column>
      </Grid.Row>
    );
  };

  const renderFormContent = (
    { handleSubmit, submitting, form }: FormRenderProps<UpsertRawScoreResultDto>): React.ReactNode => {
    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          <Grid.Row>
            <Grid.Column width={16}>
              <div className='title-h1'>
                {t('rawscoreresult.viewTitle')} <span className='addition'>{testConfAndResultDescHeader()}</span>
              </div>
            </Grid.Column>
          </Grid.Row>

          {errorMessage &&
            <Grid.Row>
              <Grid.Column width={16}>
                <div className='error'>
                  <StyledErrorMessage onDismiss={() => setErrorMessage(undefined)}>
                    {errorMessage}
                  </StyledErrorMessage>
                </div>
              </Grid.Column>
            </Grid.Row>
          }

          <Grid.Row>
            <Grid.Column width={16}>
              <Grid stackable doubling columns={2}>
                {renderInformationSection(form)}
                <Grid.Row>
                  <Grid.Column width={16}
                               style={{ borderTop: '1px solid var(--very-light-blue)', marginTop: '2rem' }}
                  >
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row textAlign='right'>
            <Grid.Column width={16} style={{ paddingRight: '0' }}>
              {
                successMsg &&
                <SaveAndUpdateConfirmationMessage>
                  {successMsg}
                </SaveAndUpdateConfirmationMessage>
              }
              <CompositeButton
                primary
                type='submit'
                className='action-button'
                disabled={submitting || !!successMsg}
              >
                {t('button.save')}
              </CompositeButton>
              <CompositeButton
                type='button'
                className='action-button'
                secondary
                disabled={submitting || !!successMsg}
                onClick={goToPreviousPage}
              >
                {t('action.back')}
              </CompositeButton>
            </Grid.Column>
          </Grid.Row>

        </TsaGrid>
      </form>
    );
  };

  const actionCellRenderer = (form: FormApi<UpsertRawScoreResultDto>) => (rowData: any) => {
    return <InnerTableActionButton
      message={t('button.add')}
      onConfirm={() => addParamToFormula(rowData.code, form)}
    />;
  };

  const renderTestResultParamsTable = (form: FormApi<UpsertRawScoreResultDto>) => {

    return (
      <Grid.Row>
        <Grid.Column width={7} verticalAlign='middle'>
          <InnerFormTable id='test-result' numOfCells={4} values={testResultParams} visibleRows={6}
                          columns={[
                            {
                              width: 8,
                              flexgrow: 1,
                              label: t('testresult.description'),
                              dataKey: 'description',
                            },
                            {
                              width: 3,
                              label: t('testresult.unit'),
                              dataKey: 'unit',
                            },
                            {
                              width: 3,
                              label: t('testresult.code'),
                              dataKey: 'code',
                            },
                            {
                              width: 2,
                              label: t('button.add'),
                              dataKey: 'code',
                              cellRenderer: actionCellRenderer(form),
                            },
                          ]}
          />
        </Grid.Column>
      </Grid.Row>
    );
  };

  return (
    <UpsertContentWrapperDiv>
      {formDataLoaded
        ? <React.Fragment>
          {renderFinalForm()}
        </React.Fragment>
        : <LoaderComponent message={t('rawscoreresult.loading')} />
      }
    </UpsertContentWrapperDiv>
  );

};

export default UpsertRawScoreResultForm;