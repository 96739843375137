import axios from 'axios';
import Input from 'components/final-form/Input';
import CompositeButton from 'components/final-form/CompositeButton';
import InnerTableActionButton from 'components/InnerTableActionButton';
import LoaderComponent from 'components/LoaderComponent';
import TsaGrid from 'components/TsaGrid';
import { FormState } from 'final-form';
import React, { useCallback, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Table } from 'semantic-ui-react';
import { searchTestConfs } from 'service/testconfService';
import styled from 'styled-components';
import { debounce } from 'ts-debounce';
import { TestConfDto, TestConfSearchRequest } from 'ts-types/api.types';
import { emptyTableRows } from 'util/tableUtils';

const TableContainer = styled.div`
  flex-grow: 1;
  cursor: context-menu;
  max-height: 180px;
  overflow: auto;
  border-radius: unset;
  border: 1px solid var(--light-gray) !important;

  & .ui.table tr {
    height: 30px;
    border: none;
  }

  .ui.table tr td {
    border-top: none;
  }

  .no-padding {
    border: 1px solid var(--light-gray) !important;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 1rem;
  }

  .ui.basic.striped.table tbody tr:nth-child(2n) {
    background-color: var(--very-light-gray) !important;
  }

`;

const ActionContainer = styled.div`
  flex-grow: 1;
  margin-top: 1rem;
  border-radius: unset;

  .ui.secondary.button, .ui.secondary.buttons .button {
    background-color: var(--secondary-color);

    :hover {
      background-color: transparent;
      border-color: var(--secondary-color);
      color: var(--secondary-color);
    }

    :focus {
      background-color: transparent;
      border-color: var(--secondary-color);
      color: var(--secondary-color);
    }
  }
`;

interface Props {
  selectedTestConfs: number[];
  onAddTestConf: (testConfId: number) => void;
  onCancel: () => void;
}

const cancelTokenSource = axios.CancelToken.source();

interface TestConfTestImageFieldProps extends FormState<any> {
  values: Partial<TestConfSearchRequest>;
}

const TestImageAddTestConfModal = (props: Props) => {

  const { t } = useTranslation('teresa');

  const { onCancel, selectedTestConfs, onAddTestConf } = props;

  const [availableTestConfs, setAvailableTestConfs] = useState<TestConfDto[]>([]);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const addTestConf = (testConfId: number, values: TestConfSearchRequest) => {
    onAddTestConf(testConfId);
    searchTestConfigs(values, testConfId);
  };

  const handleError = useCallback((error: any) => {

    if (error) {
      let errMsgs = [];
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsgs.push(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          errMsgs.push(t(`error.${errorCode}`));
        } else {
          errMsgs.push(t('error.general'));
        }
      }

      setErrorMessage(errMsgs);
    }

  }, []);

  const setErrorMessage = (errMsgs?: string[]) => {

    if (errMsgs) {
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const handleChange = ({ values }: TestConfTestImageFieldProps) => {
    searchTestConfigs(values);
  };

  const searchTestConfigs = useCallback(debounce(async (
    values: Partial<TestConfSearchRequest>,
    testConfId?: number) => {

    setDataLoaded(false);
    setAvailableTestConfs([]);

    let request: TestConfSearchRequest = {
      testConfSearchTerm: values.testConfSearchTerm ? values.testConfSearchTerm : '',
      active: true,
    };

    try {
      let testConfigs = await searchTestConfs(request, cancelTokenSource);

      const selectedTestConfIds = selectedTestConfs ? [...selectedTestConfs] : [];

      const selectedTestConfigs = testConfId ? [...selectedTestConfIds, testConfId] : selectedTestConfIds;

      testConfigs = testConfigs.filter(tc => !selectedTestConfigs.includes(tc.id));
      setAvailableTestConfs(testConfigs);
    } catch (e) {
      handleError(e.response.data);
    } finally {
      setDataLoaded(true);
    }

  }, 300), [selectedTestConfs]);

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={() => {}}
        initialValues={{}}
        subscription={{ pristine: true, values: true }}
        render={renderTable}
      />
    );
  };

  const renderTable = ({ values }: FormRenderProps<TestConfSearchRequest>) => {

    return (
      <TsaGrid>
        <Grid.Row>
          <Grid.Column width={5} floated='right'>
            <Field
              id='testConfSearchTerm'
              name='testConfSearchTerm'
              component={Input}
              icon={'search'}
              iconPosition='left'
              placeholder={t('testImage.search.placeholder')}
              autoFocus
              fluid
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {!dataLoaded
            ? <LoaderComponent message={t('testImage.loading')} />
            : <Grid.Column width={16} verticalAlign='middle' className='no-padding'>
              <TableContainer>
                <Table basic='very' striped size='small'>
                  <Table.Body className='table-body'>
                    {availableTestConfs.map(testConf => {
                      return <Table.Row key={testConf.id}>
                        <Table.Cell width={4} flexgrow={1}>
                          {testConf.description}
                        </Table.Cell>
                        <Table.Cell width={2}>
                          <div className='row-actions'>
                            <InnerTableActionButton
                              message={t('button.add')}
                              onConfirm={() => addTestConf(testConf.id, values)} />
                          </div>
                        </Table.Cell>
                      </Table.Row>;
                    })}
                    {emptyTableRows(availableTestConfs.length, 2)}
                  </Table.Body>
                </Table>
              </TableContainer>
            </Grid.Column>}
          <Grid.Column width={16}>
            <ActionContainer>
              <CompositeButton
                type='button'
                className='action-button'
                style={{ whiteSpace: 'nowrap' }}
                secondary
                floated='right'
                onClick={onCancel}
              >
                {t('button.close')}
              </CompositeButton>
            </ActionContainer>
          </Grid.Column>
        </Grid.Row>
        <FormSpy subscription={{ values: true }} onChange={handleChange} />
      </TsaGrid>
    );
  };

  return <>
    {renderFinalForm()}
  </>;
};

export default TestImageAddTestConfModal;