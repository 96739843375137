import axios from 'axios';
import Input from 'components/final-form/Input';
import CompositeButton from 'components/final-form/CompositeButton';
import InnerTableActionButton from 'components/InnerTableActionButton';
import LoaderComponent from 'components/LoaderComponent';
import TsaGrid from 'components/TsaGrid';
import { useExerciseConfFormDataContext } from 'context/ExerciseConfFormDataContext';
import { FormState } from 'final-form';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Table } from 'semantic-ui-react';
import { searchTestResultParamsForTestResult } from 'service/testResultParamServices';
import styled from 'styled-components';
import { debounce } from 'ts-debounce';
import { ScopeType } from 'ts-types/api.enums';
import { ParamSearchRequest, TestResultParamDto } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { useAfterFirstRender } from 'util/functionUtils';
import { emptyTableRows } from 'util/tableUtils';

const TableContainer = styled.div`
  flex-grow: 1;
  cursor: context-menu;
  max-height: 180px;
  overflow: auto;
  border-radius: unset;
  border: 1px solid var(--light-gray) !important;

  & .ui.table tr {
    height: 30px;
    border: none;
  }

  .ui.table tr td {
    border-top: none;
  }

  .no-padding {
    border: 1px solid var(--light-gray) !important;
    padding-left: 0;
    padding-right: 0;
    margin-left: 1rem;
  }

  .ui.basic.striped.table tbody tr:nth-child(2n) {
    background-color: var(--very-light-gray) !important;
  }

  .header-cell {
    padding: 10px;
  }
`;

const ActionContainer = styled.div`
  flex-grow: 1;
  margin-top: 1rem;
  border-radius: unset;

  .ui.secondary.button, .ui.secondary.buttons .button {
    background-color: var(--secondary-color);

    :hover {
      background-color: transparent;
      border-color: var(--secondary-color);
      color: var(--secondary-color);
    }

    :focus {
      background-color: transparent;
      border-color: var(--secondary-color);
      color: var(--secondary-color);
    }
`;

interface Props {
  exerciseResultIx: number,
  exerciseResultParams: TestResultParamDto[];
  onCancel: () => void;
}

interface ExerciseResultParamFieldProps extends FormState<any> {
  values: Partial<ParamSearchRequest>;
}

const cancelTokenSource = axios.CancelToken.source();

const ExerciseResultAddParamsModal = (props: Props) => {

  const { t } = useTranslation('teresa');
  const { exerciseConfFormData, onAddExerciseResultExerciseResultParam } = useExerciseConfFormDataContext();

  const { onCancel, exerciseResultParams, exerciseResultIx } = props;

  const [searchValues, setSearchValues] = useState<Partial<ParamSearchRequest>>({
    code: '',
    selectedTestResultParamIds: exerciseConfFormData
    && exerciseConfFormData.exerciseResults
    && exerciseConfFormData.exerciseResults[exerciseResultIx]
      ? exerciseConfFormData.exerciseResults[exerciseResultIx].exerciseResultParams
      .map(param => param.paramId)
      : [],
    selectedConfParamIds: exerciseConfFormData!.exerciseResultParams!.map(param => param.paramId),
    scopeType: ScopeType.REHAB,
  });

  const [availableExerciseResultParams, setAvailableExerciseResultParams] = useState<TestResultParamDto[]>([]);
  const [formDataLoaded, setFormDataLoaded] = useState<boolean>(true);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    setAvailableExerciseResultParams(exerciseResultParams);
  }, [exerciseResultParams]);

  const handleError = useCallback((error: any) => {

    if (error) {
      let errMsgs = [];
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [errorUtils.invalidEmail];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsgs.push(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          errMsgs.push(t(`error.${errorCode}`));
        } else {
          errMsgs.push(t('error.general'));
        }
      }

      setErrorMessage(errMsgs);
    }

  }, []);

  const setErrorMessage = (errMsgs?: string[]) => {

    if (errMsgs) {
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const handleChange = useAfterFirstRender(async ({ values }: ExerciseResultParamFieldProps) => {
    await setSearchValues(values);
    searchExerciseResultParams(values);
  });

  useEffect(() => {
    let newSearchValues = {
      code: '',
      selectedTestResultParamIds: exerciseConfFormData
      && exerciseConfFormData.exerciseResults
      && exerciseConfFormData.exerciseResults[exerciseResultIx]
        ? exerciseConfFormData.exerciseResults[exerciseResultIx].exerciseResultParams
        .map(param => param.paramId)
        : [],
      selectedConfParamIds: exerciseConfFormData!.exerciseResultParams!.map(param => param.paramId),
      scopeType: ScopeType.REHAB,
    };
    setSearchValues(newSearchValues);
    searchExerciseResultParams(newSearchValues);
  }, [exerciseConfFormData!.exerciseResults![exerciseResultIx].exerciseResultParams]);

  const searchExerciseResultParams = debounce(async (values: Partial<ParamSearchRequest>) => {

    const onFinally = () => {
      setFormDataLoaded(true);
    };

    setFormDataLoaded(false);
    setAvailableExerciseResultParams([]);

    let newSearchValues = { ...searchValues };

    if (values) {
      newSearchValues = { ...values };
    } else {
      if (_.isEmpty(newSearchValues)) {
        newSearchValues = {
          code: '',
          selectedTestResultParamIds: exerciseConfFormData
          && exerciseConfFormData.exerciseResults
          && exerciseConfFormData.exerciseResults[exerciseResultIx]
            ? exerciseConfFormData.exerciseResults[exerciseResultIx].exerciseResultParams
            .map(param => param.paramId)
            : [],
          selectedConfParamIds: exerciseConfFormData!.exerciseResultParams!
          .map(param => param.paramId),
          scopeType: ScopeType.REHAB,
        };
      }
    }

    try {
      const paramsResponse = await searchTestResultParamsForTestResult(newSearchValues, cancelTokenSource);
      setAvailableExerciseResultParams(paramsResponse);
    } catch (e) {
      handleError(e.response.data);
    } finally {
      onFinally();
    }

  }, 300);

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={() => {}}
        initialValues={searchValues}
        subscription={{ pristine: true, values: true }}
        render={renderTable}
      />
    );
  };

  const renderTable = () => {

    return (
      <TsaGrid>
        <Grid.Row>
          <Grid.Column width={5} floated='right'>
            <Field
              id='code'
              name='code'
              component={Input}
              icon={'search'}
              iconPosition='left'
              placeholder={t('exerciseResult.search.placeholder')}
              autoFocus
              fluid
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {!formDataLoaded
            ? <LoaderComponent message={t('loading')} />
            : <Grid.Column width={16} verticalAlign='middle' className='no-padding'>
              <TableContainer>
                <Table basic='very' striped size='small'>
                  <Table.Body className='table-body'>
                    <Table.HeaderCell width={10}
                                      className='header-cell'>{t('testresult.description')}</Table.HeaderCell>
                    <Table.HeaderCell width={3} className='header-cell'>{t('testresult.unit')}</Table.HeaderCell>
                    <Table.HeaderCell width={7} className='header-cell'>{t('testresult.code')}</Table.HeaderCell>
                    {availableExerciseResultParams.map(exerciseResultParam => {
                      return <Table.Row key={exerciseResultParam.id}>
                        <Table.Cell width={10}>
                          {exerciseResultParam.description}
                        </Table.Cell>
                        <Table.Cell width={3}>
                          {t(`unit.${exerciseResultParam.unit}`)}
                        </Table.Cell>
                        <Table.Cell width={7} flexgrow={1}>
                          {exerciseResultParam.code}
                        </Table.Cell>
                        <Table.Cell width={2}>
                          <div className='row-actions'>
                            <InnerTableActionButton
                              message='Add'
                              onConfirm={() => onAddExerciseResultExerciseResultParam(
                                exerciseResultIx,
                                exerciseResultParam.id,
                              )
                              }
                            />
                          </div>
                        </Table.Cell>
                      </Table.Row>;
                    })}
                    {emptyTableRows(availableExerciseResultParams.length, 4)}
                  </Table.Body>
                </Table>
              </TableContainer>
            </Grid.Column>}
          <Grid.Column width={16}>
            <ActionContainer>
              <CompositeButton
                type='button'
                className='action-button'
                style={{ whiteSpace: 'nowrap' }}
                secondary
                floated='right'
                onClick={onCancel}
              >
                {t('button.close')}
              </CompositeButton>
            </ActionContainer>
          </Grid.Column>
        </Grid.Row>
        <FormSpy subscription={{ values: true }} onChange={handleChange} />
      </TsaGrid>
    );
  };

  return <>{renderFinalForm()}</>;
};

export default ExerciseResultAddParamsModal;