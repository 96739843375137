import DataLabel from 'components/final-form/DataLabel';
import CompositeButton from 'components/final-form/CompositeButton';
import MainContainerComponent from 'components/MainContainerComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import _ from 'lodash';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Grid, Input, InputOnChangeData } from 'semantic-ui-react';
import axios from 'service/http';
import { requestPasswordReset, RequestResetPasswordDto } from 'service/passwordResetServices';
import styled from 'styled-components';
import { errorUtils } from 'util/errorUtils';
import { isKeyCheck } from 'util/keyUtils';

const GridColumn = styled(Grid.Column)`
  padding-right: 0 !important;
`

const ContainerDiv = styled.div` 

    .container-div {
    justify-content: center;
    background-color: white;
    padding: 50px;
    max-width: 650px;
    border: 1px solid var(--very-very-light-gray);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
   }

   .header-div {
    text-align: center;
   }

  .auth-form {
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
    margin-top: 2rem;
   
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 2rem;

    label {
      align-self: center;
      width: 20rem;
    }
    
    .btn-span {
      display: inline-flex;
      margin-left: 5rem;
      padding-right: 7rem;
    }

    .action-button {
      grid-column-start: 2;
      justify-self: start;
      margin-top: 0.75rem;

      &.text {
        margin-top: 0;
      }
    }
    
    .error-message {
      margin-bottom: 1rem;
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
      position: relative;
    }
  }

  @media only screen and (max-width: 767px) {
    .auth-form {
      display: flex;
      flex-direction: column;

      label {
        align-self: flex-start;
      }
      
      .btn-span {
        margin-left: unset;
      }

      .action-button {
        display: block;
        width: 100%;
      }
    }
  }
`;

const cancelTokenSource = axios.CancelToken.source();

const ForgotPassword = () => {

  const { t } = useTranslation('login');
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [fieldError, setFieldError] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const goToLogin = () => {
    history.push('/login');
  };

  const handleError = (error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [errorUtils.invalidEmail, errorUtils.invalidInput,
        errorUtils.unknownEmailAddress];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      } else {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };


  const setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const setInputEmail = (evt: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    if (_.isEmpty(email)) {
      setFieldError(false);
    }
    setEmail(data.value);
  };

  const onEnterKeyDown = (event: any) => {
    event.persist();
    const isKey: any = isKeyCheck(event);

    if (isKey.enter) {
      createResetPasswordToken();
    }
  };

  const createResetPasswordToken = async () => {

    if (_.isEmpty(email)) {
      setFieldError(true);
      return;
    }

    const requestPasswordResetDto: RequestResetPasswordDto = {
      email: email,
    };

    try {
      const response = await requestPasswordReset(requestPasswordResetDto, cancelTokenSource);
      goToLogin();
    } catch (e: any) {
      handleError(e.response.data);
    }

  };


  const renderFormContent = (): React.ReactNode => {
    return <MainContainerComponent>
      <ContainerDiv>
        <div className={'container-div'}>
          <div className={'header-div'}>
            <div className='title-h1'>{t('login.forgetPassword')}</div>
          </div>
          <TsaGrid>
            <Grid.Row>
              <GridColumn width={16}>
                <TsaGrid>
                  {
                    errorMessages.length > 0 &&
                    <div className='error-message'>
                      <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                        {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                      </StyledErrorMessage>
                    </div>
                  }

                  <Grid.Row>
                    <GridColumn width={16}>
                      <DataLabel>{t('login.email')}</DataLabel>
                      <Input
                        type='email'
                        placeholder={t('login.email')}
                        value={email}
                        error={fieldError}
                        onChange={setInputEmail}
                        onKeyDown={(e: any) => onEnterKeyDown(e)}
                        autoFocus
                        fluid
                      />
                    </GridColumn>
                  </Grid.Row>
                </TsaGrid>
              </GridColumn>
            </Grid.Row>

            <Grid.Row>
              <GridColumn width={8} textAlign={'right'}>
                <CompositeButton
                  type='button'
                  className='action-button'
                  style={{ marginRight: '2rem' }}
                  primary
                  onClick={createResetPasswordToken}
                >
                  {t('action.send')}
                </CompositeButton>
              </GridColumn>
              <GridColumn width={8} textAlign={'left'}>
                <CompositeButton
                  type='button'
                  className='action-button'
                  style={{ whiteSpace: 'nowrap', marginRight: '5rem' }}
                  secondary
                  onClick={goToLogin}
                >
                  {t('action.backToLogin')}
                </CompositeButton>
              </GridColumn>
            </Grid.Row>
          </TsaGrid>
        </div>
      </ContainerDiv>
    </MainContainerComponent>;
  };

  return <>
    {renderFormContent()}
  </>;
};

export default ForgotPassword;
