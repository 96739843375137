import InnerTableActionButton from 'components/InnerTableActionButton';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const UploadImageContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  background-color: white;
  border: 1px dashed var(--very-very-light-blue);
  cursor: pointer;

  & .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--dark-gray);

    .icon {
      margin-top: .8rem;
    }
  }
`;

const ThumbsContainer = styled.aside`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  cursor: pointer;
  background-color: white;
`;

const Thumb = styled.div`
  display: inline-flex;
  width: 100%;
  height: 150px;
  box-sizing: border-box;
  background-color: white;
`;

const ThumbInner = styled.div`
  display: flex;
  min-width: 0;
  overflow: hidden;
`;

const StyledImage = styled.img`
  display: block;
  width: auto;
  height: 100%;
`;

const StyledClearBtn = styled(InnerTableActionButton)`
  margin-top: 1rem !important;
`;

interface ExtendedFieldRenderProps extends FieldRenderProps<any> {
  imageView?: any,
  removeImageView?: Function,
  accept?: string | string[],
  getErrorCode?: Function,
}

const ImageDropZone = (props: ExtendedFieldRenderProps) => {

  const { t } = useTranslation('teresa');
  const { input, imageView, removeImageView, accept = 'image/*', getErrorCode } = props;

  const [files, setFiles] = useState<any[]>([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    multiple: false,
    onDrop: (acceptedFiles, fileRejections) => {

      if (fileRejections && fileRejections[0] && getErrorCode) {
        const errorCode = fileRejections[0].errors[0].code;
        getErrorCode(errorCode);
      }

      const files = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );
      setFiles(files);
      input.onChange(files[0]);
    },
  });

  const removeImage = (file?: any) => () => {

    if (file) {
      removeFile(file);
    } else if (removeImageView) {
      removeImageView();
    }

  };

  const removeFile = (file: any) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    input.onChange(undefined);
  };

  const thumbs = files.map(file =>
    <Thumb key={file.name}>
      <ThumbInner>
        <StyledImage src={file.preview} alt='' />
      </ThumbInner>
    </Thumb>,
  );

  useEffect(
    () => () => {
      // Make sure to revoke the data URIs to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  return (
    <div>
      <div {...getRootProps({ className: 'btn-dropzone' })}>
        <input {...getInputProps()} />
        {files && files.length
          ? <ThumbsContainer>{thumbs}</ThumbsContainer>
          : imageView
            ? <Thumb key={`imageView`}>
              <ThumbInner>
                <StyledImage src={`data:image/jpeg;base64, ${imageView}`} alt='' width='50px' height='50px' />
              </ThumbInner>
            </Thumb>
            : <UploadImageContainer>
              <div className='content'>
                <div>{t('button.uploadImage')}</div>
                <Icon name={'upload'} />
              </div>
            </UploadImageContainer>
        }
      </div>
      {(files && files.length) || imageView
        ? <StyledClearBtn
          message={t('button.clear')}
          onConfirm={removeImage(files[0])}
        />
        : undefined
      }
    </div>
  );
};

export default ImageDropZone;