import React from 'react';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledEditActionButton = styled(Button)`
  padding: .2rem 0 .2rem !important;

  &.ui.primary.button {
    height: 20px;
    border-radius: unset;
    font-weight: 600;
    font-size: 13px;
    background: transparent !important;
    color: var(--primary-color);
    padding-right: 5px !important;
  }

  &.ui.primary.button:hover {
    font-weight: 600;
    font-size: 13px;
    background: transparent !important;
    color: var(--primary-color);
  }

  &.custom-border {
    margin-right: 5px;
  }

  &.custom-border:after {
    content: " ";
    border-right: 1px var(--light-blue) solid;
    padding-left: 10px;
  }
`;

interface Props {
  message: string,
  disabled?: boolean,
  onConfirm: () => void,
  className?: string,
  divider?: boolean
}

const InnerTableActionButton = (props: Props) => {

  const { message, disabled = false, onConfirm, className = '', divider = false } = props;
  let classes = className;
  if (divider) {
    classes = `${classes} custom-border`
  }

  return (
    <StyledEditActionButton
      type='button'
      size='tiny'
      primary
      className={classes}
      onClick={onConfirm}
      disabled={disabled}
    >
      {message}
    </StyledEditActionButton>
  );

};

export default InnerTableActionButton;
