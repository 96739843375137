import React, { Component } from 'react';
import { DividerProps } from 'semantic-ui-react';
import styled from 'styled-components';

const ContentWrapperDivStyle = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 0rem;
  padding-bottom: 0rem;

  .page-header {
    margin-bottom: 4rem;
  }

  .warning-message {
    width: 100%;
    box-shadow: unset;
    border-radius: unset;
    color: var(--dark-yellow);
    float: right;
  }

  .ui.button + .ui.button {
    margin-left: 1rem;
  }

  .scoreLabel {
    margin-bottom: -11px;
    font-size: 11px;
    color: var(--light-blue);
    font-family: 'Manrope', sans-serif;
  }

`;

class UpsertContentWrapperDiv extends Component<DividerProps> {

  render(): React.ReactNode {
    const {children, ...rest} = this.props;
    return (
        <ContentWrapperDivStyle {...rest}>
          {children}
        </ContentWrapperDivStyle>
    )
  }
}

export default UpsertContentWrapperDiv;
