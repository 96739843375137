import { useAuthContext } from 'auth/AuthContext';
import ProtectedRoute from 'auth/ProtectedRoute';
import FormModalContentContainer from 'components/final-form/FormModalContentContainer';
import CompositeButton from 'components/final-form/CompositeButton';
import LoaderComponent from 'components/LoaderComponent';
import SideBar from 'components/sidebar/SideBar';
import SidebarProvider from 'components/sidebar/SidebarContext';
import ClinicInfoProvider from 'context/ClinicInfoContext';
import ExerciseConfFormDataProvider from 'context/ExerciseConfFormDataContext';
import PatientExerciseFormDataProvider from 'context/PatientExerciseFormDataContext';
import TestResultFormDataProvider from 'context/TestResultFormDataContext';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import AdminView from 'routes/account/AdminView';
import UpsertAccountForm from 'routes/account/UpsertAccountForm';
import ActivationKeyView from 'routes/ActivationKeyView';
import AgeRangesView from 'routes/ageranges/AgeRangesView';
import UpsertAgeRangesForm from 'routes/ageranges/UpsertAgeRangesForm';
import ClinicConfigView from 'routes/clinic/ClinicConfigView';
import ClinicDetailsView from 'routes/clinic/ClinicDetailsView';
import ClinicInvoiceView from 'routes/clinic/ClinicInvoiceView';
import ClinicStatsView from 'routes/clinic/ClinicStatsView';
import ClinicsView from 'routes/clinic/ClinicsView';
import UpsertClinicForm from 'routes/clinic/UpsertClinicForm';
import DoctorDetailsView from 'routes/doctor/DoctorDetailsView';
import UpsertDoctorForm from 'routes/doctor/UpsertDoctorForm';
import DomainsView from 'routes/domain/DomainsView';
import UpsertDomainForm from 'routes/domain/UpsertDomainForm';
import EducationalYearsView from 'routes/educationalyears/EducationalYearsView';
import UpsertEducationalYearsForm from 'routes/educationalyears/UpsertEducationalYearsForm';
import ExerciseExportView from 'routes/exercise-export/ExerciseExportView';
import ExerciseConfView from 'routes/exerciseconf/ExerciseConfView';
import UpsertExerciseConfForm from 'routes/exerciseconf/UpsertExerciseConfForm';
import ExerciseTemplateView from 'routes/exercise-template/ExerciseTemplateView';
import UpsertExerciseTemplateForm from 'routes/exercise-template/UpsertExerciseTemplateForm';
import UpsertExerciseResultForm from 'routes/exerciseresult/UpsertExerciseResultForm';
import ForgotPassword from 'routes/ForgotPassword';
import Home from 'routes/Home';
import ImportPage from 'routes/import/ImportPage';
import Init2fa from 'routes/init-2fa/Init2fa';
import InvoiceConfigView from 'routes/invoice/InvoiceConfigView';
import UpsertInvoiceConfigForm from 'routes/invoice/UpsertInvoiceConfigForm';
import Login from 'routes/Login';
import MandatesView from 'routes/mandate/MandatesView';
import UpsertMandateForm from 'routes/mandate/UpsertMandateForm';
import PatientDetailsView from 'routes/patient/PatientDetailsView';
import PatientSearchView from 'routes/patient/PatientSearchView';
import UpsertPatientForm from 'routes/patient/UpsertPatientForm';
import ExercisingForm from 'routes/patientexercising/ExercisingForm';
import PreviewExerciseReportPage from 'routes/patientexercising/PreviewExerciseResultPage';
import UpsertPatientExercisingForm from 'routes/patientexercising/UpsertPatientExercisingForm';
import AnswerQuestionsView from 'routes/patienttesting/AnswerQuestionsView';
import ExportTestingView from 'routes/patienttesting/ExportTestingView';
import PreviewResultsPage from 'routes/patienttesting/PreviewResultsPage';
import StartTestingForm from 'routes/patienttesting/StartTestingForm';
import UpsertPatientTestingForm from 'routes/patienttesting/UpsertPatientTestingForm';
import QuestionSetView from 'routes/question-set/QuestionSetView';
import UpsertQuestionSetForm from 'routes/question-set/UpsertQuestionSetForm';
import UpsertRawScoreResultForm from 'routes/rawscoreresult/UpsertRawScoreResultForm';
import ResetPassword from 'routes/ResetPassword';
import TestResultParamView from 'routes/resultparam/TestResultParamView';
import UpsertTestResultParamForm from 'routes/resultparam/UpsertTestResultParamForm';
import UpsertSettingsForm from 'routes/settings/UpsertSettingsForm';
import TestExportView from 'routes/test-export/TestExportView';
import TestTemplateView from 'routes/test-template/TestTemplateView';
import UpsertTestTemplateForm from 'routes/test-template/UpsertTestTemplateForm';
import TestConfSearchView from 'routes/testconf/TestConfSearchView';
import UpsertTestConfForm from 'routes/testconf/UpsertTestConfForm';
import TestImageView from 'routes/testimage/TestImageView';
import UpsertTestImageForm from 'routes/testimage/UpsertTestImageForm';
import UpsertTestResultForm from 'routes/testresult/UpsertTestResultForm';
import UpsertTrueScoreResultForm from 'routes/truescorerules/UpsertTrueScoreResultForm';
import TrueScoreRulesView from 'routes/truescorerules/TrueScoreRulesView';
import { Icon, Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { ScopeType } from 'ts-types/api.enums';

const StyledContainer = styled.div`
  min-height: 100vh;
  overflow: hidden;
  background-color: #f1f2f7;
  font-family: 'Manrope', sans-serif;

  .title-h1 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }

  .table-title {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .sub-title {
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--dark-gray);

    & > .normal-text {
      font-weight: 300;
    }
  }

  h4.ui.header {
    color: var(--medium-blue-gray);
  }

  .ui.button {
    font-family: 'Manrope', sans-serif;
  }

  .ui.large.button {
    padding: 0.7rem 4rem 0.7rem;
    font-weight: 500;
  }

  .ui.primary.button, .ui.primary.buttons .button {
    background-color: var(--primary-color);

    :active {
      background-color: var(--primary-color);
    }

    :hover { // we should decide for the hover color
      background-color: transparent;
    }

    :focus {
      background-color: transparent;
    }

    &.logout-btn {
      padding: .2rem 1.8rem .2rem !important;
      background-color: transparent;
      border: 1px solid black;
      color: black;

      :active {
        background-color: transparent;
        border-color: black;
      }

      :hover {
        background-color: transparent;
        border-color: black;
        text-decoration: underline;
      }

      :focus {
        background-color: transparent;
        border-color: black;
      }
    }
  }

  .ui.secondary.button, .ui.secondary.buttons .button {
    background-color: var(--secondary-color);

    //:active {
    //  background-color: #1166A8;
    //}
    //
    :hover {
      background-color: transparent;
    }
    //
    //:focus {
    //  background-color: #0F5A94;
    //}
  }

  .field > .ui.input {
    width: 100%;
    height: 100%; 
    
    &.text-align-right > input {
        text-align: right;
    }
  }

  .ui.input > input {
    border: 1px solid var(--very-very-light-blue);
    background-color: white;
    padding: .68rem 0.675rem;
    border-radius: 5px;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--dark-gray);
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: var(--dark-gray);
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: var(--dark-gray);
    }

    & > i.icon {
      color: var(--dark-gray);
    }
  }
    
  .ui.input.multilang > input {
    border-radius: 0 5px 5px 5px;
  }  

  .ui.input.error > input {
    background-color: var(--very-very-light-red);
    border-color: var(--dark-red);
    color: var(--dark-red);
    box-shadow: none;
  }

  .ui.disabled.input > input {
    opacity: .45;
  }

  .ui.input.down input,
  .ui.input > input:active {
    //border: 1px solid #1578C6;
  }

  .ui.input.focus > input,
  .ui.input > input:focus {
    border: 1px solid var(--very-very-light-blue);
    background-color: white;
  }

  .ui.disabled.input {
    opacity: 1;
  }

  label {
    color: #000000;
    font-size: 1.1589rem;
  }

  .ui.selection.dropdown, &.ui.selection.active.dropdown .menu {
    border: 1px solid var(--very-very-light-blue);
    background-color: white;
    border-radius: 5px;
    box-shadow: unset;
    width: 100%;
    color: #767a95;
  }

  .ui.error.selection.dropdown {
    background-color: var(--very-very-light-red);
    border-color: var(--dark-red);
    color: var(--dark-red);
    box-shadow: none;
  }

  .ui.active.selection.dropdown {
    background-color: white;
  }

  .ui.selection.active.dropdown .menu {
    border-color: var(--very-very-light-blue);
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: var(--primary-color) !important;
  }

  .ui.form textarea {
    border: 1px solid var(--very-very-light-blue);
    background-color: white;
    padding: 0.68rem 0.675rem;
    border-radius: 5px;

    &:focus {
      background-color: white;
    }
  }

  .ui.form textarea.multilang {
    border-radius: 0 5px 5px 5px;
  }

    .ui.menu {
    font-family: 'Manrope', sans-serif;
  }
  
  .ui.header {
    font-family: 'Manrope', sans-serif;
  }

  .search-form {
    padding-left: 0.75rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
      margin-right: 1rem;
    }

    .ui.input {
      min-width: 20rem;
    }

    button, a {
      margin-left: 1rem;
    }

    .checkbox {
      margin-left: 1rem;
    }
  }

  .search-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    & .title-h1 {
      display: inline-block;
      margin-bottom: unset;
      padding-left: 0px !important;
    }

    & .search-form {
      margin-bottom: unset;
    }
  }

  .data-table {
    flex: 1 1 auto;
    min-height: 250px;

    .row-actions {
      i.icon,
      i.icons {
        color: var(--light-purple);
      }
    }

    .table-loader.ui.inline.loader.active,
    .table-loader.ui.inline.loader.visible {
      display: block;
      top: 5rem;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .title-h1 {
      font-size: 1.5rem;
      margin-bottom: 1.2rem;
    }

    .table-title {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
  }
`;

const StyledLoaderWrapper = styled.div`
  padding-top: 20px;
`;

const AppDiv = styled.div`
  &.app.teresa {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
  }

  .app-header {
    flex: 0 0 auto;
    border-bottom: 1px solid var(--very-dark-blue);
    padding: 0.9rem 0;
    font-size: 1.4rem;
    margin: 0 0 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .header-title {
      flex: 0 0 auto;
      line-height: 2.775rem;
      color: var(--very-dark-blue);
    }

    .header-right {
      flex: 1 1 auto;
      align-items: end;
    }
  }

  .header-title-mobile {
    display: none;
  }

  .app-content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    margin: 3.5rem 2.5rem 0rem 2.5rem;
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .app-header {
      margin-bottom: 0.3rem;

      .header-title {
        display: none;
      }
    }

    .header-title-mobile {
      display: block;
      flex-basis: 100%;
      margin-bottom: 0.75rem;
      color: var(--very-dark-blue);
      font-size: 1.1rem;
      font-weight: 500;
    }
  }
`;

const Logo = styled.div`
  position: fixed;
  top: 10px;
  left: 0;
  width: 105px;
  cursor: pointer;
  padding: 0 10px 15px 15px;
  color: var(--primary-color);
  text-transform: lowercase;
  font-size: 18px;
  font-weight: 600;
  z-index: 9999;
`

const LogoutModal = styled(Modal)`
  .ui.primary.button, .ui.primary.buttons .button {
    background-color: var(--primary-color);

    :hover {
      background-color: transparent;
      border-color: var(--primary-color);
      color: var(--primary-color);
    }

    :focus {
      background-color: transparent;
      border-color: var(--primary-color);
      color: var(--primary-color);
    }
  }
  
  .ui.secondary.button, .ui.secondary.buttons .button {
    background-color: var(--secondary-color);

    :hover {
      background-color: transparent;
      border-color: var(--secondary-color);
      color: var(--secondary-color);
    }

    :focus {
      background-color: transparent;
      border-color: var(--secondary-color);
      color: var(--secondary-color);
    }
  }
`;

interface Props {
}

const Main = (props: Props) => {

  const { t } = useTranslation('login');
  const location = useLocation();
  const isIpad = useIsIpadWidthOrBelow();
  const { fetchingUser, currentUser, sessionExpiredModalOpen, onSessionExpiredModalClose, logout } = useAuthContext();

  const [hideSideBar, setHideSideBar] = useState<boolean>(isIpad);

  useEffect(() => {
    setHideSideBar(isIpad);
  }, [isIpad]);

  const isSystemAdmin = useMemo(() => currentUser
    && currentUser.roles.includes('ROLE_APP_ADMIN'), [currentUser]);

  const isTestAdmin = useMemo(() => currentUser
    && currentUser.roles.includes('ROLE_TEST_ADMIN'), [currentUser]);

  const isClinicAdmin = useMemo(() => currentUser
    && currentUser.clinicAdmin, [currentUser]);

  const isDoctor = useMemo(() => currentUser
    && currentUser.clinicId, [currentUser]);

  const toggleSidebar = (open: boolean) => {
    setHideSideBar(open);
  }

  const renderLoader = (): React.ReactNode => {
    return (
      <StyledLoaderWrapper>
        <LoaderComponent active />
      </StyledLoaderWrapper>
    );
  };

  const userLandingPage = () => {

    if (isSystemAdmin) {
      return <ProtectedRoute exact path='/' component={ClinicsView} />;
    }

    if (isTestAdmin) {
      return <ProtectedRoute exact path='/' component={TestConfSearchView} />;
    }

    if (isClinicAdmin) {
      return <ProtectedRoute exact path='/' component={ClinicDetailsView} />;
    }

    if (isDoctor) {
      return <ProtectedRoute exact path='/' component={Home} />;
    }

    return '';
  };

  const renderAppRoutes = () => {

    const style = { color: 'var(--primary-color)', paddingLeft: "10px" };

    return <>
      {isIpad && currentUser && (location.pathname !== "/export-tests")
        && (location.pathname !== "/export-exercises") &&
        <Logo onClick={() => {toggleSidebar(!hideSideBar)}}>
          Teresa
          <Icon
            name='bars'
            size='small'
            style={style}
          />
        </Logo>
      }
      {userLandingPage()}
      {isSystemAdmin
        ? <Switch>
          <ProtectedRoute exact path='/account' component={UpsertAccountForm} />
          <ProtectedRoute exact path='/account/search' component={AdminView} />
          <ProtectedRoute exact path='/account/test-admin' component={UpsertAccountForm} />
          <ProtectedRoute exact path='/invoice-config/upsert' component={UpsertInvoiceConfigForm} />
          <ProtectedRoute exact path='/invoice-config' component={InvoiceConfigView} />
          <ProtectedRoute exact path='/mandates' component={MandatesView} />
          <ProtectedRoute exact path='/mandate' component={UpsertMandateForm} />
        </Switch>
        : <></>
      }
      {isSystemAdmin || isTestAdmin
        ? <><Switch>
          <ProtectedRoute exact path='/domain/search' component={DomainsView} />
          <ProtectedRoute exact path='/domain' component={UpsertDomainForm} />
          <ProtectedRoute exact path='/exerciseconf/search' component={ExerciseConfView} />
          <ProtectedRoute exact path='/question-set/search' component={() => <QuestionSetView scopeType={ScopeType.TEST} />} />
          <ProtectedRoute exact path='/rehabilitation-question-set/search' component={() => <QuestionSetView scopeType={ScopeType.REHAB} />} />
          <ProtectedRoute exact path='/question-set' component={UpsertQuestionSetForm} />
          <ProtectedRoute exact path='/rehabilitation-question-set' component={UpsertQuestionSetForm} />
          <ProtectedRoute exact path='/testresultparam/search' component={() => <TestResultParamView scopeType={ScopeType.TEST} />} />
          <ProtectedRoute exact path='/rehabilitation-testresultparam/search' component={() => <TestResultParamView scopeType={ScopeType.REHAB} />} />
          <ProtectedRoute exact path='/rehabilitation-exercise-template/search' component={() => <ExerciseTemplateView />} />
          <ProtectedRoute exact path='/rehabilitation-exercise-template' component={() => <UpsertExerciseTemplateForm />} />
          <ProtectedRoute exact path='/testresultparam' component={UpsertTestResultParamForm} />
          <ProtectedRoute exact path='/rehabilitation-testresultparam' component={UpsertTestResultParamForm} />
          <ProtectedRoute exact path='/testconf/search' component={TestConfSearchView} />

          <ProtectedRoute exact path='/test-template/search' component={TestTemplateView} />
          <ProtectedRoute exact path='/test-template' component={UpsertTestTemplateForm} />
          <ProtectedRoute exact path='/educational-years/search' component={EducationalYearsView} />
          <ProtectedRoute exact path='/educational-years' component={UpsertEducationalYearsForm} />
          <ProtectedRoute exact path='/age-ranges/search' component={AgeRangesView} />
          <ProtectedRoute exact path='/age-ranges' component={UpsertAgeRangesForm} />
          <ProtectedRoute exact path='/test-image' component={UpsertTestImageForm} />
          <ProtectedRoute exact path='/test-image/search' component={TestImageView} />
          <ProtectedRoute exact path='/truescorerule/search' component={TrueScoreRulesView} />
          <ProtectedRoute exact path='/truescorerule' component={UpsertTrueScoreResultForm} />
          <ProtectedRoute exact path='/export-tests' component={TestExportView} />
          <ProtectedRoute exact path='/export-exercises' component={ExerciseExportView} />
          <TestResultFormDataProvider>
            <ProtectedRoute exact path='/testconf' component={UpsertTestConfForm} />
            <ProtectedRoute exact path='/testresult/rawscoreresult' component={UpsertRawScoreResultForm} />
            <ProtectedRoute exact path='/testresult/truescoreresult' component={UpsertTrueScoreResultForm} />
            <ProtectedRoute exact path='/testresult' component={UpsertTestResultForm} />
          </TestResultFormDataProvider>
        </Switch>
          <Switch>
            <ExerciseConfFormDataProvider>
              <ProtectedRoute exact path='/exerciseconf' component={UpsertExerciseConfForm} />
              <ProtectedRoute exact path='/exerciseresult' component={UpsertExerciseResultForm} />
            </ExerciseConfFormDataProvider>
          </Switch>
          </>
        : <></>
      }
      {isSystemAdmin || isClinicAdmin
        ? <Switch>
          <ClinicInfoProvider>
            <ProtectedRoute exact path='/clinic/details' component={ClinicDetailsView} />
            <ProtectedRoute exact path='/doctor/details' component={DoctorDetailsView} />
            <ProtectedRoute exact path='/clinic/config' component={ClinicConfigView} />
            <ProtectedRoute exact path='/clinic/stats' component={ClinicStatsView} />
            <ProtectedRoute exact path='/clinic/invoice' component={ClinicInvoiceView} />
            <ProtectedRoute exact path='/clinic' component={UpsertClinicForm} />
            <ProtectedRoute exact path='/import' component={ImportPage} />
          </ClinicInfoProvider>
        </Switch>
        : <></>
      }
      {isSystemAdmin || isClinicAdmin || isDoctor
        ? <Switch>
          <ProtectedRoute exact path='/doctor' component={UpsertDoctorForm} />
        </Switch>
        : <></>
      }
      {isSystemAdmin || isDoctor
        ? <Switch>
          <ProtectedRoute exact path='/patient/details' component={PatientDetailsView} />
          <ProtectedRoute exact path='/patient/search' component={PatientSearchView} />
          <ProtectedRoute exact path='/patient' component={UpsertPatientForm} />
          <ProtectedRoute exact path='/patient-testing' component={UpsertPatientTestingForm} />
          <ProtectedRoute exact path='/exercise-testing' component={UpsertPatientExercisingForm} />
          <ProtectedRoute exact path='/start-testing' component={StartTestingForm} />
          <ProtectedRoute exact path='/preview-results' component={PreviewResultsPage} />
          <ProtectedRoute exact path='/export-testing' component={ExportTestingView} />
          <ProtectedRoute exact path='/answer-questions' component={AnswerQuestionsView} />
          <PatientExerciseFormDataProvider>
            <ProtectedRoute exact path='/exercising' component={ExercisingForm} />
            <ProtectedRoute exact path='/preview-exercising' component={PreviewExerciseReportPage} />
          </PatientExerciseFormDataProvider>
        </Switch>
        : <></>
      }
      <Switch>
        <ProtectedRoute exact path='/settings' component={UpsertSettingsForm} />
      </Switch>
    </>;
  };

  const renderAppContent = () => {
    if (currentUser) {
      return renderAppRoutes();
    }

    return '';
  };

  const renderApp = (): React.ReactNode => {
    return (
      <>
        <LogoutModal
          open={sessionExpiredModalOpen}
          onClose={onSessionExpiredModalClose}
          style={{ width: '315px' }}
          closeOnDimmerClick={false}
        >
          <Modal.Header>{t('sessionExpiredHeader')}</Modal.Header>
          <FormModalContentContainer>{t('sessionExpired')}</FormModalContentContainer>
          <Modal.Actions>
            <CompositeButton
              type='button'
              className='action-button'
              size='medium'
              primary
              onClick={logout}>
              {t('header.action.logout')}
            </CompositeButton>
            <CompositeButton
              type='button'
              className='action-button'
              size='medium'
              secondary
              onClick={onSessionExpiredModalClose}>
              {t('action.close')}
            </CompositeButton>
          </Modal.Actions>
        </LogoutModal>
        <AppDiv className='app teresa'>
          {currentUser && (location.pathname !== "/export-tests") && (location.pathname !== "/export-exercises") &&
            <SidebarProvider>
              <SideBar
                hideSideBar={hideSideBar}
                closeSideBar={() => setHideSideBar(!hideSideBar)}
              />
            </SidebarProvider>
          }
          <div className='app-content'>
            <Switch>
              <Route exact path='/reset-password' component={ResetPassword} />
              <Route exact path='/forget-password' component={ForgotPassword} />
              <Route exact path='/registration' component={ActivationKeyView} />
              <Route exact path='/login' component={Login} />
              <ProtectedRoute path='/init-2fa' exact component={Init2fa} />
            </Switch>
            {renderAppContent()}
          </div>
        </AppDiv>
      </>
    );
  };

  const renderContent = (): React.ReactNode => {
    return fetchingUser ? renderLoader() : renderApp();
  };

  return (
    <StyledContainer>
      {renderContent()}
    </StyledContainer>
  );
};

export default Main;
