import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import { checkBoxInput } from 'components/final-form/CheckBoxInput';
import DataLabel from 'components/final-form/DataLabel';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import { DropdownOption, mapToMultiLangDropdownOptionArray } from 'components/final-form/Select';
import { selectInput } from 'components/final-form/SelectComponent';
import CompositeButton from 'components/final-form/CompositeButton';
import { textInput } from 'components/final-form/TextInput';
import InnerTsaGrid from 'components/InnerTsaGrid';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import { useTestResultFormDataContext } from 'context/TestResultFormDataContext';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Header } from 'semantic-ui-react';
import { getAllTestResults } from 'service/testResultService';
import { addTrueScoreResult, editTrueScoreResult, getTrueScoreResult } from 'service/trueScoreResultService';
import { TrueResultEnum, TrueScoreResultType } from 'ts-types/api.enums';
import { UpsertTrueScoreResultDto } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { composeValidators, mustBeNumber, required } from 'util/validatorUtils';

interface Props {
}

const UpsertTrueScoreResultForm = (props: Props) => {

  const { language } = useAuthContext();
  const { state } = useLocation();
  const history = useHistory();
  const testConfId: number | undefined = state?.testConfId ? Number(state?.testConfId) : undefined;
  const testResultId: number | undefined = state?.testResultId ? Number(state?.testResultId) : undefined;
  const trueScoreResultIx: number | undefined = state.trueScoreResultIx >= 0 ? Number(state.trueScoreResultIx) : -1;
  const { t } = useTranslation('teresa');
  const { testResultFormData, addOrEditTrueScoreResult } = useTestResultFormDataContext();
  const prevPath: string | undefined = state?.prevPath ? state.prevPath : undefined;
  const trueScoreResultId: number | undefined = state?.trueScoreResultId ? Number(state.trueScoreResultId) : undefined;
  const cancelTokenSource = useMemo(axios.CancelToken.source, []);

  const trueResults: Array<DropdownOption> = Object.values(TrueResultEnum)
  .map((trueResultEnum, index): DropdownOption => (
    {
      key: index,
      text: t(`truescoreresult.${trueResultEnum}`),
      value: trueResultEnum,
    }
  ));

  const trueScoreResultTypes: Array<DropdownOption> = Object.values(TrueScoreResultType)
  .map((trueScoreResultTypes, index): DropdownOption => (
    {
      key: index,
      text: t(`trueScoreResultType.${trueScoreResultTypes}`),
      value: trueScoreResultTypes,
    }
  ));

  const [trueScoreResult, setTrueScoreResult] =
    useState<Partial<UpsertTrueScoreResultDto> | undefined>({ active: true });

  const [testResultOptions, setTestResultOptions] = useState<DropdownOption[]>([]);
  const [successMsg, setSuccessMsg] = React.useState<string | undefined>(undefined);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(true);

  useEffect(() => {
    setFormDataLoaded(false);
    if (testResultFormData) {
      setTimeout(() => {
        const trueScores = testResultFormData.trueScores;
        if ((trueScoreResultIx >= 0) && trueScores) {
          setTrueScoreResult(trueScores[trueScoreResultIx]);
        }
        setFormDataLoaded(true);
      }, 1200);
    } else {
      fetchFormData();
    }
  }, []);

  const fetchFormData = async () => {
    try {
      if (trueScoreResultId) {
        const upsertTrueScoreResult = await getTrueScoreResult(trueScoreResultId, cancelTokenSource);
        setTrueScoreResult(upsertTrueScoreResult);
      }

      const response = await getAllTestResults(cancelTokenSource);
      const testResultOptArray = mapToMultiLangDropdownOptionArray(response, 'description', language);
      setTestResultOptions(testResultOptArray);
    } catch (e) {
      handleError(e.response.data);
    } finally {
      setFormDataLoaded(true);
    }
  };

  const handleError = useCallback((error: any) => {
    if (error) {
      let errMsgs = [];
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.trueScoreResultNotFound,
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsgs.push(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          errMsgs.push(t(`error.${errorCode}`));
        } else {
          errMsgs.push(t('error.general'));
        }
      }

      setErrorMessage(errMsgs);
    }

  }, []);

  const setErrorMessage = (errMsgs?: string[]) => {

    if (errMsgs) {
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const goToPreviousPage = () => {
    if (prevPath === '/testresult') {
      history.push('/testresult', { testResultId: testResultId, testConfId: testConfId });
    } else {
      history.push('/truescorerule/search');
    }
  };

  const submitHandler = async (values: Partial<UpsertTrueScoreResultDto>) => {
    let saveValues: Partial<UpsertTrueScoreResultDto> = {
      ...values,
      testResultId: testResultFormData ? testResultId : values.testResultId,
      trueResult: values.trueResult,
    };

    const onSave = () => {
      trueScoreResultIx >= 0 || trueScoreResultId
        ? setSuccessMsg(t('testresult.onUpdate'))
        : setSuccessMsg(t('testresult.onAdd'));
      setTimeout(() => {
        goToPreviousPage();
      }, 1200);
    };

    if (testResultFormData) {
      addOrEditTrueScoreResult(trueScoreResultIx, saveValues, onSave);
    } else if (trueScoreResultId) {
      await editTrueScoreResult(trueScoreResultId, saveValues, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e.response.data));
    } else {
      await addTrueScoreResult(saveValues, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e.response.data));
    }
  };

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={(values: Partial<UpsertTrueScoreResultDto>) => submitHandler(values)}
        initialValues={trueScoreResult}
        subscription={{ pristine: true, submitting: true, values: true }}
        render={(formProps: FormRenderProps<UpsertTrueScoreResultDto>) => renderFormContent(formProps)}
      />
    );
  };

  const renderInformationSection = (values: any) => {

    let disabled = false;
    if(values.trueScoreResultType){
      disabled = true;
    }

    const leftInfoFields = [
      textInput({
        name: 'description',
      }),
      textInput({
        name: 'fromScoreRaw',
        validate: composeValidators(required, mustBeNumber),
      }),
      textInput({
        name: 'toScoreRaw',
        validate: composeValidators(required, mustBeNumber),
      }),
      checkBoxInput({
        name: 'active',
        toggle: true,
      }),
    ];

    let rightInfoFields = [
      selectInput({
          name: 'trueResult',
          validate: required,
        },
        trueResults,
      ),
      selectInput({
          name: 'trueScoreResultType',
          clearable: true
        },
        trueScoreResultTypes,
      ),
    ];

    if (!testResultFormData) {
      const testResultField = selectInput({
          name: 'testResultId',
          searchable: true,
          disabled: disabled,
          clearable: true
        },
        testResultOptions,
      );

      rightInfoFields.push(testResultField);
    }

    const mappedLeftRow = leftInfoFields.map((field, index) => {
      return (
        <React.Fragment key={index}>
          <Grid.Row className='scoreLabel'>
            <Grid.Column width={16} verticalAlign='middle'>
              <DataLabel>{t(`truescoreresult.${field.name}`)}</DataLabel>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16} verticalAlign='middle' className='rawScoreInput'>
              <Field
                fluid
                {...field}
              />
            </Grid.Column>
          </Grid.Row>
        </React.Fragment>
      );
    });

    const mappedRightRow = rightInfoFields.map((field, index) => {
      return (
        <React.Fragment key={index}>
          <Grid.Row className='scoreLabel'>
            <Grid.Column width={16} verticalAlign='middle'>
              <DataLabel>{t(`truescoreresult.${field.name}`)}</DataLabel>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16} verticalAlign='middle'>
              <Field
                fluid
                {...field}
              />
            </Grid.Column>
          </Grid.Row>
        </React.Fragment>
      );
    });

    return (
      <Grid.Row>
        <Grid.Column width={6}>
          <InnerTsaGrid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header as='h4'>{t(`truescoreresult.conditionsTitle`)}</Header>
              </Grid.Column>
            </Grid.Row>
            {mappedLeftRow}
          </InnerTsaGrid>
        </Grid.Column>
        <Grid.Column width={6}>
          <InnerTsaGrid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header as='h4'>{t(`truescoreresult.calculationTitle`)}</Header>
              </Grid.Column>
            </Grid.Row>
            {mappedRightRow}
          </InnerTsaGrid>
        </Grid.Column>
      </Grid.Row>
    );

  };

  const renderFormContent = (
    { handleSubmit, submitting, form, values }: FormRenderProps<UpsertTrueScoreResultDto>): React.ReactNode => {
    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          <Grid.Row>
            <Grid.Column width={16}>
              <div className='title-h1'>{t('truescoreresult.viewTitle')}</div>
            </Grid.Column>
          </Grid.Row>

          {errorMessages.length > 0 &&
            <Grid.Row>
              <Grid.Column width={12}>
                <div className='error'>
                  <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                    {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                  </StyledErrorMessage>
                </div>
              </Grid.Column>
            </Grid.Row>
          }

          <Grid.Row>
            <Grid.Column width={16}>
              <Grid stackable doubling columns={2}>
                {renderInformationSection(values)}
                <Grid.Row>
                  <Grid.Column width={12}
                               style={{ borderTop: '1px solid var(--very-light-blue)', marginTop: '2rem' }}
                  >
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row textAlign='right'>
            <Grid.Column width={12}>
              {
                successMsg &&
                <SaveAndUpdateConfirmationMessage>
                  {successMsg}
                </SaveAndUpdateConfirmationMessage>
              }
              <CompositeButton
                primary
                type='submit'
                className='action-button'
                disabled={submitting || !!successMsg}
              >
                {t('button.save')}
              </CompositeButton>
              <CompositeButton
                type='button'
                className='action-button'
                secondary
                disabled={submitting || !!successMsg}
                onClick={goToPreviousPage}
              >
                {t('action.back')}
              </CompositeButton>
            </Grid.Column>
          </Grid.Row>

        </TsaGrid>
      </form>
    );
  };

  return (
    <UpsertContentWrapperDiv>
      {formDataLoaded
        ? <React.Fragment>
          {renderFinalForm()}
        </React.Fragment>
        : <LoaderComponent message={t('truescoreresult.loading')} />
      }
    </UpsertContentWrapperDiv>
  );
};

export default UpsertTrueScoreResultForm;