import { useAuthContext } from 'auth/AuthContext';
import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import CompositeButton from 'components/final-form/CompositeButton';
import Input from 'components/final-form/Input';
import TabComponent from 'components/final-form/TabComponent';
import InnerTableActionButton from 'components/InnerTableActionButton';
import SearchDisplayContainer from 'components/SearchDisplayContainer';
import StyledErrorMessage from 'components/StyledErrorMessage';
import VirtualizedTable from 'components/VirtualizedTable';
import { FormState } from 'final-form';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Icon, Loader } from 'semantic-ui-react';
import { deleteExerciseConfById, searchExerciseConfs } from 'service/exerciseService';
import axios from 'service/http';
import styled from 'styled-components';
import { debounce } from 'ts-debounce';
import { ExerciseConfDto, ExerciseConfSearchRequest } from 'ts-types/api.types';
import { isoToDisplayDateTime } from 'util/dateUtils';
import { errorUtils } from 'util/errorUtils';
import { useAfterFirstRender } from 'util/functionUtils';
import { emptyTableCell, multiLanguageFieldCellRenderer, shortLabel } from 'util/tableUtils';

const StyledSpan = styled.span`
  display: flex;

  .patient-origin-id {
    margin-right: 0.5rem;
  }

  .exerciseconf-id {
    color: var(--primary-color);
  }
`;

interface ExerciseConfFieldProps extends FormState<any> {
  values: Partial<ExerciseConfSearchRequest>;
}

interface Props {

}

const cancelTokenSource = axios.CancelToken.source();

const ExerciseConfSearchView = (props: Props) => {

  const { push } = useHistory();
  const { t } = useTranslation('teresa');
  const { language, currentUser } = useAuthContext();

  const [exerciseConfs, setExerciseConfs] = useState<ExerciseConfDto[]>([]);
  const [exerciseConfsLoaded, setExerciseConfsLoaded] = useState<boolean>(true);

  const isSystemAdmin = useMemo(() => currentUser
    && currentUser.roles.includes('ROLE_APP_ADMIN'), [currentUser]);

  const isTestAdmin = useMemo(() => currentUser
    && currentUser.roles.includes('ROLE_TEST_ADMIN'), [currentUser]);

  const [searchValues, setSearchValues] = useState<Partial<ExerciseConfSearchRequest>>({
    exerciseConfSearchTerm: '',
  });
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  React.useEffect(() => {
    fetchExerciseConfs(searchValues);
  }, []);

  const fetchExerciseConfs = debounce((values: Partial<ExerciseConfSearchRequest>): void => {

    const onFinally = () => {
      setExerciseConfsLoaded(true);
    };

    setExerciseConfsLoaded(false);
    setExerciseConfs([]);

    let newSearchValues = { ...values };
    if (_.isEmpty(newSearchValues)) {
      newSearchValues = {
        exerciseConfSearchTerm: '',
        active: values.active,
      };
    }
    searchExerciseConfs(newSearchValues, cancelTokenSource)
    .then(response => {
        setExerciseConfs(response);
      },
    ).catch((e: any) => handleError(e.response.data))
    .finally(onFinally);

  }, 300);

  const onAddExerciseConf = () => {
    push('/exerciseconf');
  };

  const handleChange = useAfterFirstRender(({ values }: any): void => {
    setSearchValues(values);
    fetchExerciseConfs(values);
  });

  const setErrorMessage = useCallback((errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  }, [errorMessages]);

  const handleError = (error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.exerciseConfNotFound,
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={() => {}}
        initialValues={{
          exerciseConfSearchTerm: '',
        }}
        subscription={{ pristine: true, values: true }}
        render={renderSearchExerciseConf}
      />
    );
  };

  const renderSearchExerciseConf = ({ values }: FormRenderProps): React.ReactNode => {

    return (
      <>
        <div className='search-container'>
          <div className='title-h1' style={{ paddingLeft: '0.6rem' }}>{t('exerciseConf.searchTitle')}</div>

          <div className='search-form'>
            <Field
              id='exerciseConfSearchTerm'
              name='exerciseConfSearchTerm'
              component={Input}
              icon={'search'}
              iconPosition='left'
              placeholder={t('exerciseConf.placeholder')}
              autoFocus
              fluid
            />

            <CompositeButton
              type='button'
              className='action-button'
              primary
              onClick={onAddExerciseConf}
            >
              {t('button.add')}
            </CompositeButton>
            {isSystemAdmin || isTestAdmin ?
              <Link
                to='/export-exercises'
                target='_blank'
              >
                <CompositeButton
                  type='button'
                  to='/export-tests'
                  target='_blank'
                  className='action-button'
                  primary
                >
                  {t('button.export')}
                </CompositeButton>
              </Link>
              : <></>
            }

          </div>
        </div>

        {errorMessages.length > 0 &&
          <div className='error'>
            <StyledErrorMessage onDismiss={() => setErrorMessage()}>
              {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
            </StyledErrorMessage>
          </div>
        }

        <Field
          id='active'
          name='active'
          component={TabComponent}
        />

        <div className='data-table'>
          {exerciseConfsLoaded && renderExerciseConfTableSegment(values)}
          {!exerciseConfsLoaded &&
            <Loader className='table-loader' active inline content={t('exerciseConf.loading')} />}

        </div>

        <FormSpy subscription={{ values: true }} onChange={handleChange} />
      </>
    );
  };

  const exerciseConfsTableRowGetter = ({ index }: any) => {
    Object.assign(exerciseConfs[index], { index: index + 1 });
    return exerciseConfs[index];
  };

  const excerciseConfsTableRowRenderer = ({ className, columns, index, key, style }: any) => {
    const a11yProps = { 'aria-rowindex': index + 1 };

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role='row'
        style={style}
      >
        {columns}
      </div>
    );
  };

  const exerciseConfIdCellRenderer = ({ rowData }: any) => {

    if (rowData) {
      return (
        <StyledSpan>
          <div className='exerciseconf-id'>{rowData.id}</div>
        </StyledSpan>
      );
    }
  };

  const statusCellRenderer = ({ cellData }: any) => {

    if (cellData) {
      return <Icon name='check' size='small' />;
    }
    return emptyTableCell();
  };

  const deleteExerciseConf = (id: number, values: Partial<ExerciseConfSearchRequest>) => (): void => {
    deleteExerciseConfById(id, cancelTokenSource)
    .then(() => {
      fetchExerciseConfs(values);
    });
  };

  const translateDescription = (exercise: ExerciseConfDto) => {
    const description = exercise.description;

    const exerciseConfDescriptions: { [key: string]: string } = {
      'de': exercise.description || description,
      'en': exercise.descriptionEn || description,
      'fr': exercise.descriptionFr || description,
      'it': exercise.descriptionIt || description,
    };
    return exerciseConfDescriptions[language];
  }

  const actionsCellRenderer = (values: Partial<ExerciseConfSearchRequest>) => ({ rowData }: any) => {

    return (
      <div className='row-actions'>
        <InnerTableActionButton
          message={t('button.edit')}
          onConfirm={() => push(`/exerciseconf`, { id: rowData.id })}
          divider={true}
        />
        <DeleteRecordConfirmation triggerButtonText={t('button.delete')}
                                  confirmAction={deleteExerciseConf(rowData.id, values)}
                                  deleteConfirmationText={t('exerciseConf.confirmDelete', { description: translateDescription(rowData) })}
                                  position={"top left"} />
      </div>
    );
  };

  const ownerCellRenderer = ({ rowData }: any) => {
    if (rowData) {
      return <div>{rowData.owner} ({rowData.ownerAccountId})</div>
    }

  };

  const activityDateTimeCellRenderer = ({rowData}: any) => {

    if (rowData) {
      return <div>{isoToDisplayDateTime(rowData.activityDateTime)}</div>
    }
  };

  const renderExerciseConfTableSegment = (values: any) => {
    return (
      <VirtualizedTable
        rowCount={exerciseConfs.length}
        rowGetter={exerciseConfsTableRowGetter}
        rowRender={excerciseConfsTableRowRenderer}
        rowHeight={48}
        columns={[
          {
            width: 50,
            flexShrink: 0,
            label: t('exerciseConf.Id'),
            dataKey: 'id',
            cellRenderer: (exerciseConfIdCellRenderer),
          },
          {
            width: 200,
            flexGrow: 1,
            label: t('exerciseConf.description'),
            dataKey: 'description',
            cellRenderer: multiLanguageFieldCellRenderer('description', language),
          },
          {
            width: 150,
            flexGrow: 1,
            label: t("exerciseConf.owner"),
            dataKey: 'owner',
            cellRenderer: (ownerCellRenderer),
          },
          {
            width: 150,
            flexGrow: 1,
            label: t("exerciseConf.lastActivity"),
            dataKey: 'lastActivity',
            cellRenderer: (activityDateTimeCellRenderer)
          },
          {
            width: 100,
            label: shortLabel(t("exerciseConf.numOfExerciseParams"),t("exerciseConf.shortNumOfExerciseParams")),
            dataKey: 'numOfExerciseParams',
          },
          {
            width: 100,
            label: shortLabel(t("exerciseConf.numOfExerciseResults"),t("exerciseConf.shortNumOfExerciseResults")),
            dataKey: 'numOfExerciseResults',
          },
          {
            width: 100,
            label: shortLabel(t("exerciseConf.numOfExerciseConfInPatient"),
              t("exerciseConf.shortNumOfExerciseConfInPatient")),
            dataKey: 'numOfExerciseConfUsesInPatient',
          },
          {
            width: 50,
            label: t('exerciseConf.status'),
            dataKey: 'active',
            cellRenderer: statusCellRenderer,
          },
          {
            width: 200,
            flexShrink: 0,
            label: t('exerciseConf.action'),
            dataKey: 'id',
            cellRenderer: (actionsCellRenderer(values)),
          },
        ]}
      />
    );
  };

  return (
    <SearchDisplayContainer>
      {renderFinalForm()}
    </SearchDisplayContainer>
  );
};

export default ExerciseConfSearchView;