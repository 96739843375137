import {CancelTokenSource} from 'axios';
import axios, {backendUrls} from 'service/http';
import {
  PatientTestDto,
  PatientTestingActivityDto,
  PatientTestingActivityResponse,
  PatientTestingDto, PatientTestingRelative,
  QuestionAnswerRequest, SetEvaluationDto,
  UpsertPatientTestingDto,
  UpsertPatientTestResultDto,
} from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/patient-testing`;

export const getAllPatientTestings = (patientId: number, source: CancelTokenSource): Promise<PatientTestingDto[]> => {
  return axios
  .get(`${apiUri}/${patientId}/all`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getPatientTesting = (patientTestingId: number, source: CancelTokenSource):
  Promise<UpsertPatientTestingDto> => {
  return axios
    .get(`${apiUri}/${patientTestingId}`,  {cancelToken: source.token })
    .then(response => response.data);
};

export const getUpsertPatientTest = (patientTestId: number, source: CancelTokenSource):
  Promise<UpsertPatientTestResultDto> => {
  return axios
  .get(`${apiUri}/test/${patientTestId}`,  {cancelToken: source.token })
  .then(response => response.data);
};

export const getRecentActivities = (doctorId: number, source: CancelTokenSource):
  Promise<PatientTestingActivityResponse[]> => {
  return axios
  .get(`${apiUri}/recent-activities/${doctorId}`, {cancelToken: source.token})
  .then(response => response.data);
}

export const getPatientTestingActivities = (patientId: number, source: CancelTokenSource):
  Promise<PatientTestingActivityDto[]> => {
  return axios
    .get(`${apiUri}/patient-testing-activities/${patientId}`, {cancelToken: source.token})
    .then(response => response.data);
}

export const getPatientTestingRelatives = (patientTestingId: number, source: CancelTokenSource):
  Promise<PatientTestingRelative[]> => {
  return axios
  .get(`${apiUri}/${patientTestingId}/relative`, {cancelToken: source.token})
  .then(response => response.data);
}

export const addPatientTesting = (upsertPatientTestingDto: Partial<UpsertPatientTestingDto>, source:CancelTokenSource):
  Promise<PatientTestingDto> => {
  return axios
    .post(apiUri, upsertPatientTestingDto, {cancelToken: source.token})
    .then(response => response.data);
};

export const editPatientTesting = (patientTestingId: number, upsertPatientTestingDto: Partial<UpsertPatientTestingDto>, source:CancelTokenSource):
    Promise<PatientTestingDto> => {
  return axios
      .put(`${apiUri}/${patientTestingId}`, upsertPatientTestingDto, {cancelToken: source.token})
      .then(response => response.data);
};

export const saveEvaluation = (patientTestingId: number, evaluation: SetEvaluationDto, source:CancelTokenSource):
  Promise<PatientTestingDto> => {
  return axios
  .put(`${apiUri}/${patientTestingId}/save-evaluation`, evaluation, {cancelToken: source.token})
  .then(response => response.data);
};

export const getPatientTests = (patientTestingId: number, source: CancelTokenSource):
  Promise<PatientTestDto[]> => {
  return axios
    .get(`${apiUri}/patient-tests/${patientTestingId}`, {cancelToken: source.token})
    .then(response => response.data);
}

export const answerQuestions = (patientTestingQuestionSetId: number, request: Partial<QuestionAnswerRequest>, source:CancelTokenSource):
  Promise<void> => {
  return axios
  .put(`${apiUri}/${patientTestingQuestionSetId}/answer-questions`, request, {cancelToken: source.token})
  .then(response => response.data);
};

export const archivePatientTesting = (patientTestingId: number, source:CancelTokenSource):
  Promise<PatientTestingDto> => {
  return axios
  .put(`${apiUri}/${patientTestingId}/archive`, {cancelToken: source.token})
  .then(response => response.data);
};

export const deletePatientTestingArchive = (patientTestingId: number, source:CancelTokenSource):
  Promise<PatientTestingDto> => {
  return axios
    .delete(`${apiUri}/${patientTestingId}`, {cancelToken: source.token})
    .then(response => response.data)
};
