import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import AccordionWithArrow, { CustomContent, CustomTitle } from 'components/AccordionWithArrow';
import i18n from 'i18next';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItemProps, Icon, Input, Popup } from 'semantic-ui-react';
import { getTestConfsExport } from 'service/testConfExportService';
import styled from 'styled-components';
import {
  DomainDto,
  RawScoreResultExportDto,
  TestConfExportDto,
  TestConfSearchRequest,
  TestResultExportDto,
  TestResultParamDto,
  TrueScoreResultExportDto,
} from 'ts-types/api.types';
import { supportedLanguages } from 'util/localizationUtils';

const PopupTriggerContainer = styled.div`
  display: inline-block;
  position: relative;
  margin-inline: 5px;
`;

const StyledTextDisplayDiv = styled.div`
  display: inline-block;
  position: absolute;
  font-size: 17px;
  left: 40%;
  transform: translateX(-45%);
  top: 5%;
`;

const StyledPre = styled.div`
  white-space: pre-line;
  font: unset;
`;

const Label = styled.span`
  font-weight: bold;
  font-size: 15px;
`;

const FormBarLabel = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  margin-right: 5px;
`;

const FormBarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const TestExportView = () => {
  const [testConfs, setTestConfs] = useState<{ [index: string]: TestConfExportDto }>({});
  const [langOptions, setLangOptions] = useState<DropdownItemProps[]>([]);
  const [filteredTestConfs, setFilteredTestConfs] = useState<{ [index: string]: TestConfExportDto }>({});
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
  const [searchValues, setSearchValues] = useState<Partial<TestConfSearchRequest>>({
    testConfSearchTerm: '',
  });
  const [searchByTestResultParams, setSearchByTestResultParams] = useState<boolean>(false);
  const [searchByTests, setSearchByTests] = useState<boolean>(false);
  const [searchByTestResults, setSearchByTestResults] = useState<boolean>(false);
  const [searchByAll, setSearchByAll] = useState<boolean>(true);

  const [expandAll, setExpandAll] = useState(false);
  const [expandTests, setExpandTests] = useState(false);
  const [expandTestResults, setExpandTestResults] = useState(false);
  const [expandRawScoreRules, setExpandRawScoreRules] = useState(false);
  const [expandTrueScoreRules, setExpandTrueScoreRules] = useState(false);
  const [expandTestResultParams, setExpandTestResultParams] = useState(false);

  const currentLanguage = i18n.language;
  const cancelTokenSource = axios.CancelToken.source();
  const { updateLanguage } = useAuthContext();
  const { language } = useAuthContext();
  const { t } = useTranslation('teresa');

  const handleExpandAll = () => {
    const newExpandAll = !expandAll;
    setExpandAll(newExpandAll);

    const updatedTestConfs: { [index: string]: TestConfExportDto } = {};
    Object.keys(testConfs).forEach(key => {
      const conf = testConfs[key];
      updatedTestConfs[key] = {
        ...conf,
        expanded: newExpandAll,
        testResultMapExpanded: newExpandAll,
        testResultMap: Object.keys(conf.testResultMap).reduce((resultMap, resultKey) => {
          const result = conf.testResultMap[resultKey];
          resultMap[resultKey] = {
            ...result,
            expanded: newExpandAll,
            rawScoresExpanded: newExpandAll,
            trueScoresExpanded: newExpandAll,
            testResultParamsExpanded: newExpandAll,
          };
          return resultMap;
        }, {} as { [index: string]: TestResultExportDto }),
      };
    });

    setTestConfs(updatedTestConfs);
  };

  const handleExpandTests = () => {
    const newExpandTests = !expandTests;
    setExpandTests(newExpandTests);

    const updatedTestConfs = { ...testConfs };
    Object.keys(updatedTestConfs).forEach(key => {
      updatedTestConfs[key] = {
        ...updatedTestConfs[key],
        expanded: newExpandTests,
      };
    });

    setTestConfs(updatedTestConfs);
  };

  const handleExpandTestResults = () => {
    const newExpandTestResults = !expandTestResults;
    setExpandTestResults(newExpandTestResults);

    const updatedTestConfs = { ...testConfs };
    Object.keys(updatedTestConfs).forEach(key => {
      const conf = updatedTestConfs[key];
      updatedTestConfs[key] = {
        ...conf,
        testResultMapExpanded: newExpandTestResults,
        testResultMap: Object.keys(conf.testResultMap).reduce((resultMap, resultKey) => {
          const result = conf.testResultMap[resultKey];
          resultMap[resultKey] = {
            ...result,
            expanded: newExpandTestResults,
          };
          return resultMap;
        }, {} as { [index: string]: TestResultExportDto }),
      };
    });

    setTestConfs(updatedTestConfs);
  };

  const handleExpandRawScoreRules = () => {
    const newExpandRawScoreRules = !expandRawScoreRules;
    setExpandRawScoreRules(newExpandRawScoreRules);

    const updatedTestConfs = { ...testConfs };
    Object.keys(updatedTestConfs).forEach(key => {
      const conf = updatedTestConfs[key];
      if (conf.testResultMapExpanded) {
        updatedTestConfs[key] = {
          ...conf,
          testResultMap: Object.keys(conf.testResultMap).reduce((resultMap, resultKey) => {
            const result = conf.testResultMap[resultKey];
            if (result.expanded) {
              resultMap[resultKey] = {
                ...result,
                rawScoresExpanded: newExpandRawScoreRules,
              };
            } else {
              resultMap[resultKey] = { ...result };
            }
            return resultMap;
          }, {} as { [index: string]: TestResultExportDto }),
        };
      }
    });

    setTestConfs(updatedTestConfs);
  };

  const handleExpandTrueScoreRules = () => {
    const newExpandTrueScoreRules = !expandTrueScoreRules;
    setExpandTrueScoreRules(newExpandTrueScoreRules);

    const updatedTestConfs = { ...testConfs };
    Object.keys(updatedTestConfs).forEach(key => {
      const conf = updatedTestConfs[key];
      if (conf.testResultMapExpanded) {
        updatedTestConfs[key] = {
          ...conf,
          testResultMap: Object.keys(conf.testResultMap).reduce((resultMap, resultKey) => {
            const result = conf.testResultMap[resultKey];
            if (result.expanded) {
              resultMap[resultKey] = {
                ...result,
                trueScoresExpanded: newExpandTrueScoreRules,
              };
            } else {
              resultMap[resultKey] = { ...result };
            }
            return resultMap;
          }, {} as { [index: string]: TestResultExportDto }),
        };
      }
    });

    setTestConfs(updatedTestConfs);
  };

  const handleExpandTestResultParams = () => {
    const newExpandTestResultParams = !expandTestResultParams;
    setExpandTestResultParams(newExpandTestResultParams);

    const updatedTestConfs = { ...testConfs };
    Object.keys(updatedTestConfs).forEach(key => {
      const conf = updatedTestConfs[key];
      if (conf.testResultMapExpanded) {
        updatedTestConfs[key] = {
          ...conf,
          testResultMap: Object.keys(conf.testResultMap).reduce((resultMap, resultKey) => {
            const result = conf.testResultMap[resultKey];
            if (result.expanded) {
              resultMap[resultKey] = {
                ...result,
                testResultParamsExpanded: newExpandTestResultParams,
              };
            } else {
              resultMap[resultKey] = { ...result };
            }
            return resultMap;
          }, {} as { [index: string]: TestResultExportDto }),
        };
      }
    });

    setTestConfs(updatedTestConfs);
  };

  const searchTestConfs = useCallback(() => {
    if (!searchValues.testConfSearchTerm) {
      setFilteredTestConfs({ ...testConfs });
      return;
    }

    const newTestConfs = { ...testConfs };
    const filtered = Object.keys(newTestConfs).filter((key) => {
      const testConf = newTestConfs[key];
      const { testResultMap } = testConf;
      let testConfMatches = false;
      let resultMatches = false;
      let resultParamMatches = false;

      if (searchByAll || (searchByTests && !searchByTestResults && !searchByTestResultParams)) {
        if (getTestConfDescription(testConf).toLowerCase().includes(searchValues.testConfSearchTerm!.toLowerCase())) {
          testConfMatches = true;
        }
      }

      if (searchByAll || (searchByTestResults && !searchByTests && !searchByTestResultParams)) {
        Object.keys(testResultMap).forEach(resultKey => {
          const result = testResultMap[resultKey];
          if (getTestResultDescription(result).toLowerCase().includes(searchValues.testConfSearchTerm!.toLowerCase())) {
            resultMatches = true;
            testResultMap[resultKey] = { ...result, expanded: true };
          }
        });
      }

      if (searchByAll || searchByTestResultParams) {
        Object.keys(testResultMap).forEach(resultKey => {
          const result = testResultMap[resultKey];
          if (result.testResultParamMap && Object.values(result.testResultParamMap).some(param => {
            return getTestResultParamDescription(param).toLowerCase().includes(searchValues.testConfSearchTerm!.toLowerCase());
          })) {
            resultParamMatches = true;
            testResultMap[resultKey] = { ...result, testResultParamsExpanded: true, expanded: true };
          }
        });
      }

      if (resultMatches) {
        newTestConfs[key] = {
          ...testConf,
          expanded: true,
          testResultMapExpanded: true,
          testResultMap: { ...testResultMap },
        };
      } else if (resultParamMatches) {
        newTestConfs[key] = {
          ...testConf,
          expanded: true,
          testResultMapExpanded: true,
          testResultMap: { ...testResultMap },
        };
      } else if (testConfMatches) {
        newTestConfs[key] = { ...testConf, expanded: true };
      }

      return testConfMatches || resultMatches || resultParamMatches;
    }).reduce((acc, key) => {
      acc[key] = newTestConfs[key];
      return acc;
    }, {} as { [index: string]: TestConfExportDto });

    setTestConfs(newTestConfs);
    setFilteredTestConfs(filtered);
  }, [searchValues, searchByAll, searchByTests, searchByTestResults, searchByTestResultParams, language, testConfs]);

  const highlightText = (fullText: string, searchTerm: string) => {
    if (!searchTerm) return fullText;
    const escapedSearchTerm = searchTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    const regex = new RegExp(`(${escapedSearchTerm})`, 'gi');
    const parts = fullText.split(regex);
    return parts.map((part, index) =>
      regex.test(part) ? <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span> : part,
    );
  };

  useEffect(() => {
    if (timerId) {
      clearTimeout(timerId);
    }
    const id = setTimeout(searchTestConfs, 1000);
    setTimerId(id);

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [searchValues, searchByAll, searchByTests, searchByTestResults, searchTestConfs, searchByTestResultParams]);

  useEffect(() => {
    const updatedLangOptions = supportedLanguages.map((lang) => ({
      key: lang,
      value: lang,
      text: t(`language.${lang}`),
    }));
    setLangOptions(updatedLangOptions);

    getTestConfsExport(cancelTokenSource).then(r => setTestConfs(r));
  }, []);

  const instructionContent = (instruction: string) => {
    return (
      <StyledPre>
        {instruction}
      </StyledPre>
    );
  };

  const getTestResultParamDescription = (testResultParam: TestResultParamDto) => {
    const texts: { [key: string]: string } = {
      'de': testResultParam.description || '',
      'en': testResultParam.descriptionEn || testResultParam.description || '',
      'fr': testResultParam.descriptionFr || testResultParam.description || '',
      'it': testResultParam.descriptionIt || testResultParam.description || '',
    };
    return texts[language];
  };

  const getDomainDescription = (domain: DomainDto) => {
    const texts: { [key: string]: string } = {
      'de': domain.description || '',
      'en': domain.descriptionEn || domain.description || '',
      'fr': domain.descriptionFr || domain.description || '',
      'it': domain.descriptionIt || domain.description || '',
    };
    return texts[language];
  };

  const getTestResultDescription = (testResult: TestResultExportDto) => {
    const texts: { [key: string]: string } = {
      'de': testResult.description || '',
      'en': testResult.descriptionEn || testResult.description || '',
      'fr': testResult.descriptionFr || testResult.description || '',
      'it': testResult.descriptionIt || testResult.description || '',
    };
    return texts[language];
  };

  const getTestConfDescription = (testConf: TestConfExportDto) => {
    const texts: { [key: string]: string } = {
      'de': testConf.description || '',
      'en': testConf.descriptionEn || testConf.description || '',
      'fr': testConf.descriptionFr || testConf.description || '',
      'it': testConf.descriptionIt || testConf.description || '',
    };
    return texts[language];
  };

  const getInstructionTherapist = (testConf: TestConfExportDto) => {
    const texts: { [key: string]: string } = {
      'de': testConf.instructionTherapist || '',
      'en': testConf.instructionTherapistEn || testConf.instructionTherapist || '',
      'fr': testConf.instructionTherapistFr || testConf.instructionTherapist || '',
      'it': testConf.instructionTherapistIt || testConf.instructionTherapist || '',
    };
    return texts[language];
  };

  const getInstructionPatient = (testConf: TestConfExportDto) => {
    const texts: { [key: string]: string } = {
      'de': testConf.instructionPatient || '',
      'en': testConf.instructionPatientEn || testConf.instructionPatient || '',
      'fr': testConf.instructionPatientFr || testConf.instructionPatient || '',
      'it': testConf.instructionPatientIt || testConf.instructionPatient || '',
    };
    return texts[language];
  };

  const instructionPopupTrigger = (buttonText: string, instructionTrigger?: boolean) => {

    if (!instructionTrigger) {
      return false;
    }

    return (
      <PopupTriggerContainer>
        <Icon name='circle outline'
              style={{
                fontSize: '1.55rem',
              }}
        >
        </Icon>
        <StyledTextDisplayDiv>
          {buttonText}
        </StyledTextDisplayDiv>
      </PopupTriggerContainer>
    );
  };

  const instructions = (testConf: TestConfExportDto) => {
    return (
      <>
        {
          testConf.instructionTherapist
            ? <Popup
              trigger={instructionPopupTrigger(t('testconf.therapistInstruction'), !!testConf?.instructionTherapist)}
              content={instructionContent(getInstructionTherapist(testConf))}
              on='hover'
              size='large'
              position='bottom right'
              wide='very'
            />
            : <> --- </>
        }
        <> /</>
        {
          testConf.instructionPatient
            ? <Popup
              trigger={instructionPopupTrigger(t('testconf.patientInstruction'), !!testConf?.instructionPatient)}
              content={instructionContent(getInstructionPatient(testConf))}
              on='hover'
              size='large'
              position='bottom left'
              wide='very'
            />
            : <> --- </>
        }

      </>
    );
  };

  const formulaPopup = (rawScoreResultExportDto: RawScoreResultExportDto) => {
    return (
      <Popup
        trigger={instructionPopupTrigger(t('rawscoreresultshort.formula'), !!rawScoreResultExportDto?.formula)}
        content={instructionContent(rawScoreResultExportDto.formula)}
        on='hover'
        size='large'
        position='bottom center'
        wide='very'
      />
    );
  };

  const handleTestConfOnExpand = (id: string) => {
    setTestConfs(prevTestConfs => {
      const updatedTestConfs = { ...prevTestConfs };
      updatedTestConfs[id] = {
        ...updatedTestConfs[id],
        expanded: !updatedTestConfs[id].expanded,
      };
      return updatedTestConfs;
    });
  };

  const handleTestConfTestResultsOnExpand = (id: string) => {
    setTestConfs(prevTestConfs => {
      const updatedTestConfs = { ...prevTestConfs };
      updatedTestConfs[id] = {
        ...updatedTestConfs[id],
        testResultMapExpanded: !updatedTestConfs[id].testResultMapExpanded,
      };
      return updatedTestConfs;
    });
  };

  const handleTestResultOnExpand = (testResultid: string, testConfId: string) => {
    setTestConfs(prevTestConfs => {
      const updatedTestConfs = { ...prevTestConfs };
      const updatedTestResults = { ...updatedTestConfs[testConfId].testResultMap };
      updatedTestResults[testResultid] = {
        ...updatedTestResults[testResultid],
        expanded: !updatedTestResults[testResultid].expanded,
      };
      updatedTestConfs[testConfId] = {
        ...updatedTestConfs[testConfId],
        testResultMap: { ...updatedTestResults },
      };
      return updatedTestConfs;
    });
  };

  const handleRawScoresOnExpand = (testResultid: string, testConfIndex: string) => {
    setTestConfs(prevTestConfs => {
      const updatedTestConfs = { ...prevTestConfs };
      const updatedTestResults = { ...updatedTestConfs[testConfIndex].testResultMap };
      updatedTestResults[testResultid] = {
        ...updatedTestResults[testResultid],
        rawScoresExpanded: !updatedTestResults[testResultid].rawScoresExpanded,
      };
      updatedTestConfs[testConfIndex] = {
        ...updatedTestConfs[testConfIndex],
        testResultMap: { ...updatedTestResults },
      };
      return updatedTestConfs;
    });
  };

  const handleTrueScoresOnExpand = (testResultid: string, testConfIndex: string) => {
    setTestConfs(prevTestConfs => {
      const updatedTestConfs = { ...prevTestConfs };
      const updatedTestResults = { ...updatedTestConfs[testConfIndex].testResultMap };
      updatedTestResults[testResultid] = {
        ...updatedTestResults[testResultid],
        trueScoresExpanded: !updatedTestResults[testResultid].trueScoresExpanded,
      };
      updatedTestConfs[testConfIndex] = {
        ...updatedTestConfs[testConfIndex],
        testResultMap: { ...updatedTestResults },
      };
      return updatedTestConfs;
    });
  };

  const handleTestResultParamsOnExpand = (testResultid: string, testConfIndex: string) => {
    setTestConfs(prevTestConfs => {
      const updatedTestConfs = { ...prevTestConfs };
      const updatedTestResults = { ...updatedTestConfs[testConfIndex].testResultMap };
      updatedTestResults[testResultid] = {
        ...updatedTestResults[testResultid],
        testResultParamsExpanded: !updatedTestResults[testResultid].testResultParamsExpanded,
      };
      updatedTestConfs[testConfIndex] = {
        ...updatedTestConfs[testConfIndex],
        testResultMap: { ...updatedTestResults },
      };
      return updatedTestConfs;
    });
  };

  const handleRawScoreResultOnExpand = (testRawScoreResultId: string, testResultid: string, testConfIndex: string) => {
    setTestConfs(prevTestConfs => {
      const updatedTestConfs = { ...prevTestConfs };
      const updatedTestResults = { ...updatedTestConfs[testConfIndex].testResultMap };
      const updatedRawScoreResults = { ...updatedTestResults[testResultid].rawScoreResultMap };
      updatedRawScoreResults[testRawScoreResultId] = {
        ...updatedRawScoreResults[testRawScoreResultId],
        expanded: !updatedRawScoreResults[testRawScoreResultId].expanded,
      };
      updatedTestResults[testResultid].rawScoreResultMap = { ...updatedRawScoreResults };
      updatedTestConfs[testConfIndex] = {
        ...updatedTestConfs[testConfIndex],
        testResultMap: { ...updatedTestResults },
      };
      return updatedTestConfs;
    });
  };

  const renderTestResultParamContent = useCallback((testResult: TestResultExportDto) => {
    return Object.keys(testResult.testResultParamMap).map((testResultParamId: string) => {
      const testResultParam: TestResultParamDto = testResult.testResultParamMap[testResultParamId];
      return <div style={{ marginTop: '5px' }}>
        {(searchByTestResultParams || searchByAll)
          ? highlightText(
            getTestResultParamDescription(testResultParam),
            searchValues.testConfSearchTerm ? searchValues.testConfSearchTerm : '',
          )
          : getTestResultParamDescription(testResultParam)} / {testResultParam.unit} / {testResultParam.code}
      </div>;
    });
  }, [testConfs, searchValues]);

  const renderTrueScoreResultContent = useCallback((testResult: TestResultExportDto) => {
    return Object.keys(testResult.trueScoreResultMap).map((trueScoreResultId: string) => {
      const trueScoreResult: TrueScoreResultExportDto = testResult.trueScoreResultMap[trueScoreResultId];
      return <div style={{ marginTop: '5px' }}>
        <span>{trueScoreResult.fromScoreRaw} / </span>
        <span>{trueScoreResult.toScoreRaw} / </span>
        <span>{t(`truescoreresult.trueScore.${trueScoreResult.trueResult}`)} / </span>
        {trueScoreResult.trueScoreResultType}
      </div>;
    });
  }, []);

  const renderRawScoreResultContent = useCallback((
    testResult: TestResultExportDto,
    testResultId: string,
    testConfId: string,
  ) => {
    return Object.keys(testResult.rawScoreResultMap).map((rawScoreResultId: string) => {
      const rawScoreResult: RawScoreResultExportDto = testResult.rawScoreResultMap[rawScoreResultId];
      return <AccordionWithArrow expanded={rawScoreResult.expanded}
                                 onExpand={() => handleRawScoreResultOnExpand(rawScoreResultId, testResultId, testConfId)}>
        <CustomTitle>
          {rawScoreResult.ageRange} / {rawScoreResult.education} / {rawScoreResult.sex}
          {rawScoreResult.formula && <> / {formulaPopup(rawScoreResult)}</>}
        </CustomTitle>
        <CustomContent>
          <pre><code>{rawScoreResult.formula}</code></pre>
        </CustomContent>
      </AccordionWithArrow>;
    });
  }, []);

  const renderTestResultContent = useCallback((testConf: TestConfExportDto, testConfId: string) => {
    return Object.keys(testConf.testResultMap).map((testResultId: string) => {
      const testResult: TestResultExportDto = testConf.testResultMap[testResultId];
      const rawScoreMapLength = Object.keys(testResult.rawScoreResultMap).length;
      const trueScoreMapLength = Object.keys(testResult.trueScoreResultMap).length;
      const paramsMapLength = Object.keys(testResult.testResultParamMap).length;
      return <AccordionWithArrow expanded={testResult.expanded}
                                 onExpand={() => handleTestResultOnExpand(testResultId, testConfId)}>
        <CustomTitle>
          {(searchByTestResults || searchByAll)
            ? highlightText(getTestResultDescription(testResult), searchValues.testConfSearchTerm
              ? searchValues.testConfSearchTerm
              : '',
            )
            : getTestResultDescription(testResult)}
          {testResult.domain != null && <> / {getDomainDescription(testResult.domain)}</>}
        </CustomTitle>
        <CustomContent>
          {
            rawScoreMapLength > 0 &&
            <AccordionWithArrow
              expanded={testResult.rawScoresExpanded}
              onExpand={() => handleRawScoresOnExpand(testResultId, testConfId)}
            >
              <CustomTitle>
                <Label>{t('rawscoreresult.rules')} ({rawScoreMapLength}): </Label>
              </CustomTitle>
              <CustomContent>
                {renderRawScoreResultContent(testResult, testResultId, testConfId)}
              </CustomContent>
            </AccordionWithArrow>
          }
          {
            trueScoreMapLength > 0 &&
            <AccordionWithArrow
              expanded={testResult.trueScoresExpanded}
              onExpand={() => handleTrueScoresOnExpand(testResultId, testConfId)}
            >
              <CustomTitle>
                <Label>{t('truescoreresult.rules')} ({trueScoreMapLength}) : </Label>
              </CustomTitle>
              <CustomContent>
                {renderTrueScoreResultContent(testResult)}
              </CustomContent>
            </AccordionWithArrow>
          }
          {
            paramsMapLength > 0 &&
            <AccordionWithArrow
              expanded={testResult.testResultParamsExpanded}
              onExpand={() => handleTestResultParamsOnExpand(testResultId, testConfId)}
            >
              <CustomTitle>
                <Label>{t('testresult.params')} ({paramsMapLength}) : </Label>
              </CustomTitle>
              <CustomContent>
                {renderTestResultParamContent(testResult)}
              </CustomContent>
            </AccordionWithArrow>
          }
        </CustomContent>
      </AccordionWithArrow>;
    });
  }, [testConfs, searchValues]);

  const renderTestConfigRows = useCallback(() => {
    return <>
      {Object.keys(filteredTestConfs).map((testConfId) => {
        const testConf = testConfs[testConfId];
        const testResultMapLength = Object.keys(testConf.testResultMap).length;
        return (
          <AccordionWithArrow expanded={testConf.expanded} onExpand={() => handleTestConfOnExpand(testConfId)}>
            <CustomTitle>
              {(searchByTests || searchByAll)
                ? highlightText(getTestConfDescription(testConf), searchValues.testConfSearchTerm
                  ? searchValues.testConfSearchTerm
                  : '',
                )
                : getTestConfDescription(testConf)}
              <> /</>
              {instructions(testConf)}
              {testConf.scoreRulesType != null ? <> / {t(`scoreRulesType.${testConf.scoreRulesType}`)}</> : <> / ---</>}
            </CustomTitle>
            <CustomContent>
              {testResultMapLength > 0 &&
                <AccordionWithArrow expanded={testConf.testResultMapExpanded}
                                    onExpand={() => handleTestConfTestResultsOnExpand(testConfId)}>
                  <CustomTitle>
                    <Label>{t('patientTesting.testResultsTitle')} ({testResultMapLength}): </Label>
                  </CustomTitle>
                  <CustomContent>
                    {renderTestResultContent(testConf, testConfId)}
                  </CustomContent>
                </AccordionWithArrow>
              }
            </CustomContent>
          </AccordionWithArrow>
        );
      })}
    </>;
  }, [testConfs, filteredTestConfs]);

  const renderFormBar = () => {
    return (
      <div className='search-form' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div className='title-h1' style={{ paddingLeft: '0.6rem' }}>{t('testconf.searchTitle')}</div>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <FormBarLabel>
              <div>{t('testconf.expand')}:</div>
            </FormBarLabel>
            <FormBarContainer>
              <input
                type='checkbox'
                id='expandAll'
                checked={expandAll}
                onChange={handleExpandAll}
              />
              <label htmlFor='expandAll'>{t('sex.ALL')}</label>
            </FormBarContainer>
            <FormBarContainer>
              <input
                type='checkbox'
                id='expandTests'
                checked={expandTests}
                onChange={handleExpandTests}
              />
              <label htmlFor='expandTests'>{t('clinic.config.testConf')}</label>
            </FormBarContainer>
            <FormBarContainer>
              <input
                type='checkbox'
                id='expandTestResults'
                checked={expandTestResults}
                onChange={handleExpandTestResults}
              />
              <label
                style={{ whiteSpace: 'nowrap' }}
                htmlFor='expandTestResults'
              >
                {t('patientTesting.testResultsTitle')}
              </label>
            </FormBarContainer>
            <FormBarContainer>
              <input
                type='checkbox'
                id='expandRawScoreRules'
                checked={expandRawScoreRules}
                onChange={handleExpandRawScoreRules}
              />
              <label
                style={{ whiteSpace: 'nowrap' }}
                htmlFor='expandRawScoreRules'
              >
                {t('testconf.rawScoreRulesShort')}
              </label>
            </FormBarContainer>
            <FormBarContainer>
              <input
                type='checkbox'
                id='expandTrueScoreRules'
                checked={expandTrueScoreRules}
                onChange={handleExpandTrueScoreRules}
              />
              <label
                style={{ whiteSpace: 'nowrap' }}
                htmlFor='expandTrueScoreRules'
              >
                {t('testconf.scoreRulesType')}
              </label>
            </FormBarContainer>
            <FormBarContainer>
              <input
                type='checkbox'
                id='expandTestResultParams'
                checked={expandTestResultParams}
                onChange={handleExpandTestResultParams}
              />
              <label
                style={{ whiteSpace: 'nowrap' }}
                htmlFor='expandTestResultParams'
              >
                {t('patientTesting.params')}
              </label>
            </FormBarContainer>
          </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <FormBarContainer>
                <FormBarLabel>{t('button.search')}:</FormBarLabel>
              </FormBarContainer>
              <FormBarContainer>
                <input
                  type='checkbox'
                  id='searchByAll'
                  checked={searchByAll}
                  onChange={() => {
                    setSearchByTests(false);
                    setSearchByTestResults(false);
                    setSearchByAll(!searchByAll);
                  }}
                />
                <label htmlFor='searchByAll'>{t('sex.ALL')}</label>
              </FormBarContainer>
              <FormBarContainer>
                <input
                  type='checkbox'
                  id='searchByTests'
                  checked={searchByTests}
                  onChange={() => {
                    if (!searchByTestResults && searchByTests && !searchByTestResultParams) {
                      setSearchByAll(true);
                    } else setSearchByAll(false);
                    setSearchByTests(!searchByTests);
                  }}
                />
                <label htmlFor='searchByTests'>{t('clinic.config.testConf')}</label>
              </FormBarContainer>
              <FormBarContainer>
                <input
                  type='checkbox'
                  id='searchByTestResults'
                  checked={searchByTestResults}
                  onChange={() => {
                    if (searchByTestResults && !searchByTests && !searchByTestResultParams) {
                      setSearchByAll(true);
                    } else setSearchByAll(false);
                    setSearchByTestResults(!searchByTestResults);
                  }}
                />
                <label
                  style={{ whiteSpace: 'nowrap' }}
                  htmlFor='searchByTestResults'
                >
                  {t('patientTesting.testResultsTitle')}
                </label>
              </FormBarContainer>
              <FormBarContainer>
                <input
                  type='checkbox'
                  id='searchByTestResultParams'
                  checked={searchByTestResultParams}
                  onChange={() => {
                    if (!searchByTestResults && !searchByTests && searchByTestResultParams) {
                      setSearchByAll(true);
                    } else setSearchByAll(false);
                    setSearchByTestResultParams(!searchByTestResultParams);
                  }}
                />
                <label htmlFor='searchByTestResultParams'>{t('patientTesting.params')}</label>
              </FormBarContainer>
              <Input
                type='text'
                placeholder={t('button.search')}
                value={searchValues.testConfSearchTerm}
                onChange={(e) => setSearchValues({ ...searchValues, testConfSearchTerm: e.target.value })}
              />
              <Dropdown
                style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}
                className='language-menu-item'
                text={t(`language.${currentLanguage}`)}
                direction='left'
                icon='chevron up'
                value={currentLanguage}
                options={langOptions}
                onChange={(evt, data) => {
                  const value = data.value as string;
                  updateLanguage(value);
                }}
                selectOnBlur
                selectOnNavigation={false}
                fluid
              />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderFormBar()}
      {renderTestConfigRows()}
    </div>
  );
};
export default TestExportView;