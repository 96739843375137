import axios, { CancelTokenSource } from 'axios';
import { backendUrls } from 'service/http';
import { DisplayTestImageDto, TestImageDto, TestImageSearchRequest, UpsertTestImageDto } from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/testimage`;

export const getAllTestImages = (source: CancelTokenSource): Promise<TestImageDto[]> => {
  return axios
  .get(`${apiUri}/all`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getTestImageById = (testImageId: number, source: CancelTokenSource): Promise<UpsertTestImageDto> => {
  return axios
  .get(`${apiUri}/${testImageId}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getTestImageForDisplay = (
  testImageId: number,
  source: CancelTokenSource): Promise<DisplayTestImageDto> => {

  return axios
  .get(`${apiUri}/${testImageId}/display`, { cancelToken: source.token })
  .then(response => response.data);
};

export const searchTestImages = (
  request: Partial<TestImageSearchRequest>,
  source: CancelTokenSource): Promise<TestImageDto[]> => {
  return axios
  .post(`${apiUri}/search`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const addTestImage = (testImage: Partial<UpsertTestImageDto>, source: CancelTokenSource): Promise<TestImageDto> => {
  let bodyFormData = new FormData();
  Object.keys(testImage).forEach((key) => {
    let value = testImage[key as keyof UpsertTestImageDto];

    if (value) {
      //@ts-ignore
      bodyFormData.append(key, value);
    }
  });
  return axios
  .post(apiUri, bodyFormData, { cancelToken: source.token })
  .then((response) => response.data);
};

export const editTestImage = (
  testImageId: number,
  testImage: Partial<UpsertTestImageDto>,
  source: CancelTokenSource): Promise<TestImageDto> => {

  let bodyFormData = new FormData();
  Object.keys(testImage).forEach((key) => {
    let value = testImage[key as keyof UpsertTestImageDto];

    if (value) {
      //@ts-ignore
      bodyFormData.append(key, value);
    }
  });
  return axios
  .put(`${apiUri}/${testImageId}`, bodyFormData, { cancelToken: source.token })
  .then((response) => response.data);
};

export const deleteTestImageById = (testImageId: number, source: CancelTokenSource): Promise<string> => {

  return axios
  .delete(`${apiUri}/${testImageId}`, { cancelToken: source.token })
  .then(response => response.data);
};
