import { useAuthContext } from 'auth/AuthContext';
import axios, { AxiosError } from 'axios';
import EditPopUp from 'components/EditPopUp';
import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import FinalFormInput from 'components/final-form/Input';
import PatientInfoHeaderComponent from 'components/final-form/PatientInfoHeaderComponent';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import SpecialLabel from 'components/final-form/SpecialLabel';
import TextArea from 'components/final-form/TextArea';
import FinalFormTimer from 'components/final-form/TimeComponent';
import FormulaPopupContent from 'components/FormulaPopupContent';
import { defaultPopupStyle } from 'components/HeaderButton';
import InnerTsaGrid from 'components/InnerTsaGrid';
import InstructionPopupContent from 'components/InstructionPopoupContent';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import { FormApi } from 'final-form';
import createDecorator from 'final-form-calculate';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import TextInputOverImage from 'routes/patienttesting/TextInputOverImage';
import { Grid, Icon, Popup } from 'semantic-ui-react';
import {
  calculateFormulaTest,
  calculateScoringTest,
  getAvailableTestResultParams,
  getTestConf,
  getTestConfImagesForDisplay,
  getTestResults,
} from 'service/clinicAdminConfService';
import { getPatientDetails } from 'service/patientService';
import { createPatientTestingResult } from 'service/patientTestingResultService';
import { getPatientTesting, getUpsertPatientTest } from 'service/patientTestingService';
import styled from 'styled-components';
import { UnitType, PatientTestingStatus as TestingStatus } from 'ts-types/api.enums';
import {
  DisplayTestImageDto,
  FormulaTestDto,
  InvalidInputDto,
  ObjectValidationErrorDto,
  ParamWithValueDto,
  PatientDto,
  PatientTestingStatus,
  ScoringTestDto,
  ScoringTestParamsDto,
  TestResultDto,
  TestResultParamDto,
  TestResultWithParams,
  UpsertPatientTestImageNoteDto,
  UpsertPatientTestingDto,
  UpsertPatientTestResultDto,
  UpsertTestConfDto,
} from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { noop } from 'util/functionUtils';
import { getMinMaxDescriptionTitle } from 'util/stringUtils';
import { composeValidators, isValueValidNumber, minMaxValueValidator, mustBeNumber } from 'util/validatorUtils';

const ParamContainer = styled.div`
  max-height: 500px;
  overflow-y: auto;
  
  .special-label-class {
    @media only screen and (max-width: 767px) {
      margin-left: 15px;
    }
  }
  
  .data-label-class {
    @media only screen and (max-width: 767px) {
      margin-left: 15px;
    }
  }

  .params-row-class {
    width: 100%;
    min-height: fit-content !important;
    display: inline-table;
  }
  
  .results-column-class {
    padding-top: 7px;
    
    @media only screen and (max-width: 767px) {
      padding-bottom: 7px;
    }
  }

  @media only screen and (max-width: 767px) {
    overflow-x: hidden;
    overflow-y: inherit;
  }
`;

const PopupTriggerContainer = styled.div`
  display: inline-block;
  position: relative;
  margin-left: 20px;
`;

const ParamsRow = styled(Grid.Row)`
  display: flex;
  max-height: 35px;
  background-color: var(--very-light-gray);
  border-radius: 5px;
  margin-bottom: 0.5rem;
  
  .column:first-child {
    flex-grow: 1;
  }

  @media only screen and (max-width: 767px) {
    margin-bottom: 0.5rem !important;
    min-height: 110px !important;
  }
`;

const ParamsColumn = styled(Grid.Column)`
  display: flex;
  font-size: 15px;
  max-height: 100%;
  padding-top: 10px;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  
  .timer-column {
    padding-left: 0 !important;
  } 
  
  .time-duration-class {
    & .ui.input > input {
      @media only screen and (max-width: 767px) {
        margin-left: 14px;
      }
    }
  }

  .seconds-label {
    @media only screen and (max-width: 767px) {
      margin-left: 0 !important;
    }
  }
  
  input {
    max-height: 35px;
    text-align: right !important;
  }

  & .ui.input > input {
    @media only screen and (max-width: 767px) {
      margin-left: 25px;
      margin-right: 25px;
    }
  }
`;

const ParamsDiv = styled.div`
  padding-left: 1rem !important;
  margin-top: 5px;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media only screen and (max-width: 767px) {
    margin-left: 10px;
  }
`;

const TimerDiv = styled.div`
  // margin-top: 0px;
`;

const TestParamsDiv = styled.div`
  font-weight: 600;
  padding-top: 15px;
  padding-left: 1rem;
`

const ResultsColumn = styled(Grid.Column)`
  font-size: 15px;
  max-height: 100%;
  min-height: 40px;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  
  input {
    max-height: 35px;
  }
`;

const TestData = styled.textarea`
  width: 100%;
  padding-left: 1rem;
  font-weight: 600;
  min-height: 180px;
  overflow: auto;
  line-height: 30px;
  background-color: var(--very-light-gray);
  border: none;
`;

const StyledTextDisplayDiv = styled.div`
  display: inline-block;
  position: absolute;
  font-size: 22px;
  left: 45%;
  transform: translateX(-45%);
  top: 5%;
`

const StyledTabs = styled.div<{ numOfParams: number }>`
  display: flex;
  overflow: hidden;

  .tab {
    cursor: pointer;
    padding: 5px 20px 10px;
    color: var(--dark-gray);
    margin-top: 10px;
    font-weight: 600;
    border-bottom: 2px solid transparent;
    transition: 200ms all ease-in-out;
  }

  .tab.selected {
    border-bottom-color: var(--primary-color);
  }

  @media only screen and (min-width: 821px) and (max-width: 1500px) {
    flex-wrap: ${(props) => props.numOfParams > 6 ? 'wrap' : 'nowrap'};
  }

  @media only screen and (max-width: 820px) {
    flex-wrap: wrap;
  }
`;

const QualitativeComment = styled(Grid.Row)`
  display: flex;
  background-color: var(--very-light-gray);
  border-radius: 5px;
  padding-bottom: 10px !important;
  padding-left: 10px !important;
  margin-bottom: 0.5rem;
`;

const TimerRow = styled(Grid.Row)`
  display: flex;
  background-color: var(--very-light-gray);
  border-radius: 5px;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  margin-bottom: 0.5rem;

  .column:first-child {
    flex-grow: 1;
  }
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  
  & > .ui.button:last-child {
    margin-right: 0;
  }
`;

const StyledPre = styled.div`
  white-space: pre-line;
  font: unset;
`;

export interface DisplayImage {
  image: string,
  fileType: string
}

const cancelTokenSource = axios.CancelToken.source();
const isDesktop = window.innerWidth >= 992;

const StartTestingForm = () => {

  const { state } = useLocation();

  const patientTestingId: number | undefined = state?.patientTestingId ? Number(state?.patientTestingId) : undefined;
  const questionSetId: number | undefined = state?.questionSetId ? Number(state?.questionSetId): undefined;
  const patientTestingQuestionSetId: number | undefined = state?.patientTestingQuestionSetId
    ? Number(state?.patientTestingQuestionSetId)
    : undefined;

  const prevPath: string | undefined = state?.prevPath ? state?.prevPath : undefined;
  const { t } = useTranslation('teresa');
  const { language, currentUser } = useAuthContext();
  const history = useHistory();
  const isAdmin = useMemo(() => currentUser
    && currentUser.roles.includes('ROLE_APP_ADMIN' || 'ROLE_TEST_ADMIN'), [currentUser]);

  const [patientTesting, setPatientTesting] = useState<UpsertPatientTestingDto>()

  const [currentPatientTest, setCurrentPatientTest] =
    useState<Partial<UpsertPatientTestResultDto>>({});
  const [patient, setPatient] = useState<PatientDto>();
  const [testConf, setTestConf] = useState<Partial<UpsertTestConfDto>>();
  const [testImages, setTestImages] = useState<DisplayTestImageDto[]>();
  const [testConfResults, setTestConfResults] = useState<TestResultDto[]>();
  const [testResultParamMap, setTestResultParamMap] = useState<{ [key: number]: TestResultParamDto }>([]);
  const [counterIndex, setCounterIndex] = useState<number>(0);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [activeImgIx, setActiveImgIx] = useState<number>(0);
  const [formulaTest, setFormulaTest] = React.useState<number>();
  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(false);
  const [successMsg, setSuccessMsg] = React.useState<string | undefined>(undefined);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [hideTimerDisplay, setHide] = React.useState<boolean>(false);

  useEffect(() => {
    fetchPatientTests();
  }, []);

  const handleError = (error: AxiosError<InvalidInputDto>) => {

    const { response } = error;
    if (response) {
      const { data: { errorCode, violations } } = response;

      const knownErrors: Array<string> = [
        errorUtils.patientNotFound,
        errorUtils.patientTestNotFound,
        errorUtils.patientTestingNotFound,
        errorUtils.testconfNotFound
      ];

      if (violations && violations.length > 0) {
        violations.forEach((violation: ObjectValidationErrorDto) => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      } else {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };

  const setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const fetchPatientTests = async () => {
    if (patientTestingId) {
      setFormDataLoaded(false);
      try {
        const patientTesting = await getPatientTesting(patientTestingId, cancelTokenSource);
        setPatientTesting(patientTesting);
        const patientTests = patientTesting.patientTests;

        const patientData = await getPatientDetails(patientTesting.patientId, cancelTokenSource);
        setPatient(patientData);

        const allTestResultParams = await getAvailableTestResultParams(cancelTokenSource);
        setTestResultParamMap(_.keyBy(allTestResultParams, 'id'));

        let newCounterIndex = patientTests.findIndex(pt => !pt.completed);
        newCounterIndex = newCounterIndex > 0 ? newCounterIndex : 0
        setCounterIndex(newCounterIndex);

        const patientTest: Partial<UpsertPatientTestResultDto> =
          await getUpsertPatientTest(patientTests[newCounterIndex].id, cancelTokenSource);
        setCurrentPatientTest({...currentPatientTest, ...patientTest});

        const fetchedTestConf: Partial<UpsertTestConfDto> =
          await getTestConf(patientTest!.testConfId!, cancelTokenSource);

        const fetchedTestImages : DisplayTestImageDto[] =
          await getTestConfImagesForDisplay(patientTest!.testConfId!, cancelTokenSource);

        setTestConf(fetchedTestConf);
        setTestImages(fetchedTestImages);
        if (fetchedTestImages.length) {
          setActiveImgIx(0);
        }

        const testResults = await getTestResults(patientTest!.testConfId!, cancelTokenSource);

        const activeTestResults = testResults.filter(tr => tr.active);
        setTestConfResults(activeTestResults);
      } catch (e) {
        handleError(e);
      } finally {
        setFormDataLoaded(true);
      }
    }
  };

  const imageViewMap = useMemo((): { [lang: string]: DisplayImage } => {

    if (testImages && testImages.length && activeImgIx !== undefined) {
      const testImage = testImages[activeImgIx];
      const defaultImage = { image: testImage.imageView, fileType: testImage.imageFileType };
      return {
        'de': defaultImage,
        'en': { image: testImage.imageViewEn || testImage.imageView, fileType: testImage.imageEnFileType || testImage.imageFileType },
        'fr': { image: testImage.imageViewFr || testImage.imageView, fileType: testImage.imageFrFileType || testImage.imageFileType },
        'it': { image: testImage.imageViewIt || testImage.imageView, fileType: testImage.imageItFileType || testImage.imageFileType },
      };
    }

    return {};

  }, [language, testImages, activeImgIx, testConf]);

  const saveNote = (values: any, form: FormApi) =>
    (upsertPatientTestImageNoteDto: Partial<UpsertPatientTestImageNoteDto>, index?: number) => {

    if (_.isEmpty(upsertPatientTestImageNoteDto.note)) {
      return;
    }

      let newArr = [...values.testImageNotes];
      if (index !== undefined && index >= 0) {
        newArr[index] = upsertPatientTestImageNoteDto;
      } else {
        newArr = [...newArr, upsertPatientTestImageNoteDto];
      }

      form.change('testImageNotes', newArr);
    };

  const renderImages = useCallback((values: any, form: FormApi) => {

    if (!imageViewMap[language] || activeImgIx === undefined) {
      return <></>;
    }

    return <Grid.Row>
          <Grid.Column width={16}>
            <DataLabel>{t('testconf.image')}</DataLabel>
            <ActionButtonContainer style={{marginBottom: "10px"}}>
              <CompositeButton
                secondary
                type='button'
                className='action-button'
                disabled={activeImgIx === 0}
                onClick={prevImage}
              >
                {t('action.previous')}
              </CompositeButton>
              <CompositeButton
                secondary
                type='button'
                className='action-button'
                disabled={activeImgIx === testImages!.length - 1}
                onClick={nextImage}
              >
                {t('action.next')}
              </CompositeButton>
            </ActionButtonContainer>
            {testImages && testImages.length && testImages[activeImgIx] &&
              <TextInputOverImage testImage={testImages[activeImgIx]}
                                  notes={values.testImageNotes}
                                  saveNote={saveNote(values, form)}

              />
            }
            <ActionButtonContainer style={{marginTop: "5px"}}>
              <CompositeButton
                secondary
                type='button'
                className='action-button'
                disabled={activeImgIx === 0}
                onClick={prevImage}
              >
                {t('action.previous')}
              </CompositeButton>
              <CompositeButton
                secondary
                type='button'
                className='action-button'
                disabled={activeImgIx === testImages!.length - 1}
                onClick={nextImage}
              >
                {t('action.next')}
              </CompositeButton>
            </ActionButtonContainer>
          </Grid.Column>
        </Grid.Row>;
  }, [activeImgIx, imageViewMap, testImages]);

  const submitHandler = async (values: UpsertPatientTestResultDto) => {
    // TODO OC: we should not save if there are errors in the form, where is the error display that
    // mandatory fields are not filled with value.
    // Is not submit already saving the data somehow?

    const onSave = (status: PatientTestingStatus) => {
      setSuccessMsg(t('patientTesting.addSuccess'));
      setTimeout(() => {
        if (status === 'COMPLETED') {
          history.push('/preview-results', {
            patientTestingId: patientTestingId,
            patientTestingQuestionSetId: patientTestingQuestionSetId,
            questionSetId: questionSetId,
            patient: patient,
            prevPath: prevPath,
          });
          return;
        }
        if (prevPath === '/') {
          history.push(prevPath, { patientTestingQuestionSetId: patientTestingQuestionSetId, questionSetId: questionSetId });
        } else if (prevPath === '/doctor/details') {
          history.push(prevPath!, {
            id: patient?.treaterId,
            patientTestId: currentPatientTest.patientTestId,
          })
        }
        else {
          history.push('/patient/details', {
            id: patientTesting?.patientId,
            patientTestId: currentPatientTest.patientTestId,
          });
        }
      }, 1200);
    };

    try {
      const request = {
        ...values,
        complete: true
      };

      const patientTestingProgressDto = await savePatientTestingResult(request);
      onSave(patientTestingProgressDto.status);
    }
    catch (e) {
      handleError(e.response.data);
    }
  };

  const setPatientTestData = async (index: number) => {

    const patientTests = patientTesting!.patientTests;
    setFormDataLoaded(false);

    try {
      const newPatientTest: Partial<UpsertPatientTestResultDto> =
        await getUpsertPatientTest(patientTests[index].id, cancelTokenSource);

      const otherTestConf = await getTestConf(newPatientTest.testConfId!, cancelTokenSource);
      const otherTestImages : DisplayTestImageDto[] =
        await getTestConfImagesForDisplay(newPatientTest!.testConfId!, cancelTokenSource);

      const otherTestResults = await getTestResults(newPatientTest!.testConfId!, cancelTokenSource);

      const activeOtherTestResults = otherTestResults.filter(otr => otr.active);

      setCurrentPatientTest(newPatientTest);

      setTestConf(otherTestConf);
      setTestImages(otherTestImages);
      if (otherTestImages.length) {
        setActiveImgIx(0);
      }

      setTestConfResults(activeOtherTestResults);
    } catch (e) {

    } finally {
      setFormDataLoaded(true);
    }
  };

  const previousHandler = async (values: Partial<UpsertPatientTestResultDto>, form: FormApi) => {
    if(form.getState().dirty) {
      // TODO OC: where is the validation of the mandatory fields or numbers.
      savePatientTestingResult(values);
    }

    let index = indexHandler(-1);
    await setPatientTestData(index);
    setTabIndex(0);
  };

  const nextHandler = async (values: Partial<UpsertPatientTestResultDto>, form: FormApi) => {
    if(form.getState().dirty) {
      // TODO OC: where is the validation of the mandatory fields or numbers.
      await savePatientTestingResult(values);
    }

    let index = indexHandler(1);
    await setPatientTestData(index);
    setTabIndex(0);
  };

  const savePatientTestingResult = async (values: Partial<UpsertPatientTestResultDto>) => {

    const patientTestingResult: UpsertPatientTestResultDto = {
      // @ts-ignore
      patientTestingId: patientTestingId,
      // @ts-ignore
      patientTestId: values.patientTestId,
      // @ts-ignore
      testConfId: values.testConfId,
      // @ts-ignore
      testParams: values.testParams,
      // @ts-ignore
      testResultWithParams: values.testResultWithParams,
      // @ts-ignore
      testImageNotes: values.testImageNotes,
      // @ts-ignore
      qualitativeComment: values.qualitativeComment,
      // @ts-ignore
      complete: true
    }

    return await createPatientTestingResult(patientTestingResult, cancelTokenSource);
  }

  const savePatientTestingResultAndGoToEdit = (values: Partial<UpsertPatientTestResultDto>) => {

      const onSave = () => {
        setTimeout(() => {
            history.push("/patient-testing", {
              patientTestingId: patientTestingId,
              patientId: patient?.id,
              testTemplateId: patientTesting?.testTemplateId,
              prevPath: prevPath
            })
          }, 1200)
      }

    const patientTestingResult: UpsertPatientTestResultDto = {
      // @ts-ignore
      patientTestingId: patientTestingId,
      // @ts-ignore
      patientTestId: values.patientTestId,
      // @ts-ignore
      testConfId: values.testConfId,
      // @ts-ignore
      testParams: values.testParams,
      // @ts-ignore
      testResultWithParams: values.testResultWithParams,
      // @ts-ignore
      testImageNotes: values.testImageNotes,
      // @ts-ignore
      qualitativeComment: values.qualitativeComment,
      // @ts-ignore
      complete: true,
    };

      if (patientTestingId) {
        createPatientTestingResult(patientTestingResult, cancelTokenSource)
        .then(onSave)
        .catch((e:any) => handleError(e.response.data))
      }

    };

  const goToEditTestSet = () =>  {
      setTimeout(() => {
        history.push("/patient-testing", {
          patientTestingId: patientTestingId,
          patientId: patient?.id,
          testTemplateId: patientTesting?.testTemplateId,
          prevPath: prevPath
        })
      }, 1200)
  };

  const indexHandler = (count: number) => {

    let index = counterIndex + count;

    setCounterIndex(index);
    return index;
  };

  const goToPreviousPage = () => {
    if (prevPath === '/') {
      history.push(prevPath);
    } else {
      history.push(prevPath!, {
        id: prevPath === '/patient/details'
          ? patientTesting?.patientId
          : patient?.treaterId,
      });
    }
  };

  const goToAnswerQuestions = () => {
    history.push('/answer-questions',
      {
        patientTestingId,
        patientTestingQuestionSetId,
        patientId: patientTesting?.patientId,
        prevPath: '/start-testing',
      },
    );
  };

  const testCounter = () => {
    const patientTests = patientTesting ? patientTesting.patientTests : [];
    return `${counterIndex + 1}/${patientTests.length}`;
  }

  const onTabClickHandler = async (index: number) => {
    await setTabIndex(index);
  };

  const testResultTabsRenderer = (): React.ReactNode => {
    const tabs = testConfResults
      ? testConfResults.map((result, index) => {
        const defaultDescription = result.description;

        const texts: { [key: string]: string } = {
          'de': defaultDescription,
          'en': result.descriptionEn || defaultDescription,
          'fr': result.descriptionFr || defaultDescription,
          'it': result.descriptionIt || defaultDescription,
        };

        let description = texts[language];
        let descriptionShort = texts[language];

        // TODO OC: implement proper with div overflow hidden but not to destroy image rendering and flex
        if ((testImages && testImages.length) && (descriptionShort && descriptionShort.length > 30)) {
          descriptionShort = `${descriptionShort.substring(0, 30)}...`;
        }
        return (
          <div key={result.id}
               className={`tab ${tabIndex === index && 'selected'}`}
               onClick={() => onTabClickHandler(index)}
          >
              <span title={description}>
                {descriptionShort}
              </span>
          </div>
        );
      })
      : [];

    return (
      <StyledTabs numOfParams={tabs.length}>
        {tabs}
      </StyledTabs>
    )
  }

  const calculateResults = async (values: Partial<UpsertPatientTestResultDto>, form: FormApi) => {
    let params: ScoringTestParamsDto[] = [];
    let resultParams: ScoringTestParamsDto[] = [];
    let param: ScoringTestParamsDto;
    let resultParam: ScoringTestParamsDto;

    const setErrorMessagesForTestResult = (error: string | null, errorDetails: string | null) => {

      if (!error && !errorDetails) {
        form.change(`testResultWithParams[${tabIndex}].calculationError`, null);
        form.change(`testResultWithParams[${tabIndex}].calculationErrorDetails`, null);
      }

      if (error || errorDetails) {
        form.change(`testResultWithParams[${tabIndex}].trueResult`, null);
      }

      if (error) {
        form.change(`testResultWithParams[${tabIndex}].calculationError`, error);
      }

      if (errorDetails) {
        form.change(`testResultWithParams[${tabIndex}].calculationErrorDetails`, errorDetails);
      }
    };

    const age = patientTesting?.patientAge;

    if(currentPatientTest.testParams) {
      currentPatientTest.testParams.forEach( (p, index) =>{
        const paramId = p.paramId;

        param = {
          code: testResultParamMap[paramId].code,
          // @ts-ignore
          value: values.testParams[index].resultNumber,
          unitType: testResultParamMap[paramId].unit
        }
        params.push(param);
      })
    }

    if (currentPatientTest
      && currentPatientTest.testResultWithParams
      && currentPatientTest.testResultWithParams.length
      && currentPatientTest.testResultWithParams[tabIndex]) {
      currentPatientTest.testResultWithParams[tabIndex].paramDtoList
      .forEach((par, index) => {
        const paramId = par.paramId;
        if (!isNaN(+values.testResultWithParams![tabIndex].paramDtoList[index].resultNumber)) {
          resultParam = {
            code: testResultParamMap[paramId].code,
            value: +values.testResultWithParams![tabIndex].paramDtoList[index].resultNumber,
            unitType: testResultParamMap[paramId].unit,
          };
          resultParams.push(resultParam);
        }
      });
    }

    if(testConfResults && testConfResults[tabIndex].id) {

      let scoringTest: Partial<ScoringTestDto> = {
        testResultId: testConfResults[tabIndex].id,
        age: age,
        sex: patient?.gender,
        schoolYears: patientTesting?.patientSchoolYears,
        testDuration: values.testResultWithParams
        && values.testResultWithParams.length
        && values.testResultWithParams[tabIndex]
          ? values.testResultWithParams[tabIndex].testDuration
          : undefined,
        params: params,
        resultParams: resultParams,
      };

      if(scoringTest) {
        try {
          await calculateScoringTest(scoringTest, cancelTokenSource)
          .then(response => {
            form.change(`testResultWithParams[${tabIndex}].normValue`, response.result);
            form.change(`testResultWithParams[${tabIndex}].rawValue`, response.rawValue)
            form.change(`testResultWithParams[${tabIndex}].trueResult`, response.trueResult);
            form.change(`testResultWithParams[${tabIndex}].formula`, response.formula);
            setErrorMessagesForTestResult(response.error, null);
          })
        } catch (e) {
          const error = e.response.data;
          if (error && error.violations && error.violations.length > 0
            && error.violations[0]
            && error.violations[0].errorCode
            && error.violations[0].details) {
            setErrorMessagesForTestResult(error.violations[0].errorCode, error.violations[0].details)
          }
        }
      }
    }
  }

  const paramCheck = (form: FormApi) => {
    const values = form.getState().values;

    if (values.testResultWithParams[tabIndex].paramDtoList) {
        calculateResults(values, form);
    }
  }

  const instructionsRenderer = (instruction: string | undefined) => {
      return (
        <Popup content={<InstructionPopupContent>
          {instruction}
        </InstructionPopupContent>}
               on='hover'
               trigger={
                 <div>
                   <TestData disabled>{instruction}</TestData>
                 </div>
               }
               popperModifiers={[{ name: 'preventOverflow', options: { boundary: 'window' } }]}
               size='small'
               position='top left'
               style={defaultPopupStyle}
               hoverable
        />
      );
  }

  const resultsRenderer = (testResultWithParams: TestResultWithParams) => {

    const formula = testResultWithParams.formula;
    const normValue = testResultWithParams.normValue;
    const rawValue = testResultWithParams.rawValue;
    const trueResult = testResultWithParams.trueResult;

    let data: (string | number)[] = [normValue];

    if (_.isNumber(rawValue)) {
      data.unshift(Number(rawValue).toFixed(2));
    }

    if (trueResult) {
      data.push(t(`truescoreresult.trueScore.${trueResult}`));
    }

    if (testResultWithParams.calculationErrorDetails) {
      data.push(testResultWithParams.calculationErrorDetails);
    } else if (testResultWithParams.calculationError) {
      data.push(testResultWithParams.calculationError);
    }

    data = data.filter(d => d !== null && d !== undefined);

    let triggerContent = data.length
      ? data.length > 1 ? data.join(' / ') : data[0]
      : '';

    if (!isAdmin) {
      return (
        <div>
          {triggerContent}
        </div>
      )
    }

    return (
      <Popup content={
        <FormulaPopupContent>
          {formula}
        </FormulaPopupContent>
      }
             on='hover'
             trigger={
               <div>
                 {triggerContent}
               </div>
             }
             popperModifiers={[{ name: 'preventOverflow', options: { boundary: 'window' } }]}
             size='small'
             position='top left'
             style={defaultPopupStyle}
             hoverable
      />
    );
  };

  const nextImage = () => {
    if (activeImgIx !== undefined && activeImgIx >= 0 && activeImgIx < testImages!.length) {
      setActiveImgIx(activeImgIx + 1);
    }
  };

  const prevImage = () => {
    if (activeImgIx !== undefined && activeImgIx > 0) {
      setActiveImgIx(activeImgIx - 1);
    }
  };

  const renderTimerComp = useCallback((form: FormApi) => {
    const autoTimerStart =
      !!(testConf
        && testConf.testResultDataList
        && !_.isEmpty(testConf.testResultDataList)
        && testConf.testResultDataList[tabIndex]
        && testConf.testResultDataList[tabIndex].automaticTimer
      );

    const hideTimer =
      !!(testConf
        && testConf.testResultDataList
        && !_.isEmpty(testConf.testResultDataList)
        && testConf.testResultDataList[tabIndex]
        && testConf.testResultDataList[tabIndex].hideTimer
      );
     setHide(hideTimer);

    return (
      <Field
        name={`testResultWithParams[${tabIndex}].testDuration`}
        component={FinalFormTimer}
        onStop={() => paramCheck(form)}
        automaticTimer={autoTimerStart}
        hideTimer={hideTimer}
      />
    );
  }, [tabIndex, testConf]);

  const formulaCalculation = createDecorator({
    field: new RegExp('^testResultWithParams\\[' + tabIndex + ']\\.paramDtoList\\[.*]\\.resultNumber$'),
    updates: async (value, name, allValues?: Partial<UpsertPatientTestResultDto>) => {

      if (allValues) {
        let values = { ...allValues };
        const paramDtoPath = name.replace('.resultNumber', '');
        const paramId = _.get(values, paramDtoPath).paramId;
        const param = testResultParamMap[paramId];

        if (param && UnitType.FORMULA !== param.unit) {
          const params: Array<ParamWithValueDto> = values.testResultWithParams![tabIndex].paramDtoList;
          const formulaParamId = params.find(param => testResultParamMap[param.paramId].unit === UnitType.FORMULA)?.paramId;
          if(formulaParamId == undefined){
            return {}
          }
          const formula = testResultParamMap[formulaParamId as number].formula;

          const paramIds = _.map(values.testResultWithParams![tabIndex].paramDtoList, 'paramId')
          .filter(id => testResultParamMap[id].unit !== UnitType.FORMULA);

          const paramMap = new Map<number, ParamWithValueDto>();
          params.forEach(param => paramMap.set(param.paramId, param));

          const paramIx = paramIds.findIndex(p =>
              testResultParamMap[p] && (
                testResultParamMap[p].unit !== UnitType.SUM &&
                testResultParamMap[p].unit !== UnitType.FORMULA
              ),
          );

          if (paramIx >= 0 && values.testResultWithParams) {
            const findCodeReg = /\$([A-Za-z]+)/g;
            const codes = formula.match(findCodeReg);
            let codesList: string[] = [];
            if (codes) {
              codesList = codes.map(code => {
                return code.slice(1);
              });
            }

            codesList = Array.from(new Set(codesList).values());

            if (!codesList.includes(param.code)) {
              return { ...values };
            }

            const formulaParams = [];
            for (let i = 0; i < paramIds.length; i++) {
              const paramId = paramIds[i];
              const param = testResultParamMap[paramId];
              if (param && codesList.includes(param.code)) {
                formulaParams.push(param);
              }
            }

            if (allValues.testResultWithParams) {
              const formulaParamIndex = allValues.testResultWithParams[tabIndex].paramDtoList.findIndex(param => param.paramId === formulaParamId);
              let paramsForCalc: ScoringTestParamsDto[] = [];

              let valid = true;
              for (let i = 0; i < formulaParams.length; i++) {
                const value = paramMap.get(formulaParams[i].id)?.resultNumber;

                if (!value) {
                  valid = false;
                  break;
                }

                const paramCal: ScoringTestParamsDto = {
                  code: formulaParams[i].code,
                  value: value,
                  unitType: param.unit
                };
                paramsForCalc.push(paramCal);
              }

              if (valid) {
                let calculationRequest: FormulaTestDto = {
                  formula: formula,
                  scoringParams: paramsForCalc,
                };

                calculateFormulaTest(calculationRequest)
                .then(score => setFormulaTest(score))
                .catch(noop);

                allValues.testResultWithParams[tabIndex].paramDtoList[formulaParamIndex].resultNumber = Number(formulaTest?.toFixed(2));

              }
            }

          }
        }
        return { ...values };
      }
      return {};
    },
  });

  const sumTestResultParam = createDecorator({
    field: new RegExp('^testResultWithParams\\[' + tabIndex + ']\\.paramDtoList\\[.*]\\.resultNumber$'),
    updates: (value, name, allValues?: Partial<UpsertPatientTestResultDto>) => {
      if (allValues) {
        let values = { ...allValues };
        const paramDtoPath = name.replace('.resultNumber', '');
        const paramId = _.get(values, paramDtoPath).paramId;
        const param = testResultParamMap[paramId];
        if (param && UnitType.SUM !== param.unit) {
          const paramIds = _.map(values.testResultWithParams![tabIndex].paramDtoList, 'paramId');
          const paramIx = paramIds.findIndex(p => testResultParamMap[p] && UnitType.SUM === testResultParamMap[p].unit);
          if (paramIx >= 0) {
            values.testResultWithParams![tabIndex].paramDtoList[paramIx].resultNumber = 0;

            const sumValue = _.chain(values.testResultWithParams![tabIndex].paramDtoList)
            .filter(paramDto => (paramDto.resultNumber !== null
              && paramDto.resultNumber !== undefined) && (isValueValidNumber(paramDto.resultNumber)
              || (testResultParamMap[paramDto.paramId] && UnitType.SUM === testResultParamMap[paramDto.paramId].unit)))
            .map(paramDto => parseFloat(paramDto.resultNumber.toString()))
            .sum()
            .value();

            _.set(
              values,
              `testResultWithParams[${tabIndex}].paramDtoList[${paramIx}].resultNumber`,
              sumValue === 0 ? '' : sumValue,
            );
          }
        }

        return {
          ...values,
        };
      }
      return {};
    },
  });

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={(values: any) => submitHandler(values)}
        initialValues={currentPatientTest}
        // @ts-ignore
        decorators={[sumTestResultParam, formulaCalculation]}
        subscription={{ pristine: true, submitting: true, values: true }}
        render={(formProps: FormRenderProps<UpsertPatientTestResultDto>) => renderFormContent(formProps)}
      />
    );
  };

  const getInstructionTherapist = (testConf: any) => {
    const texts: { [key: string]: string } = {
      'de': testConf.instructionTherapist || '',
      'en': testConf.instructionTherapistEn || testConf.instructionTherapist || '',
      'fr': testConf.instructionTherapistFr || testConf.instructionTherapist || '',
      'it': testConf.instructionTherapistIt || testConf.instructionTherapist || '',
    };
    return texts[language];
  };

  const getInstructionPatient = (testConf: any) => {
      const texts: { [key: string]: string } = {
        'de': testConf.instructionPatient || "",
        'en': testConf.instructionPatientEn || testConf.instructionPatient || "",
        'fr': testConf.instructionPatientFr || testConf.instructionPatient || "",
        'it': testConf.instructionPatientIt || testConf.instructionPatient || ""
      };
    return texts[language];
  }

  const translateDescription = (paramValueWithDto: ParamWithValueDto) => {
    const param = testResultParamMap[paramValueWithDto.paramId];
    const paramDefault = param.description;

    const paramDescriptions: { [key: string]: string } = {
      'de': param.description || paramDefault,
      'en': param.descriptionEn || paramDefault,
      'fr': param.descriptionFr || paramDefault,
      'it': param.descriptionIt || paramDefault,
    };
    return paramDescriptions[language];
  };

  const translateTestConfDescription = () => {
    const description = testConf?.description;

    const paramDescriptions: { [key: string]: string | undefined} = {
      'de': testConf?.description || description,
      'en': testConf?.descriptionEn || description,
      'fr': testConf?.descriptionFr || description,
      'it': testConf?.descriptionIt || description,
    };
    return paramDescriptions[language];
  };

  const instructions = () => {
    return (
      <>
        <Popup
          trigger={instructionPopupTrigger(t('testconf.therapistInstruction'), !!testConf?.instructionTherapist)}
          content={instructionContent(getInstructionTherapist(testConf))}
          on='click'
          size='huge'
          position='bottom center'
          wide='very'
        />
        <Popup
          trigger={instructionPopupTrigger(t('testconf.patientInstruction'), !!testConf?.instructionPatient)}
          content={instructionContent( getInstructionPatient(testConf))}
          on='click'
          size='huge'
          position='bottom center'
          wide='very'
        />
      </>
    );
  };

  const instructionPopupTrigger = (buttonText: string ,instructionTrigger?: boolean) => {

    if (!instructionTrigger) {
      return false;
    }

      return (
        <PopupTriggerContainer>
          <Icon name='circle outline'
                style={{
                  fontSize: '2.05rem',
                }}
          >
          </Icon>
          <StyledTextDisplayDiv>
            {buttonText}
          </StyledTextDisplayDiv>
        </PopupTriggerContainer>
      );
    };

  const instructionContent = (instruction: string) => {
    return (
      <StyledPre>
        {instruction}
      </StyledPre>
    );
  };

  const renderFormContent = (
    { handleSubmit, submitting, values, form }: FormRenderProps<any>): React.ReactNode => {

    const disabledNext = patientTesting && patientTesting.patientTests.length - 1 === counterIndex;
    const disabledPrev = counterIndex === 0;

    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          {
            errorMessages.length > 0 &&
            <Grid.Row>
              <Grid.Column width={16}>
                <div className='error'>
                  <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                    {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                  </StyledErrorMessage>
                </div>
              </Grid.Column>
            </Grid.Row>
          }
          <PatientInfoHeaderComponent
            titleElement={testConf
              ? <div className='title-h1'>
                {t('testconf.viewTitle')} ( {testCounter()} ): {translateTestConfDescription()}
                {instructions()}
              </div>
              : <></>
            }
            patient={patient}
            patientTesting={patientTesting}
          />
          <Grid.Row>
            <Grid.Column>
              <Grid stackable doubling columns={2}>
                <Grid.Column width={testImages?.length ? 8 : 16}>
                  <InnerTsaGrid>
                    {
                      currentPatientTest
                      && currentPatientTest.testParams
                      && currentPatientTest.testParams.length
                      && testResultParamMap ?
                        <>
                          <Grid.Row>
                            <Grid.Column width={16}>
                              <DataLabel>{t('testconf.parameters')}</DataLabel>
                              <ParamContainer>
                                <InnerTsaGrid>
                                  {currentPatientTest.testParams.map((paramWithValue, index) => {

                                    const paramId = paramWithValue.paramId;
                                    const param = testResultParamMap[paramId];

                                    const minValue = param.minimumValue;
                                    const maxValue = param.maximumValue;

                                    const minMaxDescriptionTitle = getMinMaxDescriptionTitle(minValue, maxValue, t);

                                    return (
                                      <ParamsRow key={paramId}>
                                        <ParamsColumn width={7}>
                                          <ParamsDiv>
                                          <span title={param.description}>
                                            {translateDescription(paramWithValue)}
                                          </span>
                                          </ParamsDiv>
                                        </ParamsColumn>
                                        <ParamsColumn width={3} className='padding-1rem' style={{ paddingTop: '7px' }}>
                                          <Field
                                            name={`testParams[${index}].resultNumber`}
                                            component={FinalFormInput}
                                            title={minMaxDescriptionTitle}
                                            validate={composeValidators(mustBeNumber, minMaxValueValidator(minValue, maxValue))}
                                          />
                                        </ParamsColumn>
                                        <ParamsColumn width={2}>
                                          <ParamsDiv>
                                          <span title={t("testconf.insertValueBetweenMinMax", {minValue: minValue, maxValue: maxValue})}>
                                            {t(`unit.${param.unit}`)}
                                          </span>
                                          </ParamsDiv>
                                        </ParamsColumn>

                                      </ParamsRow>
                                    );
                                  })
                                  }
                                </InnerTsaGrid>
                              </ParamContainer>
                            </Grid.Column>
                          </Grid.Row>
                        </> : <></>
                    }

                      <Grid.Row>
                        <Grid.Column width={16}>
                            {testResultTabsRenderer()}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <InnerTsaGrid>
                            <Grid.Row>
                              <Grid.Column width={16}>
                                <DataLabel>{t('patientTesting.params')}</DataLabel>
                                <ParamContainer>
                                  <InnerTsaGrid stackable>
                                    {
                                      testConf?.testResultDataList?.length !== 0 ?
                                      <TimerRow style={{display: hideTimerDisplay ? "none" : true}}>
                                        <ParamsColumn width={11} className='timer-column'>
                                          <TimerDiv>
                                            {renderTimerComp(form)}
                                          </TimerDiv>
                                        </ParamsColumn>
                                        <ParamsColumn width={3} style={{ paddingTop: '7px' }}>
                                          <TimerDiv className='time-duration-class'>
                                            <Field
                                              name={`testResultWithParams[${tabIndex}].testDuration`}
                                              component={FinalFormInput}
                                              onBlur={(e: any)=> paramCheck(form)}
                                              validate={composeValidators(mustBeNumber)}
                                            />
                                          </TimerDiv>
                                        </ParamsColumn>
                                        <ParamsColumn width={2}>
                                          <ParamsDiv className='seconds-label'>
                                          <span title={t(`unit.SECONDS`)}>
                                            {t(`unit.SECONDS`)}
                                          </span>
                                          </ParamsDiv>
                                        </ParamsColumn>
                                      </TimerRow> :
                                        <Grid.Row>
                                          <div className='special-label-class'>
                                            <SpecialLabel>
                                              {t('patientTesting.noTestResult')}
                                            </SpecialLabel>
                                          </div>
                                        </Grid.Row>
                                    }
                                    {currentPatientTest
                                    && currentPatientTest.testResultWithParams
                                    && currentPatientTest.testResultWithParams.length
                                    && currentPatientTest.testResultWithParams[tabIndex]
                                    && currentPatientTest.testResultWithParams[tabIndex].paramDtoList
                                    && currentPatientTest.testResultWithParams[tabIndex].paramDtoList.length
                                      ? currentPatientTest.testResultWithParams[tabIndex].paramDtoList
                                      .map((paramValueWithDto, index) => {
                                        const param = testResultParamMap[paramValueWithDto.paramId];

                                        const minValue = param.minimumValue;
                                        const maxValue = param.maximumValue;

                                        const minMaxDescriptionTitle = getMinMaxDescriptionTitle(minValue, maxValue, t);

                                        return (
                                          <ParamsRow key={paramValueWithDto.paramId}>
                                            <ParamsColumn width={7}>
                                              <ParamsDiv>
                                                <span title={param.description}>
                                                  {translateDescription(paramValueWithDto)}
                                                </span>
                                              </ParamsDiv>
                                            </ParamsColumn>
                                            <ParamsColumn width={3} className='padding-1rem'
                                                          style={{ paddingTop: '7px' }}>
                                              <Field
                                                name={
                                                  `testResultWithParams[${tabIndex}].paramDtoList[${index}].resultNumber`
                                                }
                                                component={FinalFormInput}
                                                title={minMaxDescriptionTitle}
                                                onBlur={(e: any) => UnitType.SUM === param.unit ? noop() : paramCheck(form)}
                                                validate={composeValidators(mustBeNumber)}
                                              />
                                            </ParamsColumn>
                                            <ParamsColumn width={2}>
                                              <ParamsDiv>
                                                <span title={t(`unit.${param.unit}`)}>
                                                  {t(`unit.${param.unit}`)}
                                                </span>
                                              </ParamsDiv>
                                            </ParamsColumn>
                                          </ParamsRow>
                                        );
                                      }) : <></>}

                                    <DataLabel className='data-label-class'>
                                      {t('patientTesting.qualitativeComment')}
                                    </DataLabel>
                                    <QualitativeComment>
                                      <ParamsColumn width={16}>

                                          <Field
                                            fluid
                                            name={`testResultWithParams[${tabIndex}].qualitativeComment`}
                                            placeholder={t('patientTesting.placeHolder.qualitativeComment')}
                                            component={TextArea}
                                            rows={6}
                                          />
                                      </ParamsColumn>
                                    </QualitativeComment>

                                  </InnerTsaGrid>
                                </ParamContainer>

                              </Grid.Column>
                            </Grid.Row>
                          </InnerTsaGrid>

                          <DataLabel>{t('testresult.result')}</DataLabel>
                          <ParamContainer style={{overflow : 'unset'}}>
                            <ParamsRow className='params-row-class'>
                              <ResultsColumn width={10} className='results-column-class'>
                                  {values.testResultWithParams
                                    && values.testResultWithParams.length
                                    && values.testResultWithParams[tabIndex]
                                      ? resultsRenderer(values.testResultWithParams[tabIndex])
                                      : <></>
                                  }
                              </ResultsColumn>
                            </ParamsRow>
                          </ParamContainer>
                          {/*<InnerTableActionButton message={t('testresult.calculate')}*/}
                          {/*                        onConfirm={() => calculateResults(values, form)}*/}
                          {/*                        divider={false}/>*/}
                        </Grid.Column>
                      </Grid.Row>
                    </InnerTsaGrid>
                  </Grid.Column>

                  <Grid.Column width={8}>
                    <InnerTsaGrid>
                      {/*<Grid.Row>*/}
                      {/*  <Grid.Column width={16}>*/}
                      {/*    <DataLabel>{t('testconf.instructionTherapist')}</DataLabel>*/}
                      {/*    /!*<TestData disabled={true}>{testConf?.instructionTherapist}</TestData>*!/*/}
                      {/*    {instructionsRenderer(testConf?.instructionTherapist)}*/}
                      {/*  </Grid.Column>*/}
                      {/*</Grid.Row>*/}

                      {/*<Grid.Row>*/}
                      {/*  <Grid.Column width={16}>*/}
                      {/*    <DataLabel>{t('testconf.instructionPatient')}</DataLabel>*/}
                      {/*    /!*<TestData disabled={true}>{testConf?.instructionPatient}</TestData>*!/*/}
                      {/*    {instructionsRenderer(testConf?.instructionPatient)}*/}
                      {/*  </Grid.Column>*/}
                      {/*</Grid.Row>*/}

                      {renderImages(values, form)}

                    </InnerTsaGrid>
                  </Grid.Column>

                  <Grid.Row>
                    <Grid.Column width={16}
                                 style={{ borderTop: '1px solid var(--very-light-blue)', marginTop: '2rem' }}
                    >
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row className="button-row-container">
                    <Grid.Column className="button-column-container" width={16}>
                      {
                        successMsg &&
                        <SaveAndUpdateConfirmationMessage>
                          {successMsg}
                        </SaveAndUpdateConfirmationMessage>
                      }

                      {patientTestingQuestionSetId
                        ? <CompositeButton
                          primary
                          type='button'
                          className='action-button'
                          disabled={submitting}
                          onClick={goToAnswerQuestions}
                        >
                          {t('patientTesting.answerQuestions')}
                        </CompositeButton>
                        : <></>
                      }

                      {form.getState().dirty
                        ? <EditPopUp triggerButtonText={t('button.editTestSet')}
                                     confirmAction={() => savePatientTestingResultAndGoToEdit(values)}
                                     editConfirmationText={t('testSet.confirmEdit')}
                                     goToAnotherPage={() => goToEditTestSet()}
                        />
                        : <CompositeButton
                          primary
                          type='button'
                          className='action-button'
                          disabled={submitting}
                          onClick={() => goToEditTestSet()}
                        >
                          {t('button.editTestSet')}
                        </CompositeButton>
                      }

                      <CompositeButton
                        primary
                        type='button'
                        className='action-button'
                        disabled={disabledPrev}
                        onClick={() => previousHandler(values, form)}
                      >
                        {t('action.previous')}
                      </CompositeButton>

                      <CompositeButton
                        primary
                        type='button'
                        className='action-button'
                        disabled={disabledNext}
                        onClick={() => nextHandler(values, form)}
                      >
                        {t('action.next')}
                      </CompositeButton>

                      {patientTesting && TestingStatus.COMPLETED === patientTesting.status
                        ? <CompositeButton
                          primary
                          type='submit'
                          className='action-button'
                          disabled={submitting}
                        >
                          {t('button.save')}
                        </CompositeButton>
                        : <>
                          <CompositeButton
                            primary
                            type='submit'
                            className='action-button'
                            disabled={submitting}
                          >
                            {t('button.save')}
                          </CompositeButton>
                        </>
                      }

                      <CompositeButton
                        type='button'
                        className='action-button'
                        secondary
                        onClick={goToPreviousPage}
                        style={{ float: 'right' }}
                      >
                        {t('action.cancel')}
                      </CompositeButton>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
          </Grid.Row>
        </TsaGrid>
      </form>
    );
  };

  return (
    <UpsertContentWrapperDiv>
      {formDataLoaded
        ? renderFinalForm()
        : <LoaderComponent message={t('general.loading')} />
      }
    </UpsertContentWrapperDiv>
  );
};

export default StartTestingForm;