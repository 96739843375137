import React from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';
import 'react-virtualized/styles.css';
import styled from 'styled-components';
import 'styles/VirtualizedTable.css';

const StyledTable = styled(Table)`

  .ReactVirtualized__Table__Grid {
    outline: none;
    border-radius: unset;
  }

  .ReactVirtualized__Table__headerRow {
    font-weight: 600;
    font-size: 10px;
    color: var(--light-blue);
    text-transform: uppercase !important;
  }

  & .ReactVirtualized__Table__row {
    cursor: pointer;
    font-size: 12px;

    &.disabled .ReactVirtualized__Table__rowColumn {
      color: #C0C0C0;
    }

    &:hover .ReactVirtualized__Table__rowColumn {
      background: rgba(0, 0, 0, .05) !important;
      color: rgba(0, 0, 0, .95) !important;
    }

    & .ReactVirtualized__Table__rowColumn {
      transition: 250ms all ease-in-out;
      background-color: white;
      color: var(--dark-gray);
    }

    & .ReactVirtualized__Table__rowColumn + .ReactVirtualized__Table__rowColumn {
      padding-left: 1rem;
    }

    .ReactVirtualized__Table__headerColumn, .ReactVirtualized__Table__rowColumn {
      margin-right: 0;
    }

    & .ReactVirtualized__Table__rowColumn:first-of-type {
      margin-left: 0;
      padding-left: 20px;
      border-right: none !important;
      border-top-right-radius: unset !important;
      border-bottom-right-radius: unset !important;
      border-left: 1px solid var(--very-very-light-blue);
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    & .ReactVirtualized__Table__rowColumn:last-of-type {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: 1px solid var(--very-very-light-blue);
    }
  }

  & .ReactVirtualized__Table__headerColumn:first-of-type {
    padding-left: 8px
  }
`;

function rowClassName({index}: any) {
  if (index < 0) {
    return "headerRow";
  } else {
    return index % 2 === 0 ? "evenRow" : "oddRow";
  }
}

const VirtualizedTable = ({columns, ...tableProps}: any) => {
  return (
      <AutoSizer>
        {({width, height}) => (
            <StyledTable
                width={width}
                height={height}
                headerHeight={25}
                headerClassName='headerColumn'
                rowClassName={rowClassName}
                rowHeight={tableProps.rowHeight || 48}
                {...tableProps}
            >
              {
                columns.map(({className, dataKey, customClass, ...other }: any) => {
                  const custom = customClass ? customClass : "";
                  return (
                      <Column
                          key={dataKey}
                          className={`bodyColumn ${custom}`}
                          dataKey={dataKey}
                          width={width}
                          style={{
                            borderTop: "1px solid #ebeef2",
                            borderBottom: "1px solid #ebeef2",
                            paddingTop: "10px",
                            paddingBottom: "10px"
                      }}
                          {...other}
                      />
                  );
                })
              }
            </StyledTable>
        )}
      </AutoSizer>
  );
};

export default VirtualizedTable;
