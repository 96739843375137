import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import CompositeButton from 'components/final-form/CompositeButton';
import Input from 'components/final-form/Input';
import InnerTableActionButton from 'components/InnerTableActionButton';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import { FormState } from 'final-form';
import React, { useCallback, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { ModalSearchType } from 'routes/clinic/ClinicConfigView';
import { Grid, Table } from 'semantic-ui-react';
import {
  searchExerciseConfs,
  searchExerciseTemplates,
  searchQuestionSet,
  searchTestConfs,
  searchTestTemplates,
} from 'service/clinicAdminConfService';
import styled from 'styled-components';
import { debounce } from 'ts-debounce';
import {
  ExerciseConfDto,
  ExerciseConfSearchRequest,
  ExerciseTemplateDto,
  ExerciseTemplateSearchRequest,
  QuestionSetDto,
  QuestionSetSearchRequest,
  ScopeType,
  TestConfDto,
  TestConfSearchRequest,
  TestTemplateDto,
  TestTemplateSearchRequest,
} from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { emptyTableRows } from 'util/tableUtils';

const TableContainer = styled.div`
  flex-grow: 1;
  cursor: context-menu;
  max-height: 180px;
  overflow: auto;
  border-radius: unset;
  border: 1px solid var(--light-gray) !important;

  & .ui.table tr {
    height: 30px;
    border: none;
  }

  .ui.table tr td {
    border-top: none;
  }

  .no-padding {
    border: 1px solid var(--light-gray) !important;
    padding-left: 0;
    padding-right: 0;
    margin-left: 1rem;
  }

  .ui.basic.striped.table tbody tr:nth-child(2n) {
    background-color: var(--very-light-gray) !important;
  }

`;

const ActionContainer = styled.div`
  flex-grow: 1;
  margin-top: 1rem;
  border-radius: unset;
`;

interface Props {
  type: ModalSearchType;
  selectedRecords: number[];
  onAddRecord: (testImageId: number) => Promise<void>;
  onCancel: () => void;
  scopeType?: ScopeType;
}

const cancelTokenSource = axios.CancelToken.source();

interface SearchRequest extends TestConfSearchRequest,
  TestTemplateSearchRequest,
  ExerciseConfSearchRequest,
  ExerciseTemplateSearchRequest,
  QuestionSetSearchRequest {

}

interface ModalFormFieldProps extends FormState<any> {
  values: Partial<SearchRequest>;
}

const UniversalAddModal = (props: Props) => {

  const { t } = useTranslation('teresa');
  const { language } = useAuthContext();

  const { type, onCancel, selectedRecords, onAddRecord, scopeType } = props;

  const [availableRecords, setAvailableRecords] =
    useState<Array<TestConfDto | TestTemplateDto | ExerciseConfDto | ExerciseTemplateDto | QuestionSetDto>>([]);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const addRecord = async (recordId: number, values: SearchRequest) => {
    try {
      await onAddRecord(recordId);
    } catch (e) {
      handleError(e.response.data);
    } finally {
      searchRecords(values, recordId);
    }
  };

  const handleError = useCallback((error: any) => {

    if (error) {
      let errMsgs = [];
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.clinicTestConfExists,
        errorUtils.clinicTestTemplateExists,
        errorUtils.clinicExerciseConfExists,
        errorUtils.clinicExerciseTemplateExists,
        errorUtils.clinicQuestionSetExists,
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsgs.push(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errMsgs.length) {
        if (knownErrors.includes(errorCode)) {
          errMsgs.push(t(`error.${errorCode}`));
        } else {
          errMsgs.push(t('error.general'));
        }
      }

      setErrorMessage(errMsgs);
    }

  }, []);

  const setErrorMessage = (errMsgs?: string[]) => {

    if (errMsgs) {
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const handleChange = ({ values }: ModalFormFieldProps) => {
    searchRecords(values);
  };

  const getRecordsByModalType = async (request: Partial<SearchRequest>): Promise<
    Array<TestConfDto | TestTemplateDto | ExerciseConfDto | ExerciseTemplateDto | QuestionSetDto>
  > => {

    try {

      switch (type) {
        case ModalSearchType.TEST_CONF:
          return await searchTestConfs(request, cancelTokenSource);
        case ModalSearchType.TEST_TEMPLATE:
          return await searchTestTemplates(request, cancelTokenSource);
        case ModalSearchType.EXERCISE_CONF:
          return await searchExerciseConfs(request, cancelTokenSource);
        case ModalSearchType.EXERCISE_TEMPLATE:
          return await searchExerciseTemplates(request, cancelTokenSource);
        case ModalSearchType.QUESTION_SET:
          return await searchQuestionSet(request, cancelTokenSource);
      }

    } catch (e) {
      handleError(e.response.data);
    }

    return [];
  };

  const searchRecords = useCallback(debounce(async (
    values: Partial<SearchRequest>,
    recordId?: number) => {

    setDataLoaded(false);
    setAvailableRecords([]);

    let request: Partial<SearchRequest> = {
      searchKey: values.searchKey ? values.searchKey : '',
      active: true,
    };

    if (ModalSearchType.TEST_CONF === type) {
      request.testConfSearchTerm = values.searchKey ? values.searchKey : '';
    }

    if (ModalSearchType.EXERCISE_CONF === type) {
      request.exerciseConfSearchTerm = values.searchKey ? values.searchKey : '';
    }

    if (scopeType) {
      request.scopeType = scopeType;
    }

    try {
      let response = await getRecordsByModalType(request);

      response = response!.filter(r => !selectedRecords.includes(r.id));
      setAvailableRecords(response);
    } catch (e) {
      handleError(e.response.data);
    } finally {
      setDataLoaded(true);
    }

  }, 300), [selectedRecords]);

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={() => {}}
        initialValues={{}}
        subscription={{ pristine: true, values: true }}
        render={renderTable}
      />
    );
  };

  const getRecordDescriptionOrNameMap = (
    record: TestConfDto | TestTemplateDto | ExerciseConfDto | ExerciseTemplateDto | QuestionSetDto): {
    [key: string]: string
  } => {
    if ('name' in record) {
      return {
        'de': record.name,
        'en': record.nameEn || record.name,
        'fr': record.nameFr || record.name,
        'it': record.nameIt || record.name,
      };
    } else {
      return {
        'de': record.description,
        'en': record.descriptionEn || record.description,
        'fr': record.descriptionFr || record.description,
        'it': record.descriptionIt || record.description,
      };
    }
  };

  const renderTable = ({ values }: FormRenderProps<SearchRequest>) => {

    return (
      <TsaGrid>
        {errorMessages.length > 0 &&
          <Grid.Row>
            <Grid.Column width={16}>
              <div className='error'>
                <StyledErrorMessage onDismiss={() => setErrorMessages([])}>
                  {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                </StyledErrorMessage>
              </div>
            </Grid.Column>
          </Grid.Row>
        }

        <Grid.Row>
          <Grid.Column width={5} floated='right'>
            <Field
              id='searchKey'
              name='searchKey'
              component={Input}
              icon={'search'}
              iconPosition='left'
              placeholder={t('testImage.search.placeholder')}
              autoFocus
              fluid
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          {!dataLoaded
            ? <LoaderComponent message={t('general.loading')} />
            : <Grid.Column width={16} verticalAlign='middle' className='no-padding'>
              <TableContainer>
                <Table basic='very' striped size='small'>
                  <Table.Body className='table-body'>
                    {availableRecords.map(record => {
                      let description = getRecordDescriptionOrNameMap(record)[language];
                      return <Table.Row key={record.id}>
                        <Table.Cell width={4} flexgrow={1}>
                          {description}
                        </Table.Cell>
                        <Table.Cell width={2}>
                          <div className='row-actions'>
                            <InnerTableActionButton
                              message={t('button.add')}
                              onConfirm={() => addRecord(record.id, values)} />
                          </div>
                        </Table.Cell>
                      </Table.Row>;
                    })}
                    {emptyTableRows(availableRecords.length, 2)}
                  </Table.Body>
                </Table>
              </TableContainer>
            </Grid.Column>}
          <Grid.Column width={16}>
            <ActionContainer>
              <CompositeButton
                type='button'
                className='action-button'
                style={{ whiteSpace: 'nowrap' }}
                secondary
                floated='right'
                onClick={onCancel}
              >
                {t('button.close')}
              </CompositeButton>
            </ActionContainer>
          </Grid.Column>
        </Grid.Row>
        <FormSpy subscription={{ values: true }} onChange={handleChange} />
      </TsaGrid>
    );
  };

  return <>
    {renderFinalForm()}
  </>;
};

export default UniversalAddModal;