import TextArea from 'components/final-form/TextArea';
import React from 'react';
import { FieldProps } from 'react-final-form';


export interface InputFieldProps {
  name: string,
  component: any,
  validate?: any,
  hiddenInput?: boolean,
  id?: string
}

export const textAreaInput = (fields: FieldProps<any, any>): InputFieldProps => {
  return {
    name: fields.name,
    component: TextArea,
    validate: fields.validate,
    hiddenInput: fields.hiddenInput,
    id: fields.id
  }
};
