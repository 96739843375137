import React, { Component } from 'react';
import { DividerProps } from 'semantic-ui-react';
import styled from 'styled-components';

const InputViewStyle = styled.div`
  padding-left: 1rem;
  font-weight: 600;
  min-height: 35px;
  line-height: 35px;
  background-color: var(--very-light-gray);
  border-radius: 4px;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
`;

class InputView extends Component<DividerProps> {

  render(): React.ReactNode {
    const {children, ...rest} = this.props;
    return (
      <InputViewStyle {...rest}>
        {children}
      </InputViewStyle>
    )
  }
}

export default InputView;
