import { CancelTokenSource } from 'axios';
import axios, { backendUrls } from 'service/http';
import { ClinicStatsDto, ClinicStatsRequest } from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/clinic-stats`;

export const getClinicStats = (
  request: ClinicStatsRequest,
  source: CancelTokenSource): Promise<ClinicStatsDto> => {
  return axios
  .post(`${apiUri}`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const exportClinicStats = (
  request: ClinicStatsRequest,
  source: CancelTokenSource): Promise<any> => {

  return axios
  .post(`${apiUri}/export`, request, { cancelToken: source.token })
  .then(response => {
    const type = response.headers['content-type'];
    // @ts-ignore
    const BOM = new Uint8Array([0xEF, 0xBB, 0xBF]);
    const blob = new Blob([BOM, response.data], { type: type });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = response.headers['content-disposition'];
    const filenameRegex = /filename=(.*)/;
    const regexResponse = filenameRegex.exec(link.download);
    const defaultFileName = 'response.csv';
    const fileName = regexResponse ?
      regexResponse[1] ? regexResponse[1] : defaultFileName :
      defaultFileName;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    return true;
  });

};
