import { AppData } from 'ts-types/api.types';
import React, { useCallback, useState } from 'react';
import { getAppData } from 'service/appDataServices';

interface State {
  appData?: AppData;
}

const AppDataContext = React.createContext<State>({
  appData: undefined,
});

interface Props extends JSX.ElementChildrenAttribute {}

const AppDataProvider = (props: Props) => {

  const [appData, setAppData] = useState<AppData | undefined>(undefined);

  const getApplicationInfo = useCallback(async () => {
    try {
      const applicationInfo: AppData = await getAppData();
      setAppData(applicationInfo);
    } catch (e) {}
  }, [appData, setAppData]);

  React.useEffect(() => {
    getApplicationInfo();
  }, []);


  const state: State = React.useMemo(() => {
    return { appData, setAppData };
  }, [appData, setAppData]);

  return (
    <AppDataContext.Provider value={state}>
      {props.children}
    </AppDataContext.Provider>

  );
};

export const useAppDataContext = () => React.useContext(AppDataContext);

export default AppDataProvider;