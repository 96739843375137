import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Form, TextArea } from 'semantic-ui-react';
import styled from 'styled-components';

interface ExtendedFieldRenderProps extends FieldRenderProps<any> {
  rows?: number,
  id?: string
}

export const TextAreaSc = styled(TextArea)`
    width: 100%;

    .ui.form &.textarea,
    .field .ui.textarea & {
        padding: 5px;
    }

    .ui.form &.textarea.error,
    .field .ui.textarea.error & {
        background-color: var(--very-very-light-red);
        border-color: var(--dark-red);
        color: var(--dark-red);
    }

    &:disabled {
        cursor: default;
        pointer-events: none;
        opacity: .45;
        resize: none !important;
    }
`;

const FinalFormTextArea = (props: ExtendedFieldRenderProps) => {

  const {
    input,
    meta: { touched, error },
    rows = 5,
    id,
    ...restProps
  } = props;

  return (
    <Form.Field>
      <div className={classNames(
        'ui', 'form', 'textarea', { 'error': !!(touched && error) })}
      >
        <TextAreaSc
          error={!!(touched && error)}
          {...input}
          {..._.omit(restProps, ['fluid'])}
          rows={rows}
          id={id}
        />
      </div>
    </Form.Field>
  );

};

export default FinalFormTextArea;
