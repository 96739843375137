export const errorUtils = {
  invalidInput: "INVALID_INPUT",
  invalidEmail: "INVALID_EMAIL",
  invalidParameter: "INVALID_PARAMETER",
  usernameAlreadyExists: "USERNAME_ALREADY_EXISTS",
  userNotAdmin: "USER_NOT_ADMIN",
  emailAlreadyInuse: "EMAIL_ALREADY_IN_USE",
  unknownEmailAddress: "UNKNOWN_ACCOUNT_EMAIL",
  invalidVerificationCode: "INVALID_VERIFICATION_CODE",
  passwordsDoNotMatch: "PASSWORDS_DO_NOT_MATCH",
  passwordResetTokenInvalid:"PASSWORD_RESET_TOKEN_INVALID",
  passwordFieldLengthDoNotMatch: "PASSWORD_FIELD_LENGTH_DO_NOT_MATCH",
  passwordResetTokenExpired: "PASSWORD_RESET_TOKEN_EXPIRED",
  emptyPasswordField: "EMPTY_PASSWORD_FIELD",
  invalidRegistrationKey: "INVALID_REGISTRATION_KEY",
  passwordSize: "PASSWORD_SIZE_INCORRECT",
  invalidCode: "INVALID_PARAMETER",
  invalidFormula: "INVALID_FORMULA",
  noRawScoreFound: "NO_RAW_SCORE_FOUND",
  multipleRawScoresNotAllowed: "MULTIPLE_RAW_SCORES_NOT_ALLOWED",
  trueScoreResultNotFound: "TRUE_SCORE_RESULT_NOT_FOUND",
  missingParameters: "MISSING_PARAMETERS",
  deletingNotAllowed:"DELETING_TEST_RESULT_PARAM_NOT_ALLOWED",
  deleteTestImageNotAllowed:"DELETING_TEST_IMAGE_NOT_ALLOWED",
  patientNotFound:"PATIENT_NOT_FOUND",
  doctorNotFound:"DOCTOR_NOT_FOUND",
  accountNotFound:"ACCOUNT_NOT_FOUND",
  domainNotFound:"DOMAIN_NOT_FOUND",
  clinicNotFound:"CLINIC_NOT_FOUND",
  testImageNotFound:"TEST_IMAGE_NOT_FOUND",
  fileArchiveNotFound:"FILE_ARCHIVE_NOT_FOUND",
  patientTestNotFound:"PATIENT_TEST_NOT_FOUND",
  patientTestingNotFound:"PATIENT_TESTING_NOT_FOUND",
  testconfNotFound:"TESTCONF_NOT_FOUND",
  clinicconfNotFound:"CLINICCONF_NOT_FOUND",
  patientTestingResultNotFound:"PATIENT_TESTING_RESULT_NOT_FOUND",
  patientTestConfNotFound:"PATIENT_TEST_CONF_NOT_FOUND",
  rawScoreResultNotFound:"RAW_SCORE_RESULT_NOT_FOUND",
  testResultParamNotFound:"TEST_RESULT_PARAM_NOT_FOUND",
  testResultNotFound:"TEST_RESULT_NOT_FOUND",
  testTemplateNotFound:"TEST_TEMPLATE_NOT_FOUND",
  educationalYearNotFound:"EDUCATIONAL_YEAR_NOT_FOUND",
  ageRangeNotFound:"AGE_RANGE_NOT_FOUND",
  patientTestConfListEmpty: "PATIENT_TEST_CONF_LIST_EMPTY",
  emptyFormula: "EMPTY_FORMULA",
  editingPatientNotAllowed:"EDITING_PATIENT_NOT_ALLOWED",
  mandatoryFieldsNeedToBeFilledIn: "MANDATORY_FIELDS_NEED_TO_BE_FILLED_IN",
  maximumNumberOfCharacters: "MAXIMUM_NUMBER_OF_CHARACTERS",
  fileSizeExceeded: "FILE_SIZE_EXCEEDED",
  fileTypeInvalid: "FILE_TYPE_INVALID",
  exerciseConfNotFound: "EXERCISECONF_NOT_FOUND",
  exerciseTemplateNotFound:"EXERCISE_TEMPLATE_NOT_FOUND",
  patientExerciseConfListEmpty: "PATIENT_EXERCISE_CONF_LIST_EMPTY",
  exerciseResultNotFound: "EXERCISE_RESULT_NOT_FOUND",
  exerciseResultParamNotFound: "EXERCISE_RESULT_PARAM_NOT_FOUND",
  minMaxValueInvalidValues: "MIN_MAX_VALUE",
  minSmallerThenMax: "MIN_IS_NOT_SMALLER_THEN_MAX",
  duplicateEmail:"DUPLICATE_EMAIL",
  missingMandatoryCredentials: "MISSING_MANDATORY_CREDENTIALS",
  valueTooLong: "VALUE_TOO_LONG",
  emptyEmail: "EMPTY_EMAIL",
  clinicTestConfExists: "CLINIC_TEST_CONF_EXISTS",
  clinicTestTemplateExists: "CLINIC_TEST_TEMPLATE_EXISTS",
  clinicExerciseConfExists: "CLINIC_EXERCISE_CONF_EXISTS",
  clinicExerciseTemplateExists: "CLINIC_EXERCISE_TEMPLATE_EXISTS",
  clinicQuestionSetExists: "CLINIC_QUESTION_SET_EXISTS",
  invoiceConfigNotFound: "INVOICE_CONFIG_NOT_FOUND",
  invoiceConfigNotFoundForClinic: "INVOICE_CONFIG_NOT_FOUND_FOR_CLINIC",
  invoiceConfigDate: "INVOICE_CONFIG_DATE",
  clinicInvoiceNotFound: "CLINIC_INVOICE_NOT_FOUND",
  numOfSetsCantBeNull: "NUM_OF_SETS_CANT_BE_NULL",
  originIdExists: "ORIGIN_ID_EXISTS",
  mandateNotFound: "MANDATE_NOT_FOUND",
  invalidIbanFormat: "INVALID_IBAN_FORMAT",
  normValueCanNotBeNull: "NORM_VALUE_CAN_NOT_BE_NULL",
  noTrueScoreFoundForGivenResult: "NO_TRUE_SCORE_FOUND_FOR_GIVEN_RESULT",
  sessionNumberMustBeGreaterThanCompletedSessions: "SESSION_NUMBER_MUST_BE_GREATER_THAN_COMPLETED_SESSIONS"
}
