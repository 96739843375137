import axios from 'axios';
import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import CompositeButton from 'components/final-form/CompositeButton';
import Input from 'components/final-form/Input';
import MandateTabComponent from 'components/final-form/MandateTabComponent';
import InnerTableActionButton from 'components/InnerTableActionButton';
import SearchDisplayContainer from 'components/SearchDisplayContainer';
import StyledErrorMessage from 'components/StyledErrorMessage';
import VirtualizedTable from 'components/VirtualizedTable';
import React, { useCallback, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Icon, Loader, Popup } from 'semantic-ui-react';
import { deleteMandateById, searchMandates } from 'service/mandateServices';
import { debounce } from 'ts-debounce';
import { MandateDto, MandateSearchRequest } from 'ts-types/api.types';
import { useAfterFirstRender } from 'util/functionUtils';
import { emptyTableCell } from 'util/tableUtils';

const PopupStyle = {
  borderRadius: 'unset',
};

interface ExtendedMandateSearchRequest extends MandateSearchRequest {
  selectedTab: number;
}

const cancelTokenSource = axios.CancelToken.source();

interface Props {

}

const MandatesView = (props: Props) => {

  const { t } = useTranslation('teresa');
  const history = useHistory();

  const [mandates, setMandates] = useState<MandateDto[]>([]);
  const [mandatesLoaded, setMandatesLoaded] = useState<boolean>(true);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  React.useEffect(() => {
    fetchMandates({ searchKey: '' });
  }, []);

  const fetchMandates =
    debounce((values: Partial<ExtendedMandateSearchRequest>): void => {

      const onFinally = () => {
        setMandatesLoaded(true);
      };

      setMandatesLoaded(false);

      const searchKey = values.searchKey;
      let active = undefined;
      if (values.selectedTab === 1) {
        active = true;
      } else if (values.selectedTab === 2) {
        active = false;
      }

      let newSearchValues: Partial<MandateSearchRequest> = {
        searchKey: searchKey ? searchKey : '',
        active,
      };

      searchMandates(newSearchValues, cancelTokenSource)
      .then(response => {
        setMandates(response);
      }).catch((e: any) => handleError(e.response.data))
      .finally(onFinally);

    }, 300);

  const handleError = (error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };

  const setErrorMessage = useCallback((errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  }, [errorMessages]);

  const handleChange = useAfterFirstRender(({ values }: any): void => {
    fetchMandates(values);
  });

  const mandateRowGetter = ({ index }: any) => {

    Object.assign(mandates[index], { index: index + 1 });

    return mandates[index];
  };

  const mandatesRowRenderer = ({ className, columns, index, key, style }: any) => {
    const a11yProps = { 'aria-rowindex': index + 1 };

    const mandateId = mandates[index].id;

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role='row'
        style={style}
        onDoubleClick={() => openMandateDetails(mandateId!)}
      >
        {columns}
      </div>
    );
  };

  const dataCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return cellData;
    }
    return emptyTableCell();
  };

  const activeCellRenderer = ({ rowData }: any) => {

    const style = { color: 'var(--primary-color)' };

    const activeIcon = rowData.active ?
      (<Popup
        trigger={<Icon name='check circle' style={style} />}
        content={t('mandate.active')}
        size='small'
        position='top center'
        on='click'
        style={PopupStyle}
      />) :
      (<Popup
        trigger={<Icon name='circle outline' />}
        content={t('mandate.inactive')}
        size='small'
        position='top center'
        on='click'
        style={PopupStyle}
      />);

    return (
      <div>
        {activeIcon}
      </div>
    );
  };

  const openMandateDetails = (id: number): void => {
    history.push('/mandate', {
      id: id,
    });
  };

  const deleteMandate = (id: number, values: any) => (): void => {
    try {
      deleteMandateById(id, cancelTokenSource);
      fetchMandates(values);
    } catch (e) {
      handleError(e.response.data);
    }
  };

  const mandateActionCellRenderer = (values: any) => ({ rowData }: any) => {
    return (
      <div className='row-actions'>
        <InnerTableActionButton
          message={t('mandate.edit')}
          onConfirm={() => openMandateDetails(rowData.id)}
          divider={true}
        />
        <DeleteRecordConfirmation
          triggerButtonText={t('button.delete')}
          confirmAction={deleteMandate(rowData.id, values)}
          deleteConfirmationText={t('mandate.confirmDelete')}
          position={'top left'}
        />
      </div>
    );

  };

  const renderMandatesTable = (values: any): JSX.Element => {

    let columns: React.ReactNode[] = [
      {
        width: 250,
        label: t('mandate.name'),
        dataKey: 'name',
        flexGrow: 1,
        cellRenderer: dataCellRenderer
      },
      {
        width: 200,
        label: t('mandate.telephone'),
        dataKey: 'telephone',
        cellRenderer: dataCellRenderer
      },
      {
        width: 250,
        label: t('mandate.email'),
        dataKey: 'email',
        cellRenderer: dataCellRenderer
      },
      {
        width: 100,
        label: t('mandate.numberOfMandateBanks'),
        dataKey: 'numberOfMandateBanks',
        cellRenderer: dataCellRenderer
      },
      {
        width: 100,
        label: t('mandate.numberOfMandateClinics'),
        dataKey: 'numberOfMandateClinics',
        cellRenderer: dataCellRenderer
      },
      {
        width: 100,
        label: t('mandate.active'),
        dataKey: 'active',
        cellRenderer: activeCellRenderer
      },
      {
        width: 300,
        label: t('mandate.actions'),
        dataKey: 'id',
        cellRenderer: mandateActionCellRenderer(values)
      },
    ];

    return (
      <VirtualizedTable
        rowCount={mandates.length}
        rowGetter={mandateRowGetter}
        rowRenderer={mandatesRowRenderer}
        columns={columns}
      />
    );
  };

  const renderMandateForm = (): JSX.Element => {
    return (
      <FinalForm
        onSubmit={() => {
        }}
        initialValues={{
          active: undefined,
          searchKey: '',
        }}
        subscription={{ pristine: true, values: true }}
        render={renderSearchFormContent}
      />
    );
  };

  const renderSearchFormContent = (({ values }: FormRenderProps): React.ReactNode => {

    return (
      <>
        <div className='search-container'>
          <div className='title-h1'>{t('mandates.viewTitle')}</div>
          <div className='search-form'>
            <Field
              id='searchKey'
              name='searchKey'
              component={Input}
              icon={'search'}
              iconPosition='left'
              placeholder={t('mandate.placeholder')}
              autoFocus
              fluid
            />

            <CompositeButton
              type='button'
              className='action-button'
              onClick={() => history.push('/mandate', {})}
              primary
              style={{ display: 'inline-block' }}
            >
              {t('button.add')}
            </CompositeButton>
          </div>
        </div>

        <Field
          id='selectedTab'
          name='selectedTab'
          component={MandateTabComponent}
        />

        {
          errorMessages.length > 0 &&
          <div className='error-message'>
            <StyledErrorMessage onDismiss={() => setErrorMessage()}>
              {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
            </StyledErrorMessage>
          </div>
        }

        <div className='data-table'>
          {mandatesLoaded && renderMandatesTable(values)}
          {!mandatesLoaded &&
            <Loader className='table-loader' active inline content={t('mandates.loading')} />}
        </div>

        <FormSpy subscription={{ values: true }} onChange={handleChange} />
      </>
    );
  });


  return <SearchDisplayContainer>
    {renderMandateForm()}
  </SearchDisplayContainer>;

};

export default MandatesView;