const TIME_REGEX = "^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$";
const IBAN_REGEX = "(CH|LI)[a-zA-Z0-9]{19}$";
const EMAIL_REGEX = "(^$|^(([^<>()[\\]\\\\.,;:\\s@\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$)";
const TELEPHONE_REGEX = "^\\+?[\\d\\s]+$";
const AVH_REGEX = "^756\\.(\\d{4})\\.(\\d{4})\\.(\\d{2})$";
const PARAMETER_REGEX = "([A-Za-z0-9_]+)";

export const required = (value: any) => {
  if (typeof value == "string") {
    return value.trim() !== "" ? undefined : "Required";
  } else if (typeof value == "number") {
    return undefined;
  } else if (Array.isArray(value) && !value.length) {
    return "Required";
  } else {
    return value ? undefined : "Required";
  }
};

export const timeValidator = (value: string): string | undefined => {

  if (value) {
    return value.match(TIME_REGEX) ? undefined : "Required";
  }

  return undefined;
};

export const ibanValidator = (value: string): string | undefined => {

  if (value) {
    return value.match(IBAN_REGEX) ? undefined : "Required";
  }

  return undefined;
};

export const composeValidators = (...validators: any) => (value: any) =>
    validators.reduce((error: any, validator: any) => error || validator(value), undefined);

export const sessionValidation = (numOfCompletedExercises: any) => (values: any) => {

  const exerciseProgressValue = parseInt(numOfCompletedExercises);
  if (values != undefined) {
    if (exerciseProgressValue != undefined && (values < exerciseProgressValue)) {
      return "validator.needsToBeBigger";
    }
  }
  return undefined;
};

export const mustBeNumber = (value: any) => {

  if (value) {
    if (!isValueValidNumber(value)) {
      return "validator.mustBeNumber";
    }
  }

  return undefined;
};

export const emailValidator = (value: string): string | undefined => {

  if (value) {
    return value.match(EMAIL_REGEX) ? undefined : "You have entered an incorrect email";
  }

  return undefined;
};

export const parameterValidator = (value: string): string | undefined => {

  if (value) {
    return value.match(PARAMETER_REGEX) ? undefined : "You have entered an incorrect parameter values";
  }

  return undefined;
};

export const telephoneValidator = (getMessage: Function) => (value: string): string | undefined => {

  if (value) {
    return value.match(TELEPHONE_REGEX) ? undefined : getMessage("error.wrongTelephoneFormat");
  }

  return undefined;
};

export const minMaxValueValidator = (minValue: number, maxValue: number) => (value: any) => {

  if(value) {
    if((minValue !== null  && parseFloat(value) < minValue)
      || (maxValue !== null && parseFloat(value) > maxValue)) {
      return "value.mustBeInsideTheParameterRange";
    }
  }

   return undefined;
}

export const minValueValidator = (maxValue: number) => (value: any) => {

  if(value) {
    if(maxValue !== null && maxValue !== null && parseFloat(value) > maxValue) {
      return "value.mustBeInsideTheParameterRange";
    }
  }

  return undefined;
}

export const maxValueValidator = (minValue: number) => (value: any) => {

  if(value) {
    if(minValue !== undefined && minValue !== null && parseFloat(value) < minValue) {
      return "value.mustBeInsideTheParameterRange";
    }
  }

  return undefined;
}

export const telephoneCanNotBeLongerThen = (maxLength: number, getMessage: Function) => (value: any): string | undefined => {

  if ((value && maxLength)) {
    return (value.length <= maxLength) ? undefined : getMessage("error.telephoneLengthLongerThenMax");
  }

  return undefined;
};

export const mustBePositiveNumber = (value: any) => {

  if (+value < 0) {
    return "validator.mustBePositiveNumber";
  }

  return undefined;
};

export const mustBeAFullNumber = (value: any) => {

  if (value % 1 !== 0 || (value * 10) % 10 !== 0) {
    return "validator.mustBeFullNumber";
  }

  return undefined;
};

export const mustNotBeZero = (value: any) => {

  if (value) {
    if (value === 0 || value === "0") {
      return "validator.mustNotBeZero";
    }
  }

  return undefined;
};

export const maxDigits = (numOfDigits: number) => (value: any) => {
  return value.length > numOfDigits ? " validator.maxDigits " + numOfDigits : undefined;
};

export const maxChars = (numOfChars: number) => (value: string) => {
  return value && value.length > numOfChars ? " validator.maxChars " + numOfChars : undefined;
};

export const validateOnlyIfPresent = (composedValidators: (value: string) => string | undefined) => (value: any) => {
  return value ? composedValidators(value) : undefined;
};

export const isValueEmpty = (value: any): boolean => value === undefined || value === "";

// eslint-disable-next-line
export const isValueValidNumber = (value: string | number): boolean => +value == +value && !value.toString().includes("e");

export const ahvValidator = (value: string): string | undefined => {

  if (!value) {
    return undefined;
  }

  if (!value.match(AVH_REGEX)) {
    return "invalidAhvNumber";
  } else if (computeAhvn13CheckDigit(value) !== parseInt(value.substr(-1))) {
    return "invalidAhvNumber";
  }

  return undefined;
};

const computeAhvn13CheckDigit = (value: string): number | undefined => {

  const ahvn13str = value.replace(/\D/g, '');
  const chars = ahvn13str.substring(0, ahvn13str.length - 1).split("").reverse();

  let crosssum = 0;
  for (let i = 0; i < chars.length; i++) {
    if (0 == i % 2) {
      crosssum += parseInt(chars[i]) * 3;
    } else {
      crosssum += parseInt(chars[i]);
    }
  }
  const ceilSum = Math.ceil(crosssum / 10) * 10;
  return ceilSum - crosssum;

};

export const multipleEmailsValidator = (value: string): string | undefined => {

  if (value) {
    let emails = value.split(";");
    for (let i = 0; i < emails.length; i++) {
      if (emailValidator(emails[i].trim()) === "InvalidEmail") {
        return "InvalidEmail";
      }
    }
  }
  return undefined;
};