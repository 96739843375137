import moment, { Moment } from 'moment';

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';

export const isoToDisplayDate = (isoDate?: string): string => {
  if (!isoDate) {
    return "";
  }

  return moment(isoDate).format("DD.MM.YYYY");
};

export const isoToDisplayTime = (isoTime?: string): string => {
  if (!isoTime) {
    return "";
  }

  return moment(isoTime, "HH:mm:ss").format("HH:mm");
};

export const isoToDisplayDateTime = (isoDateTime?: string): string => {
  if (!isoDateTime) {
    return "";
  }

  return moment(isoDateTime).format("DD.MM.YYYY HH:mm");
};

export const fromIsoDateString = (isoDate?: string): Moment | undefined => {
  if (!isoDate) {
    return undefined;
  }

  return moment(isoDate, "YYYY-MM-DD");
};

export const toDisplayDateString = (date?: moment.Moment): string => {
  if (!date) {
    return "";
  }

  return date.format("DD.MM.YYYY");
};

export const toIsoDateString = (date?: moment.Moment): string => {
  if (!date) {
    return "";
  }

  return date.format("YYYY-MM-DD");
};
