import React, { Component } from "react"
import styled from 'styled-components';

export interface IndexSignature {
  [key: string]: any
}

export const StyledScrollableContainer = styled.div<IndexSignature>`
  height: ${p => p.height ? p.height : "750px"};
  max-height: ${p => p.maxHeight ? p.maxHeight : undefined};
  width: ${p => p.width ? p.width : "100%"};
  overflow-x: hidden;
  padding-right: 1px;
`;

class TsaFormScrollableContainer extends Component<IndexSignature> {

  render(): React.ReactNode {
    return (
          <StyledScrollableContainer {...this.props}>
            {this.props.children}
          </StyledScrollableContainer>
    )
  }
}

export default TsaFormScrollableContainer;
