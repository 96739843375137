import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import CheckBox from 'components/final-form/CheckBox';
import DataLabel from 'components/final-form/DataLabel';
import MultiLanguageInput from 'components/final-form/MultiLanguageInput';
import MultiLanguageTextArea from 'components/final-form/MultiLanguageTextArea';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import Select, { DropdownOption } from 'components/final-form/Select';
import CompositeButton from 'components/final-form/CompositeButton';
import FormModalContentContainer from 'components/FormModalContentContainer';
import { defaultPopupStyle } from 'components/HeaderButton';
import InnerFormTable from 'components/InnerFormTable';
import InnerFormTableWithOrder from 'components/InnerFormTableWithOrder';
import InnerTableActionButton from 'components/InnerTableActionButton';
import InnerTsaGrid from 'components/InnerTsaGrid';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import { useTestResultFormDataContext } from 'context/TestResultFormDataContext';
import { FormApi } from 'final-form';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Lightbox from 'react-image-lightbox';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import TestConfAddParamsModal from 'routes/testconf/TestConfAddParamsModal';
import TestConfAddTestImageModal from 'routes/testconf/TestConfAddTestImageModal';
import { Button, Grid, Icon, Modal, Popup } from 'semantic-ui-react';
import {
  addTestConf,
  addTestConfImages,
  editTestConf,
  editTestConfImages,
  getTestConf,
} from 'service/testconfService';
import { getAllTestImages, getTestImageForDisplay } from 'service/testImageService';
import { getAvailableParamsForTestConf } from 'service/testResultParamServices';
import styled from 'styled-components';
import { ScopeType, TrueScoreResultType } from 'ts-types/api.enums';
import {
  DisplayTestImageDto,
  TestImageDto,
  TestImageWithOrderDto,
  TestResultData,
  TestResultParamData,
  TestResultParamDto,
  UpsertTestConfDto,
  UpsertTestConfImage,
} from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { noop } from 'util/functionUtils';
import { emptyTableCell, multiLanguageInnerTableCellRenderer } from 'util/tableUtils';

const ActionContainer = styled.div`
  flex-grow: 1;
  border-radius: unset;
  margin-left: 5px;
  padding-top: 34px;
`;

interface TestImageDtoWithOrder extends TestImageDto {
  orderIndex: number;
}

interface Props {
}

const cancelTokenSource = axios.CancelToken.source();

const UpsertTestConfForm = (props: Props) => {

  const { state } = useLocation();
  const { language, currentUser } = useAuthContext();

  const testConfId: number | undefined = state?.id ? Number(state?.id) : undefined;
  const { t } = useTranslation('teresa');
  const history = useHistory();

  const { setTestConfDescriptions } = useTestResultFormDataContext();

  const initialTestConfValues: Partial<UpsertTestConfDto> = {
    testResultParamIds: [],
    testImages: [],
    active: true,
  };

  const [testConf, setTestConf] = React.useState<Partial<UpsertTestConfDto> | undefined>(initialTestConfValues);
  const [testResultData, setTestResultData] = React.useState<TestResultData[]>([]);
  const [testResultParams, setTestResultParams] = useState<TestResultParamDto[]>([]);
  const [testResultParamMap, setTestResultParamMap] = useState<{ [key: number]: TestResultParamData }>([]);
  const [selectedTestResultParams, setSelectedTestResultParams] = useState<TestResultParamDto[]>([]);
  const [testImages, setTestImages] = React.useState<TestImageDto[]>([]);
  const [selectedTestImages, setSelectedTestImages] = React.useState<TestImageDtoWithOrder[]>([]);
  const [previewImage, setPreviewImage] = React.useState<DisplayTestImageDto | undefined>(undefined);
  const [previewImageLoading, setPreviewImageLoading] = React.useState<boolean>(false);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [modalImageOpen, setModalImageOpen] = React.useState<boolean>(false);

  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(true);
  const [successMsg, setSuccessMsg] = React.useState<string | undefined>(undefined);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [warningMsg, setWarningMsg] = useState<string | undefined>(undefined);

  const isSystemAdmin = useMemo(() => currentUser
    && currentUser.roles.includes('ROLE_APP_ADMIN'), [currentUser]);

  const scoreRulesTypes: Array<DropdownOption> = Object.values(TrueScoreResultType)
  .map((trueScoreResultType, index): DropdownOption => (
    {
      key: index,
      text: t(`trueScoreResultType.${trueScoreResultType}`),
      value: trueScoreResultType,
    }
  ));

  useEffect(() => {
    fetchData(testConfId);
    if (!testConfId) {
      setWarningMsg(t('testresult.warningMessage'));
    }
  }, []);

  useEffect(() => {
    fetchTestConfAvailableParams(testConfId);
  }, [testConf]);

  const fetchTestConfAvailableParams = async (id?: number) => {
    const testResultParamResponse = await getAvailableParamsForTestConf(id, cancelTokenSource);
    setTestResultParams(testResultParamResponse);

    if (testConf && testConf.testResultParamIds) {
      const selected = testResultParamResponse.filter(t => testConf!.testResultParamIds!.includes(t.id));
      setSelectedTestResultParams(selected);
    }
  };

  const fetchData = async (id?: number) => {

    setFormDataLoaded(false);

    let testConfResponse: Partial<UpsertTestConfDto> | undefined = undefined;

    if (id) {

      testConfResponse = await getTestConf(id, cancelTokenSource);

      if (testConfResponse !== undefined) {

        setTestConf(testConfResponse);
        setTestResultParamMap(_.keyBy(testConfResponse.testResultParamDataList, 'testResultParamId'));

        if (testConfResponse.testResultDataList === undefined) {
          setTestResultData([]);
        } else {
          setTestResultData(testConfResponse.testResultDataList);
        }
      }
    }

    try {
      const testImageResponse = await getAllTestImages(cancelTokenSource);
      setTestImages(testImageResponse);

      if (testConfResponse && testConfResponse.testImages) {

        const testConfImages = testConfResponse.testImages;

        const selectedTestImageResponse: (TestImageDto & Omit<TestImageWithOrderDto, 'testImageId'>)[] = testImageResponse
        .filter(item => testConfImages
        .some(filterItem => filterItem.testImageId === item.id))
        .map(item => {
          const { testImageId, ...rest } =
            testConfImages.find(filterItem => filterItem.testImageId === item.id)!;
          return { ...item, ...rest };
        });

        setSelectedTestImages(_.sortBy(selectedTestImageResponse, 'orderIndex'));
      }
    } catch (e) {
      handleError(e.response.data);
    } finally {
      setFormDataLoaded(true);
    }
  };

  const onAddTestResultParam = (testResultParam: TestResultParamDto) => {
    const selected: TestResultParamDto[] = [...selectedTestResultParams, testResultParam];
    setSelectedTestResultParams(selected);
  };

  const deleteTestResultParam = (id: number) => {
    const testParam = selectedTestResultParams.find(t => t.id === id);
    if (testParam && selectedTestResultParams.includes(testParam)) {
      const selected: TestResultParamDto[] = selectedTestResultParams.filter(t => t.id !== id);
      setSelectedTestResultParams(selected);
    }
  };

  const handleError = useCallback((error: any) => {

    if (error) {
      let errMsgs = [];
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.invalidEmail,
        errorUtils.fileArchiveNotFound,
        errorUtils.testconfNotFound,
        errorUtils.testResultParamNotFound,
        errorUtils.maximumNumberOfCharacters,
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsgs.push(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          errMsgs.push(t(`error.${errorCode}`));
        } else {
          errMsgs.push(t('error.general'));
        }
      }

      setErrorMessage(errMsgs);
    }

  }, []);

  const setErrorMessage = (errMsgs?: string[]) => {

    if (errMsgs) {
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const submitHandler = async (values: Partial<UpsertTestConfDto>) => {

    let saveValues: Partial<UpsertTestConfDto> = {
      ...values,
    };

    saveValues.testResultParamIds = selectedTestResultParams.map(p => p.id);

    const onSave = () => {
      testConfId
        ? setSuccessMsg(t('testconf.onUpdate'))
        : setSuccessMsg(t('testconf.onAdd'));
      setTimeout(() => {
        if (isSystemAdmin) {
          history.push('/testconf/search');
        } else {
          history.push('/');
        }
      }, 1200);
    };

    addOrEditTestConf(saveValues, onSave);
  };

  const onAddTestImage = (
    values: Partial<UpsertTestConfDto>,
    form: FormApi<UpsertTestConfDto, Partial<UpsertTestConfDto>>) =>
    (testImageId: number) => {

      const testImage: TestImageWithOrderDto = {
        testImageId: testImageId,
        orderIndex: values.testImages ? values.testImages.length + 1 : 1,
      };

      const selectedTestImages = values.testImages
        ? [...values.testImages, testImage]
        : [testImage];

      form.change('testImages', [...selectedTestImages]);

      const filteredTestImages: (TestImageDto & Omit<TestImageWithOrderDto, 'testImageId'>)[] = testImages
      .filter(item => selectedTestImages
      .some(filterItem => filterItem.testImageId === item.id))
      .map(item => {
        const { testImageId, ...rest } =
          selectedTestImages.find(filterItem => filterItem.testImageId === item.id)!;
        return { ...item, ...rest };
      });

      setSelectedTestImages(_.sortBy(filteredTestImages, 'orderIndex'));
    };

  const onRemoveTestImage = (
    values: Partial<UpsertTestConfDto>,
    testImageId: number,
    form: FormApi<UpsertTestConfDto, Partial<UpsertTestConfDto>>) => {

    const testImageVals = values.testImages;

    if (testImageVals && testImageVals.length) {

      let tcs = [...testImageVals];
      _.remove(tcs, { testImageId: testImageId });
      tcs = tcs.map((tc, index) => ({
        ...tc,
        orderIndex: index + 1,
      }));
      form.change('testImages', tcs);

      let filteredTestImages: (TestImageDto & Omit<TestImageWithOrderDto, 'testImageId'>)[] = testImages
      .filter(item => tcs
      .some(filterItem => filterItem.testImageId === item.id))
      .map(item => {
        const { testImageId, ...rest } =
          tcs.find(filterItem => filterItem.testImageId === item.id)!;
        return { ...item, ...rest };
      });

      setSelectedTestImages(_.sortBy(filteredTestImages, 'orderIndex'));
    }
  };

  const fetchPreviewImage = async (testImageId: number) => {
    setPreviewImageLoading(true);
    try {
      const response = await getTestImageForDisplay(testImageId, cancelTokenSource);
      setPreviewImage(response);
    } catch (e) {
      handleError(e.response.data);
    } finally {
      setPreviewImageLoading(false);
    }
  };

  const actionTestImageCellRenderer = (
    values: Partial<UpsertTestConfDto>,
    form: FormApi<UpsertTestConfDto, Partial<UpsertTestConfDto>>) => (rowData: any) => {

    return <>
      <InnerTableActionButton
        message={t('button.preview')}
        onConfirm={() => fetchPreviewImage(rowData.id)}
        divider={true}
      />
      <DeleteRecordConfirmation triggerButtonText={t('button.remove')}
                                confirmAction={() => onRemoveTestImage(values, rowData.id, form)}
                                deleteConfirmationText={t('testImage.confirmDelete', { description: getDescription(rowData)[language] })}
      />
    </>;
  };

  const onDragAndDropTestConfImage = (form: FormApi<UpsertTestConfDto, Partial<UpsertTestConfDto>>) => (startIx: number, endIx: number) => {
    if (selectedTestImages && selectedTestImages.length) {
      let testConfImages = [...selectedTestImages];
      const [removed] = testConfImages.splice(startIx, 1);
      testConfImages.splice(endIx, 0, removed);

      // Update the orderIndex property for all elements
      let testImageValues: TestImageWithOrderDto[] = [];
      testConfImages.forEach((el, index) => {

        const newTestImage = {
          testImageId: el.id,
          orderIndex: index + 1,
        };

        testImageValues.push(newTestImage);

        el.orderIndex = index + 1;
      });

      form.change('testImages', [...testImageValues]);
      setSelectedTestImages(testConfImages);
    }
  };

  const renderTestImageTable = (
    values: Partial<UpsertTestConfDto>,
    form: FormApi<UpsertTestConfDto, Partial<UpsertTestConfDto>>): React.ReactNode => {

    return (
      <>
        <Grid.Row>
          <Grid.Column width={15}>
            <DataLabel>{t('testconf.image')}</DataLabel>
            <InnerFormTableWithOrder id='test-conf'
                                     numOfCells={2}
                                     values={selectedTestImages}
                                     visibleRows={6}
                                     dragAndDropFn={onDragAndDropTestConfImage(form)}
                                     columns={[
                                       {
                                         width: 2,
                                         label: '#',
                                         dataKey: 'orderIndex',
                                       },
                                       {
                                         width: 10,
                                         label: t('testconf.description'),
                                         oneLine: true,
                                         dataKey: 'description',
                                         cellRenderer: multiLanguageInnerTableCellRenderer('description', language),
                                       },
                                       {
                                         width: 3,
                                         label: t('admin.actions'),
                                         dataKey: 'id',
                                         cellRenderer: actionTestImageCellRenderer(values, form),
                                       },
                                     ]} />
          </Grid.Column>


          <Grid.Column>
            <ActionContainer>
              <span>
                <InnerTableActionButton
                  message={t('button.add')}
                  onConfirm={() => setModalImageOpen(true)}
                />
              </span>
            </ActionContainer>
          </Grid.Column>
        </Grid.Row>
      </>
    );
  };

  const addTestConfImage = (testConfId: number, values: Partial<UpsertTestConfDto>) => {
    let testConfImage: Partial<UpsertTestConfImage> = {
      image: values.image,
      imageEn: values.imageEn,
      imageFr: values.imageFr,
      imageIt: values.imageIt,
    };

    addTestConfImages(testConfId, testConfImage, cancelTokenSource);
  };

  const editTestConfImage = (testConfId: number, values: Partial<UpsertTestConfDto>) => {
    let testConfImage: Partial<UpsertTestConfImage> = {
      image: values.image,
      imageEn: values.imageEn,
      imageFr: values.imageFr,
      imageIt: values.imageIt,
      imageView: values.imageView,
      imageViewEn: values.imageViewEn,
      imageViewFr: values.imageViewFr,
      imageViewIt: values.imageViewIt,
    };

    editTestConfImages(testConfId, testConfImage, cancelTokenSource);
  };

  const addOrEditTestConf = async (
    values: Partial<UpsertTestConfDto>, onSave: () => void) => {

    const testResultParamIds = selectedTestResultParams.map(t => t.id);

    const descriptions: Partial<UpsertTestConfDto> = {
      description: values.description,
      descriptionEn: values.descriptionEn,
      descriptionFr: values.descriptionFr,
      descriptionIt: values.descriptionIt,
    };

    setTestConfDescriptions(descriptions);

    if (testConfId) {
      let request: Partial<UpsertTestConfDto> = {
        ...descriptions,
        instructionPatient: values.instructionPatient,
        instructionPatientEn: values.instructionPatientEn,
        instructionPatientFr: values.instructionPatientFr,
        instructionPatientIt: values.instructionPatientIt,
        instructionTherapist: values.instructionTherapist,
        instructionTherapistEn: values.instructionTherapistEn,
        instructionTherapistFr: values.instructionTherapistFr,
        instructionTherapistIt: values.instructionTherapistIt,
        testResultDataList: values.testResultDataList,
        scoreRulesType: values.scoreRulesType,
        active: values.active,
        testResultParamIds: testResultParamIds,
        testImages: values.testImages,
      };

      await editTestConf(testConfId, request, cancelTokenSource)
      .then(response => {
        editTestConfImage(testConfId, values);
      }).then(onSave)
      .catch((e: any) => handleError(e.response.data));
    } else {
      await addTestConf(values, cancelTokenSource)
      .then(response => {
        addTestConfImage(response.id, values);
      }).then(onSave)
      .catch((e: any) => handleError(e.response.data));
    }
  };

  const onDragAndDropTestResult = (form: FormApi<UpsertTestConfDto, Partial<UpsertTestConfDto>>) => (startIx: number, endIx: number) => {
    if (testResultData && testResultData.length) {
      let testResultDataList = [...testResultData];
      const [removed] = testResultDataList.splice(startIx, 1);
      testResultDataList.splice(endIx, 0, removed);

      // Update the orderIndex property for all elements
      testResultDataList.forEach((el, index) => {
        el.orderIndex = index + 1;
      });

      form.change('testResultDataList', [...testResultDataList]);
      setTestResultData(testResultDataList);
    }
  };

  const onAddResult = (values: Partial<UpsertTestConfDto>) => {
    if (testConfId) {
      addOrEditTestConf(values, noop);
      const newOrderIndex =
        testResultData && testResultData.length ? testResultData[testResultData.length - 1].orderIndex + 1 : 1;
      history.push(`/testresult`, {
        testConfId: testConfId,
        scoreRulesType: values.scoreRulesType,
        orderIndex: newOrderIndex,
      });
    }
  };

  const onEditResult = (values: Partial<UpsertTestConfDto>, testResultId: any, orderIndex: number, isCopy: boolean) => {
    addOrEditTestConf(values, noop);
    history.push(`/testresult`, {
      testConfId: testConfId,
      testResultId: testResultId,
      scoreRulesType: values.scoreRulesType,
      orderIndex: orderIndex,
      copy: isCopy,
    });
  };

  const reorderTestResults = (testResults: TestResultData[]) => {
    testResults.forEach((el, index) => {
      el.orderIndex = index + 1;
    });
  }

  const removeTestResultFromFormValues = (
    id: number, form: FormApi<UpsertTestConfDto, Partial<UpsertTestConfDto>>) => {
    const values = { ...form.getState().values };
    let testResults = [...values.testResultDataList];
    _.remove(testResults, { id: id });
    reorderTestResults(testResults);
    form.change('testResultDataList', [...testResults]);
  };

  const removeTestResultFromTableData = (id: number) => {
    let filterTestResultData = [...testResultData];
    _.remove(filterTestResultData, { id: id });
    reorderTestResults(filterTestResultData);
    setTestResultData(filterTestResultData);
  };

  const onDeleteResult = (id: number, form: FormApi<UpsertTestConfDto, Partial<UpsertTestConfDto>>) => {
    removeTestResultFromFormValues(id, form);
    removeTestResultFromTableData(id);
  };

  const getDescription = (data: TestResultData | TestImageDto | TestResultParamDto): { [key: string]: string } => {
    if (data) {
      return {
        'de': data.description,
        'en': data.descriptionEn || data.description,
        'fr': data.descriptionFr || data.description,
        'it': data.descriptionIt || data.description,
      };
    }

    return {};
  };

  const getNextFreeOrderIndex = (excludeIndex: number): number => {

    const orderIndexes = testResultData
    .filter((data) => data.orderIndex !== undefined)
    .map((data) => data.orderIndex);

    const sortedIndexes = orderIndexes.sort((a, b) => a - b);

    let nextIndex = 1;
    for (let i = 0; i < sortedIndexes.length; i++) {
      if (sortedIndexes[i] !== nextIndex && sortedIndexes[i] !== excludeIndex) {
        break;
      }
      nextIndex++;
    }

    return nextIndex;
  };


  const actionCellRenderer = (form: FormApi<UpsertTestConfDto, Partial<UpsertTestConfDto>>) => (rowData: any) => {
    const values = form.getState().values;
    const nextOrderIndex = getNextFreeOrderIndex(rowData.orderIndex);
    return <>
      <InnerTableActionButton
        message={t('button.edit')}
        onConfirm={() => onEditResult(values, rowData.id, rowData.orderIndex, false)}
        divider={true}
      />
      <InnerTableActionButton
        message={t('button.copy')}
        onConfirm={() => onEditResult(values, rowData.id, nextOrderIndex, true)}
        divider={true}
      />
      <DeleteRecordConfirmation triggerButtonText={t('testresult.delete')}
                                confirmAction={() => onDeleteResult(rowData.id, form)}
                                deleteConfirmationText={t('testresult.confirmDelete',
                                  { description: getDescription(rowData)[language] })}
                                position={'top left'}
      />
    </>;
  };

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={(values: Partial<UpsertTestConfDto>) => submitHandler(values)}
        initialValues={testConf}
        subscription={{ pristine: true, submitting: true, values: true }}
        render={(formProps: FormRenderProps<UpsertTestConfDto>) => renderFormContent(formProps)}
      />
    );
  };

  const activeCellRenderer = (rowData: any) => {
    if (rowData.active) {
      return <Icon name='check' size='small' />;
    }
    return emptyTableCell();
  };

  const renderFormContent = (
    { handleSubmit, submitting, form, values }: FormRenderProps<UpsertTestConfDto>): React.ReactNode => {
    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          <Grid.Row>
            <Grid.Column width={12}>
              <div className='title-h1'>{t('testconf.viewTitle')}</div>
            </Grid.Column>
          </Grid.Row>

          {errorMessages.length > 0 &&
            <Grid.Row>
              <Grid.Column width={16}>
                <div className='error'>
                  <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                    {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                  </StyledErrorMessage>
                </div>
              </Grid.Column>
            </Grid.Row>
          }

          <Grid.Row>
            <Grid.Column width={16}>
              <Grid stackable doubling columns={2}>
                <Grid.Column width={6}>
                  <InnerTsaGrid>

                    <Grid.Row>
                      <Grid.Column width={16}>
                        <DataLabel>{t('testconf.description')}</DataLabel>
                        <MultiLanguageInput fieldName='description' form={form} requiredFirst={true} />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={16}>
                        <DataLabel>{t('testconf.instructionTherapist')}</DataLabel>
                        <MultiLanguageTextArea fieldName='instructionTherapist' form={form} rows={8} />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={16}>
                        <DataLabel>{t('testconf.instructionPatient')}</DataLabel>
                        <MultiLanguageTextArea fieldName='instructionPatient' form={form} rows={8} />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={16}>
                        <DataLabel>{t('testconf.useGeneralScoreRules')}</DataLabel>
                        <Field
                          name='scoreRulesType'
                          component={Select}
                          options={scoreRulesTypes}
                          clearable
                          fluid
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={16} textAlign='left'>
                        <DataLabel>{t('domain.status')}</DataLabel>
                        <Field
                          name='active'
                          component={CheckBox}
                          label={t('testconf.active')}
                          toggle
                        />
                      </Grid.Column>
                    </Grid.Row>

                  </InnerTsaGrid>
                </Grid.Column>

                <Grid.Column width={10}>
                  <InnerTsaGrid>
                    <Grid.Row>
                      <Grid.Column width={15} verticalAlign='top'>
                        <DataLabel>{t('testresult.viewTitle')}</DataLabel>
                        <InnerFormTableWithOrder id='test-conf'
                                                 numOfCells={5}
                                                 values={testResultData}
                                                 visibleRows={6}
                                                 dragAndDropFn={onDragAndDropTestResult(form)}
                                                 columns={[
                                                   {
                                                     width: 2,
                                                     label: '#',
                                                     dataKey: 'orderIndex',
                                                   },
                                                   {
                                                     width: 14,
                                                     flexgrow: 1,
                                                     label: t('testconf.description'),
                                                     dataKey: 'description',
                                                     oneLine: true,
                                                     cellRenderer: multiLanguageInnerTableCellRenderer('description', language),
                                                   },
                                                   {
                                                     width: 12,
                                                     flexgrow: 1,
                                                     label: t('domain.viewTitle'),
                                                     oneLine: true,
                                                     dataKey: 'domainDescription',
                                                   },
                                                   {
                                                     width: 2,
                                                     label: t('testconf.selectedParams'),
                                                     shortLabel: t('testconf.shortSelectedParams'),
                                                     dataKey: 'numOfTestResultParams',
                                                   },
                                                   {
                                                     width: 2,
                                                     label: t('testconf.rawScoreRules'),
                                                     shortLabel: t('testconf.shortRawScoreRules'),
                                                     dataKey: 'numOfTestResultRawRules',
                                                   },
                                                   {
                                                     width: 2,
                                                     label: t('testconf.testUses'),
                                                     shortLabel: t('testconf.shortTestUses'),
                                                     dataKey: 'numOfTestUses',
                                                   },
                                                   {
                                                     width: 2,
                                                     label: t('active'),
                                                     dataKey: 'active',
                                                     cellRenderer: activeCellRenderer,
                                                   },
                                                   {
                                                     width: 10,

                                                     label: t('admin.actions'),
                                                     dataKey: 'domainDescription',
                                                     cellRenderer: actionCellRenderer(form),
                                                   },
                                                 ]} />
                      </Grid.Column>

                      <Grid.Column>
                        <ActionContainer>
                          <Popup content={warningMsg} on='hover'
                                 trigger={
                                   <span>
                              <InnerTableActionButton
                                message={t('button.add')}
                                onConfirm={() => onAddResult(values)}
                                disabled={!testConfId}
                              />
                            </span>
                                 }
                                 popperModifiers={[{ name: 'preventOverflow', options: { boundary: 'window' } }]}
                                 size='small'
                                 position='right center'
                                 disabled={testConfId !== undefined}
                                 style={defaultPopupStyle}
                          />
                        </ActionContainer>
                      </Grid.Column>
                    </Grid.Row>
                    {renderTestResultParamsTable()}
                    {renderTestImageTable(values, form)}
                  </InnerTsaGrid>
                </Grid.Column>

                <Grid.Row>
                  <Grid.Column width={16} style={{ borderTop: '1px solid var(--very-light-blue)', marginTop: '2rem' }}>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row textAlign='right'>
                  <Grid.Column width={16} style={{ paddingRight: '0' }}>
                    {
                      successMsg &&
                      <SaveAndUpdateConfirmationMessage>
                        {successMsg}
                      </SaveAndUpdateConfirmationMessage>
                    }
                    <CompositeButton
                      primary
                      type='submit'
                      className='action-button'
                      disabled={submitting || !!successMsg}
                    >
                      {t('button.save')}
                    </CompositeButton>
                    <CompositeButton
                      type='button'
                      className='action-button'
                      secondary
                      disabled={submitting || !!successMsg}
                      onClick={() => isSystemAdmin ? history.push('/testconf/search') : history.push('/')}
                    >
                      {t('action.back')}
                    </CompositeButton>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </TsaGrid>
        {renderAvailableTestImagesModal(values, form)}
        {previewImageLoading && <LoaderComponent message={t('general.loading')} />}

        {previewImage && <Lightbox
          mainSrc={`data:${previewImage.imageView};base64, ${previewImage.imageView}`}
          onCloseRequest={() => setPreviewImage(undefined)}
        />
        }
      </form>
    );
  };

  const deleteResultParamCellRenderer = (rowData: any) => {

    return <DeleteRecordConfirmation triggerButtonText={t('testresult.delete')}
                                     confirmAction={() => deleteTestResultParam(rowData.id)}
                                     deleteConfirmationText={t('testresult.confirmDelete', { description: getDescription(rowData)[language] })}
                                     disabled={testResultParamMap[rowData.id] ? !testResultParamMap[rowData.id].deletable : undefined}
                                     position={'top left'} />;
  };

  const numOfUsesParamCellRenderer = (rowData: any) => {
    return (
      <>
        {`${testResultParamMap[rowData.id] ? testResultParamMap[rowData.id].countInFormula : 0}`}
      </>
    );
  };

  const renderTestResultParamsTable = () => {
    return (
      <>
        <Grid.Row>
          <Grid.Column width={15}>
            <DataLabel>{t('testresult.params')}</DataLabel>
            <InnerFormTable id='true-score' numOfCells={5} values={selectedTestResultParams} visibleRows={6}
                            columns={[
                              {
                                width: 6,
                                label: t('truescoreresult.description'),
                                dataKey: 'description',
                                oneLine: true,
                                cellRenderer: multiLanguageInnerTableCellRenderer('description', language),
                              },
                              {
                                width: 2,
                                label: t('truescoreresult.unit'),
                                dataKey: 'unit',
                              },
                              {
                                width: 2,
                                flexgrow: 1,
                                label: t('truescoreresult.code'),
                                dataKey: 'code',
                              },
                              {
                                width: 1,
                                label: t('truescoreresult.numberOfUses'),
                                shortLabel: t('truescoreresult.numberOfUsesShort'),
                                dataKey: 'id',
                                cellRenderer: numOfUsesParamCellRenderer,
                              },
                              {
                                width: 2,
                                label: t('testresult.actions'),
                                dataKey: 'description',
                                cellRenderer: deleteResultParamCellRenderer,
                              },
                            ]}
            />
          </Grid.Column>
          <Grid.Column>
            <ActionContainer>
              <span>
                <InnerTableActionButton
                  message={t('button.add')}
                  onConfirm={() => setModalOpen(true)}
                />
              </span>
            </ActionContainer>
          </Grid.Column>
        </Grid.Row>
      </>
    );
  };

  const renderContactModal = (): React.ReactNode => {

    return (
      <Modal style={{ position: 'sticky' }}
             open={modalOpen} closeOnDimmerClick={true} onClose={() => setModalOpen(false)}>
        <Modal.Header>{t('testresult.testResultParams')}</Modal.Header>
        <FormModalContentContainer>
          <TestConfAddParamsModal
            testResultParams={testResultParams}
            selectedTestResultParams={selectedTestResultParams}
            scopeType={ScopeType.TEST}
            onAddTestResultParam={onAddTestResultParam}
            onCancel={() => setModalOpen(false)}
          />
        </FormModalContentContainer>
      </Modal>
    );
  };

  const renderAvailableTestImagesModal = (
    values: UpsertTestConfDto,
    form: FormApi<UpsertTestConfDto, Partial<UpsertTestConfDto>>): React.ReactNode => {

    return (
      <Modal style={{ position: 'sticky' }}
             open={modalImageOpen} closeOnDimmerClick={false} onClose={() => setModalImageOpen(false)}>
        <Modal.Header>{t('testImage.availableTestConfigs')}</Modal.Header>
        <FormModalContentContainer>
          <TestConfAddTestImageModal
            selectedTestImages={values.testImages.map(ti => ti.testImageId)}
            onAddTestImage={onAddTestImage(values, form)}
            onCancel={() => setModalImageOpen(false)}
          />
        </FormModalContentContainer>
      </Modal>
    );
  };

  return (
    <UpsertContentWrapperDiv>
      {formDataLoaded
        ? <React.Fragment>
          {renderFinalForm()}
          {renderContactModal()}
        </React.Fragment>
        : <LoaderComponent message={t('testconf.loading')} />
      }
    </UpsertContentWrapperDiv>
  );
};

export default UpsertTestConfForm;