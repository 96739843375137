import React, { CSSProperties } from 'react';
import { DividerProps } from 'semantic-ui-react';
import styled from 'styled-components';

interface Props extends DividerProps {
  style?: CSSProperties;
}

const StyledSpecialLabel = styled.div<Props>`
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const SpecialLabel = (props: Props) => {

  const { children, style } = props;

  return (
    <StyledSpecialLabel
      style={style}
    >
      {children}
    </StyledSpecialLabel>
  );
};

export default SpecialLabel;
