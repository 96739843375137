import { useAuthContext } from 'auth/AuthContext';
import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import CompositeButton from 'components/final-form/CompositeButton';
import InnerTableActionButton from 'components/InnerTableActionButton';
import Input from 'components/final-form/Input';
import TabComponent from 'components/final-form/TabComponent';
import SearchDisplayContainer from 'components/SearchDisplayContainer';
import StyledErrorMessage from 'components/StyledErrorMessage';
import VirtualizedTable from 'components/VirtualizedTable';
import { FormState } from 'final-form';
import _, { values } from 'lodash';
import React, { useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Loader } from 'semantic-ui-react';
import axios from 'service/http';
import { deleteTestTemplateById, searchTestTemplates } from 'service/testTemplateServices';
import { debounce } from 'ts-debounce';
import { TestConfDto, TestTemplateDto, TestTemplateSearchRequest } from 'ts-types/api.types';
import { useAfterFirstRender } from 'util/functionUtils';
import { emptyTableCell, multiLanguageFieldCellRenderer, shortLabel } from 'util/tableUtils';

const cancelTokenSource = axios.CancelToken.source();

interface TestTemplateFieldProps extends FormState<any> {
  values: Partial<TestTemplateSearchRequest>;
}

interface Props {
}

const TestTemplateView = (props: Props) => {

  const { currentUser } = useAuthContext();
  const { t } = useTranslation('teresa');
  const history = useHistory();
  const { language } = useAuthContext();

  const [foundTestTemplates, setFoundTestTemplates] = useState<TestTemplateDto[]>([]);
  const [testTemplatesLoaded, setTestTemplatesLoaded] = useState<boolean>(true);
  const [searchValues, setSearchValues] = useState<Partial<TestTemplateSearchRequest>>({
    searchKey: ''
  });
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  React.useEffect(() => {
    fetchTestTemplates(searchValues);
  }, []);

  const handleError = (error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };

  const setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const handleChange = useAfterFirstRender(({ values }: TestTemplateFieldProps): void => {
    setSearchValues(values);
    fetchTestTemplates(values);
  });

  const fetchTestTemplates = debounce((values: Partial<TestTemplateSearchRequest>): void => {

    const onFinally = () => {
      setTestTemplatesLoaded(true);
    };

    setTestTemplatesLoaded(false);
    setFoundTestTemplates([]);

    let newSearchValues = { ...values };
    if (_.isEmpty(newSearchValues)) {
      newSearchValues = {
        searchKey: '',
        active: values.active,
      };
    }

    searchTestTemplates(newSearchValues, cancelTokenSource)
    .then(response => {
        setFoundTestTemplates(response);
      },
    )
    .catch((e: any) => handleError(e.response.data))
    .finally(onFinally);

  }, 300);

  const openUpsertTestTemplate = (id?: number): void => {
    id ? history.push("/test-template", {id: id}) : history.push("/test-template")
  };

  const renderSearchForm = (): JSX.Element => {
    return (
      <FinalForm
        onSubmit={() => {
        }}
        initialValues={{
          searchKey: '',
          active: false,
        }}
        subscription={{ pristine: true, values: true }}
        render={renderSearchFormContent}
      />
    );
  };

  const renderSearchFormContent = ({ form, values }: FormRenderProps): React.ReactNode => {

    return (
      <>
        <div className='search-container'>
          <div className='title-h1'>{t('testTemplate.viewTitle')}</div>
          <div className='search-form'>
            <Field
              id='searchKey'
              name='searchKey'
              component={Input}
              icon={'search'}
              iconPosition='left'
              placeholder={t('testTemplate.placeholder')}
              autoFocus
              fluid
            />

            <CompositeButton
              type='button'
              className='action-button'
              onClick={() => openUpsertTestTemplate()}
              primary
            >
              {t('button.add')}
            </CompositeButton>
          </div>
        </div>

        {errorMessages.length > 0 &&
          <div className='error'>
            <StyledErrorMessage onDismiss={() => setErrorMessage()}>
              {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
            </StyledErrorMessage>
          </div>
        }

        <Field
          id='active'
          name='active'
          component={TabComponent}
        />

        <div className='data-table'>
          {testTemplatesLoaded && renderTestTemplatesTable(values)}
          {!testTemplatesLoaded &&
          <Loader className='table-loader' active inline content={t('testTemplate.loading')} />}
        </div>

        <FormSpy subscription={{ values: true }} onChange={handleChange} />
      </>
    );
  };

  const testTemplatesRowGetter = ({ index }: any) => {

    Object.assign(foundTestTemplates[index], { index: index + 1 });

    return foundTestTemplates[index];
  };

  const testTemplatesRowRenderer = ({ className, columns, index, key, style }: any) => {
    const a11yProps = { 'aria-rowindex': index + 1 };

    const testTemplateId = foundTestTemplates[index].id;

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role='row'
        style={style}
        onDoubleClick={() => openUpsertTestTemplate(testTemplateId!)}
      >
        {columns}
      </div>
    );
  };

  const renderTestTemplatesTable = (values: any): JSX.Element => {
    return (
      <VirtualizedTable
        rowCount={foundTestTemplates.length}
        rowGetter={testTemplatesRowGetter}
        rowRenderer={testTemplatesRowRenderer}
        columns={[
          {
            width: 50,
            flexShrink: 0,
            label: t('testTemplate.id'),
            dataKey: 'id',
          },
          {
            width: 300,
            flexGrow: 1,
            label: t('testTemplate.description'),
            dataKey: 'description',
            cellRenderer: multiLanguageFieldCellRenderer('description', language)
          },
          {
            width: 50,
            label: shortLabel(t("testTemplate.numberOfTestConfs"), t("testTemplate.numberOfTestConfsShort")),
            dataKey: 'numOfTestConfs',
            cellRenderer: (numberOfTestConfsCellRenderer)
          },
          {
            width: 50,
            label: t('testTemplate.status'),
            dataKey: 'active',
            cellRenderer: statusCellRenderer
          },
          {
            width: 200,
            flexShrink: 0,
            label: t('testTemplate.actions'),
            dataKey: 'id',
            cellRenderer: (actionsCellRenderer(values)),
          },
        ]}
      />
    );
  };

  const statusCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return <Icon name='check' size='small' />;
    }
    return emptyTableCell();
  };

  const numberOfTestConfsCellRenderer = ({ cellData }: any) => {
    if (!cellData || cellData === 0) {
      return emptyTableCell();
    }
    return cellData;
  };

  const deleteTestTemplate = (id: number, values: Partial<TestTemplateSearchRequest>) => (): void => {
    deleteTestTemplateById(id, cancelTokenSource)
    .then(() => {
      fetchTestTemplates(values);
    });
  };

  const translateDescription = (templateDto: TestTemplateDto) => {
    const description = templateDto.description;

    const testTemplateDescriptions: { [key: string]: string } = {
      'de': templateDto.description || description,
      'en': templateDto.descriptionEn || description,
      'fr': templateDto.descriptionFr || description,
      'it': templateDto.descriptionIt || description,
    };
    return testTemplateDescriptions[language];
  }

  const actionsCellRenderer = (values: Partial<TestTemplateSearchRequest>) => ({ rowData }: any) => {
    return (
      <div className='row-actions'>
        <InnerTableActionButton
          message={t('button.edit')}
          onConfirm={() => openUpsertTestTemplate(rowData.id)}
          divider={true}
        />
        <DeleteRecordConfirmation triggerButtonText={t('button.delete')}
                                  confirmAction={deleteTestTemplate(rowData.id, values)}
                                  deleteConfirmationText={t('testTemplate.confirmDelete', { description: translateDescription(rowData) })}
                                  position={"top left"} />
      </div>
    );
  };

  return (
    <SearchDisplayContainer>
      {renderSearchForm()}
    </SearchDisplayContainer>
  );
};

export default TestTemplateView;