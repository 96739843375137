import axios from 'axios';
import CheckBox from 'components/final-form/CheckBox';
import DataLabel from 'components/final-form/DataLabel';
import Input from 'components/final-form/Input';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import CompositeButton from 'components/final-form/CompositeButton';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import { noop } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Header } from 'semantic-ui-react';
import { addAgeRange, editAgeRange, getAgeRangeById } from 'service/ageRangesServices';
import { UpsertAgeRangesDto } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';

interface Props {

}

const UpsertAgeRangesForm = (props: Props) => {

  const cancelTokenSource = useMemo(axios.CancelToken.source, []);

  const { t } = useTranslation('teresa');
  const { push } = useHistory();
  const { state } = useLocation();
  const ageRangeId: number | undefined = state?.id ? Number(state.id) : undefined;


  const initialAgeRangeValues: Partial<UpsertAgeRangesDto> = {
    active: true,
  };

  const [ageRange, setAgeRange] = React.useState<Partial<UpsertAgeRangesDto> | undefined>(initialAgeRangeValues);
  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(true);
  const [successMsg, setSuccessMsg] = React.useState<string | undefined>(undefined);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);


  useEffect(() => {
    if (ageRangeId) {
      setFormDataLoaded(false);
      getAgeRangeById(ageRangeId, cancelTokenSource)
      .then(response => {
        const upsertAgeRangesDto: Partial<UpsertAgeRangesDto> = {
          ...response,
        };
        setAgeRange(upsertAgeRangesDto);
      })
      .catch((e: any) => handleError(e.response.data))
      .finally(() => setFormDataLoaded(true));
    }
  }, []);

  const handleError = useCallback((error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.invalidInput,
        errorUtils.ageRangeNotFound
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }

  }, []);


  const setErrorMessage = (errorMessage?: string) => {
    if (errorMessage) {
      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const handleSubmit = useCallback(async (values: Partial<UpsertAgeRangesDto>) => {

    let saveValues: Partial<UpsertAgeRangesDto> = {
      ...values,
    };

    const onSave = () => {
      ageRangeId
        ? setSuccessMsg(t('ageRanges.onUpdate'))
        : setSuccessMsg(t('ageRanges.onAdd'));
      setTimeout(() => {
        goToPreviousPage();
      }, 1200);
    };

    if (ageRangeId) {
      await editAgeRange(ageRangeId, saveValues, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e.response.data));
    } else {
      await addAgeRange(saveValues, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e.response.data));
    }
  }, []);


  const goToPreviousPage = useCallback(() => {
    let ageRangeSearch = '/age-ranges/search';

    push(ageRangeSearch);

  }, []);

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={(values: UpsertAgeRangesDto) => handleSubmit(values)}
        initialValues={ageRange}
        subscription={{ pristine: true, submitting: true }}
        render={(formProps: FormRenderProps<UpsertAgeRangesDto>) => renderFormContent(formProps)}
      />
    );
  };

  const renderFormContent = ({ handleSubmit, submitting, form }: FormRenderProps<UpsertAgeRangesDto>): React.ReactNode => {
    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          <Grid.Row>
            <Grid.Column width={16}>
              <div className='title-h1'>{t('ageRanges.viewTitle')}</div>
            </Grid.Column>
          </Grid.Row>

          {
            errorMessages.length > 0 &&
            <Grid.Row>
              <Grid.Column width={12}>
                <div className='error'>
                  <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                    {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                  </StyledErrorMessage>
                </div>
              </Grid.Column>
            </Grid.Row>
          }

          <Grid.Row>
            <Grid.Column width={4}>
              <Header as='h4'>{t('ageRanges.generalInfo')}</Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={6}>
              <DataLabel>{t('ageRanges.description')}</DataLabel>
              <Field
                fluid
                name='description'
                component={Input}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={6}>
              <DataLabel>{t('ageRanges.from')}</DataLabel>
              <Field
                fluid
                name='fromAgeRange'
                component={Input}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={6}>
              <DataLabel>{t('ageRanges.to')}</DataLabel>
              <Field
                fluid
                name='toAgeRange'
                component={Input}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={6} textAlign='left'>
              <DataLabel>{t('ageRanges.status')}</DataLabel>
              <Field
                name='active'
                component={CheckBox}
                label={t('ageRanges.active')}
                toggle
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={12}
                         style={{ borderTop: '1px solid #d4d9e0', marginTop: '2rem' }}
            >
            </Grid.Column>
          </Grid.Row>

          <Grid.Row textAlign={'right'}>
            <Grid.Column width={12} style={{ paddingRight: '0' }}>
              {
                successMsg &&
                <SaveAndUpdateConfirmationMessage>
                  {successMsg}
                </SaveAndUpdateConfirmationMessage>
              }
              <CompositeButton
                primary
                type='submit'
                className='action-button'
                disabled={submitting || !!successMsg}
                onClick={noop}
              >
                {t('button.save')}
              </CompositeButton>
              <CompositeButton
                type='button'
                className='action-button'
                secondary
                disabled={submitting || !!successMsg}
                onClick={goToPreviousPage}
              >
                {t('action.back')}
              </CompositeButton>
            </Grid.Column>
          </Grid.Row>
        </TsaGrid>
      </form>
    );
  };


  return (
    <UpsertContentWrapperDiv>
      {formDataLoaded
        ? <React.Fragment>
          {renderFinalForm()}
        </React.Fragment>
        : <LoaderComponent message={t('ageRanges.loading')} />
      }
    </UpsertContentWrapperDiv>
  );

};
export default UpsertAgeRangesForm;