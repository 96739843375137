import axios from 'axios';
import DataLabel from 'components/final-form/DataLabel';
import Input from 'components/final-form/Input';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import Select, { stringArrayToDropdownOptionArray } from 'components/final-form/Select';
import CompositeButton from 'components/final-form/CompositeButton';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import { noop } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Header } from 'semantic-ui-react';
import { getAccountById } from 'service/accountService';
import { addAccount, addTestAdmin, editAccount } from 'service/adminService';
import { UpsertAccountDto } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { required } from 'util/validatorUtils';

interface Props {

}

const UpsertAccountForm = (props: Props) => {

  const cancelTokenSource = useMemo(axios.CancelToken.source, []);

  const { t } = useTranslation('teresa');
  const { push } = useHistory();
  const { state } = useLocation();
  const accountId: number | undefined = state?.id ? Number(state.id) : undefined;
  const isTestAdmin: boolean | undefined = state.isTestAdmin ? Boolean(state.isTestAdmin) : false;
  const isIpad = useIsIpadWidthOrBelow();

  const [account, setAccount] = React.useState<Partial<UpsertAccountDto> | undefined>({ roles: [] });
  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(true);
  const [successMsg, setSuccessMsg] = React.useState<string | undefined>(undefined);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const roleLabels: Array<string> = ['ROLE_TEST_ADMIN', 'ROLE_APP_ADMIN'];

  const roles = useMemo(() =>
    stringArrayToDropdownOptionArray(roleLabels, t, 'role'), []);

  useEffect(() => {
    fetchFormData();
  }, []);

  const fetchFormData = async () => {

    const onFinally = () => setFormDataLoaded(true);

    setFormDataLoaded(false);

    let foundAccount = { ...account };

    try {
      if (accountId) {
        foundAccount = await getAccountById(accountId, cancelTokenSource);
        setAccount(foundAccount);
      }
    } catch (e) {

    } finally {
      onFinally();
    }

  };

  const handleError = useCallback((error: any) => {
    if (error) {
      let errMsg = undefined;
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.invalidInput,
        errorUtils.invalidEmail,
        errorUtils.emailAlreadyInuse,
        errorUtils.usernameAlreadyExists,
        errorUtils.accountNotFound
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsg = (t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errMsg) {
        if (knownErrors.includes(errorCode)) {
          errMsg = (t(`error.${errorCode}`));
        } else {
          errMsg = (t('error.general'));
        }
      }

      setErrorMessage(errMsg);
    }

  }, []);


  const setErrorMessage = (errorMessage?: string) => {
    if (errorMessage) {
      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const handleSubmit = useCallback(async (values: UpsertAccountDto) => {

    let saveValues: UpsertAccountDto = {
      ...values,
    };

    const onSave = () => {
      accountId
        ? setSuccessMsg(t('account.onUpdate'))
        : setSuccessMsg(t('account.onAdd'));
      setTimeout(() => goToPreviousPage(), 1200);
    };

    if (accountId) {
      await editAccount(accountId, saveValues, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e.response.data));
    } else {
      if (isTestAdmin) {
        await addTestAdmin(saveValues, cancelTokenSource)
        .then(onSave)
        .catch((e: any) => handleError(e.response.data));
      } else {
        await addAccount(saveValues, cancelTokenSource)
        .then(onSave)
        .catch((e: any) => handleError(e.response.data));
      }
    }


  }, []);


  const goToPreviousPage = useCallback(() => {
    let clinicSearch = '/account/search';
    const selectedTab = state ? state.selectedTab : 0;

    push(clinicSearch, { prevSelectedTab: selectedTab });

  }, []);

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={(values: UpsertAccountDto) => handleSubmit(values)}
        initialValues={account}
        subscription={{ pristine: true, submitting: true, values: true }}
        render={(formProps: FormRenderProps<UpsertAccountDto>) => renderFormContent(formProps)}
      />
    );
  };

  const renderFormContent = ({ handleSubmit, submitting, values }: FormRenderProps<UpsertAccountDto>): React.ReactNode => {

    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          <Grid.Row>
            <Grid.Column width={16}>
              <div className='title-h1'>{t('account.viewTitle')}</div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16}>
              <Grid>

                {
                  errorMessages.length > 0 &&
                  <Grid.Row>
                    <Grid.Column width={12}>
                      <div className='error'>
                        <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                          {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                        </StyledErrorMessage>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                }

                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as='h4'>{t('account.generalInfo')}</Header>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={isIpad ? 12 : 6}>
                    <DataLabel>{t('account.username')}</DataLabel>
                    <Field
                      fluid
                      name={'username'}
                      component={Input}
                      validate={required}
                      autoFocus
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={isIpad ? 12 : 6}>
                    <DataLabel>{t('account.name')}</DataLabel>
                    <Field
                      fluid
                      name={'name'}
                      component={Input}
                      validate={required}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={isIpad ? 12 : 6}>
                    <DataLabel>{t('account.lastName')}</DataLabel>
                    <Field
                      fluid
                      name={'lastName'}
                      component={Input}
                      validate={required}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={isIpad ? 12 : 6}>
                    <DataLabel>{t('account.email')}</DataLabel>
                    <Field
                      fluid
                      name={'email'}
                      component={Input}
                      validate={required}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={isIpad ? 12 : 6}>
                    <DataLabel>{t('account.role')}</DataLabel>
                    <Field
                      name='roles'
                      component={Select}
                      options={roles}
                      clearable
                      multiple
                      fluid
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={12}
                               style={{ borderTop: '1px solid #d4d9e0', marginTop: '2rem' }}
                  >
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row textAlign={'right'}>
            <Grid.Column width={12} style={{ paddingRight: '0' }}>
              {
                successMsg &&
                <SaveAndUpdateConfirmationMessage>
                  {successMsg}
                </SaveAndUpdateConfirmationMessage>
              }
              <CompositeButton
                primary
                type='submit'
                className='action-button'
                disabled={submitting || !!successMsg}
                onClick={noop}
              >
                {t('button.save')}
              </CompositeButton>
              <CompositeButton
                type='button'
                className='action-button'
                secondary
                disabled={submitting || !!successMsg}
                onClick={goToPreviousPage}
              >
                {t('action.back')}
              </CompositeButton>
            </Grid.Column>
          </Grid.Row>
        </TsaGrid>
      </form>
    );
  };

  return (
    <UpsertContentWrapperDiv>
      {formDataLoaded
        ? <React.Fragment>
          {renderFinalForm()}
        </React.Fragment>
        : <LoaderComponent message={t('account.loading')} />
      }
    </UpsertContentWrapperDiv>
  );

};
export default UpsertAccountForm;