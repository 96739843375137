import React, { Component } from 'react';
import { DividerProps } from 'semantic-ui-react';
import styled from 'styled-components';

const PopupContent = styled.pre`
  background-color: white; 
  line-height: 20px;
  border: none;
  overflow: hidden;
  min-height: 90px;
  min-width: 700px;
  max-width: 700px;
  
  :focus {
    outline: none;
  }
`

class FormulaPopupContent extends Component<DividerProps> {

  render(): React.ReactNode {
    const {children, ...rest} = this.props;
    return (
      <PopupContent {...rest}>
        {children}
      </PopupContent>
    )
  }
}

export default FormulaPopupContent;
