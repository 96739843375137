import { CancelTokenSource } from 'axios';
import axios, { backendUrls } from 'service/http';
import {
  ClinicConfigDto,
  ClinicConfigRequestDto,
  ExerciseConfDto,
  ExerciseTemplateDto,
  QuestionSetDto,
  TestConfDto,
  TestTemplateDto,
} from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/clinic-config`;

export const getClinicConfigData = (clinicId: number, source: CancelTokenSource): Promise<ClinicConfigDto> => {
  return axios
  .get(`${apiUri}/${clinicId}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const saveClinicTestConf = (request: ClinicConfigRequestDto, source: CancelTokenSource): Promise<number> => {
  return axios
  .post(`${apiUri}/test-conf`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const saveClinicTestTemplate = (request: ClinicConfigRequestDto, source: CancelTokenSource): Promise<number> => {
  return axios
  .post(`${apiUri}/test-template`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const saveClinicExerciseConf = (request: ClinicConfigRequestDto, source: CancelTokenSource): Promise<number> => {
  return axios
  .post(`${apiUri}/exercise-conf`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const saveClinicExerciseTemplate = (request: ClinicConfigRequestDto, source: CancelTokenSource): Promise<number> => {
  return axios
  .post(`${apiUri}/exercise-template`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const saveClinicQuestionSet = (request: ClinicConfigRequestDto, source: CancelTokenSource): Promise<number> => {
  return axios
  .post(`${apiUri}/question-set`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const deleteClinicTestConf = (request: ClinicConfigRequestDto, source: CancelTokenSource): Promise<number> => {
  return axios
  .post(`${apiUri}/delete/test-conf`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const deleteClinicTestTemplate = (request: ClinicConfigRequestDto, source: CancelTokenSource): Promise<number> => {
  return axios
  .post(`${apiUri}/delete/test-template`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const deleteClinicExerciseConf = (request: ClinicConfigRequestDto, source: CancelTokenSource): Promise<number> => {
  return axios
  .post(`${apiUri}/delete/exercise-conf`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const deleteClinicExerciseTemplate = (request: ClinicConfigRequestDto, source: CancelTokenSource): Promise<number> => {
  return axios
  .post(`${apiUri}/delete/exercise-template`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const deleteClinicQuestionSet = (request: ClinicConfigRequestDto, source: CancelTokenSource): Promise<number> => {
  return axios
  .post(`${apiUri}/delete/question-set`, request, { cancelToken: source.token })
  .then(response => response.data);
};
