import { useEffect, useState } from 'react';

function useIsIpadWidthOrBelow(width?: number): boolean {
  const [isIpadWidth, setIsIpadWidth] = useState(false);

  useEffect(() => {
    const iPadMaxWidth = width ? width : 1024;
    let resizeTimeout: any;

    const handleWindowResize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        setIsIpadWidth(window.innerWidth <= iPadMaxWidth);
      }, 200);
    }

    handleWindowResize();

    const cleanup = () => {
      clearTimeout(resizeTimeout);
      window.removeEventListener('resize', handleWindowResize);
      window.removeEventListener('beforeunload', cleanup);
    }

    window.addEventListener('resize', handleWindowResize);
    window.addEventListener('beforeunload', cleanup);

    return cleanup;
  }, []);

  return isIpadWidth;
}

export default useIsIpadWidthOrBelow;
