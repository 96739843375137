import { useAuthContext } from 'auth/AuthContext';
import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import Input from 'components/final-form/Input';
import CompositeButton from 'components/final-form/CompositeButton';
import TabComponent from 'components/final-form/TabComponent';
import InnerTableActionButton from 'components/InnerTableActionButton';
import SearchDisplayContainer from 'components/SearchDisplayContainer';
import VirtualizedTable from 'components/VirtualizedTable';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Loader } from 'semantic-ui-react';
import axios from 'service/http';
import { deleteTrueScoreResult, searchTrueScoreRules } from 'service/trueScoreResultService';
import { debounce } from 'ts-debounce';
import { TrueScoreResultDto, TrueScoreResultSearchRequest } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { useAfterFirstRender } from 'util/functionUtils';
import { emptyTableCell, multiLanguageFieldCellRenderer } from 'util/tableUtils';

const cancelTokenSource = axios.CancelToken.source();

interface Props {
}

const TrueScoreRulesView = (props: Props) => {

  const { t } = useTranslation('teresa');
  const history = useHistory();
  const { language } = useAuthContext();

  const [trueScoreRulesLoaded, setTrueScoreRulesLoaded] = useState<boolean>(true);
  const [foundTrueScoreRules, setFoundTrueScoreRules] = useState<TrueScoreResultDto[]>([]);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [searchValues, setSearchValues] = useState<Partial<TrueScoreResultSearchRequest>>({
    searchKey: '',
  });

  useEffect(() => {
    fetchTrueScoreRules(searchValues);
  }, []);

  const handleError = (error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.userNotAdmin,
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      } else {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };

  const setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const fetchTrueScoreRules = debounce((values: Partial<TrueScoreResultSearchRequest>): void => {

    const onFinally = () => {
      setTrueScoreRulesLoaded(true);
    };

    setTrueScoreRulesLoaded(false);
    setFoundTrueScoreRules([]);

    let newSearchValues = { ...values };
    if (_.isEmpty(newSearchValues)) {
      newSearchValues = {
        searchKey: '',
      };
    }

    searchTrueScoreRules(newSearchValues, cancelTokenSource)
    .then(response => {
        setFoundTrueScoreRules(response);
      },
    )
    .catch((e: any) => handleError(e.response.data))
    .finally(onFinally);

  }, 300);

  const handleChange = useAfterFirstRender(({ values }: any): void => {
    setSearchValues(values);
    fetchTrueScoreRules(values);
  });


  const deleteTrueScoreRule = (id: number, values: Partial<TrueScoreResultSearchRequest>) => (): void => {
    deleteTrueScoreResult(id, cancelTokenSource)
    .then(() => {
      fetchTrueScoreRules(values);
    });
  };

  const trueScoreRulesRowGetter = ({ index }: any) => {

    Object.assign(foundTrueScoreRules[index], { index: index + 1 });

    return foundTrueScoreRules[index];
  };

  const trueScoreRulesRowRenderer = ({ className, columns, index, key, style }: any) => {
    const a11yProps = { 'aria-rowindex': index + 1 };

    const trueScoreRuleId = foundTrueScoreRules[index].id;

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role='row'
        style={style}
        onDoubleClick={() => openTrueScoreRulesDetails(trueScoreRuleId!)}
      >
        {columns}
      </div>
    );
  };

  const openTrueScoreRulesDetails = (id: number): void => {
    history.push('/truescorerule', {
      trueScoreResultId: id,

    });
  };

  const descriptionCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return cellData;
    }
    return emptyTableCell();
  };

  const trueScoreResultTypeCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return t("trueScoreResultType." + cellData);
    }
    return emptyTableCell();
  };

  const activeCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return <Icon name='check' size='small' />;
    }
    return emptyTableCell();
  };

  const trueScoreRulesActionCellRenderer = (values: Partial<TrueScoreResultSearchRequest>) => ({ rowData }: any) => {
    return (
      <div className='row-actions'>
        <InnerTableActionButton
          message={t('button.edit')}
          onConfirm={() => openTrueScoreRulesDetails(rowData.id)}
          divider={true}
        />
        <DeleteRecordConfirmation triggerButtonText={t('button.delete')}
                                  confirmAction={deleteTrueScoreRule(rowData.id, values)}
                                  deleteConfirmationText={t('truescorerules.confirmDelete', { id: rowData.id })}
                                  position={"top left"}/>
      </div>
    );
  };

  const trueScoreCellRenderer = ({ rowData }: any) => {

    return (
      <>
        {t(`truescoreresult.trueScore.${rowData.trueResult}`)}
      </>
    );
  };

  const renderTrueScoreRulesTable = (values: any): JSX.Element => {
    return (
      <VirtualizedTable
        rowCount={foundTrueScoreRules.length}
        rowGetter={trueScoreRulesRowGetter}
        rowRenderer={trueScoreRulesRowRenderer}
        columns={[
          {
            width: 50,
            flexShrink: 0,
            label: t('truescorerules.id'),
            dataKey: 'id',
          },
          {
            width: 150,
            flexGrow: 1,
            label: t('truescorerules.description'),
            dataKey: 'description',
            cellRenderer: descriptionCellRenderer,
          },
          {
            width: 150,
            label: t('truescorerules.trueScoreResultType'),
            dataKey: 'trueScoreResultType',
            cellRenderer: trueScoreResultTypeCellRenderer
          },
          {
            width: 100,
            flexGrow: 1,
            label: t('truescoreresult.testResultId'),
            dataKey: 'testResultId',
            cellRenderer: multiLanguageFieldCellRenderer('testResultDesc', language),
          },
          {
            width: 150,
            label: t('truescorerules.fromScoreRaw'),
            dataKey: 'fromScoreRaw',
          },
          {
            width: 150,
            label: t('truescorerules.toScoreRaw'),
            dataKey: 'toScoreRaw',
          },
          {
            width: 100,
            label: t('truescorerules.trueResultEnum'),
            dataKey: 'trueResult',
            cellRenderer: trueScoreCellRenderer,
          },
          {
            width: 100,
            label: t('truescorerules.status'),
            dataKey: 'active',
            cellRenderer: activeCellRenderer,
          },
          {
            width: 200,
            flexShrink: 0,
            dataKey: 'id',
            label: t('truescorerules.actions'),
            cellRenderer: trueScoreRulesActionCellRenderer(values),
          },
        ]}
      />
    );
  };

  const renderTrueScoreRulesForm = (): JSX.Element => {
    return (
      <FinalForm
        onSubmit={() => {
        }}
        initialValues={{
          searchKey: '',
        }}
        subscription={{ pristine: true, values: true }}
        render={renderSearchFormContent}
      />
    );
  };

  const renderSearchFormContent = ({ values }: FormRenderProps): React.ReactNode => {

    return (
      <>
        <div className='search-container'>
          <div className='title-h1'>{t('truescorerules.viewTitle')}</div>

          <div className='search-form'>
            <Field
              id='searchKey'
              name='searchKey'
              component={Input}
              icon={'search'}
              iconPosition='left'
              placeholder={t('truescorerules.placeholder')}
              autoFocus
              fluid
            />

            <CompositeButton
              type='button'
              className='action-button'
              onClick={() => history.push('/truescorerule', {
                trueScoreResultId: values.id,
              })}
              primary
              style={{ display: 'inline-block' }}
            >
              {t('button.add')}
            </CompositeButton>
          </div>
        </div>

        <Field
          id='active'
          name='active'
          component={TabComponent}
        />

        <div className='data-table'>
          {trueScoreRulesLoaded && renderTrueScoreRulesTable(values)}
          {!trueScoreRulesLoaded &&
            <Loader className='table-loader' active inline content={t('truescorerules.loading')} />}
        </div>

        <FormSpy subscription={{ values: true }} onChange={handleChange} />
      </>
    );
  };


  return <SearchDisplayContainer>
    {renderTrueScoreRulesForm()}
  </SearchDisplayContainer>;

};

export default TrueScoreRulesView;