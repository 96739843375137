import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import CheckBox from 'components/final-form/CheckBox';
import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import MultiLanguageInput from 'components/final-form/MultiLanguageInput';
import MultiLanguageTextArea from 'components/final-form/MultiLanguageTextArea';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import FormModalContentContainer from 'components/FormModalContentContainer';
import { defaultPopupStyle } from 'components/HeaderButton';
import InnerFormTableWithOrder from 'components/InnerFormTableWithOrder';
import InnerTableActionButton from 'components/InnerTableActionButton';
import InnerTsaGrid from 'components/InnerTsaGrid';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import { useExerciseConfFormDataContext } from 'context/ExerciseConfFormDataContext';
import { FormApi } from 'final-form';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { ExtendedUpsertExerciseResultDto } from 'routes/exerciseresult/UpsertExerciseResultForm';
import TestConfAddParamsModal from 'routes/testconf/TestConfAddParamsModal';
import { Grid, Icon, Modal, Popup } from 'semantic-ui-react';
import { addExerciseConf, editExerciseConf, getExerciseConf } from 'service/exerciseService';
import { getAvailableParamsForExerciseConf, getExerciseResultParams } from 'service/testResultParamServices';
import styled from 'styled-components';
import { ScopeType } from 'ts-types/api.enums';
import {
  ExerciseResultData,
  ExerciseResultDto,
  ExerciseResultParamDto,
  TestResultParamDto,
  UpsertExerciseConfDto,
  UpsertExerciseResultDto,
} from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { useAfterFirstRender } from 'util/functionUtils';
import { emptyTableCell, multiLanguageInnerTableCellRenderer } from 'util/tableUtils';

const ActionContainer = styled.div`
  flex-grow: 1;
  border-radius: unset;
  margin-left: 5px;
  padding-top: 34px;
`;

interface Props {
}

const cancelTokenSource = axios.CancelToken.source();

const UpsertExerciseConfForm = (props: Props) => {

  const {
    exerciseConfFormData,setExerciseConfFormData,
    setExerciseResult, deleteExerciseResult,
    deleteExerciseResultParam, onAddExerciseResultParam,
    onDragAndDropExerciseConfParams,
  } = useExerciseConfFormDataContext();

  const { state } = useLocation();
  const { language, currentUser } = useAuthContext();

  const exerciseConfId: number | undefined = state?.id ? Number(state?.id) : undefined;
  const { t } = useTranslation('teresa');
  const history = useHistory();

  const initialExerciseConfValues: Partial<UpsertExerciseConfDto> = {
    exerciseResultDataList: [],
    exerciseResults: [],
    exerciseResultParams: [],
    active: true,
  };

  const [exerciseResultParams, setExerciseResultParams] = useState<TestResultParamDto[]>([]);
  const [exerciseResultData, setExerciseResultData] = React.useState<ExerciseResultData[]>([])
  const [exerciseResults, setExerciseResults] = React.useState<UpsertExerciseResultDto[]>([]);
  const [exerciseResultParamMap, setExerciseResultParamMap] = useState<{ [key: number]: TestResultParamDto }>([]);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(true);
  const [successMsg, setSuccessMsg] = React.useState<string | undefined>(undefined);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [warningMsg, setWarningMsg] = useState<string | undefined>(undefined);

  useEffect(() => {
    fetchData();
    if(!exerciseConfId){
      setWarningMsg(t('exerciseResult.warningMessage'));
    }

    /* manages problems when the user goes back one page with the back browser button not with component back button
    if the pathname for exercise conf search gets changed than this will not work
    return statement used it useEffect reacts as componentDidUnmount in older react versions */

    return () => {
      if (history.location.pathname !== '/exerciseresult') {
        setExerciseConfFormData(undefined);
      }
    };
  }, []);

  useEffect(() => {
    fetchExerciseConfAvailableParams(exerciseConfId);
  }, []);

  const fetchExerciseConfAvailableParams = async (id?: number) => {
    const exerciseResultParamResponse = await getAvailableParamsForExerciseConf(id, cancelTokenSource);
    setExerciseResultParams(exerciseResultParamResponse);
  };

  const fetchData = async () => {

    setFormDataLoaded(false);

    try {

      const exerciseResultParamResponse = await getExerciseResultParams(true, cancelTokenSource);
      setExerciseResultParamMap(_.keyBy(exerciseResultParamResponse, 'id'));
      if (!exerciseConfFormData) {

        if (exerciseConfId) {
          const exerciseConfResponse = await getExerciseConf(exerciseConfId, cancelTokenSource);
          setExerciseConfFormData(exerciseConfResponse);

          if (exerciseConfResponse && exerciseConfResponse.exerciseResults) {
            setExerciseResults(exerciseConfResponse.exerciseResults);
          }

          if (exerciseConfResponse && exerciseConfResponse.exerciseResultDataList) {
            setExerciseResultData(exerciseConfResponse.exerciseResultDataList);
          }

        } else {
          setExerciseConfFormData(initialExerciseConfValues);
        }
      } else {

        let exerciseResultDataList =
          _.sortBy(exerciseConfFormData.exerciseResultDataList, 'orderIndex');

        setExerciseResultData(exerciseResultDataList);
        setExerciseResults(exerciseConfFormData!.exerciseResults!);
      }
    } catch (e) {
      handleError(e);
    } finally {
      setFormDataLoaded(true);
    }
  };

  const handleError = useCallback((error: any) => {

    if (error) {
      if (error.response && error.response.data) {
        const e = error.response.data;
        let errMsgs = [];
        const errorCode = e.errorCode;
        const knownErrors: Array<string> = [
          errorUtils.exerciseConfNotFound,
          errorUtils.exerciseResultParamNotFound,
        ];

        const violations: Array<any> = e.violations;

        if (violations && violations.length > 0) {
          violations.forEach(violation => {
            if (knownErrors.includes(violation.errorCode)) {
              errMsgs.push(t(`error.${violation.errorCode}`));
            }
          });
        }

        if (!errorMessages.length) {
          if (knownErrors.includes(errorCode)) {
            errMsgs.push(t(`error.${errorCode}`));
          } else {
            errMsgs.push(t('error.general'));
          }
        }

        setErrorMessage(errMsgs);
      }
    } else {
      setErrorMessages([t('error.general')]);
    }

  }, []);

  const setErrorMessage = (errMsgs?: string[]) => {

    if (errMsgs) {
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const submitHandler = useCallback(async (values: Partial<UpsertExerciseConfDto>) => {

    let saveValues: Partial<UpsertExerciseConfDto> = {
      ...values,
    };

    const onSave = () => {
      exerciseConfId
        ? setSuccessMsg(t('exerciseConf.onUpdate'))
        : setSuccessMsg(t('exerciseConf.onAdd'));
      setTimeout(() => {
        history.push('/exerciseconf/search');
        setExerciseConfFormData(undefined);
      }, 1200);
    };

    addOrEditExerciseConf(saveValues, onSave);
  }, []);

  const addOrEditExerciseConf = async (
    values: Partial<UpsertExerciseConfDto>, onSave: () => void) => {

    if (exerciseConfId) {
      await editExerciseConf(exerciseConfId, values, cancelTokenSource)
      .then(() => {
        onSave();
      })
      .catch((e: any) => handleError(e));
    } else {
      await addExerciseConf(values, cancelTokenSource)
      .then(() => {
        onSave();
      })
      .catch((e: any) => handleError(e));
    }
  };

  const onDragAndDropExerciseResult = (form: FormApi<UpsertExerciseConfDto, Partial<UpsertExerciseConfDto>>) =>
    (startIx: number, endIx: number) => {

      if(exerciseResults && exerciseResults.length) {
        let exerciseResultsList = [...exerciseResults];
        const [removed] = exerciseResultsList.splice(startIx, 1);
        exerciseResultsList.splice(endIx, 0, removed);

        exerciseResultsList.forEach((exr, index) => {
          exr.orderIndex = index + 1;
        })

        form.change('exerciseResults', [...exerciseResultsList]);
        setExerciseResults(exerciseResultsList);
      }

    if(exerciseResultData && exerciseResultData.length) {
      let exerciseResultDataList = [...exerciseResultData];
      const [removed] = exerciseResultDataList.splice(startIx, 1);
      exerciseResultDataList.splice(endIx, 0, removed);

      exerciseResultDataList.forEach((exr, index) => {
        exr.orderIndex = index + 1;
      })
      form.change('exerciseResultDataList', [...exerciseResultDataList]);
      setExerciseResultData(exerciseResultDataList);
    }
  }

  const onAddExerciseResult = () => {
    let initialExerciseResultValues: Partial<ExtendedUpsertExerciseResultDto> = {
      exerciseConfId: exerciseConfId,
      active: true,
      activeStatus: 1,
      exerciseResultParams: [],
    };

    initialExerciseResultValues.orderIndex = exerciseConfFormData!.exerciseResultDataList!
    && exerciseConfFormData!.exerciseResultDataList!.length
      ? exerciseConfFormData!.exerciseResultDataList![exerciseResultData.length - 1].orderIndex + 1
      : 1;

    const exerciseResultIx = setExerciseResult(initialExerciseResultValues);
    history.push(`/exerciseresult`, { exerciseConfId: exerciseConfId, exerciseResultIx: exerciseResultIx });
  };

  const onEditExerciseResult = (exerciseResultIx: number, orderIndex: number) => {

    let erIx = exerciseConfFormData?.exerciseResultDataList!
    .findIndex(exerciseResult => exerciseResult === exerciseResultData[exerciseResultIx]);

    history.push(`/exerciseresult`, { exerciseConfId: exerciseConfId, exerciseResultIx: erIx, orderIndex: orderIndex });
  };

  const goBack = () => {
    setExerciseConfFormData(undefined);
    history.push('/exerciseconf/search');
  };

  const handleFormValuesChange = useAfterFirstRender(({ values }: any): void => {
    setExerciseConfFormData(values);
  });

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={(values: Partial<UpsertExerciseConfDto>) => submitHandler(values)}
        initialValues={exerciseConfFormData}
        subscription={{ pristine: true, submitting: true, values: true }}
        render={(formProps: FormRenderProps<UpsertExerciseConfDto>) => renderFormContent(formProps)}
      />
    );
  };

  const activeCellRenderer = (rowData: any) => {
    if (rowData.active) {
      return <Icon name='check' size='small' />;
    }
    return emptyTableCell();
  };

  const translateDescription = (exerciseResultDto: ExerciseResultDto) => {
    const description = exerciseResultDto.description;

    const testConfDescriptions: { [key: string]: string } = {
      'de': exerciseResultDto.description || description,
      'en': exerciseResultDto.descriptionEn || description,
      'fr': exerciseResultDto.descriptionFr || description,
      'it': exerciseResultDto.descriptionIt || description,
    };
    return testConfDescriptions[language];
  }

  const actionCellRenderer = (rowData: any, index: number) => {
    return <>
      <InnerTableActionButton
        message={t('button.edit')}
        onConfirm={() => onEditExerciseResult(index, rowData.orderIndex)}
        divider={true}
      />
      <DeleteRecordConfirmation triggerButtonText={t('action.delete')}
                                confirmAction={() => deleteExerciseResult(index)}
                                deleteConfirmationText={t('exerciseConf.confirmDelete', { description: translateDescription(rowData) })}
      />
    </>;
  };

  const orderIndexRenderer = (rowData: any) => {
    return rowData.orderIndex;
  }

  const descriptionCellRenderer = (rowData: any) => {

    const paramId = rowData.paramId;

    if (exerciseResultParamMap[paramId]) {
      return exerciseResultParamMap[paramId].description;
    }

    return emptyTableCell();
  };

  const unitCellRenderer = (rowData: any) => {

    const paramId = rowData.paramId;

    if (exerciseResultParamMap[paramId]) {
      return exerciseResultParamMap[paramId].unit;
    }

    return emptyTableCell();
  };

  const codeCellRenderer = (rowData: any) => {

    const paramId = rowData.paramId;

    if (exerciseResultParamMap[paramId]) {
      return exerciseResultParamMap[paramId].code;
    }

    return emptyTableCell();
  };


  const deleteResultParamCellRenderer = (rowData: any) => {

    const paramId = rowData.paramId;

    if (exerciseResultParamMap[paramId]) {
      return <DeleteRecordConfirmation triggerButtonText={t('testresult.delete')}
                                       confirmAction={() => deleteExerciseResultParam(paramId)}
                                       deleteConfirmationText={t('testresult.confirmDelete', { description: exerciseResultParamMap[paramId].description })}
                                       position={"top left"} />;
    }

    return emptyTableCell();
  };

  const renderExerciseResultParamsTable = (form: FormApi<UpsertExerciseConfDto>) => {
    return (
      <>
        <Grid.Row>
          <Grid.Column width={15}>
            <DataLabel>{t('exerciseResult.params')}</DataLabel>
            <InnerFormTableWithOrder id='exercise-conf-params' numOfCells={5}
                            values={exerciseConfFormData && exerciseConfFormData.exerciseResultParams
                              ? exerciseConfFormData.exerciseResultParams
                              : []
                            }
                            dragAndDropFn={onDragAndDropExerciseConfParams}
                            visibleRows={6}
                            columns={[
                              {
                                width: 2,
                                label: '#',
                                dataKey: 'orderIndex',
                                cellRenderer: orderIndexRenderer,
                              },
                              {
                                width: 6,
                                label: t('truescoreresult.description'),
                                dataKey: 'description',
                                oneLine: true,
                                cellRenderer: descriptionCellRenderer,
                              },
                              {
                                width: 2,
                                label: t('truescoreresult.unit'),
                                dataKey: 'unit',
                                cellRenderer: unitCellRenderer,
                              },
                              {
                                width: 2,
                                flexgrow: 1,
                                label: t('truescoreresult.code'),
                                dataKey: 'code',
                                cellRenderer: codeCellRenderer,
                              },
                              {
                                width: 2,
                                label: t('testresult.actions'),
                                dataKey: 'paramId',
                                cellRenderer: deleteResultParamCellRenderer,
                              },
                            ]}
            />
          </Grid.Column>
          <Grid.Column>
            <ActionContainer>
              <span>
                <InnerTableActionButton
                  message={t('button.add')}
                  onConfirm={() => setModalOpen(true)}
                />
              </span>
            </ActionContainer>
          </Grid.Column>
        </Grid.Row>
      </>
    );
  };

  const renderFormContent = (
    { handleSubmit, submitting, form }: FormRenderProps<UpsertExerciseConfDto>): React.ReactNode => {
    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          <Grid.Row>
            <Grid.Column width={12}>
              <div className='title-h1'>{t('exerciseConf.editTitle')}</div>
            </Grid.Column>
          </Grid.Row>

          {errorMessages.length > 0 &&
            <Grid.Row>
              <Grid.Column width={16}>
                <div className='error'>
                  <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                    {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                  </StyledErrorMessage>
                </div>
              </Grid.Column>
            </Grid.Row>
          }

          <Grid.Row>
            <Grid.Column width={16}>
              <Grid stackable doubling columns={2}>
                <Grid.Column width={6}>
                  <InnerTsaGrid>

                    <Grid.Row>
                      <Grid.Column width={16}>
                        <DataLabel>{t('exerciseConf.description')}</DataLabel>
                        <MultiLanguageInput fieldName='description' form={form} requiredFirst={true} />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={16}>
                        <DataLabel>{t('exerciseConf.instructionTherapist')}</DataLabel>
                        <MultiLanguageTextArea fieldName='instructionTherapist' form={form} rows={8} />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={16}>
                        <DataLabel>{t('exerciseConf.instructionPatient')}</DataLabel>
                        <MultiLanguageTextArea fieldName='instructionPatient' form={form} rows={8} />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={16} textAlign='left'>
                        <DataLabel>{t('exerciseConf.status')}</DataLabel>
                        <Field
                          name='active'
                          component={CheckBox}
                          label={t('exerciseConf.active')}
                          toggle
                        />
                      </Grid.Column>
                    </Grid.Row>

                  </InnerTsaGrid>
                </Grid.Column>

                <Grid.Column width={10}>
                  <InnerTsaGrid>
                    <Grid.Row>
                      <Grid.Column width={15} verticalAlign='top'>
                        <DataLabel>{t('exerciseResult.viewTitle')}</DataLabel>
                        <InnerFormTableWithOrder id='exercise-conf'
                                        numOfCells={6}
                                        values={exerciseConfFormData && exerciseConfFormData.exerciseResultDataList
                                          ? _.sortBy(exerciseConfFormData.exerciseResultDataList, 'orderIndex') : []}
                                        visibleRows={6}
                                        dragAndDropFn={onDragAndDropExerciseResult(form)}
                                        columns={[
                                          {
                                            width: 2,
                                            label: '#',
                                            dataKey: 'orderIndex',
                                          },
                                          {
                                            width: 14,
                                            flexGrow: 1,
                                            label: t('testconf.description'),
                                            dataKey: 'description',
                                            oneLine: true,
                                            cellRenderer: multiLanguageInnerTableCellRenderer('description', language),
                                          },
                                          {
                                            width: 14,
                                            flexGrow: 1,
                                            label: t('domain.viewTitle'),
                                            oneLine: true,
                                            dataKey: 'domainDescription',
                                          },
                                          {
                                            width: 2,
                                            label: t('testconf.selectedParams'),
                                            shortLabel: t('exerciseResult.shortSelectedParams'),
                                            dataKey: 'numOfExerciseResultParams',
                                          },
                                          {
                                            width: 2,
                                            label: t('testconf.testUses'),
                                            shortLabel: t('exerciseResult.shortExercisesUses'),
                                            dataKey: 'numOfExerciseUses',
                                          },
                                          {
                                            width: 2,
                                            label: t('active'),
                                            dataKey: 'active',
                                            cellRenderer: activeCellRenderer,
                                          },
                                          {
                                            width: 8,
                                            label: t('admin.actions'),
                                            dataKey: 'id',
                                            cellRenderer: actionCellRenderer,
                                          },
                                        ]} />
                      </Grid.Column>

                      <Grid.Column>
                        <ActionContainer>
                          <Popup content={warningMsg} on='hover'
                                 trigger={
                                   <span>
                              <InnerTableActionButton
                                message={t('button.add')}
                                onConfirm={onAddExerciseResult}
                              />
                            </span>
                                 }
                                 popperModifiers={[{ name: 'preventOverflow', options: { boundary: 'window' } }]}
                                 size='small'
                                 position='right center'
                                 disabled={exerciseConfId !== undefined}
                                 style={defaultPopupStyle}
                          />
                        </ActionContainer>
                      </Grid.Column>
                    </Grid.Row>
                    {renderExerciseResultParamsTable(form)}
                  </InnerTsaGrid>
                </Grid.Column>

                <Grid.Row>
                  <Grid.Column width={16} style={{ borderTop: '1px solid var(--very-light-blue)', marginTop: '2rem' }}>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row textAlign='right'>
                  <Grid.Column width={16} style={{ paddingRight: '0' }}>
                    {
                      successMsg &&
                      <SaveAndUpdateConfirmationMessage>
                        {successMsg}
                      </SaveAndUpdateConfirmationMessage>
                    }
                    <CompositeButton
                      primary
                      type='submit'
                      className='action-button'
                      disabled={submitting || !!successMsg}
                    >
                      {t('button.save')}
                    </CompositeButton>
                    <CompositeButton
                      type='button'
                      className='action-button'
                      secondary
                      disabled={submitting || !!successMsg}
                      onClick={goBack}
                    >
                      {t('action.back')}
                    </CompositeButton>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </TsaGrid>
        <FormSpy subscription={{ values: true }} onChange={handleFormValuesChange} />
      </form>
    );
  };

  const extractIds = (obj: any): number[] => {

    const ids = _.isArray(obj) ? [] : obj.paramId ? [obj.paramId] : [];

    _.forEach(obj, (value) => {
      if (_.isObject(value) || _.isArray(value)) {
        ids.push(...extractIds(value));
      }
    });

    return _.uniq(ids);
  }

  const renderExerciseParamsModal = (): React.ReactNode => {

    let selectedExerciseResultParams: ExerciseResultParamDto[] = [];

    if (exerciseConfFormData) {
      const exerciseResultParamIds = extractIds(exerciseConfFormData);
      const exerciseResultParams: ExerciseResultParamDto[] = exerciseResultParamIds.map(pId => {
        return {
          paramId: pId,
          orderIndex: 1,
        };
      });

      selectedExerciseResultParams = _.uniqBy(
        [...exerciseResultParams, ...exerciseConfFormData.exerciseResultParams!],
        'paramId',
      );
    }

    return (
      <Modal style={{ position: 'sticky' }}
             open={modalOpen} closeOnDimmerClick={true} onClose={() => setModalOpen(false)}>
        <Modal.Header>{t('testresult.testResultParams')}</Modal.Header>
        <FormModalContentContainer>
          <TestConfAddParamsModal
            testResultParams={exerciseResultParams}
            selectedTestResultParams={selectedExerciseResultParams}
            scopeType={ScopeType.REHAB}
            onAddExerciseResultParam={onAddExerciseResultParam}
            onCancel={() => setModalOpen(false)}
          />
        </FormModalContentContainer>
      </Modal>
    );
  };

  return (
    <UpsertContentWrapperDiv>
      {formDataLoaded
        ? <React.Fragment>
          {renderFinalForm()}
          {renderExerciseParamsModal()}
        </React.Fragment>
        : <LoaderComponent message={t('exerciseConf.loading')} />
      }
    </UpsertContentWrapperDiv>
  );
};
export default UpsertExerciseConfForm;