import { useAuthContext } from 'auth/AuthContext';
import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import DatePicker from 'components/final-form/DatePicker';
import Input from 'components/final-form/Input';
import Select, { DropdownOption } from 'components/final-form/Select';
import InnerTableActionButton from 'components/InnerTableActionButton';
import SearchDisplayContainer from 'components/SearchDisplayContainer';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import VirtualizedTable from 'components/VirtualizedTable';
import { useClinicInfoContext } from 'context/ClinicInfoContext';
import { FormApi } from 'final-form';
import createDecorator from 'final-form-calculate';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import _ from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Grid, GridColumn, Loader } from 'semantic-ui-react';
import PaymentModal from 'routes/clinic/PaymentModal';
import { getClinicConf } from 'service/clinicConfService';
import {
  deleteClinicInvoice,
  downloadClinicInvoiceExportPdf,
  getClinicInvoiceData,
  makeClinicInvoice,
  payClinicInvoice,
  searchByClinicAdminForClinicInvoiceData,
  searchClinicInvoiceData,
} from 'service/clinicInvoiceService';
import axios from 'service/http';
import { getInvoiceConfigs } from 'service/invoiceConfigServices';
import { getMandateBanksForClinicMandate } from 'service/mandateBankService';
import styled from 'styled-components';
import { InvoiceConfigType } from 'ts-types/api.enums';
import {
  ClinicInvoiceDataSearchRequest,
  ClinicInvoiceDto,
  ClinicInvoicePaymentRequestDto,
  ClinicInvoiceRequestDto,
  ClinicInvoiceSearchRequest,
  ClinicInvoiceTotalsDto,
  InvoiceConfigDto,
  UpsertMandateBankDto,
} from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { noop } from 'util/functionUtils';
import { emptyTableCell } from 'util/tableUtils';
import {
  composeValidators,
  mustBeAFullNumber,
  mustBePositiveNumber,
  mustNotBeZero,
  required,
} from 'util/validatorUtils';

const ClinicInvoiceDataContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
`;

const ClinicInvoiceDataItem = styled(DataLabel).attrs((props) => ({}))`
    padding: 0.5em 1.1em 0 1.1em;
    text-align: center;
    font-weight: ${(props) => props.bold ? 600 : 'normal'};
`;


interface Props {
}

const ClinicInvoiceView = (props: Props) => {

  const cancelTokenSource = axios.CancelToken.source();
  const { t } = useTranslation('teresa');

  const history = useHistory();
  const { state } = useLocation();
  const isIpad = useIsIpadWidthOrBelow();
  const { clinicInfo, setClinicId } = useClinicInfoContext();
  const clinicId: number | undefined = state?.clinicId ? Number(state?.clinicId) : undefined;

  const { currentUser } = useAuthContext();
  const isSystemAdmin = useMemo(() => currentUser
    && currentUser.roles.includes('ROLE_APP_ADMIN'), [currentUser]);
  const isClinicAdmin = useMemo(() => currentUser
    && currentUser.clinicAdmin, [currentUser]);

  const [defaultMandateBankId, setDefaultMandateBankId] = useState<number>();
  const [invoiceConfigPackage, setInvoiceConfigPackage] = useState<String>();
  const [clinicInvoiceTotals, setClinicInvoiceTotals] =
    useState<ClinicInvoiceTotalsDto | null>(null);

  const prevPath: string | undefined = state?.prevPath ? state.prevPath : undefined;

  const [foundClinicInvoices, setFoundClinicInvoices] = useState<ClinicInvoiceDto[]>([]);
  const [clinicInvoicesLoaded, setClinicInvoicesLoaded] = useState<boolean>(true);
  const [mandateBanks, setMandateBanks] = useState<Array<UpsertMandateBankDto>>([]);
  const [invoiceConfigs, setInvoiceConfig] = useState<InvoiceConfigDto[]>([]);
  const [selectedInvoice, setSelectedInvoice] = useState<ClinicInvoiceDto | null>(null);
  const [paymentModalOpen, setPaymentModalOpen] = React.useState<boolean>(false);
  let filteredInvoiceConfig = invoiceConfigs.filter(invoiceConfig => {
    return invoiceConfig.invoiceConfigType === invoiceConfigPackage;
  });

  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [paymentModalErrorMessages, setPaymentModalErrorMessages] = useState<string[]>([]);

  const [searchValues, setSearchValues] = useState<ClinicInvoiceSearchRequest>({
    clinicId: clinicId!,
    date: moment().format('YYYY-MM-DD'),
  });

  const invoicePackageTypes: Array<DropdownOption> = Object.values(InvoiceConfigType)
  .map((invoiceConfigType, index): DropdownOption => (
    {
      key: index,
      text: t(`invoiceConfigType.${invoiceConfigType}`),
      value: invoiceConfigType,
    }
  ));

  const mandateBanksDropdown: Array<DropdownOption> = Object.values(mandateBanks)
  .map((mandateBank, index): DropdownOption => (
    {
      key: index,
      text: mandateBank.description,
      value: mandateBank.id,
    }
  ));

  const numOfSetsDecorator = createDecorator({
    field: 'numOfSets',
    updates: {
      totalForInvoice: (value, allValues: any, prevValues): string | undefined => {

        if (!value) {
          return undefined;
        }

        const hasValidPackageInvoice =
          clinicInvoiceTotals && clinicInvoiceTotals.hasValidPackageInvoice;

        if (allValues!.invoiceConfigType && !hasValidPackageInvoice) {
          return allValues!.totalForInvoice;
        }

        const totalPrice = value * allValues!.testPrice;

        return totalPrice.toFixed(2);
      },
    },
  });

  React.useEffect(() => {
    fetchClinicInvoice(searchValues);
    fetchMandateBanks();
  }, []);

  const fetchMandateBanks = () => {
    let defaultMandateBank: number | undefined = undefined;
    getMandateBanksForClinicMandate(searchValues.clinicId, cancelTokenSource)
    .then((response) => {
      setMandateBanks(response);
      response.map(mandateBank => {
        if (mandateBank.defaultBank) {
          defaultMandateBank = mandateBank.id;
        }
      });
      if ((response.length > 0) && (defaultMandateBank == undefined)) {
        defaultMandateBank = 0;
      }
      setDefaultMandateBankId(defaultMandateBank);
    });
  };
  const handleError = (error: any, searchValues?: ClinicInvoiceDataSearchRequest, paymentModalErrors? : boolean) => {
    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.clinicInvoiceNotFound,
        errorUtils.userNotAdmin,
        errorUtils.invoiceConfigNotFoundForClinic,
        errorUtils.invoiceConfigNotFound,
        errorUtils.invalidInput,
        errorUtils.numOfSetsCantBeNull,
      ];

      const violations: Array<any> = error.violations;
      const errorMessages: Array<string> = [];

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (violation.errorCode === errorUtils.invoiceConfigNotFoundForClinic && searchValues) {
            errorMessages.push(
              t(
                `error.${violation.errorCode}`,
                {
                  invoiceConfigType: t(`invoiceConfigType.${searchValues.invoiceConfigType}`),
                  date: moment(searchValues.date).format('DD.MM.YYYY'),
                },
              ),
            );
          } else {
            errorMessages.push(t(`error.${violation.errorCode}`));
          }
        });
      } else {
        if (knownErrors.includes(errorCode)) {
          errorMessages.push(t(`error.${errorCode}`));
        } else {
          errorMessages.push(t('error.general'));
        }
      }

      paymentModalErrors
        ? setPaymentModalErrorMessages([errorMessages.join(', ')])
        : setErrorMessage(errorMessages.join(', '));
    }
  };

  const setErrorMessage = (errorMessage?: string) => {
    if (errorMessage) {
      const errMsgs = [errorMessage];
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const handleChange = async ({ values }: any, form: FormApi<any>, prevValues: any) => {

    const prevInvoiceConfigType = prevValues.invoiceConfigType;
    const currentInvoiceConfigType = values.invoiceConfigType;

    const prevInvoiceDate = prevValues.invoiceDate;
    const currentInvoiceDate = values.invoiceDate;

    if (prevInvoiceConfigType !== currentInvoiceConfigType || prevInvoiceDate !== currentInvoiceDate) {

      setErrorMessage();

      const emptyInvoiceConfigType: boolean = _.isEmpty(currentInvoiceConfigType);

      const searchRequest: ClinicInvoiceDataSearchRequest = {
        invoiceConfigType: currentInvoiceConfigType ? currentInvoiceConfigType : invoiceConfigPackage,
        date: values.invoiceDate,
      };

      if (searchRequest.invoiceConfigType != undefined) {

        try {
          const invoiceData =
            await getClinicInvoiceData(searchRequest, cancelTokenSource);

          const request = { ...searchValues, date: values.invoiceDate };
          const clinicInvoicesData =
            await searchClinicInvoiceData(request, cancelTokenSource);

          const hasValidPackageInvoice = clinicInvoicesData.clinicInvoiceTotals.hasValidPackageInvoice;
          let totalsResponse: ClinicInvoiceTotalsDto = { ...clinicInvoiceTotals! };
          totalsResponse.hasValidPackageInvoice = hasValidPackageInvoice;
          setClinicInvoiceTotals(totalsResponse);

          form.change('invoiceConfigId', invoiceData.invoiceConfigId);
          form.change('invoiceDate', invoiceData.invoiceDate);
          form.change('testPrice', invoiceData.testPrice);
          if (!hasValidPackageInvoice) {
            form.change('numOfSets', invoiceData.numOfSets);
            form.change('totalForInvoice', invoiceData.totalForInvoice?.toFixed(2));
          }

          if (emptyInvoiceConfigType) {

            const numOfSets = clinicInvoiceTotals && clinicInvoiceTotals.openSetsForInvoice > 0
              ? clinicInvoiceTotals.openSetsForInvoice
              : undefined;

            form.change('numOfSets', numOfSets);
            form.change('totalForInvoice', undefined);
            invoiceData.totalForInvoice = undefined;
            invoiceData.numOfSets = undefined;
          }
        } catch (e) {
          form.change('invoiceConfigId', undefined);
          form.change('numOfSets', undefined);
          form.change('testPrice', undefined);
          form.change('totalForInvoice', undefined);
          handleError(e.response.data, searchRequest);
        }
      }
    }
  };

  const handleSearch = (values: ClinicInvoiceSearchRequest) => {
    setSearchValues(values);
    fetchClinicInvoice(values);
  };

  const handleSubmit = (values: ClinicInvoiceRequestDto, form: FormApi<any, any>) => {

    let request: Partial<ClinicInvoiceRequestDto> = { ...values };
    if (clinicInvoiceTotals && clinicInvoiceTotals.hasValidPackageInvoice) {
      request.invoiceConfigType = undefined;
    }

    makeClinicInvoice(request, cancelTokenSource)
    .then(response => {
      fetchClinicInvoice(searchValues);
      if (!values.invoiceConfigType || (clinicInvoiceTotals && clinicInvoiceTotals.hasValidPackageInvoice)) {
        form.change('numOfSets', undefined);
      }
    })
    .catch(e => handleError(e.response.data));
  };

  const fetchClinicInvoice = (values: ClinicInvoiceSearchRequest): void => {

    setErrorMessage();
    setClinicId(values.clinicId);

    const onFinally = () => {
      setClinicInvoicesLoaded(true);
    };

    setClinicInvoicesLoaded(false);
    setFoundClinicInvoices([]);
    setInvoiceConfigPackage(undefined);

    getClinicConf(clinicId!, cancelTokenSource)
    .then(response => {
      setInvoiceConfigPackage(response.invoicePackageType);
    })
    .catch((e: any) => handleError(e.response.data));

    if (isSystemAdmin) {
      searchClinicInvoiceData(values, cancelTokenSource)
      .then(response => {
        setFoundClinicInvoices(response.clinicInvoiceDtos);
        setClinicInvoiceTotals(response.clinicInvoiceTotals);
      })
      .catch((e: any) => handleError(e.response.data))
      .finally(onFinally);
    } else {
      searchByClinicAdminForClinicInvoiceData(values, cancelTokenSource)
      .then(response => {
        setFoundClinicInvoices(response.clinicInvoiceDtos);
        setClinicInvoiceTotals(response.clinicInvoiceTotals);
      })
      .catch((e: any) => handleError(e.response.data))
      .finally(onFinally);
    }

    getInvoiceConfigs(cancelTokenSource)
    .then(response => {
      setInvoiceConfig(response);
    }).catch((e: any) => handleError(e.response.data))
    .finally(onFinally);
  };

  const clinicInvoiceStatusCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return t(`invoiceStatus.${cellData}`);
    }
    return emptyTableCell();
  };

  const testPriceCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return <div style={{ textAlign: 'right', paddingRight: '2.3rem' }}>{cellData}</div>;
    }
    return emptyTableCell();
  };

  const invoiceAmountCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return <div style={{ textAlign: 'right' }}>{cellData}</div>;
    }
    return emptyTableCell();
  };

  const invoiceDateCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return moment(cellData).format('DD.MM.YYYY');
    }
    return emptyTableCell();
  };

  const invoiceConfigTypeCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return t(`invoiceConfigType.${cellData}`);
    }
    return emptyTableCell();
  };

  const invoiceTypeCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return t(`invoiceType.${cellData}`);
    }
    return emptyTableCell();
  };


  const paymentDateCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return moment(cellData).format('DD.MM.YYYY');
    }
    return emptyTableCell();
  };

  const performDeleteClinicInvoice = async (id: number) => {
    try {
      const clinicInvoice = await deleteClinicInvoice(id, cancelTokenSource);
      fetchClinicInvoice(searchValues);
    } catch (e) {
      handleError(e.response.data);
    }
  };

  const performDownloadClinicInvoice = async (invoicePdfExternalId: number) => {
    if (invoicePdfExternalId) {
      try {
        const response = await downloadClinicInvoiceExportPdf(
          invoicePdfExternalId,
          cancelTokenSource,
        );
      } catch (e) {
        handleError(e.response.data);
      }
    }
  };

  const payInvoice = async (values: any) => {
    const clinicInvoicePaymentRequest = {
      paymentAmount: values.paymentAmount,
      paymentDate: values.paymentDate,
    };
    if (selectedInvoice?.id) {
      try {
        const response = await payClinicInvoice(selectedInvoice.id, clinicInvoicePaymentRequest, cancelTokenSource);
        setPaymentModalOpen(false);
        fetchClinicInvoice(searchValues);
      } catch (e) {
        handleError(e.response.data, undefined, true);
      }
    }
  };

  const actionCellRenderer = ({ cellData, rowData }: any) => {
    const payed: boolean = (rowData.paymentDate != null) && (rowData.paymentAmount != undefined);
    return <>
      {isSystemAdmin &&
        <InnerTableActionButton
          message={payed ? t("clinicInvoice.paid") : t('clinicInvoice.pay')}
          onConfirm={() => {
            setSelectedInvoice(rowData);
            setPaymentModalOpen(true);
          }}
          divider={true}
          disabled={payed}
        />
      }
      {(isSystemAdmin || isClinicAdmin) &&
        <InnerTableActionButton
          message={t('button.download')}
          onConfirm={() => performDownloadClinicInvoice(rowData.invoicePdfExternalId)}
          divider={isSystemAdmin || isClinicAdmin}
        />
      }
      <DeleteRecordConfirmation triggerButtonText={t('action.delete')}
                                confirmAction={() => performDeleteClinicInvoice(cellData)}
                                deleteConfirmationText={t('clinicInvoice.confirmDelete')}
      />
    </>;
  };

  const renderClinicInvoiceTable = (): JSX.Element => {

    const columns = [
      {
        width: 100,
        label: t('clinicInvoice.id'),
        dataKey: 'id',
      },
      {
        width: 250,
        flexGrow: 0,
        label: t('clinicInvoice.invoiceDate'),
        dataKey: 'invoiceDate',
        cellRenderer: (invoiceDateCellRenderer),
      },
      {
        width: 200,
        flexGrow: 1,
        label: t('clinicInvoice.invoiceConfigType'),
        dataKey: 'invoiceConfigType',
        cellRenderer: (invoiceConfigTypeCellRenderer),
      },
      {
        width: 200,
        flexGrow: 1,
        label: t('clinicInvoice.invoiceType'),
        dataKey: 'invoiceType',
        cellRenderer: (invoiceTypeCellRenderer),
      },
      {
        width: 100,
        flexGrow: 1,
        label: (t('clinicInvoice.numOfSets')),
        dataKey: 'numOfSets',
      },
      {
        width: 100,
        label: t('clinicInvoice.testPrice'),
        dataKey: 'testPrice',
        cellRenderer: (testPriceCellRenderer),
      },
      {
        width: 100,
        label: t('clinicInvoice.invoiceAmount'),
        dataKey: 'invoiceAmount',
        cellRenderer: (invoiceAmountCellRenderer),
      },
      {
        width: 250,
        flexGrow: 1,
        label: t('clinicInvoice.paymentDate'),
        dataKey: 'paymentDate',
        cellRenderer: (paymentDateCellRenderer),
      },
      {
        width: 150,
        flexGrow: 1,
        label: t('clinicInvoice.invoiceStatus'),
        dataKey: 'invoiceStatus',
        cellRenderer: (clinicInvoiceStatusCellRenderer),
      },
      {
        width: 250,
        flexGrow: 0,
        dataKey: 'id',
        label: t('domain.actions'),
        cellRenderer: (actionCellRenderer),
      },
    ];

    return (
      <VirtualizedTable
        rowCount={foundClinicInvoices.length}
        rowGetter={clinicInvoiceRowGetter}
        rowRenderer={clinicInvoiceRowRenderer}
        columns={columns}
      />
    );
  };

  const renderSearchClinicInvoiceForm = (): JSX.Element => {
    return (
      <FinalForm
        onSubmit={(values: ClinicInvoiceSearchRequest) => handleSearch(values)}
        initialValues={searchValues}
        subscription={{ pristine: true, submitting: true }}
        // create new invoice request when adding new invoice
        render={(formProps: FormRenderProps<any>) => renderSearchFormContent(formProps)}
      />
    );
  };

  const renderClinicInvoiceForm = (): JSX.Element => {
    return (
      <FinalForm
        onSubmit={(values: ClinicInvoiceRequestDto, formApi: FormApi<any, any>) => handleSubmit(values, formApi)}
        initialValues={{
          clinicId: clinicId!,
          invoiceConfigType: invoiceConfigPackage,
          invoiceDate: moment().format('YYYY-MM-DD'),
          mandateBankId: defaultMandateBankId,
        }}
        //@ts-ignore
        decorators={[numOfSetsDecorator]}
        subscription={{ pristine: true, values: true, submitting: true }}
        // create new invoice request when adding new invoice
        render={(formProps: FormRenderProps<any>) => renderClinicInvoiceFinalForm(formProps)}
      />
    );
  };

  const renderSearchFormContent = ({
                                     handleSubmit,
                                     submitting,
                                   }: FormRenderProps<any>): React.ReactNode => {

    const clinicName = clinicInfo ? `(${clinicInfo.name})` : '';

    return (
      <>
        <div className='search-container'>
          <div className='title-h1'>{t('clinicInvoice.header')} {clinicName}</div>
        </div>

        <form onSubmit={handleSubmit}>
          <TsaGrid stackable columns={3}>
            {
              errorMessages.length > 0 &&
              <Grid.Row>
                <Grid.Column width={16}>
                  <div className='error'>
                    <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                      {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                    </StyledErrorMessage>
                  </div>
                </Grid.Column>
              </Grid.Row>
            }

            <Grid.Row>
              <Grid.Column width={3} verticalAlign='bottom'>
                <DataLabel>{t('clinicInvoice.date')}</DataLabel>
                <Field
                  name='date'
                  component={DatePicker}
                  fluid
                />
              </Grid.Column>
              <Grid.Column width={2} verticalAlign='bottom'>
                <CompositeButton
                  primary
                  type='submit'
                  className='action-button'
                  disabled={submitting}
                  onClick={noop}
                >
                  {t('button.search')}
                </CompositeButton>
              </Grid.Column>
              {invoiceConfigPackage
                ? <Grid.Column width={11}>
                  <ClinicInvoiceDataContainer>
                    <ClinicInvoiceDataItem>
                      {t(`clinicInvoice.packageType`)}
                      <div style={{ fontWeight: 'bold' }}>
                        {t(`invoicePackageType.${invoiceConfigPackage}`)}
                      </div>
                    </ClinicInvoiceDataItem>
                    {clinicInvoiceTotals &&
                      <>
                        <ClinicInvoiceDataItem>
                          {t(`clinicDetails.validFromDate`)}
                          <div style={{ fontWeight: 'bold' }}>
                            {moment(clinicInvoiceTotals.validFrom).format('DD.MM.YYYY')}
                          </div>
                        </ClinicInvoiceDataItem>
                        <ClinicInvoiceDataItem>
                          {t(`clinicInvoice.validUntil`)}
                          <div style={{ fontWeight: 'bold' }}>
                            {moment(clinicInvoiceTotals.validUntil).format('DD.MM.YYYY')}
                          </div>
                        </ClinicInvoiceDataItem>
                        <ClinicInvoiceDataItem>
                          {t(`clinicInvoice.totalSetsInvoiced`)}
                          <div style={{ fontWeight: 'bold' }}>
                            {clinicInvoiceTotals.totalSetsInvoiced}
                          </div>
                        </ClinicInvoiceDataItem>
                        <ClinicInvoiceDataItem>
                          {t(`clinicInvoice.totalSetsMade`)}
                          <div style={{ fontWeight: 'bold' }}>
                            {clinicInvoiceTotals.totalSetsMade}
                          </div>
                        </ClinicInvoiceDataItem>
                        <ClinicInvoiceDataItem>
                          {t(`clinicInvoice.openSetsForInvoice`)}
                          <div style={{ fontWeight: 'bold' }}>
                            {
                              clinicInvoiceTotals.openSetsForInvoice > 0
                                ? clinicInvoiceTotals.openSetsForInvoice
                                : 0
                            }
                          </div>
                        </ClinicInvoiceDataItem>
                      </>
                    }
                  </ClinicInvoiceDataContainer>
                </Grid.Column>
                : clinicInvoicesLoaded &&
                <Grid.Column width={4} verticalAlign='bottom'>
                  <DataLabel style={{ marginBottom: 0 }}>
                    <StyledErrorMessage color='yellow'>
                      {t('clinicInvoice.warning')}
                    </StyledErrorMessage>
                  </DataLabel>
                </Grid.Column>
              }
            </Grid.Row>
          </TsaGrid>
        </form>
      </>
    );
  };

  const renderClinicInvoiceFinalForm = ({
                                          handleSubmit,
                                          submitting,
                                          values,
                                          form,
                                        }: FormRenderProps<any>): React.ReactNode => {

    const hasValidPackageInvoice = clinicInvoiceTotals && clinicInvoiceTotals.hasValidPackageInvoice;
    const disabled = values.invoiceConfigType && !hasValidPackageInvoice;

    const submitDisabled = submitting
      || !(values.invoiceDate && values.numOfSets && values.testPrice && values.totalForInvoice);

    return (
      <>
        {isSystemAdmin &&
          <form onSubmit={handleSubmit}>
            <div>
              <TsaGrid columns={7} doubling>
                <Grid.Row>
                  <GridColumn width={3}>
                    <DataLabel>{t('invoiceConfig.type')}</DataLabel>
                    <Field
                      name='invoiceConfigType'
                      component={Select}
                      options={invoicePackageTypes}
                      disabled={hasValidPackageInvoice}
                      clearable
                      fluid
                    />
                  </GridColumn>
                  <GridColumn width={2}>
                    <DataLabel>{t('clinicInvoice.invoiceDate')}</DataLabel>
                    <Field
                      name='invoiceDate'
                      component={DatePicker}
                      fluid
                    />
                  </GridColumn>
                  <GridColumn width={3}>
                    <DataLabel>{t('mandateBank.title')}</DataLabel>
                    <Field
                      name='mandateBankId'
                      component={Select}
                      options={mandateBanksDropdown}
                      fluid
                    />
                  </GridColumn>
                  <GridColumn width={2}>
                    <DataLabel>{t('clinicInvoice.numOfSets')}</DataLabel>
                    <Field
                      name='numOfSets'
                      component={Input}
                      numberInput={true}
                      fluid
                      disabled={disabled}
                      validate={composeValidators(required, mustBePositiveNumber, mustNotBeZero, mustBeAFullNumber)}
                    />
                  </GridColumn>
                  <GridColumn width={2}>
                    <DataLabel>{t('clinicInvoice.testPrice')}</DataLabel>
                    <Field
                      name='testPrice'
                      component={Input}
                      numberInput={true}
                      fluid
                      disabled={true}
                    />
                  </GridColumn>
                  <GridColumn width={2}>
                    <DataLabel>{t('clinicInvoice.totalForInvoice')}</DataLabel>
                    <Field
                      name='totalForInvoice'
                      component={Input}
                      numberInput={true}
                      fluid
                      disabled={true}
                    />
                  </GridColumn>
                  <Grid.Column width={2} textAlign={'right'}>
                    <DataLabel>&nbsp;</DataLabel>
                    <CompositeButton
                      primary
                      type='submit'
                      className='action-button'
                      onClick={noop}
                      disabled={submitDisabled}
                    >
                      {t('button.invoiceConfig')}
                    </CompositeButton>
                  </Grid.Column>
                </Grid.Row>
              </TsaGrid>
            </div>

            <div
              style={{
                width: '100%',
                height: '1px',
                backgroundColor: 'var(--very-light-blue)',
                marginTop: '1rem',
                marginBottom: '1rem',
              }}>
            </div>
            <FormSpy subscription={{ values: true }} onChange={(formState) => handleChange(formState, form, values)} />
          </form>
        }
      </>
    );
  };

  const clinicInvoiceRowGetter = ({ index }: any) => {

    Object.assign(foundClinicInvoices[index], { index: index + 1 });

    return foundClinicInvoices[index];
  };

  const clinicInvoiceRowRenderer = ({ className, columns, index, key, style }: any) => {
    const a11yProps = { 'aria-rowindex': index + 1 };

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role='row'
        style={style}
      >
        {columns}
      </div>
    );
  };

  const onSubmit = (values: ClinicInvoicePaymentRequestDto) => {
    payInvoice(values);
  };

  const onDismissError = () => {
    setPaymentModalErrorMessages([]);
  }

  const goBack = () => {
    history.push(prevPath ? prevPath : '/',{
      id: clinicId
    });
  }

  const renderFormsAndTable = () => {
    return (
      <>
        {renderSearchClinicInvoiceForm()}
        {renderClinicInvoiceForm()}
        <div className='data-table'>
          {clinicInvoicesLoaded && renderClinicInvoiceTable()}
          {!clinicInvoicesLoaded &&
            <Loader className='table-loader' active inline content={t('clinicInvoice.loading')} />}
        </div>

        <div
          style={{ width: '100%', height: '1px', backgroundColor: 'var(--very-light-blue)', marginTop: '1rem', marginBottom: '1rem' }}>
        </div>

        <div style={{ width: '100%', marginBottom: '1rem' }}>
          <CompositeButton
            type='button'
            className='action-button'
            secondary
            onClick={() => goBack()}
            floated='right'
          >
            {t('action.back')}
          </CompositeButton>
        </div>
      </>
    );
  };

  return (
    <SearchDisplayContainer>
      {renderFormsAndTable()}
      {isSystemAdmin && selectedInvoice && <PaymentModal
        onDismissError={onDismissError}
        errorMessages={paymentModalErrorMessages}
        open={paymentModalOpen}
        onClose={() => setPaymentModalOpen(false)}
        onSubmit={onSubmit}
        initialValues={{paymentDate: moment().format('YYYY-MM-DD'), paymentAmount: selectedInvoice.invoiceAmount}}
      />}
    </SearchDisplayContainer>
  );
};

export default ClinicInvoiceView;