import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import CheckBox from 'components/final-form/CheckBox';
import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import Input from 'components/final-form/Input';
import MultiLanguageInput from 'components/final-form/MultiLanguageInput';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import Select, { DropdownOption, mapToMultiLangDropdownOptionArray } from 'components/final-form/Select';
import FormModalContentContainer from 'components/FormModalContentContainer';
import InnerFormTable from 'components/InnerFormTable';
import InnerFormTableWithOrder from 'components/InnerFormTableWithOrder';
import InnerTableActionButton from 'components/InnerTableActionButton';
import InnerTsaGrid from 'components/InnerTsaGrid';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import { useTestResultFormDataContext } from 'context/TestResultFormDataContext';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import ScoringTestModal from 'routes/testresult/ScoringTestModal';
import TestResultAddParamsModal from 'routes/testresult/TestResultAddParamsModal';
import TrueScoreCopyModal from 'routes/testresult/TrueScoreCopyModal';
import { Container, Grid, Modal } from 'semantic-ui-react';
import { getDomains } from 'service/domainService';
import { getAvailableParamsForTestResult } from 'service/testResultParamServices';
import {
  addTestResult,
  calculateTestResultParamsStatistic,
  editTestResult,
  getTestResult,
} from 'service/testResultService';
import styled from 'styled-components';
import {
  CalculateTestStatisticRequest,
  DomainDto,
  TestResultParamData,
  TestResultParamDto,
  UpsertTestResultDto,
} from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { useAfterFirstRender } from 'util/functionUtils';
import { emptyTableCell, formatRawScoreNumbers } from 'util/tableUtils';
import { required } from 'util/validatorUtils';

const ActionContainer = styled.div`
  flex-grow: 1;
  border-radius: unset;
  padding-left: 5px;
  padding-top: 34px;

  .scoring-test {
    display: inline-block;
    white-space: nowrap;
  }
`;

const CheckBoxGridColumn = styled(Grid.Column)`
  margin-top: 31px !important;
`;

export interface ExtendedUpsertTestResultDto extends UpsertTestResultDto {
  activeStatus: number;
}

interface Props {
}

const cancelTokenSource = axios.CancelToken.source();

const UpsertTestResultForm = (props: Props) => {

  const { state } = useLocation();
  const testConfId: number | undefined = state?.testConfId ? Number(state?.testConfId) : undefined;
  const testResultId: number | undefined = state?.testResultId ? Number(state?.testResultId) : undefined;
  const scoreRulesType: string | undefined = state.scoreRulesType ? String(state?.scoreRulesType) : undefined;
  const orderIndex: number | undefined = state.orderIndex ? Number(state?.orderIndex) : undefined;
  const isCopy: boolean | undefined = state?.copy ? Boolean(state.copy) : undefined;

  const { language } = useAuthContext();
  const { t } = useTranslation('teresa');
  const history = useHistory();
  const {
    testResultFormData, setTestResultFormData,
    testConfDescriptions,
    deleteTrueScoreResult, deleteRawScoreResult,
    deleteTestResultParam, onDragAndDropTestResultParam,
  } = useTestResultFormDataContext();

  const initialTestResultValues: Partial<ExtendedUpsertTestResultDto> = {
    testConfId: testConfId,
    active: true,
    activeStatus: 1,
    testResultParams: [],
    rawScores: [],
    trueScores: [],
    orderIndex: orderIndex,
  };

  const [testResultParamMap, setTestResultParamMap] =
    useState<{ [key: number]: TestResultParamData }>([]);

  const [domains, setDomains] = useState<DomainDto[]>([]);
  const [domainOptions, setDomainOptions] = useState<Array<DropdownOption>>([]);

  const [testResultParams, setTestResultParams] = useState<TestResultParamDto[]>([]);
  const [avTestResultParamMap, setAvTestResultParamMap] = useState<{ [key: number]: TestResultParamDto }>([]);

  const [successMsg, setSuccessMsg] = React.useState<string | undefined>(undefined);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [copyModalOpen, setCopyModalOpen] = React.useState<boolean>(false);
  const [scoringTestModalOpen, setScoringTestModalOpen] = React.useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(true);

  useEffect(() => {
    fetchData(true);
    fetchDomains(true);
  }, []);

  useEffect(() => {
    const domainOptArray = mapToMultiLangDropdownOptionArray(domains, 'description', language);
    setDomainOptions(domainOptArray);
  }, [domains, language]);

  const fetchData = async (active: boolean) => {

    setFormDataLoaded(false);

    try {
      const domainsResponse = await getDomains(active, cancelTokenSource);
      setDomains(domainsResponse);

      const testResultParamResponse = await getAvailableParamsForTestResult(testConfId, cancelTokenSource);

      setTestResultParams(testResultParamResponse);
      setAvTestResultParamMap(_.keyBy(testResultParamResponse, 'id'));

      if (testResultFormData === undefined) {
        setTestResultFormData(initialTestResultValues);
      } else {
        if (testResultFormData.testResultParams) {
          let paramIdList = testResultFormData.testResultParams.map(trParam => trParam.testResultParamId);
          let filteredResultParamsDtos = testResultParamResponse.filter(r => paramIdList.includes(r.id));
          const calculateTestStatisticRequest: CalculateTestStatisticRequest = {
            testResultParamsDto: filteredResultParamsDtos,
            rawScoreResultsDto: testResultFormData.rawScores!,
          };

          let testResultParamsStatistic = await calculateTestResultParamsStatistic(
            calculateTestStatisticRequest, cancelTokenSource);

          testResultParamsStatistic.forEach((record) => {
            const formDataRecord = testResultFormData.testResultParams!.find(
              (formData) => formData.testResultParamId === record.testResultParamId,
            );

            if (formDataRecord) {
              formDataRecord.countInFormula = record.countInFormula;
              formDataRecord.deletable = record.deletable;
            }
          });
        }
      }

      if (testResultId && testConfId) {

        // @ts-ignore
        let upsertTestResultDto: Partial<ExtendedUpsertTestResultDto> = testResultFormData;

        if (testResultFormData === undefined) {
          const testResultResponse = await getTestResult(testResultId, cancelTokenSource);
          upsertTestResultDto = { ...testResultResponse, activeStatus: testResultResponse.active ? 1 : 0 };
          setTestResultFormData({
            ...upsertTestResultDto,
            orderIndex: orderIndex ? orderIndex : upsertTestResultDto.orderIndex,
          });
        }

        setTestResultParamMap(_.keyBy(upsertTestResultDto.testResultParams, 'testResultParamId'));

      }

    } catch (e) {
      handleError(e.response.data);
    } finally {
      setFormDataLoaded(true);
    }
  };

  const fetchDomains = (active: boolean): void => {
    try {
      getDomains(active, cancelTokenSource)
      .then(response => {
          setDomains(response);
        },
      );
    } catch (e) {
      handleError(e.response.data);
    }
  };

  const handleError = (error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.deletingNotAllowed,
        errorUtils.invalidInput,
        errorUtils.trueScoreResultNotFound,
        errorUtils.rawScoreResultNotFound,
        errorUtils.testResultNotFound,
        errorUtils.domainNotFound,
        errorUtils.missingParameters
      ];

      const violations: Array<any> = error.violations;
      const errMsgs: Array<string> = [];

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
           errMsgs.push(t(`error.${violation.errorCode}`, {details: violation.details}));
          }
        });
        setErrorMessages(errMsgs);

      } else {
        if (knownErrors.includes(errorCode)) {
          setErrorMessages([t(`error.${errorCode}`)]);
        } else {
          setErrorMessages([t('error.general')]);
        }
      }
    }
  };

  const goToPreviousPage = () => {
    setTestResultFormData(undefined);
    history.push('/testconf', { id: testConfId });
  };

  const handleSubmit = async (values: Partial<ExtendedUpsertTestResultDto>) => {

    let saveValues: Partial<ExtendedUpsertTestResultDto> = {
      ...values,
      testConfId: testConfId,
      domainId: values.domainId,
      active: !!values.activeStatus,
    };

    const onSave = () => {
      testResultId
        ? setSuccessMsg(t('testresult.onUpdate'))
        : setSuccessMsg(t('testresult.onAdd'));
      setTimeout(() => {
        history.push('/testconf', { id: testConfId });
        setTestResultFormData(undefined);
      }, 1200);
    };

    try {
      if (testResultId && !isCopy) {
        await editTestResult(testResultId, saveValues, cancelTokenSource);
      } else {
      if (values.rawScores) {
        values.rawScores.forEach((rawScore) => {
          rawScore.id = undefined;
        });
      }

      if (values.trueScores) {
        values.trueScores.forEach((trueScore) => {
          trueScore.id = undefined;
        });
      }

        await addTestResult(saveValues, cancelTokenSource);
      }
      onSave();
    } catch (e) {
      handleError(e.response.data);
    }
  };

  const handleFormValuesChange = useAfterFirstRender(({ values }: any): void => {
    setTestResultFormData(values);
  });

  const onAddRawScore = () => {
    history.push(
      `/testresult/rawscoreresult`,
      { testResultId: testResultId, testConfId: testConfId, scoreRulesType: scoreRulesType },
    );
  };

  const onEditRawScore = (rawScoreResultId: any, rawScoreResultIx: number) => {
    history.push(`/testresult/rawscoreresult`, {
      testResultId: testResultId,
      rawScoreResultId: rawScoreResultId,
      rawScoreResultIx: rawScoreResultIx,
      scoreRulesType: scoreRulesType,
      testConfId: testConfId,
    });
  };

  const onAddTrueScore = () => {
    history.push(
      `/testresult/truescoreresult`, {
        testResultId: testResultId,
        testConfId: testConfId,
        prevPath: '/testresult',
      });
  };

  const onEditTrueScore = (id: any, ix: number) => {
    history.push(`/testresult/truescoreresult`, {
      testResultId: testResultId,
      trueScoreResultId: id,
      trueScoreResultIx: ix,
      testConfId: testConfId,
      prevPath: '/testresult',
    });
  };

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={(values: Partial<UpsertTestResultDto>) => handleSubmit(values)}
        initialValues={testResultFormData}
        subscription={{ pristine: true, submitting: true, values: true }}
        render={(formProps: FormRenderProps<UpsertTestResultDto>) => renderFormContent(formProps)}
      />
    );
  };

  const actionRawScoreCellRenderer = (rowData: any, rowIndex: number) => {
    return (
      <>
        <InnerTableActionButton
          message={t('button.edit')}
          onConfirm={() => onEditRawScore(rowData.id, rowIndex)}
          divider={true}
        />
        <DeleteRecordConfirmation triggerButtonText={t('rawscoreresult.delete')}
                                  confirmAction={() => deleteRawScoreResult(rowIndex, avTestResultParamMap)}
                                  deleteConfirmationText={t('rawscoreresult.confirmDelete',
                                    { fromAge: rowData.fromAge, toAge:rowData.toAge })}
        />
      </>
    );

  };

  const deleteResultParamCellRenderer = (rowData: any) => {
    if (!avTestResultParamMap[rowData.testResultParamId]) {
      return '';
    }

    const testResultParam = avTestResultParamMap[rowData.testResultParamId];

    const paramDescMap: { [lang: string]: string } =
      {
        'de': testResultParam.description,
        'en': testResultParam.descriptionEn || testResultParam.description,
        'fr': testResultParam.descriptionFr || testResultParam.description,
        'it': testResultParam.descriptionIt || testResultParam.description,
      };

    return <DeleteRecordConfirmation triggerButtonText={t('testresult.delete')}
                                     confirmAction={() => deleteTestResultParam(rowData.testResultParamId)}
                                     deleteConfirmationText={t('testresultparam.confirmDelete', { description: paramDescMap[language] })}
                                     disabled={!rowData.deletable}
    />;
  };

  const descriptionParamCellRenderer = (rowData: any) => {

    if (!avTestResultParamMap[rowData.testResultParamId]) {
      return '';
    }

    const testResultParam = avTestResultParamMap[rowData.testResultParamId];

    const paramDescMap: { [lang: string]: string } =
      {
        'de': testResultParam.description,
        'en': testResultParam.descriptionEn || testResultParam.description,
        'fr': testResultParam.descriptionFr || testResultParam.description,
        'it': testResultParam.descriptionIt || testResultParam.description,
      };

    return <span title={paramDescMap[language]}>{paramDescMap[language]}</span>;
  };

  const unitParamCellRenderer = (rowData: any) => {

    if (!avTestResultParamMap[rowData.testResultParamId]) {
      return '';
    }

    const testResultParam = avTestResultParamMap[rowData.testResultParamId];

    return t(`unit.${testResultParam.unit}`);
  };


  const codeParamCellRenderer = (rowData: any) => {

    if (!avTestResultParamMap[rowData.testResultParamId]) {
      return '';
    }

    const testResultParam = avTestResultParamMap[rowData.testResultParamId];

    return testResultParam.code;
  };

  const numOfUsesParamCellRenderer = (rowData: any) => {
    return rowData.countInFormula;
  };

  const actionsTrueScoreCellRenderer = (rowData: any, rowIndex: number) => {

    return (
      <>
        <InnerTableActionButton
          message={t('button.edit')}
          disabled={scoreRulesType != undefined}
          onConfirm={() => onEditTrueScore(rowData.id, rowIndex)}
          divider={true}
        />
        <DeleteRecordConfirmation triggerButtonText={t('truescorerules.delete')}
                                  confirmAction={() => deleteTrueScoreResult(rowIndex)}
                                  deleteConfirmationText={t('truescoreresult.confirmDelete',
                                    { fromScoreRaw: rowData.fromScoreRaw, toScoreRaw: rowData.toScoreRaw })}
        />
      </>
    );
  };

  const fromToYearsCellRenderer = (rowData: any) => {
    if (rowData.ageRangeId) {
      return (
        <>
          {`${rowData.fromAge} - ${rowData.toAge}`}
        </>
      );
    }
    return '';
  };

  const fromToEducationCellRenderer = (rowData: any) => {
    if (rowData.educationalYearId) {
      return (
        <>
          {`${rowData.fromEducationalYear} - ${rowData.toEducationalYear}`}
        </>
      );
    }
    return '';
  };

  const genderCellRenderer = (rowData: any) => {
    if (rowData.sex == undefined) {
      return emptyTableCell();
    }
    return (
      <>
        {t(`sex.${rowData.sex}`)}
      </>
    );
  };

  const trueScoreCellRenderer = (rowData: any) => {
    return (
      <>
        {t(`truescoreresult.trueScore.${rowData.trueResult}`)}
      </>
    );
  };

  const fromScoreRawCellRenderer = (rowData: any) => {
    return (
      <>
        {formatRawScoreNumbers(rowData.fromScoreRaw)}
      </>
    );
  };

  const toScoreRawCellRenderer = (rowData: any) => {
    return (
      <>
        {formatRawScoreNumbers(rowData.toScoreRaw)}
      </>
    );
  };

  const scoreRulesTypeCellRenderer = (rowData: any) => {
    if (rowData.trueScoreResultType) {
      return (
        <>
          {t('trueScoreResultType.' + rowData.trueScoreResultType)}
        </>
      );
    }
    return emptyTableCell();
  };

  const testConfDescHeader = () => {
    if (testConfDescriptions && testConfDescriptions.description) {
      const testConfDescMap: { [lang: string]: string } =
        {
          'de': testConfDescriptions.description,
          'en': testConfDescriptions.descriptionEn || testConfDescriptions.description,
          'fr': testConfDescriptions.descriptionFr || testConfDescriptions.description,
          'it': testConfDescriptions.descriptionIt || testConfDescriptions.description,
        };

      return `(${testConfDescMap[language]})`;
    }

    return '';
  };

  const renderFormContent = (
    { handleSubmit, submitting, form }: FormRenderProps<UpsertTestResultDto>): React.ReactNode => {

    let disabledTrueScoreRules = false;
    if (scoreRulesType != undefined) {
      disabledTrueScoreRules = true;
    }

    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          <Grid.Row>
            <Grid.Column width={16}>
              <div className='title-h1'>
                {t('testresult.viewTitle')} <span className='addition'>{testConfDescHeader()}</span>
              </div>
            </Grid.Column>
          </Grid.Row>

          {errorMessages.length > 0 &&
            <Grid.Row>
              <Grid.Column width={16}>
                <div className='error'>
                  <StyledErrorMessage onDismiss={() => setErrorMessages([])}>
                    {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                  </StyledErrorMessage>
                </div>
              </Grid.Column>
            </Grid.Row>
          }

          <Grid.Row>
            <Grid.Column width={16}>
              <Grid stackable doubling columns={2}>
                <Grid.Column width={6}>
                  <InnerTsaGrid>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <DataLabel>{t('testconf.description')}</DataLabel>
                        <MultiLanguageInput fieldName='description' form={form} requiredFirst={true} />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={16}>
                        <DataLabel>{t('domain.viewTitle')}</DataLabel>
                        <Field
                          name='domainId'
                          component={Select}
                          options={domainOptions}
                          validate={required}
                          fluid
                        />
                      </Grid.Column>
                    </Grid.Row>
                    {renderTestResultParamsTable()}
                  </InnerTsaGrid>
                </Grid.Column>

                <Grid.Column width={10}>
                  <InnerTsaGrid>
                    <Grid.Row>
                      <Grid.Column width={14}>
                        <DataLabel>{t('rawscoreresult.rules')}</DataLabel>
                        <InnerFormTable id='raw-score'
                                        numOfCells={5}
                                        values={testResultFormData && testResultFormData.rawScores
                                          ? testResultFormData.rawScores
                                          : []
                                        }
                                        visibleRows={6}
                                        columns={[
                                          {
                                            width: 1,
                                            label: t('rawscoreresult.fromToYears'),
                                            dataKey: 'ageRangeId',
                                            cellRenderer: fromToYearsCellRenderer,
                                          },
                                          {
                                            width: 1,
                                            label: t('rawscoreresult.fromToEducation'),
                                            dataKey: 'ageRangeId',
                                            cellRenderer: fromToEducationCellRenderer,
                                          },
                                          {
                                            width: 1,
                                            label: t('rawscoreresult.gender'),
                                            dataKey: 'id',
                                            singleLine: true,
                                            cellRenderer: genderCellRenderer,
                                          },
                                          {
                                            width: 6,
                                            flexgrow: 1,
                                            label: t('rawscoreresult.formula'),
                                            dataKey: 'formula',
                                            oneLine: true,
                                          },
                                          {
                                            width: 3,
                                            label: t('rawscoreresult.actions'),
                                            dataKey: 'id',
                                            cellRenderer: actionRawScoreCellRenderer,
                                          },
                                        ]}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <ActionContainer>
                          <div className='row-actions'>
                            <InnerTableActionButton
                              message={t('button.add')}
                              onConfirm={onAddRawScore}
                            />
                          </div>
                        </ActionContainer>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={14}>
                        <DataLabel>{t('truescoreresult.rules')}</DataLabel>
                        <InnerFormTable id='true-score'
                                        numOfCells={4}
                                        values={testResultFormData && testResultFormData.trueScores
                                          ? testResultFormData.trueScores
                                          : []}
                                        visibleRows={6}
                                        columns={[
                                          {
                                            width: 1,
                                            label: t('truescoreresult.fromScoreRaw'),
                                            dataKey: 'fromScoreRaw',
                                            cellRenderer: fromScoreRawCellRenderer,
                                          },
                                          {
                                            width: 2,
                                            label: t('truescoreresult.toScoreRaw'),
                                            dataKey: 'toScoreRaw',
                                            cellRenderer: toScoreRawCellRenderer,
                                          },
                                          {
                                            width: 4,
                                            flexgrow: 1,
                                            label: t('truescoreresult.trueScores'),
                                            dataKey: 'id',
                                            cellRenderer: trueScoreCellRenderer,
                                          },
                                          {
                                            width: 3,
                                            label: t('truescoreresult.scoreRulesType'),
                                            dataKey: 'trueScoreResultType',
                                            cellRenderer: scoreRulesTypeCellRenderer,
                                          },
                                          {
                                            width: 3,
                                            label: t('truescoreresult.actions'),
                                            dataKey: 'id',
                                            cellRenderer: actionsTrueScoreCellRenderer,
                                          },
                                        ]}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <ActionContainer>
                          <>
                            <div>
                              <InnerTableActionButton
                                message={t('button.copy')}
                                onConfirm={() => showCopyTrueScoreModal()}
                                disabled={disabledTrueScoreRules}
                              />
                            </div>
                            <div>
                              <InnerTableActionButton
                                message={t('button.add')}
                                onConfirm={onAddTrueScore}
                                disabled={disabledTrueScoreRules}
                              />
                            </div>
                            <div>
                              <InnerTableActionButton
                                className='scoring-test'
                                message={t('testresult.scoringTest')}
                                onConfirm={() => showScoringTestModal()}
                              />
                            </div>
                          </>
                        </ActionContainer>
                      </Grid.Column>
                    </Grid.Row>
                  </InnerTsaGrid>
                </Grid.Column>

              </Grid>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <CheckBoxGridColumn width={2} verticalAlign='middle'>
              <Field
                name='activeStatus'
                component={CheckBox}
                label={t('domain.active')}
                toggle
              />
            </CheckBoxGridColumn>

            <CheckBoxGridColumn width={2} verticalAlign='middle'>
              <Field
                name='automaticTimer'
                component={CheckBox}
                label={t('testResult.automaticTimer')}
              />
            </CheckBoxGridColumn>

            <CheckBoxGridColumn width={2} verticalAlign='middle'>
              <Field
                name='hideTimer'
                component={CheckBox}
                label={t('testResult.hideTimer')}
              />
            </CheckBoxGridColumn>

            <CheckBoxGridColumn width={3} verticalAlign='middle'>
              <Field
                name='overrideNormValue'
                component={CheckBox}
                label={t('testResult.overrideNormValue')}
              />
            </CheckBoxGridColumn>

            <Grid.Column width={6}>
              <div style={{ float: 'right', marginRight: '16px' }}>
                <DataLabel>{t('testResult.orderIndex')}</DataLabel>
                <Field
                  name='orderIndex'
                  validate={required}
                  component={Input}
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16} style={{ borderTop: '1px solid var(--very-light-blue)', marginTop: '2rem' }}>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row textAlign='right'>
            <Grid.Column width={16}>
              {
                successMsg &&
                <SaveAndUpdateConfirmationMessage>
                  {successMsg}
                </SaveAndUpdateConfirmationMessage>
              }
              <CompositeButton
                primary
                type='submit'
                className='action-button'
                disabled={submitting || !!successMsg}
              >
                {t('button.save')}
              </CompositeButton>
              <CompositeButton
                type='button'
                className='action-button'
                secondary
                disabled={submitting || successMsg !== undefined}
                onClick={goToPreviousPage}
              >
                {t('action.back')}
              </CompositeButton>
            </Grid.Column>
          </Grid.Row>

        </TsaGrid>
        <FormSpy subscription={{ values: true }} onChange={handleFormValuesChange} />
      </form>
    );
  };

  const renderTestResultParamsTable = () => {
    return (
      <>
        <Grid.Row>
          <Grid.Column width={15}>
            <DataLabel>{t('testresult.params')}</DataLabel>
            <InnerFormTableWithOrder id='test-result'
                                     numOfCells={5}
                                     values={testResultFormData && testResultFormData.testResultParams
                                       ? testResultFormData.testResultParams
                                       : []
                                     }
                                     visibleRows={8}
                                     dragAndDropFn={onDragAndDropTestResultParam}
                                     columns={[
                                       {
                                         width: 2,
                                         label: '#',
                                         dataKey: 'orderIndex',
                                       },
                                       {
                                         width: 5,
                                         flexgrow: 1,
                                         oneLine: true,
                                         label: t('truescoreresult.description'),
                                         dataKey: 'testResultParamId',
                                         cellRenderer: descriptionParamCellRenderer,
                                       },
                                       {
                                         width: 3,
                                         label: t('testresultparam.unit'),
                                         dataKey: 'testResultParamId',
                                         cellRenderer: unitParamCellRenderer,
                                       },
                                       {
                                         width: 3,
                                         label: t('truescoreresult.code'),
                                         dataKey: 'testResultParamId',
                                         cellRenderer: codeParamCellRenderer,
                                       },
                                       {
                                         width: 1,
                                         label: t('truescoreresult.numberOfUses'),
                                         shortLabel: t('truescoreresult.numberOfUsesShort'),
                                         dataKey: 'testResultParamId',
                                         cellRenderer: numOfUsesParamCellRenderer,
                                       },
                                       {
                                         width: 3,
                                         label: t('testresult.delete'),
                                         dataKey: 'testResultParamId',
                                         cellRenderer: deleteResultParamCellRenderer,
                                       },
                                     ]}
            />
          </Grid.Column>
          <Grid.Column>
            <ActionContainer>
              <InnerTableActionButton
                message={t('button.add')}
                onConfirm={() => showModal()}
              />
            </ActionContainer>
          </Grid.Column>
        </Grid.Row>
      </>
    );
  };

  const showModal = () => {
    setModalOpen(true);
  };

  const hideModal = () => {
    setModalOpen(false);
  };

  const renderContactModal = (): React.ReactNode => {

    return (
      <Modal style={{ position: 'sticky' }}
             open={modalOpen} closeOnDimmerClick={true} onClose={() => hideModal()}>
        <Modal.Header>{t('testresult.testResultParams')}</Modal.Header>
        <FormModalContentContainer>
          <TestResultAddParamsModal
            testConfId={testConfId}
            testResultParams={testResultParams}
            onCancel={hideModal}
          />
        </FormModalContentContainer>
      </Modal>
    );
  };

  const showScoringTestModal = () => {
    setScoringTestModalOpen(true);
  };

  const hideScoringTestModal = () => {
    setScoringTestModalOpen(false);
  };

  const renderScoringTestModal = (): React.ReactNode => {

    return (
      <Modal style={{ position: 'sticky' }}
             open={scoringTestModalOpen}
             closeOnDimmerClick={true}
             onClose={() => hideScoringTestModal()}
             size='large'
      >
        <Modal.Header>{t('testresult.scoringTest')}</Modal.Header>
        <FormModalContentContainer>
          <ScoringTestModal
            testResultId={testResultId}
            testConfId={testConfId}
            onCancel={hideScoringTestModal}
          />
        </FormModalContentContainer>
      </Modal>
    );
  };

  const showCopyTrueScoreModal = () => {
    setCopyModalOpen(true);
  };

  const hideCopyTruesScoreModal = () => {
    setCopyModalOpen(false);
  };

  const renderCopyTrueScoreModal = (): React.ReactNode => {

    return (
      <Modal style={{ position: 'sticky' }}
             open={copyModalOpen} closeOnDimmerClick={true} onClose={() => hideCopyTruesScoreModal()}>
        <Modal.Header>{t('testResult.copyTrueScores')}</Modal.Header>
        <FormModalContentContainer>
          <TrueScoreCopyModal
            onCancel={hideCopyTruesScoreModal}
          />
        </FormModalContentContainer>
      </Modal>
    );
  };

  return (
    <div>
      <Container fluid>
        <UpsertContentWrapperDiv>
          {formDataLoaded
            ? <>
              {renderFinalForm()}
              {renderContactModal()}
              {renderScoringTestModal()}
              {renderCopyTrueScoreModal()}
            </>
            : <LoaderComponent message={t('testresult.loading')} />
          }
        </UpsertContentWrapperDiv>
      </Container>
    </div>
  );

};


export default UpsertTestResultForm;