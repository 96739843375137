import axios from 'axios';
import DataLabel from 'components/final-form/DataLabel';
import Input from 'components/final-form/Input';
import Select, { stringArrayToDropdownOptionArray } from 'components/final-form/Select';
import CompositeButton from 'components/final-form/CompositeButton';
import FormulaPopupContent from 'components/FormulaPopupContent';
import { defaultPopupStyle } from 'components/HeaderButton';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TableContainer from 'components/TableContainerDiv';
import TsaGrid from 'components/TsaGrid';
import { useTestResultFormDataContext } from 'context/TestResultFormDataContext';
import { FormApi } from 'final-form';
import createDecorator from 'final-form-calculate';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Popup, Table } from 'semantic-ui-react';
import { findAllAvailableParameters } from 'service/testResultParamServices';
import { calculateFormulaTest, calculateScoringTest } from 'service/trueScoreResultService';
import styled from 'styled-components';
import { UnitType } from 'ts-types/api.enums';
import {
  AvailableParamsRequest,
  FormulaTestDto,
  ScoringTestDto,
  ScoringTestParamsDto,
  ScoringTestResponse,
  TestResultParamDto,
} from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { noop } from 'util/functionUtils';
import {
  composeValidators,
  isValueValidNumber,
  mustBeNumber,
  mustBePositiveNumber,
  required,
} from 'util/validatorUtils';

const ScoringTestGrid = styled(TsaGrid)`
  & .scoring-test-container {
    max-height: 800px;
  }
`;

const StyledResultDiv = styled.div`
  font-weight: 700;
  white-space: nowrap;
`;

const StyledInputDiv = styled.div`
  input {
    text-align: right !important;
  }
`;

const StyledFormulaDiv = styled.div`
  white-space: nowrap;
`;

const ActionContainer = styled.div`
  flex-grow: 1;
  margin-top: 1rem;
  border-radius: unset;

  .ui.primary.button, .ui.primary.buttons .button {
    background-color: var(--primary-color);
    
    :hover {
      background-color: transparent;
      border-color: var(--primary-color);
      color: var(--primary-color);
    }

    :focus {
      background-color: transparent;
      border-color: var(--primary-color);
      color: var(--primary-color);
    }
  }

  .ui.secondary.button, .ui.secondary.buttons .button {
    background-color: var(--secondary-color);
    
    :hover {
      background-color: transparent;
      border-color: var(--secondary-color);
      color: var(--secondary-color);
    }

    :focus {
      background-color: transparent;
      border-color: var(--secondary-color);
      color: var(--secondary-color);
    }
  }
`;

interface Props {
  testResultId?: number,
  testConfId?: number;
  onCancel: () => void;
}

const cancelTokenSource = axios.CancelToken.source();
const windowWidth = window.innerWidth;

const ScoringTestModal = (props: Props) => {

  const { t } = useTranslation('teresa');
  const { testResultFormData } = useTestResultFormDataContext();

  const { onCancel, testResultId, testConfId } = props;

  const genderLabels: Array<string> = ['male', 'female', 'notAvailable'];
  const genderKeys: Array<number> = [0, 1, 2];
  const genders = useMemo(() =>
    stringArrayToDropdownOptionArray(genderLabels, t, 'gender', genderKeys), []);

  const [scoringTestResponse, setScoringTestResponse] = useState<ScoringTestResponse | undefined>(undefined);
  const [availableParameters, setAvailableParameters] = useState<TestResultParamDto[]>([]);
  const [initialFormValues, setInitialFormValues] = useState<Object | undefined>(undefined);
  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(true);
  const [formulaTest, setFormulaTest] = React.useState<number>();
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    if (testConfId && testResultFormData) {

      setFormDataLoaded(false);

      const testResultTestResultParamIds = testResultFormData.testResultParams!.map(param => param.testResultParamId);
      const request: AvailableParamsRequest = { testConfId, testResultTestResultParamIds, includeSystemParams: false };

      const methods: Array<Promise<any>> = [
        findAllAvailableParameters(request, cancelTokenSource),
      ];

      Promise.all(methods)
      .then(responses => {
        setAvailableParameters(responses[0]);
        const params = [...responses[0]];
        setInitialFormValues({ params: _.fill(params, undefined) });
      })
      .catch((e: any) => handleError(e.response.data))
      .finally(() => setFormDataLoaded(true));
    }
  }, []);

  const handleError = (error: any) => {

    if (error) {
      let errorMsgs = [];
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.noRawScoreFound,
        errorUtils.multipleRawScoresNotAllowed,
        errorUtils.trueScoreResultNotFound,
        errorUtils.invalidFormula,
        errorUtils.missingParameters,
        errorUtils.emptyFormula,
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errorMsgs.push(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMsgs.length) {
        if (knownErrors.includes(errorCode)) {
          errorMsgs.push(t(`error.${errorCode}`));
        } else {
          errorMsgs.push(t('error.general'));
        }
      }

      setErrorMessages(errorMsgs);
    }
  };

  const calculateScore = (form: FormApi<any>) => {

    const values = form.getState().values;
    setInitialFormValues(values);

    let error = false;
    let params: ScoringTestParamsDto[] = [];
    let param: ScoringTestParamsDto;

    if (availableParameters && availableParameters.length > 0) {
      availableParameters.forEach((p, index) => {

        if (!values.params || (!values.params[index] && availableParameters[index].unit !== UnitType.FORMULA) || values.params[index] === '') {
          error = true;
        } else {
          param = {
            code: p.code,
            value: values.params[index],
            unitType: p.unit
          };
        }

        params.push(param);
      });
    }

    if (error) {
      setErrorMessages([t(`error.${errorUtils.missingParameters}`)]);
    }

    if (testResultId && !error) {
      let scoringTest: Partial<ScoringTestDto> = {
        testResultId: testResultId,
        age: values.age,
        schoolYears: values.schoolYears,
        sex: values.sex,
        testDuration: values.testDuration,
        params: params,
      };

      if (scoringTest) {
        calculateScoringTest(scoringTest, cancelTokenSource)
        .then(response => {
          if (response.error && !response.trueResult) {
            setErrorMessages([response.error]);
          } else {
            setScoringTestResponse(response);
          }
        })
        .catch((e: any) => {
            handleError(e.response.data);
          },
        );
      }
    }
  };

  const formulaRenderer = () => {
    if (scoringTestResponse) {
      const formula = scoringTestResponse.formula;
      let formulaCharacters = 50;

      if (windowWidth < 1285) {
        formulaCharacters = 40;
      }

      if (formula.length > formulaCharacters) {
        return (
          <Popup content={<FormulaPopupContent>
            {formula}
          </FormulaPopupContent>}
                 on='hover'
                 trigger={
                   <div>
                     {`${formula.substring(0, formulaCharacters)}...`}
                   </div>
                 }
                 popperModifiers={[{ name: 'preventOverflow', options: { boundary: 'window' } }]}
                 size='small'
                 position='top center'
                 style={defaultPopupStyle}
                 hoverable
          />
        );
      }

      return (
        <StyledFormulaDiv>
          {formula}
        </StyledFormulaDiv>);
    }
  };

  const formulaCalculation = createDecorator({
    field: new RegExp('^params\\[.*]\$'),
    updates: (value, name, allValues?: any) => {
      let paramsForCalc: ScoringTestParamsDto[] = [];
      let sumPresent = false;
      availableParameters.map(ap => {
        if(ap.unit === UnitType.SUM){
          sumPresent = true;
        }
      })
      let remainingParams = availableParameters.slice(0, availableParameters.length - (sumPresent ? 2 : 1));
      const formula = availableParameters[availableParameters.length - (sumPresent ? 2 : 1)]?.formula ?? "";
      let index = 0;
      if (formula != undefined) {
        remainingParams.forEach(param => {
          const paramCal: ScoringTestParamsDto = {
            code: param.code,
            value: allValues.params[index],
            unitType: param.unit
          };
          paramsForCalc.push(paramCal);
          index++;
        });
        let calculationRequest: FormulaTestDto = {
          formula: formula,
          scoringParams: paramsForCalc,
        };

        calculateFormulaTest(calculationRequest)
        .then(score => {setFormulaTest(score);})
        .catch(noop);
        if(formulaTest != undefined && formulaTest){
          allValues.params[availableParameters.length - (sumPresent ? 2 : 1)] = formulaTest.toFixed(2);
        }
        return allValues;
      }
    },
  });

  const sumTestResultParam = createDecorator({
    field: new RegExp('^params\\[.*]\$'),
    updates: (value, name, allValues?: any) => {
      if (allValues
        && availableParameters.length
        && UnitType.SUM === availableParameters[availableParameters.length - 1].unit) {
        const sumParamIx = availableParameters.length - 1;
        const regex = 'params\\[([0-9]+)\\]';
        let values = { ...allValues };
        if (name.match(regex) !== null) {
          // @ts-ignore
          const paramIx: number = name.match(regex)[1];
          const param = availableParameters[paramIx];
          if (param && UnitType.SUM !== param.unit) {
            if (paramIx >= 0) {
              values.params[sumParamIx] = 0;

              const sumValue = _.chain(values.params)
              .filter(paramScore => (paramScore !== null
                  && paramScore !== undefined
                  && isValueValidNumber(paramScore))
                || (availableParameters[paramIx] && UnitType.SUM === availableParameters[paramIx].unit))
              .map(paramScore => parseFloat(paramScore.toString()))
              .sum()
              .value();

              _.set(
                values,
                `params[${sumParamIx}]`,
                sumValue === 0 ? '' : sumValue,
              );
            }
          }
        }

        return {
          ...values,
        };
      }
      return {};
    },
  });

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={noop}
        subscription={{ pristine: true, values: true }}
        initialValues={initialFormValues}
        decorators={[sumTestResultParam,formulaCalculation]}
        render={(formProps: FormRenderProps<any>) => renderForm(formProps)}
      />
    );
  };

  const renderForm = ({ handleSubmit, form }: FormRenderProps<any>) => {

    return (
      <form onSubmit={handleSubmit}>
        <ScoringTestGrid>
          {errorMessages.length > 0 &&
            <Grid.Row>
              <Grid.Column width={16}>
                <div className='error'>
                  <StyledErrorMessage onDismiss={() => setErrorMessages([])}>
                    {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                  </StyledErrorMessage>
                </div>
              </Grid.Column>
            </Grid.Row>
          }

          <Grid.Row>
            <Grid.Column width={3}>
              <DataLabel>{t('testresult.age')}</DataLabel>
              <StyledInputDiv>
                <Field
                  id='age'
                  name='age'
                  component={Input}
                  validate={composeValidators(required, mustBePositiveNumber)}
                  autoFocus
                  fluid
                />
              </StyledInputDiv>

            </Grid.Column>
            <Grid.Column width={3}>
              <DataLabel>{t('testresult.sex')}</DataLabel>
              <Field
                id='sex'
                name='sex'
                placeholder={t('testresult.sex')}
                component={Select}
                options={genders}
                validate={required}
                fluid
              />
            </Grid.Column>
            <Grid.Column width={6} verticalAlign={'top'}>
              <DataLabel>{t('rawscoreresult.formula')}</DataLabel>
              <StyledFormulaDiv>
                {/*{scoringTestResponse &&*/}
                {/*  scoringTestResponse.formula*/}
                {/*}*/}
                {formulaRenderer()}
              </StyledFormulaDiv>
            </Grid.Column>
            <Grid.Column width={4} floated={'right'} verticalAlign={'top'}>
              <DataLabel>{t('testresult.result')}</DataLabel>
              <StyledResultDiv>
                {scoringTestResponse &&
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <pre style={{margin: 0}}>{scoringTestResponse.rawValue}  /</pre>
                    <pre style={{margin: 0}}>&nbsp;{Number(scoringTestResponse.result).toFixed(2)}   /</pre>
                    <pre style={{margin: 0}}>&nbsp;{t(`truescoreresult.trueScore.${scoringTestResponse.trueResult}`)}</pre>
                  </div>
                }
              </StyledResultDiv>
            </Grid.Column>

          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={3}>
              <DataLabel>{t('testresult.schoolYears')}</DataLabel>
              <StyledInputDiv>
                <Field
                  id='schoolYears'
                  name='schoolYears'
                  component={Input}
                  validate={composeValidators(mustBePositiveNumber)}
                  fluid
                />
              </StyledInputDiv>
            </Grid.Column>
            <Grid.Column width={3}>
              <DataLabel>{t('testresult.testDuration')}</DataLabel>
              <StyledInputDiv>
                <Field
                  id='testDuration'
                  name='testDuration'
                  component={Input}
                  validate={composeValidators(mustBePositiveNumber)}
                  fluid
                />
              </StyledInputDiv>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16} verticalAlign='middle' className='no-padding'>
              <TableContainer className='scoring-test-container' height={500}>
                <Table basic='very' striped size='small'>
                  <Table.Body className='table-body'>
                    {!formDataLoaded
                      ? <LoaderComponent message={t('testresult.testresultparams.loading')} />
                      : availableParameters.map((testResultParam, index) => {

                        const disabled = testResultParam.unit == UnitType.FORMULA;
                        const title = disabled ? testResultParam.formula : undefined;

                        return <Table.Row key={testResultParam.id}>
                          <Table.Cell width={8} title={title}>
                            {testResultParam.description}
                          </Table.Cell>
                          <Table.Cell width={3} title={title}>
                            {testResultParam.code}
                          </Table.Cell>
                          <Table.Cell width={3}>
                            <StyledInputDiv>
                              <Field
                                name={`params[${index}]`}
                                component={Input}
                                disabled={disabled}
                                title={title}
                                validate={composeValidators(required, mustBeNumber)}
                                fluid
                              />
                            </StyledInputDiv>
                          </Table.Cell>
                          <Table.Cell width={2} title={title}>
                            {testResultParam.unit}
                          </Table.Cell>
                        </Table.Row>;
                      })}
                  </Table.Body>
                </Table>
              </TableContainer>
            </Grid.Column>
            <Grid.Column width={16}>
              <ActionContainer>
                <CompositeButton
                  type='button'
                  className='action-button'
                  size='medium'
                  style={{ whiteSpace: 'nowrap' }}
                  secondary
                  floated='right'
                  onClick={onCancel}
                >
                  {t('button.close')}
                </CompositeButton>
                <CompositeButton
                  type='button'
                  className='action-button'
                  size='medium'
                  style={{ display: 'inline-block', marginRight: '2rem' }}
                  primary
                  floated='right'
                  onClick={() => calculateScore(form)}
                >
                  {t('testresult.calculate')}
                </CompositeButton>

              </ActionContainer>
            </Grid.Column>
          </Grid.Row>
        </ScoringTestGrid>
      </form>
    );
  };

  return <>{renderFinalForm()}</>;
};

export default ScoringTestModal;