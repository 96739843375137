import TableContainer from 'components/TableContainerDiv';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import React, { useLayoutEffect, useMemo, useState } from 'react';
import { Table } from 'semantic-ui-react';
import { TableCellProps } from 'semantic-ui-react/dist/commonjs/collections/Table/TableCell';
import { emptyTableRows } from 'util/tableUtils';

export interface InnerFormTableCellProps extends TableCellProps {
  dataKey: string,
  label?: string | React.ReactNode,
  shortLabel?: string,
  oneLine?: boolean,
  cellRenderer?: (rowData: any, index: number) => React.ReactNode
}

interface InnerFormTableProps {
  id: string,
  values: any[],
  columns: InnerFormTableCellProps[],
  visibleRows?: number,
  numOfCells: number
}

const InnerFormTable = ({ id, numOfCells, values = [], columns = [], visibleRows = 4 }: InnerFormTableProps) => {

  const [headerLabels, setHeaderLabels] = useState<React.ReactNode[]>([]);
  const [scrollable, setScrollable] = useState<boolean>(false);
  const isIpad = useIsIpadWidthOrBelow();

  useMemo(() => {
    let hl: React.ReactNode[] = [];
    columns.forEach((c: any) => {
      if (c.label) {
        hl.push(
          <Table.HeaderCell key={c.label} width={c.width} style={c.shortLabel ? {cursor: 'pointer'} : {cursor: 'auto'}}>
            {typeof c.label === 'string'
              ? <span title={c.label}>
            {c.shortLabel ? c.shortLabel : c.label}
             </span>
              : c.label
            }
          </Table.HeaderCell>,
        );
      }
    });
    setHeaderLabels(hl);
  }, [columns]);

  const isScrollable = (element: any) => {

    const hasScrollableContent = element.scrollHeight > element.clientHeight;

    const overflowYStyle = window.getComputedStyle(element).overflowY;
    const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;

    return hasScrollableContent && !isOverflowHidden;
  };

  useLayoutEffect(() => {
    let element = document.getElementById(id);
    setScrollable(isScrollable(element));
  }, [values]);

  const countValues = useMemo(() => {

    if (values) {
      return values.length;
    }

    return 0;
  }, [values]);

  const calculateHeight = useMemo(() => {
    return visibleRows > countValues
      ? countValues > 3
        ? countValues * 36
        : 108
      : visibleRows * 36;
  }, [values]);

  return (
    <TableContainer height={calculateHeight}>
      <Table basic='very' size='small'>
        {headerLabels.length ?
          <Table.Header style={scrollable ? { paddingRight: '10px' } : {}}>
            <Table.Row>
              {headerLabels}
            </Table.Row>
          </Table.Header>
          : ''
        }

        <Table.Body id={id} className='table-body'>
          {values && values.map((val: any, index: number) => {
            return <Table.Row key={index}>
              {columns.map(({ dataKey, cellRenderer, oneLine, ...other }: InnerFormTableCellProps, ix: number) =>
                <Table.Cell key={ix} {...other} singleLine={oneLine}
                            className={oneLine ? 'single-line-cell-class' : ''}>
                  {cellRenderer ? cellRenderer(val, index) :
                    <span title={val[`${dataKey}`]}>
                      {val[`${dataKey}`]}
                    </span>
                  }
                </Table.Cell>,
              )}
            </Table.Row>;
          })}
          {emptyTableRows(values.length, numOfCells)}
        </Table.Body>
      </Table>
    </TableContainer>
  );
};

export default InnerFormTable;
