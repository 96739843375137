import { useAuthContext } from 'auth/AuthContext';
import CheckBox from 'components/final-form/CheckBox';
import DataLabel from 'components/final-form/DataLabel';
import MultiLanguageInput from 'components/final-form/MultiLanguageInput';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import CompositeButton from 'components/final-form/CompositeButton';
import LoaderComponent from 'components/LoaderComponent';
import TsaGrid from 'components/TsaGrid';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import TemplateExerciseConfSegment from 'routes/exercise-template/TemplateExerciseConfSegment';
import { Container, Grid } from 'semantic-ui-react';
import { getExerciseConfs } from 'service/exerciseService';
import {
  addExerciseTemplate,
  editExerciseTemplate,
  getExerciseInTemplates,
  getExerciseTemplate,
} from 'service/exerciseTemplateServices';
import axios from 'service/http';
import styled from 'styled-components';
import { ExerciseConfDto, UpsertExerciseTemplateDto } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';

const StyledLeftDataDiv = styled.div`
  font-weight: 600;
  min-height: 35px;
  line-height: 35px;
  background-color: var(--very-light-gray);
  border-radius: 4px;
`;

const StyledRightDataDiv = styled.div`
  font-weight: 600;
  min-height: 35px;
  line-height: 35px;
  background-color: var(--very-light-gray);
  border-radius: 4px;
`;

const ContentWrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  padding-bottom: 2rem;

  .page-header {
    margin-bottom: 2rem;
  }

  .ui.button + .ui.button {
    margin-left: 1rem;
  }
`;

interface Props {
}

interface Props {
}

const UpsertExerciseTemplateForm = (props: Props) => {

  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);

  const { t } = useTranslation('teresa');
  const { state } = useLocation();
  const { currentUser } = useAuthContext();
  const history = useHistory();

  const exerciseTemplateId: number | undefined = state?.exerciseTemplateId
    ? Number(state.exerciseTemplateId)
    : undefined;

  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(true);
  const [exerciseTemplate, setExerciseTemplate] = React.useState<UpsertExerciseTemplateDto | undefined>(undefined);
  const [exerciseConfs, setExerciseConfs] = React.useState<ExerciseConfDto[]>([]);
  const [markedExerciseConf, setMarkedExerciseConf] = React.useState<ExerciseConfDto | undefined>(undefined);
  const [exerciseInTemplatesList, setExerciseInTemplatesList] = React.useState<string[]>([]);
  const [selectedExerciseConfList, setSelectedExerciseConfList] = React.useState<ExerciseConfDto[]>([]);
  const [availableExerciseConfList, setAvailableExerciseConfList] = React.useState<ExerciseConfDto[]>([]);
  const [successMsg, setSuccessMsg] = React.useState<string | undefined>(undefined);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const goToPreviousPage = () => {
    exerciseTemplateId ? history.goBack() : history.push('/rehabilitation-exercise-template/search');
  };

  useEffect(() => {
    fetchRequiredData();
  }, []);

  const fetchRequiredData = async () => {

    setFormDataLoaded(false);

    try {

      const exerciseConfsResponse = await getExerciseConfs(cancelTokenSource);
      setExerciseConfs(exerciseConfsResponse);

      if (exerciseTemplateId) {
        const exerciseTemplate = await getExerciseTemplate(exerciseTemplateId, cancelTokenSource);
        setExerciseTemplate(exerciseTemplate);
        if (exerciseTemplate) {
          const exerciseConfigIds = exerciseTemplate.exerciseConfList.map(t => t.id);
          let newList = exerciseTemplate.exerciseConfList;
          newList = _.orderBy(newList, ['orderIndex'], ['asc']);
          setSelectedExerciseConfList(newList);
          setAvailableExerciseConfList(exerciseConfsResponse.filter(tc => !exerciseConfigIds.includes(tc.id)));
        }
      } else {
        setSelectedExerciseConfList([]);
        setAvailableExerciseConfList(exerciseConfsResponse);
      }
    } catch (e) {
      handleError(e.response.data);
    } finally {
      setFormDataLoaded(true);
    }
  };

  const setData = (selectedExerciseConfs: Array<ExerciseConfDto>, availableExerciseConfs: Array<ExerciseConfDto>) => {
    setSelectedExerciseConfList(selectedExerciseConfs);
    setAvailableExerciseConfList(availableExerciseConfs);
  };

  const setSelectedExerciseInTemplates = async (exercise: ExerciseConfDto) => {
    setMarkedExerciseConf(exercise);
    try {
      const list = await getExerciseInTemplates(exercise.id, cancelTokenSource);
      let exerciseInTemplatesStringList: string[] = [];
      list.forEach(template => {
        let description = template.description;
        if (currentUser && currentUser.language) {
          if (currentUser.language === 'de') {
            description = template.description;
          } else if (currentUser.language === 'en' && template.descriptionEn) {
            description = template.descriptionEn;
          } else if (currentUser.language === 'fr' && template.descriptionFr) {
            description = template.descriptionFr;
          } else if (currentUser.language === 'it' && template.descriptionIt) {
            description = template.descriptionIt;
          }
        }
        exerciseInTemplatesStringList.push(list.indexOf(template) === 0 ? description : ', ' + description);
      });
      setExerciseInTemplatesList(exerciseInTemplatesStringList);
    } catch (e) {
      handleError(e.response.data);
    }
  };

  const handleError = useCallback((error: any) => {

    if (error) {
      let errMsgs = [];
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.invalidEmail,
        errorUtils.exerciseTemplateNotFound,
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsgs.push(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          errMsgs.push(t(`error.${errorCode}`));
        } else {
          errMsgs.push(t('error.general'));
        }
      }

      setErrorMessage(errMsgs);
    }

  }, []);

  const setErrorMessage = (errMsgs?: string[]) => {

    if (errMsgs) {
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const handleSubmit = (values: Partial<UpsertExerciseTemplateDto>): void => {

    values.exerciseConfList = selectedExerciseConfList;

    const onSave = () => {
      exerciseTemplateId
        ? setSuccessMsg(t('exerciseTemplate.updateSuccess'))
        : setSuccessMsg(t('exerciseTemplate.addSuccess'));
      setTimeout(() => {
        if (exerciseTemplateId) {
          history.push('/rehabilitation-exercise-template/search');
        } else {
          history.push('/rehabilitation-exercise-template/search');
        }
      }, 1200);
    };

    if (exerciseTemplateId) {
      editExerciseTemplate(exerciseTemplateId, values, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e.response.data));
    } else {
      addExerciseTemplate(values, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e.response.data));
    }
  };

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={(values: Partial<UpsertExerciseTemplateDto>) => handleSubmit(values)}
        initialValues={exerciseTemplate}
        subscription={{ pristine: true, submitting: true }}
        render={(formProps: FormRenderProps<UpsertExerciseTemplateDto>) => renderExerciseTemplateForm(formProps)}
      />
    );
  };

  const renderExerciseTemplateForm = ({
                                    handleSubmit,
                                    submitting,
                                    form,
                                  }: FormRenderProps<UpsertExerciseTemplateDto>): React.ReactNode => {

    const selectedExerciseInTemplatesLeft = markedExerciseConf && selectedExerciseConfList.includes(markedExerciseConf)
      ? exerciseInTemplatesList
      : [];
    const selectedExerciseInTemplatesRight = markedExerciseConf && availableExerciseConfList.includes(markedExerciseConf)
      ? exerciseInTemplatesList
      : [];

    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          <Grid.Row>
            <Grid.Column width={4} verticalAlign='middle'>
              <DataLabel>
                {t('exerciseTemplate.description')}
              </DataLabel>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6} verticalAlign='middle'>
              <MultiLanguageInput fieldName='description' form={form} requiredFirst={true} />
            </Grid.Column>
            <Grid.Column width={1} textAlign='center' verticalAlign='bottom'>
              <Field
                name='active'
                component={CheckBox}
                label={t('exerciseTemplate.active')}
                toggle={true}
                style={{ marginLeft: '1rem' }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4} verticalAlign='middle'>
              <DataLabel>
                {t('exerciseTemplate.exerciseSelection')}
              </DataLabel>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <TemplateExerciseConfSegment
              selectedExerciseConfList={selectedExerciseConfList && selectedExerciseConfList.length > 0 ? selectedExerciseConfList : []}
              availableExerciseConfList={availableExerciseConfList}
              setData={setData}
              setSelectedExerciseInTemplates={setSelectedExerciseInTemplates}
            />
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4} verticalAlign={'middle'}>
              <DataLabel>
                {t('exerciseTemplate.selectedExerciseInTemplate')}
              </DataLabel>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6}>
              <StyledLeftDataDiv>{selectedExerciseInTemplatesLeft}</StyledLeftDataDiv>
            </Grid.Column>
            <Grid.Column width={1} />
            <Grid.Column width={6}>
              <StyledRightDataDiv>{selectedExerciseInTemplatesRight}</StyledRightDataDiv>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={14}
                         style={{ borderTop: '1px solid #d4d9e0', marginTop: '2rem', marginBottom: '-2rem' }}
            >
            </Grid.Column>
          </Grid.Row>

          <Grid.Row textAlign='right'>
            <Grid.Column width={14} style={{ paddingRight: '0' }}>
              {
                successMsg &&
                <SaveAndUpdateConfirmationMessage>
                  {successMsg}
                </SaveAndUpdateConfirmationMessage>
              }
              <CompositeButton
                secondary
                type='button'
                floated='right'
                className='action-button'
                style={{ display: 'inline-block', marginRight: '2rem' }}
                disabled={submitting || !!successMsg}
                onClick={goToPreviousPage}
              >
                {t('action.cancel')}
              </CompositeButton>

              <CompositeButton
                primary
                type='submit'
                floated='right'
                className='action-button'
                style={{ display: 'inline-block', marginRight: '2rem' }}
                disabled={submitting || !!successMsg}
              >
                {t('button.save')}
              </CompositeButton>


            </Grid.Column>
          </Grid.Row>
        </TsaGrid>
      </form>
    );
  };

  const renderDataContent = (): React.ReactNode => {
    return (
      <div>
        <div className='page-header'>
          <div className='title-h1'>{t('exerciseTemplate.viewTitle')}</div>
        </div>
      </div>
    );
  };

  return (
    <Container fluid>
      <ContentWrapperDiv>
        {renderDataContent()}
        {formDataLoaded
          ? <React.Fragment>
            {renderFinalForm()}
          </React.Fragment>
          : <LoaderComponent message={t('exerciseTemplate.loading')} />
        }
      </ContentWrapperDiv>
    </Container>
  );
};

export default UpsertExerciseTemplateForm;