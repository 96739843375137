import { CancelTokenSource } from 'axios';
import axios, { backendUrls } from 'service/http';
import {
  ExerciseConfDto,
  ExerciseConfSearchRequest,
  UpsertExerciseConfDto,
} from 'ts-types/api.types';


const apiUri = `${backendUrls.apiRoot}/exerciseconf`;

export const getExerciseConfs = (source: CancelTokenSource): Promise<Array<ExerciseConfDto>> => {
  return axios
  .get(apiUri, { cancelToken: source.token })
  .then(response => response.data);
};

export const getExerciseConf = (exerciseConfId: number, source: CancelTokenSource): Promise<UpsertExerciseConfDto> => {
  return axios
  .get(`${apiUri}/${exerciseConfId}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const addExerciseConf = (upsertExerciseDto: Partial<UpsertExerciseConfDto>, source: CancelTokenSource): Promise<ExerciseConfDto> => {
  return axios
  .post(apiUri, upsertExerciseDto, { cancelToken: source.token })
  .then(response => response.data);
};

export const editExerciseConf = (exerciseConfId: number, exerciseConf: Partial<UpsertExerciseConfDto>, source: CancelTokenSource): Promise<ExerciseConfDto> => {
  return axios
  .put(`${apiUri}/${exerciseConfId}`, exerciseConf, { cancelToken: source.token })
  .then((response) => response.data);
};

export const searchExerciseConfs = (
  request: Partial<ExerciseConfSearchRequest>,
  source: CancelTokenSource): Promise<ExerciseConfDto[]> => {
  return axios
  .post(`${apiUri}/search`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const deleteExerciseConfById = (exerciseConfId: number, source: CancelTokenSource): Promise<ExerciseConfDto> => {

  return axios
  .delete(`${apiUri}/${exerciseConfId}`, { cancelToken: source.token })
  .then(response => response.data);
};