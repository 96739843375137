import React, { useState, useEffect } from 'react';
import { useDropzone, DropzoneProps, DropEvent } from 'react-dropzone';
import styled from 'styled-components';

const TEN_MEGA_BYTES = 10485760;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: white;
  color: var(--light-blue);
  outline: none;
  transition: border .24s ease-in-out;
  cursor: pointer;
  height: 60px;
`;

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return 'var(--light-green)';
  }
  if (props.isDragReject) {
    return 'var(--light-red)';
  }
  if (props.isDragActive || props.isFileDialogActive) {
    return 'var(--blue)';
  }
  return 'white';
};

interface Props extends DropzoneProps {
  message?: string;
  isImage?: boolean;
  imageContentBase64?: string;
  imageFileType?: string;
}

const DropZone: React.FC<Props> = ({
                                     message,
                                     isImage = false,
                                     imageContentBase64,
                                     imageFileType,
                                     onDropAccepted,
                                     ...restProps
                                   }) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const handleDrop = (acceptedFiles: File[], event: DropEvent) => {
    if (isImage && acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
      const previewImageUrl = URL.createObjectURL(file);
      setImageUrl(previewImageUrl);
    }
    if (onDropAccepted) {
      onDropAccepted(acceptedFiles, event);
    }
  };

  useEffect(() => {
    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [imageUrl]);

  const renderImage = () => {
    if (isImage) {
      if (imageUrl) {
        return <img height="60px" src={imageUrl} alt={message} />;
      } else if (imageContentBase64) {
        return <img height="60px" src={`data:${imageFileType};base64, ${imageContentBase64}`} alt={message} />;
      } else {
        return <p>{message}</p>;
      }
    } else {
      return <p>{message}</p>;
    }
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, isFileDialogActive } = useDropzone({
    maxSize: TEN_MEGA_BYTES,
    onDropAccepted: handleDrop,
    ...restProps,
  });

  return (
    <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject, isFileDialogActive })}>
      <input {...getInputProps()} />
      {renderImage()}
    </Container>
  );
};

export default DropZone;

