import CheckBox from 'components/final-form/CheckBox';
import React from 'react';
import { FieldProps } from 'react-final-form';

export interface CheckBoxProps {
  name: string,
  component: any,
  validate?: any,
  toggle?: boolean
}

export const checkBoxInput = (fields: FieldProps<any, any>): CheckBoxProps => {
  return {
    component: CheckBox,
    validate: fields.validate,
    toggle: true,
    ...fields,
  };
};
