import { CancelTokenSource } from 'axios';
import axios, { backendUrls } from 'service/http';
import {
  OverrideNormValueRequest,
  PatientTestingPreview,
  PatientTestingProgressDto,
  PdfResponse,
  UpsertPatientTestResultDto,
} from 'ts-types/api.types';

const baseUrl = process.env.REACT_APP_BACKEND_ROOT_URL;
const apiUri = `${backendUrls.apiRoot}/patient-testing-result`;

export const createPatientTestingResult = (
  upsertPatientTestResultDto: UpsertPatientTestResultDto, source: CancelTokenSource):
  Promise<PatientTestingProgressDto> => {
  return axios
  .post(apiUri, upsertPatientTestResultDto, { cancelToken: source.token })
  .then(response => response.data);
};

export const previewResults = (patientTestingId: number, source: CancelTokenSource): Promise<PatientTestingPreview> => {
  return axios
  .get(`${apiUri}/${patientTestingId}/preview-results`, { cancelToken: source.token })
  .then(response => response.data);
};

export const setOverrideNormValue = (
  request: Partial<OverrideNormValueRequest>,
  source: CancelTokenSource,
): Promise<string> => {
  return axios
  .post(`${apiUri}/override-norm-value`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const getPatientTestingPreviewPdf = (
  patientTestingId: number, source: CancelTokenSource)
  : Promise<PdfResponse> => {

  return axios
  .get(`${apiUri}/${patientTestingId}/preview`, { cancelToken: source.token })
  .then((response) => response.data);
};

export const getPatientTestingPreviewPdfUrl = (pdfResponse: PdfResponse) => {
  return `${baseUrl}/775913e47aac41da8f3c9f4da9825475/${pdfResponse.pdfId}/${pdfResponse.pdfName}`;
};