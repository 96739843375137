import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import {
  clearAccessToken,
  getAccessToken,
  getAuthorizationHeaderValue,
  getLanguage,
} from 'service/browserStorageServices';

interface IndexSignature {
  [key: string]: any;
}

const backendRootUrl = process.env.REACT_APP_BACKEND_ROOT_URL;

export const backendUrls = {
  root: backendRootUrl,
  apiRoot: `${backendRootUrl}/api`,
};

axios.defaults.paramsSerializer = (object: IndexSignature) => {

  let queryParams: string = '';
  Object.keys(object).forEach((key, index) => {
    if (index == 0) {
      queryParams += `${key}=${encodeURIComponent(object[key])}`;
    } else {
      queryParams += `&${key}=${encodeURIComponent(object[key])}`;
    }
  });

  return queryParams;
};

const authenticationInterceptor = (config: AxiosRequestConfig) => {
  const accessToken = getAccessToken();
  if (accessToken) {
    config.headers['Authorization'] = getAuthorizationHeaderValue(accessToken);
  }

  config.headers['Accept-Language'] = getLanguage();

  return config;
};

axios.interceptors.request.use(authenticationInterceptor);

function globalErrorHandler(error: AxiosError) {
  if (error.response && error.response.status === 401) {
    clearAccessToken();
  }
  return Promise.reject(error);
}

axios.interceptors.response.use(response => response, globalErrorHandler);

export default axios;
