import Dashboard from 'components/Dashboard';
import React from 'react';

function Home() {

  return (
    <Dashboard />
  );
}

export default Home;
