import React from 'react';
import { FieldProps } from 'react-final-form';
import Input from 'components/final-form/Input';

export interface InputFieldProps {
  name: string,
  component: any,
  validate?: any,
  disabled?: boolean
  hiddenInput?: boolean
}

export const textInput = (fields: FieldProps<any, any>, disabled?: boolean): InputFieldProps => {
  return {
    name: fields.name,
    component: Input,
    validate: fields.validate,
    disabled: fields.disabled,
    hiddenInput: fields.hiddenInput
  }
};
