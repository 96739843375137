import { CancelTokenSource } from 'axios';
import {
  DoctorDto,
  DoctorImportDto, DoctorImportRequest,
  DoctorSearchRequest,
  UpsertDoctorDto,
  UpsertDoctorImportDto,
} from 'ts-types/api.types';
import axios, { backendUrls } from './http';

const apiUri = `${backendUrls.apiRoot}/doctor-import`;

export const uploadDoctorsFile = (values: Partial<UpsertDoctorImportDto>, clinicId: number | undefined,
                                  source: CancelTokenSource): Promise<Array<DoctorImportDto>> => {
  let bodyFormData = new FormData();
  Object.keys(values).forEach((key) => {
    let value = values[key as keyof UpsertDoctorImportDto];

    if (value) {
      if (typeof value === "number") {
        //@ts-ignore
        value = value.toString();
      }
      //@ts-ignore
      bodyFormData.append(key, value);
    }

  });

  return axios
  .post(`${apiUri}`, bodyFormData, {params: { clinicId }, cancelToken: source.token})
  .then((response) => response.data);
};

export const importDoctors = (
  request: Partial<DoctorImportRequest>, clinicId: number | undefined, source: CancelTokenSource)
  : Promise<string> => {

  return axios
  .post(`${apiUri}/import`, request, {params: { clinicId }, cancelToken: source.token})
  .then(response => response.data);
};
