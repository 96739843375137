import { CancelTokenSource } from 'axios';
import { DoctorDto, DoctorSearchRequest, UpsertDoctorDto } from 'ts-types/api.types';
import axios, { backendUrls } from './http';

const apiUri = `${backendUrls.apiRoot}/doctor`;

export const searchDoctors = (
  doctorSearchRequest: Partial<DoctorSearchRequest>,
  source: CancelTokenSource): Promise<DoctorDto[]> => {
  return axios
  .post(`${apiUri}/search`, doctorSearchRequest, { cancelToken: source.token })
  .then(response => response.data);
};

export const getDoctorDetails = (
  doctorId: number,
  source: CancelTokenSource): Promise<DoctorDto> => {
  return axios
  .get(`${apiUri}/${doctorId}/details`, { cancelToken: source.token })
  .then(response => response.data);
};

export const findDoctorById = (doctorId: number, source: CancelTokenSource): Promise<UpsertDoctorDto> => {
  return axios
  .get(`${apiUri}/${doctorId}`, { cancelToken: source.token })
  .then(response => response.data);};

export const getDoctorsForClinicAdmin = (source: CancelTokenSource): Promise<DoctorDto[]> => {
  return axios
  .get(`${apiUri}/clinic/all`, { cancelToken: source.token })
  .then(response => response.data);
};

export const addDoctor = (
  upsertDoctorDto: Partial<UpsertDoctorDto>,
  source: CancelTokenSource): Promise<DoctorDto> => {

  return axios
  .post(apiUri, upsertDoctorDto, { cancelToken: source.token })
  .then(response => response.data);
};

export const editDoctor = (
  doctorId: number,
  upsertDoctorDto: Partial<UpsertDoctorDto>,
  source: CancelTokenSource): Promise<DoctorDto> => {

  return axios
  .put(`${apiUri}/${doctorId}`, upsertDoctorDto, {cancelToken: source.token})
  .then(response => response.data);
};

export const deleteDoctor = (doctorId: number,
                             source: CancelTokenSource): Promise<DoctorDto> => {

  return axios
  .delete(`${apiUri}/${doctorId}`, {cancelToken: source.token})
  .then(response => response.data);
};
