import { CancelTokenSource } from 'axios';
import axios, { backendUrls } from 'service/http';
import {
  ExerciseTemplateDto, ExerciseTemplateSearchRequest,
  UpsertExerciseTemplateDto,
} from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/exercise-template`;

export const getAllExerciseTemplates = (source: CancelTokenSource): Promise<ExerciseTemplateDto[]> => {
  return axios
  .get(apiUri, { cancelToken: source.token })
  .then(response => response.data);
};

export const getExerciseTemplate = (exerciseTemplateId: number, source: CancelTokenSource): Promise<UpsertExerciseTemplateDto> => {
  return axios
  .get(`${apiUri}/${exerciseTemplateId}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getExerciseInTemplates = (exerciseConfId: number, source: CancelTokenSource): Promise<ExerciseTemplateDto[]> => {
  return axios
  .get(`${apiUri}/exercise-in-templates/${exerciseConfId}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const searchExerciseTemplates = (
  request: Partial<ExerciseTemplateSearchRequest>,
  source: CancelTokenSource): Promise<ExerciseTemplateDto[]> => {
  return axios
  .post(`${apiUri}/search`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const addExerciseTemplate = (
  upsertExerciseTemplateDto: Partial<UpsertExerciseTemplateDto>,
  source: CancelTokenSource): Promise<ExerciseTemplateDto> => {

  return axios
  .post(apiUri, upsertExerciseTemplateDto, { cancelToken: source.token })
  .then(response => response.data);
};

export const editExerciseTemplate = (
  exerciseTemplateId: number,
  upsertExerciseTemplateDto: Partial<UpsertExerciseTemplateDto>,
  source: CancelTokenSource): Promise<ExerciseTemplateDto> => {

  return axios
  .put(`${apiUri}/${exerciseTemplateId}`, upsertExerciseTemplateDto, {cancelToken: source.token})
  .then(response => response.data);
};

export const deleteExerciseTemplateById = (id: number, source: CancelTokenSource): Promise<ExerciseTemplateDto> => {

  return axios
  .delete(`${apiUri}/${id}`, { cancelToken: source.token })
  .then(response => response.data);
};