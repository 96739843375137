import axios, { CancelTokenSource } from 'axios';
import { backendUrls } from 'service/http';
import { InvoiceConfigDto, UpsertInvoiceConfigDto } from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/invoiceconfig`;

export const getInvoiceConfigById = (invoiceConfigId: number, source: CancelTokenSource): Promise<UpsertInvoiceConfigDto> => {
  return axios
    .get(`${apiUri}/${invoiceConfigId}`, {cancelToken: source.token})
    .then(response => response.data);
}

export const addInvoiceConfig = (upsertInvoiceConfigDto: Partial<UpsertInvoiceConfigDto>, source: CancelTokenSource): Promise<InvoiceConfigDto> => {
  return axios
    .post(apiUri, upsertInvoiceConfigDto, { cancelToken: source.token})
    .then(response => response.data);
}

export const editInvoiceConfig = (invoiceConfigId: number, upsertInvoiceConfigDto: Partial<UpsertInvoiceConfigDto>,
                                  source: CancelTokenSource): Promise<UpsertInvoiceConfigDto> => {
  return axios
    .put(`${apiUri}/${invoiceConfigId}`, upsertInvoiceConfigDto, { cancelToken: source.token})
    .then(response => response.data);
}

export const getInvoiceConfigs = (source: CancelTokenSource): Promise<InvoiceConfigDto[]> => {
  return axios
    .get(apiUri, {cancelToken: source.token})
    .then(response => response.data);
};

export const deleteInvoiceConfigById = (invoiceConfigId: number, source: CancelTokenSource): Promise<InvoiceConfigDto> => {
  return axios
    .delete(`${apiUri}/${invoiceConfigId}`, {cancelToken: source.token})
    .then(response => response.data)
};