import React, { Component } from "react"
import { DividerProps } from 'semantic-ui-react';
import styled from 'styled-components';

const MainContainerDiv = styled.div`
  display: flex;
  flex: auto;
  align-content: stretch;
  justify-content: center;
  align-items: center;
`;

class MainContainerComponent extends Component<DividerProps> {

  render(): React.ReactNode {
    const {children, ...rest} = this.props;
    return (
        <MainContainerDiv {...rest}>
          {children}
        </MainContainerDiv>
    )
  }
}

export default MainContainerComponent;
