import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import CheckBox from 'components/final-form/CheckBox';
import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import Input from 'components/final-form/Input';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import Select, {
  DropdownOption,
  mapToDropdownOptionArray,
  stringArrayToDropdownOptionArray,
} from 'components/final-form/Select';
import TextArea from 'components/final-form/TextArea';
import InnerTsaGrid from 'components/InnerTsaGrid';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import React, { useEffect, useMemo, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Grid, Header } from 'semantic-ui-react';
import { getDoctorsForClinic } from 'service/adminService';
import { getDoctorsForClinicAdmin } from 'service/doctorService';
import { createPatient, getPatient, updatePatient } from 'service/patientService';
import { Gender } from 'ts-types/api.enums';
import { DoctorDto, UpsertPatientDto } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { noop } from 'util/functionUtils';
import { required } from 'util/validatorUtils';

interface Props {
}

const cancelTokenSource = axios.CancelToken.source();

const UpsertPatientForm = (props: Props) => {

    const { currentUser } = useAuthContext();
    const { language } = useAuthContext();

    const genderLabels: Array<Gender> = Object.values(Gender);
    const { state } = useLocation();
    const isIpad = useIsIpadWidthOrBelow();

    const patientId: number | undefined = state?.id ? Number(state?.id) : undefined;
    const clinicId: number | undefined = state?.clinicId ? Number(state?.clinicId) : undefined;
    const prevPath: string | undefined = state?.prevPath ? state.prevPath : undefined;

    const isSystemAdmin = useMemo(() => currentUser
    && currentUser.roles.includes('ROLE_APP_ADMIN'), [currentUser]);

    const [doctors, setDoctors] = useState<DoctorDto[]>([]);
    const [doctorOptions, setDoctorOptions] = useState<Array<DropdownOption>>([]);

    const { t } = useTranslation('teresa');
    const history = useHistory();

    const genders = useMemo(() =>
      stringArrayToDropdownOptionArray(genderLabels, t, 'gender'), []);

    const initialPatientValues: Partial<UpsertPatientDto> = {
      active: true,
      treaterId: currentUser?.id,
      clinicId: clinicId
    };

    const [patient, setPatient] = React.useState<Partial<UpsertPatientDto> | undefined>(initialPatientValues);

    const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(true);
    const [successMsg, setSuccessMsg] = React.useState<string | undefined>(undefined);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    useEffect(() => {
      fetchData();
    }, []);

    useEffect(() => {
      const doctorOptArray = mapToDropdownOptionArray(doctors, ['name', 'lastName'], language);
      setDoctorOptions(doctorOptArray);
    }, [doctors, language]);


    const fetchData = async () => {

      try {
        if (patientId) {
          setFormDataLoaded(false);
          getPatient(patientId, cancelTokenSource)
          .then(response => {
            setPatient(response);
          });
        }
        await fetchDoctors();
      } catch (e) {
        handleError(e.response.data);
      } finally {
        setFormDataLoaded(true);
      }
    };

    const fetchDoctors = async () => {

      let doctorsResponse: DoctorDto[] = [];

      if (isSystemAdmin && clinicId) {
        doctorsResponse = await getDoctorsForClinic(clinicId, cancelTokenSource);
      } else {
        doctorsResponse = await getDoctorsForClinicAdmin(cancelTokenSource);
      }

      setDoctors(doctorsResponse);
    };

  const goToPreviousPage = () => {
    if (prevPath) {
      history.push(prevPath, { id: patientId, clinicId: clinicId });
    } else {
      history.push('/patient/search', { clinicId: clinicId });
    }
  };

    const handleError = (error: any) => {

      if (error) {
        const errorCode = error.errorCode;

        const knownErrors: Array<string> = [
          errorUtils.invalidEmail,
          errorUtils.patientNotFound,
          errorUtils.doctorNotFound,
          errorUtils.editingPatientNotAllowed,
          errorUtils.originIdExists
        ];

        const violations: Array<any> = error.violations;

        if (violations && violations.length > 0) {
          violations.forEach(violation => {
            if (knownErrors.includes(violation.errorCode)) {
              if (violation.errorCode === errorUtils.editingPatientNotAllowed) {
                setErrorMessage(t(`error.${violation.errorCode}` , {details: violation.details}));
              } else {
                setErrorMessage(violation.details);
              }
            }

          });

        } else {

          if (knownErrors.includes(errorCode)) {
            setErrorMessage(t(`error.${errorCode}`))
          } else {
            setErrorMessage(t('error.general'));
          }
        }
      }
    };


    const setErrorMessage = (errorMessage?: string) => {

      if (errorMessage) {
        const errMsgs = [...errorMessages];
        errMsgs.push(errorMessage);
        setErrorMessages(errMsgs);
      } else {
        setErrorMessages([]);
      }
    };

    const handleSubmit = async (values: Partial<UpsertPatientDto>) => {

      let saveValues: Partial<UpsertPatientDto> = {
        ...values,
        treaterId: values.treaterId,
      };

      const onSave = () => {
        patientId
          ? setSuccessMsg(t('patient.onUpdate'))
          : setSuccessMsg(t('patient.onAdd'));
        setTimeout(() => {
          if (patientId) {
            history.push('/patient/details', { id: patientId, clinicId: clinicId });
          } else {
            history.push('/patient/search', {clinicId: clinicId});
          }
        }, 1200);
      };

      try {
        if (patientId) {
          await updatePatient(patientId, saveValues, cancelTokenSource);
        } else {
          await createPatient(saveValues, cancelTokenSource);
        }
        onSave();
      } catch (e) {
        handleError(e.response.data);
      }
    };

    const renderFinalForm = (): React.ReactNode => {
      return (
        <FinalForm
          onSubmit={(values: Partial<UpsertPatientDto>) => handleSubmit(values)}
          initialValues={patient}
          subscription={{ pristine: true, submitting: true }}
          render={(formProps: FormRenderProps<UpsertPatientDto>) => renderFormContent(formProps)}
        />
      );
    };

    const renderFormContent = (
      { handleSubmit, submitting }: FormRenderProps<UpsertPatientDto>): React.ReactNode => {

      return (
        <form onSubmit={handleSubmit}>
          <TsaGrid>
            <Grid.Row>
              <Grid.Column width={12}>
                <div className='title-h1'>{t('patient.viewTitle')}</div>
              </Grid.Column>
            </Grid.Row>

            {errorMessages.length > 0 &&
              <Grid.Row>
                <Grid.Column width={12}>
                  <div className='error'>
                    <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                      {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                    </StyledErrorMessage>
                  </div>
                </Grid.Column>
              </Grid.Row>
            }

            <Grid.Row>
              <Grid.Column width={16}>
                <Grid stackable doubling columns={2}>
                  <Grid.Column width={4}>
                    <InnerTsaGrid>
                      <Grid.Row>
                        <Grid.Column width={10}>
                          <Header as='h4'>{t('patient.generalInfo')}</Header>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('patient.originId')}</DataLabel>
                          <Field
                            fluid
                            name='originId'
                            placeholder={t('patient.placeHolder.originId')}
                            component={Input}
                            validate={required}
                            disabled={patientId !== undefined}
                            autoFocus
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('patient.gender')}</DataLabel>
                          <Field
                            fluid
                            name='gender'
                            placeholder={t('patient.placeHolder.gender')}
                            component={Select}
                            options={genders}
                            validate={required}
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('patient.schoolYears')}</DataLabel>
                          <Field
                            fluid
                            name='schoolYears'
                            component={Input}
                          />
                        </Grid.Column>
                      </Grid.Row>

                    </InnerTsaGrid>
                  </Grid.Column>

                  <Grid.Column width={4}>
                    <InnerTsaGrid>
                      {isIpad ? <></> : <Grid.Row></Grid.Row>}

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('patient.doctor')}</DataLabel>
                          <Field
                            fluid
                            name='treaterId'
                            placeholder={t('patient.placeHolder.doctor')}
                            component={Select}
                            options={doctorOptions}
                            search
                            validate={required}
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('patient.remark')}</DataLabel>
                          <Field
                            fluid
                            name='remark'
                            placeholder={t('patient.placeHolder.remark')}
                            component={TextArea}
                            rows={6}
                          />
                        </Grid.Column>
                      </Grid.Row>

                    </InnerTsaGrid>
                  </Grid.Column>

                  <Grid.Column width={4}>
                    <InnerTsaGrid>
                      <Grid.Row>

                        <Grid.Column width={10}>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('patient.active')}</DataLabel>
                          <Field
                            name='active'
                            component={CheckBox}
                            label={t('patient.active')}
                            toggle={true}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </InnerTsaGrid>
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={12}
                           style={{
                             borderTop: '1px solid var(--very-light-blue)',
                             marginTop: '2rem',
                           }}
              >
              </Grid.Column>
            </Grid.Row>

            <Grid.Row textAlign='right'>
              <Grid.Column width={12} style={{ paddingRight: '0' }}>
                {
                  successMsg &&
                  <SaveAndUpdateConfirmationMessage>
                    {successMsg}
                  </SaveAndUpdateConfirmationMessage>
                }
                <CompositeButton
                  primary
                  type='submit'
                  className='action-button'
                  disabled={submitting || !!successMsg}
                  onClick={noop}
                >
                  {t('button.save')}
                </CompositeButton>
                <CompositeButton
                  type='button'
                  className='action-button'
                  secondary
                  disabled={submitting || !!successMsg}
                  onClick={goToPreviousPage}
                  style={{ marginRight: 0 }}
                >
                  {t('action.back')}
                </CompositeButton>
              </Grid.Column>
            </Grid.Row>
          </TsaGrid>
        </form>
      );
    };

    return (
      <UpsertContentWrapperDiv>
        {formDataLoaded
          ? <React.Fragment>
            {renderFinalForm()}
          </React.Fragment>
          : <LoaderComponent message={t('patient.loadFormData')} />
        }
      </UpsertContentWrapperDiv>
    );
  }
;

export default UpsertPatientForm;