import { CancelTokenSource } from 'axios';
import axios, { backendUrls } from 'service/http';
import {
  AccountDto,
  ClinicAdminDto,
  ClinicDto,
  ClinicSearchRequest,
  DoctorDto,
  UpsertAccountDto,
  UpsertClinicDto,
  UpsertDoctorDto,
} from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/admin`;

export const getClinicDetails = (clinicId: number, source: CancelTokenSource): Promise<ClinicDto> => {
  return axios
  .get(`${apiUri}/clinic/${clinicId}/details`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getClinicAdminData = (clinicId: number, source: CancelTokenSource): Promise<ClinicAdminDto> => {
  return axios
  .get(`${apiUri}/clinic/${clinicId}/admin`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getClinicById = (clinicId: number, source: CancelTokenSource): Promise<UpsertClinicDto> => {
  return axios
  .get(`${apiUri}/clinic/${clinicId}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getDoctorsForClinic = (clinicId: number, source: CancelTokenSource): Promise<DoctorDto[]> => {
  return axios
  .get(`${apiUri}/clinic/${clinicId}/doctors`, { cancelToken: source.token })
  .then(response => response.data);
};

export const searchClinics = (
  request: Partial<ClinicSearchRequest>,
  source: CancelTokenSource): Promise<ClinicDto[]> => {
  return axios
  .post(`${apiUri}/clinic/search`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const adminEditClinic = (
  id: number,
  upsertClinicDto: Partial<UpsertClinicDto>,
  source: CancelTokenSource): Promise<ClinicDto> => {

  let bodyFormData = new FormData();
  Object.keys(upsertClinicDto).forEach((key) => {
    let value = upsertClinicDto[key as keyof UpsertClinicDto];

    if (value) {
      if (typeof value === "number") {
        //@ts-ignore
        value = value.toString();
      }
      //@ts-ignore
      bodyFormData.append(key, value);
    }

  });

  return axios
  .put(`${apiUri}/clinic/${id}`, bodyFormData, { cancelToken: source.token })
  .then(response => response.data);
};

export const adminAddDoctor = (
  clinicId: number,
  upsertDoctorDto: Partial<UpsertDoctorDto>,
  source: CancelTokenSource): Promise<DoctorDto> => {

  return axios
  .post(`${apiUri}/clinic/${clinicId}/doctor`, upsertDoctorDto, { cancelToken: source.token })
  .then(response => response.data);
};

export const adminEditDoctor = (
  clinicId: number,
  doctorId: number,
  upsertDoctorDto: Partial<UpsertDoctorDto>,
  source: CancelTokenSource): Promise<DoctorDto> => {

  return axios
  .put(`${apiUri}/clinic/${clinicId}/doctor/${doctorId}`, upsertDoctorDto, { cancelToken: source.token })
  .then(response => response.data);
};

export const addAccount = (request: Partial<UpsertAccountDto>, source: CancelTokenSource): Promise<AccountDto> => {
  return axios
  .post(`${apiUri}/account`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const editAccount = (accountId: number, upsertAccountDto: Partial<UpsertAccountDto>,
                            source: CancelTokenSource): Promise<UpsertAccountDto> => {
  return axios
  .put(`${apiUri}/account/${accountId}`, upsertAccountDto, { cancelToken: source.token })
  .then(response => response.data);
};

export const addTestAdmin = (request: Partial<UpsertAccountDto>, source: CancelTokenSource): Promise<AccountDto> => {
  return axios
  .post(`${apiUri}/account/test-admin`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const deleteAccount = (accountId: number, source: CancelTokenSource): Promise<UpsertAccountDto> => {
  return axios
  .delete(`${apiUri}/account/${accountId}`, { cancelToken: source.token })
  .then(response => response.data);
};