import _ from 'lodash';
import React, { Component } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Dropdown, DropdownItemProps, DropdownProps, Form, SemanticShorthandContent } from 'semantic-ui-react';
import styled from 'styled-components';

export type DropdownValue = boolean | number | string | (boolean | number | string)[];

export interface DropdownOption {
  key: string | number,
  text: string | number,
  value: any,
  content?: SemanticShorthandContent,
  disabled?: boolean,
  predefinedOption?: boolean
}

export const PxDropdownSc = styled(Dropdown)`

  &.ui.selection.dropdown {
    border-radius: 5px;
  }

  & .visible.menu.transition {
    border: 1px solid var(--very-very-light-blue);
    border-radius: 5px;
  }
`;

export const stringArrayToDropdownOptionArray = (list: Array<string>,
                                                 getMessage: Function,
                                                 messageKey: string,
                                                 keyList?: Array<string | number>): Array<DropdownOption> => {

  return list.map((element, index) => {
    const keyValuePair = keyList ? keyList[index] : element.toUpperCase();
    return {
      key: keyValuePair,
      text: getMessage(`${messageKey}.${element}`),
      value: keyValuePair
    };
  });
};

export const mapToMultiLangDropdownOptionArray = (list: Array<any>,
                                                  textKey: string,
                                                  language: string,
                                                  key?: string | number,
                                                  value?: string | number): Array<DropdownOption> => {

  const lang = _.capitalize(language);

  return list.map(element => {

    const text = element[`${textKey}${lang}`] || element[textKey];

    return {
      key: key ? element[key] : element.id,
      text: text,
      value: value ? element[value] : element.id,
    };
  });
};

export const mapToDropdownOptionArray = (list: Array<any>,
                                         textKey: string | Array<number | string>,
                                         key?: string | number,
                                         value?: string | number): Array<DropdownOption> => {
  return list.map(element => {

    const text = typeof textKey === "string" ?
        element[textKey] : textKey.map(subKey => element[subKey]).join(" ");

    return {
      key: key ? element[key] : element.id,
      text: text,
      value: value ? element[value] : element.id
    };
  });
};

export const mapToDropdownOptionArrayWithTextGetter = (
    list: Array<any>,
    textGetter: (item: any) => string,
    key?: string | number,
    valueKey?: string | number): Array<DropdownOption> => {

  return list.map(element => {

    return {
      key: key ? element[key] : element.id,
      text: textGetter(element),
      value: valueKey ? element[valueKey] : element.id
    };
  });
};

export interface FinalFormSelectProps extends FieldRenderProps<any>, DropdownProps {
  customRemoteSearchMethod?: (search: string) => void,
  remoteSearchMethod?: (search: string) => Promise<Array<any>>,
  searchResultToOptionMapper?: (options: Array<any>) => Array<DropdownOption>,
  onNewAddition?: (addition: DropdownOption) => void
}

interface State {
  options: Array<DropdownItemProps>;
}

class FinalFormSelectUI extends Component<FinalFormSelectProps, State> {

  constructor(props: FinalFormSelectProps) {
    super(props);

    const {options = []} = props;

    this.state = {
      options: options
    };
  }

  componentDidUpdate(
      prevProps: Readonly<FinalFormSelectProps>,
      prevState: Readonly<State>, snapshot?: any
  ): void {

    const {options: optionsProps = []} = this.props;
    const {options: prevOptions = []} = prevProps;

    if ((prevOptions !== optionsProps && optionsProps.length !== 0 && prevOptions.length !== 0)
        || prevOptions.length !== optionsProps.length) {
      this.setState({
        options: optionsProps
      });
    }
  }

  onValueChange = (event: React.SyntheticEvent<HTMLElement>, {value}: DropdownProps) => {

    const {input, remoteSearch, clearable} = this.props;

    if (remoteSearch && clearable && !value) {
      this.setState({
        options: []
      });
    }

    input.onChange(value);
  };

  removeAutoCompleteOnFocus = (e: any) => {
    e.target.setAttribute("autocomplete", "nope");
  };

  customSearch = (options: Array<DropdownItemProps>): Array<DropdownItemProps> => {

    const {allowAdditions} = this.props;

    if (allowAdditions) {
      return options.filter(option => {
        return option.key !== "addition";
      });
    }

    return options;
  };

  render(): React.ReactNode {

    const {
      meta,
      input,
      multiple,
      ...restProps
    } = this.props;

    const {options} = this.state;
    const value = input.multiple && input.value === "" ? [] : input.value;

    const restPropsCleaned = _.omit(restProps, ["children", "className"]);
    const optionsCleaned = options.map(option => _.omit(option, ["predefinedOption"]));

    return (
        <Form.Field className={`${this.props.className}`}>
          <>
            <PxDropdownSc
                error={!!(meta.touched && meta.error) || !!(meta.submitFailed && !meta.valid)}
                selection
                value={value}
                multiple={!!input.multiple}
                onChange={this.onValueChange}
                onFocus={this.removeAutoCompleteOnFocus}
                {...restPropsCleaned}
                options={optionsCleaned}
            />
            {this.props.children}
          </>
        </Form.Field>
    );
  }

}

const FinalFormSelect = (FinalFormSelectUI);
export default FinalFormSelect;
