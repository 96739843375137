import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import CompositeButton from 'components/final-form/CompositeButton';
import InnerTableActionButton from 'components/InnerTableActionButton';
import SearchDisplayContainer from 'components/SearchDisplayContainer';
import StyledErrorMessage from 'components/StyledErrorMessage';
import VirtualizedTable from 'components/VirtualizedTable';
import moment from 'moment/moment';
import React, { useCallback, useState } from 'react';
import { Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { deleteInvoiceConfigById, getInvoiceConfigs } from 'service/invoiceConfigServices';
import styled from 'styled-components';
import { debounce } from 'ts-debounce';
import { InvoiceConfigDto } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { useAfterFirstRender } from 'util/functionUtils';
import { emptyTableCell } from 'util/tableUtils';

const StyledSpan = styled.span`
    display: flex;

    .patient-origin-id {
        margin-right: 0.5rem;
    }

    .invoiceconfig-id {
        color: var(--primary-color);
    }
`;

const cancelTokenSource = axios.CancelToken.source();

interface Props {

}

const InvoiceConfigView = (props: Props) => {

  const { push } = useHistory();
  const { t } = useTranslation('teresa');
  const history = useHistory();
  const { language, currentUser } = useAuthContext();

  const [invoiceConfigs, setInvoiceConfigs] = useState<InvoiceConfigDto[]>([]);
  const [invoiceConfigsLoaded, setInvoiceConfigsLoaded] = useState<boolean>(true);

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  React.useEffect(() => {
    fetchInvoiceConfig([]);
  }, []);

  const fetchInvoiceConfig = debounce((values: any): void => {

    const onFinally = () => {
      setInvoiceConfigsLoaded(true);
    };

    setInvoiceConfigsLoaded(false);
    setInvoiceConfigs([]);

    getInvoiceConfigs(cancelTokenSource)
    .then(response => {
      setInvoiceConfigs(response);
    }).catch((e: any) => handleError(e.response.data))
    .finally(onFinally);

  }, 300);

  const handleError = (error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.invoiceConfigDate,
        errorUtils.invoiceConfigNotFound,
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };

  const setErrorMessage = useCallback((errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  }, [errorMessages]);

  const onAddInvoiceConfig = () => {
    push('/invoice-config/upsert');
  };

  const handleChange = useAfterFirstRender((): void => {
    fetchInvoiceConfig([]);
  });


  const renderFinalForm = (): JSX.Element => {
    return (
      <FinalForm
        onSubmit={() => {}}
        initialValues={{}}
        subscription={{ pristine: true, values: true }}
        render={renderInvoiceConf}
      />
    );
  };

  const renderInvoiceConf = ({ values }: FormRenderProps): React.ReactNode => {

    return (
      <>
        <div className='search-container'>
          <div className='title-h1' style={{ paddingLeft: '0.6rem' }}>{t('invoiceConfig.title')}</div>

          {
            errorMessages.length > 0 &&
            <div className='error'>
              <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
              </StyledErrorMessage>
            </div>
          }

          <div className='search-form'>
            <CompositeButton
              type='button'
              className='action-button'
              primary
              onClick={onAddInvoiceConfig}
            >
              {t('button.add')}
            </CompositeButton>

          </div>
        </div>

        {
          errorMessages.length > 0 &&
          <div className='error'>
            <StyledErrorMessage onDismiss={() => setErrorMessage()}>
              {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
            </StyledErrorMessage>
          </div>
        }

        <div className='data-table'>
          {
            invoiceConfigsLoaded
              ? renderInvoiceConfigTableSegment(values)
              : <Loader className='table-loader' active inline content={t('invoiceConfig.loading')} />
          }
        </div>

        <FormSpy subscription={{ values: true }} onChange={handleChange} />
      </>
    );
  };

  const invoiceConfigTableRowRenderer = ({ className, columns, index, key, style }: any) => {
    const a11yProps = { 'aria-rowindex': index + 1 };

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role='row'
        style={style}
      >
        {columns}
      </div>
    );
  };

  const deleteInvoiceConfig = (id: number, values: any) => (): void => {
    try {
      const deletedInvoiceConfig = deleteInvoiceConfigById(id, cancelTokenSource);
      fetchInvoiceConfig(values);
    } catch (e) {
      handleError(e.response.data);
    }
  };

  const actionsCellRenderer = (values: Partial<InvoiceConfigDto>) => ({ rowData }: any) => {

    return (
      <div className='row-actions'>
        <InnerTableActionButton
          message={t('button.edit')}
          onConfirm={() => push(`/invoice-config/upsert`, { id: rowData.id })}
          divider={true}
        />
        <DeleteRecordConfirmation triggerButtonText={t('button.delete')}
                                  confirmAction={deleteInvoiceConfig(rowData.id, values)}
                                  deleteConfirmationText={t('invoiceConfig.confirmDelete')}
                                  position={'top left'} />
      </div>
    );
  };

  const invoiceConfIdRenderer = ({ rowData }: any) => {
    if (rowData) {
      return <div>{rowData.id}</div>;
    }
  };

  const invoiceConfigTypeCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return t(`invoiceConfigType.${cellData}`);
    }
    return emptyTableCell();
  };

  const includedSetsRenderer = ({ rowData }: any) => {
    if (!rowData || !rowData.numberOfIncludedSets) {
      return emptyTableCell();
    }
    return <div style={{ textAlign: 'right' }}>{rowData.numberOfIncludedSets}</div>;
  };

  const packagePriceRenderer = ({ rowData }: any) => {
    if (!rowData || !rowData.packagePrice) {
      return emptyTableCell();
    }
    return <div style={{ textAlign: 'right' }}>{rowData.packagePrice}</div>;
  };

  const testSetPriceRenderer = ({ rowData }: any) => {
    if (!rowData || !rowData.testSetPrice) {
      return emptyTableCell();
    }
    return <div style={{ textAlign: 'right' }}>{rowData.testSetPrice}</div>;
  };

  const exerciseSetPriceRenderer = ({ rowData }: any) => {
    if (!rowData || !rowData.exerciseSetPrice) {
      return emptyTableCell();
    }
    return <div style={{ textAlign: 'right' }}>{rowData.exerciseSetPrice}</div>;
  };

  const validFromTimeCellRenderer = ({ rowData }: any) => {
    if (rowData.validFromDate === null) {
      return emptyTableCell();
    }

    const date = moment(rowData.validFromDate).format('MMM DD, YYYY');
    return <div>{`${date}`}</div>;
  };

  const validToTimeCellRenderer = ({ rowData }: any) => {
    if (rowData.validToDate === null) {
      return emptyTableCell();
    }

    const date = moment(rowData.validToDate).format('MMM DD, YYYY');
    return <div>{`${date}`}</div>;
  };

  const invoiceConfigTableRowGetter = ({ index }: any) => {
    Object.assign(invoiceConfigs[index], { index: index + 1 });
    return invoiceConfigs[index];
  };

  const renderInvoiceConfigTableSegment = (values: any) => {
    return (
      <VirtualizedTable
        rowCount={invoiceConfigs.length}
        rowGetter={invoiceConfigTableRowGetter}
        rowRender={invoiceConfigTableRowRenderer}
        rowHeight={48}
        columns={[
          {
            width: 100,
            label: t('invoiceConfig.id'),
            dataKey: 'id',
            cellRenderer: (invoiceConfIdRenderer),
          },
          {
            width: 250,
            label: t('invoiceConfig.type'),
            dataKey: 'invoiceConfigType',
            cellRenderer: (invoiceConfigTypeCellRenderer),
          },
          {
            width: 100,
            label: t('invoiceConfig.numberOfIncludedSets'),
            dataKey: 'numberOfIncludedSets',
            cellRenderer: includedSetsRenderer,
          },
          {
            width: 90,
            label: t('invoiceConfig.packagePrice'),
            dataKey: 'packagePrice',
            cellRenderer: packagePriceRenderer,
          },
          {
            width: 90,
            label: t('invoiceConfig.testSetPrice'),
            dataKey: 'testSetPrice',
            cellRenderer: testSetPriceRenderer,
          },
          {
            width: 110,
            label: t('invoiceConfig.exerciseSetPrice'),
            dataKey: 'exerciseSetPrice',
            cellRenderer: exerciseSetPriceRenderer,
          },
          {
            width: 150,
            flexGrow: 1,
            label: t('invoiceConfig.validFromDate'),
            dataKey: 'validFromDate',
            cellRenderer: (validFromTimeCellRenderer),
          },
          {
            width: 150,
            flexGrow: 1,
            label: t('invoiceConfig.validToDate'),
            dataKey: 'validToDate',
            cellRenderer: (validToTimeCellRenderer),
          },
          {
            width: 200,
            label: t('invoiceConfig.actions'),
            dataKey: 'id',
            cellRenderer: actionsCellRenderer(values),
          },
        ]}
      />
    );
  };

  return (
    <SearchDisplayContainer>
      {renderFinalForm()}
    </SearchDisplayContainer>
  );
};

export default InvoiceConfigView;