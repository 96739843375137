import Select, { DropdownOption } from 'components/final-form/Select';
import React from 'react';
import { FieldProps } from 'react-final-form';
import { DropdownItemProps } from 'semantic-ui-react';

export interface SelectFieldProps {
  name: string,
  component: any,
  validate?: any,
  search: any,
  clearable?: boolean,
  options: Array<DropdownItemProps>,
  allowAdditions?: boolean,
  remoteSearch?: boolean,
  customRemoteSearchMethod?: (search: string) => void,
  onNewAddition?: (addition: DropdownOption) => void
}

export const selectInput = (fields: FieldProps<any, any>, options: Array<DropdownOption>): SelectFieldProps => {
  return {
    component: Select,
    validate: fields.validate,
    search: true,
    clearable: fields.clearable,
    options: options,
    allowAdditions: fields.allowAdditions,
    remoteSearch: fields.remoteSearch,
    customRemoteSearchMethod: fields.customRemoteSearchMethod,
    onNewAddition: fields.onNewAddition,
    ...fields,
  };
};
