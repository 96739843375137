/* tslint:disable */
// Generated using typescript-generator version 2.12.476 on 2024-07-17 08:48:03.

export enum ActivityRecordType {
    PATIENT = "PATIENT",
    TESTING = "TESTING",
    EXERCISING = "EXERCISING",
}

export enum ActivityType {
    CREATED = "CREATED",
    MODIFIED = "MODIFIED",
    TEST_IN_PROGRESS = "TEST_IN_PROGRESS",
    THERAPY = "THERAPY",
    REPORTING = "REPORTING",
}

export enum ConfigFileArchiveSourceType {
    TEST_CONFIGURATION = "TEST_CONFIGURATION",
    TEST_IMAGE = "TEST_IMAGE",
}

export enum ExportType {
    HL7 = "HL7",
    EXCEL = "EXCEL",
    PDF = "PDF",
}

export enum FileArchiveSourceType {
    PATIENT_TESTING_REPORT = "PATIENT_TESTING_REPORT",
    PATIENT_EXERCISING_REPORT = "PATIENT_EXERCISING_REPORT",
    CLINIC_CONF = "CLINIC_CONF",
    CLINIC = "CLINIC",
    MANDATE = "MANDATE",
    CLINIC_INVOICE = "CLINIC_INVOICE",
}

export enum Gender {
    MALE = "MALE",
    FEMALE = "FEMALE",
    OTHER = "OTHER",
}

export enum HideNumbers {
    HIDE_RAW_NORM = "HIDE_RAW_NORM",
    HIDE_NORM = "HIDE_NORM",
    DONT_HIDE = "DONT_HIDE",
}

export enum InvoiceConfigType {
    SMALL = "SMALL",
    MEDIUM = "MEDIUM",
    LARGE = "LARGE",
}

export enum InvoiceStatus {
    OPEN = "OPEN",
    PAID = "PAID",
}

export enum InvoiceType {
    PACKAGE = "PACKAGE",
    NORMAL = "NORMAL",
}

export enum PatientTestingActionType {
    CREATE = "CREATE",
    START = "START",
    CONTINUE = "CONTINUE",
    CREATE_REPORT = "CREATE_REPORT",
    OPEN_REPORT = "OPEN_REPORT",
    ARCHIVED = "ARCHIVED",
}

export enum PatientTestingStatus {
    NONE = "NONE",
    OPEN = "OPEN",
    RUNNING = "RUNNING",
    COMPLETED = "COMPLETED",
    ARCHIVED = "ARCHIVED",
    DONE = "DONE",
}

export enum QuestionSetType {
    NORMAL = "NORMAL",
    SCORE = "SCORE",
}

export enum QuestionType {
    SINGLE_ANSWER = "SINGLE_ANSWER",
    MULTI_ANSWER = "MULTI_ANSWER",
    FREE_TEXT = "FREE_TEXT",
    YES_NO = "YES_NO",
    NUMBER = "NUMBER",
}

export enum ScopeType {
    TEST = "TEST",
    REHAB = "REHAB",
}

export enum Sex {
    MALE = "MALE",
    FEMALE = "FEMALE",
    OTHER = "OTHER",
    ALL = "ALL",
}

export enum StaffType {
    NEUROPSYCHOLOGIST = "NEUROPSYCHOLOGIST",
    PSYCHOTHERAPIST = "PSYCHOTHERAPIST",
    DOCTOR = "DOCTOR",
}

export enum SystemParameters {
    AGE = "AGE",
    SCHOOL_YEARS = "SCHOOL_YEARS",
    TEST_DURATION = "TEST_DURATION",
}

export enum TrueResultEnum {
    VERY_MUCH_BELOW_STANDARD = "VERY_MUCH_BELOW_STANDARD",
    MUCH_BELOW_STANDARD = "MUCH_BELOW_STANDARD",
    BELOW_STANDARD = "BELOW_STANDARD",
    STANDARD = "STANDARD",
    ABOVE_STANDARD = "ABOVE_STANDARD",
}

export enum TrueScoreResultType {
    ZVALUES = "ZVALUES",
    TVALUES = "TVALUES",
    PVALUES = "PVALUES",
    CVALUES = "CVALUES",
}

export enum UnitType {
    MINUTES = "MINUTES",
    SECONDS = "SECONDS",
    NUMBER = "NUMBER",
    RAW_VALUE = "RAW_VALUE",
    SUM = "SUM",
    FORMULA = "FORMULA",
}

export enum WildcardPosition {
    SUFFIX = "SUFFIX",
    PREFIX = "PREFIX",
    SUFFIX_AND_PREFIX = "SUFFIX_AND_PREFIX",
}
