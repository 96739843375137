import { useAuthContext } from 'auth/AuthContext';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

interface Props extends RouteProps {
  component: typeof React.Component | React.FunctionComponent<any>;
  exact?: boolean;
}

const ProtectedRoute = ({component, exact = false, ...rest}: Props) => {
  const {isAuthenticated} = useAuthContext();
  const Component = component; // JSX needs capital letter, lower case in props to keep it consistent with react-router
  return (
      <Route
          exact={exact}
          render={
            props => {
              if (!isAuthenticated) {
                return <Redirect to="/login" />;
              }
              return <Component {...props} />;
            }
          }
          {...rest}
      />
  );
};

export default ProtectedRoute;
