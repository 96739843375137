import axios, { CancelTokenSource } from 'axios';
import { backendUrls } from 'service/http';
import { DomainDto, DomainSearchRequest, UpsertDomainDto } from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/domains`;

export const getDomains = (active: boolean | undefined, source: CancelTokenSource): Promise<Array<DomainDto>> => {
  return axios
  .get(apiUri, { params: active !== undefined ? { active: active } : {}, cancelToken: source.token })
  .then(response => response.data);
};

export const getDomainById = (domainId: number, source: CancelTokenSource): Promise<UpsertDomainDto> => {
  return axios
  .get(`${apiUri}/${domainId}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const searchDomains = (
  request: Partial<DomainSearchRequest>,
  source: CancelTokenSource): Promise<DomainDto[]> => {
  return axios
  .post(`${apiUri}/search`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const addDomain = (upsertDomainDto: Partial<UpsertDomainDto>, source: CancelTokenSource): Promise<DomainDto> => {
  return axios
  .post(apiUri, upsertDomainDto, { cancelToken: source.token })
  .then(response => response.data);
};

export const editDomain = (domainId: number, upsertDomainDto: Partial<UpsertDomainDto>,
                           source: CancelTokenSource): Promise<UpsertDomainDto> => {
  return axios
  .put(`${apiUri}/${domainId}`, upsertDomainDto, { cancelToken: source.token })
  .then(response => response.data);
};

export const deleteDomainById = (domainId: number, source: CancelTokenSource): Promise<DomainDto> => {

  return axios
  .delete(`${apiUri}/${domainId}`, { cancelToken: source.token })
  .then(response => response.data);
};
