import axios from 'axios';
import CheckBox from 'components/final-form/CheckBox';
import FinalFormColorPicker from 'components/final-form/ColorPicker';
import DataLabel from 'components/final-form/DataLabel';
import Input from 'components/final-form/Input';
import MultiLanguageInput from 'components/final-form/MultiLanguageInput';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import { stringArrayToDropdownOptionArray } from 'components/final-form/Select';
import { selectInput } from 'components/final-form/SelectComponent';
import { SelectFieldProps } from 'components/final-form/SelectInput';
import CompositeButton from 'components/final-form/CompositeButton';
import { textAreaInput } from 'components/final-form/TextAreaInput';
import { InputFieldProps, textInput } from 'components/final-form/TextInput';
import TopRowEntityColorPicker from 'components/final-form/TopRowEntityColorPicker';
import InnerTsaGrid from 'components/InnerTsaGrid';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import { FormApi } from 'final-form';
import { noop } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Button, Container, Grid, Header } from 'semantic-ui-react';
import { addTestResultParam, editTestResultParam, getTestResultParamById } from 'service/testResultParamServices';
import styled from 'styled-components';
import { ScopeType, UnitType } from 'ts-types/api.enums';
import { TestResultParamDto, UpsertTestResultParamDto } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { composeValidators, mustBeNumber, parameterValidator, required } from 'util/validatorUtils';

const ContentWrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  padding-bottom: 2rem;

  .page-header {
    margin-bottom: 4rem;
  }

  .ui.button + .ui.button {
    margin-left: 1rem;
  }
`;

const ColorPickerWrapper = styled.div`
  display: flex;

  div:first-child {
    flex: 1 1 auto;
  }

  & > .color-picker-box {
    padding-right: 0px;
    margin-left: 15px;
  }
`;

const StyledColorPicker = styled.div`
  .choose-color-swatch .choose-color-swatch-preview {
    width: 36px;
    height: 25px !important;
    border-radius: 2px;
  }
`;

interface Props {

}

const UpsertTestResultParamForm = (props: Props) => {

  const cancelTokenSource = useMemo(axios.CancelToken.source, []);

  const { t } = useTranslation('teresa');
  const { push } = useHistory();
  const { state } = useLocation();
  const testResultParamId: number | undefined = state?.id ? Number(state.id) : undefined;
  const isCopy: boolean | undefined = state?.copy ? Boolean(state.copy) : undefined;

  const initialTestResultParamValues: Partial<TestResultParamDto> = {
    active: true,
  };

  const [testResultParam, setTestResultParam] = React.useState<Partial<TestResultParamDto> | undefined>(initialTestResultParamValues);
  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(true);
  const [successMsg, setSuccessMsg] = React.useState<string | undefined>(undefined);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const scopeType: ScopeType = state.scopeType ? state.scopeType : undefined;
  const prevPath: string = state?.prevPath ? state?.prevPath : '/';
  const unitLabels: Array<string> = Object.values(UnitType);

  const units = useMemo(() =>
    stringArrayToDropdownOptionArray(unitLabels, t, 'unit', unitLabels), []);

  useEffect(() => {
    if (testResultParamId) {
      setFormDataLoaded(false);
      getTestResultParamById(testResultParamId, cancelTokenSource)
      .then(response => {
        const upsertTestResultDto: Partial<TestResultParamDto> = {
          ...response,
        };
        setTestResultParam(upsertTestResultDto);
      })
      .catch(noop)
      .finally(() => setFormDataLoaded(true));
    }
  }, []);

  const handleError = useCallback((error: any) => {

    if (error) {
      let errMsgs = [];
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.invalidCode,
        errorUtils.invalidInput,
        errorUtils.testResultParamNotFound,
        errorUtils.minMaxValueInvalidValues,
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsgs.push(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errMsgs.length) {
        if (knownErrors.includes(errorCode)) {
          errMsgs.push(t(`error.${errorCode}`));
        } else {
          errMsgs.push(t('error.general'));
        }
      }

      setErrorMessages(errMsgs);
    }

  }, []);


  const setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const handleSubmit = useCallback(async (values: Partial<TestResultParamDto>) => {

    let saveValues: Partial<UpsertTestResultParamDto> = {
      ...values,
      scopeType: scopeType,
    };

    const onSave = () => {
      testResultParamId && !isCopy
        ? setSuccessMsg(t('testresultparam.onUpdate'))
        : setSuccessMsg(t('testresultparam.onAdd'));
      setTimeout(() => {
        goToPreviousPage();
      }, 1200);
    };

    if (testResultParamId && !isCopy) {
      await editTestResultParam(testResultParamId, saveValues, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e.response.data));
    } else {
      await addTestResultParam(saveValues, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e.response.data));
    }


  }, []);


  const goToPreviousPage = useCallback(() => {
    let testResultParamSearch = '/testresultparam/search';

    push(testResultParamSearch);
    push(prevPath);

  }, []);

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={(values: UpsertTestResultParamDto) => handleSubmit(values)}
        initialValues={testResultParam}
        subscription={{ pristine: true, values: true, submitting: true }}
        validate={values => {
          const errors: any = {};
          if (values.minimumValue !== undefined && values.minimumValue !== null && values.maximumValue !== undefined && values.maximumValue !== null) {
            if (parseFloat(values.minimumValue.toString()) > parseFloat(values.maximumValue.toString())) {
              errors.minimumValue = 'must be smaller then max';
              errors.maximumValue = 'must be bigger then min';
            }
          }
          return errors;
        }}
        render={(formProps: FormRenderProps<UpsertTestResultParamDto>) => renderFormContent(formProps)}
      />
    );
  };

  const renderTestResultSections = (form: FormApi<UpsertTestResultParamDto>, values: UpsertTestResultParamDto) => {

    const leftInfoFields = [
      textInput({
        name: 'code',
        validate: composeValidators(required, parameterValidator),
      }),
    ];

    let rightInfoFields: (SelectFieldProps | InputFieldProps)[] = [
      selectInput({
          name: 'unit',
          validate: required,
        },
        units,
      ),
    ];

    if (values.unit != UnitType.FORMULA && values.unit != UnitType.SUM) {
      const minimumValueField = textInput({
        name: 'minimumValue',
        validate: composeValidators(mustBeNumber),
      });
      const maximumValueField = textInput({
        name: 'maximumValue',
        validate: composeValidators(mustBeNumber),
      });
      rightInfoFields.push(minimumValueField);
      rightInfoFields.push(maximumValueField);
    } else if (values.unit == UnitType.FORMULA) {
      const formula = textAreaInput({
        name: 'formula',
        id: 'formula-input',
      })
      rightInfoFields.push(formula);
    }


    const mappedLeftRow = leftInfoFields.map((field, index) => {
      return (
        <React.Fragment key={index}>
          <Grid.Row className='scoreLabel'>
            <Grid.Column width={16} verticalAlign='middle'>
              <DataLabel>{t(`testresultparam.${field.name}`)}</DataLabel>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16} verticalAlign='middle' className='resultParam'>
              <Field
                fluid
                {...field}
              />
            </Grid.Column>
          </Grid.Row>
        </React.Fragment>
      );
    });

    const mappedRightRow = rightInfoFields.map((field, index) => {
      return (
        <React.Fragment key={index}>
          <Grid.Row className='scoreLabel'>
            <Grid.Column width={16} verticalAlign='middle'>
              <DataLabel>{t(`testresultparam.${field.name}`)}</DataLabel>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16} verticalAlign='middle'>
              <Field
                fluid
                {...field}
              />
            </Grid.Column>
          </Grid.Row>
        </React.Fragment>
      );
    });

    return (
      <Grid.Row>
        <Grid.Column width={6}>
          <InnerTsaGrid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header as='h4'>{t(`testresultparam.generalInfo`)}</Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className='scoreLabel'>
              <Grid.Column width={16} verticalAlign='middle'>
                <DataLabel>{t('testresultparam.description')}</DataLabel>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16} verticalAlign='middle' className='resultParam'>
                <MultiLanguageInput fieldName='description' form={form} requiredFirst={true} />
              </Grid.Column>
            </Grid.Row>

            {mappedLeftRow}

            {scopeType === ScopeType.REHAB ?
              <Grid.Row>
                <Grid.Column width={16}>
                  <DataLabel>{t('testresultparam.paramColor')}</DataLabel>
                  <ColorPickerWrapper>
                    <Field
                      id='paramColorInput'
                      name='paramColor'
                      component={Input}
                    />
                    <TopRowEntityColorPicker>
                      <StyledColorPicker>
                        <Field
                          id='paramColor'
                          name='paramColor'
                          component={FinalFormColorPicker}
                        />
                      </StyledColorPicker>
                    </TopRowEntityColorPicker>
                  </ColorPickerWrapper>
                </Grid.Column>
              </Grid.Row>
              : <></>
            }

            <Grid.Row>
              <Grid.Column width={16} textAlign='left'>
                <DataLabel>{t('testresultparam.status')}</DataLabel>
                <Field
                  name='active'
                  component={CheckBox}
                  label={t('testresultparam.active')}
                  toggle
                />
              </Grid.Column>
            </Grid.Row>

          </InnerTsaGrid>
        </Grid.Column>
        <Grid.Column width={6}>
          <InnerTsaGrid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header as='h4'>{t(`testresultparam.typeValues`)}</Header>
              </Grid.Column>
            </Grid.Row>
            {mappedRightRow}
          </InnerTsaGrid>
        </Grid.Column>
      </Grid.Row>
    );
  };

  const renderFormContent = ({
                               values,
                               handleSubmit,
                               submitting,
                               form,
                             }: FormRenderProps<UpsertTestResultParamDto>): React.ReactNode => {
    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          <Grid.Row>
            <Grid.Column width={16}>
              {
                scopeType === ScopeType.TEST ?
                  <div className='title-h1'>{t('testresultparam.viewTitleUpdateTEST')}</div>
                  :
                  <div className='title-h1'>{t('testresultparam.viewTitleUpdateREHAB')}</div>
              }

              {
                errorMessages.length > 0 &&
                <Grid.Row>
                  <Grid.Column width={12}>
                    <div className='error'>
                      <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                        {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                      </StyledErrorMessage>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              }

            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16}>
              <Grid stackable doubling columns={2}>
                {renderTestResultSections(form, values)}

                <Grid.Row>
                  <Grid.Column width={12}
                               style={{ borderTop: '1px solid var(--very-light-blue)', marginTop: '2rem' }}
                  >
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row textAlign={'right'}>
            <Grid.Column width={12} style={{ paddingRight: '0' }}>
              {
                successMsg &&
                <SaveAndUpdateConfirmationMessage>
                  {successMsg}
                </SaveAndUpdateConfirmationMessage>
              }
              <CompositeButton
                primary
                type='submit'
                className='action-button'
                disabled={submitting || !!successMsg}
                onClick={noop}
              >
                {t('button.save')}
              </CompositeButton>
              <CompositeButton
                type='button'
                className='action-button'
                secondary
                disabled={submitting || !!successMsg}
                onClick={goToPreviousPage}
              >
                {t('action.back')}
              </CompositeButton>
            </Grid.Column>
          </Grid.Row>
        </TsaGrid>
      </form>
    );
  };

  return (
    <div>
      <Container fluid>
        <ContentWrapperDiv>
          {formDataLoaded
            ? <React.Fragment>
              {renderFinalForm()}
            </React.Fragment>
            : <LoaderComponent message={t('testresultparam.loading')} />
          }
        </ContentWrapperDiv>
      </Container>
    </div>
  );

};

export default UpsertTestResultParamForm;