import React from 'react';
import { FieldProps } from 'react-final-form';
import { RadioProps } from 'semantic-ui-react';
import RadioGroup from 'components/final-form/RadioGroup';

interface RadioGroupFieldProps {
  name: string,
  component: any,
  radioDefinitions?: Array<RadioProps>,
  radiowidth?: string
}

export const radioGroupInput = (fields: FieldProps<any, any>, radioDefinitions: Array<RadioProps>, radiowidth?: string): RadioGroupFieldProps => {
  return {
    name: fields.name,
    component: RadioGroup,
    radioDefinitions: radioDefinitions,
    radiowidth: radiowidth
  }
};
