import { useAuthContext } from 'auth/AuthContext';
import { AxiosError } from 'axios';
import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import Input from 'components/final-form/Input';
import CompositeButton from 'components/final-form/CompositeButton';
import TabComponent from 'components/final-form/TabComponent';
import InnerTableActionButton from 'components/InnerTableActionButton';
import SearchDisplayContainer from 'components/SearchDisplayContainer';
import StyledErrorMessage from 'components/StyledErrorMessage';
import VirtualizedTable from 'components/VirtualizedTable';
import _ from 'lodash';
import React, { useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Icon, Loader } from 'semantic-ui-react';
import axios from 'service/http';
import { deleteTestResultParamById, searchTestResultParam } from 'service/testResultParamServices';
import { debounce } from 'ts-debounce';
import { ScopeType } from 'ts-types/api.enums';
import {
  InvalidInputDto,
  ObjectValidationErrorDto,
  TestResultParamDto,
  TestResultParamSearchRequest,
} from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { useAfterFirstRender } from 'util/functionUtils';
import { emptyTableCell, multiLanguageFieldCellRenderer, shortLabel } from 'util/tableUtils';

const cancelTokenSource = axios.CancelToken.source();

interface Props {
  scopeType: ScopeType
}

const TestResultParamView = (props: Props) => {

  const { t } = useTranslation('teresa');
  const history = useHistory();
  const { language } = useAuthContext();
  const { pathname } = useLocation();

  const [testResultParamsLoaded, setTestResultParamsLoaded] = useState<boolean>(true);
  const [foundTestResultParams, setFoundTestResultParams] = useState<TestResultParamDto[]>([]);
  const [searchValues, setSearchValues] = useState<Partial<TestResultParamSearchRequest>>({
    searchKey: '',
  });

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const handleError = (error: AxiosError<InvalidInputDto>) => {

    const { response } = error;
    if (response) {
      const { data: { errorCode, violations } } = response;

      const knownErrors: Array<string> = [
        errorUtils.testResultParamNotFound,
        errorUtils.minMaxValueInvalidValues
      ];

      if (violations && violations.length > 0) {
        violations.forEach((violation: ObjectValidationErrorDto) => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      } else {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };

  const setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  React.useEffect(() => {
    fetchTestResultParams(searchValues);
  }, []);

  const fetchTestResultParams = debounce((values: Partial<TestResultParamSearchRequest>): void => {

    const onFinally = () => {
      setTestResultParamsLoaded(true);
    };

    setTestResultParamsLoaded(false);

    let newSearchValues = { ...values };
    if (_.isEmpty(newSearchValues)) {
      newSearchValues = {
        searchKey: '',
      };
    }

    newSearchValues.scopeType = props.scopeType;

    searchTestResultParam(newSearchValues, cancelTokenSource)
    .then(response => {
        setFoundTestResultParams(response);
      },
    )
    .catch(handleError)
    .finally(onFinally);

  }, 300);

  const deleteTestResultParam = (id: number, values: any) => (): void => {
    deleteTestResultParamById(id, cancelTokenSource).then(() => {
      fetchTestResultParams(values);
    });
  };

  const testResultParamsRowGetter = ({ index }: any) => {

    Object.assign(foundTestResultParams[index], { index: index + 1 });

    return foundTestResultParams[index];
  };

  const testResultParamsRowRenderer = ({ className, columns, index, key, style, rowData }: any) => {
    const a11yProps = { 'aria-rowindex': index + 1 };

    const testResultParamId = foundTestResultParams[index].id;

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role='row'
        style={style}
        onDoubleClick={() => openTestResultParamDetails(testResultParamId!, rowData.paramColor, false)}
      >
        {columns}
      </div>
    );
  };

  const handleChange = useAfterFirstRender(({ values }: any): void => {
    setSearchValues(values);
    fetchTestResultParams(values);
  });


  const openTestResultParamDetails = (id: number, color: string, isCopy: boolean): void => {
    history.push( props.scopeType === ScopeType.REHAB ? '/rehabilitation-testresultparam' : '/testresultparam', {
      id: id,
      scopeType: props.scopeType,
      prevPath: pathname,
      color: color,
      copy: isCopy
    });
  };

  const unitCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return t(`unit.${cellData}`);
    }
    return emptyTableCell();
  };

  const minCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return t(`${cellData}`);
    }
    return emptyTableCell();
  };

  const maxCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return t(`${cellData}`);
    }
    return emptyTableCell();
  };

  const activeCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return <Icon name='check' size='small' />;
    }
    return emptyTableCell();
  };

  const numberCellRenderer = ({ rowData }: any) => {

    return (
      <div>{rowData?.numberOfTests}</div>
    )
  }

  const numberOfResultsCellRenderer = ({ rowData }: any) => {
    return (
      <div>{rowData?.numberOfTestResults}</div>
    )
  }

  const paramColorCellRenderer = ({ rowData }: any) => {
    if (rowData.paramColor) {
      return (<div>
      <span title={`${rowData.paramColor.toUpperCase()}`}>
        <Icon name='square full' style={{ color: `${rowData.paramColor}` }} />
      </span>
          {rowData.paramColor.toUpperCase()}
        </div>
      )
    } else {
      return emptyTableCell();
    }


  }

  const translateDescription = (resultParam: TestResultParamDto) => {
    const description = resultParam.description;

    const resultParamDescriptions: { [key: string]: string } = {
      'de': resultParam.description || description,
      'en': resultParam.descriptionEn || description,
      'fr': resultParam.descriptionFr || description,
      'it': resultParam.descriptionIt || description,
    };
    return resultParamDescriptions[language];
  }

  const resultParamActionCellRenderer = (values?: any) => ({ rowData }: any) => {

    const disable = rowData.numberOfTests > 0 || rowData.numberOfTestResults > 0;

    return (
      <div className='row-actions'>
        <InnerTableActionButton
          message={t('button.edit')}
          onConfirm={() => openTestResultParamDetails(rowData.id, rowData.paramColor, false)}
          divider={true}
        />
        <InnerTableActionButton
          message={t('button.copy')}
          onConfirm={() => openTestResultParamDetails(rowData.id, rowData.paramColor, true)}
          divider={true}
        />
        <DeleteRecordConfirmation triggerButtonText={t('button.delete')}
                                  confirmAction={deleteTestResultParam(rowData.id, values)}
                                  disabled={disable}
                                  deleteConfirmationText={t('testresultparam.confirmDelete', { description: translateDescription(rowData) })}
                                  position={"top left"} />
      </div>
    );
  };

  const renderTestResultParamTable = (values: any): JSX.Element => {
    let columns: any = [
      {
        width: 50,
        flexShrink: 0,
        label: t('testresultparam.id'),
        dataKey: 'id',
      },
      {
        width: 400,
        flexGrow: 1,
        label: t('testresultparam.description'),
        dataKey: 'description',
        cellRenderer: multiLanguageFieldCellRenderer('description', language)
      },
      {
        width: 150,
        flexGrow: 1,
        label: t('testresultparam.code'),
        dataKey: 'code',
      },
      {
        width: 150,
        label: t('testresultparam.unit'),
        dataKey: 'unit',
        cellRenderer: (unitCellRenderer)
      },
      {
        width: 150,
        label: t('testresultparam.minimumValue'),
        dataKey: 'minimumValue',
        cellRenderer: (minCellRenderer)
      },
      {
        width: 150,
        label: t('testresultparam.maximumValue'),
        dataKey: 'maximumValue',
        cellRenderer: (maxCellRenderer)
      },
    ];

    if (props.scopeType === ScopeType.REHAB) {
      columns.push({
        width: 200,
        flexGrow: 1,
        label: t('testresultparam.paramColor'),
        dataKey: 'paramColor',
        cellRenderer: (paramColorCellRenderer),
      })
    }

    columns.push({
        width: 100,
        label: props.scopeType === ScopeType.TEST ?
          shortLabel(t('testresultparam.testConf'), t('testresultparam.shortTestConf')) :
          shortLabel(t('testresultparam.exerciseConf'), t('testresultparam.shortExerciseConf')),
        dataKey: 'numberOfTests',
        cellRenderer: (numberCellRenderer),
      },
      {
        width: 100,
        label: props.scopeType === ScopeType.TEST ?
          shortLabel(t('testresultparam.testResultConf'), t('testresultparam.shortTestResultConf')) :
          shortLabel(t('testresultparam.exerciseResultConf'), t('testresultparam.shortExerciseResultConf')),
        dataKey: 'numberOfTestResults',
        cellRenderer: (numberOfResultsCellRenderer),
      },
      {
        width: 100,
        label: t('testresultparam.status'),
        dataKey: 'active',
        cellRenderer: (activeCellRenderer),
      },
      {
        width: 200,
        flexShrink: 0,
        label: t('testresultparam.actions'),
        dataKey: 'id',
        cellRenderer: (resultParamActionCellRenderer(values)),
      });

    return (
      <VirtualizedTable
        rowCount={foundTestResultParams.length}
        rowGetter={testResultParamsRowGetter}
        rowRenderer={testResultParamsRowRenderer}
        columns={columns}
      />
    );
  };

  const renderResultParamForm = (): JSX.Element => {
    return (
      <FinalForm
        onSubmit={() => {
        }}
        initialValues={{
          active: undefined
        }}
        subscription={{ pristine: true, values: true }}
        render={renderResultParamSearchForm}
      />
    );
  }

  const renderResultParamSearchForm = ({ values }: FormRenderProps): React.ReactNode => {

    return (
      <>
        <div className='search-container'>

          {
            errorMessages.length > 0 &&
            <Grid.Row>
              <Grid.Column width={16}>
                <div className='error'>
                  <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                    {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                  </StyledErrorMessage>
                </div>
              </Grid.Column>
            </Grid.Row>
          }

          <div className='title-h1'>{t(`testresultparam.viewTitle${props.scopeType}`)}</div>

          <div className='search-form'>
            <Field
              id='searchKey'
              name='searchKey'
              component={Input}
              icon={'search'}
              iconPosition='left'
              placeholder={t(`testresultparam.placeholder${props.scopeType}`)}
              autoFocus
              fluid
            />

            <CompositeButton
              type='button'
              className='action-button'
              onClick={() => history.push(props.scopeType === ScopeType.REHAB ? '/rehabilitation-testresultparam' : '/testresultparam', { prevPath: pathname, scopeType: props.scopeType })}
              primary
              style={{ display: 'inline-block' }}
            >
              {t('button.add')}
            </CompositeButton>
          </div>
        </div>


        <Field
          id='active'
          name='active'
          component={TabComponent}
        />

        <div className='data-table'>
          {testResultParamsLoaded && renderTestResultParamTable(values)}
          {!testResultParamsLoaded &&
            <Loader className='table-loader' active inline content={t('testresultparam.loading')} />}
        </div>

        <FormSpy subscription={{ values: true }} onChange={handleChange} />
      </>
    );

  };

  return <SearchDisplayContainer>
    {renderResultParamForm()}
  </SearchDisplayContainer>;

};

export default TestResultParamView;