import { useAuthContext } from 'auth/AuthContext';
import ImageDropZone from 'components/final-form/ImageDropZone';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import { Menu, Tab } from 'semantic-ui-react';
import styled from 'styled-components';
import { required } from 'util/validatorUtils';

const StyledTabMenu = styled(Tab)`
  & .ui.menu {
    margin-bottom: 0;
    min-height: 1.857143em;
  }

  & .ui.secondary.menu .item {
    border-radius: unset;
  }

  & .ui.secondary.menu .active.item {
    border: 1px solid var(--very-very-light-blue);
    border-bottom: unset;
    font-weight: 600;
    opacity: 1;
  }

  & .ui.attached.segment {
    margin: unset;
    width: unset;
    max-width: unset;
    box-shadow: none;
    border: none;
    background-color: white;
  }

  & .ui.segment {
    padding: 0;
    background: unset;
  }
`;

interface Props {
  fieldName: string;
  form: any;
  requiredFirst?: boolean;
}

const MultiLanguageImageUpload = (props: Props) => {

  const { fieldName, form, requiredFirst = false } = props;
  const { language } = useAuthContext();

  const formState = form.getState();
  const formValues = formState.values;

  const languages = ['en', 'fr', 'it'];
  let fields = languages.map(l => {
    return { language: l, name: `${fieldName}${_.capitalize(l)}` };
  });

  fields.unshift({ language: 'de', name: `${fieldName}` });

  const [activeIndex, setActiveIndex] = useState<number>(0);

  useMemo(() => {
    if (language !== 'de') {
      setActiveIndex(languages.findIndex(l => l === language) + 1);
    }
  }, []);

  const focusInputOnClick = (elementId: string) => {
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        element.focus();
      }
    }, 1);
  };

  const handleTabChange = (e: any, data: any) => {
    setActiveIndex(data.activeIndex);
  };

  const panes = fields.map((field, index) => {
    const imageValue = formValues[`${field.name}`];

    let style: any = {
      opacity: _.isEmpty(imageValue) && activeIndex !== index ? '.5' : 1,
    };

    if (formState.errors[fieldName] && requiredFirst && index === 0) {
      style = {
        backgroundColor: 'var(--very-very-light-red)',
        borderColor: 'var(--dark-red)',
        color: 'var(--dark-red)',
      };
    }

    const id = `input-${field.name}`;

    const imageViewFieldName = `imageView${field.language === 'de' ? '' : _.capitalize(field.language)}`;

    const imageView = formValues[imageViewFieldName];

    return (
      {
        menuItem: <Menu.Item
          key={id}
          style={style}
          onClick={(e) => focusInputOnClick(id)}
        >
          {field.language}
        </Menu.Item>,
        pane: <Tab.Pane key={`tab-${id}`}>
          <Field name={field.name}
                 id={id}
                 component={ImageDropZone}
                 imageView={imageView}
                 removeImageView={() => {
                   form.change(imageViewFieldName, undefined);
                   form.change(field.name, undefined);
                 }
                 }
                 validate={index === 0 && requiredFirst ? required : undefined}
          />
        </Tab.Pane>,
      }
    );
  });

  return (
    <StyledTabMenu
      menu={{ secondary: true }}
      panes={panes}
      renderActiveOnly={false}
      defaultActiveIndex={activeIndex}
      onTabChange={handleTabChange}
    />
  );
};

export default MultiLanguageImageUpload;