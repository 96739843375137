import { CancelTokenSource } from 'axios';
import { getAuthorizationHeaderValue } from 'service/browserStorageServices';
import axios, { backendUrls } from 'service/http';
import { UserDto } from 'ts-types/api.types';
import { isDevEnv } from 'util/appUtils';

const rootUrl = backendUrls.root;
const apiUri = `${backendUrls.apiRoot}/current-user`;

let devEnv = isDevEnv();
const textUrls = {
  privateRoot: devEnv ? `${rootUrl}/res/dev-locale` : `${rootUrl}/res/locale`,
  publicRoot: devEnv ? `${rootUrl}/pub/res/dev-locale` : `${rootUrl}/pub/res/locale`,
};

export const retrieveAccessToken = (username: string, password: string, verificationCode?: string) => {
  return axios({
    url: `${backendUrls.root}/auth`,
    method: 'post',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    data: {
      username: username,
      password: password,
      verificationCode: verificationCode || ""
    },
  });
};

export const getCurrentUser = (): Promise<UserDto> => {
  return axios.get(apiUri).then(response => response.data);
};

export const loadLocaleResources = (
  url: string, isPrivateResource: boolean) => {

  const config: any = {
    url: `${textUrls.publicRoot}/${url}`,
    method: 'get',
  };

  if (isPrivateResource) {
    config.url = `${textUrls.privateRoot}/${url}`;
    config.headers = {
      'Authorization': getAuthorizationHeaderValue(),
    };
  }

  return axios(config);
};

export const get2faData = (
  cancelSourceToken: CancelTokenSource): Promise<string> => {

  return axios.get(
    `${apiUri}/mfa`,
    {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const enable2fa = (
  code: string,
  cancelSourceToken: CancelTokenSource): Promise<string> => {

  const formData = new FormData();
  formData.set("code", code);
  return axios.post(
    `${apiUri}/mfa/enable`,
    formData,
    {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};
