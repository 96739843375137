import axios, { CancelTokenSource } from 'axios';
import { backendUrls } from 'service/http';
import { UpsertMandateBankDto } from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/mandatebank`;

export const getMandateBanksForClinicMandate = (clinicId: number, source: CancelTokenSource): Promise<Array<UpsertMandateBankDto>> => {
  return axios
  .get(`${apiUri}/all-for-clinic-mandate/${clinicId}`, {cancelToken: source.token})
  .then(response => response.data);
}