import { CancelTokenSource } from 'axios';
import {
  QuestionSetChartDto,
  QuestionSetDto,
  QuestionSetSearchRequest, QuestionSetsPreview, UpsertQuestionSetDto, UpsertQuestionSetRequest,
} from 'ts-types/api.types';
import axios, { backendUrls } from './http';

const apiUri = `${backendUrls.apiRoot}/question-set`;

export const getAllQuestionSets = (source: CancelTokenSource): Promise<Array<QuestionSetDto>> => {
  return axios
      .get(`${apiUri}`, {cancelToken: source.token})
      .then(response => response.data);
};

export const findQuestionSetById = (id: number, source: CancelTokenSource): Promise<QuestionSetDto> => {
  return axios
      .get(`${apiUri}/${id}`, {cancelToken: source.token})
      .then(response => response.data);
}



export const searchQuestionSet = (
  request: Partial<QuestionSetSearchRequest>,
  source: CancelTokenSource): Promise<QuestionSetDto[]> => {

  return axios
  .post(`${apiUri}/search`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const addQuestionSet = (
    questionSetDto: Partial<QuestionSetDto>,
    source: CancelTokenSource
): Promise<QuestionSetDto> => {
  return axios
      .post(`${apiUri}/`, questionSetDto, {cancelToken: source.token})
      .then(response => response.data);
}

export const editQuestionSet = (
    id: number,
    questionSetDto: Partial<QuestionSetDto>,
    source: CancelTokenSource
): Promise<QuestionSetDto> => {
  return axios
      .put(`${apiUri}/${id}`, questionSetDto, {cancelToken: source.token})
      .then(response => response.data);
};

export const deleteQuestionSet = (id: number, source: CancelTokenSource): Promise<QuestionSetDto> => {
  return axios
      .delete(`${apiUri}/${id}`, {cancelToken: source.token})
      .then(response => response.data)
};
