import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import ImageDropZone from 'components/final-form/ImageDropZone';
import { generateRadioButtonOptions } from 'components/final-form/Radio';
import RadioGroup from 'components/final-form/RadioGroup';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import Select, { stringArrayToDropdownOptionArray } from 'components/final-form/Select';
import FormModalContentContainer from 'components/FormModalContentContainer';
import InnerFormTable from 'components/InnerFormTable';
import InnerTableActionButton from 'components/InnerTableActionButton';
import InnerTsaGrid from 'components/InnerTsaGrid';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import { useClinicInfoContext } from 'context/ClinicInfoContext';
import { FormApi } from 'final-form';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import ClinicConfigTabComponent from 'routes/clinic/ClinicConfigTabComponent';
import UniversalAddModal from 'routes/clinic/UniversalAddModal';
import { Grid, Modal, RadioProps } from 'semantic-ui-react';
import {
  getAllExerciseTemplates,
  getAllQuestionSets,
  getAllTestTemplates,
  getExerciseConfs,
  getTestConfs,
} from 'service/clinicAdminConfService';
import {
  deleteClinicExerciseConf,
  deleteClinicExerciseTemplate,
  deleteClinicQuestionSet,
  deleteClinicTestConf,
  deleteClinicTestTemplate,
  getClinicConfigData,
  saveClinicExerciseConf,
  saveClinicExerciseTemplate,
  saveClinicQuestionSet,
  saveClinicTestConf,
  saveClinicTestTemplate,
} from 'service/clinicConfigService';
import { getClinicConf, saveClinicConf } from 'service/clinicConfService';
import styled from 'styled-components';
import { HideNumbers, InvoiceConfigType, ScopeType } from 'ts-types/api.enums';
import {
  ClinicConfDto,
  ClinicConfigDto,
  ClinicConfigRequestDto,
  ExerciseConfDto,
  ExerciseTemplateDto,
  QuestionSetDto,
  TestConfDto,
  TestTemplateDto,
} from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { noop } from 'util/functionUtils';
import { multiLanguageInnerTableCellRenderer } from 'util/tableUtils';

const ActionContainer = styled.div`
    flex-grow: 1;
    border-radius: unset;
    margin-left: 5px;
    padding-top: 34px;
`;

export enum ModalSearchType {
  TEST_CONF = 'TEST_CONF',
  TEST_TEMPLATE = 'TEST_TEMPLATE',
  EXERCISE_CONF = 'EXERCISE_CONF',
  EXERCISE_TEMPLATE = 'EXERCISE_TEMPLATE',
  QUESTION_SET = 'QUESTION_SET'
}

export enum ConfigView {
  GENERAL = 'general',
  TESTING = 'test',
  REHABILITATION = 'exercise'
}

interface Props {
}

const cancelTokenSource = axios.CancelToken.source();

const ClinicConfigView = (props: Props) => {

  const { state } = useLocation();
  const { language, currentUser } = useAuthContext();
  const { setClinicId, clinicInfo } = useClinicInfoContext();
  const isIpad = useIsIpadWidthOrBelow();

  const clinicId: number | undefined = state?.clinicId ? Number(state?.clinicId) : undefined;
  const prevPath: string | undefined = state?.prevPath ? state.prevPath : undefined;
  const { t } = useTranslation('teresa');
  const history = useHistory();
  const invoicePackageTypeLabels: Array<string> = useMemo(() => Object.values(InvoiceConfigType), []);

  const invoicePackageTypes = useMemo(() =>
    stringArrayToDropdownOptionArray(invoicePackageTypeLabels, t, 'invoicePackageType'), []);
  const hideNumbersLabels: Array<HideNumbers> = Object.values(HideNumbers);
  const hideNumbersDefinitions  =  generateRadioButtonOptions( hideNumbersLabels,
    "hideNumber", t, 'previewResult.hideNumber', hideNumbersLabels)

  const initialClinicConfValues: Partial<ClinicConfDto> = {
    hideNumbers: HideNumbers.DONT_HIDE,
  };

  const [activeView, setActiveView] = React.useState<ConfigView>(ConfigView.GENERAL);
  const [clinicConfig, setClinicConfig] = React.useState<ClinicConfigDto | undefined>(undefined);
  const [clinicConf, setClinicConf] = React.useState<Partial<ClinicConfDto> | undefined>(initialClinicConfValues);
  const [testConfs, setTestConfs] = React.useState<TestConfDto[]>([]);
  const [testTemplates, setTestTemplates] = React.useState<TestTemplateDto[]>([]);
  const [questionSets, setQuestionSets] = React.useState<QuestionSetDto[]>([]);
  const [exerciseConfs, setExerciseConfs] = React.useState<ExerciseConfDto[]>([]);
  const [exerciseTemplates, setExerciseTemplates] = React.useState<ExerciseTemplateDto[]>([]);
  const [modalTestConfOpen, setModalTestConfOpen] = React.useState<boolean>(false);
  const [modalTestTemplateOpen, setModalTestTemplateOpen] = React.useState<boolean>(false);
  const [modalQuestionSetOpen, setModalQuestionSetOpen] = React.useState<boolean>(false);

  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(true);
  const [successMsg, setSuccessMsg] = React.useState<string | undefined>(undefined);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (entityId?: number) => {

    try {

      setFormDataLoaded(false);

      if (clinicId) {
        const clinicConfigData = await getClinicConfigData(clinicId, cancelTokenSource);
        const clinicConfData = await getClinicConf(clinicId, cancelTokenSource);
        const testConfList = await getTestConfs(cancelTokenSource);
        const testTemplateList = await getAllTestTemplates(cancelTokenSource);
        const exerciseConfList = await getExerciseConfs(cancelTokenSource);
        const exerciseTemplateList = await getAllExerciseTemplates(cancelTokenSource);
        const questionSetList = await getAllQuestionSets(cancelTokenSource);

        setClinicId(clinicId);

        setClinicConfig(clinicConfigData);
        setClinicConf(clinicConfData);
        setTestConfs(testConfList);
        setTestTemplates(testTemplateList);
        setExerciseConfs(exerciseConfList);
        setExerciseTemplates(exerciseTemplateList);
        setQuestionSets(questionSetList);
      }

    } catch (e) {
      handleError(e.response.data);
    } finally {
      setFormDataLoaded(true);
    }
  };

  const handleError = useCallback((error: any) => {

    if (error) {
      let errMsgs = [];
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsgs.push(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          errMsgs.push(t(`error.${errorCode}`));
        } else {
          errMsgs.push(t('error.general'));
        }
      }

      setErrorMessage(errMsgs);
    }

  }, []);

  const setErrorMessage = (errMsgs?: string[]) => {

    if (errMsgs) {
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const getFineInvalidTypeError = (errorCode: string) => {
    if (errorCode === 'file-invalid-type') {
      setErrorMessage([t(`error.${errorUtils.fileTypeInvalid}`)]);
    }
  };

  const onAddConf = async (confId: number) => {

    let configData = { ...clinicConfig! };

    const request: ClinicConfigRequestDto = {
      clinicId: clinicId!,
      entityId: confId,
    };

    if (ConfigView.TESTING === activeView) {
      const response = await saveClinicTestConf(request, cancelTokenSource);

      configData.testConfIds.push(confId);
      setClinicConfig(configData);

    } else {
      const response = await saveClinicExerciseConf(request, cancelTokenSource);

      configData.exerciseConfIds.push(confId);
      setClinicConfig(configData);
    }
  };

  const onRemoveConf = async (confId: number) => {

    try {

      let configData = { ...clinicConfig! };

      const request: ClinicConfigRequestDto = {
        clinicId: clinicId!,
        entityId: confId,
      };

      if (ConfigView.TESTING === activeView) {
        const response = await deleteClinicTestConf(request, cancelTokenSource);

        _.remove(configData.testConfIds, (id) => id === confId);
        setClinicConfig(configData);

      } else {
        const response = await deleteClinicExerciseConf(request, cancelTokenSource);

        _.remove(configData.exerciseConfIds, (id) => id === confId);
        setClinicConfig(configData);
      }

    } catch (e) {
      handleError(e.response.data);
    }
  };

  const onAddTemplate = async (templateId: number) => {

    let configData = { ...clinicConfig! };

    const request: ClinicConfigRequestDto = {
      clinicId: clinicId!,
      entityId: templateId,
    };

    if (ConfigView.TESTING === activeView) {
      const response = await saveClinicTestTemplate(request, cancelTokenSource);

      configData.testTemplateIds.push(templateId);
      setClinicConfig(configData);
    } else {
      const response = await saveClinicExerciseTemplate(request, cancelTokenSource);

      configData.exerciseTemplateIds.push(templateId);
      setClinicConfig(configData);
    }
  };

  const onRemoveTemplate = async (testTemplateId: number) => {

    try {

      let configData = { ...clinicConfig! };

      const request: ClinicConfigRequestDto = {
        clinicId: clinicId!,
        entityId: testTemplateId,
      };

      if (ConfigView.TESTING === activeView) {
        const response = await deleteClinicTestTemplate(request, cancelTokenSource);

        _.remove(configData.testTemplateIds, (id) => id === testTemplateId);
        setClinicConfig(configData);
      } else {
        const response = await deleteClinicExerciseTemplate(request, cancelTokenSource);

        _.remove(configData.exerciseTemplateIds, (id) => id === testTemplateId);
        setClinicConfig(configData);
      }

    } catch (e) {
      handleError(e.response.data);
    }
  };

  const onAddQuestionSet = async (questionSetId: number) => {

    let configData = { ...clinicConfig! };

    const request: ClinicConfigRequestDto = {
      clinicId: clinicId!,
      entityId: questionSetId,
    };

    const response = await saveClinicQuestionSet(request, cancelTokenSource);

    if (ConfigView.TESTING === activeView) {
      configData.testQuestionSetIds.push(questionSetId);
      setClinicConfig(configData);
    } else {
      configData.exerciseQuestionSetIds.push(questionSetId);
      setClinicConfig(configData);
    }
  };

  const onRemoveQuestionSet = async (questionSetId: number) => {

    try {

      let configData = { ...clinicConfig! };

      const request: ClinicConfigRequestDto = {
        clinicId: clinicId!,
        entityId: questionSetId,
      };

      const response = await deleteClinicQuestionSet(request, cancelTokenSource);

      if (ConfigView.TESTING === activeView) {
        _.remove(configData.testQuestionSetIds, (id) => id === questionSetId);
        setClinicConfig(configData);
      } else {
        _.remove(configData.exerciseQuestionSetIds, (id) => id === questionSetId);
        setClinicConfig(configData);
      }


    } catch (e) {
      handleError(e.response.data);
    }
  };

  const translateDescription = (data: TestConfDto | TestTemplateDto) => {
    const description = data.description;

    const descriptions: { [key: string]: string } = {
      'de': data.description || description,
      'en': data.descriptionEn || description,
      'fr': data.descriptionFr || description,
      'it': data.descriptionIt || description,
    };
    return descriptions[language];
  };

  const translateName = (questionSetDto: QuestionSetDto) => {
    const name = questionSetDto.name;

    const questionSetNames: { [key: string]: string } = {
      'de': questionSetDto.name || name,
      'en': questionSetDto.nameEn || name,
      'fr': questionSetDto.nameFr || name,
      'it': questionSetDto.nameIt || name,
    };
    return questionSetNames[language];
  };

  const actionConfRenderer = (rowData: any) => {

    return <>
      <DeleteRecordConfirmation triggerButtonText={t('button.remove')}
                                confirmAction={() => onRemoveConf(rowData.id)}
                                deleteConfirmationText={t('clinic.config.confirmDelete',
                                  { description: translateDescription(rowData) })
                                }
      />
    </>;
  };

  const actionTemplateRenderer = (rowData: any) => {

    return <>
      <DeleteRecordConfirmation triggerButtonText={t('button.remove')}
                                confirmAction={() => onRemoveTemplate(rowData.id)}
                                deleteConfirmationText={t('clinic.config.confirmDelete',
                                  { description: translateDescription(rowData) })
                                }
      />
    </>;
  };

  const actionQuestionSetRenderer = (rowData: any) => {

    return <>
      <DeleteRecordConfirmation triggerButtonText={t('button.remove')}
                                confirmAction={() => onRemoveQuestionSet(rowData.id)}
                                deleteConfirmationText={t('clinic.config.confirmDelete',
                                  { description: translateName(rowData) })
                                }
      />
    </>;
  };

  const renderConfTable = (): React.ReactNode => {

    let filteredConfs: TestConfDto[] | ExerciseConfDto[] = [];
    if (clinicConfig) {
      if (ConfigView.TESTING === activeView && clinicConfig.testConfIds.length) {
        filteredConfs = testConfs.filter(conf => clinicConfig.testConfIds.includes(conf.id));
      } else if (ConfigView.REHABILITATION === activeView && clinicConfig.exerciseConfIds.length) {
        filteredConfs = exerciseConfs.filter(conf => clinicConfig.exerciseConfIds.includes(conf.id));
      }
    }

    return (
      <InnerFormTable id='test-conf'
                      numOfCells={2}
                      values={filteredConfs}
                      visibleRows={6}
                      columns={[
                        {
                          width: 2,
                          label: '#',
                          dataKey: 'id',
                        },
                        {
                          width: 10,
                          label: t('testconf.description'),
                          oneLine: true,
                          dataKey: 'description',
                          cellRenderer: multiLanguageInnerTableCellRenderer('description', language),
                        },
                        {
                          width: 3,
                          label: t('admin.actions'),
                          dataKey: 'id',
                          cellRenderer: actionConfRenderer,
                        },
                      ]} />
    );
  };

  const renderTestTemplateTable = (): React.ReactNode => {

    let filteredTemplates: TestTemplateDto[] | ExerciseTemplateDto[] = [];
    if (clinicConfig) {
      if (ConfigView.TESTING === activeView && clinicConfig.testTemplateIds.length) {
        filteredTemplates = testTemplates.filter(conf => clinicConfig.testTemplateIds.includes(conf.id));
      } else if (ConfigView.REHABILITATION === activeView && clinicConfig.exerciseTemplateIds.length) {
        filteredTemplates = exerciseTemplates.filter(conf => clinicConfig.exerciseTemplateIds.includes(conf.id));
      }
    }

    return (
      <InnerFormTable id='test-template'
                      numOfCells={2}
                      values={filteredTemplates}
                      visibleRows={6}
                      columns={[
                        {
                          width: 2,
                          label: '#',
                          dataKey: 'id',
                        },
                        {
                          width: 10,
                          label: t('testconf.description'),
                          oneLine: true,
                          dataKey: 'description',
                          cellRenderer: multiLanguageInnerTableCellRenderer('description', language),
                        },
                        {
                          width: 3,
                          label: t('admin.actions'),
                          dataKey: 'id',
                          cellRenderer: actionTemplateRenderer,
                        },
                      ]} />
    );
  };

  const renderQuestionSetTable = (): React.ReactNode => {

    let filteredQuestionSets: QuestionSetDto[] = [];
    if (clinicConfig) {
      if (ConfigView.TESTING === activeView && clinicConfig.testQuestionSetIds.length) {
        filteredQuestionSets = questionSets.filter(conf => clinicConfig.testQuestionSetIds.includes(conf.id));
      } else if (ConfigView.REHABILITATION === activeView && clinicConfig.exerciseQuestionSetIds.length) {
        filteredQuestionSets = questionSets.filter(conf => clinicConfig.exerciseQuestionSetIds.includes(conf.id));
      }
    }

    return (
      <InnerFormTable id='question-set'
                      numOfCells={2}
                      values={filteredQuestionSets}
                      visibleRows={6}
                      columns={[
                        {
                          width: 2,
                          label: '#',
                          dataKey: 'id',
                        },
                        {
                          width: 10,
                          label: t('questionSet.name'),
                          oneLine: true,
                          dataKey: 'description',
                          cellRenderer: multiLanguageInnerTableCellRenderer('name', language),
                        },
                        {
                          width: 3,
                          label: t('admin.actions'),
                          dataKey: 'id',
                          cellRenderer: actionQuestionSetRenderer,
                        },
                      ]} />
    );
  };

  const imageSizeExceeded = useCallback((values: ClinicConfDto) => {

    if (values.image && values.image.size >= 1048576) {

      setErrorMessages([t(`error.${errorUtils.fileSizeExceeded}`)]);

      return true;
    }

    return false;
  }, []);

  const handleSubmit = useCallback(async (
    values: ClinicConfDto,
    form: FormApi<ClinicConfDto, Partial<ClinicConfDto>>) => {

    let saveValues: ClinicConfDto = {
      ...values,
      imageView: "imageView",
    };

    const imageSizeExceed = imageSizeExceeded(values);

    if (imageSizeExceed) {
      return;
    }

    const onSave = () => {
      setSuccessMsg(t('clinicConf.onUpdate'));
      form.change('image', undefined);
      setTimeout(() => {
        setSuccessMsg(undefined);
      }, 1200);
    };

    if (clinicId) {
      await saveClinicConf(saveValues, cancelTokenSource)
      .then(onSave)
      .catch(noop);
    }
  }, []);

  const renderViews = (): React.ReactNode => {

    if (ConfigView.GENERAL !== activeView) {
      return <>
        <Grid.Row>
          <Grid.Column width={16}>
            <Grid stackable doubling columns={2}>
              <Grid.Column width={8} style={isIpad ? { paddingBottom: '0' } : {}}>
                <InnerTsaGrid>
                  <Grid.Row>
                    <Grid.Column width={15}>
                      <DataLabel>{t(`clinic.config.${activeView}Conf`)}</DataLabel>
                      {renderConfTable()}
                    </Grid.Column>
                    <Grid.Column>
                      <ActionContainer>
              <span>
                <InnerTableActionButton
                  message={t('button.add')}
                  onConfirm={() => setModalTestConfOpen(true)}
                />
              </span>
                      </ActionContainer>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={15}>
                      <DataLabel>{t('clinic.config.questionSets')}</DataLabel>
                      {renderQuestionSetTable()}
                    </Grid.Column>
                    <Grid.Column>
                      <ActionContainer>
              <span>
                <InnerTableActionButton
                  message={t('button.add')}
                  onConfirm={() => setModalQuestionSetOpen(true)}
                />
              </span>
                      </ActionContainer>
                    </Grid.Column>
                  </Grid.Row>
                </InnerTsaGrid>
              </Grid.Column>

              <Grid.Column width={8} style={isIpad ? { paddingTop: '0' } : {}}>
                <InnerTsaGrid>
                  <Grid.Row>
                    <Grid.Column width={15}>
                      <DataLabel>{t(`clinic.config.${activeView}Templates`)}</DataLabel>
                      {renderTestTemplateTable()}
                    </Grid.Column>
                    <Grid.Column>
                      <ActionContainer>
              <span>
                <InnerTableActionButton
                  message={t('button.add')}
                  onConfirm={() => setModalTestTemplateOpen(true)}
                />
              </span>
                      </ActionContainer>
                    </Grid.Column>
                  </Grid.Row>
                </InnerTsaGrid>
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </>;
    }
  };

  const handleTabChange = (view: ConfigView) => {
    setActiveView(view);
  };

  const renderClinicContentForm = (): JSX.Element => {

    return (
      <FinalForm
        onSubmit={(values: ClinicConfDto, form: FormApi<ClinicConfDto, Partial<ClinicConfDto>>) => handleSubmit(values, form)}
        initialValues={clinicConf}
        subscription={{ pristine: true, submitting: true, values: true }}
        render={(formProps: FormRenderProps<ClinicConfDto>) => renderContent(formProps)}
      />
    );

  };

  const renderContent = ({
                           handleSubmit,
                           submitting,
                           values,
                           form,
                         }: FormRenderProps<ClinicConfDto>): React.ReactNode => {

    const clinicName = clinicInfo ? `(${clinicInfo.name})` : "";

    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          <Grid.Row>
            <Grid.Column width={16}>
              <div className='title-h1'>{t('clinic.config.title')} {clinicName}</div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{ paddingBottom: '0' }}>
            <Grid.Column width={16}>
              <ClinicConfigTabComponent activeView={activeView} onTabChange={handleTabChange} />
            </Grid.Column>
          </Grid.Row>

          {errorMessages.length > 0 &&
            <Grid.Row>
              <Grid.Column width={16}>
                <div className='error'>
                  <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                    {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                  </StyledErrorMessage>
                </div>
              </Grid.Column>
            </Grid.Row>
          }

          {ConfigView.GENERAL === activeView &&
            <>
              <Grid.Row>
                <Grid.Column width={5}>
                  <DataLabel>{t('clinicConf.invoicePackageType')}</DataLabel>
                  <Field
                    name='invoicePackageType'
                    component={Select}
                    options={invoicePackageTypes}
                    clearlable
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={5}>
                  <DataLabel>{t('clinicConf.reportsLogo')}</DataLabel>
                  <Field name='image'
                         component={ImageDropZone}
                         imageView={values.image ? undefined : values.imageView}
                         accept='image/png'
                         removeImageView={() => {
                           form.change('imageView', undefined);
                           form.change('image', undefined);
                         }
                         }
                         getErrorCode={getFineInvalidTypeError}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={9}>
                  <DataLabel>{t(`clinicConf.hideNumbers`)}</DataLabel>
                  <Field
                    name='hideNumbers'
                    component={RadioGroup}
                    radioWith='25%'
                    radioDefinitions={hideNumbersDefinitions}
                    />
                </Grid.Column>
              </Grid.Row>
            </>
          }

          {renderViews()}

          <Grid.Row style={{ paddingBottom: "0" }}>
            <Grid.Column width={16} style={{ borderTop: '1px solid #d4d9e0', marginTop: '0.3rem'}}>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row textAlign='right'>
            <Grid.Column width={16} style={{ paddingRight: '0' }}>

              {
                successMsg &&
                <SaveAndUpdateConfirmationMessage>
                  {successMsg}
                </SaveAndUpdateConfirmationMessage>
              }

              {
                ConfigView.GENERAL === activeView &&
                <CompositeButton
                  primary
                  type='submit'
                  className='action-button'
                  disabled={submitting || !!successMsg}
                  onClick={noop}
                >
                  {t('button.save')}
                </CompositeButton>
              }

              <CompositeButton
                type='button'
                className='action-button'
                secondary
                disabled={submitting || !!successMsg}
                onClick={() => history.push(prevPath!, { id: clinicId })}
              >
                {t('action.back')}
              </CompositeButton>
            </Grid.Column>
          </Grid.Row>
        </TsaGrid>
        {renderAvailableTestConfsModal()}
        {renderAvailableTestTemplatesModal()}
        {renderAvailableQuestionSetsModal()}
      </form>
    );
  };

  const renderAvailableTestConfsModal = (): React.ReactNode => {

    const testingView = ConfigView.TESTING === activeView;

    return (
      <Modal style={{ position: 'sticky' }}
             open={modalTestConfOpen}
             closeOnDimmerClick={false}
             onClose={() => setModalTestConfOpen(false)}>
        <Modal.Header>{t(`testImage.available${_.capitalize(activeView)}Configs`)}</Modal.Header>
        <FormModalContentContainer>
          <UniversalAddModal
            type={testingView ? ModalSearchType.TEST_CONF : ModalSearchType.EXERCISE_CONF}
            selectedRecords={clinicConfig
              ? testingView
                ? clinicConfig.testConfIds
                : clinicConfig.exerciseConfIds
              : []
            }
            onAddRecord={onAddConf}
            onCancel={() => setModalTestConfOpen(false)}
          />
        </FormModalContentContainer>
      </Modal>
    );
  };

  const renderAvailableTestTemplatesModal = (): React.ReactNode => {

    const testingView = ConfigView.TESTING === activeView;

    return (
      <Modal style={{ position: 'sticky' }}
             open={modalTestTemplateOpen}
             closeOnDimmerClick={false}
             onClose={() => setModalTestTemplateOpen(false)}>
        <Modal.Header>{t(`clinic.config.available${_.capitalize(activeView)}Templates`)}</Modal.Header>
        <FormModalContentContainer>
          <UniversalAddModal
            type={testingView ? ModalSearchType.TEST_TEMPLATE : ModalSearchType.EXERCISE_TEMPLATE}
            selectedRecords={clinicConfig
              ? testingView
                ? clinicConfig.testTemplateIds
                : clinicConfig.exerciseTemplateIds
              : []
            }
            onAddRecord={onAddTemplate}
            onCancel={() => setModalTestTemplateOpen(false)}
          />
        </FormModalContentContainer>
      </Modal>
    );
  };

  const renderAvailableQuestionSetsModal = (): React.ReactNode => {

    const testingView = ConfigView.TESTING === activeView;

    return (
      <Modal style={{ position: 'sticky' }}
             open={modalQuestionSetOpen}
             closeOnDimmerClick={false}
             onClose={() => setModalQuestionSetOpen(false)}>
        <Modal.Header>{t('clinic.config.availableQuestionSets')}</Modal.Header>
        <FormModalContentContainer>
          <UniversalAddModal
            type={ModalSearchType.QUESTION_SET}
            selectedRecords={clinicConfig
              ? testingView
                ? clinicConfig.testQuestionSetIds
                : clinicConfig.exerciseQuestionSetIds
              : []
            }
            onAddRecord={onAddQuestionSet}
            onCancel={() => setModalQuestionSetOpen(false)}
            scopeType={testingView ? ScopeType.TEST : ScopeType.REHAB}
          />
        </FormModalContentContainer>
      </Modal>
    );
  };

  return (
    <UpsertContentWrapperDiv>
      {formDataLoaded
        ? <React.Fragment>
          {renderClinicContentForm()}
        </React.Fragment>
        : <LoaderComponent message={t('general.loading')} />
      }
    </UpsertContentWrapperDiv>
  );
};

export default ClinicConfigView;