import React, { Component } from 'react';
import { DividerProps } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledDataLabel = styled.div`
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 11px;
  color: var(--light-blue);
  text-transform: uppercase;
`;

class DataLabel extends Component<DividerProps> {

  render(): React.ReactNode {
    const {children, ...rest} = this.props;
    return (
        <StyledDataLabel {...rest}>
          {children}
        </StyledDataLabel>
    )
  }
}

export default DataLabel;
