import TableContainer from 'components/TableContainerDiv';
import React, { useLayoutEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Ref, Table } from 'semantic-ui-react';
import { TableCellProps } from 'semantic-ui-react/dist/commonjs/collections/Table/TableCell';
import { emptyTableRows } from 'util/tableUtils';

export interface InnerFormTableCellProps extends TableCellProps {
  dataKey: string,
  label?: string | React.ReactNode,
  shortLabel?: string,
  oneLine?: boolean,
  cellRenderer?: (rowData: any, index: number) => React.ReactNode
}

interface InnerFormTableProps {
  id: string,
  values: any[],
  columns: InnerFormTableCellProps[],
  visibleRows?: number,
  numOfCells: number
  dragAndDropFn?: (startIx: number, endIx: number) => void
}

const InnerFormTableWithOrder = ({
                                   id,
                                   numOfCells,
                                   values = [],
                                   columns = [],
                                   visibleRows = 4,
                                   dragAndDropFn,
                                 }: InnerFormTableProps) => {

  const [headerLabels, setHeaderLabels] = useState<React.ReactNode[]>([]);
  const [scrollable, setScrollable] = useState<boolean>(false);

  useMemo(() => {
    let hl: React.ReactNode[] = [];
    columns.forEach((c: any) => {
      if (c.label) {
        hl.push(
          <Table.HeaderCell key={c.label} width={c.width}
                            style={c.shortLabel ? { cursor: 'pointer' } : { cursor: 'auto' }}>
            {typeof c.label === 'string'
              ? <span title={c.label}>
            {c.shortLabel ? c.shortLabel : c.label}
             </span>
              : c.label
            }
          </Table.HeaderCell>,
        );
      }
    });
    setHeaderLabels(hl);
  }, [columns]);

  const isScrollable = (element: any) => {

    const hasScrollableContent = element.scrollHeight > element.clientHeight;

    const overflowYStyle = window.getComputedStyle(element).overflowY;
    const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;

    return hasScrollableContent && !isOverflowHidden;
  };

  useLayoutEffect(() => {
    let element = document.getElementById(id);
    setScrollable(isScrollable(element));
  }, [values]);

  const countValues = useMemo(() => {

    if (values) {
      return values.length;
    }

    return 0;
  }, [values]);

  const calculateHeight = useMemo(() => {
    return visibleRows > countValues
      ? countValues > 3
        ? countValues * 36
        : 108
      : visibleRows * 36;
  }, [values]);

  const onDragEnd = (result: any) => {
    // dropped outside the list or do not have drag and drop function provided
    if (!result.destination || dragAndDropFn === undefined) {
      return;
    }

    dragAndDropFn!(
      result.source.index,
      result.destination.index,
    );

  };

  return (
    <TableContainer height={calculateHeight}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Table basic='very' size='small'>
          {headerLabels.length ?
            <Table.Header style={scrollable ? { paddingRight: '10px' } : {}}>
              <Table.Row>
                {headerLabels}
              </Table.Row>
            </Table.Header>
            : ''
          }
          <Droppable droppableId={`${id}-tableBody`}>
            {(provided, snapshot) => (
              <Ref innerRef={provided.innerRef}>
                <Table.Body id={id} className='table-body' {...provided.droppableProps}>
                  {values && values.map((val: any, index: number) => {
                    return <Draggable
                      draggableId={`${id}-tableRow-${index}`}
                      index={index}
                      key={index}
                    >
                      {(provided, snapshot) => (
                        <Ref innerRef={provided.innerRef}>
                          <Table.Row key={index}
                                     {...provided.draggableProps}
                                     {...provided.dragHandleProps}
                          >
                            {columns.map(({
                                            dataKey,
                                            cellRenderer,
                                            oneLine,
                                            ...other
                                          }: InnerFormTableCellProps, ix: number) =>
                                <Table.Cell key={ix} {...other} singleLine={oneLine}
                                            className={oneLine ? 'single-line-cell-class' : ''}>
                                  {cellRenderer ? cellRenderer(val, index) :
                                    <span title={val[`${dataKey}`]}>
                      {val[`${dataKey}`]}
                    </span>
                                  }
                                </Table.Cell>,
                            )}
                          </Table.Row>
                        </Ref>
                      )}
                    </Draggable>;
                  })}
                  {emptyTableRows(values.length, numOfCells)}
                  {provided.placeholder}
                </Table.Body>
              </Ref>
            )}
          </Droppable>
        </Table>
      </DragDropContext>
    </TableContainer>
  );
};

export default InnerFormTableWithOrder;
