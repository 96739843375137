import { useAuthContext } from 'auth/AuthContext';
import DataLabel from 'components/final-form/DataLabel';
import CompositeButton from 'components/final-form/CompositeButton';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import MainContainerComponent from 'components/MainContainerComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import TwoFactorAuth from 'routes/init-2fa/TwoFactorAuth';
import { Button, Grid, Header, Input, InputOnChangeData } from 'semantic-ui-react';
import styled from 'styled-components';
import { errorUtils } from 'util/errorUtils';
import { isKeyCheck } from 'util/keyUtils';
import { applyStyles } from 'util/localizationUtils';

const ContainerDiv = styled.div`

  .container-div {
    justify-content: center;
    background-color: white;
    border: 1px solid var(--very-very-light-gray);
    padding: 50px;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .header-div {
    text-align: center;
  }

  .email-form {
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;

    margin-top: 2rem;

    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 2rem;


    label {
      align-self: center;
    }

    .action-button {
      grid-column-start: 2;
      justify-self: start;
      margin-top: 0.75rem;

      &.text {
        margin-top: 0;
      }
    }

    .error-message {
      margin-bottom: 1rem;
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
      position: relative;
    }
  }

  @media only screen and (max-width: 767px) {
    .email-form {
      display: flex;
      flex-direction: column;

      label {
        align-self: flex-start;
      }

      .action-button {
        display: block;
        width: 100%;
      }
    }
  }
`;

const StyledTransparentButton = styled(Button)`
  background: transparent !important;
  color: var(--primary-color) !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-top: 0 !important;

  :hover {
    text-decoration: underline;
  }

  :focus {
    text-decoration: underline;
  }
`;

interface Props {
}

const Login = (props: Props) => {

  const { t } = useTranslation('login');
  const { login, isAuthenticated } = useAuthContext();
  const history = useHistory();
  const { state } = useLocation();
  const successMessage = state?.successMessage;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [show2Fa, setShow2Fa] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/');
    }
  }, []);

  const setInputUsername = (evt: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setUsername(data.value);
  };

  const setInputPassword = (evt: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setPassword(data.value);
  };

  const setErrorMessage = useCallback((errorMessage?: string) => {

    if (errorMessage) {
      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  }, []);

  const handleError = (error: any) => {

    if (error
      && error.response
      && error.response.data
      && error.response.data.error
      && error.response.data.error === errorUtils.invalidVerificationCode) {

      setShow2Fa(true);
      return;
    }

    if (errorMessages.length == 0) {
      const messageKey = error.response && error.response.status == 401 ? 'error.credentials' : 'error.general';

      setErrorMessage(t(messageKey));
    }
  };

  const onEnterKeyDown = (event: any) => {
    event.persist();
    const isKey: any = isKeyCheck(event);

    if (isKey.enter) {
      userLogin();
    }
  };

  const userLogin = async () => {
    try {
      await login(username, password);
      history.push('/');
    } catch (e) {
      handleError(e);
    }
  };

  const goToForgetPassword = () => {
    history.push('/forget-password');
  };

  const goToActivationKeyView = () => {
    history.push('/registration');
  };

  const backToLogin = () => {
    setShow2Fa(false);
  };

  const renderLoginForm = () => {
    return <MainContainerComponent>
      <ContainerDiv>
        <div className={'container-div'}>
          <div className={'header-div'}>
            <Header as='h3'>{show2Fa ? t('login.2fa.title') : t('login.title')}</Header>
            <Header as='h4' color={'grey'}>{t('login.subtitle')}</Header>
          </div>

          <div className={'header-div'}>
            <Header as='h4' color={'green'}>
              {
                successMessage &&
                <SaveAndUpdateConfirmationMessage>
                  {applyStyles(successMessage)}
                </SaveAndUpdateConfirmationMessage>
              }
            </Header>
          </div>

          <div className='email-form'>
            {
              errorMessages.length > 0 &&
              <Grid.Row>
                <Grid.Column width={16}>
                <div className='error-message'>
                  <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                    {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                  </StyledErrorMessage>
                </div>
              </Grid.Column>
              </Grid.Row>
            }

            <Grid.Row>
              <Grid.Column width={4}>
                <DataLabel>{t('login.username')}</DataLabel>
                <Input
                  placeholder={t('login.placeHolder.username')}
                  value={username}
                  onChange={setInputUsername}
                  onKeyDown={(e: any) => onEnterKeyDown(e)}
                  fluid
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={4}>
                <DataLabel>{t('login.password')}</DataLabel>
                <Input
                  placeholder={t('login.placeHolder.password')}
                  type='password'
                  value={password}
                  onChange={setInputPassword}
                  onKeyDown={(e: any) => onEnterKeyDown(e)}
                  fluid
                />
              </Grid.Column>
            </Grid.Row>

            <CompositeButton
              type='button'
              className='action-button'
              primary
              onClick={userLogin}
              fluid
            >
              {t('action.login')}
            </CompositeButton>
          </div>

          <StyledTransparentButton
            type='button'
            className='action-button'
            size='large'
            onClick={goToForgetPassword}
          >
            {t('login.forgotPasswordBtn')}
          </StyledTransparentButton>
          <StyledTransparentButton
            type='button'
            className='action-button'
            size='large'
            onClick={goToActivationKeyView}
          >
            {t('login.registerBtn')}
          </StyledTransparentButton>
        </div>
      </ContainerDiv>
    </MainContainerComponent>;

  };

  return show2Fa
    ? <TwoFactorAuth username={username} password={password} onCancel={backToLogin} />
    : renderLoginForm();

};

export default Login;
