import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

interface Props extends JSX.ElementChildrenAttribute {}

interface State {
  activeTab: string,
  onSetActiveTab: (activeTab: string) => void
}

export enum Views {
  HOME = '/',
  TESTING = '/testconf',
  ADMIN = '/account',
  CLINIC = '/clinic',
  PATIENT = '/patient',
  REHABILITATION = '/rehabilitation'
}

export const SidebarContext = React.createContext<State>({
  activeTab: Views.HOME,
  onSetActiveTab: (activeTab: string) => {},
});

const SidebarProvider = (props: Props) => {

  const routeLocation = useLocation();

  const [activeTab, setActiveTab] = useState<Views>(Views.HOME);

  const onSetActiveTab = (activeTab: string) => {

    const uriRoot = activeTab.split('/')[1];
    const root = `/${uriRoot}`;

    const views = Object.values(Views).map(view => view.toString());

    if (views.includes(root)) {
      setActiveTab(root as Views);
    }
  };

  useEffect(() => {

    if (routeLocation && routeLocation.pathname) {
      onSetActiveTab(routeLocation.pathname);
    }

  }, [routeLocation.pathname]);

  const state: State = React.useMemo(() => {
    return {
      activeTab,
      onSetActiveTab,
    };
  }, [activeTab, onSetActiveTab]);

  return (
    <SidebarContext.Provider value={state}>
      {props.children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => React.useContext(SidebarContext);

export default SidebarProvider;
