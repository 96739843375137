import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import { CheckboxSc } from 'components/final-form/CheckBox';
import DataLabel from 'components/final-form/DataLabel';
import { DropdownOption, mapToMultiLangDropdownOptionArray } from 'components/final-form/Select';
import { selectInput } from 'components/final-form/SelectComponent';
import CompositeButton from 'components/final-form/CompositeButton';
import LoaderComponent from 'components/LoaderComponent';
import TsaGrid from 'components/TsaGrid';
import { useTestResultFormDataContext } from 'context/TestResultFormDataContext';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Table } from 'semantic-ui-react';
import { getAllTestResults } from 'service/testResultService';
import { getTrueScoresForTestResult } from 'service/trueScoreResultService';
import styled from 'styled-components';
import { TrueScoreResultDto, UpsertTrueScoreResultDto } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { useAfterFirstRender } from 'util/functionUtils';
import { emptyTableRows, formatRawScoreNumbers } from 'util/tableUtils';
import { required } from 'util/validatorUtils';

const TableContainer = styled.div`
  flex-grow: 1;
  cursor: context-menu;
  max-height: 180px;
  overflow: auto;
  border-radius: unset;
  border: 1px solid var(--light-gray) !important;

  & .ui.table tr {
    height: 30px;
    border: none;
  }

  .ui.table tr td {
    border-top: none;
  }

  .no-padding {
    border: 1px solid var(--light-gray) !important;
    padding-left: 0;
    padding-right: 0;
    margin-left: 1rem;
  }

  .ui.basic.striped.table tbody tr:nth-child(2n) {
    background-color: var(--very-light-gray) !important;
  }

  .header-cell {
    padding: 10px;
  }
`;

const ActionContainer = styled.div`
  flex-grow: 1;
  margin-top: 1rem;
  border-radius: unset;

  .ui.primary.button, .ui.primary.buttons .button {
    background-color: var(--primary-color);

    :hover {
      background-color: transparent;
      border-color: var(--primary-color);
      color: var(--primary-color);
    }

    :focus {
      background-color: transparent;
      border-color: var(--primary-color);
      color: var(--primary-color);
    }
  }

  .ui.secondary.button, .ui.secondary.buttons .button {
    background-color: var(--secondary-color);

    :hover {
      background-color: transparent;
      border-color: var(--secondary-color);
      color: var(--secondary-color);
    }

    :focus {
      background-color: transparent;
      border-color: var(--secondary-color);
      color: var(--secondary-color);
    }
  }
`;

interface Props {
  onCancel: () => void;
}

const cancelTokenSource = axios.CancelToken.source();

const TrueScoreCopyModal = (props: Props) => {

  const { t } = useTranslation('teresa');
  const { language } = useAuthContext();
  const { testResultFormData, onCopyFromExistingTrueScores } = useTestResultFormDataContext();

  const { onCancel } = props;

  const [testResultId, setTestResultId] = useState<number | undefined>(undefined);
  const [testResultOptions, setTestResultOptions] = useState<DropdownOption[]>([]);
  const [trueScores, setTrueScores] = useState<TrueScoreResultDto[]>([]);
  const [trueScoreMap, setTrueScoreMap] = useState<{ [key: number]: TrueScoreResultDto }>([]);
  const [selectedTrueScores, setSelectedTrueScores] = useState<number[]>([]);
  const [formDataLoaded, setFormDataLoaded] = useState<boolean>(true);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    fetchFormData();
  }, []);

  const fetchFormData = async () => {
    try {
      setFormDataLoaded(false);
      const testResults = await getAllTestResults(cancelTokenSource);
      const testResultOptArray = mapToMultiLangDropdownOptionArray(testResults, 'description', language);
      setTestResultOptions(testResultOptArray);

      if (testResults.length) {
        const testResult = testResults[0];
        setTestResultId(testResult.id);
        const trueScoreRules = await getTrueScoresForTestResult(testResult.id, cancelTokenSource);
        setTrueScores(trueScoreRules);
        setTrueScoreMap(_.keyBy(trueScoreRules, 'id'));
        setSelectedTrueScores(trueScoreRules.map(tsr => tsr.id));
      }
    } catch (e) {
      handleError(e.response.data);
    } finally {
      setFormDataLoaded(true);
    }
  };

  useEffect(() => {
    fetchTrueScores();
  }, [testResultFormData, testResultId]);

  const fetchTrueScores = async () => {
    try {
      if (testResultId) {
        setFormDataLoaded(false);
        const trueScoreRules = await getTrueScoresForTestResult(testResultId, cancelTokenSource);
        setTrueScores(trueScoreRules);
        setTrueScoreMap(_.keyBy(trueScoreRules, 'id'));
        setSelectedTrueScores(trueScoreRules.map(tsr => tsr.id));
      }
    } catch (e) {
      handleError(e.response.data);
    } finally {
      setFormDataLoaded(true);
    }
  };

  const handleError = useCallback((error: any) => {

    if (error) {
      let errMsgs = [];
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [errorUtils.invalidEmail];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsgs.push(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          errMsgs.push(t(`error.${errorCode}`));
        } else {
          errMsgs.push(t('error.general'));
        }
      }

      setErrorMessage(errMsgs);
    }
  }, []);

  const setErrorMessage = (errMsgs?: string[]) => {

    if (errMsgs) {
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const handleChange = useAfterFirstRender(async ({ values }: any) => {
    setTestResultId(values.testResultId);
  });

  const onCopyTrueScores = () => {
    let upsertTrueScores: Partial<UpsertTrueScoreResultDto>[] = [];
    selectedTrueScores.forEach(tsrId => {
      if (trueScoreMap[tsrId]) {
        const trueScore = trueScoreMap[tsrId];
        const upsertTrueScore: Partial<UpsertTrueScoreResultDto> = {
          description: trueScore.description,
          fromScoreRaw: trueScore.fromScoreRaw,
          toScoreRaw: trueScore.toScoreRaw,
          trueResult: trueScore.trueResult,
          active: trueScore.active,
        };
        upsertTrueScores.push(upsertTrueScore);
      }
    });
    onCopyFromExistingTrueScores(upsertTrueScores);
    onCancel();
  };

  const selectHeaderColumn = () => {

    const countSelectedTrueScores = selectedTrueScores.length;
    const indeterminate = countSelectedTrueScores > 0 && countSelectedTrueScores < trueScores.length;
    const noSelectedTrueScores = countSelectedTrueScores === 0 && trueScores.length > 0;
    const allTrueScoresSelected = trueScores.length > 0 && countSelectedTrueScores === trueScores.length;

    return (
      <CheckboxSc
        checked={countSelectedTrueScores > 0}
        indeterminate={indeterminate}
        onChange={() => {
          if (noSelectedTrueScores || indeterminate) {
            const selectedTrueScoreRules = trueScores.map(tsr => tsr.id);
            setSelectedTrueScores(selectedTrueScoreRules);
          }

          if (allTrueScoresSelected) {
            setSelectedTrueScores([]);
          }
        }
        }
        disabled={trueScores.length === 0}
      />
    );
  };

  const selectSingleTrueScore = (trueScoreId: number) => {
    let newSelection = [...selectedTrueScores];

    if (selectedTrueScores.includes(trueScoreId)) {
      newSelection = newSelection.filter(id => id !== trueScoreId);
      setSelectedTrueScores(newSelection);
    } else {
      newSelection.push(trueScoreId);
    }

    setSelectedTrueScores(newSelection);
  };

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={() => {}}
        initialValues={{ testResultId: testResultId }}
        subscription={{ pristine: true, values: true }}
        render={renderTable}
      />
    );
  };

  const renderTable = () => {

    const testResultField = selectInput({
        name: 'testResultId',
        validate: required,
        searchable: true,
      },
      testResultOptions,
    );

    return (
      <TsaGrid>
        {!formDataLoaded
          ? <Grid.Row style={{ height: '150px' }}>
            <Grid.Column width={16}>
              <LoaderComponent message={t('testresult.loading')} />
            </Grid.Column>
          </Grid.Row>
          : <>
            <Grid.Row>
              <Grid.Column width={6}>
                <DataLabel>{t('testresult.viewTitle')}</DataLabel>
                <Field
                  fluid
                  {...testResultField}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16} verticalAlign='middle' className='no-padding'>
                <TableContainer>
                  <Table basic='very' striped size='small'>
                    <Table.Body className='table-body'>
                      <Table.HeaderCell width={2} className='header-cell'>
                        {selectHeaderColumn()}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={8}
                                        className='header-cell'>
                        {t('testresult.description')}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2}
                                        className='header-cell'>
                        {t('truescoreresult.fromScoreRaw')}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2}
                                        className='header-cell'>
                        {t('truescoreresult.toScoreRaw')}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={4}
                                        className='header-cell'>
                        {t('truescoreresult.trueScores')}
                      </Table.HeaderCell>
                      {trueScores.map(trueScore => {
                        const trueScoreId = trueScore.id;
                        return <Table.Row key={trueScoreId}>
                          <Table.Cell width={2}>
                            <CheckboxSc
                              checked={selectedTrueScores.includes(trueScoreId)}
                              onChange={() => selectSingleTrueScore(trueScoreId)}
                            />
                          </Table.Cell>
                          <Table.Cell width={8} flexgrow={1}>
                            {trueScore.description}
                          </Table.Cell>
                          <Table.Cell width={2}>
                            {formatRawScoreNumbers(trueScore.fromScoreRaw)}
                          </Table.Cell>
                          <Table.Cell width={2}>
                            {formatRawScoreNumbers(trueScore.toScoreRaw)}
                          </Table.Cell>
                          <Table.Cell width={4}>
                            {t(`truescoreresult.trueScore.${trueScore.trueResult}`)}
                          </Table.Cell>
                        </Table.Row>;
                      })}
                      {emptyTableRows(trueScores.length, 5)}
                    </Table.Body>
                  </Table>
                </TableContainer>
              </Grid.Column>
              <Grid.Column width={16}>
                <ActionContainer>
                  <CompositeButton
                    type='button'
                    className='action-button'
                    style={{ whiteSpace: 'nowrap' }}
                    secondary
                    floated='right'
                    onClick={onCancel}
                  >
                    {t('button.close')}
                  </CompositeButton>
                  <CompositeButton
                    type='button'
                    className='action-button'
                    style={{ whiteSpace: 'nowrap', marginRight: '15px' }}
                    primary
                    floated='right'
                    onClick={onCopyTrueScores}
                    disabled={!selectedTrueScores.length}
                  >
                    {t('button.copy')}
                  </CompositeButton>
                </ActionContainer>
              </Grid.Column>
            </Grid.Row>
          </>
        }
        <FormSpy subscription={{ values: true }} onChange={handleChange} />
      </TsaGrid>
    );
  };

  return <>{renderFinalForm()}</>;
};

export default TrueScoreCopyModal;