import { useAuthContext } from 'auth/AuthContext';
import TsaGrid from 'components/TsaGrid';
import _ from 'lodash';
import React, { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Header, Icon, Input, InputOnChangeData, Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { PatientTestDto, TestConfDto } from 'ts-types/api.types';

const StyledIcon = styled(Icon)`
    cursor: pointer;
    border-radius: 4px;
`;

const StyledBtnContainer = styled.div`
  margin-bottom: 1.5rem;
  white-space: nowrap !important;
`;

const StyledButtons = styled.div`
  padding: 0 !important;
  margin: 0 0 0 4px !important;
  flex: 1 1 auto;
`;

const StyledSelectableTable = styled.div`
    flex: 50 50 auto;

  .header-row {
    align-items: baseline !important;

    .search-field {
      padding-right: 0 !important;
    }
`;

const StyledTableRow = styled(Table.Row)`
  cursor: pointer;
`;

const ScrollableTableWrapper = styled.div`
  height: 287px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  border: 1px solid rgba(34,36,38,.15);
  box-shadow: none;
  border-radius: .28571429rem;
  flex: 50 50 auto;

  & .ui.table {
    border: none;
  }

   & .ui.table thead tr:first-child > th {
      position: sticky !important;
      top: 0;
      z-index: 2;
      background: white;
  }

  & .ui.table tbody tr:last-child > td {
      border-bottom: 1px solid rgba(34,36,38,.15)
  }
`;

interface Props {
  selectedTestConfList?: Array<TestConfDto>,
  availableTestConfList?: Array<TestConfDto>,
  setData: (selectedTestConfs: Array<TestConfDto>, availableTestConfs: Array<TestConfDto>) => void
  setSelectedTestInTemplates: (markedTestConfs: TestConfDto) => void,
  patientTests?: PatientTestDto[]
}

const TemplateTestConfSegment = (props: Props) => {

  const { t } = useTranslation('teresa');
  const { language } = useAuthContext();

  const {
    selectedTestConfList = [],
    availableTestConfList = [],
    setData,
    setSelectedTestInTemplates,
    patientTests,
  } = props;

  const [selectedAddTestConfs, setSelectedAddTestConfs] = React.useState<TestConfDto[]>([]);
  const [availableTestConfs, setAvailableTestConfs] = React.useState<TestConfDto[]>([]);
  const [markedTestConf, setMarkedTestConf] = React.useState<TestConfDto | undefined>(undefined);
  const [availableSearchQuery, setAvailableSearchQuery] =
    React.useState<string | undefined>(undefined);
  const [selectedSearchQuery, setSelectedSearchQuery] =
    React.useState<string | undefined>(undefined);

  useEffect(() => {
    setSelectedAddTestConfs(selectedTestConfList);
    setAvailableTestConfs(availableTestConfList);
  }, [selectedTestConfList, availableTestConfList]);

  const markTestConf = (testConf: TestConfDto) => {
    setMarkedTestConf(testConf);
    setSelectedTestInTemplates(testConf);
  };

  const changeOrderUp = () => {
    if (markedTestConf) {
      if (selectedAddTestConfs.includes(markedTestConf)) {

        const index = selectedAddTestConfs.indexOf(markedTestConf);
        if (index === 0) {return;}

        let newList: TestConfDto[] = [...selectedAddTestConfs];

        const currentTest = { ...markedTestConf };
        const previousTest = { ...selectedAddTestConfs[index - 1] };

        newList[index].orderIndex = previousTest.orderIndex;
        newList[index - 1].orderIndex = currentTest.orderIndex;

        newList = _.orderBy(newList, ['orderIndex'], ['asc']);

        setSelectedAddTestConfs(newList);
        setData(newList, availableTestConfs);
      }
    }
  };

  const changeOrderDown = () => {
    if (markedTestConf) {
      if (selectedAddTestConfs.includes(markedTestConf)) {

        const index = selectedAddTestConfs.indexOf(markedTestConf);
        if (index + 1 === selectedAddTestConfs.length) {return;}

        let newList: TestConfDto[] = selectedAddTestConfs;

        const currentTest = { ...markedTestConf };
        const nextTest = { ...selectedAddTestConfs[index + 1] };

        newList[index].orderIndex = nextTest.orderIndex;
        newList[index + 1].orderIndex = currentTest.orderIndex;

        newList = _.orderBy(newList, ['orderIndex'], ['asc']);

        setSelectedAddTestConfs(newList);
        setData(newList, availableTestConfs);
      }
    }
  };

  const addRemoveTestConf = () => {

    if (markedTestConf) {
      if (selectedAddTestConfs.includes(markedTestConf)) {

        const markedTestConfIndex = selectedAddTestConfs.indexOf(markedTestConf);

        let selected = selectedAddTestConfs.filter(t => markedTestConf !== t);
        if (markedTestConfIndex === 0) {
          selected.forEach(t => t.orderIndex = t.orderIndex - 1);
        } else if (markedTestConfIndex != (selectedAddTestConfs.length && 0)) {
          selected.forEach((t, index) => {
            if (index >= markedTestConfIndex) {
              t.orderIndex = t.orderIndex - 1
            }
          })
        }

        const available = [...availableTestConfs, markedTestConf];
        const sortedAvailableTestConfs = available.sort((tc1, tc2) => {
          const tc1Description = translateDescription(tc1).toLowerCase();
          const tc2Description = translateDescription(tc2).toLowerCase();

          return tc1Description > tc2Description ? 1 : tc1Description === tc2Description ? 0 : -1;
        })

        setSelectedAddTestConfs(selected);
        setAvailableTestConfs(sortedAvailableTestConfs);
        setMarkedTestConf(undefined);

        setData(selected, sortedAvailableTestConfs);

      } else if (availableTestConfs.includes(markedTestConf)) {

        let addedTestConf = { ...markedTestConf };
        addedTestConf.orderIndex = selectedAddTestConfs.length;

        const selected = [...selectedAddTestConfs, addedTestConf];
        const available = availableTestConfs.filter(t => markedTestConf !== t);

        setSelectedAddTestConfs(selected);
        setAvailableTestConfs(available);
        setMarkedTestConf(undefined);

        setData(selected, available);
      }
    }
  };

  const translateDescription = (test: TestConfDto) => {
    const description = test.description;

    const testConfDescriptions: { [key: string]: string } = {
      'de': test.description || description,
      'en': test.descriptionEn || description,
      'fr': test.descriptionFr || description,
      'it': test.descriptionIt || description,
    };
    return testConfDescriptions[language];
  }

  const renderContent = (): React.ReactNode => {

    const selectable: Array<JSX.Element> = [];
    const available: Array<JSX.Element> = [];

    availableTestConfs.forEach(test => {
      const active = markedTestConf === test;
      const description = translateDescription(test);

      const availableSearchByQuery = availableSearchQuery
        && !_.isEmpty(availableSearchQuery)
        && description.toLowerCase().includes(availableSearchQuery.toLowerCase());

      if (availableSearchByQuery || !availableSearchQuery) {
        const availableTestConfDescription = (
          <StyledTableRow key={test.id} onClick={() => markTestConf(test)} active={active}>
            <Table.Cell>{description}</Table.Cell>
          </StyledTableRow>
        );

        available.push(availableTestConfDescription);
      }
    });

    if (selectedAddTestConfs && selectedAddTestConfs.length > 0) {
      selectedAddTestConfs.forEach(test => {
        const active = markedTestConf === test;
        const description = translateDescription(test);

        let completed = false;
        if (patientTests) {
          const patientTest = patientTests.find(pt => pt.testConfId === test.id);
          completed = patientTest ? patientTest.completed : false;
        }

        const selectedSearchByQuery = selectedSearchQuery
          && !_.isEmpty(selectedSearchQuery)
          && description.toLowerCase().includes(selectedSearchQuery.toLowerCase());

        if (selectedSearchByQuery || !selectedSearchQuery) {
          const selectableTestConfDescription = (
            <StyledTableRow key={test.id} onClick={() => markTestConf(test)} active={active}>
              {completed
                ? <Table.Cell>{`${description} (${t('patientTesting.completed')})`}</Table.Cell>
                : <Table.Cell>{description}</Table.Cell>
              }
            </StyledTableRow>
          );

          selectable.push(selectableTestConfDescription);
        }
      });
    }

    let testCompleted = false;
    if (patientTests) {
      const patientTest = patientTests.find(pt => pt.testConfId === markedTestConf?.id);
      testCompleted = patientTest ? patientTest.completed : false;
    }

    const isInSelected = markedTestConf ? selectedAddTestConfs.includes(markedTestConf) : false;
    const isInAvailable = markedTestConf ? availableTestConfs.includes(markedTestConf) : false;

    const selectedIndex = markedTestConf ? selectedAddTestConfs.indexOf(markedTestConf) : undefined;

    const disabledRight = !markedTestConf || isInAvailable || testCompleted;
    const disabledLeft = !markedTestConf || isInSelected || testCompleted;

    const disabledOrderUp = !markedTestConf || isInAvailable || testCompleted
      || (isInSelected && selectedIndex === 0);
    const disabledOrderDown = !markedTestConf || isInAvailable || testCompleted
      || (isInSelected && selectedIndex !== undefined && selectedIndex + 1 === selectedAddTestConfs.length);

    return (
      <React.Fragment>
        <Grid.Column width={7}>
          <StyledSelectableTable>
            {renderLeftTableSegment(t('testTemplate.orderedTests'), selectable)}
          </StyledSelectableTable>
        </Grid.Column>
        <Grid.Column width={1} textAlign='center' verticalAlign='middle'>
          <StyledButtons>
            <StyledBtnContainer>
              <StyledIcon bordered
                          disabled={disabledLeft}
                          onClick={addRemoveTestConf}
                          name='angle double left'
              />
            </StyledBtnContainer>
            <StyledBtnContainer>
              <StyledIcon bordered
                          disabled={disabledRight}
                          onClick={addRemoveTestConf}
                          name='angle double right'
              />
            </StyledBtnContainer>
            <StyledBtnContainer>
              <StyledIcon bordered
                          disabled={disabledOrderUp}
                          onClick={changeOrderUp}
                          name='angle up'
              />
            </StyledBtnContainer>
            <StyledBtnContainer>
              <StyledIcon bordered
                          disabled={disabledOrderDown}
                          onClick={changeOrderDown}
                          name='angle down'
              />
            </StyledBtnContainer>
          </StyledButtons>
        </Grid.Column>
        <Grid.Column width={7}>
          <StyledSelectableTable>
            {renderRightTableSegment(t('testTemplate.available'), available)}
          </StyledSelectableTable>
        </Grid.Column>
      </React.Fragment>
    );
  };

  const renderLeftTableSegment = (headerText: string, selectedTestConfs: Array<React.ReactNode>): React.ReactNode => {

    return (
      <React.Fragment>
        <TsaGrid>
          <Grid.Row className='header-row'>
            <Grid.Column width={6}>
              <Header as='h5'>
                {headerText}
              </Header>
            </Grid.Column>
            <Grid.Column width={10} className='search-field'>
              <Input
                placeholder={t('testTemplate.searchSelectedTestConf.placeholder')}
                type='search'
                value={selectedSearchQuery}
                onChange={(evt: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) =>
                  setSelectedSearchQuery(data.value)
                }
                icon='search'
                iconPosition='left'
                autoFocus
                fluid
              />
            </Grid.Column>
          </Grid.Row>
        </TsaGrid>
        <ScrollableTableWrapper>
          <Table celled selectable size='small'>
            <Table.Body>
              {selectedTestConfs}
            </Table.Body>
          </Table>
        </ScrollableTableWrapper>
      </React.Fragment>
    );
  };

  const renderRightTableSegment = (headerText: string, availableTestConfs: Array<React.ReactNode>): React.ReactNode => {

    return (
      <React.Fragment>
        <TsaGrid>
          <Grid.Row className='header-row'>
            <Grid.Column width={6}>
              <Header as='h5'>
                {headerText}
              </Header>
            </Grid.Column>
            <Grid.Column width={10} className='search-field'>
              <Input
                placeholder={t('testTemplate.searchAvailableTestConf.placeholder')}
                type='search'
                value={availableSearchQuery}
                onChange={(evt: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) =>
                  setAvailableSearchQuery(data.value)
                }
                icon='search'
                iconPosition='left'
                autoFocus
                fluid
              />
            </Grid.Column>
          </Grid.Row>
        </TsaGrid>
        <ScrollableTableWrapper>
          <Table celled selectable size='small'>
            <Table.Body>
              {availableTestConfs}
            </Table.Body>
          </Table>
        </ScrollableTableWrapper>
      </React.Fragment>
    );
  };

  return <>{renderContent()}</>;
};

export default TemplateTestConfSegment;