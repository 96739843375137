import ChooseColorComponent from 'components/ChooseColorComponent';
import React from 'react';

import 'react-day-picker/lib/style.css';
import { FieldRenderProps } from 'react-final-form';

interface ColorPickerFieldProps extends FieldRenderProps<any> {

}

const FinalFormColorPicker = (props: ColorPickerFieldProps) => {

  const {
    input
    // meta: {
    //   error,
    //   touched
    // },
    // ...restProps
  } = props;


  return <ChooseColorComponent
      chosenColor={input.value}
      onSaveColor={(color?: string) => input.onChange(color)}
  />
};

export default FinalFormColorPicker;
