import axios, { CancelTokenSource } from 'axios';
import { backendUrls } from 'service/http';
import { MandateDto, MandateSearchRequest, UpsertMandateDto } from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/mandate`;

export const searchMandates = (
  request: Partial<MandateSearchRequest>,
  source: CancelTokenSource): Promise<MandateDto[]> => {

  return axios
  .post(`${apiUri}/search`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const getMandate = (mandateId: number, source: CancelTokenSource): Promise<UpsertMandateDto> => {

  return axios
  .get(`${apiUri}/${mandateId}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const addMandate = (
  upsertMandateDto: Partial<UpsertMandateDto>,
  source: CancelTokenSource): Promise<MandateDto> => {

  let bodyFormData = new FormData();
  let mandateBanks = upsertMandateDto.mandateBanks;

  Object.keys(upsertMandateDto).forEach((key) => {
    let value = upsertMandateDto[key as keyof UpsertMandateDto];

    if (value) {
      if (typeof value === 'number') {
        //@ts-ignore
        value = value.toString();
      } else if (key === "mandateBanks" && mandateBanks && mandateBanks.length > 0) {
        for (let i = 0; i < mandateBanks.length; i++) {
          bodyFormData.append(`mandateBanks[${i}].id`, mandateBanks[i].id.toString())
          bodyFormData.append(`mandateBanks[${i}].description`, mandateBanks[i].description)
          bodyFormData.append(`mandateBanks[${i}].city`, mandateBanks[i].city);
          bodyFormData.append(`mandateBanks[${i}].iban`, mandateBanks[i].iban);
          bodyFormData.append(`mandateBanks[${i}].defaultBank`, mandateBanks[i].defaultBank.toString());
        }
      }

      if (key !== "mandateBanks") {
        // @ts-ignore
        bodyFormData.append(key, value);
      }
    }
  });

  return axios
  .post(apiUri, bodyFormData, { cancelToken: source.token })
  .then(response => response.data);
};

export const editMandate = (
  mandateId: number,
  upsertMandateDto: Partial<UpsertMandateDto>,
  source: CancelTokenSource): Promise<MandateDto> => {

  let bodyFormData = new FormData();
  let mandateBanks = upsertMandateDto.mandateBanks;

  Object.keys(upsertMandateDto).forEach((key) => {
    let value = upsertMandateDto[key as keyof UpsertMandateDto];

    if (value) {
      if (typeof value === 'number' || typeof value === "boolean") {
        //@ts-ignore
        value = value.toString();
      } else if (key === "mandateBanks" && mandateBanks && mandateBanks.length > 0) {
        for (let i = 0; i < mandateBanks.length; i++) {
          const mandateBank = mandateBanks[i];
          mandateBank.id && bodyFormData.append(`mandateBanks[${i}].id`, mandateBank.id.toString())
          bodyFormData.append(`mandateBanks[${i}].description`, mandateBank.description)
          bodyFormData.append(`mandateBanks[${i}].city`, mandateBank.city);
          bodyFormData.append(`mandateBanks[${i}].iban`, mandateBank.iban);
          bodyFormData.append(`mandateBanks[${i}].defaultBank`, mandateBank.defaultBank.toString());
        }
      }

      if (key !== "mandateBanks") {
        // @ts-ignore
        bodyFormData.append(key, value);
      }
    }
  });

  return axios
  .put(`${apiUri}/${mandateId}`, bodyFormData, { cancelToken: source.token })
  .then(response => response.data);
};

export const deleteMandateById = (mandateId: number, source: CancelTokenSource): Promise<MandateDto> => {
  return axios
  .delete(`${apiUri}/${mandateId}`, { cancelToken: source.token })
  .then(response => response.data);
};