import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { ExtendedUpsertTestResultDto } from 'routes/testresult/UpsertTestResultForm';
import {
  TestResultParamData,
  TestResultParamDto,
  UpsertRawScoreResultDto,
  UpsertTestConfDto,
  UpsertTrueScoreResultDto,
} from 'ts-types/api.types';

interface State {
  testResultFormData: Partial<ExtendedUpsertTestResultDto> | undefined;
  setTestResultFormData: (upsertTestResultDto: Partial<ExtendedUpsertTestResultDto> | undefined) => void;
  testConfDescriptions: Partial<UpsertTestConfDto> | undefined;
  setTestConfDescriptions: (upsertTestConfDto: Partial<UpsertTestConfDto> | undefined) => void;
  addOrEditTrueScoreResult: (trueScoreResultIx: number, values: Partial<UpsertTrueScoreResultDto>, onSave: () => void) => void;
  onCopyFromExistingTrueScores: (values: Partial<UpsertTrueScoreResultDto>[]) => void;
  deleteTrueScoreResult: (trueScoreResultIx: number) => void;
  addOrEditRawScoreResult: (rawScoreResultIx: number, values: Partial<UpsertRawScoreResultDto>, testResultParams: TestResultParamDto[], onSave: () => void) => void;
  deleteRawScoreResult: (rawScoreResultIx: number, testResultParamMap: { [key: number]: TestResultParamDto }) => void;
  onAddTestResultParam: (testResultParamId: number) => void;
  deleteTestResultParam: (testResultParamId: number) => void;
  onDragAndDropTestResultParam: (startIx: number, endIx: number) => void,
}


const TestResultFormDataContext = React.createContext<State>({
  testResultFormData: undefined,
  setTestResultFormData: (upsertTestResultDto: Partial<ExtendedUpsertTestResultDto> | undefined) => {},
  testConfDescriptions: undefined,
  setTestConfDescriptions: (upsertTestConfDto: Partial<UpsertTestConfDto> | undefined) => {},
  addOrEditTrueScoreResult: (trueScoreResultIx: number, values: Partial<UpsertTrueScoreResultDto>, onSave: () => void) => {},
  onCopyFromExistingTrueScores: (values: Partial<UpsertTrueScoreResultDto>[]) => {},
  deleteTrueScoreResult: (trueScoreResultIx: number) => {},
  addOrEditRawScoreResult: (rawScoreResultIx: number, values: Partial<UpsertRawScoreResultDto>, testResultParams: TestResultParamDto[], onSave: () => void) => {},
  deleteRawScoreResult: (rawScoreResultIx: number, testResultParamMap: { [key: number]: TestResultParamDto }) => {},
  onAddTestResultParam: (testResultParamId: number) => {},
  deleteTestResultParam: (testResultParamId: number) => {},
  onDragAndDropTestResultParam: (startIx: number, endIx: number) => {},
});

interface Props extends JSX.ElementChildrenAttribute {}

const TestResultFormDataProvider = (props: Props) => {

  const [testResultFormData, setTestResultFormData] =
    useState<Partial<ExtendedUpsertTestResultDto> | undefined>(undefined);

  const [testConfDescriptions, setTestConfDescriptions] =
    useState<Partial<UpsertTestConfDto> | undefined>(undefined);

  const addOrEditTrueScoreResult = useCallback(
    (trueScoreResultIx: number, values: Partial<UpsertTrueScoreResultDto>, onSave: () => void) => {

      let newFormData = { ...testResultFormData };

      if (trueScoreResultIx >= 0 && testResultFormData && testResultFormData.trueScores) {
        let truesScores = [...testResultFormData.trueScores];
        // @ts-ignore
        truesScores[trueScoreResultIx] = values;
        newFormData.trueScores = truesScores;
      } else {
        // @ts-ignore
        newFormData.trueScores = [...newFormData.trueScores, values];
      }

      setTestResultFormData(newFormData);
      onSave();
    }, [testResultFormData],
  );

  const onCopyFromExistingTrueScores = useCallback(
    (trueScores: Partial<UpsertTrueScoreResultDto>[]) => {

      let newFormData = { ...testResultFormData };

      if (trueScores && trueScores.length) {
        // @ts-ignore
        newFormData.trueScores = [...newFormData.trueScores, ...trueScores];
      }

      setTestResultFormData(newFormData);

    }, [testResultFormData],
  );

  const deleteTrueScoreResult = useCallback((trueScoreResultIx: number) => {

    let newFormData = { ...testResultFormData };

    if (trueScoreResultIx >= 0 && testResultFormData && testResultFormData.trueScores) {
      let truesScores = [...testResultFormData.trueScores];
      truesScores.splice(trueScoreResultIx, 1);
      newFormData.trueScores = truesScores;
    }

    setTestResultFormData(newFormData);

  }, [testResultFormData]);

  const addOrEditRawScoreResult = useCallback((
      rawScoreResultIx: number,
      values: Partial<UpsertRawScoreResultDto>,
      testResultParams: TestResultParamDto[],
      onSave: () => void) => {

      if (testResultFormData) {

        const formula = values.formula;

        let newFormData = { ...testResultFormData };

        if (rawScoreResultIx >= 0 && testResultFormData && testResultFormData.rawScores) {
          let rawsScores = [...testResultFormData.rawScores];

          const oldFormula = rawsScores[rawScoreResultIx].formula;

          testResultParams.forEach(trp => {
            if (newFormData.testResultParams) {

              const testResultParamIx = newFormData.testResultParams
              .findIndex(val => val.testResultParamId === trp.id);

              if (testResultParamIx >= 0) {

                let editParam = newFormData.testResultParams[testResultParamIx];
                let contInFormula = editParam.countInFormula;

                if (formula && (formula.includes(trp.code)) && (!oldFormula || !oldFormula.includes(trp.code))) {
                  contInFormula = editParam.countInFormula + 1;
                }

                if ((!formula || !formula.includes(trp.code)) && oldFormula && oldFormula.includes(trp.code)) {
                  contInFormula = editParam.countInFormula - 1;
                }

                newFormData.testResultParams[testResultParamIx] = {
                  ...editParam,
                  countInFormula: contInFormula,
                  deletable: contInFormula < 1,
                };
              }
            }
          });

          // @ts-ignore
          rawsScores[rawScoreResultIx] = values;
          newFormData.rawScores = rawsScores;
        } else {
          // @ts-ignore
          newFormData.rawScores = [...newFormData.rawScores, values];
        }

        setTestResultFormData(newFormData);
        onSave();
      }
    }, [testResultFormData],
  );

  const deleteRawScoreResult = useCallback((
    rawScoreResultIx: number,
    testResultParamMap: { [key: number]: TestResultParamDto }) => {

    let newFormData = { ...testResultFormData };

    if (rawScoreResultIx >= 0 && testResultFormData && testResultFormData.rawScores) {

      let rawsScores = [...testResultFormData.rawScores];
      const deleteRawScore = rawsScores[rawScoreResultIx];
      const formula = deleteRawScore.formula;

      if (formula && testResultParamMap && newFormData.testResultParams) {

        const regex = /\$(\w+)\b/g;
        const matches = _.map(Array.from(formula.matchAll(regex)), '1');

        newFormData.testResultParams.forEach(trp => {

          const trpId = trp.testResultParamId;
          const param = testResultParamMap[trpId];

          if (param && matches.includes(param.code)) {
            const countInFormula = trp.countInFormula - 1;
            trp.countInFormula = countInFormula;
            trp.deletable = countInFormula < 1;
          }
        });
      }

      rawsScores.splice(rawScoreResultIx, 1);
      newFormData.rawScores = rawsScores;
    }

    setTestResultFormData(newFormData);

  }, [testResultFormData]);

  const onAddTestResultParam = useCallback((testResultParamId: number) => {

    let newFormData = { ...testResultFormData };

    let addTestResultParam: TestResultParamData = {
      testResultParamId: testResultParamId,
      deletable: true,
      countInFormula: 0,
      orderIndex: newFormData.testResultParams!.length + 1,
    };

    newFormData.testResultParams = [...newFormData.testResultParams!, addTestResultParam];

    setTestResultFormData(newFormData);
  }, [testResultFormData]);

  const deleteTestResultParam = useCallback((id: number) => {
    if (testResultFormData && testResultFormData.testResultParams) {
      const testParam = testResultFormData.testResultParams
      .find(t => t.testResultParamId === id);

      if (testParam) {
        let newFormData: Partial<ExtendedUpsertTestResultDto> = {
          ...testResultFormData,
        };

        newFormData.testResultParams = testResultFormData.testResultParams
        .filter(t => t.testResultParamId !== id)
        .map((t, ix) => ({
          ...t,
          orderIndex: ix + 1,
        }));

        setTestResultFormData(newFormData);
      }
    }
  }, [testResultFormData]);

  const onDragAndDropTestResultParam = (startIx: number, endIx: number) => {
    if (testResultFormData && testResultFormData.testResultParams && testResultFormData.testResultParams.length) {
      let newFormData: Partial<ExtendedUpsertTestResultDto> = {
        ...testResultFormData,
      };
      let testResultParams = newFormData.testResultParams!;
      const [removed] = testResultParams.splice(startIx, 1);
      testResultParams.splice(endIx, 0, removed);

      // Update the orderIndex property for all elements
      testResultParams.forEach((el, index) => {
        el.orderIndex = index + 1;
      });

      setTestResultFormData(newFormData);
    }
  };

  const state: State = React.useMemo(() => {
    return {
      testResultFormData, setTestResultFormData,
      testConfDescriptions, setTestConfDescriptions,
      addOrEditTrueScoreResult, onCopyFromExistingTrueScores, deleteTrueScoreResult,
      addOrEditRawScoreResult, deleteRawScoreResult,
      onAddTestResultParam, deleteTestResultParam,
      onDragAndDropTestResultParam,
    };
  }, [testResultFormData, setTestResultFormData]);

  return (
    <TestResultFormDataContext.Provider value={state}>
      {props.children}
    </TestResultFormDataContext.Provider>

  );
};

export const useTestResultFormDataContext = () => React.useContext(TestResultFormDataContext);

export default TestResultFormDataProvider;