import { useAuthContext } from 'auth/AuthContext';
import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import Input from 'components/final-form/Input';
import CompositeButton from 'components/final-form/CompositeButton';
import TabComponent from 'components/final-form/TabComponent';
import InnerTableActionButton from 'components/InnerTableActionButton';
import SearchDisplayContainer from 'components/SearchDisplayContainer';
import StyledErrorMessage from 'components/StyledErrorMessage';
import VirtualizedTable from 'components/VirtualizedTable';
import { FormState } from 'final-form';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Loader } from 'semantic-ui-react';
import { getClinicDetails } from 'service/adminService';
import axios from 'service/http';
import { deletePatientById, searchPatients } from 'service/patientService';
import styled from 'styled-components';
import { debounce } from 'ts-debounce';
import { ClinicDto, PatientDto, PatientSearchRequest } from 'ts-types/api.types';
import { isoToDisplayDate } from 'util/dateUtils';
import { errorUtils } from 'util/errorUtils';
import { useAfterFirstRender } from 'util/functionUtils';
import { emptyTableCell, shortLabel } from 'util/tableUtils';

const StyledSpan = styled.span`
  display: flex;
  
  .patient-origin-id{
    margin-right: 0.5rem;
  }
  
  .patient-id {
    color: blue;
  }
`;

interface PatientFieldProps extends FormState<any> {
  values: Partial<PatientSearchRequest>;
}

interface Props {
}

const cancelTokenSource = axios.CancelToken.source();

const PatientSearchView = (props: Props) => {

  const { currentUser } = useAuthContext();
  const { push } = useHistory();
  const { t } = useTranslation('teresa');
  const { language } = useAuthContext();

  const { state, pathname } = useLocation();
  const clinicId: number | undefined = state?.clinicId ? Number(state?.clinicId) : undefined;
  const currentUserId = currentUser?.id

  const isSystemAdmin = useMemo(() => currentUser
    && currentUser.roles.includes('ROLE_APP_ADMIN'), [currentUser]);

  const isClinicAdmin = useMemo(() => currentUser
    && currentUser.clinicAdmin, [currentUser]);

  const enableEdit: boolean | undefined = isSystemAdmin || isClinicAdmin;

  const [foundPatients, setFoundPatients] = useState<PatientDto[]>([]);
  const [clinic, setClinic] = React.useState<ClinicDto | undefined>(undefined);
  const [patientsLoaded, setPatientsLoaded] = useState<boolean>(true);
  const [dataLoaded, setDataLoaded] = React.useState<boolean>(true);

  const [searchValues, setSearchValues] = useState<Partial<PatientSearchRequest>>({
    clinicId: clinicId,
    patientSearchTerm: ''
  });
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  React.useEffect(() => {
    fetchPatients(searchValues);
    fetchClinicData();
  }, []);

  const goToPreviousPage = () => {
    if (isSystemAdmin && clinicId) {
      push('/clinic/details', {
        id: clinicId,
      });
    } else {
      push('/');
    }
  };

  const goToPatientDetails = (id: number, clinicId: number) => {
    push('/patient/details', { id: id, clinicId: clinicId });
  };

  const openEditPatient = (id: number, clinicId: number): void => {
    push('/patient', {
      id: id,
      clinicId: clinicId,
      prevPath: pathname,
    });
  };

  const onAddPatientButtonClick = () => {
    if (currentUser?.roles.includes('ROLE_APP_ADMIN')) {
      push('/patient', {
        clinicId: clinicId,
      });
    } else {
      push('/patient');
    }
  };

  const handleError = (error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.patientNotFound,
        errorUtils.doctorNotFound
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };

  const setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const handleChange = useAfterFirstRender(async ({ values }: PatientFieldProps) => {
    await setSearchValues(values);
    fetchPatients(values);
  });

  const fetchClinicData = useCallback(async () => {
    const onFinally = () => setDataLoaded(true);

    try {
      let clinicData = undefined;

      if (clinicId && isSystemAdmin) {
        clinicData = await getClinicDetails(clinicId, cancelTokenSource);
      }
      setClinic(clinicData);
    } catch (e: any) {
      handleError(e.response.data);
    } finally {
      onFinally();
    }
  }, []);

  const fetchPatients = debounce(async (values: Partial<PatientSearchRequest>) => {

    const onFinally = () => {
      setPatientsLoaded(true);
    };

    setPatientsLoaded(false);
    setFoundPatients([]);

    let newSearchValues = { ...searchValues };

    if (values) {
      newSearchValues = { ...values };
    } else {
      if (_.isEmpty(newSearchValues)) {
        newSearchValues = {
          clinicId: searchValues.clinicId,
          patientSearchTerm: '',
          active: searchValues.active
        };
      }
    }

    try {
      const patients = await searchPatients(newSearchValues, cancelTokenSource);
      setFoundPatients(patients);
    } catch (e) {
      handleError(e.response.data);
    } finally {
      onFinally();
    }

  }, 300);

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={() => {}}
        initialValues={searchValues}
        subscription={{ pristine: true, values: true }}
        render={renderSearchPatient}
      />
    );
  };

  const renderSearchPatient = ({ form, values }: FormRenderProps): React.ReactNode => {
    return (
      <>
        <div className='search-container'>
          <div className='title-h1' style={{ paddingLeft: '0.6rem' }}>{t('patient.searchTitle')}
            {isSystemAdmin ?
              <>
                &nbsp; ( {clinic?.name} )
              </> : ""
            }

          </div>
          <div className='search-form'>
            <Field
              id='patientSearchTerm'
              name='patientSearchTerm'
              component={Input}
              icon={'search'}
              iconPosition='left'
              placeholder={t('patient.placeholder')}
              autoFocus
              fluid
            />

            <CompositeButton
              type='button'
              className='action-button'
              primary
              // disabled={currentUser?.roles.includes('ROLE_APP_ADMIN')}
              onClick={onAddPatientButtonClick}
            >
              {t('button.add')}
            </CompositeButton>
          </div>
        </div>

        {errorMessages.length > 0 &&
          <div className='error'>
            <StyledErrorMessage onDismiss={() => setErrorMessage()}>
              {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
            </StyledErrorMessage>
          </div>
        }

        <Field
          id='active'
          name='active'
          component={TabComponent}
        />

        <div className='data-table'>
          {patientsLoaded && renderPatientsTableSegment(values)}
          {!patientsLoaded &&
            <Loader className='table-loader' active inline content={t('patient.loading')} />}
        </div>

        {isSystemAdmin &&
          <div className='page-actions'>
            <CompositeButton
              type='button'
              className='action-button'
              secondary
              onClick={goToPreviousPage}
              style={{ float: 'right' }}
            >
              {t('action.back')}
            </CompositeButton>
          </div>
        }

        <FormSpy subscription={{ values: true }} onChange={handleChange} />
      </>
    );
  };

  const patientsTableRowGetter = ({ index }: any) => {
    Object.assign(foundPatients[index], { index: index + 1 });
    return foundPatients[index];
  };

  const patientsTableRowRenderer = ({ className, columns, index, key, style }: any) => {
    const a11yProps = { 'aria-rowindex': index + 1 };

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role='row'
        style={style}
      >
        {columns}
      </div>
    );
  };

  const activeCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return <Icon name='check' size='small' />;
    }
    return emptyTableCell();
  };

  const renderPatientsTableSegment = (values: any) => {
    return (
      <VirtualizedTable
        rowCount={foundPatients.length}
        rowGetter={patientsTableRowGetter}
        rowRender={patientsTableRowRenderer}
        rowHeight={48}
        columns={[
          {
            width: 150,
            label: t('patient.patientId'),
            dataKey: 'id',
            cellRenderer: (patientIdCellRenderer),
          },
          {
            width: 100,
            label: t('patient.gender'),
            dataKey: 'gender',
            cellRenderer: (genderCellRenderer),
          },
          {
            width: 150,
            flexGrow: 1,
            label: t('patient.doctor'),
            dataKey: "treaterName",
            cellRenderer: (doctorCellRenderer),
          },
          {
            width: 150,
            label: t('patient.activity'),
            dataKey: 'activity',
            cellRenderer: (activityCellRenderer),
          },
          {
            width: 100,
            label: shortLabel(t("patient.numOfTestSets"),t("patient.shortNumOfTestSets")),
            dataKey: 'numOfTestSets',
          },
          {
            width: 100,
            label: shortLabel(t('patient.openTests'),t("patient.shortOpenTests")),
            dataKey: 'openedSessions',
            cellRenderer:(openTestCellRenderer)
          },
          {
            width: 100,
            label: shortLabel(t('patient.closedTests'),t("patient.shortClosedTests")),
            dataKey: 'closedSessions',
            cellRenderer:(closedTestCellRenderer)
          },
          {
            width: 100,
            label: shortLabel(t("patient.numOfExercises"),t("patient.shortNumOfExercises")),
            dataKey: 'numOfExercises',
          },
          {
            width: 100,
            label: t('patient.active'),
            dataKey: 'active',
            cellRenderer: (activeCellRenderer),
          },
          {
            width: 280,
            flexShrink: 0,
            label: t('patient.action'),
            dataKey: 'id',
            cellRenderer: (actionsCellRenderer(values)),
          },
        ]}
      />
    );
  };

  const patientIdCellRenderer = ({ rowData }: any) => {

    if (rowData) {
      return (
        <StyledSpan>
          <div className='patient-origin-id'>{rowData.originId}</div>
          <>(
            <div className='patient-id'>{rowData.id}</div>
            )
          </>
        </StyledSpan>
      );
    }
  };

  const genderCellRenderer = ({ rowData }: any) => {

    if (rowData && rowData.gender !== null) {
      return (
        <div>{t(`gender.${rowData.gender}`)}</div>
      );
    } else {
      return (
        <div>n/a</div>
      );
    }
  };

  const doctorCellRenderer = ({ rowData }: any) => {
    return (
      <div>{rowData.treaterName}</div>
    )
  }

  const activityCellRenderer = ({ rowData }: any) => {

    const numOfExercises = rowData.numOfExercises;
    const numOfTestSets = rowData.numOfTestSets;

    switch (rowData.activity) {
      case 'NONE':
      case 'CREATED':
        return <div>{t('patientTesting.activity.PatientModified')}</div>;
      case 'OPEN':
      case 'RUNNING':
      case 'COMPLETED':
      case 'ARCHIVED':
        if (numOfExercises > 0 && numOfTestSets == 0) {
          return <div>{t('patientTesting.activity.Exercising')}</div>;
        } else if (numOfExercises == 0 && numOfTestSets > 0) {
          return <div>{t('patientTesting.activity.Testing')}</div>;
        } else {
          return <div>{t('patientTesting.activity.TestingAndExercising')}</div>;
        }
    }
  };

  const openTestCellRenderer = ({rowData }: any) => {
    if(rowData.openedSessions) {
      return (
        <div>{rowData.openedSessions}</div>
      )
    }
    return <div>0</div>;

  }

  const closedTestCellRenderer = ({rowData }: any) => {
    if(rowData.closedSessions) {
      return (
        <div>{rowData.closedSessions}</div>
      )
    }
    return <div>0</div>;

  }

  const deletePatient = (id: number, values: Partial<PatientSearchRequest>) => (): void => {
    deletePatientById(id, cancelTokenSource)
    .then(() => {
      fetchPatients(values);
    });
  };

  const actionsCellRenderer = (values: Partial<PatientSearchRequest>) => ({ rowData }: any) => {

    return (
      <div className='row-actions'>
        <InnerTableActionButton
          message={t('button.details')}
          onConfirm={() => goToPatientDetails(rowData.id, rowData.clinicId)}
          divider={true}
        />
        <InnerTableActionButton
          message={t('button.edit')}
          onConfirm={() => openEditPatient(rowData.id, rowData.clinicId)}
          divider={true}
        />
        <DeleteRecordConfirmation triggerButtonText={t('button.delete')}
                                  confirmAction={deletePatient(rowData.id, values)}
                                  deleteConfirmationText={t('patient.confirmDelete', {originid: rowData.originId})}
                                  position={"top left"} />
      </div>
    );
  };
  return (
    <SearchDisplayContainer>
      {renderFinalForm()}
    </SearchDisplayContainer>
  );
};

export default PatientSearchView;