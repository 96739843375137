import React, { Component } from 'react';
import { DividerProps } from 'semantic-ui-react';
import styled from 'styled-components';

const InstructionContent = styled.textarea`
  background-color: white; 
  line-height: 20px;
  border: none;
  overflow: auto;
  min-height: 180px;
  min-width: 1300px;
  max-width: 1300px;
  
  :focus {
    outline: none;
  }
  
  @media only screen and (max-width: 1836px) {
    min-width: 1100px;
    max-width: 1100px;
  }
  
  @media only screen and (max-width: 1512px) {
    min-width: 1000px;
    max-width: 1000px;
  }
  
  @media only screen and (max-width: 1410px) {
    min-width: 800px;
    max-width: 800px;
  }
  
  @media only screen and (max-width: 1210px) {
    min-width: 700px;
    max-width: 700px;
  }
  
  @media only screen and (max-width: 1111px) {
    min-width: 600px;
    max-width: 600px;
  }
`;

class InstructionPopupContent extends Component<DividerProps> {

  render(): React.ReactNode {
    const {children, ...rest} = this.props;
    return (
      <InstructionContent {...rest}>
        {children}
      </InstructionContent>
    )
  }
}

export default InstructionPopupContent;