import React from 'react';
import { Button, ButtonProps, Icon, Popup } from 'semantic-ui-react';

interface BtnCustomProps extends ButtonProps {
  customTrigger?: React.ReactNode,
  toolbarButtonStyle?: Object,
  popupStyle?: Object
}

const defaultToolbarBtnStyle = {
  background: 'transparent',
  cursor: 'pointer',
  padding: '5px 7px',
  color: '#000000'
};

export const defaultPopupStyle = {
  borderRadius: 'unset',
  padding: '7px',
};

const HeaderButton: React.FC<BtnCustomProps> = (props: BtnCustomProps) => {

  const {
    iconName,
    iconSize,
    disabled = false,
    onClick,
    dataTooltip,
    customTrigger,
    toolbarButtonStyle = {},
    popupStyle = {},
    ...restProps
  } = props;

  const ToolbarBtnStyle = {
    ...defaultToolbarBtnStyle,
    ...toolbarButtonStyle,
  };

  const BtnPopupStyle = {
    ...defaultPopupStyle,
    ...popupStyle,
  };

  const renderTrigger = (): React.ReactNode => {

    const { customTrigger, iconName, iconSize } = props;

    if (customTrigger) {
      return customTrigger;
    } else {
      return <Icon name={iconName} size={iconSize} />;
    }
  };

  return (
    <Button type='button' icon {...restProps}
            disabled={disabled}
            style={ToolbarBtnStyle}
            onClick={onClick}
    >

      <Popup
        popperModifiers={[{ name: 'preventOverflow', options: {boundary: 'window'} }]}
        trigger={renderTrigger()}
        content={dataTooltip}
        size='small'
        position='top center'
        style={BtnPopupStyle}
      />
    </Button>
  );
};

export default HeaderButton;
