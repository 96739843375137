import axios from 'axios';
import Input from 'components/final-form/Input';
import CompositeButton from 'components/final-form/CompositeButton';
import InnerTableActionButton from 'components/InnerTableActionButton';
import LoaderComponent from 'components/LoaderComponent';
import TsaGrid from 'components/TsaGrid';
import { FormState } from 'final-form';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Table } from 'semantic-ui-react';
import { searchTestResultParamsForTestResult } from 'service/testResultParamServices';
import styled from 'styled-components';
import { debounce } from 'ts-debounce';
import { ScopeType } from 'ts-types/api.enums';
import { ExerciseResultParamDto, ParamSearchRequest, TestResultParamDto } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { emptyTableRows } from 'util/tableUtils';

const TableContainer = styled.div`
  flex-grow: 1;
  cursor: context-menu;
  max-height: 180px;
  overflow: auto;
  border-radius: unset;
  border: 1px solid var(--light-gray) !important;

  & .ui.table tr {
    height: 30px;
    border: none;
  }

  .ui.table tr td {
    border-top: none;
  }

  .no-padding {
    border: 1px solid var(--light-gray) !important;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 1rem;
  }

  .ui.basic.striped.table tbody tr:nth-child(2n) {
    background-color: var(--very-light-gray) !important;
  }

`;

const ActionContainer = styled.div`
  flex-grow: 1;
  margin-top: 1rem;
  border-radius: unset;
  
  .ui.secondary.button, .ui.secondary.buttons .button {
    background-color: var(--secondary-color);

    :hover {
      background-color: transparent;
      border-color: var(--secondary-color);
      color: var(--secondary-color);
    }

    :focus {
      background-color: transparent;
      border-color: var(--secondary-color);
      color: var(--secondary-color);
    }
  }
`;

interface Props {
  testResultParams: TestResultParamDto[];
  selectedTestResultParams: Array<TestResultParamDto | ExerciseResultParamDto>;
  scopeType: ScopeType;
  onAddTestResultParam?: (testResultParam: TestResultParamDto) => void;
  onAddExerciseResultParam?: (exerciseResultParamId: number) => void;
  onCancel: () => void;
}

interface TestResultParamFieldProps extends FormState<any> {
  values: Partial<ParamSearchRequest>;
}

const cancelTokenSource = axios.CancelToken.source();

const TestConfAddParamsModal = (props: Props) => {

  const { t } = useTranslation('teresa');

  const {
    onCancel,
    testResultParams,
    selectedTestResultParams,
    scopeType,
    onAddTestResultParam,
    onAddExerciseResultParam
  } = props;

  const mapSelectedParamIds = () => {
    return selectedTestResultParams.map(t => {
      if ('id' in t) {
        return t.id;
      }
      return t.paramId;
    });
  };

  const [searchValues, setSearchValues] = useState<Partial<ParamSearchRequest>>({
    code: '',
    selectedTestResultParamIds: mapSelectedParamIds(),
    scopeType: scopeType
  });

  const [availableTestResultParams, setAvailableTestResultParams] = useState<TestResultParamDto[]>([]);
  const [formDataLoaded, setFormDataLoaded] = useState<boolean>(true);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    searchTestResultParams(searchValues);
  }, [selectedTestResultParams, testResultParams]);


  const handleError = useCallback((error: any) => {

    if (error) {
      let errMsgs = [];
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [errorUtils.invalidEmail];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsgs.push(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          errMsgs.push(t(`error.${errorCode}`));
        } else {
          errMsgs.push(t('error.general'));
        }
      }

      setErrorMessage(errMsgs);
    }

  }, []);

  const setErrorMessage = (errMsgs?: string[]) => {

    if (errMsgs) {
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const handleChange = ({ values }: TestResultParamFieldProps) => {
    setSearchValues(values);
    searchTestResultParams(values);
  };

  const searchTestResultParams = debounce(async (values: Partial<ParamSearchRequest>) => {

    const onFinally = () => {
      setFormDataLoaded(true);
    };

    setFormDataLoaded(false);
    setAvailableTestResultParams([]);

    let newSearchValues = { ...searchValues };

    if (values) {
      newSearchValues = { ...values, selectedTestResultParamIds: mapSelectedParamIds() };
    } else {
      if (_.isEmpty(newSearchValues)) {
        newSearchValues = {
          code: '',
          selectedTestResultParamIds: mapSelectedParamIds(),
          scopeType: scopeType
        };
      }
    }

    try {
      const paramsResponse = await searchTestResultParamsForTestResult(newSearchValues, cancelTokenSource);
      setAvailableTestResultParams(paramsResponse);
    } catch (e) {
      handleError(e.response.data);
    } finally {
      onFinally();
    }

  }, 300);

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={() => {}}
        initialValues={searchValues}
        subscription={{ pristine: true, values: true }}
        render={renderTable}
      />
    );
  };

  const renderTable = () => {

    return (
      <TsaGrid>
        <Grid.Row>
          <Grid.Column width={5} floated='right'>
            <Field
              id='code'
              name='code'
              component={Input}
              icon={'search'}
              iconPosition='left'
              placeholder={t('testresult.search.placeholder')}
              autoFocus
              fluid
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {!formDataLoaded
            ? <LoaderComponent message={t('testresult.loading')} />
            : <Grid.Column width={16} verticalAlign='middle' className='no-padding'>
              <TableContainer>
                <Table basic='very' striped size='small'>
                  <Table.Body className='table-body'>
                    {availableTestResultParams.map(testResultParam => {
                      return <Table.Row key={testResultParam.id}>
                        <Table.Cell width={3}>
                          {testResultParam.description}
                        </Table.Cell>
                        <Table.Cell width={3}>
                          {testResultParam.unit}
                        </Table.Cell>
                        <Table.Cell width={7} flexgrow={1}>
                          {testResultParam.code}
                        </Table.Cell>
                        <Table.Cell width={2}>
                          <div className='row-actions'>
                            <InnerTableActionButton
                              message='Add'
                              onConfirm={() => onAddTestResultParam
                                ? onAddTestResultParam(testResultParam)
                                : onAddExerciseResultParam!(testResultParam.id)
                            } />
                          </div>
                        </Table.Cell>
                      </Table.Row>;
                    })}
                    {emptyTableRows(availableTestResultParams.length, 4)}
                  </Table.Body>
                </Table>
              </TableContainer>
            </Grid.Column>}
          <Grid.Column width={16}>
            <ActionContainer>
              <CompositeButton
                type='button'
                className='action-button'
                style={{ whiteSpace: 'nowrap' }}
                secondary
                floated='right'
                onClick={onCancel}
              >
                {t('button.close')}
              </CompositeButton>
            </ActionContainer>
          </Grid.Column>
        </Grid.Row>
        <FormSpy subscription={{ values: true }} onChange={handleChange} />
      </TsaGrid>
    );
  };

  return <>{renderFinalForm()}</>;
};

export default TestConfAddParamsModal;