import { CancelTokenSource } from 'axios';
import axios, { backendUrls } from 'service/http';
import { ExerciseConfExportDto } from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/exerciseconfexport`;

export const getExerciseConfsExport = (source: CancelTokenSource): Promise<{
  [index: string]: ExerciseConfExportDto
}> => {
  return axios
  .get(`${apiUri}/export`, { cancelToken: source.token })
  .then(response => response.data);
};