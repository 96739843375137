import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import CheckBox from 'components/final-form/CheckBox';
import DataLabel from 'components/final-form/DataLabel';
import Input from 'components/final-form/Input';
import InnerTableActionButton from 'components/InnerTableActionButton';
import TableContainer from 'components/TableContainerDiv';
import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { Grid, Icon, Popup, Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { UpsertMandateBankDto } from 'ts-types/api.types';
import { ibanValidator, required } from 'util/validatorUtils';

const ActionContainer = styled.div`
  flex-grow: 1;
  border-radius: unset;
  margin-left: 5px;
  padding-top: 34px;
`;

const PopupStyle = {
  borderRadius: 'unset',
};

interface Props {
  existingMandateBanks?: UpsertMandateBankDto[];
}

const MandateBankTable = (props: Props) => {

  const { t } = useTranslation('teresa');
  const { existingMandateBanks = [] } = props;

  const contextRef = React.useRef<any>(undefined);

  const [editMandateBank, setEditMandateBank] =
    useState<boolean>(false);
  const [editMandateBankIndex, setEditMandateBankIndex] =
    useState<number | undefined>(undefined);
  const [mandateBanks, setMandateBanks] =
    useState<Partial<UpsertMandateBankDto>[]>([...existingMandateBanks]);
  const [mandateBankCopy, setMandateBankCopy] =
    useState<Partial<UpsertMandateBankDto> | undefined>(undefined);
  const [errors, setErrors] = useState<boolean[]>([false, false]);

  const validateFields = (mandateBank: Partial<UpsertMandateBankDto>): boolean => {

    let errors: Array<boolean> = [];

    errors[0] = required(mandateBank.description) !== undefined;

    if (mandateBank.iban) {
      errors[1] = ibanValidator(mandateBank.iban) !== undefined
    } else {
      errors[1] = required(mandateBank.iban) !== undefined;
    }

    setErrors(errors);

    return errors.includes(true);
  };

  const addMandateBank = (fields: any, index: number) => {

    const newMandateBank: Partial<UpsertMandateBankDto> = {
      description: '',
      city: '',
      iban: '',
      defaultBank: false,
    };

    fields.push(newMandateBank);
    setEditMandateBank(true);
    setEditMandateBankIndex(index);
    setMandateBanks(mandateBanks ? [...mandateBanks, newMandateBank] : [newMandateBank]);
  };

  const deleteMandateBank = (index: number, fields: any) => {

    fields.remove(index);

    setMandateBanks(mandateBanks.filter((item, ix) => ix != index));
  };

  const saveMandateBank = (fields: any, index: number) => {

    const mandateBanks: Partial<UpsertMandateBankDto>[] = fields.value;
    const mandateBank: Partial<UpsertMandateBankDto> = mandateBanks[index];

    if (!validateFields(mandateBank)) {

      if (mandateBank.defaultBank) {
        mandateBanks.forEach((mb: Partial<UpsertMandateBankDto>, mbIndex: number) => {
          mb.defaultBank = mbIndex === index;
        });
      }

      setMandateBanks(mandateBanks);
      setEditMandateBank(false);
      setEditMandateBankIndex(undefined);
    }
  };

  const cancelEditMandateBank = (fields: any, index: number) => {

    setEditMandateBank(false);
    setEditMandateBankIndex(undefined);
    setMandateBankCopy(undefined);
    setErrors([false, false]);

    fields.remove(index);
    if (mandateBankCopy) {
      fields.insert(index, mandateBankCopy);
    }
  };

  const onEditMandateBank = (index: number, field: Partial<UpsertMandateBankDto>, fields: any): void => {

    if (editMandateBank) {

      fields.remove(editMandateBankIndex);
      fields.insert(editMandateBankIndex, mandateBankCopy);

      setEditMandateBankIndex(index);
      setMandateBankCopy(field);
    } else {

      setEditMandateBank(true);
      setEditMandateBankIndex(index);
      setMandateBankCopy(field);
    }
  };

  const renderIbanInfoIcon = () => {
    return (
       <Popup
         trigger={ <Icon name='info circle' link/>}
         context={contextRef}
         content={renderIbanIconContent}
         size='small'
         position='top center'
         on='hover'
       />
    )
  }

  const renderIbanIconContent = () => {
    return (
      <div style={{display: 'inline-flex', flexDirection: 'column'}}>
        <div>{t('iban.info.message.explanation')}</div>
        <strong>{t('iban.info.message.example')}</strong>
      </div>
    )
  }

  const defaultBankCellRenderer = (data: any) => {

    const style = { color: 'var(--primary-color)' };

    const activeIcon = data.defaultBank
      ? <Popup
        trigger={<Icon name='check circle' style={style} />}
        content={t('mandateBank.default')}
        size='small'
        position='top center'
        on='click'
        style={PopupStyle}
      />
      : <Popup
        trigger={<Icon name='circle outline' />}
        content={t('mandateBank.optional')}
        size='small'
        position='top center'
        on='click'
        style={PopupStyle}
      />;

    return (
      <div>
        {activeIcon}
      </div>
    );
  };

  const actionCellRenderer = (index: number, fields: any) => {

    const mandateBank = fields.value[index];

    return <>
      <InnerTableActionButton
        message={t('button.edit')}
        onConfirm={() => onEditMandateBank(index, fields.value[index], fields)}
        divider={true}
      />

      <DeleteRecordConfirmation
        triggerButtonText={t('mandateBank.delete')}
        confirmAction={() => deleteMandateBank(index, fields)}
        deleteConfirmationText={t('mandateBank.confirmDelete', { description: mandateBank.description })}
        position={'top left'}
      />
    </>;
  };

  const renderMandateBankForm = (field: string, index: number, fields: any) => {

    const mandateBank = fields.value[index];
    const validDescription = required(mandateBank.description) !== undefined;
    const validIban = mandateBank.iban
      ? ibanValidator(mandateBank.iban) !== undefined
      : required(mandateBank.iban) !== undefined;

    return (
      <React.Fragment key={index}>
        <Table.Row>
          <Table.Cell width={16} className={'single-line-cell-class'}>
            <Field
              fluid
              name={`${field}.description`}
              component={Input}
              autoFocus
              validate={required}
            />
          </Table.Cell>
          <Table.Cell width={10} className={'single-line-cell-class'}>
            <Field
              fluid
              name={`${field}.city`}
              component={Input}
            />
          </Table.Cell>
          <Table.Cell width={16} className={'single-line-cell-class'}>
            <div ref={contextRef}>
              <Field
                fluid
                name={`${field}.iban`}
                component={Input}
                validate={ibanValidator}
                icon={renderIbanInfoIcon}
              />
            </div>
          </Table.Cell>
          <Table.Cell width={10} className={'single-line-cell-class'}>
            <Field
              name={`${field}.defaultBank`}
              component={CheckBox}
              toggle={true}
            />
          </Table.Cell>
          <Table.Cell width={10} className={'single-line-cell-class'}>
            <InnerTableActionButton
              message={t('button.save')}
              onConfirm={() => saveMandateBank(fields, index)}
              divider={true}
              disabled={validDescription || validIban}
            />
            <InnerTableActionButton
              message={t('button.cancel')}
              onConfirm={() => cancelEditMandateBank(fields, index)}
            />
          </Table.Cell>
        </Table.Row>
      </React.Fragment>
    );
  };

  const renderMandateBanksTable = (mandateBank: UpsertMandateBankDto, index: number, fields: any) => {
    return (
      <React.Fragment key={index}>
        <Table.Row>
          <Table.Cell width={16} className={'single-line-cell-class'}>
            {mandateBank.description}
          </Table.Cell>
          <Table.Cell width={10} className={'single-line-cell-class'}>
            {mandateBank.city}
          </Table.Cell>
          <Table.Cell width={16} className={'single-line-cell-class'}>
            {mandateBank.iban}
          </Table.Cell>
          <Table.Cell width={10} className={'single-line-cell-class'}>
            {defaultBankCellRenderer(mandateBank)}
          </Table.Cell>
          <Table.Cell width={10} className={'single-line-cell-class'}>
            {actionCellRenderer(index, fields)}
          </Table.Cell>
        </Table.Row>
      </React.Fragment>
    );
  };

  return (
    <Grid.Row>
      <Grid.Column width={15}>
        <DataLabel>{t('mandate.mandateBanks')}</DataLabel>
        <TableContainer>
          <Table basic='very' size='small'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={16} className={'single-line-cell-class'}>
                  {t('mandateBank.description')}
                </Table.HeaderCell>
                <Table.HeaderCell width={10} className={'single-line-cell-class'}>
                  {t('mandateBank.city')}
                </Table.HeaderCell>
                <Table.HeaderCell width={16} className={'single-line-cell-class'}>
                  {t('mandateBank.iban')}
                </Table.HeaderCell>
                <Table.HeaderCell width={10} className={'single-line-cell-class'}>
                  {t('mandateBank.defaultBank')}
                </Table.HeaderCell>
                <Table.HeaderCell width={10} className={'single-line-cell-class'}>
                  {t('mandate.actions')}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body className='table-body'>
              <FieldArray name='mandateBanks'>
                {({ fields }) =>
                  fields.map((field, index: number) => {
                    if (fields.value[index] !== undefined) {
                      return (
                        (editMandateBank && editMandateBankIndex === index)
                          ? renderMandateBankForm(field, index, fields)
                          : renderMandateBanksTable(fields.value[index], index, fields)
                      );
                    }
                  })
                }
              </FieldArray>
            </Table.Body>
          </Table>
        </TableContainer>
      </Grid.Column>
      <Grid.Column>
        <FieldArray name='mandateBanks'>
          {({ fields }) => (
            <ActionContainer>
              <span>
                <InnerTableActionButton
                  message={t('button.add')}
                  onConfirm={() => {
                    addMandateBank(fields, fields.length!);
                  }}
                />
              </span>
            </ActionContainer>
          )}
        </FieldArray>
      </Grid.Column>
    </Grid.Row>
  );

};
export default MandateBankTable;