import { Cancel, CancelTokenSource } from 'axios';
import axios, { backendUrls } from 'service/http';
import {
  CalculateTestStatisticRequest,
  TestResultDto,
  TestResultParamData,
  UpsertTestResultDto,
} from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/testresults`;

export const getAllTestResults = (source: CancelTokenSource): Promise<TestResultDto[]> => {
  return axios
  .get(apiUri, { cancelToken: source.token })
  .then(response => response.data);
}

export const calculateTestResultParamsStatistic = (
  calculateTestStatisticRequest: CalculateTestStatisticRequest, source: CancelTokenSource):
  Promise<TestResultParamData[]> => {
  return axios
  .post(`${apiUri}/all-with-statistic`, calculateTestStatisticRequest, { cancelToken: source.token })
  .then(response => response.data);
}

export const getTestResult = (id: number, source: CancelTokenSource): Promise<UpsertTestResultDto> => {
  return axios
    .get(`${apiUri}/${id}`, { cancelToken: source.token })
    .then(response => response.data);
}

export const addTestResult = (testResult: Partial<UpsertTestResultDto>, source: CancelTokenSource):
  Promise<TestResultDto> => {
  return axios.post(apiUri, testResult, { cancelToken: source.token })
    .then(response => response.data);
}

export const editTestResult = (testResultId: any, testResult: Partial<UpsertTestResultDto>, source: CancelTokenSource):
  Promise<TestResultDto> => {
  return axios.put(`${apiUri}/${testResultId}`, testResult, { cancelToken: source.token })
    .then(response => response.data);
}