import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import AdminTabComponent from 'components/final-form/AdminTabComponent';
import CompositeButton from 'components/final-form/CompositeButton';
import Input from 'components/final-form/Input';
import InnerTableActionButton from 'components/InnerTableActionButton';
import SearchDisplayContainer from 'components/SearchDisplayContainer';
import SendActivationKeyPopup from 'components/SendActivationKeyPopup';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import VirtualizedTable from 'components/VirtualizedTable';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import React, { useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Grid, Header, Icon, Loader, Popup } from 'semantic-ui-react';
import { deactivateAdmin, generateRegKey, resendInvitation, searchAccounts } from 'service/accountService';
import { deleteAccount } from 'service/adminService';
import axios from 'service/http';
import styled from 'styled-components';
import { debounce } from 'ts-debounce';
import { AccountDto, AccountSearchRequest, TriggerAccountActivationRequest } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { useAfterFirstRender } from 'util/functionUtils';
import { defaultContentCellRenderer, emptyTableCell, shortLabel } from 'util/tableUtils';

const StyledPopupContentText = styled.div`
  font-size: 12px;
  
  margin-top: -15px;
  
  width: 300px;
  
  & > strong {
    display: block;
  }
`;

const PopupStyle = {
  borderRadius: 'unset',
};

const ActiveActions = styled.span`
  float: right;
`;

interface ExtendedAccountSearchRequest extends AccountSearchRequest {
  selectedTab: number;
}

const cancelTokenSource = axios.CancelToken.source();

interface Props {
}

const AdminView = (props: Props) => {

  const { t } = useTranslation('teresa');
  const history = useHistory();
  const isIpad = useIsIpadWidthOrBelow();
  const { state } = useLocation();

  const [adminsLoaded, setAdminsLoaded] = useState<boolean>(true);
  const [foundAdmins, setFoundAdmins] = useState<AccountDto[]>([]);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const prevSelectedTab = state ? state.prevSelectedTab : 0;
  const [successMessage, setSuccessMessage] =
    useState<string | undefined>(undefined);

  React.useEffect(() => {
    fetchAdmins({ searchKey: '', selectedTab: prevSelectedTab});
  }, []);

  const handleError = (error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.userNotAdmin,
        errorUtils.invalidEmail,
        errorUtils.accountNotFound
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      } else {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };

  const setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const fetchAdmins = debounce((values: Partial<ExtendedAccountSearchRequest>): void => {

    const onFinally = () => {
      setAdminsLoaded(true);
    };

    setAdminsLoaded(false);

    const searchKey = values.searchKey;
    let isDoctor = false;
    let active = undefined;
    if (values.selectedTab === 1) {
      active = true;
    } else if (values.selectedTab === 2) {
      active = false;
    } else if (values.selectedTab === 3) {
      isDoctor = true
    }

    let newSearchValues: Partial<AccountSearchRequest> = {
      searchKey: searchKey ? searchKey : '', active, isDoctor
    };

    searchAccounts(newSearchValues, cancelTokenSource)
    .then(response => {
        setFoundAdmins(response);
      },
    )
    .catch((e: any) => handleError(e.response.data))
    .finally(onFinally);

  }, 300);

  const adminsRowGetter = ({ index }: any) => {

    Object.assign(foundAdmins[index], { index: index + 1 });

    return foundAdmins[index];
  };

  const adminsRowRenderer = (values: any) => ({ className, columns, index, key, style }: any) => {
    const a11yProps = { 'aria-rowindex': index + 1 };

    const adminId = foundAdmins[index].id;

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role='row'
        style={style}
        onDoubleClick={() => openAdminDetails(adminId!, values.selectedTab)}
      >
        {columns}
      </div>
    );
  };

  const activateAccount = (values: any) => (id: number, request: Partial<TriggerAccountActivationRequest>) => {
    generateRegKey(id, request)
    .then(() => {
      fetchAdmins(values);
    })
      .catch((e: any) => handleError(e.response.data));
  };

  const resendActivateAccount = (values: any) => (id: number, request: Partial<TriggerAccountActivationRequest>) => {
    resendInvitation(id, request)
    .then(() => {
      fetchAdmins(values);
    })
    .catch((e) => {});
  };

  const deactivateAccountButton = (id: number, values: any) => {
    deactivateAdmin(id).then(() => {
      fetchAdmins(values);
    });
  };

  const rolesCellRenderer = ({ cellData }: any) => {
    if (cellData && cellData.length) {
      const formattedRoles = cellData.map((e: string) => {
        if (isIpad) {
          e += '_SHORT';
        }
        return t(`role.${e}`);
      }).join(', ');

      return formattedRoles;
    }
    return emptyTableCell();
  };

  const isDoctorCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return <Icon name='check' size='small'/>;
    }
    return emptyTableCell();
  };

  const use2faCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return <Icon name='check' size='small' />;
    }
    return emptyTableCell();
  };

  const emailCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return cellData;
    }
    return emptyTableCell();
  };

  const handleChange = useAfterFirstRender(({ values }: any): void => {
    fetchAdmins(values);
  });

  const onAddTestAdminButtonClick = (isTestAdmin: boolean) => {
    history.push('/account/test-admin', { isTestAdmin: isTestAdmin })
  }

  const removeAdmin = (id: number): void => {
    deleteAccount(id, cancelTokenSource)
      .then(() => {
        setSuccessMessage(t("admin.successfullyDeleted.message"));
        fetchAdmins({ searchKey: '' });
      })
      .catch((e: any) => handleError(e.response.data));
  }

  const popupContent = (
    id: number,
    resend: boolean,
    func: (id: number, request: Partial<TriggerAccountActivationRequest>) => void,
    email?: string) => {

    return {
      id: id,
      resend: resend,
      func: func,
      email: email,
    };
  };

  const renderRegInfoPopup = (regKey: any) => {

    return (
      <div>
        <TsaGrid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Header as='h4'>
                {t('admin.registrationKey')}
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <StyledPopupContentText>
                {t('admin.regInfo.desc')} <strong>{regKey}</strong>
              </StyledPopupContentText>
            </Grid.Column>
          </Grid.Row>
        </TsaGrid>
      </div>

    );
  };

  const activeCellRenderer = (values: any) => ({ rowData }: any) => {

    const style = { color: 'var(--primary-color)' };

    const activeIcon = rowData.active ?
      (<Popup
        trigger={<Icon name='check circle' style={style} />}
        content={t('admin.active')}
        size='small'
        position='top center'
        on='click'
        style={PopupStyle}
      />) : !!rowData.regKey ?
        (<Popup
          style={PopupStyle}
          trigger={<Icon name='key' style={style} />}
          size='huge'
          position='top center'
          on='click'
          content={renderRegInfoPopup(rowData.regKey)} />) :
        (<Popup
          trigger={<Icon name='circle outline' />}
          content={t('admin.inactive')}
          size='small'
          position='top center'
          on='click'
          style={PopupStyle}
        />);

    const activeActions = rowData.active ?
      <InnerTableActionButton message={t('admin.deactivate')}
                              onConfirm={() => deactivateAccountButton(rowData.id, values)}
      />
      : !!rowData.regKey
        ? <React.Fragment>
          <InnerTableActionButton message={isIpad ? t('admin.removeKeyShort') : t('admin.removeKey')}
                                  onConfirm={() => deactivateAccountButton(rowData.id, values)}
                                  divider={true}
          />
          <SendActivationKeyPopup
            triggerButtonText={isIpad ? 'admin.resendInvShort' : 'admin.resendInv'}
            content={popupContent(rowData.id, true, resendActivateAccount(values), rowData.email)}
            position='left center'
            displayModal={isIpad}
            tableButton={true}
          />
        </React.Fragment> :
        <SendActivationKeyPopup
          triggerButtonText='admin.activate'
          content={popupContent(rowData.id, false, activateAccount(values), rowData.email)}
          position='left center'
          displayModal={isIpad}
          tableButton={true}
        />;
    return (
      <div>
        {activeIcon}
        <ActiveActions>
          {activeActions}
        </ActiveActions>
      </div>
    );
  };

  const openAdminDetails = (id: number, selectedTab: number): void => {
    history.push('/account', {
      id: id,
      selectedTab: selectedTab
    });
  };


  const adminActionCellRenderer = (values: any) => ({ rowData }: any) => {
    const staffTadSelected = values.selectedTab === 3;

    return (
      <div className='row-actions'>
        <InnerTableActionButton
          message={t('admin.edit')}
          onConfirm={() => openAdminDetails(rowData.id, values.selectedTab)}
          divider={!staffTadSelected && !rowData.doctorId}
        />
        {!staffTadSelected && !rowData.doctorId &&
          <DeleteRecordConfirmation
            triggerButtonText={t('button.delete')}
            confirmAction={() => removeAdmin(rowData.id)}
            deleteConfirmationText={t('admin.confirmDelete', {adminName: rowData.name + rowData.lastName})}
            position={'top left'}
          />}
      </div>
    );

  };

  const renderAdminsTable = (values: any): JSX.Element => {

    let columns: React.ReactNode[] = [
      {
        width: 225,
        label: t('admin.clinic'),
        dataKey: 'clinicName',
        cellRenderer: defaultContentCellRenderer
      },
      {
        width: 225,
        label: t('admin.userName'),
        dataKey: 'username',
        cellRenderer: defaultContentCellRenderer
      },
    ];

    if (!isIpad) {
      columns.push(
        {
          width: 200,
          label: t('admin.name'),
          dataKey: 'name',
          cellRenderer: defaultContentCellRenderer
        },
        {
          width: 200,
          label: t('admin.lastName'),
          dataKey: 'lastName',
          cellRenderer: defaultContentCellRenderer
        },
      );
    }

    columns.push(
      {
        width: 200,
        label: t('admin.email'),
        dataKey: 'email',
        cellRenderer: emailCellRenderer
      },
      {
        width: 100,
        label: shortLabel(t('admin.use2fa'),t('admin.use2faShort')),
        dataKey: 'use2fa',
        cellRenderer: use2faCellRenderer,
      },
      {
        width: isIpad ? 75 : 200,
        label:  t('admin.roles'),
        dataKey: 'accountRoles',
        cellRenderer: rolesCellRenderer
      },
      {
        width: 100,
        label: t('admin.isDoctor'),
        dataKey: 'doctorId',
        cellRenderer: isDoctorCellRenderer
      },
      {
        width: isIpad ? 500 : 400,
        flexGrow: 1,
        label: t('admin.active'),
        dataKey: 'active',
        cellRenderer: activeCellRenderer(values)
      },
      {
        width: 170,
        label: t('admin.actions'),
        dataKey: 'id',
        cellRenderer: adminActionCellRenderer(values),
      });


    return (
      <VirtualizedTable
        rowCount={foundAdmins.length}
        rowGetter={adminsRowGetter}
        rowRenderer={adminsRowRenderer(values)}
        columns={columns}
      />
    );
  };

  const renderAdminForm = (): JSX.Element => {
    return (
      <FinalForm
        onSubmit={() => {
        }}
        initialValues={{
          active: undefined,
          searchKey: '',
          selectedTab: state ? state.prevSelectedTab : 0
        }}
        subscription={{ pristine: true, values: true }}
        render={renderSearchFormContent}
      />
    );
  };

  const renderSearchFormContent = (({ values }: FormRenderProps): React.ReactNode => {

    return (
      <>
        <div className='search-container'>
          <div className='title-h1'>{t('admin.viewTitle')}</div>
          <div className='search-form'>
            <Field
              id='searchKey'
              name='searchKey'
              component={Input}
              icon={'search'}
              iconPosition='left'
              placeholder={t('admin.placeholder')}
              autoFocus
              fluid
            />

            <CompositeButton
              type='button'
              className='action-button'
              onClick={() => history.push('/account', { isTestAdmin: false })}
              primary
              style={{ display: 'inline-block' }}
            >
              {t('button.add')}
            </CompositeButton>
          </div>
        </div>

        <Field
          id='selectedTab'
          name='selectedTab'
          component={AdminTabComponent}
        />

        {
          errorMessages.length > 0 &&
          <div className='error-message'>
            <StyledErrorMessage onDismiss={() => setErrorMessage()}>
              {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
            </StyledErrorMessage>
          </div>
        }

        {
          successMessage != undefined &&
          <div className='error-message'>
            <StyledErrorMessage
              onDismiss={() => setSuccessMessage(undefined)}
              color='green'
            >
              {successMessage}
            </StyledErrorMessage>
          </div>
        }

        <div className='data-table'>
          {adminsLoaded && renderAdminsTable(values)}
          {!adminsLoaded &&
            <Loader className='table-loader' active inline content={t('admin.loading')} />}
        </div>

        <FormSpy subscription={{ values: true }} onChange={handleChange} />
      </>
    );
  });


  return <SearchDisplayContainer>
    {renderAdminForm()}
  </SearchDisplayContainer>;

};

export default AdminView;