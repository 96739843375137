import React, { ReactNode } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

interface TitleProps {
  children: ReactNode;
}

interface ContentProps {
  children: ReactNode;
}

interface Props {
  expanded: boolean;
  onExpand: () => void;
  children: ReactNode;
}

const LineVertical = styled.div`
  //right: 0; /* Start from the right side of the container */
  //top: 50%; /* Start from the middle of the container */
  //width: 30px; /* Extend halfway across the container */
  //height: 1px; /* Adjust the thickness of the line as needed */
  //background-color: #af2424; /* Adjust the color as needed */
  //transform-origin: center left; /* Set the rotation origin to the right top corner */
  //transform: rotate(90deg); /* Rotate the line 45 degrees clockwise */
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 0;
`;

const AccordionWithArrow = ({ expanded, onExpand, children }: Props) => {
  const titleElement = React.Children.toArray(children).find(
    (child: any) => child && child.type === CustomTitle
  );

  const contentElement = React.Children.toArray(children).find(
    (child: any) => child && child.type === CustomContent
  );

  return (
    <Accordion style={{ marginTop: 0 }}>
      <Accordion.Title active={expanded} onClick={onExpand}>
        <Container>
          {expanded && <LineVertical />}
          <Icon name={expanded ? 'angle down' : 'angle right'} />
          {titleElement}
        </Container>
      </Accordion.Title>
      <Accordion.Content active={expanded}>
        {contentElement}
      </Accordion.Content>
    </Accordion>
  );
};

export const CustomTitle = ({ children }: TitleProps) => <div>{children}</div>;
export const CustomContent = ({ children }: ContentProps) => <div style={{ marginLeft: "25px"}}>{children}</div>;

export default AccordionWithArrow;
