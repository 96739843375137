import React from 'react';
import { Message } from 'semantic-ui-react';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';
import styled from 'styled-components';

const StyledErrorMessages = styled(Message)`
    width: 100%;

    &.ui.red.message {
        box-shadow: unset;
        border-radius: unset;
        white-space: pre-line;
    }

    &.ui.yellow.message {
        box-shadow: unset;
        border-radius: unset;
        white-space: pre-line;
    }

    &.ui.message > .close.icon {
        vertical-align: middle;
        top: auto;
    }
`;

interface DivWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  [key: string]: any,
  color?: SemanticCOLORS,
}

export class StyledErrorMessage extends React.Component<DivWrapperProps> {
  render() {
    const { children, color = 'red', ...rest } = this.props;
    return <StyledErrorMessages color={color} {...rest}>
      {children}
    </StyledErrorMessages>;
  }
}

export default StyledErrorMessage;
