import { useAuthContext } from 'auth/AuthContext';
import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import CompositeButton from 'components/final-form/CompositeButton';
import Input from 'components/final-form/Input';
import TabComponent from 'components/final-form/TabComponent';
import InnerTableActionButton from 'components/InnerTableActionButton';
import SearchDisplayContainer from 'components/SearchDisplayContainer';
import StyledErrorMessage from 'components/StyledErrorMessage';
import VirtualizedTable from 'components/VirtualizedTable';
import { FormState } from 'final-form';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Icon, Loader } from 'semantic-ui-react';
import axios from 'service/http';
import { deleteTestConf, searchTestConfs } from 'service/testconfService';
import styled from 'styled-components';
import { debounce } from 'ts-debounce';
import { TestConfDto, TestConfSearchRequest } from 'ts-types/api.types';
import { isoToDisplayDateTime } from 'util/dateUtils';
import { useAfterFirstRender } from 'util/functionUtils';
import { emptyTableCell, multiLanguageFieldCellRenderer, shortLabel } from 'util/tableUtils';

const StyledSpan = styled.span`
  display: flex;
  
  .patient-origin-id{
    margin-right: 0.5rem;
  }
  
  .testconf-id {
    color: var(--primary-color);
  }
`;

interface TestConfFieldProps extends FormState<any> {
  values: Partial<TestConfSearchRequest>;
}

interface Props {

}

const cancelTokenSource = axios.CancelToken.source();

const TestConfSearchView = (props: Props) => {

  const { push } = useHistory();
  const { t } = useTranslation('teresa');
  const { language, currentUser } = useAuthContext();

  const [testConfigs, setTestConfigs] = useState<TestConfDto[]>([]);
  const [testConfigsLoaded, setTestConfigsLoaded] = useState<boolean>(true);

  const [searchValues, setSearchValues] = useState<Partial<TestConfSearchRequest>>({
    testConfSearchTerm: ''
  });
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const isSystemAdmin = useMemo(() => currentUser
    && currentUser.roles.includes('ROLE_APP_ADMIN'), [currentUser]);

  const isTestAdmin = useMemo(() => currentUser
    && currentUser.roles.includes('ROLE_TEST_ADMIN'), [currentUser]);

  React.useEffect(() => {
    fetchTestConfs(searchValues);
  }, []);

  const fetchTestConfs = debounce((values: Partial<TestConfSearchRequest>): void => {

    const onFinally = () => {
      setTestConfigsLoaded(true);
    };

    setTestConfigsLoaded(false);
    setTestConfigs([]);

    let newSearchValues = { ...values };
    if (_.isEmpty(newSearchValues)) {
      newSearchValues = {
        testConfSearchTerm: '',
        active: values.active
      };
    }
    searchTestConfs(newSearchValues,cancelTokenSource)
    .then(response => {
        setTestConfigs(response);
      },
    ).catch((e: any) => handleError(e.response.data))
    .finally(onFinally);

  }, 300);

  const onAddTestConf = () => {
    push('/testconf');
  }

  const handleChange = useAfterFirstRender(({ values }: any): void => {
    setSearchValues(values);
    fetchTestConfs(values);
  });

  const setErrorMessage = useCallback((errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  },[errorMessages]);

  const handleError = (error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={() => {}}
        initialValues={{
          testConfSearchTerm: '',
        }}
        subscription={{ pristine: true, values: true }}
        render={renderSearchTestConf}
      />
    );
  };

  const renderSearchTestConf = ({ form, values }: FormRenderProps): React.ReactNode => {

    return (
      <>
        <div className='search-container'>
          <div className='title-h1' style={{ paddingLeft: '0.6rem' }}>{t('testconf.searchTitle')}</div>

          <div className='search-form'>
            <Field
              id='testConfSearchTerm'
              name='testConfSearchTerm'
              component={Input}
              icon={'search'}
              iconPosition='left'
              placeholder={t('testconf.placeholder')}
              autoFocus
              fluid
            />

            <CompositeButton
              type='button'
              className='action-button'
              primary
              onClick={onAddTestConf}
            >
              {t('button.add')}
            </CompositeButton>
            {isSystemAdmin || isTestAdmin ?
              <Link
                to='/export-tests'
                target='_blank'
              >
                <CompositeButton
                  type='button'
                  to='/export-tests'
                  target='_blank'
                  className='action-button'
                  primary
                >
                  {t('button.export')}
                </CompositeButton>
              </Link>
              : <></>
            }
          </div>
        </div>

        {errorMessages.length > 0 &&
          <div className='error'>
            <StyledErrorMessage onDismiss={() => setErrorMessage()}>
              {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
            </StyledErrorMessage>
          </div>
        }

        <Field
          id='active'
          name='active'
          component={TabComponent}
        />

        <div className='data-table'>
          {testConfigsLoaded && renderTestConfsTableSegment(values)}
          {!testConfigsLoaded &&
            <Loader className='table-loader' active inline content={t('testconf.loading')}/>}

        </div>

        <FormSpy subscription={{ values: true }} onChange={handleChange} />
      </>
    );
  };

  const testConfsTableRowGetter = ({ index }: any) => {
    Object.assign(testConfigs[index], { index: index + 1 });
    return testConfigs[index];
  };

  const testConfsTableRowRenderer = ({ className, columns, index, key, style }: any) => {
    const a11yProps = { 'aria-rowindex': index + 1 };

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role='row'
        style={style}
      >
        {columns}
      </div>
    );
  };

  const ownerCellRenderer = ({ rowData }: any) => {
    if (rowData) {
      return <div>{rowData.owner} ({rowData.ownerAccountId})</div>
    }

  };

  const testConfIdCellRenderer = ({rowData}: any) => {

    if (rowData) {
      return (
        <StyledSpan>
          <div className='testconf-id'>{rowData.id}</div>
        </StyledSpan>
      );
    }
  };

  const activityDateTimeCellRenderer = ({rowData}: any) => {

    if (rowData) {
      return <div>{isoToDisplayDateTime(rowData.activityDateTime)}</div>
    }
  };

  const statusCellRenderer = ({ cellData }: any) => {

    if (cellData) {
      return <Icon name='check' size='small' />;
    }
    return emptyTableCell();
  };

  const deleteTestConfigs = (id: number, values: Partial<TestConfSearchRequest>) => (): void => {
    deleteTestConf(id, cancelTokenSource)
    .then(() => {
      fetchTestConfs(values);
    });
  };

  const translateDescription = (test: TestConfDto) => {
    const description = test.description;

    const testConfDescriptions: { [key: string]: string } = {
      'de': test.description || description,
      'en': test.descriptionEn || description,
      'fr': test.descriptionFr || description,
      'it': test.descriptionIt || description,
    };
    return testConfDescriptions[language];
  }

  const actionsCellRenderer = (values: Partial<TestConfSearchRequest>) => ({rowData}: any) => {

    return (
      <div className='row-actions'>
        <InnerTableActionButton
          message={t('button.edit')}
          onConfirm={() => push(`/testconf`, { id: rowData.id })}
          divider={true}
        />
        <DeleteRecordConfirmation triggerButtonText={t('button.delete')}
                                  confirmAction={deleteTestConfigs(rowData.id, values)}
                                  deleteConfirmationText={t('testconf.confirmDelete', { description: translateDescription(rowData) })}
                                  position={"top left"}/>
      </div>
    );
  };

  const scoreRulesTypeCellRenderer = ({rowData}: any) => {
    if (rowData.scoreRulesType) {
      return t("scoreRulesType." + rowData.scoreRulesType);
    }
    return emptyTableCell();
  };

  const renderTestConfsTableSegment = (values: any) => {
    return (
      <VirtualizedTable
        rowCount={testConfigs.length}
        rowGetter={testConfsTableRowGetter}
        rowRender={testConfsTableRowRenderer}
        rowHeight={48}
        columns={[
          {
            width: 50,
            flexShrink: 0,
            label: t("testconf.testId"),
            dataKey: 'id',
            cellRenderer: (testConfIdCellRenderer)
          },
          {
            width: 200,
            label: t("testconf.description"),
            flexGrow: 1,
            dataKey: 'description',
            cellRenderer: multiLanguageFieldCellRenderer('description', language)
          },
          {
            width: 150,
            label: t("testconf.scoreRulesType"),
            dataKey: 'scoreRulesType',
            cellRenderer: scoreRulesTypeCellRenderer
          },
          {
            width: 150,
            flexGrow: 1,
            label: t("testconf.owner"),
            dataKey: 'owner',
            cellRenderer: (ownerCellRenderer),
          },
          {
            width: 50,
            label: shortLabel(t("testconf.numOfTestParams"), t("testconf.numOfTestParamsShort")),
            dataKey: 'numOfTestParams',
          },
          {
            width: 50,
            label: shortLabel(t("testconf.numOfTestResults"), t("testconf.numOfTestResultsShort")),
            dataKey: 'numOfTestResults',
          },
          {
            width: 50,
            label: shortLabel(t("testconf.numOfTestConfInPatientTest"), t("testconf.numOfTestConfInPatientTestShort")),
            dataKey: 'numOfTestConfUsesInPatientTest',
          },
          {
            width: 200,
            label: t("testconf.lastActivity"),
            dataKey: 'lastActivity',
            cellRenderer: (activityDateTimeCellRenderer)
          },
          {
            width: 50,
            label: t("testconf.status"),
            dataKey: 'active',
            cellRenderer: statusCellRenderer
          },
          {
            width: 200,
            flexShrink: 0,
            label: t('testconf.actions'),
            dataKey: 'id',
            cellRenderer: actionsCellRenderer(values)
          },
        ]}
      />
    );
  };

  return (
    <SearchDisplayContainer>
      {renderFinalForm()}
    </SearchDisplayContainer>
  );
};

export default TestConfSearchView;