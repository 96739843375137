import React from 'react';

import 'react-day-picker/lib/style.css';
import styled from 'styled-components';

const PickerContainerDiv = styled.div`

  &.color-picker-box {
    display: inline-block;
    padding-right: 1rem;

    label {
      display: inline-block;
      padding-right: 0.5rem;
    }

    .choose-color-box {
      display: inline-block;
       
      .choose-color-swatch {
        margin-bottom: -0.5rem;
      }
    }
  }
`;

interface FinalFormTopRowEntityColorPickerFieldProps {
}

const FinalFormTopRowEntityColorPicker: React.FC<FinalFormTopRowEntityColorPickerFieldProps> = ({children}) => {

  return <PickerContainerDiv className="color-picker-box">
    {children}
  </PickerContainerDiv>
};

export default FinalFormTopRowEntityColorPicker;
