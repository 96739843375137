import { useAuthContext } from 'auth/AuthContext';
import { useSidebarContext, Views } from 'components/sidebar/SidebarContext';
import SideBarItem from 'components/sidebar/SideBarItem';
import { useAppDataContext } from 'context/AppDataContext';
import { useClinicDataContext } from 'context/ClinicDataContext';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import i18n from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import styled from 'styled-components';
import { supportedLanguages } from 'util/localizationUtils';

const StyledSideBar = styled.div`

  flex-grow: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  //position: fixed; // disabled for now
  min-width: 210px;
  max-width: 210px;
  /*     overflow-y: scroll; */
  top: 0;
  bottom: 0;

  .logo {
    cursor: pointer;
    padding: 0 10px 15px 15px;
    color: var(--primary-color);
    border-bottom: 2px solid var(--very-very-light-blue);
    text-transform: lowercase;
    font-size: 18px;
    font-weight: 600;
  }

  .sideBar__item {
    cursor: pointer;
    color: var(--medium-blue-gray);
    font-size: 12px;

    .item__inner {
      font-weight: 600;
      padding: 12px 10px 12px 15px;

      i.icon {
        color: var(--primary-color);
        margin-right: .6rem;
        font-size: 18px;
      }

      span {
        vertical-align: text-bottom;
      }
    }

    &.sub-item {
      display: none;

      & > .item__inner {
        font-weight: 400;
        padding: 12px 10px 12px 44px;
      }
    }

    &.sub-item.show {
      display: block;

      &.bold > .item__inner {
        background-color: var(--submenu-selected);
        :hover {
          color: black;
        }
      }
    }

    .language-item {

      font-weight: 600;
      padding: 12px 10px 12px 15px;
      border-bottom: 2px solid var(--very-very-light-blue);

      .language-menu-item {
        color: var(--dark-gray);

        i.icon {
          float: right;
        }
      }

      .ui.upward.dropdown > .menu {
        width: 100%;
        border: none;
        border-radius: 4px;
        box-shadow: 0 0 10px 0 rgb(34 36 38 / 15%) !important;
        margin-bottom: 15px;

        & > .item {
          color: var(--dark-gray);
        }
      }
    }

    .user-item {
      display: flex;
      padding: 20px 10px 19px 15px;
      color: var(--dark-gray);
      align-items: center;

      & > .user-image {
        border-radius: 4px;
        background-color: #c1c2c6;
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: white;
      }

      & > .user-info {
        font-size: 12px;
        //margin-left: 5px;

        .name {
          color: black;
          font-weight: 600;
        }

        .bold {
          font-weight: 600;
        }
      }
    }

    .app-info {
      color: var(--dark-gray);
      font-size: 10px;
      padding: 0 10px 0 15px;
      cursor: default;
    }
  }

  .sideBar__item:hover {
    background-color: var(--primary-color);
    color: white;

    i.icon {
      color: white;
    }

    .language-item {
      background: white;

      .language-menu-item {
        background: white;
        color: var(--dark-gray);

        i.icon {
          color: var(--dark-gray);
        }
      }
    }

    .app-info {
      color: var(--dark-gray);
    }

    .user-item, .app-info {
      background: white;
    }
  }

  .sideBar__item.active-menu-item {
    background-color: var(--primary-color);
    color: white;

    i.icon {
      color: white;
    }
  }

  .sideBar__navigation {
    flex-grow: 1;
    //overflow-y: scroll;
  }

  .user-menu-trigger.ui.dropdown {

    .user-menu-select.left.menu {
      width: 100%;
      border: none;
      border-radius: 4px;
      box-shadow: 0 0 10px 0 rgb(34 36 38 / 15%) !important;
      left: 12px !important;

      & > .item {
        color: var(--medium-blue-gray);
      }
    }
  }
`;

const OverlayElement = styled.div`
    position: fixed;
    width: calc(100% - 210px);
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99999;
`

interface Props {
  closeSideBar: () => void,
  hideSideBar: boolean
}

const SideBar = (props: Props) => {

  const { t } = useTranslation('teresa');
  const currentLanguage = i18n.language;
  const { activeTab, onSetActiveTab } = useSidebarContext();
  const { currentUser, updateLanguage, logout } = useAuthContext();
  const { clinicData } = useClinicDataContext();
  const { appData } = useAppDataContext();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const history = useHistory();
  const isIpad = useIsIpadWidthOrBelow();
  const doctorId: number | undefined = currentUser?.id;
  const accountId: number | undefined = currentUser?.accountId;
  const {
    closeSideBar,
    hideSideBar
  } = props;

  const [langOptions, setLangOptions] = useState<DropdownItemProps[]>([]);

  useEffect(() => {
    const updatedLangOptions = supportedLanguages.map((lang) => ({
      key: lang,
      value: lang,
      text: t(`language.${lang}`),
    }));

    setLangOptions(updatedLangOptions);
  }, [currentLanguage]);

  const isSystemAdmin: boolean = useMemo(() => currentUser!
    && currentUser.roles.includes('ROLE_APP_ADMIN'), [currentUser]);

  const isTestAdmin = useMemo(() => currentUser
    && currentUser.roles.includes('ROLE_TEST_ADMIN'), [currentUser]);

  const isClinicAdmin = useMemo(() => currentUser
    && currentUser.clinicAdmin, [currentUser]);

  const isDoctor = useMemo(() => currentUser
    && currentUser.clinicId, [currentUser]);

  useEffect(() => {
    onSetActiveTab(pathname);
  }, []);

  const getUserRole = () => {

    let userRole = '';
    if (currentUser) {
      if (isSystemAdmin) {
        userRole = t('sidebar.user.roles.ROLE_APP_ADMIN');
      } else if (isTestAdmin) {
        userRole = t('sidebar.user.roles.ROLE_TEST_ADMIN');
      } else if (isClinicAdmin) {
        userRole = t('sidebar.user.roles.clinicAdmin');
      } else {
        userRole = t('sidebar.user.roles.ROLE_DOCTOR');
      }
    }

    return userRole;
  };

  const userRole = useMemo(() => getUserRole(), [currentUser]);

  const renderLanguage = () => {
    return (
      <Dropdown
        className='language-menu-item'
        text={t(`language.${currentLanguage}`)}
        direction='left'
        icon='chevron up'
        value={currentLanguage}
        options={langOptions}
        onChange={(evt, data) => {
          const value = data.value as string;
          updateLanguage(value);
        }}
        selectOnBlur
        selectOnNavigation={false}
        fluid
      />
    );
  };

  const onLogoutClick = (event: React.MouseEvent<HTMLDivElement>, data: DropdownItemProps) => {
    logout();
  };

  const onInit2faClick = (event: React.MouseEvent<HTMLDivElement>, data: DropdownItemProps) => {
    push('/init-2fa');

    if (isIpad) {
      closeSideBar();
    }
  };

  const onSettingsClick = (doctorId: number | undefined, accountId: number | undefined): void => {
    if (doctorId) {
      history.push('/doctor', {
        id: doctorId,
      });
    } else {
      history.push('/settings', {
        id: accountId,
      });
    }

    if (isIpad) {
      closeSideBar();
    }
  };

  const userData = () => {
    const trigger = currentUser ?
      <div className='sideBar__item'>
        <div className='user-item'>
          {/*<div className='user-image'>*/}
          {/*  <div>{currentUser.name.charAt(0)}{currentUser.lastName.charAt(0)}</div>*/}
          {/*</div>*/}
          <div className='user-info'>
            <div className='name'>{currentUser.name} {currentUser.lastName}</div>
            <div><span className='bold'>{userRole}&nbsp;</span>
              {clinicData &&
                <>
                  | {clinicData.name} - {clinicData.city}
                </>
              }
            </div>
          </div>
        </div>
      </div>
      : '';

    return <Dropdown trigger={trigger}
                     className='user-menu-trigger'
                     icon={null}
                     direction='left'
                     selectOnBlur
                     selectOnNavigation={false}>

      <Dropdown.Menu className='user-menu-select'>
        <Dropdown.Item
          text={t('sidebar.action.use2fa')}
          onClick={onInit2faClick}
        />
        <Dropdown.Item
          text={t('sidebar.action.settings')}
          onClick={() => onSettingsClick(doctorId, accountId)}
        />
        <Dropdown.Divider />
        <Dropdown.Item
          text={t('sidebar.action.logout')}
          onClick={onLogoutClick}
        />
      </Dropdown.Menu>
    </Dropdown>;
  };

  const onMenuItemClick = (view: Views, route?: string) => {

    onSetActiveTab(view);
    if (route) {
      push(route);
    }
  };

  const setActiveSubItem = (activeSubItem: string) => {

    const uriRoot = pathname.split('/')[1];

    return activeSubItem === uriRoot;
  };

  const onHomeItemClicked = () => {
    onMenuItemClick(Views.HOME, '/');

    if (isIpad) {
      closeSideBar();
    }
  };

  const onTestingItemClicked = () => onMenuItemClick(Views.TESTING, '/testconf/search');
  const onAdminItemClicked = () => onMenuItemClick(Views.ADMIN, '/account/search');
  const onClinicItemClicked = () => onMenuItemClick(Views.CLINIC, '/clinic/details');
  const onPatientItemClicked = () => onMenuItemClick(Views.PATIENT, '/patient/search');
  const onRehabilitationItemClicked = () => onMenuItemClick(Views.REHABILITATION, '/exerciseconf/search');

  const testingSubItems = [
    {
      onClick: () => {
        !isSystemAdmin && isTestAdmin ? push('/') : push('/testconf/search')

        if (isIpad) {
          closeSideBar();
        }
      },
      title: t('admin.manageTests'),
      active: setActiveSubItem('testconf')
        || setActiveSubItem('testresult')
        || (!isSystemAdmin && isTestAdmin && setActiveSubItem('')),
    },
    {
      onClick: () => {
        push('/test-template/search');

        if (isIpad) {
          closeSideBar();
        }
      },
      title: t('admin.manageTestTemplates'),
      active: setActiveSubItem('test-template'),
    },
    {
      onClick: () => {
        push('/testresultparam/search');

        if (isIpad) {
          closeSideBar();
        }
      },
      title: t('admin.manageResultParam'),
      active: setActiveSubItem('testresultparam'),
    },
    {
      onClick: () => {
        push('/question-set/search');

        if (isIpad) {
          closeSideBar();
        }
      },
      title: t('admin.manageQuestionSets'),
      active: setActiveSubItem('question-set'),
    },
    {
      onClick: () => {
        push('/domain/search');

        if (isIpad) {
          closeSideBar();
        }
      },
      title: t('admin.ManageDomains'),
      active: setActiveSubItem('domain'),
    },
    {
      onClick: () => {
        push('/educational-years/search');

        if (isIpad) {
          closeSideBar();
        }
      },
      title: t('admin.manageEducationalYears'),
      active: setActiveSubItem('educational-years'),
    },
    {
      onClick: () => {
        push('/age-ranges/search');

        if (isIpad) {
          closeSideBar();
        }
      },
      title: t('admin.manageAgeRanges'),
      active: setActiveSubItem('age-ranges'),
    },
    {
      onClick: () => {
        push('/test-image/search');

        if (isIpad) {
          closeSideBar();
        }
      },
      title: t('admin.manageTestImage'),
      active: setActiveSubItem('test-image'),
    },
    {
      onClick: () => {
        push('/truescorerule/search');

        if (isIpad) {
          closeSideBar();
        }
      },
      title: t('admin.manageTrueScoreRules'),
      active: setActiveSubItem('truescorerule'),
    },
  ];

  const rehabilitationSubItems = [
    {
      onClick: () => {
        push('/exerciseconf/search');

        if (isIpad) {
          closeSideBar();
        }
      },
      title: t('admin.manageExerciseConfs'),
      active: setActiveSubItem('exerciseconf'),
    },
    {
      onClick: () => {
        push('/rehabilitation-exercise-template/search');

        if (isIpad) {
          closeSideBar();
        }
      },
      title: t('admin.manageExerciseTemplate'),
      active: setActiveSubItem('rehabilitation-exercise-template'),
    },
    {
      onClick: () => {
        push('/rehabilitation-testresultparam/search');

        if (isIpad) {
          closeSideBar();
        }
      },
      title: t('admin.manageResultParam'),
      active: setActiveSubItem('rehabilitation-testresultparam'),
    },
    {
      onClick: () => {
        push('/rehabilitation-question-set/search');

        if (isIpad) {
          closeSideBar();
        }
      },
      title: t('admin.manageQuestionSets'),
      active: setActiveSubItem('rehabilitation-question-set'),
    },
    {
      onClick: () => {
        push('/domain/search');

        if (isIpad) {
          closeSideBar();
        }
      },
      title: t('admin.ManageDomains'),
      active: setActiveSubItem('domain'),
    },
  ];

  const adminSubItems = [
    {
      onClick: () => {
        push('/account/search');

        if (isIpad) {
          closeSideBar();
        }
      },
      title: t('admin.manageAdmins'),
      active: setActiveSubItem('account'),
    },
    {
      onClick: () => {
        push('/invoice-config');

        if (isIpad) {
          closeSideBar();
        }
      },
      title: t('admin.manageInvoiceConfigs'),
      active: setActiveSubItem('invoice-config'),
    },
    {
      onClick: () => {
        push('/mandates');

        if (isIpad) {
          closeSideBar();
        }
      },
      title: t('admin.manageMandate'),
      active: setActiveSubItem('mandates'),
    },
  ];


  return (
    <div>
      <StyledSideBar style={hideSideBar
        ? {display: 'none'}
        : isIpad
          ? {
              position: 'fixed',
              height: '100%',
              top: 0,
              left: 0,
              bottom: 0,
              zIndex: 2
            }
          : { top: 0,
              height: '100%',
              bottom: 0}
      }>
        <div className='sideBar__section'>
          <div className='logo' onClick={() => (isIpad ) ? closeSideBar() : {}}>Teresa</div>
        </div>
        <div className='sideBar__navigation'>
          <SideBarItem icon='home'
                       onClick={onHomeItemClicked}
                       title={t('sidebar.item.overview')}
                       enabled={!!((isDoctor || isSystemAdmin) && !isTestAdmin)}
                       active={activeTab == Views.HOME || activeTab == Views.CLINIC || (isSystemAdmin && activeTab == Views.PATIENT)}
          />
          <SideBarItem icon='newspaper outline'
                       onClick={onTestingItemClicked}
                       title={t('sidebar.item.testing')}
                       enabled={!!(isSystemAdmin || isTestAdmin)}
                       active={!!(activeTab == Views.TESTING || (isTestAdmin && activeTab == Views.HOME))}
                       subItems={(isSystemAdmin || isTestAdmin) ? testingSubItems : undefined}
          />
          <SideBarItem icon='medkit'
                       onClick={onRehabilitationItemClicked}
                       title={t('sidebar.item.rehabilitation')}
                       enabled={!!(isSystemAdmin || isTestAdmin)}
                       active={activeTab == Views.REHABILITATION}
                       subItems={(isSystemAdmin || isTestAdmin) ? rehabilitationSubItems : undefined}
          />
          <SideBarItem icon='users'
                       onClick={onAdminItemClicked}
                       title={t('sidebar.item.admins')}
                       enabled={isSystemAdmin}
                       active={activeTab == Views.ADMIN}
                       subItems={adminSubItems}
          />
          <SideBarItem icon='building outline'
                       onClick={onClinicItemClicked}
                       title={t('sidebar.item.clinic')}
                       enabled={!!((isSystemAdmin || isTestAdmin) && isClinicAdmin)}
                       active={activeTab == Views.CLINIC}
          />
          <SideBarItem icon='address card outline'
                       onClick={onPatientItemClicked}
                       title={t('sidebar.item.patients')}
                       enabled={!!isDoctor}
                       active={activeTab == Views.PATIENT}
          />
        </div>
        <div className='sideBar__section'>
          <div className='sideBar__item'>
            <div className='language-item'>
              {renderLanguage()}
            </div>
          </div>
          {userData()}
          <div className='sideBar__item'>
            {appData &&
              <div className='app-info'>v. {appData.version} - {t('app.build')} {appData.buildDate}</div>
            }
          </div>
        </div>
      </StyledSideBar>
      {(isIpad && !hideSideBar) &&
        <OverlayElement
          onClick={() => closeSideBar()}
        />
      }
    </div>
  );
};

export default SideBar;
