import { useAuthContext } from 'auth/AuthContext';
import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import Input from 'components/final-form/Input';
import CompositeButton from 'components/final-form/CompositeButton';
import TabComponent from 'components/final-form/TabComponent';
import InnerTableActionButton from 'components/InnerTableActionButton';
import SearchDisplayContainer from 'components/SearchDisplayContainer';
import VirtualizedTable from 'components/VirtualizedTable';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Loader } from 'semantic-ui-react';
import axios from 'service/http';
import { deleteTestImageById, searchTestImages } from 'service/testImageService';
import { debounce } from 'ts-debounce';
import { TestImageDto, TestImageSearchRequest } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { useAfterFirstRender } from 'util/functionUtils';
import { emptyTableCell, multiLanguageFieldCellRenderer, shortLabel } from 'util/tableUtils';

const cancelTokenSource = axios.CancelToken.source();

interface Props {
}

const TestImageView = (props: Props) => {

  const { t } = useTranslation('teresa');
  const history = useHistory();
  const { language } = useAuthContext();

  const [testImagesLoaded, setTestImagesLoaded] = useState<boolean>(true);
  const [foundTestImages, setFoundTestImages] = useState<TestImageDto[]>([]);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [searchValues, setSearchValues] = useState<Partial<TestImageSearchRequest>>({
    searchKey: '',
  });

  useEffect(() => {
    fetchTestImages(searchValues);
  }, []);

  const handleError = (error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.testImageNotFound,
        errorUtils.deleteTestImageNotAllowed,
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      } else {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };

  const setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const fetchTestImages = debounce((values: Partial<TestImageSearchRequest>): void => {

    const onFinally = () => {
      setTestImagesLoaded(true);
    };

    setTestImagesLoaded(false);
    setFoundTestImages([]);

    let newSearchValues = { ...values };
    if (_.isEmpty(newSearchValues)) {
      newSearchValues = {
        searchKey: '',
      };
    }

    searchTestImages(newSearchValues, cancelTokenSource)
    .then(response => {
        setFoundTestImages(response);
      },
    )
    .catch((e: any) => handleError(e.response.data))
    .finally(onFinally);

  }, 300);

  const handleChange = useAfterFirstRender(({ values }: any): void => {
    setSearchValues(values);
    fetchTestImages(values);
  });


  const deleteTestImage = (id: number, values: Partial<TestImageSearchRequest>) => (): void => {
    deleteTestImageById(id, cancelTokenSource)
    .then(() => {
      fetchTestImages(values);
    });
  };

  const testImagesRowGetter = ({ index }: any) => {

    Object.assign(foundTestImages[index], { index: index + 1 });

    return foundTestImages[index];
  };

  const testImagesRowRenderer = ({ className, columns, index, key, style }: any) => {
    const a11yProps = { 'aria-rowindex': index + 1 };

    const testImageId = foundTestImages[index].id;

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role='row'
        style={style}
        onDoubleClick={() => openTestImageDetails(testImageId!)}
      >
        {columns}
      </div>
    );
  };

  const openTestImageDetails = (id: number): void => {
    history.push('/test-image', {
      id: id,
    });
  };

  const activeCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return <Icon name='check' size='small' />;
    }
    return emptyTableCell();
  };

  const translateDescription = (templateDto: TestImageDto) => {
    const description = templateDto.description;

    const testImageDescriptions: { [key: string]: string } = {
      'de': templateDto.description || description,
      'en': templateDto.descriptionEn || description,
      'fr': templateDto.descriptionFr || description,
      'it': templateDto.descriptionIt || description,
    };
    return testImageDescriptions[language];
  }

  const testImageActionCellRenderer = (values: Partial<TestImageSearchRequest>) => ({ rowData }: any) => {
    return (
      <div className='row-actions'>
        <InnerTableActionButton
          message={t('button.edit')}
          onConfirm={() => openTestImageDetails(rowData.id)}
          divider={true}
        />
        <DeleteRecordConfirmation triggerButtonText={t('button.delete')}
                                  confirmAction={deleteTestImage(rowData.id, values)}
                                  deleteConfirmationText={t('testImage.confirmDelete', { description: translateDescription(rowData) })}
                                  position={"top left"}/>
      </div>
    );
  };

  const multiLanguageMultiDescriptionCellRenderer = (fieldName: string) => (data: any) => {

    const {cellData} = data;

    let descriptions = "";

    if(cellData) {
      let confDescriptions: string[] = [];
      cellData.forEach((cellData: any) => {

        if (!fieldName) {
          return emptyTableCell();
        }

        const defaultValue = cellData[`${fieldName}`];

        const texts: { [key: string]: string } = {
          "de": defaultValue,
          "en": cellData[`${fieldName}En`],
          "fr": cellData[`${fieldName}Fr`],
          "it": cellData[`${fieldName}It`]
        };

        confDescriptions.push(texts[language] || defaultValue);
      });
      descriptions = confDescriptions.join(', ');
    }

    if(_.isEmpty(descriptions)){
      return emptyTableCell();
    }

    return descriptions;
  };

  const renderTestImagesTable = (values: any): JSX.Element => {

    return (
      <VirtualizedTable
        rowCount={foundTestImages.length}
        rowGetter={testImagesRowGetter}
        rowRenderer={testImagesRowRenderer}
        columns={[
          {
            width: 50,
            flexShrink: 0,
            label: t('testImage.id'),
            dataKey: 'id',
          },
          {
            width: 150,
            flexGrow: 1,
            label: t('testImage.description'),
            dataKey: 'description',
            cellRenderer: multiLanguageFieldCellRenderer('description', language),
          },
          {
            width: 50,
            label: shortLabel(t("testImage.numberOfImageUses"), t("testImage.numberOfImageUsesShort")),
            dataKey: 'numOfImageUses',
          },
          {
            width: 100,
            flexGrow: 1,
            label: t('testImage.testConfDescriptions'),
            dataKey: 'testDescriptions',
            cellRenderer: multiLanguageMultiDescriptionCellRenderer('description')
          },
          {
            width: 50,
            label: t('testImage.status'),
            dataKey: 'active',
            cellRenderer: activeCellRenderer,
          },
          {
            width: 200,
            flexShrink: 1,
            label: t('testImage.actions'),
            dataKey: 'id',
            cellRenderer: testImageActionCellRenderer(values),
          },
        ]}
      />
    );
  };

  const renderTestImageForm = (): JSX.Element => {
    return (
      <FinalForm
        onSubmit={() => {
        }}
        initialValues={{
          active: undefined,
          searchKey: '',
        }}
        subscription={{ pristine: true, values: true }}
        render={renderSearchFormContent}
      />
    );
  };

  const renderSearchFormContent = ({ values }: FormRenderProps): React.ReactNode => {

    return (
      <>
        <div className='search-container'>
          <div className='title-h1'>{t('testImage.viewTitle')}</div>

          <div className='search-form'>
            <Field
              id='searchKey'
              name='searchKey'
              component={Input}
              icon={'search'}
              iconPosition='left'
              placeholder={t('testImage.placeholder')}
              autoFocus
              fluid
            />

            <CompositeButton
              type='button'
              className='action-button'
              onClick={() => history.push('/test-image')}
              primary
              style={{ display: 'inline-block' }}
            >
              {t('button.add')}
            </CompositeButton>
          </div>
        </div>

        <Field
          id='active'
          name='active'
          component={TabComponent}
        />

        <div className='data-table'>
          {testImagesLoaded && renderTestImagesTable(values)}
          {!testImagesLoaded &&
            <Loader className='table-loader' active inline content={t('testImage.loading')} />}
        </div>

        <FormSpy subscription={{ values: true }} onChange={handleChange} />
      </>
    );
  };


  return <SearchDisplayContainer>
    {renderTestImageForm()}
  </SearchDisplayContainer>;

};

export default TestImageView;