import { useAuthContext } from 'auth/AuthContext';
import CheckBox from 'components/final-form/CheckBox';
import DataLabel from 'components/final-form/DataLabel';
import MultiLanguageInput from 'components/final-form/MultiLanguageInput';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import CompositeButton from 'components/final-form/CompositeButton';
import LoaderComponent from 'components/LoaderComponent';
import TsaGrid from 'components/TsaGrid';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import TemplateTestConfSegment from 'routes/test-template/TemplateTestConfSegment';
import { Button, Container, Grid } from 'semantic-ui-react';
import axios from 'service/http';
import { getTestConfs } from 'service/testconfService';
import { addTestTemplate, editTestTemplate, getTestInTemplates, getTestTemplate } from 'service/testTemplateServices';
import styled from 'styled-components';
import { TestConfDto, UpsertTestTemplateDto } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';

const StyledLeftDataDiv = styled.div`
  font-weight: 600;
  min-height: 35px;
  line-height: 35px;
  background-color: var(--very-light-gray);
  border-radius: 4px;
`;

const StyledRightDataDiv = styled.div`
  font-weight: 600;
  min-height: 35px;
  line-height: 35px;
  background-color: var(--very-light-gray);
  border-radius: 4px;
`;

const ContentWrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  padding-bottom: 2rem;

  .page-header {
    margin-bottom: 2rem;
  }

  .ui.button + .ui.button {
    margin-left: 1rem;
  }
`;

interface Props {
}

const UpsertTestTemplateForm = (props: Props) => {

  const cancelTokenSource = React.useMemo(() => axios.CancelToken.source(), []);

  const { t } = useTranslation('teresa');
  const { state } = useLocation();
  const { currentUser } = useAuthContext();
  const history = useHistory();

  const testTemplateId: number | undefined = state?.id ? Number(state.id) : undefined;

  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(true);
  const [testTemplate, setTestTemplate] = React.useState<UpsertTestTemplateDto | undefined>(undefined);
  const [testConfs, setTestConfs] = React.useState<TestConfDto[]>([]);
  const [markedTestConf, setMarkedTestConf] = React.useState<TestConfDto | undefined>(undefined);
  const [testInTemplatesList, setTestInTemplatesList] = React.useState<string[]>([]);
  const [selectedTestConfList, setSelectedTestConfList] = React.useState<TestConfDto[]>([]);
  const [availableTestConfList, setAvailableTestConfList] = React.useState<TestConfDto[]>([]);
  const [successMsg, setSuccessMsg] = React.useState<string | undefined>(undefined);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const goToPreviousPage = () => {
    testTemplateId ? history.goBack() : history.push('/test-template/search');
  };

  useEffect(() => {
    fetchRequiredData();
  }, []);

  const fetchRequiredData = async () => {

    setFormDataLoaded(false);

    try {

      const testConfsResponse = await getTestConfs(cancelTokenSource);
      setTestConfs(testConfsResponse);

      if (testTemplateId) {
        const testTemplate = await getTestTemplate(testTemplateId, cancelTokenSource);
        setTestTemplate(testTemplate);
        if (testTemplate) {
          const testConfigIds = testTemplate.testConfList.map(t => t.id);
          let newList = testTemplate.testConfList;
          newList = _.orderBy(newList, ['orderIndex'], ['asc']);
          setSelectedTestConfList(newList);
          setAvailableTestConfList(testConfsResponse.filter(tc => !testConfigIds.includes(tc.id)));
        }
      } else {
        setSelectedTestConfList([]);
        setAvailableTestConfList(testConfsResponse);
      }
    } catch (e) {
      handleError(e.response.data);
    } finally {
      setFormDataLoaded(true);
    }
  };

  const setData = (selectedTestConfs: Array<TestConfDto>, availableTestConfs: Array<TestConfDto>) => {
    setSelectedTestConfList(selectedTestConfs);
    setAvailableTestConfList(availableTestConfs);
  };

  const setSelectedTestInTemplates = async (test: TestConfDto) => {
    setMarkedTestConf(test);
    try {
      const list = await getTestInTemplates(test.id, cancelTokenSource);
      let testInTemplatesStringList: string[] = [];
      list.forEach(template => {
        let description = template.description;
        if (currentUser && currentUser.language) {
          if (currentUser.language === 'de') {
            description = template.description;
          } else if (currentUser.language === 'en' && template.descriptionEn) {
            description = template.descriptionEn;
          } else if (currentUser.language === 'fr' && template.descriptionFr) {
            description = template.descriptionFr;
          } else if (currentUser.language === 'it' && template.descriptionIt) {
            description = template.descriptionIt;
          }
        }
        testInTemplatesStringList.push(list.indexOf(template) === 0 ? description : ', ' + description);
      });
      setTestInTemplatesList(testInTemplatesStringList);
    } catch (e) {
      handleError(e.response.data);
    }


  };

  const handleError = useCallback((error: any) => {

    if (error) {
      let errMsgs = [];
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.invalidEmail,
        errorUtils.testTemplateNotFound,
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsgs.push(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          errMsgs.push(t(`error.${errorCode}`));
        } else {
          errMsgs.push(t('error.general'));
        }
      }

      setErrorMessage(errMsgs);
    }

  }, []);

  const setErrorMessage = (errMsgs?: string[]) => {

    if (errMsgs) {
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const handleSubmit = (values: Partial<UpsertTestTemplateDto>): void => {

    values.testConfList = selectedTestConfList;

    const onSave = () => {
      testTemplateId
        ? setSuccessMsg(t('testTemplate.updateSuccess'))
        : setSuccessMsg(t('testTemplate.addSuccess'));
      setTimeout(() => {
        if (testTemplateId) {
          history.push('/test-template/search');
        } else {
          history.push('/test-template/search');
        }
      }, 1200);
    };

    if (testTemplateId) {
       editTestTemplate(testTemplateId, values, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e.response.data));
    } else {
      addTestTemplate(values, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e.response.data));
    }
  };

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={(values: Partial<UpsertTestTemplateDto>) => handleSubmit(values)}
        initialValues={testTemplate}
        subscription={{ pristine: true, submitting: true }}
        render={(formProps: FormRenderProps<UpsertTestTemplateDto>) => renderTestTemplateForm(formProps)}
      />
    );
  };

  const renderTestTemplateForm = ({
                                    handleSubmit,
                                    submitting,
                                    form,
                                  }: FormRenderProps<UpsertTestTemplateDto>): React.ReactNode => {

    const selectedTestInTemplatesLeft = markedTestConf && selectedTestConfList.includes(markedTestConf)
      ? testInTemplatesList
      : [];
    const selectedTestInTemplatesRight = markedTestConf && availableTestConfList.includes(markedTestConf)
      ? testInTemplatesList
      : [];

    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          <Grid.Row>
            <Grid.Column width={7} verticalAlign='middle'>
              <DataLabel>
                {t('testTemplate.description')}
              </DataLabel>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={7} verticalAlign='middle'>
              <MultiLanguageInput fieldName='description' form={form} requiredFirst={true} />
            </Grid.Column>
            <Grid.Column width={1} textAlign='center' verticalAlign='bottom'>
              <Field
                name='active'
                component={CheckBox}
                label={t('testTemplate.active')}
                toggle={true}
                style={{ marginLeft: '1rem' }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={7} verticalAlign='middle'>
              <DataLabel>
                {t('testTemplate.testSelection')}
              </DataLabel>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <TemplateTestConfSegment
              selectedTestConfList={selectedTestConfList && selectedTestConfList.length > 0 ? selectedTestConfList : []}
              availableTestConfList={availableTestConfList}
              setData={setData}
              setSelectedTestInTemplates={setSelectedTestInTemplates}
            />
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={7} verticalAlign={'middle'}>
              <DataLabel>
                {t('testTemplate.selectedTestsInTemplate')}
              </DataLabel>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={7}>
              <StyledLeftDataDiv>{selectedTestInTemplatesLeft}</StyledLeftDataDiv>
            </Grid.Column>
            <Grid.Column width={1} />
            <Grid.Column width={7}>
              <StyledRightDataDiv>{selectedTestInTemplatesRight}</StyledRightDataDiv>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={15}
                         style={{ borderTop: '1px solid var(--very-light-blue)', marginTop: '2rem', marginBottom: '-2rem' }}
            >
            </Grid.Column>
          </Grid.Row>

          <Grid.Row textAlign='right'>
            <Grid.Column width={15} style={{ paddingRight: '0' }}>
              {
                successMsg &&
                <SaveAndUpdateConfirmationMessage>
                  {successMsg}
                </SaveAndUpdateConfirmationMessage>
              }
              <CompositeButton
                secondary
                type='button'
                floated='right'
                className='action-button'
                style={{ display: 'inline-block', marginRight: '2rem' }}
                disabled={submitting || !!successMsg}
                onClick={goToPreviousPage}
              >
                {t('action.cancel')}
              </CompositeButton>

              <CompositeButton
                primary
                type='submit'
                floated='right'
                className='action-button'
                style={{ display: 'inline-block', marginRight: '2rem' }}
                disabled={submitting || !!successMsg}
              >
                {t('button.save')}
              </CompositeButton>


            </Grid.Column>
          </Grid.Row>
        </TsaGrid>
      </form>
    );
  };

  const renderDataContent = (): React.ReactNode => {
    return (
      <div>
        <div className='page-header'>
          <div className='title-h1'>{t('testTemplate.viewTitle')}</div>
        </div>
      </div>
    );
  };

  return (
    <Container fluid>
      <ContentWrapperDiv>
        {renderDataContent()}
        {formDataLoaded
          ? <React.Fragment>
            {renderFinalForm()}
          </React.Fragment>
          : <LoaderComponent message={t('testTemplate.loading')} />
        }
      </ContentWrapperDiv>
    </Container>
  );
};

export default UpsertTestTemplateForm;