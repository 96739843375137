export const keyCodes: any = {
  enter: 13,
  delete: 46,
  pageUp: 33,
  pageDown: 34,
  arrowUp: 38,
  arrowDown: 40,
  escape: 27
};

const _isKey = (event: any, keyCode: any) => function () {
  return event.keyCode === keyCode
};

export const isKeyCheck = (event: any) => {
  const check = {};
  for (let [keyName, keyCode] of Object.entries(keyCodes)) {
    Object.defineProperty(
        check, keyName, {get: _isKey(event, keyCode)});
  }
  return check;
};
