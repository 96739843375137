import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import Input from 'components/final-form/Input';
import PatientInfoHeaderComponent from 'components/final-form/PatientInfoHeaderComponent';
import RadioGroup from 'components/final-form/RadioGroup';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import CompositeButton from 'components/final-form/CompositeButton';
import InnerTsaGrid from 'components/InnerTsaGrid';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import { FormApi } from 'final-form';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import _ from 'lodash';
import React, { useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Header, List, ListItem, RadioProps } from 'semantic-ui-react';
import { getQuestionAnswerPairsForQuestionSet } from 'service/clinicAdminConfService';
import { getPatientDetails } from 'service/patientService';
import { answerQuestions, getPatientTesting } from 'service/patientTestingService';
import styled from 'styled-components';
import { QuestionType } from 'ts-types/api.enums';
import {
  PatientDto,
  PossibleAnswersDto,
  QuestionAnswerPairDto,
  QuestionAnswerRequest,
  QuestionDto,
  UpsertPatientTestingDto,
  UpsertQuestionSetDto,
  UpsertQuestionSetRequest,
} from 'ts-types/api.types';
import { noop } from 'util/functionUtils';
import { getMinMaxDescriptionTitle } from 'util/stringUtils';
import { composeValidators, minMaxValueValidator, mustBeNumber } from 'util/validatorUtils';

export const StyledLabel = styled.div`
    min-width: 200px;
    font-size: 16px;
    display: flex;
    flex-wrap: nowrap;
    font-weight: bold;
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(212, 217, 224);
`;

export const StyledDataEntry = styled.div`
    min-width: 170px;
    margin-top: 1rem;
    margin-bottom: 2rem;
`;

const ScrollableTsaGrid = styled(TsaGrid)`
  position: fixed;
  overflow: auto;
  top: 130px;
  bottom: 125px;
  width: calc(100% - 255px);
`;

const FormButtonsWrapper = styled(TsaGrid)`
  position: fixed;
  width: calc(100% - 255px);
  height: 75px;
  bottom: 50px;

  & .buttons-container {
    padding-top: 20px;
    border-top: 1px solid var(--very-light-blue);
  }
`;


const generateRadioButtonOptions = (
  ids: Array<string | number>,
  labels: Array<string>,
  groupName: string,
  t: Function,
  values?: Array<string | number>): Array<RadioProps> => {

  return labels.map((label, index) => ({
      id: ids[index],
      label: t(`${label}`),
      name: groupName,
      value: values ? values[index] : index,
    }
  ));
};

const extractValueFromList = (value: Array<string>) => value && value.length === 1 ? value[0] : '';

const cancelTokenSource = axios.CancelToken.source();

const AnswerQuestionsView = () => {

  const { t } = useTranslation('teresa');
  const { language } = useAuthContext();
  const { state } = useLocation();
  const history = useHistory();
  const isIpad = useIsIpadWidthOrBelow();

  const prevPath: string | undefined = state?.prevPath ? state.prevPath : undefined;

  const patientTestingId: number | undefined = state?.patientTestingId ? Number(state?.patientTestingId) : undefined;
  let patientTestingQuestionSetId: number | undefined = state?.patientTestingQuestionSetId
    ? Number(state?.patientTestingQuestionSetId)
    : undefined;

  const [questionSet, setQuestionSet] = useState<UpsertQuestionSetDto | undefined>(undefined);
  const [patientTesting, setPatientTesting] = useState<UpsertPatientTestingDto>();
  const [patient, setPatient] = useState<PatientDto>();
  const [testingQuestionSetId, setTestingQuestionSetId] = useState<number | undefined>(patientTestingQuestionSetId);
  const [questionSetIx, setQuestionSetIx] = useState<number>(0);
  const [successMsg, setSuccessMsg] = useState<string | undefined>(undefined);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const errorElement = document.getElementById('errorElement');
  const errorElementHeight = errorElement != null ? errorElement.offsetHeight : 0;

  React.useEffect(() => {
    fetchData();
  }, []);

  const goToPreviousPage = () => {
    returnToCorrectPage(false, false);
  };

  const fetchData = async (ptqsId?: number) => {
    if (patientTestingId) {
      const currentPtqsId = ptqsId ? ptqsId : testingQuestionSetId;
      const request: Partial<UpsertQuestionSetRequest> = {
        patientTestingQuestionSetId: currentPtqsId,
        patientTestingId: patientTestingId,
      };

      try {
        const patientTesting = await getPatientTesting(patientTestingId, cancelTokenSource);
        setPatientTesting(patientTesting);
        const patientData = await getPatientDetails(patientTesting.patientId, cancelTokenSource);
        setPatient(patientData);
        const questionSetResponse = await getQuestionAnswerPairsForQuestionSet(request, cancelTokenSource);
        setQuestionSet(questionSetResponse);
        const testingQuestionSetIds = questionSetResponse.testingQuestionSetIds;
        const currentQuestionSetIx = testingQuestionSetIds.findIndex(t => t === currentPtqsId);
        setQuestionSetIx(currentQuestionSetIx);
      } catch (e) {
        handleError(e.response.data);
      }
    }
  };

  const handleError = (error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };

  const setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const getAnswerDescriptionMap = (answer: PossibleAnswersDto): { [key: string]: string } => {
    let textsMap: { [key: string]: string } = {
      'de': answer.description ,
      'en': answer.descriptionEn || answer.description,
      'fr': answer.descriptionFr || answer.description,
      'it': answer.descriptionIt || answer.description,
    };

    Object.keys(textsMap).forEach((lang) => {
      if (answer.score !== null && answer.score !== undefined &&  answer.score >= 0) {
        textsMap[lang] = `${textsMap[lang]} (${answer.score})`;
      }
    });

    return textsMap;
  };

  const previousHandler = (form: FormApi) => {
    saveQuestionAnswers(form.getState().values);
    if (questionSet && questionSet.testingQuestionSetIds) {
      const testingQuestionSetIds = [...questionSet.testingQuestionSetIds];
      const currentQuestionSetIx = testingQuestionSetIds.findIndex(t => t === testingQuestionSetId);
      if (currentQuestionSetIx === 0) { return; }
      if (testingQuestionSetIds[currentQuestionSetIx - 1]) {
        const ptqsId = testingQuestionSetIds[currentQuestionSetIx - 1];
        setTestingQuestionSetId(ptqsId);
        setQuestionSetIx(currentQuestionSetIx - 1);
        fetchData(ptqsId);
      }
    }
  };

  const nextHandler = (form: FormApi<QuestionAnswerRequest>) => {
    saveQuestionAnswers(form.getState().values);
    if (questionSet && questionSet.testingQuestionSetIds) {
      const testingQuestionSetIds = [...questionSet.testingQuestionSetIds];
      const currentQuestionSetIx = testingQuestionSetIds.findIndex(t => t === testingQuestionSetId);
      if (currentQuestionSetIx === testingQuestionSetIds.length + 1) { return; }
      if (testingQuestionSetIds[currentQuestionSetIx + 1]) {
        const ptqsId = testingQuestionSetIds[currentQuestionSetIx + 1];
        setTestingQuestionSetId(ptqsId);
        setQuestionSetIx(currentQuestionSetIx + 1);
        fetchData(ptqsId);
      }
    }
  };

  const saveQuestionAnswers = (values: Partial<QuestionAnswerRequest>, onSave?: () => void) => {
    let saveValues: Partial<QuestionAnswerRequest> = { ...values };
    saveValues.questionAnswerPairs = questionSet?.questionAnswerPairs.map((q, index) => {

      let selectedAnswers = values.questionAnswerPairs
        ? values.questionAnswerPairs[index].selectedAnswers
        : [];

      let selectedAnswerIds = values.questionAnswerPairs
        ? values.questionAnswerPairs[index].selectedAnswerIds
        : [];

      if (QuestionType.SINGLE_ANSWER === q.questionType || QuestionType.MULTI_ANSWER === q.questionType) {

        if (!_.isEmpty(selectedAnswerIds)) {
          selectedAnswers = [];
          const possibleAnswerMap = _.keyBy(q.possibleAnswers, 'id');
          selectedAnswerIds.forEach(a => {
            const answer = possibleAnswerMap[a];
            const texts: { [key: string]: string } = getAnswerDescriptionMap(answer);
            selectedAnswers.push(texts[language]);
          });
        } else {
          selectedAnswers = [];
        }
      }

      return {
        questionId: q.questionId,
        questionText: q.text,
        questionTextEn: q.textEn,
        questionTextFr: q.textFr,
        questionTextIt: q.textIt,
        selectedAnswers,
        selectedAnswerIds,
        language,
      };
    });

    if (patientTestingId) {
      answerQuestions(testingQuestionSetId!, saveValues, cancelTokenSource)
      .then(response => onSave ? onSave() : noop())
      .catch((e: any) => handleError(e.response.data));
    }
  };

  const handleSubmit = (values: Partial<QuestionAnswerRequest>) => {

    const onSave = () => {
      setSuccessMsg(t('questionAnswers.successMsg'));
      setTimeout(() => {
        returnToCorrectPage(true, true);
      }, 1200);
    };

    saveQuestionAnswers(values, onSave);
  };

  const saveAnswersAndExit = (values: Partial<QuestionAnswerRequest>, continueOrExit: boolean, completed: boolean) => {

    const onSave = () => {
      setSuccessMsg(t('questionAnswers.successMsg'));
      setTimeout(() => {
        returnToCorrectPage(true, continueOrExit);
      }, 1200);
    };

    const request = {
      ...values,
      completed: completed,
    };

    saveQuestionAnswers(request, onSave);
  };

  const returnToCorrectPage = (isSave : boolean, continueToTesting: boolean) => {
    if (prevPath === '/') {
      if (continueToTesting) {
        history.push('/start-testing', {
          patientTestingId: patientTestingId,
          patientTestingQuestionSetId: patientTestingQuestionSetId,
          prevPath: prevPath
        });
      } else {
        history.push('/');
      }
    } else if (prevPath === '/doctor/details') {
      history.push(prevPath!, { id: patient?.treaterId });
    } else if ((continueToTesting && prevPath === '/patient-testing') || (isSave && continueToTesting)) {
      history.push('/start-testing', {
        patientTestingId: patientTestingId,
        patientTestingQuestionSetId: patientTestingQuestionSetId,
        prevPath: prevPath
      });
    } else if (isSave && !continueToTesting) {
      history.push('/patient/details', { id: patientTesting?.patientId });
    } else {
      history.goBack();
    }
  };

  const getQuestionSetNameMap = (): { [key: string]: string } => {
    if (questionSet) {
      return {
        'de': questionSet.name ,
        'en': questionSet.nameEn || questionSet.name,
        'fr': questionSet.nameFr || questionSet.name,
        'it': questionSet.nameIt || questionSet.name,
      };
    }

    return {};
  }

  const questionSetCounter = () => {
    const questionSets = patientTesting ? patientTesting.questionSetDtos : [];
    return `${questionSetIx + 1}/${questionSets.length}`;
  }

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={(values: any) => handleSubmit(values)}
        initialValues={questionSet}
        subscription={{ pristine: true, submitting: true, values: true }}
        render={(formProps: FormRenderProps<any>) => renderFormContent(formProps)}
      />
    );
  };

  const renderFormContent = ({ handleSubmit, submitting, form, values }: FormRenderProps<any>): React.ReactNode => {
    let listLeft: QuestionAnswerPairDto[] = [];
    let listRight: QuestionAnswerPairDto[] = [];
    let numOfQuestionsLeft = 0;
    if (questionSet) {
      numOfQuestionsLeft = Math.round(questionSet.numOfQuestions / 2);
      questionSet.questionAnswerPairs.forEach((q, index) => {
        if (questionSet.numOfQuestions <= 4) {
          listLeft.push(q);
        } else if (index < numOfQuestionsLeft) {
          listLeft.push(q);
        } else {
          listRight.push(q);
        }
      });
    }

    const disablePrevious = !questionSet
      || submitting
      || !!successMsg
      || (questionSet && questionSet.testingQuestionSetIds && questionSetIx === 0);

    const disableNext = !questionSet
      || submitting
      || !!successMsg
      || (questionSet && questionSet.testingQuestionSetIds && questionSetIx === questionSet.testingQuestionSetIds.length - 1);

    const questionSetName = getQuestionSetNameMap()[language]

    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          <PatientInfoHeaderComponent
            titleElement={
            <div className='title-h1' style={{marginBottom: 0}}>
              {t('questionAnswers.viewTitle')} ( {questionSetCounter()} ): {questionSetName}
            </div>
            }
            patient={patient}
            patientTesting={patientTesting}
          />
          {
            errorMessages.length > 0 &&
            <Grid.Row>
              <Grid.Column width={16}>
                <div className='error' id='errorElement'>
                  <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                    {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                  </StyledErrorMessage>
                </div>
              </Grid.Column>
            </Grid.Row>
          }
        </TsaGrid>
        <ScrollableTsaGrid style={isIpad
          ? { top: '220px',
              width: 'calc(100% - 40px)',
              marginTop: errorMessages.length > 0 ? `${errorElementHeight}px` : 0
            }
          : { marginTop: errorMessages.length > 0 ? `${errorElementHeight}px` : 0 }
        }>
          {questionSet &&
            <React.Fragment>
              <Grid.Row>
                <Grid.Column>
                  <Grid stackable doubling columns={2}>
                    <Grid.Column width={8}>
                      <InnerTsaGrid>
                        <Grid.Row>
                          <Grid.Column width={16}>
                            {listLeft.map((question, index) => {
                              if (question) {
                                return (
                                  <div key={question.questionId}>
                                    {renderSingleQuestion(
                                      `questionAnswerPairs[${index}]`,
                                      index,
                                      question,
                                      form,
                                    )}
                                  </div>
                                );
                              }
                            })
                            }
                          </Grid.Column>
                        </Grid.Row>
                      </InnerTsaGrid>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <InnerTsaGrid>
                        <Grid.Row>
                          <Grid.Column width={16}>
                            {listRight.map((question, index) => {
                              if (question) {
                                return (
                                  <div key={question.questionId}>
                                    {renderSingleQuestion(
                                      `questionAnswerPairs[${index + numOfQuestionsLeft}]`,
                                      index + numOfQuestionsLeft,
                                      question,
                                      form)}
                                  </div>
                                );
                              }
                            })
                            }
                          </Grid.Column>
                        </Grid.Row>
                      </InnerTsaGrid>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </React.Fragment>
          }
        </ScrollableTsaGrid>
        <FormButtonsWrapper style={isIpad ? { width: 'calc(100% - 40px)' } : {}}>
          <Grid.Row textAlign={"right"}>
            <Grid.Column width={16}>
              <div className='buttons-container'>
                {
                  successMsg &&
                  <SaveAndUpdateConfirmationMessage>
                    {successMsg}
                  </SaveAndUpdateConfirmationMessage>
                }
                <CompositeButton
                  primary
                  type='button'
                  className='action-button'
                  onClick={() => previousHandler(form)}
                  disabled={disablePrevious}
                  style={{marginBottom: '1rem'}}
                >
                  {t('action.previous')}
                </CompositeButton>
                <CompositeButton
                  primary
                  type='button'
                  className='action-button'
                  onClick={() => nextHandler(form)}
                  disabled={disableNext}
                  style={{marginBottom: '1rem'}}
                >
                  {t('action.next')}
                </CompositeButton>
                <CompositeButton
                  primary
                  type='button'
                  className='action-button'
                  disabled={submitting || !!successMsg}
                  onClick={() => saveAnswersAndExit(values, false, false)}
                  style={{marginBottom: '1rem'}}
                >
                  {t('button.saveAndExit')}
                </CompositeButton>
                <CompositeButton
                  primary
                  type='button'
                  className='action-button'
                  disabled={submitting || !!successMsg}
                  onClick={() => (patientTesting && patientTesting.testConfDtoList.length)
                    ? saveAnswersAndExit(values, true, false)
                    : saveAnswersAndExit(values, false, true)}
                  style={{marginBottom: '1rem'}}
                >
                  {t((patientTesting && patientTesting.testConfDtoList.length)
                    ? 'button.saveAndContinueTesting' : 'button.saveAndComplete')}
                </CompositeButton>
                <CompositeButton
                  type='button'
                  className='action-button'
                  secondary
                  disabled={submitting || !!successMsg}
                  onClick={goToPreviousPage}
                  style={{marginBottom: '1rem'}}
                >
                  {t('action.back')}
                </CompositeButton>
              </div>
            </Grid.Column>
          </Grid.Row>
        </FormButtonsWrapper>
      </form>
    );
  };

  const renderSingleQuestion = (questionAnswerFieldPath: string, index: number, question: any, form: FormApi) => {
    const textMap: { [lang: string]: string } =
      {
        'de': question.text,
        'en': question.textEn || question.text,
        'fr': question.textFr || question.text,
        'it': question.textIt || question.text,
      };

    const minimumValue = question.minimumValue;
    const maximumValue = question.maximumValue;

    const minMaxDescriptionTitle = t(getMinMaxDescriptionTitle(minimumValue, maximumValue, t));

    return (
      <React.Fragment key={`question-fragment-${index}`}>
        <StyledLabel className='question-text' key={`question_label_${question.questionKey}`}>
          {`${index + 1}. ${textMap[language]}`}
        </StyledLabel>
        <StyledDataEntry key={`question_value_${question.questionKey}`}>
          {renderAnswerByType(`${questionAnswerFieldPath}.selectedAnswer`, index, question, form, minMaxDescriptionTitle)}
        </StyledDataEntry>
      </React.Fragment>
    );
  };

  const renderAnswerByType = (fieldPath: string, index: number, question: QuestionDto, form: FormApi, title: string) => {
    switch (question.questionType) {
      case 'MULTI_ANSWER':
        const multiAnswerPath = `${fieldPath}Ids`;
        const answers = question.possibleAnswers.map((answer, index) => {
          const texts: { [key: string]: string } = getAnswerDescriptionMap(answer);
          return (
            <ListItem key={`${multiAnswerPath}_${index}`}>
              <Field
                name={multiAnswerPath}
                component='input'
                type='checkbox'
                value={answer.id.toString()}
              />
              {' ' + t(texts[language])}
            </ListItem>
          )},
        );
        return (
          <List>
            {answers}
          </List>
        );
      case 'SINGLE_ANSWER':
        const singleAnswerPath = `${fieldPath}Ids`;
        return (
          <Field
            name={singleAnswerPath}
            component={RadioGroup}
            parse={(value: string) => [value]}
            format={extractValueFromList}
            radiowidth="50%"
            radioDefinitions={
              generateRadioButtonOptions(
                _.map(question.possibleAnswers, (value) => value.id.toString()),
                _.map(question.possibleAnswers, (value) => {
                  const texts: { [key: string]: string } = getAnswerDescriptionMap(value);
                  return texts[language];
                }),
                singleAnswerPath,
                t,
                _.map(question.possibleAnswers, (value) => value.id.toString()),
              )
            }
          />
        );
      case 'NUMBER':
        return (
          <Field
            name={`${fieldPath}s`}
            component={Input}
            fluid
            title={title}
            placeholder={t('questionAnswer.number.placeholder')}
            parse={(value: string) => [value]}
            validate={composeValidators(mustBeNumber,minMaxValueValidator(question.minimumValue, question.maximumValue))}
          />
        );
      case 'FREE_TEXT':
        return (
          <Field
            name={`${fieldPath}s`}
            component={Input}
            fluid
            placeholder={t('questionAnswer.freeText.placeholder')}
            parse={(value: string) => [value]}
            format={extractValueFromList}
          />
        );
      case 'YES_NO':
      default:
        const path = `${fieldPath}s`;
        const qa = _.get(form.getState().values, path);
        return (
          <>
            <CompositeButton
              className='yesno-button'
              type='button'
              size='mini'
              primary={answerEquals(qa, true)}
              onClick={() => setYesNoQuestionValue(path, true, form)}
            >
              {t('button.yes')}
            </CompositeButton>

            <CompositeButton
              className='yesno-button'
              type='button'
              size='mini'
              primary={answerEquals(qa, false)}
              onClick={() => setYesNoQuestionValue(path, false, form)}
            >
              {t('button.no')}
            </CompositeButton>
          </>
        );
    }
  };

  const setYesNoQuestionValue = (fieldPath: string, value: boolean, form: FormApi) => {
    form.change(fieldPath, [value]);
  };

  const answerEquals = (answers: string[], value: any): boolean => {
    return answers && answers.length === 1 && (answers[0] === value || answers[0] === '' + value);
  };

  return (
    <UpsertContentWrapperDiv>
      <React.Fragment>
        {renderFinalForm()}
      </React.Fragment>
    </UpsertContentWrapperDiv>
  );
};

export default AnswerQuestionsView;