import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import VerificationInput from 'react-verification-input';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

const FormFiledSc = styled(Form.Field)`
  .ui.placeholder.segment .column &.field {
    min-width: 25rem;
  }

  .characters {
    height: 3rem;
  }

  .character {
    line-height: 3rem;
    text-align: center;
    font-size: 1.6rem;
    border: 1px solid var(--very-very-light-blue);
    background-color: white;
    border-radius: 5px;
    color: black;
    margin-left: 15px;
    max-width: 43px;
  }

  .character--inactive {
    background-color: white;
    border-color: var(--very-very-light-blue);
    box-shadow: none;
  }

  .character--selected {
    color: black;
    border: 1px solid var(--primary-color);
    background-color: white;
  }
`;

interface ExtendedFieldRenderProps extends FieldRenderProps<string, HTMLInputElement> {
  autoFocus?: boolean
}

const FinalFormVerificationInput = (props: ExtendedFieldRenderProps) => {

  const {
    input
  } = props;

  return (
      <FormFiledSc>
        <div>
          <VerificationInput
              removeDefaultStyles
              autoFocus
              container={{
                className: "container"
              }}
              characters={{
                className: "characters"
              }}
              character={{
                className: "character",
                classNameInactive: "character--inactive",
                classNameSelected: "character--selected"
              }}
              input={input}
          />
        </div>
      </FormFiledSc>
  );
};

export default FinalFormVerificationInput;
