import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import Input from 'components/final-form/Input';
import CompositeButton from 'components/final-form/CompositeButton';
import InnerTableActionButton from 'components/InnerTableActionButton';
import TabComponent from 'components/final-form/TabComponent';
import SearchDisplayContainer from 'components/SearchDisplayContainer';
import StyledErrorMessage from 'components/StyledErrorMessage';
import VirtualizedTable from 'components/VirtualizedTable';
import { FormState } from 'final-form';
import _ from 'lodash';
import React, { useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Loader } from 'semantic-ui-react';
import { deleteEducationalYearById, searchEducationalYear } from 'service/educaionalYearsServices';
import axios from 'service/http';
import { debounce } from 'ts-debounce';
import {
  EducationalYearsDto,
  EducationalYearsSearchRequest,
} from 'ts-types/api.types';
import { useAfterFirstRender } from 'util/functionUtils';
import { emptyTableCell } from 'util/tableUtils';

const cancelTokenSource = axios.CancelToken.source();

interface EducationalYearsFieldProps extends FormState<any> {
  values: Partial<EducationalYearsSearchRequest>;
}

interface Props {
}

const EducationalYearsView = (props: Props) => {

  const { t } = useTranslation('teresa');
  const history = useHistory();

  const [foundEducationalYears, setFoundEducationalYears] = useState<EducationalYearsDto[]>([]);
  const [educationalYearsLoaded, setEducationalYearsLoaded] = useState<boolean>(true);
  const [searchValues, setSearchValues] = useState<Partial<EducationalYearsSearchRequest>>({
    searchKey: ''
  });
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  React.useEffect(() => {
    fetchEducationalYears(searchValues);
  }, []);

  const handleError = (error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };

  const setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const handleChange = useAfterFirstRender(({ values }: EducationalYearsFieldProps): void => {
    setSearchValues(values);
    fetchEducationalYears(values);
  });

  const fetchEducationalYears = debounce((values: Partial<EducationalYearsSearchRequest>): void => {

    const onFinally = () => {
      setEducationalYearsLoaded(true);
    };

    setEducationalYearsLoaded(false);
    setFoundEducationalYears([]);

    let newSearchValues = { ...values };
    if (_.isEmpty(newSearchValues)) {
      newSearchValues = {
        searchKey: ''
      };
    }

    searchEducationalYear(newSearchValues, cancelTokenSource)
    .then(response => {
        setFoundEducationalYears(response);
      },
    )
    .catch((e: any) => handleError(e.response.data))
    .finally(onFinally);

  }, 300);

  const openUpsertEducationalYears = (id?: number): void => {
    history.push("/educational-years", {id: id})
  };

  const deleteEducationalYear = (id: number, values: Partial<EducationalYearsSearchRequest>) => (): void => {
    deleteEducationalYearById(id, cancelTokenSource)
    .then(() => {
      fetchEducationalYears(values);
    });
  };

  const renderSearchForm = (): JSX.Element => {
    return (
      <FinalForm
        onSubmit={() => {
        }}
        initialValues={{
          searchKey: ''
        }}
        subscription={{ pristine: true, values: true }}
        render={renderSearchFormContent}
      />
    );
  };

  const renderSearchFormContent = ({ values }: FormRenderProps): React.ReactNode => {

    return (
      <>
        <div className='search-container'>
          <div className='title-h1'>{t('educationalYears.viewTitle')}</div>
          <div className='search-form'>
            <Field
              id='searchKey'
              name='searchKey'
              component={Input}
              icon={'search'}
              iconPosition='left'
              placeholder={t('educationalYears.placeholder')}
              autoFocus
              fluid
            />

            <CompositeButton
              type='button'
              className='action-button'
              onClick={() => openUpsertEducationalYears()}
              primary
            >
              {t('button.add')}
            </CompositeButton>
          </div>
        </div>

        {errorMessages.length > 0 &&
          <div className='error'>
            <StyledErrorMessage onDismiss={() => setErrorMessage()}>
              {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
            </StyledErrorMessage>
          </div>
        }

        <Field
          id='active'
          name='active'
          component={TabComponent}
        />

        <div className='data-table'>
          {educationalYearsLoaded && renderEducationalYearsTable(values)}
          {!educationalYearsLoaded &&
            <Loader className='table-loader' active inline content={t('educationalYears.loading')} />}
        </div>

        <FormSpy subscription={{ values: true }} onChange={handleChange} />
      </>
    );
  };

  const educationalYearsRowGetter = ({ index }: any) => {

    Object.assign(foundEducationalYears[index], { index: index + 1 });

    return foundEducationalYears[index];
  };

  const educationalYearsRowRenderer = ({ className, columns, index, key, style }: any) => {
    const a11yProps = { 'aria-rowindex': index + 1 };

    const educationalYearId = foundEducationalYears[index].id;

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role='row'
        style={style}
        onDoubleClick={() => openUpsertEducationalYears(educationalYearId!)}
      >
        {columns}
      </div>
    );
  };

  const descriptionCellRenderer = ({ cellData }: any) => {
    if (cellData && cellData.trim() !== "") {
      return t(`${cellData}`);
    }
    return emptyTableCell();
  };

  const renderEducationalYearsTable = (values: any): JSX.Element => {
    return (
      <VirtualizedTable
        rowCount={foundEducationalYears.length}
        rowGetter={educationalYearsRowGetter}
        rowRenderer={educationalYearsRowRenderer}
        columns={[
          {
            width: 50,
            flexShrink: 0,
            label: t('educationalYears.id'),
            dataKey: 'id',
          },
          {
            width: 250,
            flexGrow: 1,
            label: t('educationalYears.description'),
            dataKey: 'description',
            cellRenderer: descriptionCellRenderer
          },
          {
            width: 100,
            label: t('educationalYears.fromYear'),
            dataKey: 'fromYear',
          },
          {
            width: 100,
            label: t('educationalYears.toYear'),
            dataKey: 'toYear',
          },
          {
            width: 100,
            label: t('educationalYears.status'),
            dataKey: 'active',
            cellRenderer: statusCellRenderer
          },
          {
            width: 200,
            flexShrink: 0,
            label: t('educationalYears.actions'),
            dataKey: 'id',
            cellRenderer: actionsCellRenderer(values),
          },
        ]}
      />
    );
  };

  const statusCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return <Icon name='check' size='small' />;
    }
    return emptyTableCell();
  };

  const actionsCellRenderer = (values: Partial<EducationalYearsSearchRequest>) => ({ rowData }: any) => {
    return (
      <div className='row-actions'>
        <InnerTableActionButton
          message={t('button.edit')}
          onConfirm={() => openUpsertEducationalYears(rowData.id)}
          divider={true}
        />
        <DeleteRecordConfirmation triggerButtonText={t('button.delete')}
                                  confirmAction={deleteEducationalYear(rowData.id, values)}
                                  deleteConfirmationText={t('educationalYears.confirmDelete', {id: rowData.id})}
                                  position={"top left"} />
      </div>
    );
  };

  return (
    <SearchDisplayContainer>
      {renderSearchForm()}
    </SearchDisplayContainer>
  );
};

export default EducationalYearsView;