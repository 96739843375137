import { useAuthContext } from 'auth/AuthContext';
import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import CompositeButton from 'components/final-form/CompositeButton';
import InnerTableActionButton from 'components/InnerTableActionButton';
import Input from 'components/final-form/Input';
import TabComponent from 'components/final-form/TabComponent';
import SearchDisplayContainer from 'components/SearchDisplayContainer';
import StyledErrorMessage from 'components/StyledErrorMessage';
import VirtualizedTable from 'components/VirtualizedTable';
import { FormState } from 'final-form';
import _ from 'lodash';
import React, { useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Loader } from 'semantic-ui-react';
import { deleteExerciseTemplateById, searchExerciseTemplates } from 'service/exerciseTemplateServices';
import axios from 'service/http';
import { debounce } from 'ts-debounce';
import {
  DomainSearchRequest,
  ExerciseTemplateDto,
  ExerciseTemplateSearchRequest,
} from 'ts-types/api.types';
import { useAfterFirstRender } from 'util/functionUtils';
import { emptyTableCell, multiLanguageFieldCellRenderer, shortLabel } from 'util/tableUtils';

const cancelTokenSource = axios.CancelToken.source();

interface ExerciseTemplateFieldProps extends FormState<any> {
  values: Partial<ExerciseTemplateSearchRequest>;
}

interface Props {
}

const ExerciseTemplateView = (props: Props) => {

  const { t } = useTranslation('teresa');
  const history = useHistory();
  const { language } = useAuthContext();

  const [foundExerciseTemplates, setFoundExerciseTemplates] = useState<ExerciseTemplateDto[]>([]);
  const [exerciseTemplatesLoaded, setExerciseTemplatesLoaded] = useState<boolean>(true);
  const [searchValues, setSearchValues] = useState<Partial<ExerciseTemplateSearchRequest>>({
    searchKey: ''
  });
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  React.useEffect(() => {
    fetchExerciseTemplates(searchValues);
  }, []);

  const handleError = (error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };

  const setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const handleChange = useAfterFirstRender(({ values }: ExerciseTemplateFieldProps): void => {
    setSearchValues(values);
    fetchExerciseTemplates(values);
  });

  const fetchExerciseTemplates = debounce((values: Partial<ExerciseTemplateSearchRequest>): void => {

    const onFinally = () => {
      setExerciseTemplatesLoaded(true);
    };

    setExerciseTemplatesLoaded(false);
    setFoundExerciseTemplates([]);

    let newSearchValues = { ...values };
    if (_.isEmpty(newSearchValues)) {
      newSearchValues = {
        searchKey: '',
      };
    }

    searchExerciseTemplates(newSearchValues, cancelTokenSource)
    .then(response => {
      setFoundExerciseTemplates(response);
      },
    )
    .catch((e: any) => handleError(e.response.data))
    .finally(onFinally);

  }, 300);

  const openUpsertExerciseTemplate = (id?: number): void => {
    id
      ? history.push('/rehabilitation-exercise-template', { exerciseTemplateId: id })
      : history.push('/rehabilitation-exercise-template');
  };

  const renderSearchForm = (): JSX.Element => {
    return (
      <FinalForm
        onSubmit={() => {
        }}
        initialValues={{
          searchKey: '',
          active: undefined,
        }}
        subscription={{ pristine: true, values: true }}
        render={renderSearchFormContent}
      />
    );
  };

  const renderSearchFormContent = ({ form, values }: FormRenderProps): React.ReactNode => {

    return (
      <>
        <div className='search-container'>
          <div className='title-h1'>{t('exerciseTemplate.viewTitle')}</div>
          <div className='search-form'>
            <Field
              id='searchKey'
              name='searchKey'
              component={Input}
              icon={'search'}
              iconPosition='left'
              placeholder={t('exerciseTemplate.placeholder')}
              autoFocus
              fluid
            />

            <CompositeButton
              type='button'
              className='action-button'
              onClick={() => openUpsertExerciseTemplate()}
              primary
            >
              {t('button.add')}
            </CompositeButton>
          </div>
        </div>

        {errorMessages.length > 0 &&
          <div className='error'>
            <StyledErrorMessage onDismiss={() => setErrorMessage()}>
              {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
            </StyledErrorMessage>
          </div>
        }

        <Field
          id='active'
          name='active'
          component={TabComponent}
        />

        <div className='data-table'>
          {exerciseTemplatesLoaded && renderExerciseTemplatesTable(values)}
          {!exerciseTemplatesLoaded &&
            <Loader className='table-loader' active inline content={t('exerciseTemplate.loading')} />}
        </div>

        <FormSpy subscription={{ values: true }} onChange={handleChange} />
      </>
    );
  };

  const exerciseTemplatesRowGetter = ({ index }: any) => {

    Object.assign(foundExerciseTemplates[index], { index: index + 1 });

    return foundExerciseTemplates[index];
  };

  const exerciseTemplatesRowRenderer = ({ className, columns, index, key, style }: any) => {
    const a11yProps = { 'aria-rowindex': index + 1 };

    const exerciseTemplateId = foundExerciseTemplates[index].id;

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role='row'
        style={style}
        onDoubleClick={() => openUpsertExerciseTemplate(exerciseTemplateId!)}
      >
        {columns}
      </div>
    );
  };

  const renderExerciseTemplatesTable = (values: any): JSX.Element => {
    return (
      <VirtualizedTable
        rowCount={foundExerciseTemplates.length}
        rowGetter={exerciseTemplatesRowGetter}
        rowRenderer={exerciseTemplatesRowRenderer}
        columns={[
          {
            width: 50,
            flexShrink: 0,
            label: t('exerciseTemplate.id'),
            dataKey: 'id',
          },
          {
            width: 150,
            flexGrow: 1,
            label: t('exerciseTemplate.description'),
            dataKey: 'description',
            cellRenderer: multiLanguageFieldCellRenderer('description', language)
          },
          {
            width: 50,
            label: shortLabel(t("exerciseTemplate.numberOfExerciseConfs"), t("exerciseTemplate.numberOfExerciseConfsShort")),
            dataKey: 'numOfExerciseConfs',
            cellRenderer: (numberOfExerciseConfsCellRenderer)
          },
          {
            width: 50,
            label: t('exerciseTemplate.status'),
            dataKey: 'active',
            cellRenderer: statusCellRenderer
          },
          {
            width: 200,
            flexShrink: 0,
            label: t('exerciseTemplate.actions'),
            dataKey: 'id',
            cellRenderer: (actionsCellRenderer(values)),
          },
        ]}
      />
    );
  };

  const statusCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return <Icon name='check' size='small' />;
    }
    return emptyTableCell();
  };

  const numberOfExerciseConfsCellRenderer = ({ cellData }: any) => {
    if (!cellData || cellData === 0) {
      return emptyTableCell();
    }
    return cellData;
  };

  const deleteExerciseTemplate = (id: number, values: Partial<ExerciseTemplateSearchRequest>) => (): void => {
    deleteExerciseTemplateById(id, cancelTokenSource)
    .then(() => {
      fetchExerciseTemplates(values);
    });
  };

  const translateDescription = (exerciseTemplate: ExerciseTemplateDto) => {
    const description = exerciseTemplate.description;

    const exerciseTemplateDescriptions: { [key: string]: string } = {
      'de': exerciseTemplate.description || description,
      'en': exerciseTemplate.descriptionEn || description,
      'fr': exerciseTemplate.descriptionFr || description,
      'it': exerciseTemplate.descriptionIt || description,
    };
    return exerciseTemplateDescriptions[language];
  }

  const actionsCellRenderer = (values: Partial<ExerciseTemplateSearchRequest>) => ({ rowData }: any) => {
    return (
      <div className='row-actions'>
        <InnerTableActionButton
          message={t('button.edit')}
          onConfirm={() => openUpsertExerciseTemplate(rowData.id)}
          divider={true}
        />
        <DeleteRecordConfirmation triggerButtonText={t('button.delete')}
                                  confirmAction={deleteExerciseTemplate(rowData.id, values)}
                                  deleteConfirmationText={t('exerciseTemplate.confirmDelete', { description: translateDescription(rowData) })}
                                  position={"top left"} />
      </div>
    );
  };

  return (
    <SearchDisplayContainer>
      {renderSearchForm()}
    </SearchDisplayContainer>
  );
};

export default ExerciseTemplateView;