import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import CheckBox from 'components/final-form/CheckBox';
import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import Input from 'components/final-form/Input';
import MultiLanguageInput from 'components/final-form/MultiLanguageInput';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import Select, { DropdownOption, mapToMultiLangDropdownOptionArray } from 'components/final-form/Select';
import FormModalContentContainer from 'components/FormModalContentContainer';
import InnerFormTableWithOrder from 'components/InnerFormTableWithOrder';
import InnerTableActionButton from 'components/InnerTableActionButton';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import { useExerciseConfFormDataContext } from 'context/ExerciseConfFormDataContext';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import ExerciseResultAddParamsModal from 'routes/exerciseresult/ExerciseResultAddParamsModal';
import { Container, Grid, Modal } from 'semantic-ui-react';
import { getDomains } from 'service/domainService';
import { searchTestResultParamsForTestResult } from 'service/testResultParamServices';
import styled from 'styled-components';
import { ScopeType } from 'ts-types/api.enums';
import { DomainDto, ParamSearchRequest, TestResultParamDto, UpsertExerciseResultDto } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { useAfterFirstRender } from 'util/functionUtils';
import { required } from 'util/validatorUtils';

const ActionContainer = styled.div`
  flex-grow: 1;
  border-radius: unset;
  padding-left: 5px;
  padding-top: 34px;

  .scoring-exercise {
    display: inline-block;
    white-space: nowrap;
  }
`;

export interface ExtendedUpsertExerciseResultDto extends UpsertExerciseResultDto {
  activeStatus: number;
}

interface Props {
}

const cancelTokenSource = axios.CancelToken.source();

const UpsertExerciseResultForm = (props: Props) => {

  const { state } = useLocation();
  const exerciseConfId: number | undefined = state?.exerciseConfId ? Number(state?.exerciseConfId) : undefined;
  let exerciseResultIx: number = 0;

  if (state.exerciseResultIx !== undefined && (state.exerciseResultIx != 0)) {
    exerciseResultIx = Number(state.exerciseResultIx);
  }

  const { language } = useAuthContext();
  const orderIndex: number | undefined = state.orderIndex ? Number(state?.orderIndex) : undefined;
  const { t } = useTranslation('teresa');
  const history = useHistory();
  const {
    exerciseConfFormData, addOrEditExerciseResult,
    setExerciseResult, deleteExerciseResult,
    deleteExerciseResultExerciseResultParam, onDragAndDropExerciseResultParam
  } = useExerciseConfFormDataContext();

  const initialExerciseResultValues: Partial<ExtendedUpsertExerciseResultDto> = {
    exerciseConfId: exerciseConfId,
    active: true,
    activeStatus: 1,
    exerciseResultParams: [],
    orderIndex: orderIndex
  };

  const [initialExerciseResult, setInitialExerciseResult] = useState<Partial<ExtendedUpsertExerciseResultDto>>({});

  const [domains, setDomains] = useState<DomainDto[]>([]);
  const [domainOptions, setDomainOptions] = useState<Array<DropdownOption>>([]);

  const [avExerciseResultParamMap, setAvExerciseResultParamMap] = useState<{ [key: number]: TestResultParamDto }>([]);

  const [successMsg, setSuccessMsg] = React.useState<string | undefined>(undefined);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(true);

  useEffect(() => {
    fetchData(true);
    fetchDomains(true);
  }, []);

  useEffect(() => {
    const domainOptArray = mapToMultiLangDropdownOptionArray(domains, 'description', language);
    setDomainOptions(domainOptArray);
  }, [domains, language]);


  const fetchData = async (active: boolean) => {

    setFormDataLoaded(false);

    try {
      const domainsResponse = await getDomains(active, cancelTokenSource);
      setDomains(domainsResponse);

      const paramSearchRequest: Partial<ParamSearchRequest> = {
        code: '',
        selectedConfParamIds: [],
        selectedTestResultParamIds: [],
        scopeType: ScopeType.REHAB,
      };

      const exerciseResultParamResponse =
        await searchTestResultParamsForTestResult(paramSearchRequest, cancelTokenSource);

      setAvExerciseResultParamMap(_.keyBy(exerciseResultParamResponse, 'id'));

      if (exerciseResultIx >= 0) {
        setInitialExerciseResult(exerciseConfFormData!.exerciseResults![exerciseResultIx]);
      }
    } catch (e) {
      handleError(e);
    } finally {
      setFormDataLoaded(true);
    }
  };

  const fetchDomains = (active: boolean): void => {
    try {
      getDomains(active, cancelTokenSource)
      .then(response => {
          setDomains(response);
        },
      );
    } catch (e) {
      handleError(e);
    }
  };

  const handleError = (error: any) => {

    if (error) {
      if (error.response && error.response.data) {
        const e = error.response.data;
        const errorCode = e.errorCode;
        const knownErrors: Array<string> = [
          errorUtils.deletingNotAllowed,
          errorUtils.invalidInput,
          errorUtils.exerciseResultNotFound,
          errorUtils.domainNotFound,
        ];

        const violations: Array<any> = e.violations;

        if (violations && violations.length > 0) {
          violations.forEach(violation => {
            if (knownErrors.includes(violation.errorCode)) {
              setErrorMessage(t(`error.${violation.errorCode}`));
            }

          });
        } else {
          if (knownErrors.includes(errorCode)) {
            setErrorMessage(t(`error.${errorCode}`));
          } else {
            setErrorMessage(t('error.general'));
          }
        }
      }
    } else {
      setErrorMessage(t('error.general'));
    }
  };

  const setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {
      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const goToPreviousPage = () => {
    if (_.isEqual(initialExerciseResultValues, initialExerciseResult)) {
      deleteExerciseResult(exerciseResultIx);
    } else {
      setExerciseResult(initialExerciseResult, exerciseResultIx);
    }
    history.push('/exerciseconf', { id: exerciseConfId });
  };

  const submitHandler = async (values: Partial<ExtendedUpsertExerciseResultDto>) => {

    let saveValues: Partial<UpsertExerciseResultDto> = {
      ...values,
      exerciseConfId: exerciseConfId,
      domainId: values.domainId,
      active: values.active,
    };

    const onSave = async () => {
      setSuccessMsg(t('exerciseResult.onSave'));
      setTimeout(() => {
          history.push('/exerciseconf', { id: exerciseConfId });
      }, 1200);
    };

    let domainDesc = '';
    const domain = domains.find(d => d.id === values.domainId);
    if (domain) {
      domainDesc = domain.description;
    }

    try {
      await addOrEditExerciseResult(exerciseResultIx!, saveValues, domainDesc, onSave);
    } catch (e) {}
  };

  const handleFormValuesChange = useAfterFirstRender(({ values }: any): void => {
    setExerciseResult(values, exerciseResultIx);
  });

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={(values: Partial<UpsertExerciseResultDto>) => submitHandler(values)}
        initialValues={exerciseConfFormData!.exerciseResults![exerciseResultIx!]}
        subscription={{ pristine: true, submitting: true, values: true }}
        render={(formProps: FormRenderProps<UpsertExerciseResultDto>) => renderFormContent(formProps)}
      />
    );
  };

  const deleteResultParamCellRenderer = (rowData: any) => {

    const paramId = rowData.paramId;

    if (!avExerciseResultParamMap[paramId]) {
      return '';
    }

    const exerciseResultParam = avExerciseResultParamMap[paramId];

    const paramDescMap: { [lang: string]: string } =
      {
        'de': exerciseResultParam.description,
        'en': exerciseResultParam.descriptionEn || exerciseResultParam.description,
        'fr': exerciseResultParam.descriptionFr || exerciseResultParam.description,
        'it': exerciseResultParam.descriptionIt || exerciseResultParam.description,
      };

    return <DeleteRecordConfirmation triggerButtonText={t('exerciseresult.delete')}
                                     confirmAction={() => deleteExerciseResultExerciseResultParam(exerciseResultIx, paramId)}
                                     deleteConfirmationText={t('exerciseResult.confirmDeleteParam', { description: paramDescMap[language] })}
                                     position={"top left"}/>;
  };

  const descriptionParamCellRenderer = (rowData: any) => {

    const paramId = rowData.paramId;

    if (!avExerciseResultParamMap[paramId]) {
      return '';
    }

    const exerciseResultParam = avExerciseResultParamMap[paramId];

    const paramDescMap: { [lang: string]: string } =
      {
        'de': exerciseResultParam.description,
        'en': exerciseResultParam.descriptionEn || exerciseResultParam.description,
        'fr': exerciseResultParam.descriptionFr || exerciseResultParam.description,
        'it': exerciseResultParam.descriptionIt || exerciseResultParam.description,
      };

    return <span title={paramDescMap[language]}>{paramDescMap[language]}</span>;
  };

  const orderIndexRenderer = (rowData: any) => {

    const paramId = rowData.paramId;

    if (!avExerciseResultParamMap[paramId]) {
      return '';
    }

    return rowData.orderIndex;
  };

  const unitParamCellRenderer = (rowData: any) => {

    const paramId = rowData.paramId;

    if (!avExerciseResultParamMap[paramId]) {
      return '';
    }

    const exerciseResultParam = avExerciseResultParamMap[paramId];

    return t(`unit.${exerciseResultParam.unit}`);
  };


  const codeParamCellRenderer = (rowData: any) => {

    const paramId = rowData.paramId;

    if (!avExerciseResultParamMap[paramId]) {
      return '';
    }

    const exerciseResultParam = avExerciseResultParamMap[paramId];

    return exerciseResultParam.code;
  };

  const renderFormContent = (
    { handleSubmit, submitting, form }: FormRenderProps<UpsertExerciseResultDto>): React.ReactNode => {
    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          <Grid.Row>
            <Grid.Column width={15}>
              <div className='title-h1'>{t('exerciseResult.viewTitle')}</div>
            </Grid.Column>
          </Grid.Row>

          {errorMessages.length > 0 &&
            <Grid.Row>
              <Grid.Column width={15}>
                <div className='error'>
                  <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                    {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                  </StyledErrorMessage>
                </div>
              </Grid.Column>
            </Grid.Row>
          }

          <Grid.Row>
            <Grid.Column width={15}>
              <DataLabel>{t('testconf.description')}</DataLabel>
              <MultiLanguageInput fieldName='description' form={form} requiredFirst={true} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={15}>
              <DataLabel>{t('domain.viewTitle')}</DataLabel>
              <Field
                name='domainId'
                component={Select}
                options={domainOptions}
                validate={required}
                fluid
              />
            </Grid.Column>
          </Grid.Row>
          {renderExerciseResultParamsTable()}

          <Grid.Row>
            <Grid.Column width={10} textAlign='left'>
              <DataLabel>{t('domain.active')}</DataLabel>
              <Field
                name='active'
                component={CheckBox}
                label={t('domain.active')}
                toggle
              />
            </Grid.Column>

            <Grid.Column width={5}>
              <DataLabel>{t('exerciseResult.orderIndex')}</DataLabel>
              <Field
                name='orderIndex'
                validate={required}
                component={Input}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16} style={{ borderTop: '1px solid var(--very-light-blue)', marginTop: '2rem' }}>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row textAlign='right'>
            <Grid.Column width={16}>
              {
                successMsg &&
                <SaveAndUpdateConfirmationMessage>
                  {successMsg}
                </SaveAndUpdateConfirmationMessage>
              }
              <CompositeButton
                primary
                type='submit'
                className='action-button'
                disabled={submitting || !!successMsg}
              >
                {t('button.save')}
              </CompositeButton>
              <CompositeButton
                type='button'
                className='action-button'
                secondary
                disabled={submitting || successMsg !== undefined}
                onClick={goToPreviousPage}
              >
                {t('action.back')}
              </CompositeButton>
            </Grid.Column>
          </Grid.Row>

        </TsaGrid>
        <FormSpy subscription={{ values: true }} onChange={handleFormValuesChange} />
      </form>
    );
  };

  const renderExerciseResultParamsTable = () => {

    const values = exerciseConfFormData && exerciseConfFormData.exerciseResults && exerciseConfFormData.exerciseResults.length
      ? exerciseConfFormData.exerciseResults[exerciseResultIx!].exerciseResultParams
      : [];

    return (
      <>
        <Grid.Row>
          <Grid.Column width={15}>
            <DataLabel>{t('exerciseResult.params')}</DataLabel>
            <InnerFormTableWithOrder id='exercise-result'
                            numOfCells={5}
                            values={values}
                            visibleRows={8}
                            dragAndDropFn={onDragAndDropExerciseResultParam(exerciseResultIx)}
                            columns={[
                              {
                                width: 2,
                                label: '#',
                                flexGrow: 0,
                                dataKey: 'orderIndex',
                                cellRenderer: orderIndexRenderer
                              },
                              {
                                width: 5,
                                flexGrow: 1,
                                oneLine: true,
                                label: t('exerciseResult.description'),
                                dataKey: 'exerciseResultParamId',
                                cellRenderer: descriptionParamCellRenderer,
                              },
                              {
                                width: 3,
                                label: t('exerciseResult.unit'),
                                dataKey: 'exerciseResultParamId',
                                cellRenderer: unitParamCellRenderer,
                              },
                              {
                                width: 3,
                                label: t('exerciseResult.code'),
                                dataKey: 'exerciseResultParamId',
                                cellRenderer: codeParamCellRenderer,
                              },
                              {
                                width: 3,
                                label: t('action.delete'),
                                dataKey: 'exerciseResultParamId',
                                cellRenderer: deleteResultParamCellRenderer,
                              },
                            ]}
            />
          </Grid.Column>
          <Grid.Column>
            <ActionContainer>
              <InnerTableActionButton
                message={t('button.add')}
                onConfirm={() => showModal()}
              />
            </ActionContainer>
          </Grid.Column>
        </Grid.Row>
      </>
    );
  };

  const showModal = () => {
    setModalOpen(true);
  };

  const hideModal = () => {
    setModalOpen(false);
  };

  const renderContactModal = (): React.ReactNode => {

    return (
      <Modal style={{ position: 'sticky' }}
             open={modalOpen} closeOnDimmerClick={true} onClose={() => hideModal()}>
        <Modal.Header>{t('exerciseResult.exerciseResultParams')}</Modal.Header>
        <FormModalContentContainer>
          <ExerciseResultAddParamsModal
            exerciseResultIx={exerciseResultIx}
            exerciseResultParams={[]}
            onCancel={hideModal}
          />
        </FormModalContentContainer>
      </Modal>
    );
  };

  return (
    <div>
      <Container fluid>
        <UpsertContentWrapperDiv>
          {formDataLoaded
          && exerciseConfFormData !== undefined
          && exerciseConfFormData.exerciseResults
          && exerciseConfFormData.exerciseResults[exerciseResultIx!]
            ? <>
              {renderFinalForm()}
              {renderContactModal()}
            </>
            : <LoaderComponent message={t('exerciseResult.loading')} />
          }
        </UpsertContentWrapperDiv>
      </Container>
    </div>
  );

};


export default UpsertExerciseResultForm;