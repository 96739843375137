import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import CheckBox from 'components/final-form/CheckBox';
import DataLabel from 'components/final-form/DataLabel';
import MultiLanguageImageUpload from 'components/final-form/MultiLanguageImageUpload';
import MultiLanguageInput from 'components/final-form/MultiLanguageInput';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import CompositeButton from 'components/final-form/CompositeButton';
import FormModalContentContainer from 'components/FormModalContentContainer';
import InnerFormTable from 'components/InnerFormTable';
import InnerTableActionButton from 'components/InnerTableActionButton';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import { FormApi } from 'final-form';
import { noop } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import TestConfAddParamsModal from 'routes/testconf/TestConfAddParamsModal';
import TestImageAddTestConfModal from 'routes/testimage/TestImageAddTestConfModal';
import { Button, Grid, Header, Modal } from 'semantic-ui-react';
import { getTestConfs } from 'service/testconfService';
import { addTestImage, editTestImage, getTestImageById } from 'service/testImageService';
import styled from 'styled-components';
import { TestConfDto, UpsertTestImageDto } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { multiLanguageInnerTableCellRenderer } from 'util/tableUtils';

const StyledDataLabel = styled(DataLabel)`
  margin-top: 35px;
`;

interface Props {
}

const UpsertTestImageForm = (props: Props) => {

  const cancelTokenSource = useMemo(axios.CancelToken.source, []);

  const { t } = useTranslation('teresa');
  const { language } = useAuthContext();
  const { push } = useHistory();
  const { state } = useLocation();
  const testImageId: number | undefined = state?.id ? Number(state.id) : undefined;


  const initialTestImageValues: Partial<UpsertTestImageDto> = {
    active: true,
  };

  const [testImage, setTestImage] = React.useState<Partial<UpsertTestImageDto> | undefined>(initialTestImageValues);
  const [testConfigs, setTestConfigs] = React.useState<TestConfDto[]>([]);
  const [selectedTestConfigs, setSelectedTestConfigs] = React.useState<TestConfDto[]>([]);
  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(true);
  const [successMsg, setSuccessMsg] = React.useState<string | undefined>(undefined);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    fetchFormData();
  }, []);

  const fetchFormData = useCallback(async () => {

    setFormDataLoaded(false);

    let selectedTestConfIds: number[] = [];

    try {
      if (testImageId) {
        const response = await getTestImageById(testImageId, cancelTokenSource);
        const upsertTestImageDto: Partial<UpsertTestImageDto> = {
          ...response,
        };
        setTestImage(upsertTestImageDto);
        selectedTestConfIds = response.testConfIds;
      }

      const testConfs = await getTestConfs(cancelTokenSource);
      setTestConfigs(testConfs);

      const selectedTestConfs = testConfs.filter(tc => selectedTestConfIds.includes(tc.id));
      setSelectedTestConfigs(selectedTestConfs);

    } catch (e) {
      handleError(e.response.data);
    } finally {
      setFormDataLoaded(true);
    }
  }, [testImageId]);

  const handleError = useCallback((error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.invalidInput,
        errorUtils.testImageNotFound,
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }

  }, []);

  const setErrorMessage = (errorMessage?: string) => {
    if (errorMessage) {
      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const imageSizeExceeded = useCallback((values: Partial<UpsertTestImageDto>) => {

    if ((values.image && values.image.size >= 1048576)
      || (values.imageEn && values.imageEn.size >= 1048576)
      || (values.imageFr && values.imageFr.size >= 1048576)
      || (values.imageIt && values.imageIt.size >= 1048576)) {

      setErrorMessages([t(`error.${errorUtils.fileSizeExceeded}`)]);

      return true;
    }

    return false;
  }, []);

  const handleSubmit = useCallback(async (values: Partial<UpsertTestImageDto>) => {

    let saveValues: Partial<UpsertTestImageDto> = {
      ...values,
    };

    const imageSizeExceed = imageSizeExceeded(values);

    if (imageSizeExceed) {
      return;
    }

    const onSave = () => {
      testImageId
        ? setSuccessMsg(t('testImage.onUpdate'))
        : setSuccessMsg(t('testImage.onAdd'));
      setTimeout(() => {
        goToPreviousPage();
      }, 1200);
    };

    if (testImageId) {
      await editTestImage(testImageId, saveValues, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e.response.data));
    } else {
      await addTestImage(saveValues, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e.response.data));
    }


  }, []);


  const goToPreviousPage = useCallback(() => {
    let testImageSearch = '/test-image/search';
    push(testImageSearch);
  }, []);

  const onAddTestConfig = (
    values: Partial<UpsertTestImageDto>,
    form: FormApi<UpsertTestImageDto, Partial<UpsertTestImageDto>>) =>
    (testConfId: number) => {

      const selectedTestConfIds = values.testConfIds ? [...values.testConfIds, testConfId] : [testConfId];
      form.change('testConfIds', [...selectedTestConfIds]);
      setSelectedTestConfigs(testConfigs.filter(tc => selectedTestConfIds.includes(tc.id)));
    };

  const onRemoveTestConfig = (
    values: Partial<UpsertTestImageDto>,
    testConfId: number,
    form: FormApi<UpsertTestImageDto, Partial<UpsertTestImageDto>>) => {

    const testConfIds = values.testConfIds;

    if (testConfIds) {
      let tcIds = [...testConfIds];
      tcIds = tcIds.filter(tcId => tcId !== testConfId);
      form.change('testConfIds', tcIds);
      setSelectedTestConfigs(testConfigs.filter(tc => tcIds.includes(tc.id)));
    }
  };

  const actionCellRenderer = (
    values: Partial<UpsertTestImageDto>,
    form: FormApi<UpsertTestImageDto, Partial<UpsertTestImageDto>>) => (rowData: any) => {

    return <InnerTableActionButton
      message={t('button.remove')}
      onConfirm={() => onRemoveTestConfig(values, rowData.id, form)}
    />;
  };

  const renderTestConfigTable = (
    values: Partial<UpsertTestImageDto>,
    form: FormApi<UpsertTestImageDto, Partial<UpsertTestImageDto>>): React.ReactNode => {

    return (
      <div style={{display: 'flex'}}>
        <InnerFormTable id='test-conf'
                        numOfCells={3}
                        values={selectedTestConfigs}
                        visibleRows={6}
                        columns={[
                          {
                            width: 2,
                            label: t('id'),
                            dataKey: 'id',
                          },
                          {
                            width: 5,
                            label: t('testconf.description'),
                            dataKey: 'description',
                            cellRenderer: multiLanguageInnerTableCellRenderer('description', language)
                          },
                          {
                            width: 2,
                            label: t('admin.actions'),
                            dataKey: 'id',
                            cellRenderer: actionCellRenderer(values, form),
                          },
                        ]} />

        <span style={{marginLeft: '8px'}}>
          <InnerTableActionButton
            message={t('button.add')}
            onConfirm={() => setModalOpen(true)}
          />
        </span>
      </div>
    );
  };

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={(values: UpsertTestImageDto) => handleSubmit(values)}
        initialValues={testImage}
        subscription={{ pristine: true, submitting: true, values: true }}
        render={(formProps: FormRenderProps<UpsertTestImageDto>) => renderFormContent(formProps)}
      />
    );
  };

  const renderFormContent = ({
                               handleSubmit,
                               submitting,
                               form,
                               values,
                             }: FormRenderProps<UpsertTestImageDto>): React.ReactNode => {
    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          <Grid.Row>
            <Grid.Column width={16}>
              <div className='title-h1'>{t('testImage.viewTitle')}</div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16}>
              <Grid stackable doubling columns={2}>

                {
                  errorMessages.length > 0 &&
                  <Grid.Row>
                    <Grid.Column width={12}>
                      <div className='error'>
                        <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                          {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                        </StyledErrorMessage>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                }

                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as='h4'>{t('testImage.generalInfo')}</Header>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={6}>
                    <DataLabel>{t('testImage.description')}</DataLabel>
                    <MultiLanguageInput fieldName='description' form={form} requiredFirst={true} />

                    <StyledDataLabel>{t('testImage.viewTitle')}</StyledDataLabel>
                    <MultiLanguageImageUpload fieldName='image' form={form} />

                  </Grid.Column>
                  <Grid.Column width={6}>
                    <DataLabel>{t('testImage.status')}</DataLabel>
                    <Field
                      name='active'
                      component={CheckBox}
                      label={t('testImage.active')}
                      toggle
                    />

                    <StyledDataLabel>{t('testImage.testConfigs')}</StyledDataLabel>
                    {renderTestConfigTable(values, form)}
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={12}
                               style={{ borderTop: '1px solid var(--very-light-blue)', marginTop: '2rem' }}
                  >
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row textAlign={'right'}>
            <Grid.Column width={12} style={{ paddingRight: '0' }}>
              {
                successMsg &&
                <SaveAndUpdateConfirmationMessage>
                  {successMsg}
                </SaveAndUpdateConfirmationMessage>
              }
              <CompositeButton
                primary
                type='submit'
                className='action-button'
                disabled={submitting || !!successMsg}
                onClick={noop}
              >
                {t('button.save')}
              </CompositeButton>
              <CompositeButton
                type='button'
                className='action-button'
                secondary
                disabled={submitting || !!successMsg}
                onClick={goToPreviousPage}
              >
                {t('action.back')}
              </CompositeButton>
            </Grid.Column>
          </Grid.Row>
        </TsaGrid>
        {renderAvailableTestConfsModal(values, form)}
      </form>
    );
  };

  const renderAvailableTestConfsModal = (
    values: UpsertTestImageDto,
    form: FormApi<UpsertTestImageDto, Partial<UpsertTestImageDto>>): React.ReactNode => {

    return (
      <Modal style={{ position: 'sticky' }}
             open={modalOpen} closeOnDimmerClick={true} onClose={() => setModalOpen(false)}>
        <Modal.Header>{t('testImage.availableTestConfigs')}</Modal.Header>
        <FormModalContentContainer>
          <TestImageAddTestConfModal
            selectedTestConfs={values.testConfIds}
            onAddTestConf={onAddTestConfig(values, form)}
            onCancel={() => setModalOpen(false)}
          />
        </FormModalContentContainer>
      </Modal>
    );
  };

  return (
    <UpsertContentWrapperDiv>
      {formDataLoaded
        ? <React.Fragment>
          {renderFinalForm()}
        </React.Fragment>
        : <LoaderComponent message={t('testImage.loading')} />
      }
    </UpsertContentWrapperDiv>
  );

};
export default UpsertTestImageForm;