import axios, { CancelTokenSource } from 'axios';
import { backendUrls } from 'service/http';
import {
  EducationalYearsDto, EducationalYearsSearchRequest,
  UpsertEducationalYearsDto,
} from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/educational-years`;

export const getEducationalYears = (source: CancelTokenSource): Promise<Array<EducationalYearsDto>> => {
  return axios
  .get(apiUri, { cancelToken: source.token })
  .then(response => response.data);
};

export const getEducationalYearById = (educationalYearId: number, source: CancelTokenSource): Promise<UpsertEducationalYearsDto> => {
  return axios
  .get(`${apiUri}/${educationalYearId}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const searchEducationalYear = (
  request: Partial<EducationalYearsSearchRequest>,
  source: CancelTokenSource): Promise<EducationalYearsDto[]> => {
  return axios
  .post(`${apiUri}/search`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const addEducationalYear = (upsertEducationalYearsDto: Partial<UpsertEducationalYearsDto>, source: CancelTokenSource): Promise<EducationalYearsDto> => {
  return axios
  .post(apiUri, upsertEducationalYearsDto, { cancelToken: source.token })
  .then(response => response.data);
};

export const editEducationalYear = (educationalYearId: number, upsertEducationalYearsDto: Partial<UpsertEducationalYearsDto>,
                           source: CancelTokenSource): Promise<UpsertEducationalYearsDto> => {
  return axios
  .put(`${apiUri}/${educationalYearId}`, upsertEducationalYearsDto, { cancelToken: source.token })
  .then(response => response.data);
};

export const deleteEducationalYearById = (educationalYearId: number, source: CancelTokenSource): Promise<EducationalYearsDto> => {

  return axios
  .delete(`${apiUri}/${educationalYearId}`, { cancelToken: source.token })
  .then(response => response.data);
};