import React, { Component } from 'react';
import { DividerProps } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledSaveAndUpdateConfirmationMessage = styled.div`
  font-size: 1.1rem;
  margin-top: 8px;
  margin-right: 1rem;
  font-weight: 800;
  color: darkgreen;
  display: inline-block;
`;

class SaveAndUpdateConfirmationMessage extends Component<DividerProps> {

  render(): React.ReactNode {
    const {children, ...rest} = this.props;
    return (
      <StyledSaveAndUpdateConfirmationMessage {...rest}>
        {children}
      </StyledSaveAndUpdateConfirmationMessage>
    )
  }
}

export default SaveAndUpdateConfirmationMessage;
