import AuthProvider from 'auth/AuthContext';
import AppDataProvider from 'context/AppDataContext';
import ClinicDataProvider from 'context/ClinicDataContext';
import GlobalStyle from 'globalStyle';
import 'react-image-lightbox/style.css';
import React from 'react';
import Main from 'routes/Main';

function App() {
  return (
    <AppDataProvider>
      <AuthProvider>
        <GlobalStyle />
        <ClinicDataProvider>
        <Main />
        </ClinicDataProvider>
      </AuthProvider>
    </AppDataProvider>
  );
}

export default App;
