import axios, { CancelTokenSource } from 'axios';
import { backendUrls } from 'service/http';
import {
  AvailableParamsRequest,
  ParamSearchRequest,
  TestResultParamDto,
  TestResultParamSearchRequest,
  UpsertTestResultParamDto,
} from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/testresultparams`;

export const getTestResultParams = (active: boolean | undefined, source: CancelTokenSource): Promise<Array<TestResultParamDto>> => {
  return axios
  .get(apiUri, {params: {active}, cancelToken: source.token })
  .then(response => response.data);
};

export const getExerciseResultParams = (active: boolean | undefined, source: CancelTokenSource): Promise<Array<TestResultParamDto>> => {
  return axios
  .get(`${apiUri}/exercise`, {params: {active}, cancelToken: source.token })
  .then(response => response.data);
};

export const getAvailableExerciseResultParams = (source: CancelTokenSource): Promise<Array<TestResultParamDto>> => {
  return axios
  .get(`${apiUri}/exercise/available`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getAvailableParamsForTestConf = (testConfId: number | undefined, source: CancelTokenSource): Promise<Array<TestResultParamDto>> => {
  return axios
  .get(`${apiUri}/available-test-conf-params`, { cancelToken: source.token, params: { testConfId: testConfId ? testConfId : "" } })
  .then(response => response.data);
};

export const getAvailableParamsForExerciseConf = (exerciseConfId: number | undefined, source: CancelTokenSource): Promise<Array<TestResultParamDto>> => {
  return axios
  .get(`${apiUri}/available-exercise-conf-params`, { cancelToken: source.token, params: { exerciseConfId: exerciseConfId ? exerciseConfId : "" } })
  .then(response => response.data);
};

export const getAvailableParamsForTestResult = (testConfId: number | undefined, source: CancelTokenSource): Promise<Array<TestResultParamDto>> => {
  return axios
  .get(`${apiUri}/${testConfId}/available-test-results-params`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getAvailableParamsForExerciseResult = (exerciseConfId: number | undefined, source: CancelTokenSource): Promise<Array<TestResultParamDto>> => {
  return axios
  .get(`${apiUri}/${exerciseConfId}/available-exercise-results-params`, { cancelToken: source.token })
  .then(response => response.data);
};

export const findAllAvailableParameters = (
  request: AvailableParamsRequest,
  source: CancelTokenSource): Promise<Array<TestResultParamDto>> => {
  return axios
  .post(`${apiUri}/available-parameters`, request,  {cancelToken: source.token})
  .then(response => response.data);
};

export const getTestResultParamById = (testResultParamId: number, source: CancelTokenSource): Promise<UpsertTestResultParamDto> => {
  return axios
  .get(`${apiUri}/${testResultParamId}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const addTestResultParam = (upsertTestResultDto: Partial<UpsertTestResultParamDto>, source: CancelTokenSource): Promise<TestResultParamDto> => {
  return axios
  .post(apiUri, upsertTestResultDto, { cancelToken: source.token })
  .then(response => response.data);
};

export const searchTestResultParam = (
  request: Partial<TestResultParamSearchRequest>,
  source: CancelTokenSource): Promise<TestResultParamDto[]> => {
  return axios
  .post(`${apiUri}/search`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const searchTestResultParamsForTestResult = (
  request: Partial<ParamSearchRequest>,
  source: CancelTokenSource): Promise<TestResultParamDto[]> => {
  return axios
  .post(`${apiUri}/search-for-test-result`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const editTestResultParam = (testResultParamId: number, upsertTestResultDto: Partial<UpsertTestResultParamDto>,
                           source: CancelTokenSource): Promise<UpsertTestResultParamDto> => {
  return axios
  .put(`${apiUri}/${testResultParamId}`, upsertTestResultDto, {cancelToken: source.token})
  .then(response => response.data);
};

export const deleteTestResultParamById = (testResultParamId: number, source: CancelTokenSource): Promise<TestResultParamDto> => {

  return axios
  .delete(`${apiUri}/${testResultParamId}`, {cancelToken: source.token})
  .then(response => response.data)
};
